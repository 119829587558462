import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IContactUs } from '../../../shared/models';
import { LayoutService } from '../../../services/layout/layout.service';

@Component({
    selector: 'lib-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    public isTablet: boolean;
    public isDesktop: boolean;
    public isMobile: boolean;
    constructor(
        public bottomSheetRef: MatBottomSheetRef<ContactUsComponent>,
        private layoutService: LayoutService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: IContactUs) { }

    ngOnInit() {
            // ----- Checking screen resolution by angular layout/breakpoints
            this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
                this.isTablet = this.layoutService.isMediumView();
                this.isDesktop = this.layoutService.isLargeView();
                this.isMobile = this.layoutService.isSmallView();
            });
          }

    onClose(): void {
        this.bottomSheetRef.dismiss();
    }
}
