import { Component, Input, OnInit } from '@angular/core';
import { IContentSection } from '../../../models/content-section.model';

@Component({
  selector: 'lib-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent implements OnInit {

  @Input() contentSectionList: IContentSection[];

  constructor() { }

  ngOnInit(): void {
  }

}
