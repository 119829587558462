import { FormFieldBase } from './form-field-base';

export class CheckboxField extends FormFieldBase<string> {
    controlType = 'checkbox';
    name: string;
    value: string; 
    checked: boolean;
    isActive?: boolean   
  
    constructor(options: { name?: string, value?: string,  checked?: boolean, isActive?: boolean} = {}) {
      super(options);     
      this.name = options.name;   
      this.value = options.value;
      this.checked = options.checked;
      this.isActive = options.isActive;
    }
}
