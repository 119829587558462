import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoggingService } from '../../shared/core/app-insights/logging.service';
import { endPoints } from '../../shared/core/app.config';
import { LocalizationService } from '../localization/localization.service';
import { SharedSettingsService } from '../../services/settings/settings-base.service';
import { BaseConstantService } from '../../services/constant/base-constant.service';
import moment from 'moment';
@Injectable({
    providedIn: 'root'
})
export class PagingService {
    private currentPage: string;
    private baseUrl = endPoints.baseUrl;

    constructor(
        private sessionStorageService: SessionStorageService,
        private titleService: Title,
        private router: Router,
        private loggingService: LoggingService,
        private localizationService: LocalizationService,
        private settingsService: SharedSettingsService
    ) {
    }

    public getSponorBrandNameLabel() {
        const sponorSuffix = this.settingsService.sponsorBrandName == null ? '' : `${this.settingsService.sponsorBrandName}`;
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = sponorSuffix;
        return tempDivElement.textContent === "" || tempDivElement.innerText === "" ?
            tempDivElement.textContent || tempDivElement.innerText || "" :
            ` - ${tempDivElement.textContent || tempDivElement.innerText || ""}`;
    }

    public registerPageDetails(pageName: string) {
        // De-duplication of repeat views.
        if (pageName !== this.currentPage || this.currentPage === undefined) {
            const fullPageName = pageName + this.getSponorBrandNameLabel();
            this.titleService.setTitle(fullPageName);
            this.loggingService.logPageView(
                fullPageName, `${endPoints.host}${endPoints.baseUrl}${this.stripLeadingSlashes(this.router.url)}`);
            this.currentPage = pageName;
        }
    }

    public getSponsorBrandName(): string {
        const selectedSponsorBrandName = this.localizationService.sharedUIElement?.filter(x =>
            x.uiElementCode === 'lblSponsorBrandNameLabel_Claims')
        let brandName = '';
        let sponsorBrandName = '';
        if (selectedSponsorBrandName !== undefined && selectedSponsorBrandName !== null
            && selectedSponsorBrandName.length > 0 && selectedSponsorBrandName[0] !== null) {
            if (selectedSponsorBrandName[0]?.uiElementValue) {
                brandName = selectedSponsorBrandName[0].uiElementValue !== undefined ? selectedSponsorBrandName[0].uiElementValue :
                    BaseConstantService.defaultSponsorBrandNameClaims;
            }
            else {
                brandName = BaseConstantService.defaultSponsorBrandNameClaims
            }
        }
        else {
            brandName = '';
        }
        this.settingsService.sponsorBrandName = brandName;
        sponsorBrandName = this.settingsService.sponsorBrandName;
        return sponsorBrandName ? ` - ${sponsorBrandName}` : '';
    }

    private stripLeadingSlashes(input: string) {
        let result: string = input;
        while (result && result.charAt(0) === '/') {
            result = result.substring(1);
        }
        return result;
    }
    public convertDateIntoUTC(objDate: any) {
        const startDate = moment(objDate).valueOf();
        const resultDate = moment(startDate).format("YYYY-MM-DD");
        if (resultDate && resultDate !== 'Invalid date' && resultDate !== undefined) {
            return resultDate;
        }
        return objDate;
    }
}
