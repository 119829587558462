import { Component, OnInit, ViewChild, ViewChildren, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, RouterEvent } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { DataCaptureDirective } from '../data-capture.directive';
import { CustomDataCaptureComponent } from '../custom-data-capture.component';
import * as dataCaptureComponents from '../data-capture-components';
import { Router } from '@angular/router';
import { QuestionSetService } from '../../../services/question-set/question-set.service';
import { TranslateService } from '@ngx-translate/core';
import {
  IProgressBar, Question, QuestionLogicResolverData, QuestionAnsweredEventArgs,
  IGetUIElement, ModalContent, SeverityLevel
} from '../../models';
import { SessionStorageService } from 'angular-web-storage';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';
import { BaseConstantService } from '../../../services/constant/base-constant.service';
import moment from 'moment';
import { BaseCustomerService } from '../../../services/customer/base-customer.service';
import { LayoutService } from '../../../services/layout/layout.service';
import { DateManipulationService } from '../../../services/date-manipulation/date-manipulation.service';
import { MsalAuthenticationService } from '../../../services/common-auth/msalauthentication.service';
import { PagingService } from '../../../services/app-insights/paging-service';
import { ReferenceService } from '../../../services/References/reference.service';
import { BehaviorSubject, Subject, mergeMap } from 'rxjs';
import { LocalizationService } from '../../../services/localization/localization.service';
import { MatDialog } from '@angular/material/dialog';
import { BaseProductTemplateService } from '../../../services/base-product-template/base-product-template.service';
import { withFetch } from '@angular/common/http';
import { ModalTemplateComponent } from '../../components/modal/modal-template/modal-template.component';

const dynamicComponentMapper = {
  imagebutton: dataCaptureComponents.ImageButtonComponent,
  plusminus: dataCaptureComponents.PlusMinusDataCaptureComponent,
  optionslist: dataCaptureComponents.OptionListDataCaptureComponent,
  checkbox: dataCaptureComponents.CheckBoxDataCaptureComponent,
  text: dataCaptureComponents.TextDataCaptureComponent,
  numeric: dataCaptureComponents.NumberDataCaptureComponent,
  radio: dataCaptureComponents.RadioDataCaptureComponent,
  dropdown: dataCaptureComponents.DropdownDataCaptureComponent,
  datepicker: dataCaptureComponents.DatepickerDataCaptureComponent,
  grouppanel: dataCaptureComponents.GroupPanelDataCaptureComponent,
  repeatinggroup: dataCaptureComponents.RepeatingGroupDataCaptureComponent,
  textarea: dataCaptureComponents.TextAreaDataCaptureComponent,
  recaptcha: dataCaptureComponents.RecaptchaDataCaptureComponent,
  money: dataCaptureComponents.CurrencyDataCaptureComponent
};

const nextButtonLabel = 'Shared.lblNext';
const logoutLabel = 'Shared.lblLogout';
const pageClaimQuestion = 'Shared.pageClaimQuestionnaire';
const productValidationText = 'Shared.productValidation';

const policyInceptionDateIntentCode = 'PolicyInceptionDate';
const policyExpiryDateIntentCode = 'PolicyExpiryDate';

const saveQuoteButtonLabel = 'Shared.lblSaveQuoteReferred';
const savedQuoteLabel = 'Shared.savedQuoteMessageReferred';
const okLabel = 'Shared.lblOk';

@Component({
  selector: 'lib-base-question-logic',
  templateUrl: './base-question-logic.component.html',
  styleUrls: ['./base-question-logic.component.scss']
})
export class BaseQuestionLogicComponent implements OnInit {
  @ViewChild(DataCaptureDirective, { static: true }) appDataCapture: DataCaptureDirective;
  quoteQnPayload: any;
  @Output() questionAnswered = new EventEmitter<QuestionAnsweredEventArgs>();
  @Input() questionObject: QuestionLogicResolverData;
  @Input() progressBar: IProgressBar;
  @Output() emitNextButtonClick = new EventEmitter();
  @Output() emitSaveResponseData = new EventEmitter();
  nextButtonText: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  logoutText: string;
  isCompressedViewOfPreviousAnswers: boolean;
  data: QuestionLogicResolverData;
  externalData: any;
  groupPannelQnData;
  pageClaimQuestion: string;
  productValidation: string;
  lookUpValues: any;
  subject = new BehaviorSubject(null);
  getUIElementData = new BehaviorSubject<IGetUIElement[]>(null);
  getUIElementDataValue: IGetUIElement[] = [];
  lastQuestion;
  repeatingGroupName: string;
  isDuplicateProductName: any;
  WizardParentSponsorName: any;
  isQuoteReferredCheck: boolean;
  isLoading: boolean = false;

  private saveQuoteButtonText: string;
  private savedQuoteText: string;
  private okButtonText: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public questionSetService: QuestionSetService,
    private router: Router,
    private layoutService: LayoutService,
    public translate: TranslateService,
    private msalAuthenticationService: MsalAuthenticationService,
    public sessionStorageService: SessionStorageService,
    private metaTag: Meta,
    private baseConstantService: BaseConstantService,
    private pagingService: PagingService,
    private dateManipulationService: DateManipulationService,
    private settingsService: SharedSettingsService,
    private customerService: BaseCustomerService,
    private referenceService: ReferenceService,
    private localizationService: LocalizationService,
    public matDialog: MatDialog,
    public baseProductTemplateService: BaseProductTemplateService
  ) {

    // super(sessionStorageService, questionSetService, translate, matDialog);
  }

  public ngOnInit() {


    this.localizationService.getUIElementsForProduct().subscribe(UIELementRes => {
      this.settingsService.setMCIPopUpText = UIELementRes?.find(result => result?.uiElementCode === 'lblMCIPopupText')?.uiElementValue;
    });

    if (this.questionObject && this.questionObject.questionSet === undefined) {
      this.questionObject.questionSet = this.settingsService.PrimaryQuestionData;
    }

    this.isQuoteReferredCheck = this.settingsService.isQuoteReferredCheck;
    const hasVisiblePolicyQuestions = this.questionSetService.hasVisibleQuestions('policy');
    if (this.isQuoteReferredCheck && this.settingsService.hidePolicyQuestionFromB2BProfileonB2C
      && hasVisiblePolicyQuestions && !Boolean(this.questionSetService.LastPolicyQuestion)) {
      this.questionSetService.setLastPolicyQuestion();
    }

    if (this.questionObject.questionSet?.some(value => value?.childQuestionSets?.
      some(childValue => childValue?.globalFunction?.toLowerCase()?.includes('dynamic')
        || value?.globalFunction?.toLowerCase()?.includes('source') ||
        childValue?.globalFunction?.toLowerCase()?.includes('metadata')))) {

      this.quoteQnPayload = {

        productTemplateId: this.sessionStorageService.get('ProductId'),
        timeZone: this.sessionStorageService.get('timeZone'),
        languageId: this.sessionStorageService.get('LanguageId'),
        sponsorId: this.sessionStorageService.get('SponsorId'),
        sponsorName: this.sessionStorageService.get('SponsorName')

      };

      if (this.questionObject.backwardNavRoute.includes('claim-details')) {
        this.referenceService.getClaimLookUpValues(this.quoteQnPayload).then(observerResponse => {
          this.settingsService.setLookUpValues = observerResponse;
          this.initializeQuestionSet();
        });
      }
      else {
        this.referenceService.getLookUpValues(this.quoteQnPayload).then(observerResponse => {
          this.settingsService.setLookUpValues = observerResponse;
          this.initializeQuestionSet();
        });
      }
    } else {
      this.initializeQuestionSet();
    }
    this.checkDeviceWidth();
  }

  initializeQuestionSet() {
    this.isCompressedViewOfPreviousAnswers = this.settingsService.compressedViewOfPreviousAnswers;
    this.metaTag.updateTag(
      { name: 'robots', content: BaseConstantService.metaDataDenySearch }
    );
    this.checkSessionTimedOut();

    this.data = this.questionObject;
    this.externalData = this.sessionStorageService.get('ExternalData');
    // Initialise question set service with applicable question set if required.
    // This should only happen when when calling for first time within Quote module.
    if (!this.questionSetService.isInitialized) {
      this.questionSetService.initialize(this.data.questionSet);
    } else if (this.questionSetService.currentQuestion
      && this.questionSetService.currentQuestionType.toLowerCase() !== this.data.questionType.toLowerCase()) {
      this.questionSetService.navigateToQuestionType(this.data.questionType);
    }

    const currentQuestion = this.questionSetService.currentQuestion;

    if (currentQuestion) {
      this.loadQuestion(currentQuestion);

      if (window.location.pathname.includes('claim-questionnaire')) {
        this.pagingService.registerPageDetails(this.pageClaimQuestion);
      }
    } else {
      // Potentially skip this section where no Questions of the current type are supplied.
      const state = window.history.state;
      if (state && state.isBackwards === true) {
        this.goBack();
      } else {
        this.navigateToNextPage();
      }


      this.pagingService.registerPageDetails(`${this.data.questionType} Questions`);
    }
  }

  private checkSessionTimedOut() {
    if (this.msalAuthenticationService.isSessionTimedOut()) {
      this.router.navigate(['/sessiontimeout']);
    }
  }

  closeAndGoBack() {
    if (this.sessionStorageService.get('isRepeat') === 'true') {
      this.sessionStorageService.set('isRepeat', 'false');
    }

    if (this.questionSetService.positionForType > 0) {
      this.navigateToPrevious('navigateBack');
    } else {
      this.goBack();
    }
  }

  protected goBack() {
    this.customerService.setIsBackwardsFlaginSessionStorage();
    this.router.navigate([this.data.backwardNavRoute]);
  }

  previousClicked(question: Question) {
    this.moveToTop();
    if (question) {
      this.navigateToIntentCode(question.qIntentCode);
    }
  }

  protected get previousAnswers() {
    return this.questionSetService.answeredQuestions;
  }

  get iconName(): string {
    return this.questionSetService.positionForType > 0 ? 'keyboard_backspace' : this.data.backwardNavIcon;
  }

  private loadQuestion(questionSet: Question) {
    this.questionSetService.stashQuestionSet();
    // If required, pre-populate answer for question.
    if (this.externalData) {
      this.questionSetService.prePopulateAnswer(questionSet, this.externalData);
    }

    this.prePopulateSubGroupID(questionSet);  // PBI#242648 To prepopulate sponsor dealer code based on b2b user logged in
    if (questionSet.isHidden) {
      this.resolveHiddenQuestion(questionSet, questionSet.isGroup);

    }
    else {
      // added this code for bug 197425
      if (this.isCompressedViewOfPreviousAnswers) {
        if (questionSet.qQuestionFormat?.toLowerCase() === 'confirmation') {
          if (window.location.pathname.includes('quote-questions')) {
            this.router.navigate(['quote/choose-cover']);
          }

        }
      }
      if (questionSet?.childQuestionSets?.length > 0) {
        this.resolveHiddenQuestionForGroupPannel(questionSet);
      }
      this.loadDynamicComponent(questionSet);
    }
  }

  private loadDynamicComponent(questionSet: Question) {
    this.moveToTop();
    let currentDynamicComponent;
    let groupPanelQnSet: Question[];
    let currentlRepeatingGroupCount: any;

    this.applyTranslations();
    this.showLogoutLink();
    this.sessionStorageService.set('PageId', '');
    let pageId = questionSet.pageId;
    this.groupPannelQnData = questionSet;
    if (questionSet.isGroup && questionSet.isGroup === true) {
      currentDynamicComponent = dynamicComponentMapper['grouppanel'];
      groupPanelQnSet = questionSet.childQuestionSets;
      groupPanelQnSet = this.questionSetService.getAlertMessage(groupPanelQnSet);
    } else if (questionSet?.maxRepeats > 0) {
      const currentRepeatingGroupName = this.questionSetService.getRepeatingGroupName(questionSet.qIntentCode);
      currentlRepeatingGroupCount = parseInt(this.questionSetService.getCurrentRepeatingGroupCount(currentRepeatingGroupName), 10);
      if (currentlRepeatingGroupCount === 0) {
        this.questionSetService.formFieldIteration = 1;
        this.questionSetService.initialRepeatingGroup = true;
        pageId = pageId ? pageId + "_1" : null;
      } else {
        pageId = pageId ? questionSet.pageId + "_" +  currentlRepeatingGroupCount.toString(): null;
        this.questionSetService.setRepeatingGroupData(questionSet);
      }

      this.sessionStorageService.set('currentlRepeatingGroupCount', currentlRepeatingGroupCount);
      const state = window.history.state;
      // removed the condition as below two condition is enough to set the value and was creating issue for bug 209738
      if (this.questionSetService.initialRepeatingGroup === true && currentlRepeatingGroupCount === 0) {
        this.questionSetService.initialRepeatingGroup = false;
        questionSet = this.firstRepeatingIteration(questionSet);
      }
      currentDynamicComponent = dynamicComponentMapper['repeatinggroup'];
      groupPanelQnSet = questionSet?.childQuestionSets;
      groupPanelQnSet = this.questionSetService.getAlertMessage(groupPanelQnSet);
    }
    else {
      currentDynamicComponent = this.getSetQuestionTypeMapper(questionSet);
    }

    if (pageId) {
      this.sessionStorageService.set('PageId', pageId);
    }

    questionSet?.childQuestionSets?.filter(childQuestion => {
      this.lookUpValues = this.settingsService.getLookUpValues;
      this.lookUpValues?.filter(values => {
        this.mapAnswerSetFromLookup(childQuestion, values);

        // recursively check for Question answerSet with lookup response for all childQuestions
        if (childQuestion?.answerSets && values?.options.length > 0) {
          childQuestion?.answerSets?.forEach(answerSet => {
            values.options.forEach(option => {
              if (answerSet?.name === option?.text) {
                answerSet?.childQuestionSets?.forEach(cq => {
                  option?.questions?.forEach(question => {
                    if (question?.options?.length !== 0 && cq?.qIntentCode === question?.id) {
                      this.mapAnswerSetFromLookup(cq, question);
                    }
                  });
                });
              }
            })
          })
        }
      })
    })

    // componentFactoryResolver is now depracated; from angular 13 we can directly pass the component in createComponent
    //  const componentFactory = this.componentFactoryResolver.resolveComponentFactory(currentDynamicComponent);
    const viewContainerRef = this.appDataCapture.viewContainerRef;
    viewContainerRef.clear();

    if (currentDynamicComponent) {
      const componentRef = viewContainerRef.createComponent(currentDynamicComponent);
      const componentInst = (componentRef.instance as CustomDataCaptureComponent);
      componentInst.qnData = questionSet;
      componentInst.groupPanelQnData = groupPanelQnSet;
      componentInst.nextBtn = this.buttonLabel(questionSet.qIntentCode);
      componentInst.offsetBasisQnData = this.getOffsetBasisQuestion(questionSet);
      componentInst.maxRepeats = questionSet?.maxRepeats;
      componentInst.currentlRepeatingGroupCount = currentlRepeatingGroupCount
      componentInst.updateEmitter.subscribe((result) => {
        this.emitNextButtonClick.emit();
        if (result?.internalQuestionSet) {
          if (this.questionSetService.initialRepeatingGroup === false && currentlRepeatingGroupCount > 0) {
            this.data.questionSet = result.internalQuestionSet;
          }
        }
        else {
          // result.internalQuestionSet is undefined sometimes while moving from
          // choose-cover to repeating element question hence added below code
          this.data.questionSet = this.questionSetService.internalQuestionSet;
        }

        if (this.isQuoteReferredCheck && this.settingsService.hidePolicyQuestionFromB2BProfileonB2C
          && questionSet.qIntentCode === this.questionSetService.LastPolicyQuestion) {
          this.showRefferedPopUp();
        } else {
          if (this.questionSetService.currentQuestion.qIntentCode === 'WizardParentSponsor') {
            this.sessionStorageService.set('WizardParentSponsor', this.questionSetService.currentQuestion.answerSets[0].name);
          }
          if (this.questionSetService.currentQuestion.childQuestionSets !== undefined
            && this.questionSetService.currentQuestion.childQuestionSets.length > 0) {
            if (this.questionSetService.currentQuestion.childQuestionSets[0].qIntentCode === 'WizardProductName') {
              const WizardProductNameval = this.questionSetService.currentQuestion.childQuestionSets[0].answerSets[0].name
              this.WizardParentSponsorName = this.sessionStorageService.get('WizardParentSponsor')
              this.referenceService.getProductNameValidation(this.WizardParentSponsorName, WizardProductNameval).then(observerResponse => {
                this.isDuplicateProductName = observerResponse;
                if (this.isDuplicateProductName && this.isDuplicateProductName !== undefined) {
                  return alert(this.productValidation);
                }
                else {
                  this.onQuestionAnswered();
                }
              })
            }
            else {
              this.onQuestionAnswered();
            }
          }
          else {
            this.onQuestionAnswered();
          }
        }
      });
      if (questionSet?.maxRepeats > 0) {
        componentInst.deleteRepeatingGroup.subscribe(() => {
          this.onDeleteRepeatingGroup();
        })
      }
    }

  }

  mapAnswerSetFromLookup(childQuestion, values) {
    let isDuplicate = false;

    if (childQuestion?.globalFunction !== '' && childQuestion?.globalFunction !== null && childQuestion?.qIntentCode === values?.id) {
      if (childQuestion.globalFunction?.toLowerCase()?.includes('source')) {

        // bug241397-added this code to eliminate the options which are not available in lookup response
        if (childQuestion?.answerSets?.length === 1 && childQuestion?.answerSets[0]?.name !== ''
          && childQuestion?.answerSets[0]?.name !== undefined && childQuestion?.answerSets[0]?.name !== null) {
          if (values?.options?.filter(x => (x?.text === childQuestion?.answerSets[0]?.name) ||
            (x?.id === childQuestion?.answerSets[0]?.name))?.length === 0) {
            childQuestion.answerSets.splice(0, 1);
          }
        }

        values?.options.forEach((dataValue) => {
          let answerSet = this.questionSetService.MapAnswerSet(dataValue);

          if (answerSet.childQuestionSets?.length > 0 && childQuestion.answerSets.length > 0
            && childQuestion.answerSets[0]?.childQuestionSets?.length > 0) {
            answerSet.childQuestionSets = answerSet.childQuestionSets.map(obj1 => {
              const matchingQuetion = childQuestion.answerSets[0]?.childQuestionSets?.filter(p => p?.parentOValue === answerSet?.name)
                ?.find(obj2 => obj2?.qIntentCode === obj1?.id);
              return { ...obj1, ...matchingQuetion };
            });
          }
          if (dataValue.id !== '' && dataValue.id !== undefined && dataValue.text !== '' && dataValue.text !== undefined) {
            if (dataValue.id !== dataValue.text) { answerSet = this.questionSetService.MapAnswerSetMetadata(dataValue); }
          }

          isDuplicate = false;
          childQuestion?.answerSets?.forEach(value => {
            if (answerSet?.name === value?.name) {
              isDuplicate = true
              if (!value.hasOwnProperty('textValue')) {
                value.textValue = answerSet.textValue;
              }
            }
          })
          if (!isDuplicate) {
            childQuestion.answerSets.push(answerSet);
          }
        })
      }
    }
  }

  showRefferedPopUp() {
    const modalData: ModalContent = {
      headingText: '',
      subHeadingText: this.savedQuoteText,
      additionalText: '',
      instructionText: '',
      iconName: '',
      severityLevel: SeverityLevel.Error,
      closeButton: true,
      buttons: [
        {
          buttonText: this.okButtonText,
          tag: true,
          isSecondary: true,
        }
      ]
    };
    const dialogRef = this.matDialog.open(ModalTemplateComponent, {
      autoFocus: false,
      data: modalData
    });
    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result !== 'close') {
        this.emitSaveResponseData.emit();
      }
    });
  }

  private getSetQuestionTypeMapper(questionSet) {
    const qQuestionFormat = questionSet.qQuestionFormat.toLowerCase();
    const qQuestionSubType = questionSet.subType ? questionSet.subType.toLowerCase() : null;
    if (qQuestionSubType && dynamicComponentMapper.hasOwnProperty(qQuestionSubType)) {
      return dynamicComponentMapper[qQuestionSubType];
    } else if (qQuestionFormat && dynamicComponentMapper.hasOwnProperty(qQuestionFormat)) {
      return dynamicComponentMapper[qQuestionFormat];
    }
  }

  private checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  hasAnsweredQuestions(dependentQuestions: any): boolean {
    let hasAnsweredQuestion: boolean = false;
    hasAnsweredQuestion = (dependentQuestions?.answerSets?.filter(x => x.isAnswered === true).length > 0);

    if (hasAnsweredQuestion === false && dependentQuestions.childQuestionSets?.length > 0) {
      dependentQuestions.childQuestionSets.filter(cq => {
        cq.answerSets?.filter(ans => {
          if (ans.isAnswered === true) {
            hasAnsweredQuestion = true;
            return;
          }
        });
      });
    }

    return hasAnsweredQuestion;
  }

  private onQuestionAnswered() {
    this.questionSetService.markQuoteChangesAsUnsaved(true);
    this.lastQuestion = this.questionSetService.currentQuestion;
    if (!this.lastQuestion?.isHidden && this.lastQuestion?.qQuestionFormat.toLowerCase() !== 'recaptcha') {
      this.sessionStorageService.set('lastAnsweredQuestionIntentCode', this.lastQuestion.qIntentCode);
    }

    if (this.questionSetService.currentQuestion.qIntentCode && this.questionSetService.currentQuestion.qIntentCode !== ''
      && !this.lastQuestion?.hideHistory && this.lastQuestion?.qQuestionFormat.toLowerCase() !== 'recaptcha') {

      const { currentQuestion, answeredQuestions } = this.questionSetService;
      const isRenewalQuote = this.sessionStorageService.get('IsRenewalQuote');

      // In renewal quotes, ensure the current and previous questions align for a slow reveal effect
      const previousAnswer = isRenewalQuote ? currentQuestion.childQIntentCode : currentQuestion.qIntentCode;
      const isSlowRevealEnabled = this.questionSetService.EnableSlowReveal;
      if (isSlowRevealEnabled) {
        const question = answeredQuestions.find(x => x.qIntentCode === currentQuestion.childQIntentCode);
        if (question) { question.visited = true;}
      }
      this.questionSetService.UpdatePreviousAnswerLastQuestion(previousAnswer);
    }

    this.cloneRepeatingGroupQuestions();

    if (this.lastQuestion.answerSets?.length > 1) {// *** To clear previous selection and its answers *** //
      const optionsToBeCleared = this.lastQuestion.answerSets.filter(q => q.isAnswered === false);

      optionsToBeCleared?.filter(x => x.isAnswered === false)?.forEach(opt => {
        const dependentQuestions = this.settingsService.AnsweredQuestionSet.filter(x => x.qIntentCode === opt.name);

        if (dependentQuestions?.length > 0) {

          const hasAnsweredQuestion = this.hasAnsweredQuestions(dependentQuestions[0]);
          if (hasAnsweredQuestion) {
            const answerSetIndex = this.settingsService.AnsweredQuestionSet.findIndex(q => q.qIntentCode === opt.name);
            const currentQuestionIntentCode = this.questionSetService.currentQuestion.qIntentCode;
            const parentQuestionIndex = this.settingsService.PrimaryQuestionData
              .findIndex(q => q.qIntentCode === currentQuestionIntentCode);

            // const primaryCopyOfOption = this.settingsService.PrimaryQuestionData.filter(x => x.qIntentCode === opt.name);
            const primaryCopyOfOption = this.settingsService.getBaseQuestionData?.filter(x => x.qIntentCode === opt.name);

            const answeredQSet = JSON.parse(JSON.parse(sessionStorage.getItem('AnsweredQuestionSet'))._value);

            // *** To maintain the latest selection of Parent question in 'AnsweredQuestionSet' ***//
            answeredQSet[parentQuestionIndex] = this.questionSetService.currentQuestion;

            if (primaryCopyOfOption) {
              // *** Overriding / Resetting the previously selected option's values with defaualt value ***//
              answeredQSet[answerSetIndex] = primaryCopyOfOption[0];
            }

            this.sessionStorageService.set('AnsweredQuestionSet', JSON.stringify(answeredQSet));

            // *** resetting the current question index as parent question ***//
            this.questionSetService.initialize(answeredQSet, parentQuestionIndex);
          }

        }
      });
    }
    this.updateChildQuestionStateOnParentEdit()
    this.settingsService.PrimaryQuestionData = this.questionSetService.internalQuestionSet;
    this.questionSetService.navigateNext();
    const currentQuestion = this.questionSetService.currentQuestion;
    if (this.questionSetService.currentQuestion?.maxRepeats > 0) {
      this.questionSetService.setRepeatingGroupData(currentQuestion);
    }
    if (currentQuestion) {
      this.questionAnswered.emit(new QuestionAnsweredEventArgs(this.lastQuestion));
      this.loadQuestion(currentQuestion);
    } else {
      // Let's leave the the service positioned on the last question, in case we navigate back to it.
      this.questionSetService.navigateToIntentCode(this.lastQuestion.qIntentCode);
      this.questionAnswered.emit(new QuestionAnsweredEventArgs(this.lastQuestion, true));
      const isB2BFlow = this.sessionStorageService.get('isb2b')
      const popUpTextValue = this.settingsService.getMCIPopUpText;
      const existingCustomerQuote = this.settingsService.existingCustomerQuote;
      if (existingCustomerQuote) {
        /* if 'existingCustomerQuote' is 'true' then.. if the code execution comming here then it means..
         user were trying to change/Override the quote question answer.  So, if '' is 'true' then */
        this.sessionStorageService.set('isQSetOverride', true);
        const isB2cMultiCarrierQuotingEnabled = this.settingsService.isB2cMultiCarrierQuotingEnabled;
        if (isB2cMultiCarrierQuotingEnabled === true) {
          /* While overdding existing question set answers in the MCI journey..
          we have to consider it as a new Journey / Quote.  Hence, clearing the existing quote details. */
          this.sessionStorageService.set('QuoteReferenceID', null);
          this.sessionStorageService.set('quoteSummary', null);
          this.sessionStorageService.set('OrderId', null);
          this.sessionStorageService.set('PolicyId', null);

          const productId = this.sessionStorageService.get('ProductId');
          if (productId > 0) {
            this.baseProductTemplateService.getMasterProduct(productId).subscribe(masterProduct => {
              if (masterProduct) {
                this.sessionStorageService.set('ProductId', masterProduct.productTemplateID);
                this.sessionStorageService.set('ProductName', masterProduct.productTemplateName);
                this.sessionStorageService.set(BaseConstantService.Key_IsATPDocumentDownloadForMCI, masterProduct.allowDocGenerationForMCI);
                this.settingsService.isDefaultListViewForMCI = masterProduct.isDefaultListView;

              }
            });
          }

        }
      }

      if (this.lastQuestion?.qQuestionType.toLowerCase() === 'quote') {
        if (this.sessionStorageService.get('isMasterProduct') && !isB2BFlow) {
          const isTsAndCsRequired = sessionStorage.getItem('isTermsAndConditionsAcceptanceRequiredToQuote');
          if (!isTsAndCsRequired || isTsAndCsRequired !== 'true')
          {
            this.sessionStorageService.set('isModelOpen', 'true');
            this.localizationService.openModal(popUpTextValue);
          }
        }
      }

      this.navigateToNextPage();
    }
  }

  // Only supports date, text, numeric.
  private resolveHiddenQuestion(question: Question, isGroup: boolean = false) {
    // Only enter below logic when not already asnwered/pre-populated.
    if (!question.answerSets.some(ans => ans.isAnswered)) {
      const answer = question.answerSets[0];

      if (question.qQuestionFormat.toLowerCase() === 'datepicker') {
        let isFixedTerm = false;
        let policyTerm = 0;

        // PBI 130354: Is this 'PolicyExpiryDate' & do we have a PolicyTerm on Product? => Set fixed term flag.
        if (question.qIntentCode.toLowerCase() === policyExpiryDateIntentCode.toLowerCase()) {
          policyTerm = Number(sessionStorage.getItem('policyTerm'));
          if (policyTerm && Number(policyTerm) > 0) {
            isFixedTerm = true;
          }
        }

        // 1. Get the TimeZoneOffset.
        const timeZoneOffset = this.sessionStorageService.get('timeZoneOffset');
        const sponsorTimeZoneOffset = timeZoneOffset && timeZoneOffset.toLowerCase().replace('utc', '');

        // 2. Calculate anchor date.
        // PBI 130354: If fixed term flag => OffsetBasis is always 'PolicyStartDate'.
        const offsetIntentCode = isFixedTerm ? policyInceptionDateIntentCode : question.offsetBasis;
        const offsetBasisValue = this.questionSetService.findAnswerForIntentCode(offsetIntentCode);

        let offsetBasisDate: moment.Moment;
        if (offsetBasisValue && moment(offsetBasisValue) && moment(offsetBasisValue).isValid()) {
          offsetBasisDate = moment.parseZone(offsetBasisValue);
        }
        const anchorDate = offsetBasisDate || this.dateManipulationService.getMomentDatewithUtcOffset(sponsorTimeZoneOffset);

        // 3. Discern the offset pattern.
        // PBI 130354: If fixed term flag => Build offset pattern from PolicyTerm i.e. 'n,months'.
        const offsetPattern =
          isFixedTerm ? `${policyTerm},months` :
            question.default?.toLowerCase() === 'mindate' ? question.minDate
              : question.default?.toLowerCase() === 'maxdate' ? question.maxDate
                : question.default;

        // 4. Calculate the date.
        let result: moment.Moment = this.dateManipulationService.applyDateOffset(offsetPattern, anchorDate, timeZoneOffset);

        if (isFixedTerm) {
          // PBI 130354: If fixed term flag && !isExpiryDateasInceptionDate => deduct one day from result.
          if (sessionStorage.getItem('isExpiryDateasInceptionDate') === 'false') {
            result = this.dateManipulationService.applyDateOffset('-1,days', result, timeZoneOffset);
          }
        } else if (offsetBasisValue === undefined && isFixedTerm === false && answer?.name) {
          result = this.dateManipulationService.getMomentDateWithUtc(answer.name);
        }

        answer.name = result?.format();
      } else {
        // Non-date questions.
        // Check if the answer should be populated from a parameter.
        if (question.parameterName) {
          const params = this.settingsService.queryParams;
          const paramValue = params && params[question.parameterName.toLowerCase()];
          if (paramValue) {
            answer.name = paramValue;
          }
        } else {
          // Just populate the answer with the default.
          if (question?.default && question?.default !== undefined) {
            answer.name = question.default;
          }
        }
      }

      answer.isAnswered = true;
    }
    if (!isGroup) {
      this.onQuestionAnswered();
    }
  }

  private resolveHiddenQuestionForGroupPannel(questionSet) {
    questionSet.childQuestionSets.forEach(ques => {
      if (ques.isHidden) {
        this.resolveHiddenQuestion(ques, true);
      }
      if (ques?.answerSets?.length > 0) {
        ques?.answerSets?.forEach((que) => {
          if (que?.childQIntentCode && que?.childQuestionSets?.length > 0) {
              this.resolveHiddenQuestionForGroupPannel(que);
          }
        });
      }
    });
  }


  private prePopulateSubGroupID(questionSet: Question) {
    if (questionSet?.childQuestionSets) {
      const index = questionSet.childQuestionSets?.findIndex(i => i.subGroupID?.toLowerCase() === "true");
      if (index >= 0) {
        const subgroupid = this.sessionStorageService.get('sponsorsubgroupid');
        const childLink = questionSet.childQuestionSets[index];
        if (subgroupid && subgroupid !== '' && subgroupid !== null && !childLink.answerSets[0].isAnswered) {
          childLink.answerSets[0].name = subgroupid;
          childLink.answerSets[0].isAnswered = true;
        }
      }
    }
  }

  private navigateToPrevious(navigateFrom: string) {
    this.questionSetService.navigatePrevious(navigateFrom);
    if (this.questionSetService.currentQuestion?.maxRepeats > 0) {
      this.questionSetService.setRepeatingGroupData(this.questionSetService.currentQuestion);
    }
    this.loadDynamicComponent(this.questionSetService.currentQuestion);
  }

  private navigateToIntentCode(intentCode: string) {
    this.questionSetService.navigateToIntentCode(intentCode);
    this.loadDynamicComponent(this.questionSetService.currentQuestion);
  }

  private navigateToNextPage() {
    this.router.navigate([this.data.forwardNavRoute], { relativeTo: this.activatedRoute, state: { isForwardNavigation: true } });
  }

  private getOffsetBasisQuestion(question: Question): Question {
    if (question?.offsetBasis && question?.offsetBasis !== '') {
      return this.questionSetService.getQuestionByIntentCode(question.offsetBasis);
    }
    return null;
  }

  private applyTranslations() {
    this.translate.get([nextButtonLabel, logoutLabel, pageClaimQuestion, productValidationText,
      saveQuoteButtonLabel, savedQuoteLabel, okLabel]).subscribe((res) => {
        this.nextButtonText = res[nextButtonLabel];
        this.logoutText = res[logoutLabel];
        this.pageClaimQuestion = res[pageClaimQuestion];
        this.productValidation = res[productValidationText];
        this.saveQuoteButtonText = res[saveQuoteButtonLabel];
        this.savedQuoteText = res[savedQuoteLabel];
        this.okButtonText = res[okLabel];
      });
  }

  async showLogoutLink() {
    this.logoutText = await this.baseConstantService.getLogOutText(this.logoutText);
  }

  logout() {
    this.msalAuthenticationService.logout();
  }

  moveToTop() {
    let top = document.getElementById('header');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  // To clone repeating group question and modify QIntentCode for 2nd, 3rd and so on iteration
  cloneRepeatingGroupQuestions() {
    if (this.sessionStorageService.get('isRepeat') === 'true') {
      this.sessionStorageService.set('PreviousAnsEditButton', true);
      this.questionSetService.IsNewRepeatingGroup = true;
      const currentQIntentCode = this.sessionStorageService.get('currentRepeatedQuestion').qIntentCode;

      const index = this.data.questionSet.findIndex(i => i.qIntentCode === currentQIntentCode);
      if (index >= 0) {
        const childLink = this.data.questionSet[index].childQIntentCode;

        const clonedArray = JSON.parse(JSON.stringify(this.data.questionSet));
        const clonedGroup = clonedArray[index];

        // Don't clone the repeating group IDs.
        clonedGroup.childQuestionSets.forEach(question => {
          const lowerCaseIntentCode = question.qIntentCode.toLowerCase();

          if (lowerCaseIntentCode.endsWith('~repeatinggroupid') // Varies between "~" and ".".
            || lowerCaseIntentCode.toLowerCase().endsWith('.repeatinggroupid')) {
            question.answerSets.forEach(answer => {
              answer.name = '';
            });
          }
        });

        this.repeatingGroupName = clonedGroup.qIntentCode;
        this.repeatingGroupName = this.questionSetService.getRepeatingGroupName(this.repeatingGroupName);
        this.questionSetService.formFieldIteration = this.questionSetService.formFieldIteration + 1
        const iterationCount = this.questionSetService.formFieldIteration;
        const clonedGroupIntentCode = this.repeatingGroupName + '~' + (iterationCount - 1);
        const indexOfQuestion = this.data.questionSet.findIndex(que => que.qIntentCode === clonedGroupIntentCode);
        if (indexOfQuestion < 0) {
          clonedGroup.childQuestionSets.forEach((child) => {
            const childQIntentCode = this.questionSetService.getRepeatingGroupChildQintentCode(child.qIntentCode);
            child.qIntentCode = this.repeatingGroupName + '~' + (iterationCount - 1) + '~' + childQIntentCode;
            child.answerSets.forEach(answer => {
              answer.isAnswered = false;
            });
          });
          clonedGroup.qIntentCode = this.repeatingGroupName + '~' + (iterationCount - 1);
          this.data.questionSet[index].childQIntentCode = clonedGroup.qIntentCode;
          this.data.questionSet[index].answerSets[0].childQIntentCode = clonedGroup.qIntentCode;
          clonedGroup.childQIntentCode = childLink;
          clonedGroup.answerSets[0].childQIntentCode = childLink;
          this.data.questionSet.splice(index + 1, 0, clonedGroup);
          if (clonedGroup?.qSubDescription) {
            clonedGroup.qSubDescription = this.questionSetService.getRepeatingGroupqSubDescription(clonedGroup?.qSubDescription)
              + ' (' + iterationCount + ')';
          }
          const currentlRepeatingGroupCount = this.sessionStorageService.get('currentlRepeatingGroupCount');
          this.data.questionSet = this.questionSetService.increaseRepeatingGroupCount(this.data.questionSet,
            currentlRepeatingGroupCount + 1, this.repeatingGroupName);
          this.questionSetService.updateRepeatingGroupCount(this.repeatingGroupName, iterationCount, clonedGroup.qIntentCode);
          this.questionSetService.initialize(this.data.questionSet, index);
        }
      }
    }
  }

  // To modify QIntentcode for first iteration of repeating group
  // and no need to make clone of question
  firstRepeatingIteration(questionSet: Question) {
    if (this.questionSetService.formFieldIteration === 1) {
      const index = this.data?.questionSet?.findIndex(i => i.qIntentCode === questionSet.qIntentCode);
      const clonedGroup = this.data?.questionSet[index];
      this.sessionStorageService.set('PreviousAnsEditButton', true);
      this.questionSetService.IsNewRepeatingGroup = true;
      // const childLink = this.data.questionSet[index].childQIntentCode;
      const repeatingGroupOriginalQIntentCode = clonedGroup.qIntentCode;
      this.repeatingGroupName = clonedGroup.qIntentCode;
      const iterationCount = this.questionSetService.formFieldIteration;
      clonedGroup.qIntentCode = this.repeatingGroupName + '~' + (iterationCount - 1);

      if (this.lastQuestion) {
        const lastQuestionIndex = this.data?.questionSet?.findIndex(i => i.qIntentCode === this.lastQuestion?.qIntentCode);
        this.data.questionSet[lastQuestionIndex].childQIntentCode = clonedGroup.qIntentCode;
        this.data.questionSet[lastQuestionIndex].answerSets.forEach(answer => {
          if (answer.childQIntentCode === repeatingGroupOriginalQIntentCode) {
            answer.childQIntentCode = clonedGroup.qIntentCode;
          }
        });
      }

      clonedGroup?.childQuestionSets.forEach((child) => {
        child.qIntentCode = this.repeatingGroupName + '~' + (iterationCount - 1) + '~' + child.qIntentCode;
        child.answerSets.forEach(answer => {
          answer.isAnswered = false;
        });
      });

      this.questionSetService.updateRepeatingGroupCount(this.repeatingGroupName, iterationCount, clonedGroup.qIntentCode);
      // clonedGroup.qSubDescription = clonedGroup.qSubDescription + ' (' + (iterationCount) + ')';
      if (clonedGroup?.qSubDescription) {
        clonedGroup.qSubDescription = this.questionSetService.getRepeatingGroupqSubDescription(clonedGroup?.qSubDescription)
          + ' (' + iterationCount + ')';
      }
      const currentlRepeatingGroupCount = this.sessionStorageService.get('currentlRepeatingGroupCount');
      this.data.questionSet = this.questionSetService.increaseRepeatingGroupCount(this.data.questionSet,
        currentlRepeatingGroupCount + 1, this.repeatingGroupName);
      this.questionSetService.internalQuestionSet = this.data.questionSet;
      return this.data.questionSet[index];
    }
  }

  onDeleteRepeatingGroup() {
    this.data.questionSet = JSON.parse(this.sessionStorageService.get('AnsweredQuestionSet'));
    this.navigateToPrevious('navigateAfterDelete');
  }

  private buttonLabel(intentCode): string {
    if (this.isQuoteReferredCheck && this.settingsService.hidePolicyQuestionFromB2BProfileonB2C
      && intentCode === this.questionSetService.LastPolicyQuestion) {
      return this.saveQuoteButtonText
    } else {
      return this.nextButtonText;
    }
  }

  /**
   * Updates the state of the child question based on the parent's selection.
   *
   * Scenario:
   * - If the parent question (e.g., extraDetails) is selected as "Yes", the child question (e.g., extraDetails) is included.
   * - If the parent question is later changed to "No", the child question should not appear in the next iteration.
   */
  private updateChildQuestionStateOnParentEdit(): void {
    const currentQuestion = this.questionSetService.currentQuestion;
    const internalQuestionSet = this.questionSetService.internalQuestionSet;

    const childQuestionIndex = internalQuestionSet.findIndex(question =>
      question.parentQIntentCode === currentQuestion.qIntentCode
    );

    if (childQuestionIndex !== -1) {
      const childQuestion = internalQuestionSet[childQuestionIndex];
      if (currentQuestion.childQIntentCode !== childQuestion.qIntentCode) {
        if (childQuestion.answerSets && childQuestion.answerSets.length > 0 && childQuestion.answerSets[0].isAnswered) {
          childQuestion.answerSets[0].isAnswered = false;
        }
      }
    }
  }

}
