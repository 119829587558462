import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LayoutService } from 'projects/wtw-ui-components/src/lib/services/layout/layout.service';
import { FaqItem } from '../../../models/faq-item.model';

@Component({
  selector: 'lib-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  @Input() faqData: FaqItem[];
  @Input() tag: any;
  @Input() faqSectionHeading: string;
  @Input() faqSectionSubHeading: string;
  @Output() clicked = new EventEmitter();

  public isMobileView = false;
  public isDesktopView: boolean;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {


    // ----- Checking screen resolution by angular layout/breakpoints
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobileView = this.layoutService.isSmallView();
      this.isDesktopView = this.layoutService.isLargeView();
    });
  }

}
