// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-container {
  padding: 2em;
}

.error-title {
  color: red;
  text-decoration: underline;
}

.error-content {
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./projects/wtw-atp-sales/src/app/errors/generic-error.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;EACA,0BAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".error-container {\r\n  padding: 2em;\r\n}\r\n\r\n.error-title {\r\n  color: red;\r\n  text-decoration: underline;\r\n}\r\n\r\n.error-content {\r\n  font-style: italic;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
