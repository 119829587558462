export class CardButtonBase<T> {
    key: string;
    titleLabel: string;
    subTitleLabel: string;
    iconName: string;
    menuInfo: MenuParams[];

    constructor(options: {
        key?: string,
        titleLabel?: string,
        subTitleLabel?: string,
        iconName?: string,
      } = {}) {
      this.key = options.key || '';
      this.titleLabel = options.titleLabel || '';
      this.subTitleLabel = options.subTitleLabel || '';
      this.iconName = options.iconName || '\'visiblity\'';
    }
  }

export class MenuParams {
    key: string;
    iconName: string;
    menuLabel: string;
    data: object | string;
  }
