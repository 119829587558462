<mat-form-field class="dropDown-container" *ngIf="isDesktop">
  <span matPrefix>
    <mat-icon>{{iconName}}</mat-icon>
  </span>
  <mat-select [(ngModel)]="selectedOption" (selectionChange)="onSelectionChange($event)" disableRipple
    panelClass="optionPanelClass">
    <mat-option *ngFor="let category of Categories" [value]="category.id">
      {{category.name}}
    </mat-option>
  </mat-select>
</mat-form-field>



