import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { LayoutService } from '../../../services/layout/layout.service';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';

@Component({
  selector: 'lib-login-register-logout',
  templateUrl: './login-register-logout.component.html',
  styleUrls: ['./login-register-logout.component.scss']
})
export class LoginRegisterLogoutComponent implements OnInit, AfterContentChecked {

  @Input() loginRegisterText: string;
  @Output() redirectToLogin = new EventEmitter();

  isMobile = false;
  isAssentlyEnabled = false;
  isLoading = false;
  goToRoute = '/home/new-claim'; // go to this route once SSN no. is found in database

  constructor(
    private layoutService: LayoutService,
    private settingsService: SharedSettingsService,
    public router: Router,
    private ref: ChangeDetectorRef
  ) { }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  ngOnInit(): void {
    this.checkDeviceWidth();
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
    });
  }
  getLoader(loaderValue) {
    this.isLoading = loaderValue;
    if (loaderValue === true) {
      document.getElementById('btnPrimary').style.pointerEvents = 'none';
    } else {
      document.getElementById('btnPrimary').style.pointerEvents = 'all';
    }
   }


  closeAssentlyModal(event) {
    this.isAssentlyEnabled = event;
  }

  navigateToLogin() {
    if (this.settingsService.enableAssently) {
      this.isAssentlyEnabled = true;
    } else {
      this.redirectToLogin.emit();
    }
  }

}
