import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AssentlyService } from '../../../../services/Assently/assently.service';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { ExternalValidationRequest, ExternalValidationResponse } from '../../../models/external-validation-request.model';
import { ExternalValidationService } from '../../../../services/external-validation/external-validation.service';
import { SessionStorageService } from 'angular-web-storage';
import { ErrorState } from '../../../models';

declare let coreid_client: any;
const assentlyVerificationFailedHeading = 'Shared.lblAssentlyVerificationFailedHeading';
const assentlyVerificationFailedMessage = 'Shared.lblAssentlyVerificationFailedMessage';
@Component({
  selector: 'lib-assently-login',
  templateUrl: './assently-login.component.html',
  styleUrls: ['./assently-login.component.scss']
})
export class AssentlyLoginComponent implements OnInit {

  @Input() goToRoute;
  public assentlyVerificationFailedHeadingLabel: string;
  public assentlyVerificationFailedMessageLabel: string;

  @Output() isModalOpen = new EventEmitter<boolean>();
  @Output() isAssentlyModalLoading = new EventEmitter<boolean>();
  constructor(
    private assentlyService: AssentlyService,
    public router: Router,
    public translate: TranslateService,
    public externalValidationService: ExternalValidationService,
    public sessionStorageService: SessionStorageService,
    public sharedSettingsService: SharedSettingsService
  ) { }

  ngOnInit(): void {
    this.isAssentlyModalLoading.emit(true);
    this.gettoken();
    this.applyTranslations();
  }

  private applyTranslations() {
    this.translate.get([assentlyVerificationFailedHeading, assentlyVerificationFailedMessage]).subscribe((res) => {
      this.assentlyVerificationFailedHeadingLabel = res[assentlyVerificationFailedHeading];
      this.assentlyVerificationFailedMessageLabel = res[assentlyVerificationFailedMessage];

    });
  }


  // Assently Code
  async gettoken() {
    const token = await this.GetInitToken();
    await this.DoAssently(token.tokenVal);
  }

  async GetInitToken() {
    const result = await this.assentlyService.gettoken().then(data => {
      return data;
    });
    return result;
  }

  async DoAssently(token: any) {
    coreid_client.init({
      config: {
        allowedEids: ['*'],   // enable all eID providers
        mode: 'auth',         // sets the mode to authentication
        language: 'sv',       // set the client to use swedish language
        provider: 'se-bankid', // default provider to show on start
      },
      token,
      callback: async function(data: any) {
        if (data.success === true) {
          try {
            const value = JSON.stringify(data.token);
            const decryptedData = await this.assentlyService.DecryptResult(value).then(decryptResultData => {
              return decryptResultData;
            });
            await this.assentlyService.getCustomerIdentifier(decryptedData.ssn).then(customerData => {

              if (customerData.customerIdentifier === decryptedData.ssn) {
                coreid_client.close();
                this.isModalOpen.emit(false);
                this.enableScrolling();
                this.sessionStorageService.set('Email', customerData.email);
                this.sessionStorageService.set('customerId', customerData.customerId);

                const request = new ExternalValidationRequest();
                // Enable guest purchase for assently/3rd party sign token
                request.isGuestPurchase = true;
                request.appName = 'claims';
                this.externalValidationService.getExternalValidationStatus(request).subscribe((response: ExternalValidationResponse) => {
                  if (response) {
                    this.sessionStorageService.set('tokenGenerationRedirectUrl', response.tokenGenerationRedirectUrl);
                    // Add 30 minutes in current time and stores it in session storage to check expiry of generated 'id_token'.
                    const currentTime = new Date();
                    localStorage.setItem('idTokenExpiryTime', String(currentTime.setMinutes(currentTime.getMinutes() + 30)));
                    this.sharedSettingsService.previousUrl = this.goToRoute;
                    window.location.href = response.tokenGenerationRedirectUrl;
                  }
                });
              } else if (customerData.customerIdentifier !== decryptedData.ssn) {
                coreid_client.close();
                this.isModalOpen.emit(false);
                this.enableScrolling();
                this.navigateToErrorPage();
              }

            });
          } catch (e) {
            this.isModalOpen.emit(false);
            this.enableScrolling();
            console.log(e);
          }
        } else if (data.type === 'failed') {
          this.isModalOpen.emit(false);
          this.enableScrolling(); // to show the background scroll bar once the assently pop up is gone
        } else if (data.type === 'cancelled') {
          this.isModalOpen.emit(false);
          console.log('user closed the app');
          this.enableScrolling();
        } else if (data.type === 'error') {
          this.isModalOpen.emit(false);
          this.enableScrolling();
        }

      }.bind(this)
    });
    coreid_client.start();
    this.isAssentlyModalLoading.emit(false);
    this.disableScrolling(); //  to hide the background scroll bar once the assently pop up is display
  }

  navigateToErrorPage() {
    const header = this.assentlyVerificationFailedHeadingLabel;
    const messages = this.assentlyVerificationFailedMessageLabel;
    this.router.navigate(['/error-page'], { state: new ErrorState(header, messages) });
  }

  // to enable or disble the background scrolling if assently pop is displayed
  disableScrolling() {
    document.body.style.overflow = 'hidden';
  }
  enableScrolling() {
    document.body.style.overflow = 'auto';
  }


}
