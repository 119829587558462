import { IFieldMapperStrategy } from './field-mapper-strategy';
import { DatePickerFieldMapper } from './date-picker-field-mapper';
import { TextboxFieldMapper } from './textbox-field-mapper';
import { DropDownFieldMapper } from './dropdown-field-mapper';
import { TextAreaFieldMapper } from './textarea-field-mapper';
import { RecaptchaFieldMapper } from './recaptcha-field-mapper';
import { CurrencyboxFieldMapper } from './currencybox-field-mapper';
import { CheckboxFieldMapper } from './checkbox-field-mapper';


export const mappers: IFieldMapperStrategy[] = [
    new DatePickerFieldMapper(),
    new TextboxFieldMapper(),
    new TextAreaFieldMapper(),
    new DropDownFieldMapper(),
    new RecaptchaFieldMapper(),
    new CurrencyboxFieldMapper(),
    new CheckboxFieldMapper()
];
