import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MsalAuthenticationService, PagingService, LoginBaseComponent, SharedSettingsService,
  ExternalValidationRequest, ExternalValidationService
} from 'wtw-ui-components';
import { SessionStorageService } from 'angular-web-storage';
import { SalesSettingsService } from '../../../services/settings/settings.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends LoginBaseComponent {
  public hideSignInText = false;
  constructor(
    route: ActivatedRoute,
    router: Router,
    msalService: MsalAuthenticationService,
    metaTag: Meta,
    sharedSettingsService: SharedSettingsService,
    pagingService: PagingService,
    private sessionStorage: SessionStorageService,
    private externalValidationService: ExternalValidationService,
    private settingsService: SalesSettingsService) {
    super(route, router, msalService, metaTag, sharedSettingsService, pagingService);
    this.hideSignInText = this.settingsService.enableGuestPurchase;
  }

  protected async authenticate() {
    const enableVoucherBasedAccess = this.settingsService.enableVoucherBasedAccess;
    const enableSSOBasedAccess = this.settingsService.enableSSOBasedAccess;
    const enableGuestPurchase = this.settingsService.enableGuestPurchase;
    const extAuthNav = this.settingsService.extAuthNav;

    if (enableVoucherBasedAccess || enableSSOBasedAccess || enableGuestPurchase || extAuthNav) {
      const idTokenExpiryTime = localStorage.getItem('idTokenExpiryTime');

      const currentTime = new Date().getTime();
      // If 'idTokenExpiryTime' is greater then 'currentTime' get 'tokenGenerationRedirectUrl' from backend
      // otherwise use existing 'tokenGenerationRedirectUrl' present in session storage.
      if (idTokenExpiryTime && Number(idTokenExpiryTime) < currentTime) {
        const request = new ExternalValidationRequest();
        request.sponsorId = this.sessionStorage.get('SponsorId');
        request.data = this.settingsService.queryParams;
        const response = await lastValueFrom<any>(this.externalValidationService.getExternalValidationStatus(request))
        window.location.href = response.tokenGenerationRedirectUrl;
      } else {
        const tokenGenerationRedirectUrl = this.sessionStorage.get('tokenGenerationRedirectUrl');
        if (tokenGenerationRedirectUrl) {
          window.location.href = tokenGenerationRedirectUrl;
        }
      }
    } else {
      await super.authenticate();
    }
  }
}
