import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { IQuoteSummary } from '../../../shared/models';
import { QuotationService } from '../../../services/quotation/quotation.service';
import { SessionStorageService } from 'angular-web-storage';
import { QuestionSetService } from 'wtw-ui-components';

@Component({
  selector: 'app-tax-summary',
  templateUrl: './tax-summary.component.html',
  styleUrls: ['./tax-summary.component.scss']
})
export class TaxSummaryComponent {

  @Input() isVisible= true;
  @Input() quoteSummary: IQuoteSummary;
  isSpinner: boolean = false;
  isB2B: boolean = false;

  constructor(
    private quotationService: QuotationService,
    public sessionStorage: SessionStorageService,
    private cdr: ChangeDetectorRef,
    private questionSetService: QuestionSetService
    ) {
    this.isB2B = this.sessionStorage.get('isb2b');
  }

  ngOnChanges() {
    this.isSpinner = false;
    this.cdr.detectChanges();
  }

  reCalculateTax() {
    this.isSpinner = true;
    this.cdr.detectChanges();
    this.quotationService.UpdateRecalculateClickedSubject(true);
    this.quotationService.UpdateRecalculationSubject(true);
    this.questionSetService.markQuoteChangesAsUnsaved(true);
  }
}
