import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { endPoints } from '../../core/app.config';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap, share } from 'rxjs/operators';
import moment from 'moment';
import {
  CommonHttpService, UserProfile, Question, BaseConstantService, SharedSettingsService,
  IAllowableCoverageTerms, MandatoryForCoverages
} from 'wtw-ui-components';
import { AccountService } from '../account/account.service';
import {
  QuoteQuestionPayload, Product, ProductStraplineData, ProductSubjectivityDocuments,
  DocumentWithFileDetails, DocumentWithFileDetailsPayload, ProductTemplate, IGetDeclarationResponse,
  ProductDetails, ConfirmSuccess, ICoverageTermsModel
} from '../../shared/models';
import { Router } from '@angular/router';
import { SalesSettingsService } from '../settings/settings.service';
import { PaymentFrequenciesRequest } from '../../shared/models/payment-frequencies.model';
import { PaymentTermDetails } from '../../shared/models/payment-term-details.model';

@Injectable({
  providedIn: 'root'
})
export class ProductTemplateService {
  quoteQnPayload: QuoteQuestionPayload;
  constructor(
    private router: Router,
    private commonHttpService: CommonHttpService,
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private accountService: AccountService,
    private sharedSettingsService: SharedSettingsService,
    private settingsService: SalesSettingsService
  ) { }

  private apiUrl = endPoints.apiUrl;

  private getB2CHomeProductDetail = this.apiUrl + endPoints.endPointName.getB2CHomeProductDetails;
  private getQuestionSet = this.apiUrl + endPoints.endPointName.getRatingQuestionSet;
  private getQuoteQuestionSetUrl = this.apiUrl + endPoints.endPointName.getQuoteQuestionSet;
  private getProductSubjectivityDocumentsUrl = this.apiUrl + endPoints.endPointName.getProductSubjectivityDocument;
  private getSelectedPaymentFrequenciesForProductUrl = this.apiUrl + endPoints.endPointName.getSelectedPaymentFrequenciesForProduct;
  private getPaymentTermDetailsForProductUrl = this.apiUrl + endPoints.endPointName.getPaymentTermDetailsForProduct;
  private getDocumentWithFileDetailsUrl = this.apiUrl + endPoints.endPointName.getDocumentWithFileDetails;
  private getProductTemplateUrl = this.apiUrl + endPoints.endPointName.getProductTemplate;
  private getPaymentTypeUrl = this.apiUrl + endPoints.endPointName.getPaymentType;
  private getPolicyDeclaration = this.apiUrl + endPoints.endPointName.getDeclaration;
  private getPolicyDocumentsDSign = this.apiUrl + endPoints.endPointName.getPolicyDocumentsDocuSign;
  private getProductTemplatePolicyConfirmationDisplayOptions =
    this.apiUrl + endPoints.endPointName.getProductTemplatePolicyConfirmationDisplayOptions;
  private getProductRatingAndPremiumUrl = this.apiUrl + endPoints.endPointName.getProductRatingAndPremium;
  private getCoverageTerms = this.apiUrl + endPoints.endPointName.getCoverageTerms;
  private getAllowableCoverageTerms = this.apiUrl + endPoints.endPointName.getAllowableCoverageTerms;
  private getAllMandatoryForCoverages = this.apiUrl + endPoints.endPointName.getAllMandatoryForCoverages;
  private getProductTemplateRatingDataUrl = this.apiUrl + endPoints.endPointName.getProductTemplateRatingData;
  // TODO : This API retrieves data based on old B2C Sales application , only few of them are needed in new B2C Sales
  // To be confirmed with Backend team if they could create a wrapper API which sends only what is required

  private productTemplateObservables: { [id: number]: Observable<ProductTemplate>; } = {};
  private homeProductObservables: { [languageId: number]: Observable<ProductDetails>; } = {};

  getProduct(sponsorID: number, productId?: number): Observable<number> {
    if (productId && productId > 0) {
      // Product Specific journey
      return this.getProductTemplate(productId).pipe(map(productTemplate => {
        this.sessionStorageService.set('newOrEditQuoteAllowed', productTemplate.newOrEditQuoteAllowed);
        this.stashProductData(productTemplate);
        // // ** CustomerType can be >> 0-Both, 1-Person, 2-Company **//
        // this.sessionStorageService.set('ProductCustomerType', productTemplate.validCustomerType);
        // this.sessionStorageService.set('ProductName', productTemplate.productTemplateName);
        // this.sessionStorageService.set('isMasterProduct', productTemplate.isMasterProduct);
        // this.sessionStorageService.set('IsDDBeforeESignature', productTemplate.isDDBeforeESignature);
        // this.sessionStorageService.set('isB2cMultiCarrierQuotingEnabled', productTemplate.isB2cMultiCarrierQuotingEnabled);
        // this.sessionStorageService.set(BaseConstantService.Key_DefaultCoverageTerm, productTemplate.defaultCoverageTerm);
        // this.sessionStorageService.set(BaseConstantService.Key_IsATPDocumentDownloadForMCI, productTemplate.allowDocGenerationForMCI);
        // this.sessionStorageService.set('IsESignatureEnabled', productTemplate.isESignatureEnabled);

        return productTemplate.productTemplateID;
      }));
    }
    // Sponsor Specific journey
    return this.getB2CHomeProductDetails(sponsorID, this.sessionStorageService.get('LanguageId')).pipe(map(productDetails => {
      const result = productDetails.productList[0];
      if (result !== null && result !== undefined) {
        this.sessionStorageService.set('ProductId', result.productID);
        this.sessionStorageService.set('ProductName', result.productname);
        this.getProductTemplate(result.productID).subscribe(productTemplate => {
          this.stashProductData(productTemplate);
        })
        return result.productID;
      }
    }));
  }

  getProductTemplate(templateId: number): Observable<ProductTemplate> {
    // Caching as queried in multiple places.
    if (!this.productTemplateObservables[templateId]) {
      const apiURL = this.getProductTemplateUrl;
      this.productTemplateObservables[templateId] = this.commonHttpService.httpGetServiceWithBearer(apiURL + '/' + templateId).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }
    return this.productTemplateObservables[templateId];
  }

  getProductTemplateRatingResult(quoteId: any, productTemplateId: any, languageId: any) {
    const apiURL = `${this.getProductTemplateRatingDataUrl}/${quoteId}/${productTemplateId}/${languageId}`;
    return this.commonHttpService.httpGetServiceWithBearer(apiURL);
  }
  getProductCustomerType(templateId) {
    const apiURL = this.getProductTemplateUrl;
    const res = this.commonHttpService.httpGetServiceWithBearer(apiURL + '/' + templateId)
    return res
  }

  getProductRatingAndPremiumAsync(templateId) {
    const apiURL = this.getProductRatingAndPremiumUrl;
    return this.commonHttpService.httpGetService(apiURL + '/' + templateId)

  }

  getPaymentType(productId: number): any {
    const apiURL = `${this.getPaymentTypeUrl}${productId}`;
    return this.commonHttpService.httpGetServiceWithBearer(apiURL);
  }

  getB2CHomeProductDetails(sponsorID: number, languageId: number): Observable<ProductDetails> {
    if (sponsorID > 0 && languageId > 0) {
      if (!this.homeProductObservables[languageId]) {
        const apiURL = `${this.getB2CHomeProductDetail}${sponsorID}/${languageId}`;
        // Caching as queried in multiple places.
        this.homeProductObservables[languageId] = this.commonHttpService.httpGetService(apiURL).pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: true,
          }))
      }
    }

    return this.homeProductObservables[languageId];
  }

  getB2CHomeProductDetailsByUserId(sponsorID: number, userId: string, languageId: number): Observable<ProductDetails> {
    if (!this.homeProductObservables[languageId]) {
      const apiURL = `${this.getB2CHomeProductDetail}${sponsorID}/${userId}/${languageId}`;
      // Caching as queried in multiple places.
      this.homeProductObservables[languageId] = this.commonHttpService.httpGetService(apiURL).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }
    return this.homeProductObservables[languageId];
  }

  getQuoteQuestionSet(requestBody: QuoteQuestionPayload): Observable<Question[]> {
    const apiURL = this.getQuoteQuestionSetUrl;
    return this.commonHttpService.httpPostService(apiURL, requestBody);
  }

  /* Temporary service to get quoute question by mock data json file */
  getQuoteQuestionSetByMockData(): Observable<any> {
    return this.http.get('../../assets/mockMetaDataQuestionLogic.json');
  }

  /* get quoute question set by product id which is usedin resolver */
  getQuoteQuestionSetByProductId(productId: number): Observable<Question[]> {
    this.quoteQnPayload = {
      productTemplateId: productId,
      timeZone: this.sessionStorageService.get('timeZone'),
      languageId: this.sessionStorageService.get('LanguageId')
    };
    // return this.getQuoteQuestionSetByMockData();
    return this.getQuoteQuestionSet(this.quoteQnPayload);
  }

  getProductSubjectivityDocuments(params: number, sponsorId: number, languageId: number): Observable<ProductSubjectivityDocuments> {
    const url: string = this.getProductSubjectivityDocumentsUrl + '/' + params + '/' + sponsorId + '/' + languageId;
    return this.commonHttpService.httpGetService(url);
  }

  getSelectedPaymentFrequenciesForProduct(productId: number, languageId: number): Observable<PaymentFrequenciesRequest> {
    const url: string = this.getSelectedPaymentFrequenciesForProductUrl + '/' + productId + '/' + languageId;
    return this.commonHttpService.httpGetService(url);
  }

  getPaymentTermDetailsForProduct(productId: number, languageId: number): Observable<PaymentTermDetails> {
    const url: string = this.getPaymentTermDetailsForProductUrl + '/' + productId + '/' + languageId;
    return this.commonHttpService.httpGetService(url);
  }

  getDocumentWithFileDetails(requestBody: DocumentWithFileDetailsPayload): Observable<DocumentWithFileDetails> {
    const apiURL = this.getDocumentWithFileDetailsUrl;
    return this.commonHttpService.httpPostService(apiURL, requestBody);
  }

  getDeclaration(): Observable<IGetDeclarationResponse> {
    return this.accountService.getUserProfile().pipe(mergeMap((userProfileDetails: UserProfile) => {
      const apiURL = this.getPolicyDeclaration;
      // Get 'currentDeclarationOrder' from session storage.
      let currentDeclarationOrder = this.sessionStorageService.get('currentDeclarationOrder');
      // Check if customer coming in forward direction or backward direction on declaration page.
      const currentNavigation = this.router.getCurrentNavigation();
      const queryParam = currentNavigation?.extras;
      let isForwardNavigationForDeclaration = false;
      // if forward direction then remove 'currentDeclarationOrder' and
      // 'eSignedDocumentList' from session so that document can be signed
      // again in case of changes in quote / policy questions.
      if (queryParam && queryParam.state && queryParam.state.isForwardNavigation === true) {
        isForwardNavigationForDeclaration = true;
        this.sessionStorageService.remove('currentDeclarationOrder');
        this.sessionStorageService.remove('eSignedDocumentList');
        currentDeclarationOrder = null;
      }
      const reqObj = {
        ProductID: this.sessionStorageService.get('ProductId'),
        CurrentDateTime: new Date().toDateString(),
        PolicyId: this.sessionStorageService.get('PolicyId'),
        SponsorId: this.sessionStorageService.get('SponsorId'),
        LanguageId: this.sessionStorageService.get('LanguageId'),
        CustomerId: userProfileDetails.customerId,
        isForwardNavigation: isForwardNavigationForDeclaration,
        displayOrder: currentDeclarationOrder
      };
      return this.commonHttpService.httpPostServiceWithBearer(apiURL, reqObj);
    }));
  }

  getPolicyDocumentsDocuSign(): Observable<IGetDeclarationResponse> {
    return this.accountService.getUserProfile().pipe(mergeMap((userProfileDetails: UserProfile) => {
      const apiURL = this.getPolicyDocumentsDSign;
      const reqObj = {
        ProductID: this.sessionStorageService.get('ProductId'),
        CurrentDateTime: new Date().toDateString(),
        PolicyId: this.sessionStorageService.get('PolicyId'),
        SponsorId: this.sessionStorageService.get('SponsorId'),
        LanguageId: this.sessionStorageService.get('LanguageId'),
        CustomerId: userProfileDetails.customerId,
      };
      return this.commonHttpService.httpPostServiceWithBearer(apiURL, reqObj);
    }));
  }
  /* Temporary until get admin module and replace with that API */
  /* getProductStrapline(): Observable<ProductStraplineData> {
    return this.getProductStraplineByMockData();
  } */

  getPolicyConfirmationDisplayOptions(productId: number): Observable<ConfirmSuccess> {
    return this.commonHttpService.httpGetServiceWithBearer(this.getProductTemplatePolicyConfirmationDisplayOptions + '/' + productId)
      .pipe(map(res => {
        return res;
      }));
  }

  private stashProductData(product: ProductTemplate) {
    // Using inherent session storage as no dependency injection avaiable when retrieving values.
    sessionStorage.setItem('isExpiryDateasInceptionDate', String(product.isExpiryDateasInceptionDate));
    sessionStorage.setItem('policyTerm', String(product.policyTerm));
    sessionStorage.setItem('isB2cSingleCarrierMultiQuoteEnabled', String(product.isB2cSingleCarrierMultiQuoteEnabled));
    sessionStorage.setItem('isSuppressQuoteReview', String(product.isSuppressQuoteReview));
    sessionStorage.setItem('isExpiryDateThrougRating', String(product.isCalculatedThroughRating));
    sessionStorage.setItem('isUnderMasterPolicy', String(product.isUnderMasterPolicy));
    sessionStorage.setItem('isTermsAndConditionsAcceptanceRequiredToQuote', String(product.isTermsAndConditionsAcceptanceRequiredToQuote));

    // ** CustomerType can be >> 0-Both, 1-Person, 2-Company **//
    this.sharedSettingsService.productCustomerType = product.validCustomerType;
    this.sharedSettingsService.isMasterProduct = product.isMasterProduct;
    this.sharedSettingsService.isUnderMasterPolicy = product.isUnderMasterPolicy;
    this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled = product.isB2cMultiCarrierQuotingEnabled;
    this.sessionStorageService.set(BaseConstantService.Key_IsATPDocumentDownloadForMCI, product.allowDocGenerationForMCI)
    this.sharedSettingsService.isDefaultListViewForMCI = product.isDefaultListView;

    this.sharedSettingsService.productName = product.productTemplateName;
    this.sharedSettingsService.isDDBeforeESignature = product.isDDBeforeESignature;
    this.sharedSettingsService.defaultCoverageTerm = product.defaultCoverageTerm;
    this.sharedSettingsService.isESignatureEnabled = product.isESignatureEnabled;
    this.sharedSettingsService.addPremiumOfMandatoryAndPreSelectedCoverages = product.addPremiumOfMandatoryAndPreSelectedCoverages;
    this.sharedSettingsService.editPrimaryCoveargeTermOnSecondaryCoveragePage = product.editPrimaryCoveargeTermOnSecondaryCoveragePage;
    this.sharedSettingsService.currencyIso4217 = product.currencyIso4217;
    this.sharedSettingsService.setAlternateViewOfSecondaryCoveragePage = product.alternateViewOfSecondaryCover;
    this.sharedSettingsService.setClearSelectionOnSecondaryCoverage = product.clearSelectionOnSecondaryCoverages;
    this.sharedSettingsService.cultureName = product.cultureName;
    if (product.endDate) {
      this.sharedSettingsService.productValidityEndDate = moment(product.endDate, 'YYYY-MM-DD');
    }
  }

  getCoverageTerm(productId: number): Observable<ICoverageTermsModel> {
    return this.commonHttpService.httpGetServiceWithBearer(this.getCoverageTerms + '/' + productId)
      .pipe(map(res => {
        return res;
      }));
  }
  getAllowableCoverageTerm(productId: number): Observable<IAllowableCoverageTerms> {
    return this.commonHttpService.httpGetServiceWithBearer(this.getAllowableCoverageTerms + '/' + productId)
      .pipe(map(res => {
        return res;
      }));
  }

  getAllMandatoryForCoverage(mandatoryForCoverages: MandatoryForCoverages): Observable<any> {
    const apiURL = this.getAllMandatoryForCoverages;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, mandatoryForCoverages);
  }
}
