import { FormFieldBase } from './form-field-base';

export class TextAreaField extends FormFieldBase<string> {
  controlType = 'textarea';
  minLength: number;
  maxLength: number;
  rows: number;
  questionFormat: string;

  constructor(options: {
    value?: string,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    type?: string,
    readOnly?: boolean,
    iconName?: string,
    mandatoryValidationMsg?: string | string[],
    callToActionLabel?: string,
    validatorMatchWith?: string,
    minLength?: number,
    maxLength?: number,
    minRange?: number,
    maxRange?: number,
    isVisible?: boolean,
    questionFormat?: string;
  } = {}) {
    super(options);
    this.rows = options['rows'] || 3;
    this.minLength = options.minLength;
    this.maxLength = options.maxLength;
    this.questionFormat = options.questionFormat;
  }
}
