import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TextboxFieldMapper } from '../field-mapper-strategy/strategies/textbox-field-mapper';
import { Question } from '../../../models';
import { TextboxField } from '../../../form-field/textbox-field';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';

const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
const emailErrorMessageLabel = 'Shared.lblInvalidEmail';
const emailCompareErrorMessageLabel = 'Shared.lblEmailCompareErrorMessage';
const minLengthErrorMessageLabel = 'Shared.lblMinLengthErrorMessage';
const maxLengthErrorMessageLabel = 'Shared.lblMaxLengthErrorMessage';
const invalidValueErrorMessageLabel = 'Shared.lblInvalidValue';

@Component({
  selector: 'lib-text-data-capture',
  templateUrl: './text-data-capture.component.html',
  styleUrls: ['./text-data-capture.component.scss']
})
export class TextDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Output() updateEmitter = new EventEmitter();
  @Input() nextBtn: string;
  textboxForm: FormGroup;
  field: TextboxField;
  mandatoryErrorMessageText: string;
  emailErrorMessageText: string;
  emailCompareErrorMessageText: string;
  minLengthErrorMessage: string;
  maxLengthErrorMessage: string;
  invalidValueErrorMessage: string;
  constructor(
    private formGroupService: FormGroupControlService,
    private fieldMapper: TextboxFieldMapper,
    private translate: TranslateService) { }

  ngOnInit() {

    this.applyTranslations();
    this.createFormGroup();

  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, [
      this.mandatoryErrorMessageText,
      this.emailErrorMessageText,
      '',
      this.emailCompareErrorMessageText,
      this.minLengthErrorMessage,
      this.maxLengthErrorMessage,
      '',
      '',
      '',
      this.invalidValueErrorMessage]);
    this.textboxForm = this.formGroupService.toFormGroup([this.field]);

  }


  submitTextAnswer(answeredData): void {
    const val = this.textboxForm.get(this.field.key).value;
    answeredData.name = val;
    answeredData.isAnswered = true;
    this.updateEmitter.emit();
  }

  private applyTranslations() {
    this.translate.get([
      mandatoryErrorMessageLabel,
      emailErrorMessageLabel,
      emailCompareErrorMessageLabel,
      minLengthErrorMessageLabel,
      maxLengthErrorMessageLabel,
      invalidValueErrorMessageLabel]).subscribe((res) => {
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
        this.emailErrorMessageText = res[emailErrorMessageLabel];
        this.emailCompareErrorMessageText = res[emailCompareErrorMessageLabel];
        this.minLengthErrorMessage = res[minLengthErrorMessageLabel];
        this.maxLengthErrorMessage = res[maxLengthErrorMessageLabel];
        this.invalidValueErrorMessage = res[invalidValueErrorMessageLabel];
      });
  }
}
