<div [formGroup]="form">
    <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-readonly':field.readOnly, 'textarea-container-medium': !isSmallView}" class="textarea-container">
        <mat-label [innerHTML]="field.label | safeHtml"></mat-label>
        <!-- <ng-container *ngIf="field.cdkTextareaAutosize">
            <textarea matInput formControlName="{{field.key}}" [id]="field.key" [readonly]="field.readOnly"
                cdkTextareaAutosize="{{field.cdkTextareaAutosize}}" cdkAutosizeMinRows="{{field.cdkAutosizeMinRows}}"
                cdkAutosizeMaxRows="{{field.cdkAutosizeMaxRows}}">{{field.value}}</textarea>
        </ng-container> -->
        <!-- <ng-container *ngIf="!field.cdkTextareaAutosize"> -->
            <textarea matInput formControlName="{{field.key}}" [id]="field.key"
                [readonly]="field.readOnly" rows="{{field.rows}}">{{field.value}}</textarea>
        <!-- </ng-container> -->
        <mat-error id="matError">{{getSpecificErrorMessage()}}</mat-error>

    </mat-form-field>
</div>
