import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { PolicyService } from '../../../services/policy/policy.service';
import { lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class YourPoliciesResolver  {

  private customerId: number;

  constructor(
    private policyService: PolicyService,
    private sessionStorageService: SessionStorageService) { }

  async resolve(route: ActivatedRouteSnapshot) {
    const customerid = this.sessionStorageService.get('customerId') ? this.sessionStorageService.get('customerId') : 0;
    const yourpolicyList = await lastValueFrom<any>(this.policyService.yourPolicies(customerid));
    return yourpolicyList;
  }
}
