<div id="link-layout" *ngIf="!isMobile" (click)="navigateToLogin()">
    {{loginRegisterText}}
</div>

<ng-container *ngIf="isMobile">    
    <button mat-menu-item class="active" (click)="navigateToLogin()">
        {{loginRegisterText}}
      </button>
</ng-container>

 <!-- to display the assently login pop up if enable assently switch is on -->
<lib-assently-login *ngIf="isAssentlyEnabled" [goToRoute]="goToRoute" (isModalOpen)="closeAssentlyModal($event)" (isAssentlyModalLoading)="getLoader($event)"></lib-assently-login>

<lib-progress-spinner *ngIf="isLoading"></lib-progress-spinner>
