import { Injectable } from '@angular/core';

import { SponsorService } from '../../../services/sponsor/sponsor.service';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
    providedIn: 'root'
})
export class SponsorLanguageResolver  {
    constructor(
        private sponsorService: SponsorService,
        private sessionStorage: SessionStorageService) {}

    resolve()  {
        const sponsorID: number = this.sessionStorage.get('SponsorId');
        if (sponsorID) {
            return this.sponsorService.initialiseSponsorPreferredLanguage(sponsorID);
        }
    }
}
