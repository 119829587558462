import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CardButtonBase } from './card-button-base';
import { MenuParams } from './card-button-base';

const pageLabels = {
  newClaimRemoveTxt: 'Claim.lblNewClaimRemoveTxt',
  newClaimViewTxt: 'Claim.lblNewClaimViewTxt',
  removeTxt: 'Shared.lblRemoveTxt',
  viewTxt: 'Shared.lblViewTxt'
};


@Component({
  selector: 'lib-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.scss']
})
export class CardButtonComponent implements OnInit {
  @Input() cardInput: CardButtonBase<string>;
  @Output() emitMenuAction = new EventEmitter();
  @Output() emitHeaderClickAction = new EventEmitter();
  @Input() isLast: boolean;
  @Input() type: string;
  public labels = {
    newClaimRemoveTxt: '',
    newClaimViewTxt: '',
    newSalesRemoveTxt: '',
    newSalesViewTxt: ''
  };
  constructor(private readonly translate: TranslateService) { }

  ngOnInit() {
    this.applyTranslation();
  }

  public menuClicked(item: MenuParams): void {
    this.emitMenuAction.emit(item);
  }
  public emitHeaderClicked(item: CardButtonBase<string>): void {
    item.menuInfo[0].menuLabel === 'remove' ? item.menuInfo[0].menuLabel = this.type === "sales" ?
      this.labels.newSalesRemoveTxt : this.labels.newClaimRemoveTxt
    : item.menuInfo[0].menuLabel = item.menuInfo[0].menuLabel;
    item.menuInfo[1].menuLabel === 'view' ? item.menuInfo[1].menuLabel = this.type === "sales" ?
      this.labels.newSalesViewTxt : this.labels.newClaimViewTxt
    : item.menuInfo[1].menuLabel = item.menuInfo[1].menuLabel;
    this.emitHeaderClickAction.emit(item);
  }
  applyTranslation() {
    const { newClaimRemoveTxt,
            newClaimViewTxt, removeTxt, viewTxt
    } = pageLabels;

    this.translate.get([newClaimRemoveTxt, newClaimViewTxt, removeTxt, viewTxt ])
      .subscribe((res) => {
        this.labels.newClaimRemoveTxt = res[newClaimRemoveTxt];
        this.labels.newClaimViewTxt = res[newClaimViewTxt];
        this.labels.newSalesRemoveTxt = res[removeTxt];
        this.labels.newSalesViewTxt = res[viewTxt]
      });
  }
}
