import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DateManipulationService {
    applyDateOffset(offsetValue: string, anchorDate: Date | moment.Moment, timeZoneOffset: string): moment.Moment {
        if (!offsetValue) {
            return null;
        } else if (Number(offsetValue) === 0) {
            return this.getMomentDatewithUtcOffset(timeZoneOffset);
        }
        const dateOffsetComponents = offsetValue && offsetValue.split(',');
        return dateOffsetComponents ? this.applyDateChange(anchorDate, dateOffsetComponents[0], dateOffsetComponents[1]) : null;
    }

    getMomentDatewithUtcOffset(timeZoneOffset?: string): moment.Moment {
        return timeZoneOffset ? moment(new Date()).utcOffset(timeZoneOffset) : moment(new Date());
    }

    getMomentDateWithUtc(anchorDate: Date) {
      return this.applyDateChange(anchorDate, "", "");
    }

    private applyDateChange(anchorDate: Date | moment.Moment, unit: string, delta: string): moment.Moment {
        const duration = delta as moment.DurationInputArg2;
        return moment(moment(anchorDate).add(Number(unit), duration));
    }
}
