import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tile } from '../../../models';

@Component({
  selector: 'lib-tile-list',
  templateUrl: './tile-list.component.html',
  styleUrls: ['./tile-list.component.scss']
})
export class TileListComponent implements OnInit {

  @Input() tiles: Tile[] = [];
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() linkClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  public onLinkClick(tag: any): void {
    this.linkClicked.emit(tag);
  }

  public onButtonClick(tag: any): void {
    this.buttonClicked.emit(tag);
  }
}
