import { FormFieldBase } from './form-field-base';

export class CurrencyBoxField extends FormFieldBase<string> {
  controlType = 'money';
  validatorMatchWith: string;
  minLength: number;
  maxLength: number;
  minRange: number;
  maxRange: number;
  questionFormat: string;
  regexValidation: string;
  correctFormatValidationMsg: string;


  constructor(options: {
    value?: string,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    type?: string,
    readOnly?: boolean,
    iconName?: string,
    mandatoryValidationMsg?: string | string[],
    callToActionLabel?: string,
    validatorMatchWith?: string,
    minLength?: number,
    maxLength?: number,
    minRange?: number,
    maxRange?: number,
    isVisible?: boolean,
    questionFormat?: string,
    regexValidation?: string,
    correctFormatValidationMsg?: string


  } = {}) {
    super(options);
    this.validatorMatchWith = options['validatorMatchWith'] || '';
    this.minLength = options.minLength;
    this.maxLength = options.maxLength;
    this.minRange = options.minRange;
    this.maxRange = options.maxRange;
    this.questionFormat = options.questionFormat;
    this.regexValidation = options.regexValidation;
    this.correctFormatValidationMsg = options.correctFormatValidationMsg;
  }
}
