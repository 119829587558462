import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


const stepLabel = 'Shared.lblNewClaimStep';
const stepOfLabel = 'Shared.lblNewClaimStepOf';
@Component({
   selector: 'lib-progress-bar-steps',
   templateUrl: './progress-bar-steps.component.html',
   styleUrls: ['./progress-bar-steps.component.scss']
})
export class ProgressBarStepsComponent implements OnInit {

   stepText: string;
   stepOfText: string;

   @Input() progress: number;
   @Input() total: number;
   @Input() stepName: string;
   Arr = Array;

   constructor( private translate: TranslateService) { }
   ngOnInit() {
      this.applyTranslations();

   }

   private applyTranslations() {
      this.translate.get([stepLabel, stepOfLabel]).subscribe((res) => {
        this.stepText = res[stepLabel];
        this.stepOfText = res[stepOfLabel];

      });
    }
}
