<div class="container-fluid p-0">
  <div class="row header-background-color">
    <div class="col-12 d-flex">
      <div class="d-flex align-items-center">
        <div id="divSponsorLogo" [ngClass]="(isMobile || isTablet)?'logo-container':'logo-container-desktop'">
          <img id="imgSponsorLogo" class="branding-logo mx-auto" [src]="sponsorImage">
        </div>
        <div *ngIf="isDesktop">
          <div id="navbarLinks">
            <ng-container *ngFor="let labelObject of topbarLabellist">
              <button mat-button (click)="navigateNavLink(labelObject.navLink)"
                [class.active]="isActive(labelObject.navLink)">{{labelObject.label}}</button>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="ml-auto d-flex align-items-center" id="navbarOtherOptions">
        <span *ngIf="!showHomeIcon" class="material-icons" (click)="navigateToMultiProductLandingPage()">home</span>
        <ng-container *ngIf=" isTablet || isDesktop">
          <ng-container *ngIf="langSelection">
            <lib-topbar-dropdown class="topbarDropDown language-center" [profileMenuItems]="profileMenuItems"
                                 id="topbarDropDown" (navigateLink)="navigateNavLink($event)">
            </lib-topbar-dropdown>
          </ng-container>
          <ng-container *ngIf="loginRegisterText && showLoginRegisterLogoutButton">
            <lib-login-register-logout class="label-center" [loginRegisterText]="loginRegisterText"
                                       (redirectToLogin)="navigateToLogin()">
            </lib-login-register-logout>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isMobile || isTablet || isDesktop">
          <ng-container *ngIf="loginRegisterText && !showLoginRegisterLogoutButton">
            <lib-login-register-logout class="label-center" [loginRegisterText]="loginRegisterText">
            </lib-login-register-logout>
            <button id="btnNext" class="btn btn-md btn-block btn-top-bar" (click)="closeCurrentTab()">
              {{closeButtonText}}
            </button>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="isMobile || isTablet">
          <div class="d-flex flex-row-reverse h-100">
            <div class="align-self-center hamburger-center">
              <mat-icon [matMenuTriggerFor]="mobilemenu" (menuOpened)="mobileMenuOpen()"
                        (menuClosed)="mobileMenuClose()">{{toggleMenuIcon?'close':'menu'}}</mat-icon>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<mat-menu #mobilemenu="matMenu" class="menu-screen">
  <ng-container *ngFor="let labelObject of topbarLabellist">
    <div class="mat-menu-item-container">
      <button mat-button (click)="navigateNavLink(labelObject.navLink)"
        [class.active]="isActive(labelObject.navLink)">{{labelObject.label}}</button>
    </div>
  </ng-container>
  <ng-container *ngIf="langSelection">
    <div class="mat-menu-item-container">
      <lib-topbar-dropdown [profileMenuItems]="profileMenuItems" id="topbarDropDown"
        (navigateLink)="navigateNavLink($event)">
      </lib-topbar-dropdown>
    </div>
  </ng-container>
  <ng-container *ngIf="loginRegisterText && showLoginRegisterLogoutButton">
    <div class="mat-menu-item-container">
      <lib-login-register-logout [loginRegisterText]="loginRegisterText" (redirectToLogin)="navigateToLogin()">
      </lib-login-register-logout>
    </div>
  </ng-container>
</mat-menu>
