import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TextboxField } from '../../../form-field/textbox-field';
import { FormGroup } from '@angular/forms';
import { LayoutService } from '../../../../services/layout/layout.service';
import { CurrencyFormatPipe } from '../../../../shared/pipes/currency-format.pipe';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service'


@Component({
  selector: 'lib-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent implements OnInit {
  @Input() field: TextboxField;
  @Input() form: FormGroup;
  @Output() cToAction = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  public isSmallView: boolean;
  public mandatoryErrorMessage: string;
  public emailErrorMessage: string;
  public validEmailErrorMessage: string;
  public confirmEmailErrorMessage: string;
  public minLengthErrorMessage: string;
  public maxLengthErrorMessage: string;
  public minRangeErrorMessage: string;
  public maxRangeErrorMessage: string;
  public numericErrorMessage: string;
  public invalidValueErrorMessage: string;
  public showTooltip = false;
  public maxLength: number;
  public correctFormatValidationMsg: string;
  public isAutoSuggestionVisible: boolean;

  constructor(private layoutService: LayoutService,
              private currencyPipe: CurrencyFormatPipe,
              private sharedSettingsService: SharedSettingsService) { }

  get formControl(): any {
    return this.form.controls[this.field.key];
  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  ngOnInit() {
    if (Array.isArray(this.field.mandatoryValidationMsg)) {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg[0];
      this.emailErrorMessage = this.field.mandatoryValidationMsg[1];
      this.validEmailErrorMessage = this.field.mandatoryValidationMsg[2];
      this.confirmEmailErrorMessage = this.field.mandatoryValidationMsg[3];
      this.minLengthErrorMessage = this.field.mandatoryValidationMsg[4];
      this.maxLengthErrorMessage = this.field.mandatoryValidationMsg[5];
      this.minRangeErrorMessage = this.field.mandatoryValidationMsg[6];
      this.maxRangeErrorMessage = this.field.mandatoryValidationMsg[7];
      this.numericErrorMessage = this.field.mandatoryValidationMsg[8];
      this.invalidValueErrorMessage = this.field.mandatoryValidationMsg[9];
    } else {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg;
      this.correctFormatValidationMsg = this.field.correctFormatValidationMsg;
    }

    if (this.field.key === 'email') {
      this.showTooltip = true;
    }
    if (this.field.label === undefined || this.field.label === null) {
      this.field.label = '';
    }
    this.isAutoSuggestionVisible = this.field?.globalFunction?.toLowerCase()?.includes('autosuggestion') ? true : false;
  }

  callToActionOnClick() {
    this.cToAction.emit();
  }

  onBlurEvent(event, data) {
    // if in QS metadata localization is true then currency will be visible
    // in local curreny format.
    if (data.localization && event.target.value?.length > 0) {
      this.convertToLocaliseCurrency(event);
    }
  }

  valueChangeOnKeyPressed(event, data) {
    if (event instanceof PointerEvent) {
       // triggered from button click
      const target = event.target as HTMLInputElement;
      target.value = this.form.controls[data.key].value;
    }

    if (data.localization && event.target.value?.length > 0) {
      this.convertToLocaliseCurrency(event);
    }

    event['tagName'] = "text";
    event['id'] = data.key;
    event['qIntentCode'] = data?.qIntentCode;
    if (data.globalFunction !== null && data.globalFunction !== "") {
      event['data'] = data;
    }
    this.valueChange.emit(event);
  }

  getSpecificErrorMessage() {
    if (this.formControl.hasError('required')) {
      return this.mandatoryErrorMessage;
    }
    if ((this.formControl.hasError('pattern')) && (this.field.questionFormat?.toLowerCase() === 'text') && (this.field?.type === 'email')) {
      return this.validEmailErrorMessage;
    }
    if ((this.formControl.hasError('pattern')) && (this.field.questionFormat?.toLowerCase() === 'text')
      && ((this.field.regexValidation !== null) || (this.field.regexValidation !== undefined))) {
      return this.invalidValueErrorMessage;
    }
    if ((this.formControl.hasError('pattern')) && (this.field.questionFormat?.toLowerCase() === 'numeric')) {
      return this.numericErrorMessage;
    }
    if ((this.formControl.hasError('min')) && (this.field.questionFormat.toLowerCase() === 'numeric')) {
      return this.minRangeErrorMessage;
    }
    if ((this.formControl.hasError('max')) && (this.field.questionFormat.toLowerCase() === 'numeric')) {
      return this.maxRangeErrorMessage;
    }
    if ((this.formControl.hasError('minlength')) && (this.field?.questionFormat?.toLowerCase() === 'numeric')) {
      return this.minLengthErrorMessage;
    }
    if ((this.formControl.hasError('maxlength')) && (this.field?.questionFormat?.toLowerCase() === 'numeric')) {
      return this.maxLengthErrorMessage;
    }
    if (this.formControl.hasError('email')) {
      return this.emailErrorMessage;
    }
    if (this.formControl.hasError('notMatch')) {
      return this.confirmEmailErrorMessage;
    }
    if ((this.formControl.hasError('minlength')) && (this.field?.questionFormat?.toLowerCase() === 'text')) {
      return this.minLengthErrorMessage;
    }
    if ((this.formControl.hasError('maxlength')) && (this.field?.questionFormat?.toLowerCase() === 'text')) {
      return this.maxLengthErrorMessage;
    }
    if ((this.formControl.hasError('pattern')) && ((this.field.regexValidation !== null) || (this.field.regexValidation !== undefined))) {
      return this.correctFormatValidationMsg;
    }

  }

  convertToLocaliseCurrency(event) {
    const formattedValue = this.sharedSettingsService.reverseFormatNumber(event.target.value);
    if (this.formControl.valid) {
      const localizedFormat = this.currencyPipe.transform(formattedValue)
      event.target.value = localizedFormat;
      this.form.get(this.field.key).setValue(localizedFormat);
    }
  }

}
