import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { ExternalValidationResponse, ICustomerFieldValidations, IterationData, Policy } from '../../shared/models';
import { BaseConstantService } from '../../services/constant/base-constant.service';
import { Question } from '../../shared/models/question.model';
import { BehaviorSubject, Observable } from 'rxjs';

export const QueryParamsKey = 'QueryParams';
export const LanguageIdKey = 'LanguageId';
export const LanguageCodeKey = 'LanguageCode';
export const SponsorNameKey = 'SponsorName';
export const SponsorIdKey = 'SponsorId';
export const ProductIdKey = 'ProductId';
export const TimeZoneKey = 'timeZone';
export const TimeZoneOffsetKey = 'timeZoneOffset';
export const CurrentUrlKey = 'currentUrl';
export const PreviousUrlKey = 'previousUrl';
export const EnableVoucherBasedAccessKey = 'EnableVoucherBasedAccess';
export const EnableSSOBasedAccessKey = 'EnableSSOBasedAccess';
export const EnableMFA = 'EnableMFA';
export const EnableICECommonAuth = 'EnableICECommonAuth';
export const RecaptchaVerified = 'RecaptchaVerified';
export const ExtAuthNavKey = 'ExtAuthNav';
export const EnableGuestPurchaseKey = 'EnableGuestPurchase';
export const EnableSponsorLogoKey = 'EnableSponsorLogo';
export const ExternalValidationIterationDataKey = 'ExternalValidationIterationData';
export const RecaptchaToken = 'RecaptchaToken';
export const RecaptchaCount = 'RecaptchaCount';
export const LoggedInUserKey = 'LoggedInUser';
export const IsMultiProductLanding = 'IsMultiProductLanding';
export const IsMultiProductFindOutMoreLinkClickedKey = 'IsMultiProductFindOutMoreLinkClicked';
export const EnableSponsorSpecificTagsKey = 'EnableSponsorSpecificTags';
export const SelectedPolicyKey = 'SelectedPolicyKey';
export const ATPLoginExpiryTime = 'ATPLoginExpiryTime';
export const CustomerIdKey = 'customerId';
export const IsPreferredLanguageSetKey = 'IsPreferredLanguageSet';
export const EnableAssentlyKey = 'EnableAssently';
export const TimeOutLimit = 30;
export const CompressedViewOfPreviousAnswersKey = 'CompressedViewOfPreviousAnswers';
export const SponsorBrandName = 'SponsorBrandName';
export const newOrEditQuoteAllowed = 'newOrEditQuoteAllowed';
export const customerTypeBoth = 0;
export const customerTypePerson = 1;
export const customerTypeCompany = 2;
export const CustomerFieldsValidations = 'CustomerFieldsValidations';
export const policyHolderDetailsFields = 'policyHolderDetailsFields';
export const IsB2cMultiCarrierQuotingEnabled = 'isB2cMultiCarrierQuotingEnabled';
export const IsB2cSingleCarrierMultiQuoteEnabled = 'isB2cSingleCarrierMultiQuoteEnabled';
export const GTMCodeKey = 'GTMCode';
export const IsLinkToPolicyClicked = 'IsLinkToPolicyClicked';
export const B2BCustomerIdKey = 'B2BCustomerId'; /* CustomerId for whom policy will be created through B2B flow */
export const DefaultCoverageTerm = 'DefaultCoverageTerm';
export const IsESignatureEnabled = 'IsESignatureEnabled';
export const IsDDBeforeESignature = 'IsDDBeforeESignature';
export const ProductName = 'ProductName';
export const IsMasterProduct = 'isMasterProduct';
export const IsUnderMasterPolicy = 'IsUnderMasterPolicy';
export const ProductCustomerType = 'ProductCustomerType';
export const DisplayGrossPremium = 'DisplayGrossPremium';
import moment, { Moment } from 'moment';
export const IsCopyQuoteAtpClient = 'IsCopyQuoteAtpClient';
export const SelectedCoverageTerm = 'SelectedCoverageTerm';
export const PrivacyPolicyData = 'PrivacyPolicyData'
export const AddPremiumOfMandatoryAndPreSelectedCoveragesKey = 'AddPremiumOfMandatoryAndPreSelectedCoveragesKey';
export const HidePolicyQuestionFromB2BProfileonB2CKey = 'HidePolicyQuestionFromB2BProfileonB2CKey'
export const EditPrimaryCoveargeTermOnSecondaryCoveragePageKey = 'EditPrimaryCoveargeTermOnSecondaryCoveragePageKey';
export const CurrencyIso4217 = 'currencyIso4217';
export const AlternateViewOfSecondaryCoveragePageKey = 'AlternateViewOfSecondaryCoveragePageKey';
export const ClearSelectionOnSecondaryCoverageskey = 'ClearSelectionOnSecondaryCoverageskey';
export const IsCopyQuotePolicyQuestion = 'IsCopyQuotePolicyQuestion';
export const NavigateToUserDetails = 'NavigateToUserDetails';
export const DiscountRate = 'DiscountRate';
export const AllowCustomerIdentificationKey = 'AllowCustomerIdentification';
export const EnableCustomerIdentityB2CClaimsKey = 'EnableCustomerIdentityB2CClaims';
export const AppExtValRetryUrl = 'AppExtValRetryUrl';
export const TokenGenerationRedirectUrl = 'TokenGenerationRedirectUrl';
export const ExternalValidationResult = 'ExternalValidationResult';
export const BlockedTimeForCutomerIdentifactionKey = 'BlockedTimeForCutomerIdentifaction';
export const ProductValidityEndDate = 'ProductValidityEndDate';
export const InActivityMinutesForSessionTimeoutKey = 'InActivityMinutesForSessionTimeout';
export const SponsorLanguageCodeKey = 'SponsorLanguageCode'
export const SessionTimeoutCountdown = 'SessionTimeoutCountdown';
export const renewalDeclineComment = 'RenewalDeclineComment';
export const UserSessionKey = 'UserSessionKey';// *** Unique session keys to identify the user's session ***/
export const MinimumCharForAutoSuggestion = 'MinimumCharForAutoSuggestion';
export const CultureName ='cultureName'

@Injectable({
  providedIn: 'root'
})
export class SharedSettingsService {
  private cachedValues: { [key: string]: any; } = {};
  private MCIPopUpText: string;
  private LookUpValues: any[] = [];
  private BaseQuestionData: Question[];
  private quoteDeclineRenewalSubject = new BehaviorSubject(null);
  private _homeTranslationCacheObj: any;

  constructor(private sessionStorageService: SessionStorageService) { }

  public set setBaseQuestionData(value: Question[]) {
    this.BaseQuestionData = value;
  }

  public get getBaseQuestionData(): Question[] {
    return this.BaseQuestionData;
  }

  public set setMCIPopUpText(value: string) {
    this.MCIPopUpText = value;
  }

  public get getMCIPopUpText(): string {
    return this.MCIPopUpText;
  }
  public set setLookUpValues(value: any[]) {
    this.LookUpValues = value;
  }

  public get getLookUpValues(): any[] {
    return this.LookUpValues;
  }

  public set customerFieldsValidationsList(value: ICustomerFieldValidations[]) {
    this.setValue(CustomerFieldsValidations, value);
  }
  public get customerFieldsValidationsList(): ICustomerFieldValidations[] {
    return this.getValue<ICustomerFieldValidations[]>(CustomerFieldsValidations);
  }
  public set policyHolderDetailsFields(value) {
    this.setValue(policyHolderDetailsFields, value);
  }
  public get policyHolderDetailsFields() {
    return this.getValue(policyHolderDetailsFields);
  }

  // Define globally scoped settings here, application scoped settings in relevant subclass.

  public get TimeOutDuration(): number {
    return TimeOutLimit;
  }

  public set isMultiProductLanding(value: boolean) {
    this.setValue(IsMultiProductLanding, value);
  }

  public get isMultiProductLanding(): boolean {
    return this.getValue<boolean>(IsMultiProductLanding);
  }

  public set sponsorBrandName(value: string) {
    this.setValue(SponsorBrandName, value);
  }
  public get sponsorBrandName(): string {
    return this.getValue<string>(SponsorBrandName);
  }
  public set isMultiProductFindOutMoreLinkClicked(value: boolean) {
    this.setValue(IsMultiProductFindOutMoreLinkClickedKey, value);
  }

  public get isMultiProductFindOutMoreLinkClicked(): boolean {
    return this.getValue<boolean>(IsMultiProductFindOutMoreLinkClickedKey);
  }

  // Query Params
  public get queryParams(): { [id: number]: string; } {
    return this.getValue<{ [id: number]: string; }>(QueryParamsKey);
  }

  public set queryParams(value: { [id: number]: string; }) {
    this.setValue(QueryParamsKey, value);
  }

  // Language Id
  public get languageId(): number {
    const languageIdValue = this.getValue<number>(LanguageIdKey);
    if (languageIdValue !== null && languageIdValue !== undefined && languageIdValue > 0) {
      return languageIdValue;
    }
    else {
      return 1;
    }
  }

  public set languageId(value: number) {
    this.setValue(LanguageIdKey, value);
  }

  // Language Code
  public get languageCode(): string {
    return this.getValue<string>(LanguageCodeKey);
  }

  public set languageCode(value: string) {
    this.setValue(LanguageCodeKey, value);
  }

  // EnableAssentlyKey
  public get enableAssently(): boolean {
    return this.getValue<boolean>(EnableAssentlyKey);
  }

  public set enableAssently(value: boolean) {
    this.setValue(EnableAssentlyKey, value);
  }

  // CompressedViewOfPreviousAnswersKey
  public get compressedViewOfPreviousAnswers(): boolean {
    return this.getValue<boolean>(CompressedViewOfPreviousAnswersKey);
  }

  public set compressedViewOfPreviousAnswers(value: boolean) {
    this.setValue(CompressedViewOfPreviousAnswersKey, value);
  }

  public get displayGrossPremium(): boolean {
    return this.getValue(DisplayGrossPremium)?.toString().toLowerCase() === 'true';
  }

  public set displayGrossPremium(value: boolean) {
    this.setValue(DisplayGrossPremium, value.toString());
  }

  // Sponsor Name
  public get sponsorName(): string {
    return this.getValue<string>(SponsorNameKey);
  }

  public set sponsorName(value: string) {
    this.setValue(SponsorNameKey, value);
  }

  // Sponsor ID
  public get sponsorId(): number {
    const sponsorIdValue = this.getValue<number>(SponsorIdKey);
    if (sponsorIdValue !== null && sponsorIdValue !== undefined && sponsorIdValue > 0) {
      return sponsorIdValue;
    }
    else {
      return -1;
    }
  }

  public set sponsorId(value: number) {
    this.setValue(SponsorIdKey, value);
  }

  // Product ID
  public get productId(): number {
    return this.getValue<number>(ProductIdKey);
  }

  public set productId(value: number) {
    this.setValue(ProductIdKey, value);
  }

  // EnableVoucherBasedAccess
  public get enableVoucherBasedAccess(): boolean {
    return this.getValue<boolean>(EnableVoucherBasedAccessKey);
  }

  public set enableVoucherBasedAccess(value: boolean) {
    this.setValue(EnableVoucherBasedAccessKey, value);
  }

  // EnableSSOBasedAccess
  public get enableSSOBasedAccess(): boolean {
    return this.getValue<boolean>(EnableSSOBasedAccessKey);
  }

  public set enableSSOBasedAccess(value: boolean) {
    this.setValue(EnableSSOBasedAccessKey, value);
  }

  // EnableMFA
  public get enableMFA(): boolean {
    return this.getValue<boolean>(EnableMFA);
  }

  public set enableMFA(value: boolean) {
    this.setValue(EnableMFA, value);
  }

  // enableICECommonAuth
  public get enableICECommonAuth(): boolean {
    return this.getValue<boolean>(EnableICECommonAuth);
  }

  public set enableICECommonAuth(value: boolean) {
    this.setValue(EnableICECommonAuth, value);
  }

  // recaptchaVerified
  public get recaptchaVerified(): boolean {
    return this.getValue<boolean>(RecaptchaVerified);
  }

  public set recaptchaVerified(value: boolean) {
    this.setValue(RecaptchaVerified, value);
  }


  // ExtAuthNav
  public get extAuthNav(): boolean {
    return this.getValue<boolean>(ExtAuthNavKey);
  }

  public set extAuthNav(value: boolean) {
    this.setValue(ExtAuthNavKey, value);
  }

  // EnableGuestPurchase
  public get enableGuestPurchase(): boolean {
    return this.getValue<boolean>(EnableGuestPurchaseKey);
  }

  public set enableGuestPurchase(value: boolean) {
    this.setValue(EnableGuestPurchaseKey, value);
  }


  // EnableSponsorSpecificTags
  public get enableSponsorSpecificTags(): boolean {
    return this.getValue<boolean>(EnableSponsorSpecificTagsKey);
  }

  public set enableSponsorSpecificTags(value: boolean) {
    this.setValue(EnableSponsorSpecificTagsKey, value);
  }

  // EnableSponsorLogo
  public get enableSponsorLogo(): boolean {
    return this.getValue<boolean>(EnableSponsorLogoKey);
  }

  public set enableSponsorLogo(value: boolean) {
    this.setValue(EnableSponsorLogoKey, value);
  }

  // Time Zone
  public get timeZone(): string {
    return this.getValue<string>(TimeZoneKey);
  }

  public set timeZone(value: string) {
    this.setValue(TimeZoneKey, value);
  }

  // Time Zone Offset
  public get timeZoneOffset(): string {
    return this.getValue<string>(TimeZoneOffsetKey);
  }

  public set timeZoneOffset(value: string) {
    this.setValue(TimeZoneOffsetKey, value);
  }

  // Current URL
  public get currentUrl(): string {
    return this.getValue<string>(CurrentUrlKey);
  }

  public set currentUrl(value: string) {
    this.setValue(CurrentUrlKey, value);
  }

  // Previous URL
  public get previousUrl(): string {
    return this.getValue<string>(PreviousUrlKey);
  }

  public set previousUrl(value: string) {
    this.setValue(PreviousUrlKey, value);
  }

  // ExternalValidationIterationData
  public get externalValidationIterationData(): IterationData {
    return this.getValue<IterationData>(ExternalValidationIterationDataKey);
  }

  public set externalValidationIterationData(value: IterationData) {
    this.setValue(ExternalValidationIterationDataKey, value);
  }

  // RecaptchaToken
  public get recaptchaToken(): string {
    return this.getValue<string>(RecaptchaToken);
  }

  public set recaptchaToken(value: string) {
    this.setValue(RecaptchaToken, value);
  }

  // Recaptcha Verification Count
  public get recaptchaCount(): number {
    return this.getValue<number>(RecaptchaCount);
  }

  public set recaptchaCount(value: number) {
    this.setValue(RecaptchaCount, value);
  }

  // Get logged in user name
  public get loggedInUser(): string {
    return this.getValue<string>(LoggedInUserKey);
  }

  public set loggedInUser(value: string) {
    this.setValue(LoggedInUserKey, value);
  }

  // Selected policy by user
  public get selectedPolicy(): Policy {
    return this.getValue<Policy>(SelectedPolicyKey);
  }

  public set selectedPolicy(value: Policy) {
    this.setValue(SelectedPolicyKey, value);
  }

  public get ATPLoginExpiryTime(): number {
    return this.getValueFromLocalStorage(ATPLoginExpiryTime);
  }

  public set ATPLoginExpiryTime(value: number) {
    this.setValueToLocalStorage(ATPLoginExpiryTime, value);
  }

  public resetSelectedPolicy() {
    this.selectedPolicy = null;
  }
  // CustomerId
  public get customerId(): number {
    return this.getValue<number>(CustomerIdKey);
  }

  public set customerId(value: number) {
    this.setValue(CustomerIdKey, value);
  }

  public set isPreferredLanguageSet(value: boolean) {
    this.setValue(IsPreferredLanguageSetKey, value);
  }

  public get isPreferredLanguageSet(): boolean {
    return this.getValue<boolean>(IsPreferredLanguageSetKey);
  }

    // Minimum character for auto suggestion
  public get minimumCharForAutoSuggestion(): number {
      return this.getValue<number>(MinimumCharForAutoSuggestion);
    }

  public set minimumCharForAutoSuggestion(value: number) {
      this.setValue(MinimumCharForAutoSuggestion, value);
    }


  // Generic functions
  public remove(key: string) {
    delete this.cachedValues[key];
    this.sessionStorageService.remove(key);
  }

  protected getValue<T>(key: string): T {
    return this.sessionStorageService.get(key) as T;
  }

  protected setValue(key: string, value: any) {
    this.sessionStorageService.set(key, value);
  }

  protected getValueFromLocalStorage(key: string): any {
    return localStorage.getItem(key) as any;
  }

  protected setValueToLocalStorage(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  // New or Edit Quote
  public get newOrEditQuoteAllowed(): boolean {
    return this.getValue<boolean>(newOrEditQuoteAllowed);
  }

  public set newOrEditQuoteAllowed(value: boolean) {
    this.setValue(newOrEditQuoteAllowed, value);
  }

  public get isATPLogin(): boolean {
    const isB2BFlow = this.isB2B();
    const hasGuestToken = window.location.href.indexOf('id_token') > 0 ? true : false;
    return !(this.enableVoucherBasedAccess || this.enableSSOBasedAccess
      || this.enableGuestPurchase || this.enableAssently
      || isB2BFlow || hasGuestToken || this.extAuthNav || this.allowCustomerIdentification);
  }

  public getUrlParameterByName(paramName: string, useCaching: boolean = true) {
    const params = this.getAllParameters(useCaching);
    return params && params[paramName];
  }

  public getAllParameters(useCaching: boolean = true): { [id: string]: string; } {
    const stashedResult = useCaching && this.queryParams;
    if (stashedResult) {
      return stashedResult;
    }
    const params: { [id: number]: string; } = {};
    const url = window.location.href;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      for (const paramName of httpParams.keys()) {
        params[paramName] = httpParams.get(paramName);
      }
      // adding this code for bug 170050 //
      const paramVal = JSON.stringify(params);
      const queryParamsVal = JSON.stringify(this.queryParams);
      if (url.includes('?sname')) {
        if (paramVal !== queryParamsVal && (paramVal !== 'null' &&
          queryParamsVal !== 'null')) {
          sessionStorage.clear();
        }
      }
    }

    if (useCaching) {
      this.queryParams = params;
    }
    return params;
  }

  public isB2B() {
    let isB2B = this.getUrlParameterByName(BaseConstantService.Key_IsB2B) === 'true' ? true : false;
    if (isB2B === false) {
      isB2B = this.sessionStorageService.get(BaseConstantService.Key_IsB2B) === true ? true : false;
    }
    return isB2B;
  }

  public b2bEmail(): string {
    let b2bEmail = this.getUrlParameterByName(BaseConstantService.Key_B2BEmail);
    if (b2bEmail === undefined || b2bEmail === null || b2bEmail === '') {
      b2bEmail = this.sessionStorageService.get(BaseConstantService.Key_B2BEmail);
    }
    return b2bEmail;
  }

  public b2bName(): string {
    const b2bName = this.sessionStorageService.get(BaseConstantService.Key_B2BUserName);
    return b2bName;
  }
  public get customerTypeCompany(): number {
    return customerTypeCompany;
  }
  public get customerTypePerson(): number {
    return customerTypePerson;
  }

  public get isB2cMultiCarrierQuotingEnabled(): boolean {
    return this.getValue(IsB2cMultiCarrierQuotingEnabled)?.toString().toLowerCase() === 'true';
  }
  public set isB2cMultiCarrierQuotingEnabled(value: boolean) {
    this.setValue(IsB2cMultiCarrierQuotingEnabled, value.toString());
  }

  public get isB2cSingleCarrierMultiQuoteEnabled(): boolean {
    return this.getValue<boolean>(IsB2cSingleCarrierMultiQuoteEnabled);
  }
  public set isB2cSingleCarrierMultiQuoteEnabled(value: boolean) {
    this.setValue(IsB2cSingleCarrierMultiQuoteEnabled, value);
  }
  public get isCopyQuotePolicyQuestion(): boolean {
    return this.getValue<boolean>(IsCopyQuotePolicyQuestion)?.toString().toLowerCase() === 'true';
  }
  public set isCopyQuotePolicyQuestion(value: boolean) {
    this.setValue(IsCopyQuotePolicyQuestion, value.toString());
  }
  public get navigateToUserDetails(): boolean {
    return this.getValue<boolean>(NavigateToUserDetails);
  }
  public set navigateToUserDetails(value: boolean) {
    this.setValue(NavigateToUserDetails, value);
  }

  public get isATPDocumentDownloadForMCI(): boolean {

    const isATPDocumentDownloadForMCI = this.sessionStorageService.get(BaseConstantService.Key_IsATPDocumentDownloadForMCI);
    return isATPDocumentDownloadForMCI;
  }

  public get existingCustomerQuote(): boolean {
    return this.getValue(BaseConstantService.Key_ExistingCustomerQuote)?.toString().toLowerCase() === 'true';
  }

  public set existingCustomerQuote(value: boolean) {
    this.setValue(BaseConstantService.Key_ExistingCustomerQuote, value.toString());
  }

  public get isNetPremiumOverridden(): boolean {
    return this.getValue(BaseConstantService.Key_IsNetPremiumOverridden)?.toString().toLowerCase() === 'true';
  }

  public set isNetPremiumOverridden(value: boolean) {
    this.setValue(BaseConstantService.Key_IsNetPremiumOverridden, value.toString());
  }

  public get isQuoteReferredCheck(): boolean {
    return this.getValue(BaseConstantService.Key_IsQuoteReferredCheck)?.toString().toLowerCase() === 'true';
  }

  public set isQuoteReferredCheck(value: boolean) {
    this.setValue(BaseConstantService.Key_IsQuoteReferredCheck, value.toString());
  }

  public get resumeCustomerId(): number {
    const resumeCustomerId = this.sessionStorageService.get(BaseConstantService.Key_ResumeCustomerId);
    return resumeCustomerId;
  }

  public set IsRefreshRequired(value: boolean) {
    this.setValue(BaseConstantService.KEY_IsRefreshRequired, value);
  }

  public get IsRefreshRequired(): boolean {
    return this.getValue<boolean>(BaseConstantService.KEY_IsRefreshRequired);
  }

  public set isDefaultListViewForMCI(value: boolean) {
    this.setValue(BaseConstantService.Key_IsDefaultListViewForMCI, value);
  }

  public get isDefaultListViewForMCI(): boolean {
    return this.getValue<boolean>(BaseConstantService.Key_IsDefaultListViewForMCI);
  }

  setLocalisedDateFormat(objDate: any) {
    let dateFormat = 'DD/MM/YYYY';
    if (this.sessionStorageService.get('LocalisedDateFormat')) {
      dateFormat = this.sessionStorageService.get('LocalisedDateFormat');
    }
    const objectNewDate = moment(objDate).valueOf();
    const resultDate = moment(objectNewDate).format(dateFormat);
    if (resultDate && resultDate !== 'Invalid date' && resultDate !== undefined) {
      return resultDate;
    }
    return objDate;
  }

  public get PrimaryQuestionData(): any {
    const primaryQuestion = this.getValue<any>(BaseConstantService.KEY_PrimaryQuestionData);
    return JSON.parse(primaryQuestion);
  }

  public set PrimaryQuestionData(value: any) {
    const primaryQuestion = JSON.stringify(value);
    this.setValue(BaseConstantService.KEY_PrimaryQuestionData, primaryQuestion);
  }

  public get isLinkToPolicyClicked(): boolean {
    return this.getValue<boolean>(IsLinkToPolicyClicked);
  }

  public set isLinkToPolicyClicked(value: boolean) {
    this.setValue(IsLinkToPolicyClicked, value);
  }


  public get AnsweredQuestionSet(): any {
    const answeredQuestionSet = this.getValue<any>(BaseConstantService.KEY_AnsweredQuestionSet);
    return JSON.parse(answeredQuestionSet);
  }

  public set AnsweredQuestionSet(value: any) {
    const answeredQuestionSet = JSON.stringify(value);
    this.setValue(BaseConstantService.KEY_AnsweredQuestionSet, answeredQuestionSet);
  }

  // GTM Code
  public get gtmCode(): string {
    return this.getValue<string>(GTMCodeKey);
  }

  public set gtmCode(value: string) {
    this.setValue(GTMCodeKey, value);
  }

  // PreviousAnswerEditButtonStatus
  getPreviousAnswerEditButtonStatus() {
    if (sessionStorage?.PreviousAnsEditButton) {
      return JSON.parse(sessionStorage?.PreviousAnsEditButton)._value;
    }
  }

  public get b2BCustomerId(): number {
    return this.getValue<number>(B2BCustomerIdKey);
  }

  public set b2BCustomerId(value: number) {
    this.setValue(B2BCustomerIdKey, value);
  }

  public set defaultCoverageTerm(value: string) {
    this.setValue(DefaultCoverageTerm, value);
  }
  public get defaultCoverageTerm(): string {
    return this.getValue<string>(DefaultCoverageTerm);
  }

  public set isESignatureEnabled(value: boolean) {
    this.setValue(IsESignatureEnabled, value);
  }
  public get isESignatureEnabled(): boolean {
    return this.getValue<boolean>(IsESignatureEnabled);
  }

  public set productName(value: string) {
    this.setValue(ProductName, value);
  }
  public get productName(): string {
    return this.getValue<string>(ProductName);
  }

  public set isDDBeforeESignature(value: boolean) {
    this.setValue(IsDDBeforeESignature, value);
  }
  public get isDDBeforeESignature(): boolean {
    return this.getValue<boolean>(IsDDBeforeESignature);
  }

  public set isMasterProduct(value: boolean) {
    this.setValue(IsMasterProduct, value);
  }
  public get isMasterProduct(): boolean {
    return this.getValue<boolean>(IsMasterProduct);
  }

  public set isUnderMasterPolicy(value: boolean) {
    this.setValue(IsUnderMasterPolicy, value);
  }
  public get isUnderMasterPolicy(): boolean {
    return this.getValue<boolean>(IsUnderMasterPolicy);
  }

  public set productValidityEndDate(value: Moment) {
    this.setValue(ProductValidityEndDate, value);
  }
  public get productValidityEndDate(): Moment {
    return this.getValue<Moment>(ProductValidityEndDate);
  }

  public set productCustomerType(value: number) {
    this.setValue(ProductCustomerType, value);
  }
  public get productCustomerType(): number {
    return this.getValue<number>(ProductCustomerType);
  }

  public get isCopyQuoteAtpClient(): boolean {
    return this.getValue<boolean>(IsCopyQuoteAtpClient);
  }

  public set isCopyQuoteAtpClient(value: boolean) {
    this.setValue(IsCopyQuoteAtpClient, value);
  }

  public get selectedCoverageTerm(): string {
    return this.getValue<string>(SelectedCoverageTerm);
  }

  public set selectedCoverageTerm(value: string) {
    this.setValue(SelectedCoverageTerm, value);
  }

  public get privacyPolicyData() {
    return this.getValue(PrivacyPolicyData);
  }

  public set privacyPolicyData(value) {
    this.setValue(PrivacyPolicyData, value);
  }


  public set addPremiumOfMandatoryAndPreSelectedCoverages(value: boolean) {
    this.setValue(AddPremiumOfMandatoryAndPreSelectedCoveragesKey, value);
  }

  public get addPremiumOfMandatoryAndPreSelectedCoverages(): boolean {
    return this.getValue<boolean>(AddPremiumOfMandatoryAndPreSelectedCoveragesKey);
  }
  public set editPrimaryCoveargeTermOnSecondaryCoveragePage(value: boolean) {
    this.setValue(EditPrimaryCoveargeTermOnSecondaryCoveragePageKey, value);
  }

  public get editPrimaryCoveargeTermOnSecondaryCoveragePage(): boolean {
    return this.getValue<boolean>(EditPrimaryCoveargeTermOnSecondaryCoveragePageKey);
  }

  public set hidePolicyQuestionFromB2BProfileonB2C(value: boolean) {
    this.setValue(HidePolicyQuestionFromB2BProfileonB2CKey, value);
  }

  public get hidePolicyQuestionFromB2BProfileonB2C(): boolean {
    return this.getValue<boolean>(HidePolicyQuestionFromB2BProfileonB2CKey);
  }

  public set currencyIso4217(value: string) {
    this.setValue(CurrencyIso4217, value);
  }
  public get currencyIso4217(): string {
    return this.getValue<string>(CurrencyIso4217);
  }

  public set setAlternateViewOfSecondaryCoveragePage(value: boolean) {
    this.setValue(AlternateViewOfSecondaryCoveragePageKey, value);
  }

  public get getAlternateViewOfSecondaryCoveragePage(): boolean {
    return this.getValue<boolean>(AlternateViewOfSecondaryCoveragePageKey);
  }

  public set setClearSelectionOnSecondaryCoverage(value: boolean) {
    this.setValue(ClearSelectionOnSecondaryCoverageskey, value);
  }
  public get getClearSelectionOnSecondaryCoverage(): boolean {
    return this.getValue<boolean>(ClearSelectionOnSecondaryCoverageskey);
  }
  public get discountRate(): any {
    return this.getValue<any>(DiscountRate);
  }
  public set discountRate(value: any) {
    this.setValue(DiscountRate, value);
  }
  // AllowCustomerIdentification
  public get allowCustomerIdentification(): boolean {
    return this.getValue<boolean>(AllowCustomerIdentificationKey);
  }

  public set allowCustomerIdentification(value: boolean) {
    this.setValue(AllowCustomerIdentificationKey, value);
  }

  // EnableCustomerIdentityB2CClaims
  public get enableCustomerIdentityB2CClaims(): boolean {
    return this.getValue<boolean>(EnableCustomerIdentityB2CClaimsKey);
  }

  public set enableCustomerIdentityB2CClaims(value: boolean) {
    this.setValue(EnableCustomerIdentityB2CClaimsKey, value);
  }

  // Get logged in user name
  public get extValRetryUrl(): string {
    return this.getValue<string>(AppExtValRetryUrl);
  }

  public set extValRetryUrl(value: string) {
    this.setValue(AppExtValRetryUrl, value);
  }

  public get hidePolicyQuestionsEdit(): boolean {
    return this.getValue(BaseConstantService.KEY_hidePolicyQuestionsEdit)?.toString().toLowerCase() === 'true';
  }

  public set hidePolicyQuestionsEdit(value: boolean) {
    this.setValue(BaseConstantService.KEY_hidePolicyQuestionsEdit, value.toString());
  }

  public get tokenGenerationRedirectUrl(): string {
    return this.getValue(TokenGenerationRedirectUrl)?.toString();
  }

  public set tokenGenerationRedirectUrl(value: string) {
    this.setValue(TokenGenerationRedirectUrl, value.toString());
  }

  public get externalValidationResult(): ExternalValidationResponse {
    return this.getValue(ExternalValidationResult);
  }

  public set externalValidationResult(value: ExternalValidationResponse) {
    this.setValue(ExternalValidationResult, value);
  }

  // BlockedTimeForCutomerIdentifaction
  public get blockedTimeForCutomerIdentifaction(): number {
    return this.getValue<number>(BlockedTimeForCutomerIdentifactionKey);
  }

  public set blockedTimeForCutomerIdentifaction(value: number) {
    this.setValue(BlockedTimeForCutomerIdentifactionKey, value);
  }

  // Convert any currency format to decimal format
  reverseFormatNumber(val) {
    // const languageCode = this.sponsorLanguageCode
    const languageCode = this.cultureName
    const thousandSeparator = new Intl.NumberFormat(languageCode).format(1111).replace(/1/g, '');
    const decimalSeparator = new Intl.NumberFormat(languageCode).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + thousandSeparator, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimalSeparator, 'g'), '.');
    return Number.isNaN(reversedVal) ? 0 : reversedVal;

  }

  // InActivityMinutesForSessionTimeout
  public get inActivityMinutesForSessionTimeout(): number {
    return this.getValue<number>(InActivityMinutesForSessionTimeoutKey);
  }

  public set inActivityMinutesForSessionTimeout(value: number) {
    this.setValue(InActivityMinutesForSessionTimeoutKey, value);
  }

  public get SessionTimeoutCountdown(): number {
    return this.getValue<number>(SessionTimeoutCountdown);
  }

  public set SessionTimeoutCountdown(value: number) {
    this.setValue(SessionTimeoutCountdown, value);
  }

  public get sponsorLanguageCode(): string {
    return this.getValue<string>(SponsorLanguageCodeKey);
  }

  public set sponsorLanguageCode(value: string) {
    this.setValue(SponsorLanguageCodeKey, value);
  }

  public get getRandomNumber(): number {
    return Math.floor(100000 + Math.random() * 900007);
  }

  public get isQuoteStatusChangedFromReferred(): boolean {
    return this.getValue(BaseConstantService.Key_QuoteStatusChangedFromReferred)?.toString().toLowerCase() === 'true';
  }

  public set isQuoteStatusChangedFromReferred(value: boolean) {
    this.setValue(BaseConstantService.Key_QuoteStatusChangedFromReferred, value.toString());
  }

  public setUserSessionKey() {
    const sessionId = this.getRandomNumber;
    this.setValue(UserSessionKey, sessionId);
  }

  public getUserSessionKey(): string {
    //* ** Unique session keys to identify the user's session ***/
    const userSessionKeyValue = this.getValue<string>(UserSessionKey);
    return userSessionKeyValue;
  }

  public set cultureName(value: string) {
    this.setValue(CultureName, value);
  }
  public get cultureName(): string {
    return this.getValue<string>(CultureName);
  }

  public get renewalDeclineComment(): string {
    return this.getValue(renewalDeclineComment)?.toString();
  }

  public set renewalDeclineComment(value: string) {
    this.setValue(renewalDeclineComment, value.toString());
  }

  UpdateQuoteDeclineRenewalSubject(value: object) {
    this.quoteDeclineRenewalSubject.next(value);
  }

  get GetQuoteDeclineRenewalSubject(): Observable<object> {
    return this.quoteDeclineRenewalSubject.asObservable();
  }

  getHomeTranslation(labelList: string[]): boolean {
    const cachedData = sessionStorage.getItem('homeTranslationCache');
    if (cachedData) {
      return labelList.every(label => label in JSON.parse(cachedData));
    }
    return false;
  }

  getHomeTransalation() {
    const cachedData = sessionStorage.getItem('homeTranslationCache');
    if (cachedData) {
      this._homeTranslationCacheObj = JSON.parse(cachedData);
    }
    return this._homeTranslationCacheObj;
  }

  setHomeTransalation(value): void {
    this._homeTranslationCacheObj = value;
    sessionStorage.setItem('homeTranslationCache', JSON.stringify(this._homeTranslationCacheObj));
  }
}

