import { Injectable } from '@angular/core';
import { endPoints } from '../../shared/core/app.config';
import { CommonHttpService } from '../common-http/common-http.service';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  IGetUIElement, IGetPageContentValues, IErrorDetails, IGetPageContentRequestBody, IGetUIElementsPayload, IStraplineContent, ModalContent
} from '../../shared/models';
import { IStraplineCode } from '../../shared/models/strapline-code.model';
import { Router, RouterEvent } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SpinnerModalComponent } from '../../shared/components/spinner-modal/spinner-modal.component';

const pageNames: Array<string> = ['MCISalesJourney'];
const ValidatingQuoteTxt = 'Shared.MultiQuoteStatusValidatingQuotesUIUX';
const QuoteRetrievingQuotesUIUXText = 'Shared.MultiQuoteRetrievingQuotesUIUXText';


@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  quoteRetrievingQuotes: string;
  validatingQuotes: string;
  dialogRef: MatDialogRef<SpinnerModalComponent, any>;
  private getUIElementsCache: any;
  private getUIElementsCachedObservable: Observable<any>;
  private getUIElementsCachedKey: any;

  constructor(private commonHttpService: CommonHttpService,
              private router: Router,
              private sessionStorageService: SessionStorageService,
              private translate: TranslateService,
              public dialog: MatDialog) { }

  private apiUrl = `${endPoints.apiUrl}`;

  private getUIelementsUrl = this.apiUrl + `${endPoints.endPointName.getUIElements}`;

  private getStrapLineUIElementsUrl = this.apiUrl + `${endPoints.endPointName.getStrapLineUIElements}`;

  private getPageContentUrl = this.apiUrl + `${endPoints.endPointName.getPageContentValues}`;

  // private getUiElementUrl = this.apiUrl + `${endPoints.endPointName.getUIElements}`;

  public sharedUIElement: IGetUIElement[];

  private getUiElementPayload: IGetUIElementsPayload;

  // --- behaviour object to be set for ui element object
  private uiElementObj = new BehaviorSubject<IGetUIElement[]>([]);
  uiElementObjObservable = this.uiElementObj.asObservable();

  // -- new wrapper api call(POST) to get content per page we pass in array req body
  getUIElements(requestBody: IGetUIElementsPayload): Observable<IGetUIElement[]> {
    const apiURL = this.getUIelementsUrl;
    return this.commonHttpService.httpPostService(apiURL, requestBody);
  }

  getUIElementsValues(languageId, productId): Observable<IGetUIElement[]> {
    const url = `${this.getUIelementsUrl}${productId}/${languageId}`;
    return this.commonHttpService.httpGetService(url);
  }

  getStrapLineUIElements(uiElementData: IGetUIElement[], uiElementCodeList: IStraplineCode): IStraplineContent {

    const straplineHeaderTextElement = uiElementData.find(item => item.uiElementCode === uiElementCodeList.headingTextCode);
    const straplineDescriptionElement = uiElementData.find(item => item.uiElementCode === uiElementCodeList.additionalTextCode);
    const straplineButtonElement = uiElementData.find(item => item.uiElementCode === uiElementCodeList.callToActionCode);
    const straplineImageElement = uiElementData.find(item => item.uiElementCode === uiElementCodeList.imageCode);

    const straplineContent = {
      straplineHeaderText: straplineHeaderTextElement && straplineHeaderTextElement.uiElementValue,
      straplineDescription: straplineDescriptionElement && straplineDescriptionElement.uiElementValue,
      straplineButton: straplineButtonElement && straplineButtonElement.uiElementValue,
      straplineImage: straplineImageElement && straplineImageElement.uiElementValue
    };

    return straplineContent;
  }

  getPageContentValues(requestBody: IGetPageContentRequestBody): Observable<IGetPageContentValues> {
    const apiURL = this.getPageContentUrl;
    return this.commonHttpService.httpPostService(apiURL, requestBody);
  }

  getErrorContent(errorPage: string, languageId: number, sponsorId: number): Observable<IErrorDetails> {
    const url = `${this.apiUrl}${endPoints.endPointName.getErrorContent}/${errorPage}/${languageId}/${sponsorId}`;
    return this.commonHttpService.httpGetService(url);
  }

  // ---- set ui-element object
  setUIElementObj(resultData: IGetUIElement[]) {
    // this.sharedUIElement = resultData;
    this.uiElementObj.next(resultData);
  }

  // ----- get specific ui element ui object data by pagename
  getUIElementDataByPageName(uiElementData: IGetUIElement[], pageName) {
    if (uiElementData) {
      return uiElementData.filter(pageObj => pageObj.pageName.toLowerCase() === pageName);
    }
  }

  // to close the pop up after navigating to multi-carrier-quote
  CloseModalDailog() {
    this.dialogRef?.close();
  }

  private applyTranslations() {
    this.translate.get([QuoteRetrievingQuotesUIUXText, ValidatingQuoteTxt]).subscribe((res) => {

      this.quoteRetrievingQuotes = res[QuoteRetrievingQuotesUIUXText];
      this.validatingQuotes = res[ValidatingQuoteTxt]

    });
  }

  // to open pop up until routing to multi-carrier-quote page
  openModal(value) {
    this.applyTranslations();
    const modalData: ModalContent = {
      headingText: this.quoteRetrievingQuotes,
      additionalText: value !== undefined ? value : '',
      instructionText: this.validatingQuotes,

    };

    this.dialogRef = this.dialog.open(SpinnerModalComponent, {
      autoFocus: false,
      data: modalData
    });
  }


  getUIElementsForProduct() {
    const productId = this.sessionStorageService.get('ProductId');
    const languageId = this.sessionStorageService.get('LanguageId');
    const data = this.getUIElementsValues(languageId, productId)
    return data;

  }

}
