import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TimePickerField } from '../../../form-field/time-picker-field';
import { LayoutService } from '../../../../services/layout/layout.service';

@Component({
  selector: 'lib-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {

  @ViewChild('timepicker') timepicker: any;

  @Input() field: TimePickerField;
  @Input() form: FormGroup;

  public isSmallView: boolean;
  public disableElement = false;
  public timePickerFormControlName: string;
  public inputClicked = false;

  constructor(private layoutService: LayoutService) { }

  ngOnInit(): void {
    this.createForm();
    this.checkViewPortWidth();
    this.disableElement = this.field.readOnly;

  }

  createForm() {
    if (this.form) {
      this.timePickerFormControlName = this.field.key || 'timePickerField';
    } else {
      this.timePickerFormControlName = 'timePickerField';
      this.form = new FormGroup({ TimePickerField: new FormControl('') });
    }
  }

  get formControl(): AbstractControl {
    return this.form.controls[this.timePickerFormControlName];
  }

  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  closeEvent() {
    if (this.formControl.status === 'INVALID') {
      this.inputClicked = true;
    } else {this.inputClicked = false; }
  }

}
