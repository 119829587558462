import { Injectable } from '@angular/core';
import { endPoints } from '../../core/app.config';
import {
  CommonHttpService, UserProfile, QuestionSetService, Question,
  AccordionItem, AnswerSets, SharedSettingsService, MCIContext, BaseConstantService,
  BaseProductTemplateService, PagingService
} from 'wtw-ui-components';
import { Observable, of, forkJoin, lastValueFrom, ReplaySubject, BehaviorSubject } from 'rxjs';
import { mergeMap, map, share } from 'rxjs/operators';
import {
  RatingRequestPayload, SavedQuotesPayload, IQuoteSummary, QuotePricingData, IPrimaryCoverage,
  IQuoteHistory, MultiQuoteCoverage, IPremiumCoverageDetail, IProfileType
} from '../../shared/models';
import { SessionStorageService } from 'angular-web-storage';
import { AccountService } from '../../services/account/account.service';
import { ProductTemplateService } from '../product-template/product-template.service';
import moment from 'moment';
import { IQuoteHistoryExtension } from '../../shared/models/quote-history.model';
import { ConstantService } from '../constant/constant.service';
import { SendQuoteEmailRequest } from '../../shared/models/send-quote-email.model';
import { IsQuoteEmailEnabled } from '../../shared/models/IsQuoteEmailEnable.model';
import { CarrierQuoteSummary, ISubTotalTax } from '../../shared/models/quote-summary.model';
import { SalesCommonService } from '../common/sales-common.service';
import { SalesSettingsService } from '../../services/settings/settings.service';
import { Guid } from 'guid-typescript';
import { AcceptQuoteReferralActionPayload } from '../../shared/models/accept-quote-referral-action-payload.model';
import { PolicySection } from '../../shared/models/policy-section.model';
import { Router } from '@angular/router';
import { MsalAuthenticationService } from 'wtw-ui-components';

@Injectable({
  providedIn: 'root'
})

export class QuotationService {
  constructor(
    private commonHttpService: CommonHttpService,
    private sessionStorage: SessionStorageService,
    private questionSetService: QuestionSetService,
    private accountService: AccountService,
    private productTemplateService: ProductTemplateService,
    private constantService: ConstantService,
    private sharedSettingsService: SharedSettingsService,
    private salesCommonService: SalesCommonService,
    private baseProductTemplateService: BaseProductTemplateService,
    private pagingService: PagingService,
    private salesSettingService: SalesSettingsService,
    private router: Router,
    private msalAuthenticationService: MsalAuthenticationService,

  ) { }

  private quoteReferenceId: string;
  private apiUrl = endPoints.apiUrl;
  private getQuotation = this.apiUrl + endPoints.endPointName.getQuotation;
  private getSavedQuotes = this.apiUrl + endPoints.endPointName.getSavedQuotes;
  private getRenewalQuotes = this.apiUrl + endPoints.endPointName.getRenewalQuotes;
  private getQuotationPricingData = this.apiUrl + endPoints.endPointName.getQuotePricingData;
  private getQuoteDetails = this.apiUrl + endPoints.endPointName.getQuoteDetails;
  private updateQuoteStatusAction = this.apiUrl + endPoints.endPointName.updateQuoteStatuswithAction;
  private AcceptQuoteReferralActionUrl = this.apiUrl + endPoints.endPointName.AcceptQuoteReferralAction;
  private saveDeclaration = this.apiUrl + endPoints.endPointName.saveDeclaration;
  private sendQuoteEmailRequestURL = this.apiUrl + endPoints.endPointName.sendQuoteEmailRequest;
  private isQuoteEmailEnable = this.apiUrl + endPoints.endPointName.isQuoteEmailNotificationEnabled;
  private getPspNameUrl = this.apiUrl + endPoints.endPointName.getPspName;
  private ProductCreationurl = this.apiUrl + endPoints.endPointName.ProductCreation;
  private assignOrUnassignUserToActionUrl = this.apiUrl + endPoints.endPointName.assignOrUnassignUserToAction;
  private declineReferralQuoteUrl = this.apiUrl + endPoints.endPointName.declineReferralQuote;
  private getPremiumCoverageDetailsUrl = this.apiUrl + endPoints.endPointName.getPremiumCoverageDetails;
  private getDuplicateQuoteExtReferenceUrl = this.apiUrl + endPoints.endPointName.getDuplicateQuoteExtReference;
  private shouldHidePolicyQuestionsUrl = endPoints.apiUrl + endPoints.endPointName.shouldHidePolicyQuestions;
  private updateQuotesUrl = endPoints.apiUrl + endPoints.endPointName.updateQuote;
  private applyKycCheckUrl = this.apiUrl + endPoints.endPointName.applyKycCheck;

  ratingRequestPayload: RatingRequestPayload;
  savedQuotesPayload: SavedQuotesPayload;
  private optionalCoverSelected: boolean;
  private quoteSummary: IQuoteSummary;
  private quoteSummaryBeforeDiscount: IQuoteSummary;
  private selectedMCIQuoteSummary: CarrierQuoteSummary;
  private coverageList: IPrimaryCoverage[] = [];

  private primaryCover: any;
  private optionalCover: Array<AccordionItem> = new Array();
  private preOptionalCoverSelected: Array<AccordionItem> = new Array();
  private quotePricingDataObservable: Observable<QuotePricingData>;
  policyReference = '';
  private quoteDetailsObservable: Observable<IQuoteHistory>;
  private premiumCoverageDetailsObservable: Observable<IPremiumCoverageDetail>;

  private quotationDataObservable: Observable<IQuoteSummary>;
  private quoteDetail: IQuoteHistoryExtension;
  isDefaultCoverSet: boolean = false;
  // DiscountRate: number = 0;
  public paymentFrequencyIntentCodeSubject = new BehaviorSubject('SelectableFrequencyMonthlyPayment');
  public recalculateTaxSubject = new BehaviorSubject(false);
  public undoPremiumSubject = new BehaviorSubject(false);
  public recalculateClickedSubject = new BehaviorSubject(false);
  private policySection: PolicySection;
  public coverageNameAndCoverageTermSelection: any[] = [];
  public defaultCoveragesInfo: IPrimaryCoverage[] = [];


  public retrieveSavedQuotes(businessStatusCode: string = ''): any {
    if (businessStatusCode === '' || businessStatusCode === undefined || businessStatusCode === null) {
      businessStatusCode = 'ALL';
    }
    return this.accountService.getUserProfile().pipe(
      mergeMap((userProfile: UserProfile) => {
        const savedQuotesPayload = {
          customerId: userProfile.customerId,
          languageId: this.sessionStorage.get('LanguageId'),
          businessstatuscode: businessStatusCode,
          quoteorigin: BaseConstantService.Key_QuoteOrigin
        };

        return this.commonHttpService.httpPostServiceWithBearer(this.getSavedQuotes, savedQuotesPayload);
      })
    );
  }

  public retrieveRenewalQuotes(businessStatusCode: string = ''): any {
    if (businessStatusCode === '' || businessStatusCode === undefined || businessStatusCode === null) {
      businessStatusCode = 'ALL';
    }
    return this.accountService.getUserProfile().pipe(
      mergeMap((userProfile: UserProfile) => {
        const renewalQuotesPayload = {
          customerId: userProfile.customerId,
          languageId: this.sessionStorage.get('LanguageId'),
          businessstatuscode: businessStatusCode,
          quoteorigin: BaseConstantService.Key_Renewals
        };

        return this.commonHttpService.httpPostServiceWithBearer(this.getRenewalQuotes, renewalQuotesPayload);
      })
    );
  }

  public getQuoteQuestions(isFromYourPolicies: boolean = false) {
    const productId = this.sessionStorage.get('ProductId');
    return forkJoin([this.productTemplateService.getQuoteQuestionSetByProductId(productId),
    this.retrieveQuoteDetails(), this.getPremiumCoverageDetails(),
    this.shouldHidePolicyQuestions()
    ]).pipe(
      mergeMap((results: [Question[], IQuoteHistoryExtension, IPremiumCoverageDetail, boolean]) => {
        let questionSetResult = results[0];
        const quoteDetails = results[1];
        const premiumCoverageDetail = results[2];
        const hidePolicyQuestions = results[3];

        this.salesSettingService.setApplicationFormId = quoteDetails?.quoteExternalReference;

        const quoteOrigin = quoteDetails?.quoteOrigin?.toUpperCase();
        const isQuoteOriginRenewal = quoteOrigin === 'RENEWALS' || quoteOrigin === 'RENEWAL'; // Renewal Quote
        const renewalQuoteOrderID = this.sessionStorage.get('RenewalQuoteOrderID');
        if (isQuoteOriginRenewal && !renewalQuoteOrderID) {
          this.handleQuoteOriginRenewal(quoteDetails);
        }

        this.sessionStorage.set('originalQuestionSet', questionSetResult);
        const hasRepeatingGroup = this.questionSetService.hasRepeatingGroup(quoteDetails?.quoteResponseData);
        if (hasRepeatingGroup) {
          questionSetResult = this.questionSetService.cloneRepeatingGroupQuestions(questionSetResult, quoteDetails?.quoteResponseData);
        }

        const quoteResponseData = this.questionSetService.formatIntentCode(quoteDetails?.quoteResponseData);

        if (!this.salesSettingService.isCopyQuotePolicyQuestion) {
          this.sessionStorage.set('PolicyId', quoteDetails?.policyId);
          this.sessionStorage.set('PolicyNumber', quoteDetails?.policyNumber);
        }
        this.sessionStorage.set('CreatedProductId', quoteDetails?.productId);
        const renewalQuoteOrderId = this.sessionStorage.get('RenewalQuoteOrderID');
        const isRenewalPolicy = this.sessionStorage.get('IsRenewalPolicy');
        const isRenewalQuote = this.sessionStorage.get('IsRenewalQuote');

        if (isRenewalQuote || isRenewalPolicy) {
          if (this.msalAuthenticationService.isAuthenticated() === false) {
            this.msalAuthenticationService.logout();
          }

        }

        const inceptionDate = quoteResponseData?.PolicyInceptionDate;
        const expiryDate = quoteResponseData?.PolicyExpiryDate;
        if (renewalQuoteOrderId > 0) {
          if (inceptionDate !== null && inceptionDate !== undefined) {
            this.dateFormatChange('InceptionDate', inceptionDate);
          }
          if (expiryDate !== null && expiryDate !== undefined) {
            this.dateFormatChange('ExpiryDate', expiryDate);
          }
        }

        this.questionSetService.policyQuestionHidden = hidePolicyQuestions;
        if (hidePolicyQuestions) {
          // Create a copy of questionSetResult
          const questionSetResultCopy = [...questionSetResult];
          const policyQuestions: Question[] = questionSetResultCopy.filter(q => q.qQuestionType.toUpperCase() ===
            this.constantService.QuestionType.Policy);

          this.questionSetService.setPolicyQuestion(policyQuestions);
          this.questionSetService.setPolicyQuestionResponse(results[1]?.policyId);

          // remove policy questions from questionSet
          questionSetResult = questionSetResult.filter(q => q.qQuestionType.toUpperCase() !==
            this.constantService.QuestionType.Policy);
        }

        this.questionSetService.stashQuestionSet();
        this.questionSetService.mapQuoteQuestionAnswer(questionSetResult, quoteResponseData, isFromYourPolicies);
        const url = window.location.href;
        if (!this.questionSetService.isInitialized || url.includes('saved-quotes') || url.includes('renewal-quotes')) {
          this.questionSetService.initialize(questionSetResult);
        }

        if (this.questionSetService.isInitialized && this.questionSetService.answeredQuestions.length === 0)
        {
          this.questionSetService.initialize(questionSetResult);
        }

        const answeredQuestions = this.questionSetService.answeredQuestions;
        // Get Inception and Expiry dates
        if (renewalQuoteOrderId > 0) {
          answeredQuestions?.
            map((item) => {
              if (item.qIntentCode === "PolicyInceptionDate") {
                item.answerSets[0].name = inceptionDate;
              }
              else {
                item.childQuestionSets.map((x) => {
                  if (x.qIntentCode === "PolicyInceptionDate") {
                    x.answerSets[0].name = inceptionDate;
                  }
                })
              }

              if (item.qIntentCode === "PolicyExpiryDate") {
                item.answerSets[0].name = expiryDate;
              }
              else {
                item.childQuestionSets.map((x) => {
                  if (x.qIntentCode === "PolicyExpiryDate") {
                    x.answerSets[0].name = expiryDate;
                  }
                })
              }
            });
        }
        this.sessionStorage.set('primaryQuestionData', JSON.stringify(questionSetResult));

        // *** To maintain base version of QSet ***//
        this.sharedSettingsService.setBaseQuestionData = JSON.parse(JSON.stringify(questionSetResult));

        const apiURL = this.getQuotation;
        const isB2cMultiCarrierQuotingEnabled = this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled;
        if (isB2cMultiCarrierQuotingEnabled === true) {
          this.stashMasterProductData();
          const isB2BFlow = this.sessionStorage.get('isb2b');
          if (!isB2BFlow) {
            this.sessionStorage.set('Email', quoteDetails.createdBy);
          }
        }

        const questionResponse = this.getResponseDictionary(answeredQuestions);
        if (premiumCoverageDetail[0]?.coverageTerm) {
          questionResponse.push({ key: BaseConstantService.CoverageTerm, value: premiumCoverageDetail[0]?.coverageTerm });
        }

        if (!this.policySection && quoteDetails?.isGrossPremOverridden) {
          const subtotalTaxModel = this.mapToSubTotalTaxModel(quoteDetails?.taxes);

          this.policySection = {
            isNetPremiumOverride: quoteDetails.isGrossPremOverridden,
            totalNetPremium: quoteDetails?.totalNetPremium,
            subtotalTaxModel: subtotalTaxModel
          };
        }

        this.ratingRequestPayload = {
          id: this.salesSettingService.tempQuoteId,
          productTemplateId: this.sessionStorage.get('ProductId'),
          productName: this.sessionStorage.get('ProductName'),
          answeredQuestionResponse: questionResponse,
          sponsorId: this.sessionStorage.get('SponsorId'),
          sponsorName: this.sessionStorage.get('SponsorName'),
          languageId: this.sessionStorage.get('LanguageId'),
          questionSetsToFilter: this.getQuestionSetsToFilter(),
          selectedCoverageList: this.getSelectedCoverageList(),
          isB2cSingleCarrierMultiQuoteEnabled: this.IsB2cSingleCarrierMultiQuoteEnabled(),
          loggedInEmailId: this.sharedSettingsService.isB2B() ? this.sharedSettingsService.b2bEmail() : 'System',
          quoteOrigin: this.sessionStorage.get('IsRenewalQuote') === true || isQuoteOriginRenewal ? BaseConstantService.Key_Renewals :
            BaseConstantService.Key_QuoteOrigin,
          isb2b: this.sessionStorage.get('isb2b'),
          isResumeQuote: this.sharedSettingsService.existingCustomerQuote,
          quoteReferenceID: quoteDetails?.quoteId,
          insuredDetailsResponse: this.getInsuredDetailsResponse(answeredQuestions),
          riskDetailsResponse: this.getRiskDetailsResponse(answeredQuestions),
          policySection: this.policySection,
          DiscountRate: this.salesSettingService.discountRate,
          CoverageNameAndCoverageTermSelectionJson: '',
          sessionKey: this.sharedSettingsService.getUserSessionKey(),
          useCacheValue: false, // !this.salesSettingService?.isNetPremiumOverridden,
          selectedPaymentFrequency: this.sessionStorage.get('SelectedPaymentFrequency'),
          parentPolicyNumber: this.sessionStorage.get('RenewalPolicyNumber')
        };
        return this.commonHttpService.httpPostServiceWithBearer(apiURL, this.ratingRequestPayload);
      })
    );
  }

  dateFormatChange(date: string, dateValue: string) {
    if (dateValue.length === 10 && moment(dateValue, 'DD/MM/YYYY', true).isValid()) {
      this.sessionStorage.set(date, moment(dateValue, 'DD/MM/YYYY').format('MM/DD/YYYY'));
    }
    else {
      this.sessionStorage.set(date, this.pagingService.convertDateIntoUTC(dateValue));
    }
  }

  retrieveQuoteDetails(): Observable<IQuoteHistory> {
    const quoteRequestPayLoad = {
      quoteId: this.sessionStorage.get('QuoteReferenceID'),
      languageId: this.sessionStorage.get('LanguageId')
    };
    const url = window.location.href;

    // Renewal case : Check if the current quoteId is different from the stored quoteId
    let hasQuoteIdChanged: boolean;
    if (this.quoteDetailsObservable) {
      this.quoteDetailsObservable.subscribe(quoteDetail => {
        const isRenewalQuote = this.sessionStorage.get('IsRenewalQuote');
        if (!quoteDetail || (quoteDetail && quoteDetail.quoteId !== quoteRequestPayLoad.quoteId && isRenewalQuote)) {
          // If the quoteId is different, set isQuoteIdChanged to true
          hasQuoteIdChanged = true;
        }
      });
    }

    if (!this.quoteDetailsObservable || url.includes('saved-quotes') || url.includes('renewal-quotes') || hasQuoteIdChanged) {
      this.quoteDetailsObservable = this.commonHttpService.httpPostServiceWithBearer(this.getQuoteDetails, quoteRequestPayLoad).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }

    return this.quoteDetailsObservable;
  }

  updateQuoteStatuswithAction(): Observable<boolean> {
    const isB2BFlow = this.sharedSettingsService.isB2B();
    const b2bEmail = (isB2BFlow === true) ? this.sharedSettingsService.b2bEmail()
      : this.sessionStorage.get('Email');
    const quoteRequestPayLoad = {
      quoteId: this.sessionStorage.get('QuoteReferenceID'),
      customerId: this.sessionStorage.get('ResumeCustomerId'),
      languageId: this.sessionStorage.get('LanguageId'),
      sponsorId: this.sessionStorage.get('SponsorId'),
      userId: b2bEmail
    };
    const result = this.commonHttpService.httpPostServiceWithBearer(this.updateQuoteStatusAction, quoteRequestPayLoad).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: true,
      }))
    return result;
  }

  AcceptQuoteReferralAction(): Observable<boolean> {
    const answeredQuestionResponse = this.getResponseDictionary(this.questionSetService.answeredQuestions);

    const paymentFrequency =
      this.quoteSummary.paymentFrequenciesDetails?.selectedPaymentFrequencies
        ?.find(x => x.intentCode === this.quoteSummary.selectedPaymentFrequencyIntentCode)?.paymentFrequencyID;

    const AcceptQuoteReferralActionPayload: AcceptQuoteReferralActionPayload = {
      actionId: this.quoteSummary.actionID,
      quoteId: this.sessionStorage.get('QuoteReferenceID'),
      customerId: this.sessionStorage.get('ResumeCustomerId'),
      languageId: this.sessionStorage.get('LanguageId'),
      sponsorId: this.sessionStorage.get('SponsorId'),
      productTemplateId: this.sessionStorage.get('ProductId'),
      grossPremium: this.quoteSummary.ratingBindingInformations.originalGrossRate,
      isNetPremiumOverridden: this.sharedSettingsService.isNetPremiumOverridden,
      overriddenNetPremium: this.quoteSummary.ratingBindingInformations.originalNetRate,
      answeredQuestionResponse: answeredQuestionResponse,
      PaymentFrequencyDDItemID: paymentFrequency,
      taxSubtotals: this.quoteSummary.ratingBindingInformations.taxSectionDisplay,
      subtotalTaxModel: this.quoteSummary.ratingBindingInformations.subtotalTaxModel,
      userName: this.sessionStorage.get('b2bemail')
    }

    const result = this.commonHttpService.
      httpPostServiceWithBearer(this.AcceptQuoteReferralActionUrl, AcceptQuoteReferralActionPayload).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    return result;
  }

  declineReferralQuote(): Observable<boolean> {
    const isB2BFlow = this.sharedSettingsService.isB2B();
    const b2bEmail = (isB2BFlow === true) ? this.sharedSettingsService.b2bEmail()
      : this.sessionStorage.get('Email');
    const quoteRequestPayLoad = {
      quoteId: this.sessionStorage.get('QuoteReferenceID'),
      customerId: this.sessionStorage.get('ResumeCustomerId'),
      languageId: this.sessionStorage.get('LanguageId'),
      sponsorId: this.sessionStorage.get('SponsorId'),
      userId: b2bEmail,
      renewalDeclineComment: this.sharedSettingsService.renewalDeclineComment
    };
    const result = this.commonHttpService.httpPostServiceWithBearer(this.declineReferralQuoteUrl, quoteRequestPayLoad).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: true,
      }))
    return result;
  }

  retrieveRenewalQuoteDetails(): Observable<IQuoteHistory> {
    const quoteRequestPayLoad = {
      quoteId: this.sessionStorage.get('QuoteReferenceID'),
      languageId: this.sessionStorage.get('LanguageId')
    };
    const url = window.location.href;
    if (!this.quoteDetailsObservable || url.includes('saved-quotes')) {
      this.quoteDetailsObservable = this.commonHttpService.httpPostServiceWithBearer(this.getQuoteDetails, quoteRequestPayLoad).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }

    return this.quoteDetailsObservable;
  }

  getPremiumCoverageDetails() {
    const quoteReferenceID = this.sessionStorage.get('QuoteReferenceID')
    const premiumCoverageDetailsPayLoad = {
      sponsorId: this.sessionStorage.get('SponsorId'),
      quoteReferenceId: quoteReferenceID as Guid,
      policyId: null,
      languageId: this.sessionStorage.get('LanguageId')
    }
    this.premiumCoverageDetailsObservable =
      this.commonHttpService.httpPostServiceWithBearer(this.getPremiumCoverageDetailsUrl, premiumCoverageDetailsPayLoad)
        .pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: true,
          }))
    return this.premiumCoverageDetailsObservable;
  }

  // to get the duplicate quoteexternalref value
  getDuplicateQuoteExternalValue() {
    const DuplicateQuoteExtReferencePayLoad = {
      sponsorId: this.sessionStorage.get('SponsorId') as string,
      QuoteExternalReference: this.salesSettingService.getPolicyReferenceId as string
    }
    return this.commonHttpService.httpPostService(this.getDuplicateQuoteExtReferenceUrl, DuplicateQuoteExtReferencePayLoad).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: true,
      }))

  }

  getQuotationData(
    usedCachedValue: boolean = false, discount: number = 0, isRefreshedData: boolean = false,
    getUpdatedPremium: boolean = false, initiallySelectedCovers?: any[]): Observable<IQuoteSummary> {
    const productTemplateId = this.sessionStorage.get('ProductId');

    if (productTemplateId > 0) {
      const apiURL = this.getQuotation;
      const answeredQuestions = this.questionSetService.answeredQuestions;

      const primaryQuestionData = JSON.parse(this.sessionStorage.get('primaryQuestionData'));

      if (answeredQuestions && primaryQuestionData?.length > 0) {

        const questionResponse = this.getResponseDictionary(answeredQuestions);
        const isB2cSingleCarrierMultiQuoteEnabled = this.IsB2cSingleCarrierMultiQuoteEnabled();

        this.ratingRequestPayload = {
          id: this.salesSettingService.tempQuoteId,
          productTemplateId: this.sessionStorage.get('ProductId'),
          productName: this.sessionStorage.get('ProductName'),
          answeredQuestionResponse: questionResponse,
          sponsorId: this.sessionStorage.get('SponsorId'),
          sponsorName: this.sessionStorage.get('SponsorName'),
          languageId: this.sessionStorage.get('LanguageId'),
          questionSetsToFilter: this.getQuestionSetsToFilter(),
          selectedCoverageList: this.getSelectedCoverageList(),
          isB2cSingleCarrierMultiQuoteEnabled: isB2cSingleCarrierMultiQuoteEnabled,
          loggedInEmailId: this.sharedSettingsService.isB2B() ? this.sharedSettingsService.b2bEmail() :
            this.sessionStorage.get('LoggedInEmail') ?? "System",
          quoteOrigin: this.sessionStorage.get('IsRenewalQuote') === true ? BaseConstantService.Key_Renewals :
            BaseConstantService.Key_QuoteOrigin,
          isb2b: this.sessionStorage.get('isb2b'),
          isResumeQuote: this.salesCommonService.isResumeQuote(),
          quoteReferenceID: this.sessionStorage.get('QuoteReferenceID'),
          insuredDetailsResponse: this.getInsuredDetailsResponse(answeredQuestions),
          riskDetailsResponse: this.getRiskDetailsResponse(answeredQuestions),
          DiscountRate: discount,
          CoverageNameAndCoverageTermSelectionJson: this.salesSettingService.coverageNameAndCoverageTermSelection ?? '',
          policySection: this.policySection,
          useCacheValue: usedCachedValue,
          multiQuoteNumbers: this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled ? this.getMultiQuoteInformation() : null,
          IsRefreshedData: isRefreshedData,
          sessionKey: this.sharedSettingsService.getUserSessionKey(),
          selectedPaymentFrequency: this.sessionStorage.get('SelectedPaymentFrequency'),
          parentPolicyNumber: this.sessionStorage.get('RenewalPolicyNumber')
        };

        this.quotationDataObservable = this.commonHttpService.httpPostServiceWithBearer(apiURL, this.ratingRequestPayload).pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: true,
          }))
        // Clear any cached pricing data, so that updated values will be fetched.
        this.quotePricingDataObservable = null;
      } else {
        this.quotePricingDataObservable = null;
      }
    }

    return this.quotationDataObservable;
  }

  selectedCoverageWithTerm(selectedCoverageAndTerm) {
    this.coverageNameAndCoverageTermSelection = selectedCoverageAndTerm;
    this.salesSettingService.coverageNameAndCoverageTermSelection = JSON.stringify(this.coverageNameAndCoverageTermSelection);

  }

  getRatingPricingData(): Observable<QuotePricingData> {
    return this.getQuotationData(true).pipe(
      map((quoteSummary: IQuoteSummary) => {
        const quotePricingData: QuotePricingData = {
          grossPremium: +quoteSummary.originalGrossRate,
          formattedGrossPremium: quoteSummary?.formattedOriginalGrossRate,
          netPremium: quoteSummary?.ratingBindingInformations?.originalNetRate,
          currencyCode: quoteSummary?.currencyIso4217
        };
        return quotePricingData;
      }));
  }

  getQuotePricingData(): Observable<QuotePricingData> {
    const quoteRef = this.sessionStorage.get('QuoteReferenceID');
    const apiURL = this.getQuotationPricingData;

    if (!this.quotePricingDataObservable) {
      this.quotePricingDataObservable = this.commonHttpService.httpGetService(`${apiURL}/${quoteRef}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }

    return this.quotePricingDataObservable;
  }

  public async buildRequestObject() {
    const user = await lastValueFrom<any>(this.accountService.getUserProfile(true, null, true));
    const answeredInceptionDate = this.sessionStorage.get('InceptionDate')
    if (answeredInceptionDate !== new Date().toJSON().slice(0, 10)) {
      this.sessionStorage.set('InceptionDate', new Date().toJSON().slice(0, 10));
    }
    const answeredQuestions = this.questionSetService.answeredQuestions;
    answeredQuestions?.
      map((item) => {
        if (item.qIntentCode === "PolicyInceptionDate") {
          item.answerSets[0].name = this.sessionStorage.get('InceptionDate');
        }
        else {
          item.childQuestionSets.map((x) => {
            if (x.qIntentCode === "PolicyInceptionDate") {
              x.answerSets[0].name = this.sessionStorage.get('InceptionDate');
            }
          })
        }
      });

    // build requestobject for save/update quote and inception date.
    const saveUserDetailsComposite = await this.buildUserDetailsCompositeObject(user, true);
    return saveUserDetailsComposite;
  }

  public updateInceptionDateForESign(saveUserDetailsComposite): Observable<any> {
    const saveUserDetailsWithSupplementaryDataUrl = this.apiUrl + endPoints.endPointName.saveUserDetailsWithSupplementaryData;
    const apiURL = saveUserDetailsWithSupplementaryDataUrl;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, saveUserDetailsComposite).pipe(
      map((result) => {
        return result;
      })
    );
  }

  public async buildUserDetailsCompositeObject(userProfile: UserProfile, isSkipUserDetailSavePart: boolean):
    Promise<any> {

    const isB2BFlow = this.sharedSettingsService.isB2B();
    const b2bEmail = (isB2BFlow === true) ? this.sharedSettingsService.b2bEmail()
      : this.sessionStorage.get('Email');
    const quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    return {
      userProfile,
      sponsorId: this.sessionStorage.get('SponsorId'),
      sponsorName: this.sessionStorage.get('SponsorName'),
      languageId: this.sessionStorage.get('LanguageId'),
      productId: this.sessionStorage.get('ProductId'),
      inceptionDate: this.sessionStorage.get('InceptionDate'),
      expiryDate: this.sessionStorage.get('ExpiryDate'),
      grossPremium: 0, // *** getting these value in the middleware ***//
      netPremium: 0,
      currencyCode: quoteSummary?.currencyIso4217,
      quoteReferenceId: this.sessionStorage.get('QuoteReferenceID') ? this.sessionStorage.get('QuoteReferenceID') : '',
      orderId: this.sessionStorage.get('OrderId'),
      policyId: this.sessionStorage.get('PolicyId') ? this.sessionStorage.get('PolicyId') : 0,
      policyNumber: this.sessionStorage.get('PolicyNumber') ? this.sessionStorage.get('PolicyNumber') : '',
      createdProductId: this.sessionStorage.get('CreatedProductId') ? this.sessionStorage.get('CreatedProductId') : 0,
      answeredQuestionResponse: this.getResponseDictionary(this.questionSetService.answeredQuestions),
      selectedCoverageList: this.getSelectedCoverageList(),
      isB2cSingleCarrierMultiQuoteEnabled: this.IsB2cSingleCarrierMultiQuoteEnabled(),
      isB2cMultiCarrierQuotingEnabled: this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled,
      multiCarrierQuoteSummary: quoteSummary?.multiCarrierQuoteSummary,
      MCIContext: MCIContext.ATPCARRINT,
      isSkipUserDetailSavePart,
      QuestionSetsToFilter: this.getQuestionSetsToFilter(),
      isB2BFlow: isB2BFlow,
      b2bEmail: b2bEmail
    };
  }

  public applyKycCheck(quoteReferenceId: string): Observable<any> {
    const url = this.applyKycCheckUrl + "/" + quoteReferenceId;

    return this.commonHttpService.httpGetService(url).pipe(
      map((result: any) => {
        return result;
      }));
  }

  savePolicyDeclaration() {
    const apiUrl = this.saveDeclaration;

    return forkJoin([this.accountService.getUserProfile(), this.getQuotePricingData()]).pipe(
      mergeMap((results: [UserProfile, QuotePricingData]) => {
        const userDetails = results[0];
        const premium = results[1];
        const isB2BFlow = this.sharedSettingsService.isB2B();
        const creationUser = isB2BFlow ? this.sharedSettingsService.b2bEmail() : userDetails.email;

        const saveDeclarationReqObj = {
          quoteId: this.quoteReferenceId,
          inceptionDate: this.sessionStorage.get('InceptionDate'),
          expiryDate: this.sessionStorage.get('ExpiryDate'),
          grossPremium: premium.grossPremium,
          quotedDate: new Date().toDateString(),
          quoteAccepted: true,
          productTemplateId: this.sessionStorage.get('ProductId'),
          customerId: userDetails.customerId,
          createdBy: creationUser,
          sponsorId: this.sessionStorage.get('SponsorId'),
          policyId: this.sessionStorage.get('PolicyId'),
          languageId: this.sessionStorage.get('LanguageId'),
          salesPolicyDeclerationId: '534e2b68-bffd-4677-95f2-f8f2200f1883',
          timeZone: this.sessionStorage.get('timeZone'),
          answeredQuestionResponse: this.getResponseDictionary(this.questionSetService.answeredQuestions),
          quoteReferenceId: this.sessionStorage.get('QuoteReferenceID'),
          sponsorEnglishName: this.sessionStorage.get('SponsorName')
        };

        return this.commonHttpService.httpPostServiceWithBearer(apiUrl, saveDeclarationReqObj);
      })
    );
  }

  setQuoteSummary(quoteSummary: IQuoteSummary, isRefreshedRequired: boolean = true) {
    this.quoteSummary = quoteSummary;
    this.sessionStorage.set('quoteSummary', JSON.stringify(this.quoteSummary));
    if (isRefreshedRequired) {
      this.coverageList = null;
    }
  }

  setBeforeDiscount(quoteSummaryBeforeDiscount: IQuoteSummary) {
    this.quoteSummaryBeforeDiscount = quoteSummaryBeforeDiscount;
    this.sessionStorage.set('quoteSummaryBeforeDiscount', JSON.stringify(this.quoteSummaryBeforeDiscount));
  }

  getBeforeDiscount() {
    this.quoteSummaryBeforeDiscount = JSON.parse(this.sessionStorage.get('quoteSummaryBeforeDiscount'));

    if (!this.quoteSummaryBeforeDiscount) {
      const coverage: IPrimaryCoverage[] = [];
      return coverage;
    }
    return this.quoteSummaryBeforeDiscount.coverageInformation.coverageInformation;
  }

  getQuoteSummary() {
    if (!this.quoteSummary) {
      this.quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    }
    return this.quoteSummary;
  }

  getChildProductByMasterId(quoteSummary) {
    const getChildProductByMasterIdURL = endPoints.endPointName.getChildProductByMasterIdURL;
    return this.commonHttpService.httpPostServiceWithBearer(getChildProductByMasterIdURL, quoteSummary);
  }

  submitQuote(userProfile: UserProfile) {

    const submitQuoteURL = endPoints.endPointName.submitQuoteURL;

    this.salesCommonService.stashInceptionExpiryDates();

    const answeredQuestionResponse = this.getResponseDictionary(this.questionSetService.answeredQuestions);
    const quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));

    const isB2BFlow = this.sharedSettingsService.isB2B();
    const b2bEmail = (isB2BFlow === true) ? this.sharedSettingsService.b2bEmail()
      : this.sessionStorage.get('Email');

    const productId = this.sessionStorage.get('ProductId');
    const productName = this.sessionStorage.get('ProductName');

    const quoteInputData = {
      SponsorId: this.sessionStorage.get('SponsorId'),
      ProductId: productId,
      ProductName: productName,
      IsB2cMultiCarrierQuotingEnabled: true,
      inceptionDate: this.sessionStorage.get('InceptionDate'),
      expiryDate: this.sessionStorage.get('ExpiryDate'),
      MultiCarrierQuoteSummary: quoteSummary?.multiCarrierQuoteSummary,
      AnsweredQuestionResponse: answeredQuestionResponse,
      MCIContext: MCIContext.ATPCARRINT,
      UserProfile: userProfile,
      currencyCode: quoteSummary?.currencyIso4217,
      isB2BFlow: isB2BFlow,
      b2bEmail: b2bEmail,
      isResumeQuote: this.salesCommonService.isResumeQuote(),
      quoteReferenceId: this.sessionStorage.get('QuoteReferenceID'),
      orderId: this.sessionStorage.get('OrderId'),
      sessionKey: this.sharedSettingsService.getUserSessionKey()
    };

    return this.commonHttpService.httpPostServiceWithBearer(submitQuoteURL, quoteInputData);

  }

  getCoverageInformation(): IPrimaryCoverage[] {
    if (!this.quoteSummary) {
      this.quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    }

    if (!this.quoteSummary) {
      const coverage: IPrimaryCoverage[] = [];
      return coverage;
    }
    return this.quoteSummary.coverageInformation.coverageInformation;
  }

  getCoverageList(isRefreshRequired: boolean = false): IPrimaryCoverage[] {
    if (this.coverageList !== null && this.coverageList !== undefined && isRefreshRequired === false && this.coverageList.length > 0) {
      return this.coverageList;
    }
    else {
      const coverInformatiion = this.getCoverageInformation();

      const uniquePrimaryCovers = [];
      this.coverageList = [];
      let coverageTermsList: string[];
      const selectedPrimaryCoverExists = coverInformatiion.some(p => p.isSelected);

      coverInformatiion?.forEach(c => {

        if (uniquePrimaryCovers?.indexOf(c.intentCode) < 0) {

          uniquePrimaryCovers.push(c.intentCode);

          // coverageTermsList = coverInformatiion.filter(x => x.coverageName === c.coverageName).map(y => y.coverageTermValue);
          const selectedCoverage = coverInformatiion.filter(x => x.coverageName === c.coverageName)[0];
          coverageTermsList = this.getAllowableCoverageList(selectedCoverage);

          if (c.defaultCoverageTermText !== '') {
            // *** When we had the DefaultCoverageTerm configured then we have to display it is respective data ***//
            let defaultCoverageByIntentCode = coverInformatiion.filter(cov => cov.intentCode === c.intentCode);
            const isCopyQuotePolicy = this.salesSettingService.isCopyQuotePolicyQuestion;
            const isResumeQuote = this.salesCommonService.isResumeQuote();
            const isb2b = this.sessionStorage.get('isb2b')
            // Retain primary cover selection
            if (selectedPrimaryCoverExists && (!isRefreshRequired || ((isResumeQuote || isCopyQuotePolicy) && isb2b))) {
              const coverToBeRetained = coverInformatiion.filter(cov => cov.intentCode === c.intentCode
                && cov.isSelected);
              if (coverToBeRetained[0]?.discountRate > 0 && (this.salesSettingService.isCopyQuotePolicyQuestion || isResumeQuote) &&
                !this.salesSettingService.isPrimaryCoverSelected) {
                this.salesSettingService.discountRate = coverToBeRetained[0]?.discountRate;
              }
              if (coverToBeRetained && coverToBeRetained.length > 0) {
                defaultCoverageByIntentCode = coverToBeRetained;
              }
            }

            if (defaultCoverageByIntentCode && defaultCoverageByIntentCode.length > 0) {
              // ***  When we have Same Coverage with differnt CoverageTerms***//
              defaultCoverageByIntentCode[0].coverageTermsList = coverageTermsList;
              this.coverageList.push(defaultCoverageByIntentCode[0]);
            }
            else {
              // ***  When we have Coverage with only one CoverageTerms***//
              this.coverageList.push(c);
            }
          }
          else {

            c.coverageTermsList = coverageTermsList;
            this.coverageList.push(c);
          }
        }

      });

      return this.coverageList;
    }
  }

  getPaymentTerms() {
    if (this.quoteSummary) {
      return this.quoteSummary.paymentTerms;
    }
    return null;
  }

  currencySymbol() {
    if (!this.quoteSummary) {
      this.quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    }
    return this.quoteSummary.currencySymbol;
  }

  currencySymbolAfterMoney() {
    if (!this.quoteSummary) {
      this.quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    }
    return this.quoteSummary.coverageInformation.currencySymbolAfterMoney;
  }

  setPrimaryCover(primaryCover: any) {
    this.primaryCover = primaryCover;
    this.sessionStorage.set('primaryCover', JSON.stringify(primaryCover));
    this.resetOptionalCoverSelection();
  }

  setPrimaryCoverOnly(primaryCover: any) {
    this.primaryCover = primaryCover;
    this.sessionStorage.set('primaryCover', JSON.stringify(primaryCover));
  }

  getPrimaryCover() {
    if (!this.primaryCover || this.primaryCover.length === 0) {
      this.primaryCover = JSON.parse(this.sessionStorage.get('primaryCover'));
    }
    return this.primaryCover;
  }

  resetOptionalCoverSelection() {
    this.sessionStorage.set('optionalCover', '');
    this.optionalCover = null; // resetting the 'optionalCover' object along with session storage
    this.sessionStorage.set('optionalCoverSelected', false);
    this.optionalCoverSelected = false;
  }

  setOptionalCover(optionalCover: Array<AccordionItem>) {
    this.optionalCover = optionalCover;
    this.sessionStorage.set('optionalCover', JSON.stringify(optionalCover));
    this.sessionStorage.set('optionalCoverSelected', true);
    this.optionalCoverSelected = true;
  }
  setInitialOptionalCover(optionalCover: Array<AccordionItem>) {
    this.optionalCover = optionalCover;
    this.sessionStorage.set('preSelectedOptionalCover', JSON.stringify(optionalCover));
  }

  getInitialOptionalCover() {
    this.preOptionalCoverSelected = JSON.parse(this.sessionStorage.get('preSelectedOptionalCover'));
    return this.preOptionalCoverSelected;
  }

  isOptionalCoverSelected() {
    if (!this.optionalCoverSelected) {
      this.optionalCoverSelected = this.sessionStorage.get('optionalCoverSelected');
    }
    return this.optionalCoverSelected;
  }

  getOptionalCover() {
    if (!this.optionalCover || this.optionalCover.length === 0) {
      this.optionalCover = JSON.parse(this.sessionStorage.get('optionalCover'));
    }
    return this.optionalCover;
  }

  IsB2cSingleCarrierMultiQuoteEnabled(): boolean {
    const result = sessionStorage.getItem('isB2cSingleCarrierMultiQuoteEnabled');
    return result.toLowerCase() === 'true';
  }

  getSelectedCoverageList(): IPrimaryCoverage[] {
    const selectedCoverageList: IPrimaryCoverage[] = [];

    // ** added 'coverageTermChanged' condition as no need to send selectedCoverageList if defaultcoverageterm
    // ** has reselected on primary cover page
    if (this.IsB2cSingleCarrierMultiQuoteEnabled()) {

      const coverageList = this.getCoverageList();

      coverageList.forEach(cover => {
        if (cover.isSelected) {
          const primaryCover: IPrimaryCoverage = {
            coverageName: cover.coverageName,
            intentCode: cover.intentCode,
            grossPremium: cover.grossPremium,
            netPremium: cover.netPremium,
            freeGrossPremium: cover.freeGrossPremium,
            freeNetPremium: cover.freeNetPremium,
            allowableTerms: cover.allowableTerms,
            coverageTermsLabel: '',
            coverageTerms: cover.coverageTermValue,
            coverageTermsList: [],
            freeCoverageTerm: cover.freeCoverageTerm,
            paidCoverageTerm: cover.paidCoverageTerm,
            defaultCoverageTermText: cover.defaultCoverageTermText,
            coverageStartDate: cover.coverageStartDate,
            coverageEndDate: cover.coverageEndDate,
            isPrimary: true,
            isSelected: true,
            coverageDetails: '',
            additionalcoverageInformation: [],
            base64ImageData: cover.base64ImageData,
            primaryCoverageBenefits: [],
            optionalCoverageBenefits: cover.optionalCoverageBenefits,
            discountRate: null
          };
          selectedCoverageList.push(primaryCover);

          cover.additionalcoverageInformation.forEach(optCover => {
            if (optCover.isSelected) {
              const optionalCover: IPrimaryCoverage = {
                coverageName: optCover.coverageName,
                intentCode: optCover.intentCode,
                grossPremium: optCover.grossPremium,
                netPremium: optCover.netPremium,
                freeGrossPremium: optCover.freeGrossPremium,
                freeNetPremium: optCover.freeNetPremium,
                allowableTerms: optCover.allowableTerms,
                coverageTermsLabel: '',
                coverageTerms: optCover.coverageTermValue, // replacing with coverageTermValue to get the selected coverage term
                coverageTermValue: optCover.coverageTermValue,
                defaultCoverageTermText: optCover.defaultCoverageTermText,
                freeCoverageTerm: optCover.freeCoverageTerm,
                paidCoverageTerm: optCover.paidCoverageTerm,
                coverageStartDate: optCover.coverageStartDate,
                coverageEndDate: optCover.coverageEndDate,
                coverageTermsList: [],
                isPrimary: false,
                isSelected: true,
                coverageDetails: '',
                additionalcoverageInformation: [],
                base64ImageData: '',
                primaryCoverageBenefits: [],
                optionalCoverageBenefits: optCover.optionalCoverageBenefits,
                discountRate: null,
              };
              selectedCoverageList.push(optionalCover);
            }
          });
        }
      });

    }
    return selectedCoverageList;
  }

  getResponseDictionary(answeredQuestions: Question[]): any {
    let answerList: { key: string, value: string }[];
    const cAttributeEntries: { key: string, value: string }[] = [];
    this.policyReference = '';
    answeredQuestions?.forEach(question => {

      if (answerList === undefined || answerList?.length === 0) {
        answerList = [];
      }

      if (!question.childQuestionSets
        || question.childQuestionSets === undefined
        || question.childQuestionSets?.length === 0) {

        this.getPolicyExternalRef(question);

        const answer = this.getQuestionResponse(question);
        if (question.qIntentCode.includes('~')) {
          question.qIntentCode = this.formatIntentCode(question.qIntentCode);
        }
        const keyAlreadyExist = answerList?.filter(obj => obj.key === question.qIntentCode).length > 0
        if (!keyAlreadyExist) {
          answerList.push({ key: question.qIntentCode?.trim(), value: answer });
        }

        if (question.globalFunction !== "" && question.globalFunction !== undefined && question.globalFunction !== null) {
          if (question.globalFunction?.toLowerCase().includes('cattribute')) {
            cAttributeEntries.push({ key: question.qIntentCode?.trim(), value: answer });
            this.sessionStorage.set('cAttributeEntries', cAttributeEntries)
          }
        }

        // *** to handle, If the Question has dependent child questions based on current answer ***/
        const childQuestion = question.answerSets?.filter(x => x.isAnswered === true)[0]?.childQuestionSets;
        if (childQuestion && childQuestion?.length > 0) {
          this.mapChildQuestionAnswer(childQuestion, answerList, cAttributeEntries);
        }

      } else {
        this.mapChildQuestionAnswer(question.childQuestionSets, answerList, cAttributeEntries);
      }

    });
    const keyExist = answerList?.filter(obj => obj.key === "policyReference").length > 0
    if (this.policyReference !== '' && !keyExist) {
      answerList.push({ key: BaseConstantService.PolicyExternalRef, value: this.policyReference });
      this.salesSettingService.setPolicyReferenceId = this.policyReference;
    }

    // include Policy response while saving if policy questions are hidden
    answerList = this.questionSetService.includePolicyResponse(answerList);
    return answerList;
  }

  getPolicyExternalRef(question?: any) {
    // to form the 'policyReference' on basis of 'persistQuoteExternalRef' and 'persistQuoteExternalRefSuffix'
    if (question?.persistQuoteExternalRef?.toLowerCase() === "true") {
      const answer = question.answerSets.filter(x => x.isAnswered === true);
      let ExternalRef = '';

      if (answer && answer?.length > 0) {
        ExternalRef = question.answerSets.filter(x => x.isAnswered === true)[0].name;
      }

      this.policyReference = (this.policyReference !== '') ? ExternalRef + "-" + this.policyReference : ExternalRef;
    }
    else if (question?.persistQuoteExternalRefSuffix?.toLowerCase() === "true") {
      const answer = question.answerSets.filter(x => x.isAnswered === true);
      let externalRefSuffix = '';

      if (answer && answer?.length > 0) {
        externalRefSuffix = question.answerSets.filter(x => x.isAnswered === true)[0].name;
      }

      this.policyReference = (this.policyReference !== '') ? this.policyReference + "-" + externalRefSuffix : externalRefSuffix;
    }
  }

  mapChildQuestionAnswer(
    childQuestions: Question[],
    answerList: { key: string, value: string }[],
    cAttributeEntries: { key: string, value: string }[]) {
    childQuestions.forEach(childQuestion => {

      this.getPolicyExternalRef(childQuestion);

      const childQuestionAnswer = this.getQuestionResponse(childQuestion);

      if (childQuestion?.qIntentCode !== undefined && childQuestion?.qIntentCode !== null) {
        if (childQuestion.qIntentCode.includes('~')) {
          childQuestion.qIntentCode = this.formatIntentCode(childQuestion.qIntentCode);
        }
        answerList.push({ key: childQuestion.qIntentCode?.trim(), value: childQuestionAnswer });
      }

      // *** To get CAttributeEntries ***//
      if (childQuestion !== undefined && childQuestion !== null) {
        if (childQuestion.globalFunction?.toLowerCase().includes('cattribute')) {
          cAttributeEntries.push({ key: childQuestion.qIntentCode?.trim(), value: childQuestionAnswer });
          this.sessionStorage.set('cAttributeEntries', cAttributeEntries)
        }
      }

      // *** to handle, If the Question has dependent child questions based on current answer  ***/
      const grandChildQuestion = childQuestion.answerSets?.filter(x => x.isAnswered === true)[0]?.childQuestionSets;
      if (grandChildQuestion && grandChildQuestion?.length > 0) {
        this.mapChildQuestionAnswer(grandChildQuestion, answerList, cAttributeEntries);
      }

    });
  }

  getInsuredDetailsResponse(answeredQuestions: Question[]): any {
    // *** Fetch Question Responses which is having 'CAttribute' ***//

    const insuredDetailsEntries: { key: string, value: string }[] = [];
    answeredQuestions?.forEach(question => {

      if (!question.childQuestionSets
        || question.childQuestionSets === undefined
        || question.childQuestionSets?.length === 0) {

        const answer = this.getQuestionResponse(question);
        if (question.qIntentCode.includes('~')) {
          question.qIntentCode = this.formatIntentCode(question.qIntentCode);
        }

        if (question.globalFunction !== "" && question.globalFunction !== undefined && question.globalFunction !== null) {
          if (question.globalFunction?.toLowerCase().includes('cattribute')) {
            insuredDetailsEntries.push({ key: question.qIntentCode, value: answer });
          }
        }

        // *** to handle, If the Question has dependent child questions based on current answer ***/
        const childQuestion = question.answerSets.filter(x => x.isAnswered === true)[0]?.childQuestionSets;
        if (childQuestion && childQuestion?.length > 0) {
          this.mapChildQuestionsInsuredDetailsResponse(childQuestion, insuredDetailsEntries);
        }

      } else {
        this.mapChildQuestionsInsuredDetailsResponse(question.childQuestionSets, insuredDetailsEntries);
      }
    });
    return insuredDetailsEntries;
  }

  mapChildQuestionsInsuredDetailsResponse(
    childQuestions: Question[],
    insuredDetailsEntries: { key: string, value: string }[]) {

    childQuestions?.forEach(childQuestion => {
      const childQuestionAnswer = this.getQuestionResponse(childQuestion);
      if (childQuestion?.qIntentCode !== undefined && childQuestion?.qIntentCode !== null) {
        if (childQuestion?.qIntentCode.includes('~')) {
          childQuestion.qIntentCode = this.formatIntentCode(childQuestion?.qIntentCode);
        }
      }

      if (childQuestion !== undefined && childQuestion !== null) {
        if (childQuestion.globalFunction?.toLowerCase().includes('cattribute')) {
          insuredDetailsEntries.push({ key: childQuestion.qIntentCode, value: childQuestionAnswer });
        }
      }

      // *** to handle, If the Question has dependent child questions based on current answer ***/
      const grandChildQuestion = childQuestion.answerSets?.filter(x => x.isAnswered === true)[0]?.childQuestionSets;
      if (grandChildQuestion && grandChildQuestion?.length > 0) {
        this.mapChildQuestionsInsuredDetailsResponse(grandChildQuestion, insuredDetailsEntries);
      }

    });

  }

  getRiskDetailsResponse(answeredQuestions: Question[]): any {
    // *** Fetch Question Responses which is NOT having 'CAttribute' ***//
    const riskDetailsEntries: { key: string, value: string }[] = [];
    answeredQuestions?.forEach(question => {

      if (!question.childQuestionSets
        || question.childQuestionSets === undefined
        || question.childQuestionSets?.length === 0) {

        const answer = this.getQuestionResponse(question);
        if (question.qIntentCode.includes('~')) {
          question.qIntentCode = this.formatIntentCode(question.qIntentCode);
        }

        const keyAlreadyExist = riskDetailsEntries?.filter(obj => obj.key === question.qIntentCode).length > 0
        if (!question.globalFunction?.toLowerCase().includes('cattribute') && !keyAlreadyExist) {
          riskDetailsEntries.push({ key: question.qIntentCode?.trim(), value: answer });
        }

        // *** to handle, If the Question has dependent child questions based on current answer ***/
        const childQuestion = question.answerSets.filter(x => x.isAnswered === true)[0]?.childQuestionSets;
        if (childQuestion && childQuestion?.length > 0) {
          this.mapChildQuestionsRiskDetailsResponse(childQuestion, riskDetailsEntries);
        }

      } else {
        this.mapChildQuestionsRiskDetailsResponse(question.childQuestionSets, riskDetailsEntries);
      }
    });
    return riskDetailsEntries;
  }

  mapChildQuestionsRiskDetailsResponse(
    childQuestions: Question[],
    riskDetailsEntries: { key: string, value: string }[]) {

    childQuestions.forEach(childQuestion => {

      const childQuestionAnswer = this.getQuestionResponse(childQuestion);
      if (childQuestion?.qIntentCode !== undefined && childQuestion?.qIntentCode !== null) {
        if (childQuestion?.qIntentCode.includes('~')) {
          childQuestion.qIntentCode = this.formatIntentCode(childQuestion?.qIntentCode);
        }
      }

      if (childQuestion !== undefined && childQuestion !== null) {
        if (!childQuestion.globalFunction?.toLowerCase().includes('cattribute')) {
          riskDetailsEntries.push({ key: childQuestion.qIntentCode?.trim(), value: childQuestionAnswer });
        }
      }

      // *** to handle, If the Question has dependent child questions based on current answer ***/
      const grandChildQuestion = childQuestion.answerSets?.filter(x => x.isAnswered === true)[0]?.childQuestionSets;
      if (grandChildQuestion && grandChildQuestion?.length > 0) {
        this.mapChildQuestionsRiskDetailsResponse(grandChildQuestion, riskDetailsEntries);
      }

    });

  }

  getQuestionResponse(question: Question) {
    const answer: AnswerSets = question.answerSets?.filter(x => x.isAnswered === true)[0];
    let result;

    if (answer) {
      result = (answer?.textValue !== null && answer?.textValue !== undefined && answer?.textValue !== '') ?
        answer.textValue : answer.name;

      if (question.qQuestionFormat.toLowerCase() === 'datepicker' && result !== undefined) {// "yyyy-MM-dd"
        if (moment(result, "YYYY-MM-DD", true).isValid()) {
          result = this.pagingService.convertDateIntoUTC(result);
          return result;
        } else {
          if (result.length === 10) {
            result = result.substring(6, 11) + '-' + result.substring(3, 5) + '-' + result.substring(0, 2);
          }
        }
      }
      if ((question.qQuestionFormat.toLowerCase() === 'numeric' && result !== undefined && question.localization)
        || question.qQuestionFormat.toLowerCase() === 'money') {
        result = this.sharedSettingsService.reverseFormatNumber(result);
      }
    }
    return result;
  }

  getQuestionSetsToFilter() {
    let taxSubtotalQuestionSets = this.sessionStorage.get('primaryQuestionData');

    if (taxSubtotalQuestionSets) {
      taxSubtotalQuestionSets = JSON.parse(taxSubtotalQuestionSets);
      taxSubtotalQuestionSets = taxSubtotalQuestionSets?.filter(taxQSet =>
        taxQSet.qQuestionType.toUpperCase() === this.constantService.QuestionType.Subtotal
        || taxQSet.qQuestionType.toUpperCase() === this.constantService.QuestionType.TaxOnPremium
        || taxQSet.qQuestionType.toUpperCase() === this.constantService.QuestionType.TaxSubtotal
        || taxQSet.qQuestionType.toUpperCase() === this.constantService.QuestionType.Charge
        || taxQSet.qQuestionType.toUpperCase() === this.constantService.QuestionType.TaxOnCharge
        || taxQSet.qQuestionType.toUpperCase() === this.constantService.QuestionType.Commission);
    }
    return taxSubtotalQuestionSets;
  }

  sendQuoteEmailRequest(): Observable<any> {
    const apiURL = this.sendQuoteEmailRequestURL;
    const requestBody: SendQuoteEmailRequest = {
      quoteReferenceId: this.sessionStorage.get('QuoteReferenceID'),
      userEmail: this.sessionStorage.get('b2bemail')
        ? this.sessionStorage.get('b2bemail')
        : this.sessionStorage.get('Email'),
      languageId: this.sessionStorage.get('LanguageId'),
      sponsorId: this.sessionStorage.get('SponsorId'),
      sponsorName: this.sessionStorage.get('SponsorName'),
      sendEmail: true
    };
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((result: any) => {
        return result;
      }));

  }

  getPspName(): Observable<any> {
    const apiURL = this.getPspNameUrl;
    const requestBody: SendQuoteEmailRequest = {
      quoteReferenceId: this.sessionStorage.get('QuoteReferenceID'),
      userEmail: this.sessionStorage.get('Email'),
      languageId: this.sessionStorage.get('LanguageId'),
      sponsorId: this.sessionStorage.get('SponsorId'),
      sponsorName: this.sessionStorage.get('SponsorName'),
      sendEmail: true
    };
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((result: any) => {
        return result;
      }));

  }

  isQuoteEmailNotificationEnable(): Observable<any> {
    const apiUrl = this.isQuoteEmailEnable;
    const requestBody: IsQuoteEmailEnabled = {
      productIds: [this.sessionStorage.get('ProductId')],
      sponsorId: this.sessionStorage.get('SponsorId'),
      customerId: this.sessionStorage.get('customerId')
    };
    return this.commonHttpService.httpPostServiceWithBearer(apiUrl, requestBody).pipe(
      map((result: any) => {
        return result;
      }));
  }

  // Format intentcode for repeating group
  formatIntentCode(intentCode) {
    const stringArray = intentCode.split('~');
    const formattedString = stringArray[0] + '[' + stringArray[1] + '].' + stringArray[2];
    return formattedString;
  }

  getSelectedMCICarrierQuoteSummary(): CarrierQuoteSummary {
    const quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    const multiCarrierQuoteSummary = quoteSummary?.multiCarrierQuoteSummary;
    this.selectedMCIQuoteSummary = multiCarrierQuoteSummary?.carrierQuoteSummaryList?.
      find(carrier => carrier.carrierId === multiCarrierQuoteSummary.selectedCarrierId);
    const selectedCoverage: MultiQuoteCoverage[] = [];
    this.selectedMCIQuoteSummary?.coverageList?.filter(coverage => {
      if (coverage.id === multiCarrierQuoteSummary.selectedCoverageId) {
        selectedCoverage.push(coverage);
      }
      this.selectedMCIQuoteSummary.coverageList = selectedCoverage;

    });
    return this.selectedMCIQuoteSummary;
  }

  // stash master product data for MCI.
  stashMasterProductData() {
    const productId = this.sessionStorage.get('ProductId');

    if (productId > 0) {
      this.baseProductTemplateService.getMasterProduct(productId).subscribe(masterProduct => {
        if (masterProduct) {
          this.sessionStorage.set(BaseConstantService.Key_IsATPDocumentDownloadForMCI, masterProduct.allowDocGenerationForMCI);
          this.sharedSettingsService.isDefaultListViewForMCI = masterProduct.isDefaultListView;
        }
      });
    }
  }

  getQuoteEvidenceDocType() {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.getQuoteEvidenceDocTypes}`;
    const languageId = this.sessionStorage.get('LanguageId');
    const sponsorId = this.sessionStorage.get('SponsorId');
    const isB2cMultiCarrierQuotingEnabled = this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled;
    const productId = isB2cMultiCarrierQuotingEnabled ? this.salesSettingService.childProductId : this.sessionStorage.get('ProductId');

    return this.commonHttpService.httpGetServiceWithBearer(`${url}/${productId}/${sponsorId}/${languageId}`).pipe(
      map((result: any) => {
        return result;
      })
    )
  }
  setQuoteDetail(quoteDetail: IQuoteHistoryExtension) {
    this.quoteDetail = quoteDetail;
  }

  getQuoteDetail(): IQuoteHistoryExtension {
    return this.quoteDetail;
  }

  public ProductCreation(): Observable<any> {
    const ProductCreation = {
      languageId: this.sessionStorage.get('LanguageId'),
      ProductTemplateId: this.sessionStorage.get('ProductId'),
      answeredQuestionResponse: this.getResponseDictionary(this.questionSetService.answeredQuestions),
    };
    return this.commonHttpService.httpPostService(this.ProductCreationurl, ProductCreation).pipe(
      map((result: any) => {
        return result;
      }));
  }

  public assignOrUnassignUserToAction(actionID: number, isUnAssignRequest: boolean): Observable<any> {
    const assignOrUnassignUserToAction = {
      actionId: actionID,
      quoteReferenceId: this.sessionStorage.get('QuoteReferenceID'),
      username: this.sessionStorage.get('b2bemail'),
      isUnAssignRequest: isUnAssignRequest
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.assignOrUnassignUserToActionUrl, assignOrUnassignUserToAction).pipe(
      map((result: any) => {
        return result;
      }));

  }

  updatePolicySection(section: PolicySection) {
    this.policySection = section;
  }

  get getPolicySection(): PolicySection {
    return this.policySection;
  }

  clearPolicySection() {
    this.policySection = {
      isNetPremiumOverride: false,
      totalNetPremium: 0,
      subtotalTaxModel: null,
    };
  }

  UpdatePaymentFrequencyIntentCode(value: string) {
    this.paymentFrequencyIntentCodeSubject.next(value);
  }

  get GetPaymentFrequencyIntentCode(): Observable<string> {
    return this.paymentFrequencyIntentCodeSubject.asObservable();
  }

  UpdateRecalculationSubject(value: boolean) {
    this.recalculateTaxSubject.next(value);
  }

  get GetRecalculationSubject(): Observable<boolean> {
    return this.recalculateTaxSubject.asObservable();
  }

  UpdateRecalculateClickedSubject(value: boolean) {
    this.recalculateClickedSubject.next(value);
  }

  get GetRecalculateClickedSubject(): Observable<boolean> {
    return this.recalculateClickedSubject.asObservable();
  }

  UpdateUndoPremiumSubject(value: boolean) {
    this.undoPremiumSubject.next(value);
  }

  get GetUndoPremiumSubject(): Observable<boolean> {
    return this.undoPremiumSubject.asObservable();
  }

  SetPremiumBeforDiscount(isOverride: boolean = false) {
    const isB2b = this.sharedSettingsService.isB2B();
    if (isB2b && this.IsB2cSingleCarrierMultiQuoteEnabled()) {
      this.quoteSummary = this.getQuoteSummary();
      const coverageInformationBeforeDiscount = this.getBeforeDiscount();
      const optionalCover = this.getOptionalCover();
      const initialOptionalCover = this.getInitialOptionalCover();

      if (optionalCover != null) {

        this.quoteSummary.coverageInformation.coverageInformation.forEach(coverage => {
          coverageInformationBeforeDiscount.forEach(BeforeDiscount => {

            if (coverage.intentCode === BeforeDiscount.intentCode && coverage?.coverageTermValue === BeforeDiscount?.coverageTermValue) {
              coverage.grossPremium = BeforeDiscount.grossPremium;
              coverage.netPremium = BeforeDiscount.netPremium;
            }

            coverage.additionalcoverageInformation?.forEach(additionalCoverage => {
              BeforeDiscount.additionalcoverageInformation.forEach(optionalcover => {
                if ((optionalcover.intentCode === additionalCoverage.intentCode
                  && additionalCoverage.coverageTermValue === optionalcover.coverageTermValue)
                  // primarycover intentcode should also match along with optionalcover intentcode else
                  // it will mismatch the premium of wrong optionalcover
                  && coverage.intentCode === BeforeDiscount.intentCode) {
                  additionalCoverage.grossPremium = optionalcover.grossPremium
                  additionalCoverage.netPremium = optionalcover.netPremium
                }
              });
            });

            if (optionalCover != null && coverage.intentCode === this.primaryCover.intentCode
              && coverage.intentCode === BeforeDiscount.intentCode) {
              BeforeDiscount.additionalcoverageInformation?.forEach(element => {

                optionalCover?.forEach(selectOptionalCover => {

                  if (selectOptionalCover?.intentCode === element?.intentCode &&
                    selectOptionalCover?.coverageTermValue === element?.coverageTermValue) {
                    selectOptionalCover.grossPremium = element?.grossPremium;
                    selectOptionalCover.netPremium = element?.netPremium;
                  }

                  if (initialOptionalCover && isOverride) {
                    initialOptionalCover?.forEach(c => {
                      if (selectOptionalCover?.intentCode === c?.intentCode) {
                        selectOptionalCover.isCoveragePreselected = c.isCoveragePreselected;
                      }
                    });
                  }

                  if (selectOptionalCover?.coverageTermWithPricing &&
                    selectOptionalCover.coverageTermWithPricing?.length > 0) {
                    selectOptionalCover?.coverageTermWithPricing?.forEach(term => {
                      if (term?.isCoverageTermSelected === true && element?.intentCode === selectOptionalCover?.intentCode &&
                        (selectOptionalCover?.coverageTermValue === element?.coverageTermValue &&
                          term?.coverageTermValue === element?.coverageTermValue)) {
                        term.grossPremium = element?.grossPremium;
                        term.netPremium = element?.netPremium;
                      }

                    });
                  }

                });

              });
            }
          })
        });
        this.setOptionalCover(optionalCover);
        this.setQuoteSummary(this.quoteSummary);
      }
    }
  }

  removeSpacesAndConvertToUpperCase(input: string): string {
    const stringWithoutSpaces = input.replace(/\s/g, '');
    const uppercasedString = stringWithoutSpaces.toUpperCase();
    return uppercasedString;
  }

  shouldHidePolicyQuestions(): Observable<boolean> {
    const { statusText, referralOutcome, quoteAccepted, quoteOutCome } = this.getQuoteDetail() ?? {
      statusText: undefined, referralOutcome: undefined,
      quoteAccepted: undefined,
      quoteOutCome: undefined
    };
    const quoteReferenceId = this.sessionStorage.get('QuoteReferenceID');

    const request = {
      productTemplateId: this.sessionStorage.get('ProductId'),
      sponsorId: this.sessionStorage.get('SponsorId'),
      userId: this.sessionStorage.get('b2bemail') ?? this.sessionStorage.get('LoggedInEmail'),
      languageId: this.sessionStorage.get('LanguageId'),
      quoteStatus: statusText,
      referralOutcome: referralOutcome,
      quoteAccepted: quoteAccepted,
      quoteOutCome: quoteOutCome
    };
    if (quoteReferenceId && request.userId && request.quoteStatus) {
      return this.commonHttpService.httpPostServiceWithBearer(this.shouldHidePolicyQuestionsUrl, request).pipe(
        map((result: any) => {
          return result;
        }));
    } else {
      return of(false);
    }
  }

  createQuoteDetailObj({
    quoteId, statusText, referralOutcome, productId = 0, policyId = 0, policyNumber = '', customerId = 0,
    grossPremium = 0, inceptionDate = '', expiryDate = '', policyExpiryDate = "", sponsorId = 0, productTemplateId = 0,
    quotedDate = new Date(), quoteAccepted, createdBy = '', declarationAccepted = false,
    sponsorEnglishName = '', timeZone = '', answeredQuestionResponse = [], quoteOutCome
  }: Partial<IQuoteHistoryExtension>): IQuoteHistoryExtension {
    return {
      quoteId, statusText, referralOutcome,
      productId, policyId, policyNumber, customerId, grossPremium, inceptionDate,
      expiryDate, policyExpiryDate, sponsorId, productTemplateId, quotedDate, quoteAccepted,
      createdBy, declarationAccepted, sponsorEnglishName, timeZone, answeredQuestionResponse,
      quoteOutCome
    };
  }

  getAllowableCoverageList(coverageInformation: IPrimaryCoverage): any[] {
    const allowableCoverageTermsList: any[] = []
    const allowableTerms = coverageInformation?.allowableTerms?.split(",");
    allowableTerms?.forEach(term => {
      const termValue = term.split(" ")[0];
      if (termValue.length > 0 && termValue !== "0") {
        allowableCoverageTermsList.push(term);
      }
    })
    return allowableCoverageTermsList;
  }

  private mapToSubTotalTaxModel(taxes): ISubTotalTax[] {
    const filteredSubTotal = taxes?.filter(x => x.taxType.toUpperCase() === this.constantService.QuestionType.Subtotal);
    let subtotalTaxModel: ISubTotalTax[] = [];
    if (filteredSubTotal && filteredSubTotal?.length > 0) {
      subtotalTaxModel = filteredSubTotal.map(x => ({
        intentCode: x.taxCode,
        description: x.taxTypeDescription,
        formatedRate: '',
        isAvailableInUI: x.isAvailableInUI,
        isWTWUser: x.isWTWUser,
        optionText: '',
        questionType: x.taxType,
        rate: x.value
      }));
    }

    return subtotalTaxModel;
  }

  public updateQuote(): Observable<any> {
    const quoteReferenceID = this.sessionStorage.get('QuoteReferenceID') || '';
    const isB2BFlow = this.sharedSettingsService.isB2B();

    return this.accountService.getUserProfile().pipe(
      mergeMap((userDetails: UserProfile) => {

        const updateQuotePayload = {
          isNetPremiumOverridden: this.sharedSettingsService.isNetPremiumOverridden,
          quoteReferenceID: quoteReferenceID,
          grossPremium: this.quoteSummary.ratingBindingInformations.originalGrossRate,
          totalNetPremium: this.quoteSummary.ratingBindingInformations.originalNetRate,
          answeredQuestionResponse: this.getResponseDictionary(this.questionSetService.answeredQuestions),
          productTemplateId: this.sharedSettingsService.productId,
          createdBy: isB2BFlow ? this.sharedSettingsService.b2bEmail() : userDetails.email,
          subtotalTaxModel: this.quoteSummary.ratingBindingInformations.subtotalTaxModel,
          sponsorId: this.sharedSettingsService.sponsorId,
          languageId: this.sharedSettingsService.languageId
        }

        return this.commonHttpService.httpPostServiceWithBearer(this.updateQuotesUrl, updateQuotePayload);
      })
    );
  }

  setDefaultCoverage() {
    const hasOnlydefaultCoverage = this.quoteSummary.coverageInformation.coverageInformation.some(cov =>
      cov.coverageTermValue !== cov.defaultCoverageTermText);
    if (!hasOnlydefaultCoverage) {
      this.defaultCoveragesInfo = this.quoteSummary.coverageInformation.coverageInformation;
    }
  }

  getDefaultCoverage(): IPrimaryCoverage[] {
    if (this.defaultCoveragesInfo.length > 0) {
      this.quoteSummary.coverageInformation.coverageInformation = this.defaultCoveragesInfo;
      this.sessionStorage.set('quoteSummary', JSON.stringify(this.quoteSummary));
    }
    return this.defaultCoveragesInfo;
  }

  getMultiQuoteInformation(): string {
    let multiQuoteNumber = '';
    if (!this.quoteSummary) {
      this.quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    }

    if (this.quoteSummary) {
      const multiQuote = this.quoteSummary.multiCarrierQuoteSummary?.carrierQuoteSummaryList;
      if (multiQuote) {
        const listofQuote = multiQuote.map(x => x.quoteNumber);
        multiQuoteNumber = listofQuote.join(';')
      }

    }
    return multiQuoteNumber

  }

  // Preparing selected cover JSON when getQuoteRating API again calling from review your quote
  createSelectedCoverageAndTermJson(selectedCover: IPrimaryCoverage) {
    const selectedyCoverAndTerms: any[] = [];
    // primary coverage
    selectedyCoverAndTerms.push({ CoverageCode: selectedCover?.intentCode, CoverageTerm: selectedCover?.coverageTermValue });
    // optional coverages
    if (selectedCover?.additionalcoverageInformation !== undefined) {
      selectedCover.additionalcoverageInformation.forEach((coverage: any) => {
        if (coverage.isSelected) {
          selectedyCoverAndTerms.push({ CoverageCode: coverage?.intentCode, CoverageTerm: coverage?.coverageTermValue });
        }
      });
    }
    this.salesSettingService.coverageNameAndCoverageTermSelection = JSON.stringify(selectedyCoverAndTerms);
  }

  get renewalQuoteDetails() {
    const renewalQuoteDetailsString = this.sessionStorage.get('RenewalQuoteDetails');
    return renewalQuoteDetailsString ? JSON.parse(renewalQuoteDetailsString) : null;
  }

  set renewalQuoteDetails(details) {
    this.sessionStorage.set('RenewalQuoteDetails', JSON.stringify(details));
  }

  handleQuoteOriginRenewal(quoteDetails) {
    const quoteOrigin = quoteDetails.quoteOrigin.toUpperCase();
    const isQuoteOriginRenewal = quoteOrigin === 'RENEWALS' || quoteOrigin === 'RENEWAL';

    if (isQuoteOriginRenewal) {
      const orderId = this.sessionStorage.get('OrderId');
      const associatedPolicyId = quoteDetails.associatedPolicyId;

      this.sessionStorage.set('IsRenewalQuote', true);
      if (quoteDetails?.statusText.trim().toUpperCase() !== 'QUOTEREFERRED') {
        this.sessionStorage.set('IsRenewalPolicy', true);
      }
      this.sessionStorage.set('RenewalPolicyNumber', quoteDetails.parentPolicyNumber);

      const renewalQuoteDetails = {
        quoteOrigin: BaseConstantService.Key_Renewals,
        orderId: orderId,
        associatedPolicyId: associatedPolicyId
      };

      this.renewalQuoteDetails = renewalQuoteDetails;
    }
  }

}
