import { FormFieldBase } from './form-field-base';

export class RecaptchaField extends FormFieldBase<string> {
  controlType = 'recaptcha';
  validatorMatchWith: string;
  questionFormat: string;

  constructor(options: {
    value?: string,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    type?: string,
    readOnly?: boolean,
    iconName?: string,
    mandatoryValidationMsg?: string | string[],
    callToActionLabel?: string,
    validatorMatchWith?: string,
    isVisible?: boolean,
    questionFormat?: string,
  } = {}) {
    super(options);
    this.validatorMatchWith = options['validatorMatchWith'] || '';
    this.questionFormat = options.questionFormat;
  }
}
