<div class="contact-us-container" id="divContactUs">
    <header class="header-icon-container" id="headerContainer">
        <lib-top-bar-with-icon-and-link id="topBar" [iconName]="'close'" (emitIconAction)="onClose()"></lib-top-bar-with-icon-and-link>
    </header>

    <div class="main-section" id="divMainSection">
        <h1 id="h1PrimaryHeader" class="title screen-header primary-header" [innerHTML]="data.title | safeHtml" [ngClass]="{'desktop-header-size': isDesktop,
                          'tablet-header-size': isTablet,
                          'mobile-header-size': isMobile}"></h1>
        <h6 class="description tertiary-header" [innerHTML]="data.helpText | safeHtml" id="h6TertiaryHeader"></h6>
    </div>

    <section class="sub-section" id="subSection">
        <div class="details" id="divDetails">
            <p id="pcontent" *ngIf="data.content" class="text" [innerHTML]="data.content | safeHtml"></p>
            <a w-100 mt-3 href="tel:{{data.telephoneNumber | stripHtmlTags}}" id="aTelephoneNumber">
                <lib-primary-button id="btnTelephoneNumber" [text]="data.telephoneNumber" autofocus></lib-primary-button>
            </a>
        </div>
    </section>

</div>