<div class="container container-width">
  <div class="row d-flex align-items-center title py-2">
    <div class="col-md-7 col-6">
      <b>{{this.labels.contactPreferenceAddress}}</b>
    </div>
    <div class="col-md-3 col-2">
      <b>{{this.labels.editHeaderLabel}}</b>
    </div>
    <div class="col-md-2 col-4">
      <b>{{ this.labels.defaultLabel}}</b>
    </div>
  </div>
  <div *ngFor="let add of manageAddresses.addressList; let i = index" >
    <div class="row d-flex align-items-center pt-2 pb-4 mt-2 mb-2 data">
      <div class="col-md-7 col-6">
        <div class="row d-flex align-items-start pl-3" style="flex-flow: column;">
        <div>{{add.addressLine1}}</div>
        <div>{{add.town}}</div>
        <div>{{add.country}}</div>
        <div>{{add.postalCode}}</div>
        </div>
      </div>
      <div class="col-md-3 col-2">
          <a class="edit" (click)= "onEditClick(add,'edit')">{{this.labels.editLabel}}</a>
      </div>
      <div class="col-md-2 col-4 d-flex justify-content-start container" >
          <a>
              <label class="container">
                  <input id="default{{i}}" name="default" type="radio" disabled readonly [checked]="add.defaultAddress">
                  <span class="checkmark"></span>
              </label>
          </a>
      </div>
      </div>
  </div>
  <div class="row d-flex justify-content-center button-align">
    <div id="divButtonContainer" class="col-10 my-2 pt-4">
        <lib-primary-button id="btn" [text]="manageAddresses.pageInfo.btnEditText" (click)= "onAddClick('new')">
        </lib-primary-button>
    </div>
</div>   
</div>
