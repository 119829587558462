import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LayoutService } from '../../../services/layout/layout.service';
import { ModalContent } from '../../models/modal-content';
import { image } from '../../image/mci-spinner-image';
@Component({
  selector: 'lib-spinner-modal',
  templateUrl: './spinner-modal.component.html',
  styleUrls: ['./spinner-modal.component.scss']
})
export class SpinnerModalComponent implements OnInit {


 
 spinnerLogo: string 
 constructor(public dialogRef: MatDialogRef<SpinnerModalComponent>,
             @Inject(MAT_DIALOG_DATA) public data: ModalContent,
             private layoutService: LayoutService) { }

 ngOnInit(): void {
    this.dialogRef.disableClose = true; // Only close via button click
    this.dialogRef.addPanelClass('full-width-dialog'); // Injects globally declared style.
    this.layoutService.subscribeToLayoutChanges();
    this.manageScreenSizing();
    this.spinnerLogo=image;
  }
 get isMobile(): boolean {
    return this.layoutService.isSmallView();
  }

 get isTablet(): boolean {
    return this.layoutService.isMediumView();
  }

 get isDesktop(): boolean {
    return this.layoutService.isLargeView();
  }

 private manageScreenSizing() {
  const width = this.isMobile ? '75%' : '470px';
  const height = this.isMobile ? '30%' : 'auto';
  this.dialogRef.updateSize(width, height);
  }
 closeDialog() {
    this.dialogRef.close('close');
  }
}