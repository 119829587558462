<div [formGroup]="form" *ngIf="!isAutoSuggestionVisible" [ngClass]="{'textbox-container-medium': !isSmallView}"
  class="textbox-container" matTooltip={{field.value}} [matTooltipDisabled]="!showTooltip" matTooltipPosition="right"
  matTooltipClass="tooltipClass">
  <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-readonly':field.readOnly}">
    <mat-label id="matLabel" [innerHTML]="field.label | safeHtml"></mat-label>
    <input class="truncateBigWord" [type]="field.type" matInput formControlName="{{field.key}}" [id]="field.key"
      [readonly]="field.readOnly" [pattern]="field.regexValidation"
      (keydown.enter)="valueChangeOnKeyPressed($event,field)"
      [attr.maxLength]="field.maxLength !== undefined && field.maxLength > 0 ? field.maxLength : null"
      (blur)="onBlurEvent($event,field)" />

    <span matPrefix *ngIf="field.iconName !=''">
      <mat-icon id="iconName" matPrefix>{{field.iconName}}</mat-icon>
    </span>
    <button id="btnCallToAction" *ngIf="field.callToActionLabel !=''" mat-button matSuffix
      (click)="callToActionOnClick()">{{field.callToActionLabel}}</button>
    <mat-error id="matError">{{getSpecificErrorMessage()}}</mat-error>
  </mat-form-field>

  <lib-secondary-button *ngIf="field.enableSearchButton" [text]="field.searchButtonText" [tag]="field.key"
    (clicked)="valueChangeOnKeyPressed($event,field)" [emitRawEvent]="true"
    [disabled]="!form.get(field.key)?.value"></lib-secondary-button>

</div>

<div *ngIf="isAutoSuggestionVisible" [ngClass]="{'textbox-container-medium': !isSmallView}" class="textbox-container"
  matTooltip={{field.value}} [matTooltipDisabled]="!showTooltip" matTooltipPosition="right"
  matTooltipClass="tooltipClass">
  <lib-auto-suggest-input [autoSuggestListForm]="form" [field]="field" [placeHolderLabel]="field.label"
    (onAutoSuggestBlur)="onBlurEvent($event?.event,$event?.field)"
    (autoSuggestChange)="valueChangeOnKeyPressed($event?.event,$event?.field)">
  </lib-auto-suggest-input>
  <mat-error id="autoSuggestMatError" *ngIf="this.formControl.touched && !this.formControl.valid">{{getSpecificErrorMessage()}}</mat-error>
</div>
