import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TextAreaFieldMapper } from '../field-mapper-strategy/strategies/textarea-field-mapper';
import { Question } from '../../../models';
import { TextAreaField } from '../../../form-field/textarea-field';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';

const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
const minLengthErrorMessageLabel = 'Shared.lblMinLengthErrorMessage';
const maxLengthErrorMessageLabel = 'Shared.lblMaxLengthErrorMessage';

@Component({
  selector: 'lib-textarea-data-capture',
  templateUrl: './textarea-data-capture.component.html',
  styleUrls: ['./textarea-data-capture.component.scss']
})
export class TextAreaDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Output() updateEmitter = new EventEmitter();
  @Input() nextBtn: string;
  textareaForm: FormGroup;
  field: TextAreaField;
  mandatoryErrorMessageText: string;
  minLengthErrorMessage: string;
  maxLengthErrorMessage: string;


  constructor(
    private formGroupService: FormGroupControlService,
    private fieldMapper: TextAreaFieldMapper,
    private translate: TranslateService) { }

  ngOnInit() {

    this.applyTranslations();
    this.createFormGroup();

  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, [
      this.mandatoryErrorMessageText,
      '',
      '',
      '',
      this.minLengthErrorMessage,
      this.maxLengthErrorMessage]);
    this.textareaForm = this.formGroupService.toFormGroup([this.field]);
  }


  submitTextAnswer(answeredData): void {
    const val = this.textareaForm.get(this.field.key).value;
    answeredData.name = val;
    answeredData.isAnswered = true;
    this.updateEmitter.emit();
  }

  private applyTranslations() {
    this.translate.get([
      mandatoryErrorMessageLabel,
      minLengthErrorMessageLabel,
      maxLengthErrorMessageLabel]).subscribe((res) => {
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
        this.minLengthErrorMessage = res[minLengthErrorMessageLabel];
        this.maxLengthErrorMessage = res[maxLengthErrorMessageLabel];
      });
  }
}
