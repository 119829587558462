<section [ngClass]="{'section-container-small': isMobileView}">
    <ng-template id="sponsorContentSections" [ngTemplateOutlet]="sponsorContentSections"></ng-template>
</section>

<ng-template #sponsorContentSections>
    <div id="divSectionHeaderContainer" class="section-heading-container">
        <div id="divPrimaryHeader" [ngClass]="{'large-title': isDesktopView, 'medium-title': !isDesktopView}"
            class="brand-h2 primary-header" *ngIf="contentSectionObj?.headingText"
            [innerHtml]="contentSectionObj.headingText | safeHtml"></div>
        <div id="divSecondaryHeader" class="secondary-header" *ngIf="contentSectionObj?.subheadingText"
            [innerHtml]="contentSectionObj.subheadingText | safeHtml">
        </div>
    </div>
    <div id="divImageCaptionContainer" class="image-caption-container" *ngIf="contentSectionObj.sectionArray?.length > 0;else additionalText">
        <ng-container *ngFor="let subSectionObject of contentSectionObj.sectionArray">
            <div class="img-container" [ngClass]="{'img-container-tablet-desktop': !isMobileView}">
                <div id="divImageCaptionWrapper" class="image-caption-wrapper">
                    <div id="divImgElement" class="imgElement" *ngIf="subSectionObject?.image">
                        <img id="imgSponsor" class="mx-auto mb-3"
                            [src]="'data:image/png;base64,'+subSectionObject.image" />
                    </div>
                    <div>
                        <span id="tertiaryHeaderSpan" *ngIf="subSectionObject?.captionText" class="tertiary-header"
                            [innerHtml]="subSectionObject.captionText | safeHtml"></span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

   <ng-template #additionalText>
    <div class="body-section">
        <p id="description" class="tertiary-header" *ngIf="contentSectionObj?.additionalText" [innerHTML]="contentSectionObj.additionalText | safeHtml"></p>
      </div>
   </ng-template>

</ng-template>
