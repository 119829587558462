export interface IErrorState {
  header: string;
  primaryMessage: any;
  secondaryMessage: any;
  buttonLabel: string;
  isCustomerBlocked: boolean;
  signInFailed?: boolean;
}

export class ErrorState implements IErrorState {
  public header: string;
  public primaryMessage: any;
  public secondaryMessage: any;
  public buttonLabel: string;
  public isCustomerBlocked: boolean;
  public signInFailed?: boolean;

  constructor(
    header: string,
    primaryMessage: any,
    secondaryMessage: any = null,
    buttonLabel: string = null,
    isCustomerBlocked: boolean = false,
    signInFailed: boolean = false) {

    this.header = header;
    this.primaryMessage = primaryMessage;
    this.secondaryMessage = secondaryMessage;
    this.buttonLabel = buttonLabel;
    this.isCustomerBlocked = isCustomerBlocked;
    this.signInFailed = signInFailed;

  }
}
