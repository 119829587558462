import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { FileUploadField } from '../../../form-field/file-upload-field';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LayoutService } from '../../../../services/layout/layout.service';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';


export function fileSizeValidator(files, maxSize): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let fileSizeValidatorflag = false;
    const file = control.value;
    if (file) {
      const fileSize = files.target.files[0].size;
      if (fileSize > maxSize) {
        fileSizeValidatorflag = true;
      } else {
        fileSizeValidatorflag = false;
      }
    }
    return fileSizeValidatorflag === true ? { inValidSize: true } : null;
  };
}

export function fileExtensionValidator(validExt: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let isAllowedFileType = false;
    if (control.value) {
      const fileExt = control.value.split('.').pop().toLowerCase();
      validExt.split(',').forEach(ext => {
        if (ext.trim() === fileExt) { isAllowedFileType = true; }
      });
    }
    return isAllowedFileType === false ? { invalidFileType: true } : null;
  };
}

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})

export class FileUploadComponent implements OnInit, OnChanges {

  @ViewChild('fileInput') fileInput: ElementRef;
  @Output() fileUploadEvent = new EventEmitter();
  @Input() field: FileUploadField;
  @Input() form: FormGroup;
  @Input() isNextButtonDisabled: boolean;
  @Input() maxUploadedFiles: number;
  @Input() maxFileCount: number;
  @Input() mandatoryDocError: boolean;
  public isSmallView: boolean;
  public mandatoryErrorMessage: string;
  public maxRangeErrorMessage: string;
  public inValidFileTypeErrorMessage: string;
  public mandatoryDocErrorMessage: string;
  public maxFileErrorMsg: string;
  uploadPanel = false;
  fileCount = 0;

  constructor(private layoutService: LayoutService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    if (Array.isArray(this.field.mandatoryValidationMsg)) {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg[0];
      this.maxRangeErrorMessage = this.field.mandatoryValidationMsg[7];
    } else {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg;
      this.maxRangeErrorMessage = this.field.maxRangeErrorMessage;
      this.inValidFileTypeErrorMessage = this.field.inValidFileTypeErrorMessage;
      this.mandatoryDocErrorMessage = this.field.mandatoryDocErrorMessage;
      this.maxFileErrorMsg = this.field.maxFileErrorMsg;
    }
  }

  ngOnChanges() {
    if (this.maxUploadedFiles) {
      this.fileCount = this.maxUploadedFiles;
    }
  }

  uploadFileEvt(uploadedFile: any) {
    if (uploadedFile.target.files && uploadedFile.target.files[0]) {
      Array.from(uploadedFile.target.files).forEach((file: File) => {
        this.fileUploadEvent.emit(file);
      });
    }
  }

  get formControl(): any {
    return this.form.controls[this.field.key];
  }

  onFileUpload(event) {
    this.uploadPanel = true;
    this.form.controls[this.field.key].setValidators([
      fileSizeValidator(event, this.field.maxSize),
      fileExtensionValidator(this.field.allowedFileType)
    ]);
  }

  getSpecificErrorMessage() {
    // ** adding the below and condition so that it will not throw validation message after upload all mendatory docs
    if (this.formControl.invalid && this.formControl.hasError('required') && !this.mandatoryDocError && this.isNextButtonDisabled) {
      return this.mandatoryErrorMessage;
    } else if (this.formControl.hasError('inValidSize') && !this.formControl.hasError('required') && (this.fileCount < this.maxFileCount)) {
      return this.maxRangeErrorMessage;
    } else if (this.formControl.hasError('invalidFileType') && !this.formControl.hasError('required')
      && (this.fileCount < this.maxFileCount)) {
      return this.inValidFileTypeErrorMessage;
    } else if (this.formControl.invalid && this.formControl.hasError('required') && this.mandatoryDocError && this.isNextButtonDisabled) {
      return this.mandatoryDocErrorMessage;
    }

  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

}
