import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { MsalAuthenticationService } from '../../../../services/common-auth/msalauthentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../../services/layout/layout.service';
import { ConfigService } from '../../../../services/config/config.service';
import { PagingService } from '../../../../services/app-insights/paging-service';
import { Meta } from '@angular/platform-browser';
import { BaseConstantService } from '../../../../services/constant/base-constant.service';
import { CookieBannerService } from 'projects/wtw-ui-components/src/lib/services/cookie-banner/cookie-banner.service';

const loggedOutHeaderLabel = 'Shared.lblSessionLoggedOut';
const loggedoutMessageLabel = 'Shared.lblSessionLoggedOutMessage';
const returnToHomePageLabel = 'Shared.lblReturnToHomepage';

@Component({
  selector: 'lib-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  loggedOutHeaderText: string;
  loggedOutMessageText: string;
  returnToHomePageText: string;
  private sponsorName: string;
  isDesktop = false;
  isTablet = false;
  isMobile = false;
  showReturnToHomePage = true;

  constructor(
    private appConfig: ConfigService,
    private msalService: MsalAuthenticationService,
    private route: ActivatedRoute,
    private sessionStorage: SessionStorageService,
    private router: Router,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private metaTag: Meta,
    private baseConstantService: BaseConstantService,
    private pagingService: PagingService,
    private cookieBannerService: CookieBannerService,
  ) { }

  ngOnInit() {
    this.metaTag.updateTag(
      { name: 'robots', content: BaseConstantService.metaDataDenySearch }
    );

    if (this.route.snapshot.queryParams['event'] ==='sessionlogout') {
      this.showReturnToHomePage = false;
      // *** To disable browser back navigation ***//
      window.history.pushState(null, null, null);
      window.onpopstate = function () {
        window.history.go(1);
      }
    }

    this.pagingService.registerPageDetails('Logout');
    this.applyTranslations();
    this.msalService.removeMSALItems();
    this.cookieBannerService.clearLocalStorage();
    // this.cookieBannerService.clearSessionStorage(); Commenting out this line TODO check code for Bug #162777 fixes
    this.cookieBannerService.clearExternalIntegrationSessionStorage();
    this.sponsorName = this.sessionStorage.get('SponsorName');
    this.checkDeviceWidth();
  }

  private applyTranslations() {
    this.translate.get([loggedOutHeaderLabel, loggedoutMessageLabel, returnToHomePageLabel]).subscribe((res) => {
      this.loggedOutHeaderText = res[loggedOutHeaderLabel];
      this.loggedOutMessageText = res[loggedoutMessageLabel];
      this.returnToHomePageText = res[returnToHomePageLabel];
    });
  }

  returnToHomePage() {
    if (this.sponsorName) {
      this.sessionStorage.set('SponsorName', this.sponsorName);
    }
    this.router.navigate(['**']);
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }
}


