import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Question } from '../../../models';

@Component({
  selector: 'lib-option-list-data-capture',
  templateUrl: './option-list-data-capture.component.html',
  styleUrls: ['./option-list-data-capture.component.scss']
})
export class OptionListDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Output() updateEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  submitOptionListAnswer(answeredData) {
    this.qnData.answerSets.forEach(as => {
      as.isAnswered = (as === answeredData);
    });

    this.updateEmitter.emit();
  }
}
