<div *ngIf="previousAnswers?.length > 0"
    [ngClass]="{'mobile':isMobile, 'tablet':isTablet, 'desktop':isDesktop, 'full_height': !isContainer && isDesktop}">
    <h3 class="section-title" [class.question-container]="grossPremiumValue?.length > 0 && grossPremiumValue!=='' && showHeader" *ngIf="grossPremiumValue?.length > 0 && grossPremiumValue!=='' && showHeader" id="h3GrossPremium">{{grossPremiumText}}: {{ grossPremiumValue }}
    </h3>
    <h3 class="section-title" [class.style-bottom-border]="isCompressedViewOfPreviousAnswers" *ngIf="showHeader"
        id="h3SectionTitle">{{additionalLabelText?additionalLabelText:'Shared.lblPreviousAnswers' | translate}}
        <ng-container *ngIf="additionalLabel">&nbsp;-&nbsp;{{additionalLabelText?additionalLabelText:additionalLabel}}
        </ng-container>
    </h3>
    <ng-container *ngIf="previousAnswers?.length > 0">
        <div id="divQuestionContainer" [class.question-container]="!isCompressedViewOfPreviousAnswers"
            *ngFor="let answer of previousAnswers;  let i = index">
            <div
                [ngClass]="{'style-bottom-border':(i==arrayLength && isCompressedViewOfPreviousAnswers && answer.childQIntentCode!=='')||(isNotGrouped && isCompressedViewOfPreviousAnswers)}">
                <!-- Non Group questions -->
                <lib-wide-button id="btnAppWideButton"
                    *ngIf="!answer.isGroup && answer?.maxRepeats===0 && answer.qQuestionFormat!== repeatingGroupLengthKey"
                    [subheading]="answer.qDescription" [label]="answer.qSubDescription"
                    [details]="getQuestionsAnswer(answer)"
                    [callToAction]="editText" [disabled]="isDisabled(answer)" [tag]="answer"
                    (clicked)="onClick($event)" [isMaxRepeat]="answer?.maxRepeats>0?answer.maxRepeats:0"
                    [panelState]="answer?.panelState">
                </lib-wide-button>
                <!-- Group questions -->
                <ng-container *ngIf="answer.isGroup && answer?.maxRepeats===0">
                    <lib-wide-button id="btnFlaggedAnswers" [label]="answer.qSubDescription"
                        [questions]="getChildAnswers(answer)[0]" [details]="getChildAnswers(answer)[1]"
                        [callToAction]="editText" [deleteCallToAction]="deleteText"
                        [disabled]="isDisabled(answer)" [tag]="answer"
                        (clicked)="onClick($event)" (deleteClicked)="onDeleteClick($event)"
                        [isMaxRepeat]="answer?.maxRepeats>0?answer.maxRepeats:0" [panelState]="answer?.panelState">
                    </lib-wide-button>
                </ng-container>
                <!-- Repeating Group questions -->
                <ng-container *ngIf="answer.maxRepeats>0">
                    <lib-wide-button-with-accordion id="btnFlaggedAnswers" [label]="answer.qSubDescription"
                        [questions]="getChildAnswers(answer)[0]" [details]="getChildAnswers(answer)[1]"
                        [callToAction]="editText" [deleteCallToAction]="deleteText"
                        [disabled]="isDisabled(answer)" [tag]="answer"
                        (clicked)="onClick($event)" (deleteClicked)="onDeleteClick($event)"
                        [isMaxRepeat]="answer?.maxRepeats>0?answer.maxRepeats:0" [panelState]="answer?.panelState">
                    </lib-wide-button-with-accordion>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="previousAnswers?.length <= 0">
        <p class="default-text" id="PreviousAnswers">{{'Shared.lblPreviousAnswersPlaceholder' | translate}}</p>
    </ng-container>
</div>
