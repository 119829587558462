import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewEncapsulation, Inject } from '@angular/core';
import { slideInTrigger } from '../../../../shared/animation/slidein-animation';
import { LayoutService } from '../../../../services/layout/layout.service';
import { DropdownItem, UserName } from '../../../../shared/models/index';
import { SponsorService } from '../../../../services/sponsor/sponsor.service';
import { BaseAccountService } from '../../../../services/account/base-account.service';
import { MsalAuthenticationService } from '../../../../services/common-auth/msalauthentication.service';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { Router, ActivatedRoute, RouterEvent, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { endPoints } from '../../../core/app.config';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'lib-topbar-dropdown',
  templateUrl: './topbar-dropdown.component.html',
  styleUrls: ['./topbar-dropdown.component.scss'],
  animations: [slideInTrigger],
  encapsulation: ViewEncapsulation.None
})


export class TopbarDropdownComponent implements OnInit, OnChanges {

  isVisible = false;
  isUserLoggedIn: boolean;
  showSavedQuote: boolean;
  langCategories: DropdownItem[];
  defaultLang: DropdownItem;
  globeIcon = 'language';
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  logoutText = 'Logout';
  @Input() loginRegisterText?: string;
  @Input() profileMenuItems: [];
  loggedInUserName: string;
  url: string;
  profileMenuItemsData = [];
  selectedLanguage: DropdownItem;
  @Output() navigateLink = new EventEmitter<string>();


  constructor(public router: Router,
              @Inject(DOCUMENT) private document: Document,
              private layoutService: LayoutService,
              private sponsorService: SponsorService,
              private baseAccountService: BaseAccountService,
              private msalAuthenticationService: MsalAuthenticationService,
              private sharedSettingsService: SharedSettingsService,
              private activatedRoute: ActivatedRoute,
  ) {
    this.showSavedQuote = false;
  }

  ngOnInit() {
   /*  *******************To get the current route url************************ */
    this.url = this.router.routerState.snapshot.url;
    this.checkDeviceWidth();
    this.setupSponsorPreferredLanguage();
    this.getLoggedInUser();
    this.selectedLanguage = this.defaultLang;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.profileMenuItems.length > 0) {
      this.profileMenuItemsData = this.profileMenuItems;
    }
  }


  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  /* ********************For language menu Items********************************** */
  onSelectionChange(selectedOption) {
    this.selectedLanguage = selectedOption;
    const lang = selectedOption.id;
    this.sponsorService.setSponsorPreferredLanguage(+lang);
    this.mobileMenuClose();
  }

  setupSponsorPreferredLanguage(): void {
    this.langCategories = this.sponsorService.sponsorLanguagesAsDropdownItems;
    const browserLang = this.getBrowserLanguage();
    const enabledBrowserLang = this.langCategories
      && this.langCategories.filter(lang => lang.languageCode.substring(0, 2) === browserLang.substring(0, 2))[0];
      // 'substr' function is deprecated alternative is 'substring'
    if (enabledBrowserLang && this.sponsorService.browserLangFlag === true) {
      this.sponsorService.setSponsorPreferredLanguage(enabledBrowserLang.id);
      this.defaultLang = enabledBrowserLang;
    } else if (!enabledBrowserLang && this.sponsorService.browserLangFlag === true) {
      this.sponsorService.setSponsorPreferredLanguage(this.sponsorService.defaultPreferredLanguageId);
      this.defaultLang = this.langCategories
        && this.langCategories.filter(lang => lang.id === this.sponsorService.defaultPreferredLanguageId)[0];
    } else {
      this.defaultLang = this.langCategories
        && this.langCategories.filter(lang => lang.id === this.sponsorService.sponsorPreferredLanguageId)[0];
    }
  }

  langChange(e) {
    this.sponsorService.setSponsorPreferredLanguage(+e);
  }
  // *********************************************************** */

  mobileMenuClose() {
    this.document.body.classList.remove('noScroll');
  }

  getBrowserLanguage() {
    const lang = navigator.language || window.navigator.language;
    return lang;
  }

  changeItems(e) {
    const selectedLink = e.url.substr(e.url.indexOf('.') + 1).toLowerCase();
    switch (selectedLink) {
      case 'changepassword':
        this.msalAuthenticationService.changePassword();
        break;
      case 'logout':
        this.navigateLink.emit('LoginLogoutEvent');
        break;
      case 'yourprofile':
        this.navigateLink.emit('yourprofile');
        break;
      case 'yourpolicies':
        this.navigateLink.emit('yourpolicies');
        break;
      case 'yourclaims':
        this.navigateLink.emit('yourclaims');
        break;
      case 'savedquotes':
        this.navigateLink.emit('savedquotes');
        break
      case 'renewals':
        this.navigateLink.emit('renewals');
        break;
    }
  }

  getLoggedInUser() {
    if (this.sharedSettingsService.loggedInUser) {
      this.loggedInUserName = this.sharedSettingsService.loggedInUser;
    } 
    // else if (localStorage.hasOwnProperty('msal.idtoken')) {
    //   if (this.msalAuthenticationService.isAuthenticated()) {
    //    // this.baseAccountService.getUserName().then((result: UserName) => {
    //      // this.sharedSettingsService.loggedInUser = result.firstName;
    //      // this.sharedSettingsService.customerId = result.customerID;
    //       this.loggedInUserName = this.sharedSettingsService.loggedInUser;
    //    // });
    //   } else {
    //     localStorage.removeItem('msal.idtoken');
    //     this.router.navigate(['**']);
    //   }
    // }
  }
}

