import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LayoutService } from 'projects/wtw-ui-components/src/lib/services/layout/layout.service';
import { SharedSettingsService } from 'projects/wtw-ui-components/src/lib/services/settings/settings-base.service';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'lib-recaptcha-v2',
  templateUrl: './recaptcha-v2.component.html',
  styleUrls: ['./recaptcha-v2.component.scss']
})
export class RecaptchaV2Component implements OnInit {

  public siteKey = '6LeELWgdAAAAADHHjwa8oio-sa-_3sZOF693kJeQ';
  public scriptUrl = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
  public lang = 'en';
  public action = 'homepage';
  public token = '';
  public recaptcha: any;
  public captchaVerified = false;
  @Input() field: any;
  @Input() form: FormGroup;
  @Output() cToAction = new EventEmitter();
  public isSmallView: boolean;
  public mandatoryErrorMessage: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService,
    private settingsService: SharedSettingsService,
    private reCaptchaV3Service: ReCaptchaV3Service) { }

  get formControl(): any {
    return this.form.controls[this.field.key];
  }

  ngOnInit() {
    if (this.settingsService.languageCode) {
      this.setLanguage(this.settingsService.languageCode.split('-')[0].toString());
    }
  }

  callToActionOnClick() {
    this.cToAction.emit();
  }

  getSpecificErrorMessage() {
    if (this.formControl.hasError('required')) {
      return this.mandatoryErrorMessage;
    }
  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  setLanguage(lang: string): void {
    this.lang = lang;
  }

  recaptchaVerified() {

    this.settingsService.recaptchaVerified = true;
    this.captchaVerified = !this.captchaVerified;

    // this.reCaptchaV3Service.execute(this.siteKey,
    //   this.action,
    //   (token) => {
    //     this.settingsService.recaptchaToken = token;
    //     this.catchaVerified = true;
    //   }, {
    //     useGlobalDomain: true, // optional
    //   });
  }
}
