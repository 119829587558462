import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenericErrorComponent } from './generic-error.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        GenericErrorComponent
    ],
    imports: [
      CommonModule,
      SharedModule
    ],
    exports: [
        GenericErrorComponent
    ]
})
export class ErrorModule { }
