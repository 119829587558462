import { Injectable } from '@angular/core';
import { SharedSettingsService } from '../settings/settings-base.service';
import { Router } from '@angular/router';
import { BaseConstantService } from '../constant/base-constant.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalContent, SeverityLevel } from '../../shared/models/modal-content';
import { MatDialog } from '@angular/material/dialog';
import { ModalTemplateComponent } from '../../shared/components/modal/modal-template/modal-template.component';

const translationKeys = {
  Header: 'Shared.lblNewUIUXSessionTimeoutHeader',
  InstructionText1: 'Shared.lblNewUIUXSessionInstructionText1',
  InstructionText2: 'Shared.lblNewUIUXSessionInstructionText2',
  Seconds: 'Shared.lblNewUIUXSessionTimeInSeconds',
  ContinueSession: 'Shared.btnNewUIUXContinueSession',
  Logout: 'Shared.btnNewUIUXSessionLogout'
}

@Injectable({
  providedIn: 'root'
})

export class IdleUserService {
  private inactivityTimeInSeconds;
  private countdownTimeInSeconds;

  private timeoutId: any;
  private popupCountdown: number = 0;
  private dialogRef: any;

  public translatedText = {
    Header: '',
    InstructionText1: '',
    InstructionText2: '',
    Seconds: '',
    ContinueSession: '',
    Logout: ''
  }

  constructor(private settingsService: SharedSettingsService,
              private translate: TranslateService,
              public dialog: MatDialog,
              private router: Router) {
    this.initListener();
  }

  public initIdleUserListener() {

    // if the inactivityMinutes is not configured for sponsor or it doesnt have any valid date, then set 10 min has inactive minutes    
    const backgroundTimer = (this.settingsService.inActivityMinutesForSessionTimeout
      && this.settingsService.inActivityMinutesForSessionTimeout > 0) ? this.settingsService.inActivityMinutesForSessionTimeout : 10;

    const inactivityTimeInMinutes = backgroundTimer - this.settingsService.SessionTimeoutCountdown;
    this.inactivityTimeInSeconds = inactivityTimeInMinutes * 60 * 1000;// i.e., 10-2 => 8 minutes
    this.countdownTimeInSeconds = this.settingsService.SessionTimeoutCountdown * 60; // i.e., 2 minutes Or 120 seconds
    this.startIdleTimer();
  }

  private initListener() {
    window.addEventListener('mousemove', () => this.reset());
    window.addEventListener('click', () => this.reset());
    window.addEventListener('keypress', () => this.reset());
    window.addEventListener('DOMMouseScroll', () => this.reset());
    window.addEventListener('mousewheel', () => this.reset());
    window.addEventListener('touchmove', () => this.reset());
    window.addEventListener('MSPointerMove', () => this.reset());
  }

  private reset() {
    if (this.timeoutId && this.inactivityTimeInSeconds) {
      clearTimeout(this.timeoutId);
      this.startIdleTimer();
    }
  }

  private startIdleTimer() {
    this.timeoutId = setTimeout(() => {
      // User Inactivity detected for configured minutes
      if (this.dialogRef === undefined) {
        this.openSessionTimeoutDialog();
      }
      clearTimeout(this.timeoutId);
    }, this.inactivityTimeInSeconds);
  }

  public clearSession() {
    // clearing timer and removing events, 
    // navigate to sesison timeout where the localstorage are cleared
    clearTimeout(this.timeoutId);
    this.removeListener();
    this.dialogRef?.close();
    this.router.navigate(['/sessiontimeout'], { replaceUrl: true });

  }

  private removeListener() {
    window.removeEventListener('mousemove', () => this.reset());
    window.removeEventListener('click', () => this.reset());
    window.removeEventListener('keypress', () => this.reset());
    window.removeEventListener('DOMMouseScroll', () => this.reset());
    window.removeEventListener('mousewheel', () => this.reset());
    window.removeEventListener('touchmove', () => this.reset());
    window.removeEventListener('MSPointerMove', () => this.reset());

  }

  private openSessionTimeoutDialog(): void {
    const countdownTimeInMilliSeconds = this.countdownTimeInSeconds * 1000;

    if (this.applyTranslations()) {

      const modalData: ModalContent = {

        headingText: this.translatedText.Header,
        subHeadingAdditionalText: this.translatedText.InstructionText1,
        secondsCount: this.countdownTimeInSeconds,
        secondsText: this.translatedText.Seconds,
        additionalText: '',
        instructionText: this.translatedText.InstructionText2,
        iconName: 'update',
        severityLevel: SeverityLevel.Update,
        buttons: [
          {
            buttonText: this.translatedText.ContinueSession,
            tag: true
          },
          {
            buttonText: this.translatedText.Logout,
            tag: false,
            isSecondary: true,
            hasFocus: true
          }
        ]
      };

      this.dialogRef = this.dialog.open(ModalTemplateComponent, {
        autoFocus: false, // Required to allow selective focus of button applied above.
        data: modalData
      });


      this.dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {// *** Continue Session ***//
          this.dialogRef = undefined;
        }
        else if (result === false) {//* ** Logout ***//
          clearTimeout(this.timeoutId);
          this.removeListener();
          this.dialogRef?.close();
          this.router.navigate(['/logout'], { queryParams: { event: 'sessionlogout' } });
        }
      });
    }

  }

  public async applyTranslations(): Promise<boolean> {
    await this.translate.get([
      translationKeys.Header,
      translationKeys.InstructionText1,
      translationKeys.InstructionText2,
      translationKeys.Seconds,
      translationKeys.ContinueSession,
      translationKeys.Logout
    ]).subscribe((res) => {
      this.translatedText.Header = res[translationKeys.Header];
      this.translatedText.InstructionText1 = res[translationKeys.InstructionText1];
      this.translatedText.InstructionText2 = res[translationKeys.InstructionText2];
      this.translatedText.Seconds = res[translationKeys.Seconds];
      this.translatedText.ContinueSession = res[translationKeys.ContinueSession];
      this.translatedText.Logout = res[translationKeys.Logout];
    });
    return true;
  }

}
