<div [formGroup]="form" class="row">
    <div class="col-md-9 col-12 my-2">
        <mat-label id="matLabel" [innerHTML]="field.label | safeHtml"></mat-label>        
    </div>
    <div class="col-md-3 col-12 my-2">
        <mat-radio-group color="primary" aria-label="Select an option" 
        formControlName="{{field.key}}" [id]="field.key" [disabled]="field.readOnly" >
            <mat-radio-button [class.ml-4]="!first" name="field.key" *ngFor="let option of field.options;let first = first" [value]="option.value">{{option.name}}</mat-radio-button>            
        </mat-radio-group>
    </div>
</div>

