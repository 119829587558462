<lib-tile-with-image *ngFor="let tile of tiles; index as i" 
    id="{{'tileItem' + i}}" 
    [image]="tile.image" 
    [heading]="tile.heading"
    [subheading]="tile.subheading" 
    [calltoAction]="tile.buttonText"    
    [linkTotext]="tile.linkText" 
    [tag]="tile.tag"
    [isButtonVisible]="tile.isButtonVisible"
    [newOrEditQuoteAllowed] ="tile.newOrEditQuoteAllowed"
    [isImageLeft]="(i+1)%2 === 0"
    (buttonClicked)="onButtonClick($event)"
    (linkClicked)="onLinkClick($event)">
</lib-tile-with-image>