<form *ngIf="isReady" class="pageForm my-4" [formGroup]="personalDetailsForm" id="personalDetailsForm">
    <ng-container *ngFor="let field of pageFormFields" class="form-row">
        <div class="row">
            <div class="col-md-4 d-flex align-items-center padding-bottom">
                <label class="description-label" *ngIf="field.label"><b>{{field.label}}</b></label>        
            </div>
            <div class="col-md-8 w-100" [ngClass]="!personalDetails.isEditMode ? 'mat-select-disabled' : 'mat-select' ">
                <ng-container [ngSwitch]="field.controlType">
                    <ng-container *ngSwitchCase="'datepicker'" class="dateAlign">
                        <div class="row d-flex">
                        <div class="col-10"><lib-date-picker id="dtDatePicker" [field]="field" [form]="personalDetailsForm"></lib-date-picker></div>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'countrycodephonenumber'">
                        <div class="countrycodediv">
                          <div class="mat-select-custom-style">
                            <mat-form-field [hideRequiredMarker]="true">
                              <mat-label id="matLablel">{{countryCodeLabelValue}}</mat-label>
                              <mat-select class="country-code-mat-select" [form]="personalDetailsForm" id="matSelectCountryCode" formControlName="countryCode"
                                          [disabled]="countryCodeReadOnlyValue"  [(ngModel)] ="selectedCountryCodeValue"
                                          disableOptionCentering disableRipple panelClass="myPanelClass">
                                <mat-select-trigger>{{personalDetailsForm.controls['countryCode'].value}}</mat-select-trigger>
                                <mat-option *ngFor="let option of countryCodeLookupDataValue" [value]="option.code">
                                  {{option.value}}
                                </mat-option>                              
                              </mat-select>
                              <mat-error *ngIf="personalDetailsForm.controls['countryCode'].hasError('required')">{{mandatoryErrorMessageTextValue}}</mat-error>
                            </mat-form-field>
                          </div>
                          <div class="phonenumberdiv">
                            <lib-text-box [field]=field [form]="personalDetailsForm" id="userDetailsReg" ></lib-text-box>
                          </div>
                        </div>
                      </ng-container>

                    <ng-container *ngSwitchCase="'timepicker'">
                        <div class="row d-flex">
                        <div class="col-10"><lib-time-picker id="tmTimePicker" [field]="field" [form]="personalDetailsForm"></lib-time-picker></div>
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'textbox'">
                        <div class="row d-flex">
                        <div class="col-10"><lib-text-box id="txtbox" [field]=field [form]="personalDetailsForm"></lib-text-box></div>
                        <div *ngIf="field.key == 'email'" class="col-2 justify-content-start d-flex align-items-center pad-bottom">
                            <mat-icon id="help"
                            [matTooltip]= "tooltipText"
                            matTooltipPosition="right">help</mat-icon>
                        </div>
                        </div> 
                    </ng-container>
                    <ng-container *ngSwitchCase="'textarea'">
                        <div class="row d-flex">
                        <div class="col-10"><lib-text-area id="textarea" [field]=field [form]="personalDetailsForm"></lib-text-area></div>
                        </div>
                    </ng-container> 
                    <ng-container *ngSwitchCase="'dropdown'">
                        <div class="row d-flex">
                        <div class="col-10"><lib-drop-down id="ddl" [field]="field" [form]="personalDetailsForm"></lib-drop-down></div>
                        </div>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </ng-container>
    
    <div class="row d-flex button-align-edit" id="edit" *ngIf="!personalDetails.isEditMode">
        <div class="col-10 col-md-5 my-2" id="divEditButtonContainer">
            <lib-primary-button id="btnEdit" [text]="personalDetails.pageInfo.btnEditText"
            (clicked)="onClickEvent('edit')">
            </lib-primary-button>
        </div>
    </div>
    <div class="row d-flex button-align" id="saveCancel" *ngIf="personalDetails.isEditMode">
        <div class="col-5 my-2" id="divSaveButtonContainer">
            <lib-primary-button id="btnSave" [text]="personalDetails.pageInfo.btnSaveText" [disabled]="personalDetailsForm.invalid"
            (clicked)="onClickEvent('save')"></lib-primary-button>
        </div>
        <div class="col-5 my-2" id="divCancelButtonContainer">
                <lib-primary-button id="btnCancel" [text]="personalDetails.pageInfo.btnCancelText"
                (clicked)="onClickEvent('cancel')">
                </lib-primary-button>
        </div>
    </div>
    
    </form>