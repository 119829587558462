import { Injectable } from '@angular/core';
import { FormFieldMapper } from './field-mapper-base';
import { IFieldMapperStrategy } from './field-mapper-strategy';
import { Question } from '../../../../models';
import { TextboxField } from '../../../../form-field/textbox-field';

@Injectable({
    providedIn: 'root'
})
export class TextboxFieldMapper extends FormFieldMapper<TextboxField> implements IFieldMapperStrategy {

    public order = 0;

    public canMap(question: Question): boolean {
        return question.qQuestionFormat.toLowerCase() === 'text' || question.qQuestionFormat.toLowerCase() === 'numeric';
    }

    protected mapSpecificProperties(field: TextboxField, question: Question): void {
        field.type = question.subType && question.subType.toLowerCase();
        field.minLength = question.minLength;
        field.maxLength = question.maxLength;
        field.minRange = question.rangeMinimum;
        field.maxRange = question.rangeMaximum;
        field.questionFormat = question.qQuestionFormat;
        field.regexValidation = question.regexValidation;
        field.visibility = question.visibility;
        field.margin = question.margin;
        field.parentOValue = question.parentOValue;
        field.globalFunction = question.globalFunction;
        field.onDemandDataParameters = question.onDemandDataParameters;
        field.onDemandDataSubscribers = question.onDemandDataSubscribers;
        field.odefault = question.odefault;
        if (field.type === 'email') {
            field.iconName = field.type;
        }
        field.localization = question.localization;
        field.enableSearchButton = question.enableSearchButton;
        field.searchButtonText = question.searchButtonText;

        const newOrEditQuoteAllowed = JSON.parse(sessionStorage.getItem('newOrEditQuoteAllowed'))?._value;
        const isB2BFlow = JSON.parse(sessionStorage.getItem('isb2b'))?._value;

        if ((question.qQuestionType.toLowerCase() === 'quote') && (newOrEditQuoteAllowed === false) && isB2BFlow === false ||
            (question?.globalFunction?.toLowerCase()?.includes('disable') && (question?.answerSets?.length === 1) ||
                ((question?.globalFunction?.toLowerCase()?.includes('dynamic') &&
                    !question?.globalFunction?.toLowerCase()?.includes('source')
                    && (question?.answerSets[0]?.name !== ""))))) {
            // to disable the textfield if prepopulated
            field.readOnly = true;
            field.disableMatFormField = true;

        }

        const previousAnswer = question.answerSets?.filter(ans => ans.isAnswered).pop();
        field.value = previousAnswer && previousAnswer?.name;

        // To set value if Odefault==true and Otext value is configured from questionSet
        for (const answer of question.answerSets) {
            if (!previousAnswer && answer.default) {
                field.value = answer.name;
            }
        }
    }

    protected getInstance() {
        return new TextboxField();
    }
}
