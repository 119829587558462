import { Component, OnInit } from '@angular/core';
import { ProductSubjectivityDocuments } from '../../models';
import { ProductTemplateService } from '../../../services/product-template/product-template.service';
import { DocumentWithFileDetails, DocumentWithFileDetailsPayload } from '../../models';
import { SessionStorageService } from 'angular-web-storage';
import { ByteConversionService } from '../../../services/byte-conversion/byte-conversion.service';

@Component({
  selector: 'app-policy-document',
  templateUrl: './policy-document.component.html',
  styleUrls: ['./policy-document.component.scss']
})
export class PolicyDocumentComponent implements OnInit {
  policyInfoDocuments: ProductSubjectivityDocuments;
  documentWithFileDetails: DocumentWithFileDetailsPayload;
  constructor(private productTemplateService: ProductTemplateService, private sessionStorage: SessionStorageService,
              private byteConversionService: ByteConversionService) { }

  ngOnInit() {
    const productID: number = this.sessionStorage.get('ProductId');
    const languageID: number = this.sessionStorage.get('LanguageId');
    const sponsorID: number = this.sessionStorage.get('SponsorId');
    if (productID > 0) {
      this.productTemplateService.getProductSubjectivityDocuments(productID, sponsorID, languageID).subscribe(data => {
        this.policyInfoDocuments = data;
      });
    }
  }

  public openOrDownloadDocuments(policyDoc: ProductSubjectivityDocuments) {
    this.documentWithFileDetails = {
      subjectivityDocumentID: policyDoc.subjectivityDocumentID,
      fileName: policyDoc.fileName,
      sponsorId: this.sessionStorage.get('SponsorId'),
      productTemplateId: this.sessionStorage.get('ProductId')
    };
    this.productTemplateService.getDocumentWithFileDetails(this.documentWithFileDetails).subscribe(
      (resPolicyDocuments: DocumentWithFileDetails) => {
        if (resPolicyDocuments.fileContent) {
          const byteArray = this.byteConversionService.base64DecodetoByteArray(resPolicyDocuments.fileContent);
          const file = new Blob([byteArray], { type: resPolicyDocuments.mimeType });
          const filename = resPolicyDocuments.fileName;
          const nav = (window.navigator as any);
          if (nav.msSaveOrOpenBlob) {  // For IE browser
            const fileURL =nav.msSaveOrOpenBlob(file, filename);
          } else {
            const fileURL = URL.createObjectURL(file);
            if (resPolicyDocuments.mimeType === 'application/pdf' || resPolicyDocuments.fileExtension === 'pdf') {
              window.open(fileURL, '_blank');
            } else {
              this.getFileUrltoClick(fileURL, filename);
            }
          }
        }
      });
  }

  private getFileUrltoClick(fileURL: string, filename: string) {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', fileURL);
    anchor.setAttribute('download', filename);
    anchor.click();
  }
}
