import { Component, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'lib-close-top',
    templateUrl: './close-top.component.html',
    styleUrls: ['./close-top.component.scss']
})
export class CloseTopComponent {
  @Output() clicked = new EventEmitter();

  public onClick(): void {
      this.clicked.emit();
    }

}
