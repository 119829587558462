// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .autosearch {
  display: inline-flex !important;
  flex-direction: column !important;
}
:host .custom-width {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./projects/wtw-ui-components/src/lib/shared/components/inputs/auto-search-dropdown/auto-search-dropdown.component.scss"],"names":[],"mappings":"AAEE;EACE,+BAAA;EACA,iCAAA;AADJ;AAIE;EACE,WAAA;AAFJ","sourcesContent":["\r\n:host {\r\n  .autosearch {\r\n    display: inline-flex !important;\r\n    flex-direction: column !important;\r\n  }\r\n\r\n  .custom-width {\r\n    width: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
