import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-wtw-ui-components',
  template: `
    <p>
      wtw-ui-components works!
    </p>
  `,
  styles: []
})
export class WtwUiComponentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
