import { BaseYourProfileComponent } from './base-your-profile/base-your-profile.component';
import { AddressModalComponent } from './base-your-profile/components/address-modal/address-modal.component';
import { ConsentPreferencesComponent } from './base-your-profile/components/consent-preferences/consent-preferences.component';
import { ContactPreferencesComponent } from './base-your-profile/components/contact-preferences/contact-preferences.component';
import { LangPreferenceComponent } from './base-your-profile/components/lang-preference/lang-preference.component';
import { ManageAddressesComponent } from './base-your-profile/components/manage-addresses/manage-addresses.component';
import { PersonalDetailsComponent } from './base-your-profile/components/personal-details/personal-details.component';



export const components: any[] = [
    BaseYourProfileComponent,
    ConsentPreferencesComponent,
    ContactPreferencesComponent,
    LangPreferenceComponent,
    ManageAddressesComponent,
    PersonalDetailsComponent,
    AddressModalComponent
];


export * from './base-your-profile/base-your-profile.component';
export * from './base-your-profile/components/consent-preferences/consent-preferences.component';
export * from './base-your-profile/components/contact-preferences/contact-preferences.component';
export * from './base-your-profile/components/lang-preference/lang-preference.component';
export * from './base-your-profile/components/manage-addresses/manage-addresses.component';
export * from './base-your-profile/components/personal-details/personal-details.component';
export * from './base-your-profile/components/address-modal/address-modal.component';
