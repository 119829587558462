<form *ngIf="isReady" class="pageForm my-4" [formGroup]="languagePreferenceForm" id="languagePreferenceForm">
    <div class="row">
        <div class="col-md-4 d-flex align-items-center padding-bottom">
            <label class="description-label" *ngIf="field[0].label"><b>{{field[0].label}}</b></label>        
        </div>
        <div class="col-md-8 w-100" [ngClass]="!languagePreference.isEditMode ? 'mat-select-disabled' : 'mat-select' ">
            <div class="row d-flex">
                <div class="col-10">
                    <lib-drop-down id="languagePreference" [field]="field[0]" [form]="languagePreferenceForm"></lib-drop-down>            
                </div>
            </div>
        </div>
    </div>    

    <div class="row d-flex button-align-edit" id="edit" *ngIf="!languagePreference.isEditMode">
        <div class="col-10 col-md-5 my-2" id="divEditButtonContainer">
            <lib-primary-button id="btnEdit" [text]="languagePreference.pageInfo.btnEditText"
            (clicked)="onClickEvent('edit')">
            </lib-primary-button>
        </div>
    </div>
    <div class="row d-flex justify-md-content-center button-align" id="saveCancel" *ngIf="languagePreference.isEditMode">
        <div class="col-5 my-2" id="divSaveButtonContainer">
            <lib-primary-button id="btnSave" [text]="languagePreference.pageInfo.btnSaveText"
            (clicked)="onClickEvent('save')"></lib-primary-button>
        </div>
        <div class="col-5 my-2" id="divCancelButtonContainer">
                <lib-primary-button id="btnCancel" [text]="languagePreference.pageInfo.btnCancelText"
                (clicked)="onClickEvent('cancel')">
                </lib-primary-button>
        </div>
    </div>
    </form>