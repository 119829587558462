import { FormFieldBase } from './form-field-base';

export class TimePickerField extends FormFieldBase<string> {
    controlType = 'timepicker';
    // minDate: string;
    // maxDate: string;
    default: string;
    offsetBasis: string;
    constructor(options: {
        value?: string,
        key?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        type?: string,
        readOnly?: boolean,
        iconName?: string,
        mandatoryValidationMsg?: string | string[],
        callToActionLabel?: string,
        // minDate?: string,
        // maxDate?: string,
        default?: string,
        offsetBasis?: string
    } = {}) {
        super(options);
        // this.minDate = options.minDate || '';
        // this.maxDate = options.maxDate || '';
        this.default = options.default || '';
        this.offsetBasis = options.offsetBasis || '';
    }
}
