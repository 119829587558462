import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationAlertService } from '../../../services/notification-alert/notification-alert.service';


@Component({
    selector: 'lib-notification-alert',
    templateUrl: 'notification-alert.component.html',
    styleUrls: ['./notification-alert.component.scss']
})
export class NotificationAlertComponent implements OnInit, OnDestroy {
    @Input() id = 'default-alert';
    @Input() fade = true;
    @Input() field;

    alerts = [];
    alertSubscription: Subscription;
    routeSubscription: Subscription;

    constructor(
        private router: Router,
        private alertService: NotificationAlertService) { }

    ngOnInit() {
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService.onAlert(this.id)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    this.alerts.forEach((x, k) => {
                        if (x.parentQIntentCode === alert.alertType) {
                            this.alerts.splice(k, 1);
                        }
                    });
                    return;
                }
                // add alert to array
                this.alerts.forEach((x, k) => {
                    if (x.parentQIntentCode === alert.message.parentQIntentCode) {
                        this.alerts.splice(k, 1);
                    }
                });
                this.alerts.push(alert);
            });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.alertService.clear(this.id);
            }
        });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.alertSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }


}
