import { Injectable } from '@angular/core';
import { SharedSettingsService } from '../settings/settings-base.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class CookieBannerService {
    constructor(
        private router: Router,
        protected sharedSettingsService: SharedSettingsService,

    ) { }
    checkBannerStatus(): boolean {
        if (localStorage.getItem('cookie-enable') === '-1') {
            return false;
        } else if (localStorage.getItem('cookie-enable') === '1') {
            return true;
        } else if (!localStorage.getItem('cookie-enable')) {
            localStorage.setItem('cookie-enable', '1');
            return true;
        }
    }

    clearLocalStorageExceptCookie() {
        const keys: string[] = [];
        for (let i = 0; i < localStorage.length; i++) {
            if (localStorage.key(i) !== 'cookie-enable') {
                keys.push(localStorage.key(i));
            }
        }
        for (const key of keys) {
            localStorage.removeItem(key);
        }
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    clearSessionStorage() {
        const sponsorId = sessionStorage.getItem('SponsorId');
        const customerIdentifier = sessionStorage.getItem('customerIdentifier');
        const sponsorName = sessionStorage.getItem('SponsorName');
        const productId = sessionStorage.getItem('ProductId');
        const languageId = sessionStorage.getItem('LanguageId');
        const languageCode = sessionStorage.getItem('LanguageCode');
        const QueryParams = sessionStorage.getItem('QueryParams');
        const siteActive = sessionStorage.getItem('SiteActive');
        const saveQuoteEnabled = sessionStorage.getItem('saveQuoteEnabled');
        const isPreferredLanguageSet = sessionStorage.getItem('IsPreferredLanguageSet');
        const isMultiProductLanding = sessionStorage.getItem('IsMultiProductLanding');
        const timeZoneOffset = sessionStorage.getItem('timeZoneOffset');
        const timeZone = sessionStorage.getItem('timeZone');
        const isSearchMetaDataRequired = sessionStorage.getItem('isSearchMetaDataRequired');
        const ErrorPagePreviousUrl = sessionStorage.getItem('ErrorPagePreviousUrl');
        const atpLoginExpiryTime = localStorage.getItem('ATPLoginExpiryTime');
        const idTokenExpiryTime = localStorage.getItem('idTokenExpiryTime');

        const enableVoucherBasedAccess = sessionStorage.getItem('EnableVoucherBasedAccess');
        const enableSSOBasedAccess = sessionStorage.getItem('EnableSSOBasedAccess');
        const enableICECommonAuth = sessionStorage.getItem('EnableICECommonAuth');
        const recaptchaVerified = sessionStorage.getItem('RecaptchaVerified');

        const enableMFA = sessionStorage.getItem('EnableMFA');

        const extAuthNav = sessionStorage.getItem('ExtAuthNav');

        const enableGuestPurchase = sessionStorage.getItem('EnableGuestPurchase');
        const allowCustomerIdentification = sessionStorage.getItem('allowCustomerIdentification');
        const enableAssently = sessionStorage.getItem('EnableAssently');
        const enableCustomerIdentityB2CClaims = sessionStorage.getItem('EnableCustomerIdentityB2CClaims');
        const isb2b = sessionStorage.getItem('isb2b');
        const externalData = sessionStorage.getItem('ExternalData');
        const externalValidationResult = sessionStorage.getItem('externalValidationResult');
        const tokenGenerationRedirectUrl = sessionStorage.getItem('tokenGenerationRedirectUrl');
        const CompressedViewOfPreviousAnswers = sessionStorage.getItem('CompressedViewOfPreviousAnswers');
        const isB2cSingleCarrierMultiQuoteEnabled = sessionStorage.getItem('isB2cSingleCarrierMultiQuoteEnabled');
        const customerId = sessionStorage.getItem('customerId');
        const email = sessionStorage.getItem('Email');
        const loggedInUser = sessionStorage.getItem('LoggedInUser');
        const newCreatePwdId = sessionStorage.getItem('createpasswordid');
        const EnableSalesV2Home = sessionStorage.getItem('EnableSalesV2Home');
        const legalApprovedRegionAppInsights = sessionStorage.getItem('legalApprovedRegionAppInsights');
        const gtm = sessionStorage.getItem('GTM');
        const DisplayGrossPremium = sessionStorage.getItem('DisplayGrossPremium');
        const LocalisedDateFormat = sessionStorage.getItem('LocalisedDateFormat');

        sessionStorage.clear();

        sessionStorage.setItem('SponsorId', sponsorId);
        sessionStorage.setItem('customerIdentifier', customerIdentifier);
        sessionStorage.setItem('SponsorName', sponsorName);
        sessionStorage.setItem('ProductId', productId);
        sessionStorage.setItem('LanguageId', languageId);
        sessionStorage.setItem('LanguageCode', languageCode);
        sessionStorage.setItem('QueryParams', QueryParams); // adding this to fix bug 169296 as after logout
        sessionStorage.setItem('SiteActive', siteActive);
        sessionStorage.setItem('saveQuoteEnabled', saveQuoteEnabled);
        sessionStorage.setItem('IsPreferredLanguageSet', isPreferredLanguageSet);
        sessionStorage.setItem('IsMultiProductLanding', isMultiProductLanding);
        sessionStorage.setItem('timeZoneOffset', timeZoneOffset);
        sessionStorage.setItem('timeZone', timeZone);
        sessionStorage.setItem('isSearchMetaDataRequired', isSearchMetaDataRequired);
        sessionStorage.setItem('ErrorPagePreviousUrl', ErrorPagePreviousUrl);
        localStorage.setItem('ATPLoginExpiryTime', atpLoginExpiryTime);
        localStorage.setItem('idTokenExpiryTime', idTokenExpiryTime);
        sessionStorage.setItem('EnableVoucherBasedAccess', enableVoucherBasedAccess);
        sessionStorage.setItem('EnableSSOBasedAccess', enableSSOBasedAccess);
        sessionStorage.setItem('EnableICECommonAuth', enableICECommonAuth);
        sessionStorage.setItem('RecaptchaVerified', recaptchaVerified);
        sessionStorage.setItem('EnableMFA', enableMFA);
        sessionStorage.setItem('ExtAuthNav', extAuthNav);
        sessionStorage.setItem('EnableGuestPurchase', enableGuestPurchase);
        sessionStorage.setItem('EnableAssently', enableAssently);
        sessionStorage.setItem('isb2b', isb2b);
        sessionStorage.setItem('ExternalData', externalData);
        sessionStorage.setItem('externalValidationResult', externalValidationResult);
        sessionStorage.setItem('tokenGenerationRedirectUrl', tokenGenerationRedirectUrl);
        sessionStorage.setItem('CompressedViewOfPreviousAnswers', CompressedViewOfPreviousAnswers);
        sessionStorage.setItem('isB2cSingleCarrierMultiQuoteEnabled', isB2cSingleCarrierMultiQuoteEnabled);
        sessionStorage.setItem('createpasswordid', newCreatePwdId);
        sessionStorage.setItem('EnableSalesV2Home', EnableSalesV2Home);
        sessionStorage.setItem('legalApprovedRegionAppInsights', legalApprovedRegionAppInsights);
        sessionStorage.setItem('EnableCustomerIdentityB2CClaims', enableCustomerIdentityB2CClaims);
        sessionStorage.setItem('GTM', gtm);
        sessionStorage.setItem('DisplayGrossPremium', DisplayGrossPremium);
        sessionStorage.setItem('allowCustomerIdentification', allowCustomerIdentification);
        sessionStorage.setItem('LocalisedDateFormat', LocalisedDateFormat);

        if (this.sharedSettingsService.extAuthNav === true) {
            sessionStorage.setItem('customerId', customerId);
        }

        if (this.router.url.includes('claim-submit') && this.sharedSettingsService.enableAssently === true) {
            sessionStorage.setItem('customerId', customerId);
            sessionStorage.setItem('Email', email);
            sessionStorage.setItem('LoggedInUser', loggedInUser);
        }


        // QueryParams gets clear from session storage which is causing this bug
    }

    clearExternalIntegrationSessionStorage() {
        const externalValidationResult = sessionStorage.removeItem('externalValidationResult');
    }

    getCookieValue(cookieName: string): string | null {
      const cookieString = document.cookie;
      const cookies = cookieString.split('; ');

      for (const cookie of cookies) {
        const [name, value] = cookie.split('=');

        if (cookie.split('&')?.length > 1) {
          // Check for cookie with multiple values
          for (const c of cookie.split('&')) {
            const [name, value] = c.split('=');
            if (name === cookieName) {
              return decodeURIComponent(value);
            }
          }
        }
        else if (name === cookieName) {
          return decodeURIComponent(value);
        }
      }

      return null;
    }
}
