import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { CommonHttpService } from './common-http/common-http.service';
import { endPoints } from '../shared/core/app.config';

@Injectable({
  providedIn: 'root'
})
export class CreatePasswordService {

  private getupdatecratepwdUrl = endPoints.apiUrl + endPoints.endPointName.updatecreatePasswordId;
  private geturl = endPoints.apiUrl + endPoints.endPointName.createPassword;


  constructor(private commonHttpService: CommonHttpService) { }

  updateCreateNewPasswordStatus(createPasswordId: any) {
    const getcreatepasswordUrl = this.getupdatecratepwdUrl + '/' + createPasswordId;
    return this.commonHttpService.httpGetService(getcreatepasswordUrl).subscribe(data => {
    });
  }
  validateNewCreatePassword(createPasswordId: any) {
    const getcreatepasswordUrl = this.geturl + '/' + createPasswordId;
    return this.commonHttpService.httpGetService(getcreatepasswordUrl);
  }
}
