import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { SaveUserConsents, SaveUserConsentsResponse } from '../../shared/models';
import { Observable, ReplaySubject, lastValueFrom, of, finalize, map, share, tap } from 'rxjs';
import { endPoints } from '../../shared/core/app.config';
import { UserProfile, UserProfilePayload, UserName } from '../../shared/models';
import { ICheckCustomerBlockResponse } from '../../shared/models/check-customer-block-response.model';
import { CommonHttpService } from '../common-http/common-http.service';
import { SharedSettingsService } from '../settings/settings-base.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { BaseConstantService } from '../../services/constant/base-constant.service';

@Injectable({
  providedIn: 'root'
})

export abstract class BaseAccountService {

  constructor(
    protected commonHttpService: CommonHttpService,
    protected sessionStorageService: SessionStorageService,
    protected sharedSettingsService: SharedSettingsService,
    private utilitiesService: UtilitiesService
  ) { }

  static readonly ClaimsMenuTypeID = '8';
  static readonly SalesMenuTypeID = '7';
  static readonly ProfileName = 'Customer';
  private baseApiUrl = endPoints.apiUrl;
  private getUserProfileUrl = this.baseApiUrl + endPoints.endPointName.getUserProfile;
  private getDecryptedValue = this.baseApiUrl + endPoints.endPointName.getDecryptedIdentifier;
  private getUserNameUrl = this.baseApiUrl + endPoints.endPointName.getUserName;
  private yourProfileUrl = this.baseApiUrl + endPoints.endPointName.yourProfile;
  private saveNewB2CUserUrl = this.baseApiUrl + endPoints.endPointName.saveNewB2CUser;
  private isCustomerBlockedUrl = this.baseApiUrl + endPoints.endPointName.isCustomerBlocked;
  private isAccountVerifiedUrl = this.baseApiUrl + endPoints.endPointName.isAccountVerified;
  private saveB2CUserConentUrl = this.baseApiUrl + endPoints.endPointName.saveB2CUserConsent;
  protected getMenuByProfileUrl = this.baseApiUrl + endPoints.endPointName.getMenuByProfile;
  private isValidCustomerIdentifierURL = this.baseApiUrl + endPoints.endPointName.isValidAuthCustomerIdentifier;
  protected userProfileObservable: Observable<UserProfile>;
  protected menuType: string;
  private menuByProfileCache: any;
  private menuByProfileCachedObservable: Observable<any>;
  private menuByProfileCachedKey: any;

  getUserProfile(usedCachedValue: boolean = true, ssoIdentifier: string = null,
                 isEncryptionRequired: boolean = false): Observable<UserProfile> {
    if (!this.userProfileObservable || usedCachedValue === false || isEncryptionRequired === false) {
      const requestBody = this.buildUserRequestObject(ssoIdentifier, isEncryptionRequired);
      this.userProfileObservable = this.commonHttpService.httpPostServiceWithBearer(this.getUserProfileUrl, requestBody).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }

    return this.userProfileObservable;
  }
  getDecryptedIdentifier(key, value): Observable<any> {
    const requestBody = {
      key,
      value
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.getDecryptedValue, requestBody);
  }
  getUserName(ssoIdentifier: string = null): Promise<UserName> {
    const requestBody = this.buildUserRequestObject(ssoIdentifier);
    return lastValueFrom(this.commonHttpService.httpPostServiceWithBearer(this.getUserNameUrl, requestBody));
  }

  buildUserRequestObject(ssoIdentifier: string = null, isEncryptionRequired: boolean = false) {
    let email = '';
    const isLinkToQuoteClicked = this.sessionStorageService.get('isLinkToQuoteClicked');
    const isB2BFlow = this.sharedSettingsService.isB2B();

    const isLinkToQuote = (isLinkToQuoteClicked === 'true' && isB2BFlow);
    const sponsorName: string = this.sessionStorageService.get('SponsorName');
    const sponsorId: number = this.sessionStorageService.get('SponsorId');
    const objectIdentifier: string = this.sessionStorageService.get('ObjectIdentifier');
    email = isLinkToQuote === true ? this.sessionStorageService.get('Email') : ssoIdentifier || this.sessionStorageService.get('Email');
    const languageId = this.sessionStorageService.get('LanguageId');
    const customerId = (this.sharedSettingsService.enableAssently === true || isLinkToQuote === true ||
      this.sessionStorageService.get('customerId') !== null) ? this.sessionStorageService.get('customerId') : 0;
    const requestBody: UserProfilePayload = {
      sponsorId,
      sponsorName,
      objectIdentifier,
      email,
      languageId,
      customerId,
      isEncryptionRequired
    };
    return requestBody;
  }

  yourProfile(isEncryptionRequired: boolean = false): any {
    let languageId;
    const userPrUserPreferredLangId = JSON.parse(sessionStorage.UserPreferredLanguageID)._value;
    if (sessionStorage.hasOwnProperty('UserPreferredLanguageID')) {
      languageId = userPrUserPreferredLangId ? userPrUserPreferredLangId : this.sessionStorageService.get('LanguageId');
    } else {
      languageId = this.sessionStorageService.get('LanguageId');
    }
    const requestBody: UserProfilePayload = {
      sponsorId: this.sessionStorageService.get('SponsorId'),
      languageId,
      customerId: this.sessionStorageService.get('customerId') ? this.sessionStorageService.get('customerId') : 0,
      sponsorName: this.sessionStorageService.get('SponsorName'),
      objectIdentifier: null,
      isEncryptionRequired,
      email: this.sharedSettingsService.enableAssently === true ? this.sessionStorageService.get('Email') : null,
    };

    return this.commonHttpService.httpPostServiceWithBearer(this.yourProfileUrl, requestBody).pipe(
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: true,
      }))
  }

  isAccountVerified(): Observable<boolean> {
    const apiURL = this.isAccountVerifiedUrl;
    const sponsorName: string = this.sessionStorageService.get('SponsorName');
    const sponsorId: number = this.sessionStorageService.get('SponsorId');
    const objectIdentifier: string = this.sessionStorageService.get('ObjectIdentifier');
    const email: string = this.sessionStorageService.get('Email');
    const languageId: number = parseInt(this.sessionStorageService.get('LanguageId')); // Parse the languageId as an integer
    const requestBody: UserProfilePayload = {
      sponsorId,
      sponsorName,
      objectIdentifier,
      email,
      languageId,
      customerId: 0
    };
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody);
  }

  isCustomerBlocked(): Observable<ICheckCustomerBlockResponse> {
    const sponsorName: string = this.sessionStorageService.get('SponsorName');
    const sponsorId: number = this.sessionStorageService.get('SponsorId');
    const requestBody: UserProfilePayload = {
      sponsorId,
      sponsorName,
      objectIdentifier: null,
      email: null,
      languageId: null,
      customerId: null,
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.isCustomerBlockedUrl, requestBody);
  }

  saveUserProfile(requestBody: UserProfile): Observable<UserProfile> {
    const apiURL = this.saveNewB2CUserUrl;

    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((resUserProfile: UserProfile) => {
        this.patchUserProfile(resUserProfile);
        return resUserProfile;
      })
    );
  }

  patchUserProfile(result: UserProfile) {
    if (this.userProfileObservable && result) {
      this.userProfileObservable = this.userProfileObservable.pipe(
        map(userProfile => {
          userProfile.firstName = result.firstName;
          userProfile.lastName = result.lastName;
          userProfile.email = result.email;
          userProfile.phoneNumber = result.phoneNumber;
          userProfile.countryCode = result.countryCode;
          userProfile.customerIdentifier = result.customerIdentifier;
          userProfile.customerId = result.customerId;
          userProfile.title = result.title;
          userProfile.dateOfBirth = result.dateOfBirth;
          userProfile.userId = result.userId;
          userProfile.objectIdentifier = result.objectIdentifier;
          return userProfile;
        })
      );
    }
  }

  saveB2CUserConsent(requestBody: SaveUserConsents): Observable<SaveUserConsentsResponse> {
    return this.commonHttpService.httpPostServiceWithBearer(this.saveB2CUserConentUrl, requestBody).pipe(
      map((resConsent: SaveUserConsentsResponse) => {
        if (this.userProfileObservable) {
          this.userProfileObservable.subscribe(userData => {
            userData.customerConsents = resConsent.customerConsents;
            userData.dataShareConsent = resConsent.dataShareConsent;
            this.userProfileObservable = of(userData);
            this.sessionStorageService.remove('UserConsentModel');
            this.sessionStorageService.remove('UserDataShareConsentModel');
          });
        }
        return resConsent;
      })
    );
  }

  // getMenuByProfile(profileType?: any) {
  //   const sponsorName = this.sharedSettingsService.sponsorName;
  //   const baseUrl = endPoints.baseUrl.toLowerCase();

  //   if (baseUrl.indexOf('claims') === 1) {
  //     this.menuType = BaseAccountService.ClaimsMenuTypeID;
  //   } else {
  //     this.menuType = BaseAccountService.SalesMenuTypeID;
  //   }
  //   const profileName = profileType;
  //   let languageId = this.sharedSettingsService.languageId;
  //   if (languageId === null || languageId === undefined) {
  //     languageId = 1;
  //   }
  //   const menuByProfile = `${this.getMenuByProfileUrl}/${sponsorName}/${profileName}/${this.menuType}/${languageId}`;
  //   return this.commonHttpService.httpGetServiceWithBearer(menuByProfile);
  // }

  getMenuByProfile(profileType?: any) {
    let observable: Observable<any>;
    const sponsorName = this.sharedSettingsService.sponsorName;
    const baseUrl = endPoints.baseUrl.toLowerCase();

    if (baseUrl.indexOf('claims') === 1) {
      this.menuType = BaseAccountService.ClaimsMenuTypeID;
    } else {
      this.menuType = BaseAccountService.SalesMenuTypeID;
    }
    const profileName = profileType;
    let languageId = this.sharedSettingsService.languageId;
    if (languageId === null || languageId === undefined) {
      languageId = 1;
    }
    const extAuthNav =  this.sharedSettingsService?.extAuthNav ?? false;

    const menuByProfile = `${this.getMenuByProfileUrl}/${sponsorName}/${profileName}/${this.menuType}/${languageId}/${extAuthNav}`;
    const requestPayload = `${sponsorName}_${profileName}_${this.menuType}_${languageId}_${extAuthNav}`;
    if (!this.menuByProfileCachedKey?.includes(requestPayload)) {
      this.menuByProfileCachedKey = requestPayload;
    }

    if (this.menuByProfileCache && this.menuByProfileCachedKey?.includes(requestPayload)) {
      observable = of(this.menuByProfileCache);
    } else if (this.menuByProfileCachedObservable && this.menuByProfileCachedKey?.includes(requestPayload)) {
      observable = this.menuByProfileCachedObservable;
    } else {
      this.menuByProfileCachedObservable = this.commonHttpService.httpGetServiceWithBearer(menuByProfile)
        .pipe(
          tap(res => this.menuByProfileCache = res),
          share(),
          finalize(() => this.menuByProfileCachedObservable = null)
        );
      observable = this.menuByProfileCachedObservable;
    }
    return observable;
  }

  isValidCustomerIdentifier(customerExtId: any): Observable<any> {
    if (customerExtId) {
    const apiURL = this.isValidCustomerIdentifierURL;
    const requestBody = this.buildUserRequestObject(null, true);

    requestBody.extCustomerId =customerExtId;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody);
    }
    return of(null);
  }

}

