<ng-container>
  <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription"
                            [QnSubDescription]="qSubDescription">
  </lib-data-capture-heading>
  <div class="mainForm">
    <form class="pageForm repeatingGroupPannel" id="groupPanelForm" [formGroup]="groupPanelForm" (ngSubmit)="submitGroupPanelAnswer()">
      <ng-container *ngFor="let field of pageFormFields" class="form-row">
        <div [ngStyle]="{'display' : ((field?.visibility === 'hide' || field?.isHidden === true) ? 'none' : 'unset')}">
          <div [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
               [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
               [attr.parentQIntentCode]="field.parentQIntentCode"
               [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
               [attr.iterationIndex]="field?.formFieldIterationIndex" class="description-label">
            <label *ngIf="field.descriptionLabel">{{field.descriptionLabel}}</label>
          </div>
          <ng-container [ngSwitch]="field.controlType">
            <ng-container *ngSwitchCase="'datepicker'">
              <lib-date-picker id="dtDatePicker" [field]="field" [form]="groupPanelForm"
                               [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
                               [attr.pValue]="field.parentOValue"
                               [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                               [attr.parentQIntentCode]="field.parentQIntentCode"
                               [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
                               [attr.iterationIndex]="field?.formFieldIterationIndex"
                               [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode">
              </lib-date-picker>
            </ng-container>
            <ng-container *ngSwitchCase="'textbox'">
              <lib-text-box id="txtbox" [field]=field [form]="groupPanelForm"
                            [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
                            [attr.pValue]="field.parentOValue"
                            [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                            [attr.parentQIntentCode]="field.parentQIntentCode"
                            [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
                            [attr.iterationIndex]="field?.formFieldIterationIndex"
                            [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode">
              </lib-text-box>
            </ng-container>
            <ng-container *ngSwitchCase="'money'">
              <lib-currency-box id="txtmoney" [field]=field [form]="groupPanelForm"
                                [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
                                [attr.pValue]="field.parentOValue"
                                [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                                [attr.parentQIntentCode]="field.parentQIntentCode"
                                [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
                                [attr.iterationIndex]="field?.formFieldIterationIndex"
                                [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode">
              </lib-currency-box>
            </ng-container>
            <ng-container *ngSwitchCase="'textarea'">
              <lib-text-area id="textarea" [field]=field [form]="groupPanelForm"
                             [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
                             [attr.pValue]="field.parentOValue"
                             [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                             [attr.parentQIntentCode]="field.parentQIntentCode"
                             [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
                             [attr.iterationIndex]="field?.formFieldIterationIndex"
                             [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode">
              </lib-text-area>
            </ng-container>
            <ng-container *ngSwitchCase="'dropdown'">
              <lib-drop-down id="ddl" [field]="field" [form]="groupPanelForm"
                             (valueChange)="onValueChange($event)"
                             [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
                             [attr.pValue]="field.parentOValue"
                             [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                             [attr.parentQIntentCode]="field.parentQIntentCode"
                             [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
                             [attr.iterationIndex]="field?.formFieldIterationIndex"
                             [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode">
              </lib-drop-down>
              <ng-container>
                <lib-notification-alert [field]="field"></lib-notification-alert>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'recaptcha'">
              <lib-recaptcha-v2 id="ddl" [field]="field" [form]="groupPanelForm"
                                [ngStyle]="{'display':field?.visibility==='hide' ?'none':'block','margin-left':field?.margin+'px'}"
                                [attr.pValue]="field.parentOValue"
                                [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                                [attr.parentQIntentCode]="field.parentQIntentCode"
                                [attr.aKey]="field.repeatingGroupKey?field.repeatingGroupKey:field.qIntentCode"
                                [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode">
              </lib-recaptcha-v2>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
      <div class="next-btn-container" *ngIf="maxRepeats===0">
        <lib-primary-button id="btnNext" [disabled]="groupPanelForm.invalid" [text]="nextBtn" autofocus>
        </lib-primary-button>
      </div>
      <div class="row d-flex button-align mx-0 justify-content-center button-container">
        <div class="col-6 my-2 alignLeft">
          <button type="button" class="btn btn-sm" id="AddAnother" (click)="onAddAnotherClick()"
                  [disabled]="groupPanelForm.invalid || questionSetService.formFieldIteration === maxRepeats ||
                    (questionSetService.getCurrentRepeatingGroupIndex(qnData.qIntentCode).currentlRepeatingGroupCount && (questionSetService.getCurrentRepeatingGroupIndex(qnData.qIntentCode).currentIterationIndex+1)!==questionSetService.getCurrentRepeatingGroupIndex(qnData.qIntentCode).currentlRepeatingGroupCount)"
                  autofocus>
            {{addAnotherBtnText}}
          </button>
        </div>
        <div class="col-6 my-2 alignRight">
          <button type="button" class="btn btn-sm" id="Delete" (click)="onDeleteClick()"
                  [disabled]="questionSetService.formFieldIteration===1" autofocus>
            {{deleteRepeatingGroupText}}
          </button>
        </div>
      </div>
      <div class="row d-flex button-align mx-0 justify-content-center button-container">
        <button type="button" class="btn btn-sm" id="NoMoreToAdd" (click)="noMoreToAddClick()"
                [disabled]="groupPanelForm.invalid" autofocus>
          {{noMoreToAddBtnText}}
        </button>
      </div>
    </form>
    <div class="repeatingIndex">
      ({{questionSetService.getCurrentRepeatingGroupIndex(qnData.qIntentCode).currentIterationIndex+1}}/{{maxRepeats}})
    </div>
  </div>
</ng-container>
