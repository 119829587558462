import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormGroupControlService } from 'projects/wtw-ui-components/src/lib/services/form-group/form-group.service';
import { FormFieldBase } from '../../../../form-field/form-field-base';
import { CountryCodeLookupData } from '../../../../models/user-profile.model';
import { PersonalDetails } from '../../../../models/your-profile.model';

@Component({
  selector: 'lib-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss']
})

export class PersonalDetailsComponent implements OnInit {


  personalDetailsForm: FormGroup;
  pageFormFields: FormFieldBase<string>[] = [];
  @Input() personalDetails: PersonalDetails;
  @Output() personalDetailsEvent = new EventEmitter();
  isReady = false;
  tooltipText: string;
  countryCodeRequiredValue: boolean;
  countryCodeReadOnlyValue: boolean;
  selectedCountryCodeValue: string;
  countryCodeLabelValue: string;
  countryCodeLookupDataValue: CountryCodeLookupData[];
  mandatoryErrorMessageTextValue: string | string[];

  constructor(
    private readonly formGroupControlService: FormGroupControlService
  ) { }

  ngOnInit(): void {
    this.buildFormFields();
  }

  async buildFormFields() {

    const formFields: FormFieldBase<string>[] = [];
    for (const [key, value] of Object.entries(this.personalDetails)) {
      if (key !== 'pageInfo' && key !== 'tooltipText' && key !== 'isEditMode' && key !== 'selectedCountryCode' &&
        key !== 'countryCodeLookupData' && key !== 'countryCodeReadOnly' &&
        key !== 'countryCodeRequired' && key !== 'countryCodeLabel' && key !== 'showCountryCodeCtrl') {
        formFields.push(value);
      }
    }

    this.formGroupControlService.formFieldswithOrder(formFields).subscribe(results => {
      this.pageFormFields = results;
      this.personalDetailsForm = new FormGroup(
        this.formGroupControlService.toFormControlArray(results)
      );

      if (this.personalDetails.showCountryCodeCtrl) {
        this.personalDetailsForm.addControl('countryCode', new FormControl('', Validators.required));
      } else {
        this.personalDetailsForm.addControl('countryCode', new FormControl(''));
      }
      this.isReady = true;
    });

    this.tooltipText = this.personalDetails.tooltipText;
    this.selectedCountryCodeValue = this.personalDetails.selectedCountryCode;
    this.countryCodeLabelValue = this.personalDetails.countryCodeLabel;
    this.countryCodeLookupDataValue = this.personalDetails.countryCodeLookupData;
    this.countryCodeReadOnlyValue = this.personalDetails.countryCodeReadOnly;
    this.countryCodeRequiredValue = this.personalDetails.countryCodeRequired;
    if (this.personalDetails.showCountryCodeCtrl) {
      this.mandatoryErrorMessageTextValue = this.personalDetails.countryCode.mandatoryValidationMsg;
    }
  }


  onClickEvent(eventType: string) {
    this.personalDetailsEvent.emit(eventType);
  }

}

