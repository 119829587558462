<section [ngClass]="{'section-container-small': isMobileView, 'section-container-medium': !isMobileView}">
  <ng-template id="tileContentSections" [ngTemplateOutlet]="tileContentSections"></ng-template>
</section>
<ng-template #tileContentSections>
  <div class="tile-container" id="divStrapLine" *ngIf="!isMobileView">
    <div [ngClass]="{'card mb-3 tileDesktop': true,'odd-tileBgColor' : !isImageLeft,'even-tileBgColor' : isImageLeft}">
      <div class="row no-gutters" [ngClass]="{'rightToLeft': isImageLeft}">
        <div [ngClass]="{'col-md-5': true,'alignLeft': isImageLeft,'alignRight': !isImageLeft,'imgcontainer': true}">
          <img id="imgSponsor" class="img-fluid imgsize" [src]="'data:image/png;base64,'+image" />
        </div>
        <div class="col-md-7 contentStyles">
          <h1 [innerHTML]="heading" class="headerstyle"></h1>
          <p [innerHTML]="subheading" class="contextTextstyle"></p>
          <p class="btnContainer" *ngIf="!hideEditButton">
            <lib-secondary-button [text]="calltoAction" [isButtonVisible]="isButtonVisible" [isBlock]="false" (clicked)="onButtonClicked()">
            </lib-secondary-button>
          </p>
          <p class="ctaContainer">
            <lib-link-button [linkTotext]="linkTotext" (clicked)="onLinkClicked()"></lib-link-button>
          </p>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
  <div class="tile-container-mobile" id="divStrapLine" *ngIf="isMobileView">
    <div [ngClass]="{'card mb-3 tileMobile': true,
  'odd-tile-MobileBgColor' : isImageLeft === false,'even-tile-MobileBgColor' : isImageLeft === true}">
      <div class="row no-gutters">
        <div class="col-md-6 imgcontainerMobile">
          <img id="imgSponsor" class="img-fluid imgsizeMobile" [src]="'data:image/png;base64,'+image" />
        </div>
        <div class="col-md-6 textContainerMobile">
          <div class="card-block px-2">
            <h1 [innerHTML]="heading" class="headTextMobile"></h1>
            <p [innerHTML]="subheading" class="contextTextMobile"></p>
            <p class="ctaContainer" *ngIf="!hideEditButton" >
              <lib-secondary-button [text]="calltoAction"  [isButtonVisible]="isButtonVisible" [isBlock]="false" (clicked)="onButtonClicked()">
              </lib-secondary-button>
            </p>
            <p class="ctaContainer">
              <lib-link-button [linkTotext]="linkTotext" (clicked)="onLinkClicked()"></lib-link-button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-template>