import { Injectable } from '@angular/core';
import { FormFieldMapper } from './field-mapper-base';
import { IFieldMapperStrategy } from './field-mapper-strategy';
import { Question } from '../../../../models';
import { RecaptchaField } from '../../../../form-field/re-captcha-field';

@Injectable({
    providedIn: 'root'
})
export class RecaptchaFieldMapper extends FormFieldMapper<RecaptchaField> implements IFieldMapperStrategy {

    public order = 0;

    public canMap(question: Question): boolean {
        return question.qQuestionFormat.toLowerCase() === 'recaptcha';
    }

    protected mapSpecificProperties(field: RecaptchaField, question: Question): void {
        field.type = question.subType && question.subType.toLowerCase();
        field.questionFormat = question.qQuestionFormat;

        const previousAnswer = question.answerSets.filter(ans => ans.isAnswered).pop();
        field.value = previousAnswer && previousAnswer.name;
        const newOrEditQuoteAllowed = JSON.parse(sessionStorage.getItem('newOrEditQuoteAllowed'))?._value;
        const isB2BFlow = JSON.parse(sessionStorage.getItem('isb2b'))?._value;
    
        if ((question.qQuestionType.toLowerCase() === 'quote') && (newOrEditQuoteAllowed === false) && isB2BFlow === false) {
            field.readOnly = true;
        }
    }

    protected getInstance() {
        return new RecaptchaField();
    }
}
