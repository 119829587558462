import {
  Component, OnInit, AfterViewInit, Input, EventEmitter, Output,
  ChangeDetectorRef, ViewChild, AfterViewChecked, OnDestroy
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldMapper } from '../..../../field-mapper-strategy/field-mapper';
import { FormGroup, Validators } from '@angular/forms';
import { Question } from '../../../models';
import { FormFieldBase } from '../../../form-field/form-field-base';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';
import { OndemandDataRequest } from '../../../models/ondemanddatarequest.model';
import { SessionStorageService } from 'angular-web-storage';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';
import { Element, Parent } from '../../../models/Carrier-Integration/utility.model';
import { DropDownComponent } from '../../../components/inputs/drop-down/drop-down.component';
import { BaseRepeatingGroupDataCaptureComponent } from './base-repeating-group-data-capture';
import { MatDialog } from '@angular/material/dialog';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';

const datePickerErrorMessage = 'Shared.lblDatePickerErrorMessage';
const editLabel = 'Shared.lblEdit';
const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
const emailErrorMessageLabel = 'Shared.lblInvalidEmail';
const minLengthErrorMessageLabel = 'Shared.lblMinLengthErrorMessage';
const maxLengthErrorMessageLabel = 'Shared.lblMaxLengthErrorMessage';
const minRangeErrorMessageLabel = 'Shared.lblMinRangeErrorMessage';
const maxRangeErrorMessageLabel = 'Shared.lblMaxRangeErrorMessage';
const numericErrorMessageLabel = 'Shared.lblNumericErrorMessage';
const invalidValueErrorMessageLabel = 'Shared.lblInvalidValue';
const addAnotherBtnLabel = 'Shared.lblAddAnother';
const noMoreToAddBtnLabel = 'Shared.lblNoMoreToAdd';
const deleteRepeatingGroupLabel = 'Shared.lblDeleteRepeatingGroup';

@Component({
  selector: 'lib-repeating-group-data-capture',
  templateUrl: './repeating-group-data-capture.component.html',
  styleUrls: ['./repeating-group-data-capture.component.scss']
})
export class RepeatingGroupDataCaptureComponent extends BaseRepeatingGroupDataCaptureComponent implements OnInit,
  AfterViewInit, AfterViewChecked {
  @Input() qnData: Question;
  @Input() groupPanelQnData: Question[];
  @Output() updateEmitter = new EventEmitter();
  groupPanelForm: FormGroup;
  pageFormFields: FormFieldBase<string>[];
  subFormFields: FormFieldBase<string>[];
  groupQnAnswerObj: Question[];
  datePickerErrorMessageText: string;
  mandatoryErrorMessageText: string;
  emailErrorMessageText: string;
  minLengthErrorMessage: string;
  maxLengthErrorMessage: string;
  minRangeErrorMessage: string;
  maxRangeErrorMessage: string;
  numericErrorMessage: string;
  invalidValueErrorMessage: string;
  editLabelText: string;
  addAnotherBtnText: string;
  noMoreToAddBtnText: string;
  deleteRepeatingGroupText: string;
  nextBtn: string;
  currentSelection: string;
  previousSelection: string;
  @Input() maxRepeats: number;
  parentIntentCode: string = '';
  childSpace: number = 15;
  qSubDescription: string;
  onDemandDataRequestValues: OndemandDataRequest;
  onDemandDataPayLoad: OndemandDataRequest;
  childElements: Element[] = [];
  parent: Parent;
  inputParameters: Element[] = []
  inputParamValues: Element[] = []
  parentChildArray: Element[] = [];
  controlType: any;
  options: any[] = [];
  currentRepeatingGroupName: string;
  @ViewChild(DropDownComponent) dropDownComponent: DropDownComponent;
  currentRepeatingGroupIndex;
  currentlRepeatingGroupCount;
  @Output() deleteRepeatingGroup: EventEmitter<string> = new EventEmitter();
  updatedQuestionSet: Question[];

  constructor(
    private cdr: ChangeDetectorRef,
    public translate: TranslateService,
    private formGroupControlService: FormGroupControlService,
    private fieldMapper: FieldMapper,
    public questionSetService: QuestionSetService,
    public sessionStorageService: SessionStorageService,
    public matDialog: MatDialog,
    public sharedSettingService: SharedSettingsService
  ) {
    super(sessionStorageService, questionSetService, translate, matDialog);
    this.questionSetService.qSubDescriptionObservable.subscribe(qSubDescription => {
      this.showChildOfSelectedParent(this.groupPanelQnData);
    });

  }

  ngOnInit() {
    this.applyTranslations();
    this.createFormGroup();
    this.deleteRepeatingQues.subscribe((result) => {
      if (result) {
        this.questionSetService.onDeleteRepeatingGroup(result.questionSet, result.qnToDelete, result.deleteFrom);
        this.sessionStorageService.set('PreviousAnsEditButton', false);
        this.questionSetService.IsNewRepeatingGroup = false;
        this.deleteRepeatingGroup.emit();
      }
    });
  }


  async submitGroupPanelAnswer(): Promise<void> {
    // ---- set ansewer in below function
    this.groupQnAnswerObj = this.setValueToAnswerSetsGroupQn(this.groupPanelQnData);

    if (this.updatedQuestionSet === undefined || this.updatedQuestionSet === null) {
      await this.updateResponseOfRepeatingGroup();
      // this.updateEmitter.emit({ internalQuestionSet: this.updatedQuestionSet });
    }
    else {
      this.updateEmitter.emit({ internalQuestionSet: this.updatedQuestionSet });
    }
  }

  setValueToAnswerSetsGroupQn(groupPanelQnData) {
    groupPanelQnData.forEach((qn) => {
      const qnIntentCode = qn.qIntentCode;
      if (qn?.globalFunction?.toLowerCase()?.includes('dynamic') && !qn?.globalFunction?.toLowerCase()?.includes('source')) {
        this.pageFormFields.filter(fieldsVal => {
          if (fieldsVal.key === qn.qIntentCode) {
            fieldsVal.options.forEach((optionsVal, index) => {
              const answerSet = this.questionSetService.MapLookupChildAnswerSet(optionsVal);
              answerSet['active'] = true;
              qn.answerSets.push(answerSet);
            });
          }
        })
      }
      // Single input.
      if (qn?.answerSets.length === 1) {
        // const currentValue = this.groupPanelForm.get(qnIntentCode)?.value;
        const currentValue = this.groupPanelForm.controls[qnIntentCode]?.value;

        const currentAnswerSet = qn?.answerSets[0];
        if (qn.qQuestionFormat.toLowerCase() !== 'recaptcha') {
          currentAnswerSet.name = currentValue;
          if (currentValue) {
            currentAnswerSet.isAnswered = true;
          }
        }
      } else {
        qn?.answerSets.forEach(as => as.isAnswered = false);
        // const formElement = this.groupPanelForm.get(qnIntentCode);
        const formElement = this.groupPanelForm.controls[qnIntentCode];

        if (formElement) {
          const answeredData = qn?.answerSets.filter(ans => ans.name === formElement.value).pop();
          if (answeredData) {
            answeredData.isAnswered = true;
            if (answeredData.childQuestionSets?.length > 0) {
              this.setValueToAnswerSetsGroupQn(answeredData.childQuestionSets)
            }
          }
        }
      }
    });
    return this.groupPanelQnData;
  }

  ngAfterViewChecked() {
    if (this.sessionStorageService.get('isRepeat') === 'true') {
      this.createRepeatingGroup();
      this.sessionStorageService.set('isRepeat', 'false');
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.showChildOfSelectedParent(this.groupPanelQnData);
  }

  private createFormGroup() {
    this.qSubDescription = this.qnData?.qSubDescription;
    this.pageFormFields = [];
    const questions = this.groupPanelQnData;
    if (questions) {
      this.formatQustionsForIteration(questions);
      this.createChildGroup(questions);
    }
  }

  formatQustionsForIteration(questions) {
    const repeatingGroupQuestions = [];
    questions.forEach((question, index) => {
      repeatingGroupQuestions[index] = question.name;
    });
  }

  createChildGroup(questions, isRepeat?: string) {
    questions.forEach((question, index) => {
      question.iterationIndex = this.questionSetService.formFieldIteration;
      this.createLookUpParentChildHeirarchy(question);
      this.mapFormData(question, index);
      this.createGrandChilds(question, 'outerCall', 0)
    });
    this.groupPanelForm = this.formGroupControlService.toFormGroup(this.pageFormFields);
  }


  createGrandChilds(question, callType, maxRepeatIndex?: number) {
    if (question.childQIntentCode) {
      question.answerSets.forEach((subQuestion) => {
        if (subQuestion?.childQIntentCode && subQuestion?.childQuestionSets?.length > 0) {
          if (callType === 'outerCall') {
            this.childSpace = 15;
          }
          subQuestion.childQuestionSets.forEach((que, ind) => {
            que['visibility'] = 'hide';
            que['margin'] = this.childSpace;
            que['index'] = maxRepeatIndex;
            question.iterationIndex = this.questionSetService.formFieldIteration;
            if (callType === 'outerCall') {
              que['rootParentQIntentCode'] = que.parentQIntentCode;
              this.parentIntentCode = que.parentQIntentCode;
            } else if (callType === 'innerCall' && this.parentIntentCode) {
              que['rootParentQIntentCode'] = this.parentIntentCode;
            }
            this.mapFormData(que, ind);
            if (que?.childQIntentCode) {
              this.childSpace = this.childSpace + 15;
              this.createGrandChilds(que, 'innerCall');
            }
          });
        }
      });
    }
  }

  mapFormData(question, index) {
    const fieldData = this.fieldMapper.map(
      question,
      this.getErrorMessageText(question.qQuestionFormat),
      this.getEditLabelText(question.qQuestionFormat),
      index,
      this.questionSetService.formFieldIteration);
    if (fieldData) {
      fieldData[question.parentQIntentCode];
      this.pageFormFields.push(fieldData);
    }
  }

  onValueChange(event) {
    if (event.value) {
      this.previousSelection = this.currentSelection;
      this.currentSelection = event.value;
      const element = (<HTMLElement>document.getElementById('groupPanelForm'));
      const children = element.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if ((child.getAttribute('parentQIntentCode') === event?.qIntentCode) ||
          (child.getAttribute('rootParentQIntentCode') === event?.qIntentCode)) {
          if (child.getAttribute('pvalue') === this.currentSelection &&
            (parseInt(child.getAttribute('iterationindex')) === this.questionSetService.formFieldIteration)) {
            const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
            child.setAttribute("style", styles);
            if (this.getFormField(child.getAttribute('aKey'))) {
              this.getFormField(child.getAttribute('aKey')).reset();
              this.getFormField(child.getAttribute('aKey')).setValidators(Validators.required);
              this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
            }
          } else if (child.getAttribute('pvalue') !== this.currentSelection) {
            const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
            child.setAttribute("style", styles);
            this.getFormField(child.getAttribute('aKey')).reset();
            this.getFormField(child.getAttribute('aKey')).clearValidators();
            this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
          }
        }
      }// ---to clear the child values if user has chosen clear option.
      if ((event.value === '' || event.value === '-1') && event?.data.globalFunction !== undefined) {
        this.clearChildControls(event.data.onDemandDataSubscribers);
      } else if (event.value && event?.data?.globalFunction !== undefined) {
        // ---to get the child values if user has chosen valid option.
        this.getOndemandDataValues(event);
      }
    }
  }

  private getErrorMessageText(questionType: string): string | string[] {
    return questionType.toLowerCase() === 'datepicker'
      ? this.datePickerErrorMessageText
      : (questionType.toLowerCase() === 'text' || questionType.toLowerCase() === 'textarea')
        ? [
          this.mandatoryErrorMessageText,
          this.emailErrorMessageText,
          '',
          '',
          this.minLengthErrorMessage,
          this.maxLengthErrorMessage,
          '',
          '',
          '',
          this.invalidValueErrorMessage
        ] // Todo: change from positional to KVP.
        : questionType.toLowerCase() === 'numeric'
          ? [
            this.mandatoryErrorMessageText,
            '',
            '',
            '',
            this.minLengthErrorMessage,
            this.maxLengthErrorMessage,
            this.minRangeErrorMessage,
            this.maxRangeErrorMessage,
            this.numericErrorMessage,
            '',] // Todo: change from positional to KVP.
          : this.mandatoryErrorMessageText;
  }

  private getEditLabelText(questionType: string): string {
    return questionType.toLowerCase() === 'datepicker' ? this.editLabelText : '';
  }

  private applyTranslations() {
    this.translate.get([
      datePickerErrorMessage,
      mandatoryErrorMessageLabel,
      emailErrorMessageLabel,
      minLengthErrorMessageLabel,
      maxLengthErrorMessageLabel,
      minRangeErrorMessageLabel,
      maxRangeErrorMessageLabel,
      numericErrorMessageLabel,
      invalidValueErrorMessageLabel,
      addAnotherBtnLabel,
      noMoreToAddBtnLabel,
      deleteRepeatingGroupLabel,
      editLabel]).subscribe((res) => {
        this.datePickerErrorMessageText = res[datePickerErrorMessage];
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
        this.emailErrorMessageText = res[emailErrorMessageLabel];
        this.minLengthErrorMessage = res[minLengthErrorMessageLabel];
        this.maxLengthErrorMessage = res[maxLengthErrorMessageLabel];
        this.minRangeErrorMessage = res[minRangeErrorMessageLabel];
        this.maxRangeErrorMessage = res[maxRangeErrorMessageLabel];
        this.numericErrorMessage = res[numericErrorMessageLabel];
        this.invalidValueErrorMessage = res[invalidValueErrorMessageLabel];
        this.editLabelText = res[editLabel];
        this.addAnotherBtnText = res[addAnotherBtnLabel];
        this.noMoreToAddBtnText = res[noMoreToAddBtnLabel];
        this.deleteRepeatingGroupText = res[deleteRepeatingGroupLabel];
      });
  }

  getFormField(key: string) {
    return this.groupPanelForm?.get(key);
  }

  onAddAnotherClick() {
    if (this.questionSetService.formFieldIteration <= this.maxRepeats) {
      this.sessionStorageService.set('isRepeat', 'true');
      this.sessionStorageService.set('maxRepeat', this.maxRepeats);
      this.sessionStorageService.set('currentRepeatedQuestion', this.qnData);
      this.increaseAnsweredRepeatingIteration();
    }
    this.submitGroupPanelAnswer();
    // this.createChildGroup(this.groupPanelQnData, 'true');
  }


  noMoreToAddClick() {
    this.increaseAnsweredRepeatingIteration();
    this.submitGroupPanelAnswer();
  }

  increaseAnsweredRepeatingIteration() {
    const answered = this.questionSetService.currentQuestion.childQuestionSets?.some(cqs => cqs.answerSets?.some(as => as.isAnswered));
    this.sessionStorageService.set('PreviousAnsEditButton', false);
    this.questionSetService.IsNewRepeatingGroup = false;
    if (this.questionSetService.currentQuestion.childQuestionSets?.length > 0 && answered === false) {
      const repeatingGroupName = this.questionSetService.getRepeatingGroupName(this.questionSetService.currentQuestion.qIntentCode);
      this.updatedQuestionSet =
        this.questionSetService.savedRepeatingGroup(this.questionSetService.internalQuestionSet, repeatingGroupName, 'increase');
    }
  }

  onDeleteClick() {
    this.deleteDialog(this.qnData, false, false);
  }

  createRepeatingGroup() {
    this.showHideIteration(this.questionSetService.formFieldIteration, 'reset');
  }

  showHideIteration(formFieldIteration, type) {
    this.groupPanelQnData.forEach((question) => {
      const element = (<HTMLElement>document.getElementById('groupPanelForm'));
      const children = element.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        if (parseInt(child.getAttribute('iterationIndex')) === formFieldIteration &&
          child.getAttribute('aKey') === question.qIntentCode) {
          const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
          child.setAttribute("style", styles);
          if (type === 'reset' && this.getFormField(child.getAttribute('aKey'))) {
            this.getFormField(child.getAttribute('aKey')).reset();
            this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
          }
        } else if (parseInt(child.getAttribute('iterationIndex')) !== formFieldIteration) {
          const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
          child.setAttribute("style", styles);
        }
      }
    });
  }

  showChildOfSelectedParent(questions) {
    questions.forEach((question) => {
      this.showParent(question);
      this.showGrandChild(question);
    });
  }

  showGrandChild(question) {
    if (question?.childQIntentCode) {
      question.answerSets.forEach((ques) => {
        if (ques?.childQIntentCode && ques?.childQuestionSets?.length > 0) {
          ques.childQuestionSets.forEach((que) => {
            const parentQIntentCode = que?.parentQIntentCode;
            if (this.getFormField(parentQIntentCode).value) {
              const element = (<HTMLElement>document.getElementById('groupPanelForm'));
              const children = element.children;
              for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (parseInt(child.getAttribute('iterationIndex')) === this.questionSetService.formFieldIteration &&
                  ((child.getAttribute('pvalue') === this.getFormField(child.getAttribute('parentQIntentCode'))?.value) ||
                    child.getAttribute('pvalue') === this.getFormField(que.parentQIntentCode).value) &&
                  (que.parentQIntentCode === child.getAttribute('rootParentQIntentCode')
                    || que.parentQIntentCode === child.getAttribute('parentqintentcode'))) {
                  const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
                  child.setAttribute("style", styles);
                } else if ((child.getAttribute('pvalue') !== this.getFormField(que.parentQIntentCode)?.value
                  || child.getAttribute('pvalue') !== this.getFormField(child.getAttribute('parentQIntentCode'))?.value)
                  && (que.parentQIntentCode === child.getAttribute('rootParentQIntentCode') ||
                    que.parentQIntentCode === child.getAttribute('parentqintentcode'))) {
                  const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
                  child.setAttribute("style", styles);
                  if (child.getAttribute('aKey')) {
                    this.getFormField(child.getAttribute('aKey')).reset();
                    this.getFormField(child.getAttribute('aKey')).clearValidators();
                    this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
                  }
                }
              }
            }
            if (que?.childQIntentCode) {
              this.showGrandChild(que);
            }
          });
        }
      });
    }
  }

  showParent(question) {
    const element = (<HTMLElement>document.getElementById('groupPanelForm'));
    const children = element.children;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (parseInt(child.getAttribute('iterationIndex')) === this.questionSetService.formFieldIteration &&
        child.getAttribute('aKey') === question.qIntentCode) {
        const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
        child.setAttribute("style", styles);
      } else if (this.questionSetService.formFieldIteration !== null
        && parseInt(child.getAttribute('iterationIndex')) !== this.questionSetService.formFieldIteration
        && child.getAttribute('aKey') === question.qIntentCode) {
        // else if(parseInt(child.getAttribute('iterationIndex')) !== this.questionSetService.formFieldIteration){
        const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
        child.setAttribute("style", styles);
      }
    }
  }

  // look up functions start
  // get child options in response
  getOndemandDataValues(event) {
    this.childElements = [];
    this.parentChildArray = [];
    this.controlType = [];
    if (event.tagName === 'SELECT') {
      this.controlType = "SELECT";
    }
    const element = (<HTMLElement>document.getElementById('groupPanelForm'));
    const children = element.children;
    if (event?.data) {
      // if dropdown is child dropdown
      if (event?.data?.lookupPQIntentCode !== '' && event?.data?.lookupPQIntentCode) {
        // get the intend code and options of parents and its subchilds
        this.getPIntendCodeValue(children, '', event);
      }
      // if dropdown is parent dropdown
      else {
        const controlValue = this.getFormField(event?.data?.qIntentCode)?.value;
        this.parentChildArray.push(
          {
            name: event?.data?.qIntentCode,
            selectedText: controlValue,
            type: this.controlType,
            selectedValue: controlValue,
            value: null
          });
      }

      // get childelements
      event.data.onDemandDataSubscribers.split(',').forEach((value, index, array) => {
        this.childElements.push(
          {
            name: value,
            selectedText: null,
            selectedValue: null,
            type: this.controlType,
            value: null,
          });
      });
      // get parent
      this.parent = {
        name: event.id,
        selectedText: event.value,
        selectedValue: event.value,
        type: this.controlType,
        value: null,
        endPointUrl: null
      }
      // payload value
      this.onDemandDataPayLoad = {
        backEndSystem: "None",
        childElements: this.childElements,
        inputParameters: this.parentChildArray,
        parent: this.parent,
        productName: this.sessionStorageService.get('ProductName'),
        globalFunction: event.data.globalFunction,
        quoteId: this.sessionStorageService.get('QuoteReferenceID'),
        sponsorId: this.sessionStorageService.get('SponsorId'),
        sponsorName: this.sessionStorageService.get('SponsorName'),
        userId: ''
      };

      // api call
      this.questionSetService.getOnDemandDataRequest(this.onDemandDataPayLoad).then((observerResponse: OndemandDataRequest) => {
        observerResponse.childElements.forEach((value, index, array) => {
          if ((this.groupPanelForm.controls[value.name].touched !== false
            && this.groupPanelForm.controls[value.name].status !== "INVALID")
            || this.groupPanelForm.controls[value.name].value !== '') {
            this.clearChildControls(value.name, value.type);
          }
          this.options = [];
          if (value?.lookupData) {
            // to add clear option if response option is only one
            // //if (value?.lookupData?.length < 2) {
            // //  // this.options?.push({ name: '', value: '' });
            // //}
            // bind the value for dropdown options
            value?.lookupData?.forEach(lookupDataValue => {
              this.options?.push({ name: lookupDataValue?.text, value: lookupDataValue?.value })
            })
            // binding the options in dropdown
            if (value.type === 'SELECT') {
              let toDisableControl = false;
              this.pageFormFields.filter(fieldsVal => {
                if (fieldsVal.key === value.name) {
                  fieldsVal.options = this.options;
                  // *** if the lookup has single option then we have to disable and autoselect that value ***//
                  if (value?.lookupData?.length === 1) {
                    const matSelect = this.groupPanelForm.get(value.name);
                    matSelect.setValue(this.options[0].value, { emitEvent: true });
                    toDisableControl = true;
                    this.cdr.detectChanges();
                    //* ** trigger SelectionChange() event ***/
                    if (fieldsVal.controlType === "dropdown") {
                      const data = this.pageFormFields.filter(field => field.key === value.name)[0];
                      const selected = { value: this.options[0].value };
                      this.dropDownComponent.onSelectionChange(selected, data);
                    }
                  }
                  fieldsVal.readOnly = toDisableControl;
                }
              });
            }
            else {
              this.groupPanelForm.get(value.name).setValue(this.options);
            }
          }
        })
      });
    }
  }

  // method to clear child options when user reselect or clear the options
  clearChildControls(childQIntentCode, type?) {
    this.options = []; // to clear the previous value that user selected in array
    this.options?.push({ name: '', value: '' }) // to display empty value on UI
    this.pageFormFields.filter(fieldsVal => {
      if (fieldsVal.key === childQIntentCode) {
        this.getFormField(childQIntentCode).reset();
        this.getFormField(childQIntentCode).updateValueAndValidity();
        fieldsVal.options = this.options;
        fieldsVal.readOnly = true;
        if (fieldsVal?.onDemandDataSubscribers !== '' && fieldsVal?.onDemandDataSubscribers !== null) {
          this.clearChildControls(fieldsVal.onDemandDataSubscribers); // passing the next child
        }
      }
    });
  }

  getPIntendCodeValue(children, parentValue?, event?) {
    for (let i = 0; i < children?.length; i++) {
      const parent = children[i];
      if (event !== null && children !== null && event?.data?.lookupPQIntentCode !== '' && event?.data?.lookupPQIntentCode) {
        if ((event?.data?.lookupPQIntentCode === parent?.getAttribute('qIntentCode'))) {
          //    let parent=child.getAttribute('lookupPQIntentCode')
          const controlValue = this.getFormField(event?.data?.qIntentCode).value;
          const parentControlValue = this.getFormField(event?.data?.lookupPQIntentCode).value;
          this.parentChildArray.push(
            {
              name: event?.data?.qIntentCode,
              selectedText: controlValue,
              type: this.controlType,
              selectedValue: controlValue,
              value: null
            },
            {
              name: event?.data?.lookupPQIntentCode,
              selectedText: parentControlValue,
              type: this.controlType,
              selectedValue: parentControlValue,
              value: null
            });
          //  this.parentChildArray.push({name:event?.data?.lookupPQIntentCode});
          // event.data.lookupPQIntentCode='';
          if (parent.getAttribute('lookupPQIntentCode') !== '') {
            this.getPIntendCodeValue(children, parent, '');
          }
        }
      }
      else if (parentValue && parentValue?.getAttribute('lookupPQIntentCode') !== '') {
        if (parentValue.getAttribute('lookupPQIntentCode') === parent.getAttribute('qIntentCode')) {
          const parentControlValue = this.getFormField(parent.getAttribute('qIntentCode')).value;
          this.parentChildArray.push(
            {
              lookupData: null,
              name: parentValue.getAttribute('lookupPQIntentCode'),
              selectedText: parentControlValue,
              type: this.controlType,
              selectedValue: parentControlValue,
              value: null
            });
          this.getPIntendCodeValue(children, parent, '');
        }
      }
    }
  }

  createLookUpParentChildHeirarchy(question: Question) {
    if (question?.globalFunction?.toLowerCase()?.includes('source')) {
      question['lookupPQIntentCode'] = ''
    }
    else if (question && question.globalFunction?.toLowerCase()?.includes('dynamic')) {
      const ParentArray = question.onDemandDataParameters?.split(',').map(s => s.trim());
      question['lookupPQIntentCode'] = ParentArray[ParentArray.length - 2];

    }
  }
  // look up functions end


  async updateResponseOfRepeatingGroup() {

    // ---- set ansewer in below function
    if (this.updatedQuestionSet === undefined || this.updatedQuestionSet === null) {
      this.updatedQuestionSet = this.sharedSettingService.PrimaryQuestionData;

      const group = this.groupPanelQnData[0].qIntentCode.split('.');
      let groupName = '';
      if (group.length === 1 && group[0].includes('~')) {
        groupName = group[0].split('~')[0] + '~' + group[0].split('~')[1];
      } else if (group && group.length > 0) {
        groupName = group[0]?.replace('[', '~').replace(']', '');
      }

      if (groupName !== '' && groupName !== undefined) {
        // const groupName = group[0]?.replace('[', '~').replace(']', '');
        const grpQuestionSet = this.updatedQuestionSet && this.updatedQuestionSet.filter(qs => qs.qIntentCode === groupName);

        if (grpQuestionSet !== null && grpQuestionSet !== undefined && grpQuestionSet.length > 0) {

          for (const field in this.groupPanelForm.controls) { // 'field' is a string

            this.groupPanelQnData.forEach(element => {

              if (element.qIntentCode.toLowerCase() === field.toLowerCase()) {
                if (element.answerSets.length === 1 && element.qQuestionFormat.toLowerCase() !== 'recaptcha') {
                  if (element.answerSets[0].name !== this.groupPanelForm.controls[field].value) {

                    element.answerSets[0].name = this.groupPanelForm.controls[field].value
                  }
                } else {
                  element.answerSets.forEach(as => as.isAnswered = false);
                  // const formElement = this.groupPanelForm.get(qnIntentCode);
                  const formElement = this.groupPanelForm.controls[field];

                  if (formElement) {
                    const answeredData = element.answerSets.filter(ans => ans.name === formElement.value).pop();
                    if (answeredData) {
                      answeredData.isAnswered = true;
                    }
                  }
                }
              }

            });
          }

          grpQuestionSet[0].childQuestionSets = this.groupPanelQnData;
          this.sessionStorageService.set('currentRepeatedQuestion', grpQuestionSet[0]);
        }
      }
      this.sharedSettingService.PrimaryQuestionData = this.updatedQuestionSet;
      this.sharedSettingService.AnsweredQuestionSet = this.updatedQuestionSet;

      this.updateEmitter.emit({ internalQuestionSet: this.updatedQuestionSet });

    }

  }


}
