import { Component, OnInit, Input } from '@angular/core';
import { ButtonBaseDirective } from '../button-base';

@Component({
  selector: 'lib-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss']
})
export class PrimaryButtonComponent extends ButtonBaseDirective implements OnInit {
  @Input() showTick = false;
  @Input() showBookmark = false;
  ngOnInit() {
  }

  public textOnly(): boolean {
    return super.textOnly() && !this.showTick && !this.showBookmark;
  }
}
