import { CardButtonBase } from './card-button-base';
// import { MenuParams } from './card-button-base';

export class CardWithMenu extends CardButtonBase<string> {
  controlType = 'cardwithmenu';
  // menuInfo: MenuParams[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.menuInfo = options['menuInfo'] || [];
  }
}
