import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';
import { SessionStorageService } from 'angular-web-storage';

@Pipe({
  name: 'momentOffsetDatePipe'
})
export class MomentOffsetDatePipe implements PipeTransform {

  constructor(private sessionStorage: SessionStorageService) {}

  transform(date: any, args?: any): string {
    if (date) {
      let formateDate = date;
      if (!moment(date, 'DD/MM/YYYY', true).isValid()) {
        formateDate = date.replaceAll('-', '/');
      }
      const stringToDate = new Date(formateDate);
      let transformedDate;
      let dateFormat = 'DD/MM/YYYY';

      if (this.sessionStorage.get('LocalisedDateFormat')) {
        dateFormat = this.sessionStorage.get('LocalisedDateFormat');
      }     
      
      if (moment(date, 'DD/MM/YYYY', true).isValid()) {
        const newDate = moment(date, 'DD/MM/YYYY', true).toDate();
        transformedDate = moment(newDate, dateFormat, this.sessionStorage.get('LanguageCode'), false);
        return transformedDate.format(dateFormat);
      } 
      
      if (date.toString() !== (moment(date, dateFormat, true).format(dateFormat))) {
        if (moment(stringToDate, dateFormat).isValid()) {
          transformedDate = moment(stringToDate, dateFormat, this.sessionStorage.get('LanguageCode'), false);
        } else {
          transformedDate = moment(date, dateFormat, this.sessionStorage.get('LanguageCode'), false);
        } 
        return transformedDate.format(dateFormat);
      } else {
        return date.toString();
      }   
        
           
    }  
  }
}
