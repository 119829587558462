import { Component, OnInit, HostListener, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';

@Component({
  selector: 'lib-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent implements OnInit {

  isFixedAtBottom = true;
  @Input() showBanner: boolean;
  @Input() privacyPolicyLink: string;
  @Input() privacyPolicyData: any;
  @Input() bannerText: string;
  @Input() learnMoreText: string;

  constructor(public bottomSheet: MatBottomSheet) {

  }
  ngOnInit() {
    if (this.showBanner) {
      this.getDistanceFromBottom();
    }
  }
  openBottomSheet(title: string, content: string): void {
    const bottomSheetRef = this.bottomSheet.open(BottomsheetComponent, {
        panelClass: 'fullWidthBottomSheet',
        data: {
            title,
            content
        }
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
    });
  }
  closeBanner() {
    this.showBanner = false;
    localStorage.setItem('cookie-enable', '-1');
  }

  @HostListener('window:scroll')
  getDistanceFromBottom() {
    if (this.showBanner) {
      const cookieBannerDiv: HTMLElement = document.getElementById('cookie_banner');
      if (cookieBannerDiv) {
        const distanceToBottom: number = cookieBannerDiv.getBoundingClientRect().bottom;
        this.isFixedAtBottom = (distanceToBottom > 0) ? true : false;
      }
    }
  }

  affixClass() {
    return {
      affix: this.isFixedAtBottom
    };
  }

}
