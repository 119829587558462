import { Injectable } from '@angular/core';
import { SharedSettingsService } from '../settings/settings-base.service';
import { HttpParams } from '@angular/common/http';
import { CookieBannerService } from '../cookie-banner/cookie-banner.service';
import { MsalAuthenticationService } from '../../services/common-auth/msalauthentication.service';
import { CommonHttpService } from '../common-http/common-http.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private settingsService: SharedSettingsService,
              private cookieBannerService: CookieBannerService,
              private msalAuthenticationService: MsalAuthenticationService,
              private http: CommonHttpService) { }

  public getUrlParameterByName(paramName: string, useCaching: boolean = true) {
    const params = this.getAllParameters(useCaching);
    return params && params[paramName];
  }

  public getAllParameters(useCaching: boolean = true): { [id: string]: string; } {
    const stashedResult = useCaching && this.settingsService.queryParams;
    if (stashedResult) {
      return stashedResult;
    }
    const params: { [id: number]: string; } = {};
    const url = window.location.href;
    if (url.includes('?')) {

      if (url?.toLowerCase().indexOf('?sname') > 0) {
        // *** To clear the browser SessionStorage & LocalStorage values at the time of initial loading ***//
        if (this.msalAuthenticationService.isAuthenticated() === false || this.settingsService.isB2B()) {
          this.cookieBannerService.clearLocalStorage();
        }
        this.cookieBannerService.clearSessionStorage();
      }

      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      for (const paramName of httpParams.keys()) {
        params[paramName] = httpParams.get(paramName);
      }

      // adding this code for bug 170050 //
      const paramVal = JSON.stringify(params);
      const queryParamsVal = JSON.stringify(this.settingsService.queryParams);
      if (url.includes('?sname')) {
        if (paramVal !== queryParamsVal && (paramVal !== 'null' &&
          queryParamsVal !== 'null')) {
          sessionStorage.clear();
        }
      }
    }

    if (useCaching) {
      this.settingsService.queryParams = params;
    }
    return params;
  }
}
