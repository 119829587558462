import { Question } from "../models";

export abstract class FormFieldBase<T> {
  value: T;
  key: string;
  label: string;
  descriptionLabel: string;
  required: boolean;
  order: number;
  controlType: string;
  type: string;
  readOnly: boolean;
  disableMatFormField: boolean;
  iconName: string;
  mandatoryValidationMsg: string | string[];
  callToActionLabel: string;
  options: { name: string, value: string, id?: any }[];
  groupOptions: { label: string, options: { name: string, value: string } }[];
  validatorMatchWith: string;
  visibility: string;
  parentOValue: string;
  margin: number;
  index: number;
  formFieldIteration: number;
  masterGroupPanelIntentCode: string;// Very first level Parent
  rootParentQIntentCode: string;// immediate parent
  qIntentCode: string;
  childQIntentCode: string;
  parentQIntentCode: string;
  formFieldIterationIndex: number;
  globalFunction?: string;
  onDemandDataParameters?: string;
  onDemandDataSubscribers?: string;
  lookupPQIntentCode?: string;
  iterationIndex?: number;
  minLength: number;
  odefault: boolean;
  selectedId?: string;
  persistChildValue?: boolean;
  isHidden?: boolean;
  autoSuggestionList?: { name: string, value: string, id?: any }[];
  isRepeatingGroupToBeNext?: boolean;
  masterGroupPanelChildQIntentCode?: string;// Very first level Parent
  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    descriptionLabel?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    type?: string,
    readOnly?: boolean,
    disableMatFormField?: boolean,
    iconName?: string,
    mandatoryValidationMsg?: string | string[],
    callToActionLabel?: string,
    validatorMatchWith?: string,
    visibility?: string,
    parentOValue?: string,
    margin?: number,
    index?: number,
    formFieldIteration?: number,
    masterGroupPanelIntentCode?: string,
    rootParentQIntentCode?: string,
    qIntentCode?: string,
    childQIntentCode?: string,
    parentQIntentCode?: string,
    formFieldIterationIndex?: number,
    globalFunction?: string,
    onDemandDataParameters?: string,
    onDemandDataSubscribers?: string,
    lookupPQIntentCode?: string,
    iterationIndex?: number,
    minLength?: number,
    odefault?: boolean,
    persistChildValue?: boolean,
    isHidden?: boolean,
    autoSuggestionList?: { name: string, value: string, id?: any }[],
    isRepeatingGroupToBeNext?: boolean,
    masterGroupPanelChildQIntentCode?: string
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.descriptionLabel = options.descriptionLabel || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.type = options.type || '';
    this.readOnly = options.readOnly || false;
    this.iconName = options.iconName || '';
    this.callToActionLabel = options.callToActionLabel || '';
    this.mandatoryValidationMsg = options.mandatoryValidationMsg || '';
  }
}
