import { Injectable } from '@angular/core';
import { Observable, ObservableInput, ReplaySubject, Subject } from 'rxjs';
import { endPoints } from '../../shared/core/app.config';
import { CommonHttpService } from '../common-http/common-http.service';
import { ISponsor, ITermsOfUse, PreferredLanguage, Language } from '../../shared/models';
import { publishReplay, map, refCount, last, share } from 'rxjs/operators';
import { DropdownItem, ITimeZoneInfo } from '../../shared/models';
import { SharedSettingsService } from '../settings/settings-base.service';

@Injectable({
  providedIn: 'root'
})
export class SponsorService {

  currentSponsorName: string;
  sponsorLogo: any;
  applyWillisBranding: boolean;
  enableCustomerIdentityB2CClaims: boolean;
  browserLangFlag = true;
  sponsorObservable: Observable<ISponsor>;
  languageObservable = new Subject<number>();

  constructor(
    private commonHttpService: CommonHttpService,
    private settingsService: SharedSettingsService) { }

  private getB2CWebsiteBrandingDetailsapiUrl = endPoints.apiUrl;
  private getTermsOfUsebySponsorNameUrl = endPoints.apiUrl + endPoints.endPointName.getTermsOfUsebySponsorName;
  private getSponsorLogoData = endPoints.apiUrl + endPoints.endPointName.getSponsorLogoContent;
  private getSponsorPreferredLanguageUrl = endPoints.apiUrl + endPoints.endPointName.getSponsorPreferredLanguage;
  private getSponsorUrl = endPoints.apiUrl + endPoints.endPointName.getSponsorUrl;
  private getTimeZonesUrl = endPoints.apiUrl + endPoints.endPointName.getTimeZoneInfo;
  private getJavaScriptUrl = endPoints.apiUrl + endPoints.endPointName.getJavaScript;
  private getB2CWebsiteBrandingDetailsUrl = endPoints.apiUrl + endPoints.endPointName.getB2CWebsiteBrandingDetails;
  private getDateFormatById = endPoints.apiUrl + endPoints.endPointName.getDateFormatByIdUrl;
  private getSubgroupBySponsoreId = endPoints.apiUrl + endPoints.endPointName.getSubGroupIdBySponsorIdUrl;
  private getSponsorCustomerIdentificationUrl = endPoints.apiUrl + endPoints.endPointName.getSponsorCustomerIdentification;

  private preferredLanguage: PreferredLanguage;

  get sponsorLanguages(): Language[] {
    return this.preferredLanguage && this.preferredLanguage.preferredLanguages;
  }

  get sponsorLanguagesAsDropdownItems(): DropdownItem[] {
    return this.preferredLanguage && this.preferredLanguage.preferredLanguages.map(item => {
      return {
        id: item.languageID,
        name: item.languageName,
        languageCode: item.languageCode
      };
    });
  }

  get sponsorPreferredLanguageId(): number {
    return this.settingsService.languageId;
  }

  get sponsorPreferredLanguage(): Language {
    let language: Language = this.sponsorPreferredLanguageId
      && this.sponsorLanguages
      && this.sponsorLanguages.find(lang => lang.languageID === this.sponsorPreferredLanguageId);

    if (language === undefined || language === null) {
      language = { languageID: 1, languageCode: 'en-GB', languageName: 'English' };
    }

    return language;
  }

  get defaultPreferredLanguageId(): number {
    let defaultLanguageId = 1; // *** English (LanguageId= 1; LanguageCode= en-GB) ***//
    if (this.preferredLanguage !== undefined && this.preferredLanguage !== null
      && this.preferredLanguage.preferredLanguageId !== undefined) {
      defaultLanguageId = this.preferredLanguage.preferredLanguageId;
    }
    return defaultLanguageId;
  }

  setSponsorPreferredLanguage(languageId: number) {
    this.settingsService.languageId = languageId;
    this.settingsService.languageCode = this.sponsorPreferredLanguage.languageCode;
    this.browserLangFlag = false;
    // Publish the language change to any subscribers.
    this.languageObservable.next(languageId);
  }

  getSponsor(): Observable<ISponsor> {
    return this.getNamedSponsor(this.getCurrentSponsorName());
  }

  getNamedSponsor(sponsorName: string): Observable<ISponsor> {
    return this.getSponsorInternal(sponsorName);
  }

  // Assumes we have at some point stashed Sponsor Name in Session Storage!
  getCurrentSponsorName(): string {
    if (!this.currentSponsorName) {
      this.currentSponsorName = this.settingsService.sponsorName;
    }

    return this.currentSponsorName;
  }

  // Error handling?
  // Base - optional mapping
  // Base - optional caching
  private getSponsorInternal(sponsorName: string): Observable<ISponsor> {
    const uri = `${this.getSponsorUrl}/${sponsorName}`;

    if (!this.sponsorObservable) {
      this.sponsorObservable = this.commonHttpService.httpGetService(uri).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }

    return this.sponsorObservable;
  }

  initialiseSponsorPreferredLanguage(sponsorId: number): Observable<boolean> {
    const url: string = this.getSponsorPreferredLanguageUrl + '/' + sponsorId;
    return this.commonHttpService.httpGetService(url).pipe(map(pl => {
      this.preferredLanguage = pl;
      return true;
    }));
  }

  getTermsOfUsebySponsorName(sponsorName: string, languageId: string): Observable<ITermsOfUse> {
    if (languageId === null || languageId === undefined) {
      languageId = '1';
    }

    const url: string = this.getTermsOfUsebySponsorNameUrl + '/' + sponsorName + '/' + languageId;
    return this.commonHttpService.httpGetService(url);
  }

  getSponsorLogoContent(params: number): Observable<string> {
    const url: string = this.getSponsorLogoData + '/' + params;
    return this.commonHttpService.httpGetService(url);
  }

  getTimeZoneInfoList(): Observable<ITimeZoneInfo[]> {
    return this.commonHttpService.httpGetService(this.getTimeZonesUrl);
  }

  getJavaScript(sponsorId: number): Observable<any> {
    return this.commonHttpService.httpGetService(this.getJavaScriptUrl + '/' + sponsorId);
  }

  getB2CWebsiteBrandingDetails(sponsorId: number): Observable<any> {
    return this.commonHttpService.httpGetService(this.getB2CWebsiteBrandingDetailsUrl + '/' + sponsorId);
  }

  getLocalisedDateFormatById(dateFormatId: number): Observable<any> {
    return this.commonHttpService.httpGetService(this.getDateFormatById + '/' + dateFormatId);
  }

  getSubGroupIdBySponsorId(user: string, sponsorId: number): Observable<any> {
    const url: string = this.getSubgroupBySponsoreId + '/' + user + '/' + sponsorId;
    return this.commonHttpService.httpGetService(url);
  }

  getSponsorCustomerIdentification(sponsorId: number): Observable<any> {
    const url: string = this.getSponsorCustomerIdentificationUrl + '/' + sponsorId;
    return this.commonHttpService.httpGetService(url);
  }
}
