<ng-container>
    <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription" [QnSubDescription]="qnData.qSubDescription">
    </lib-data-capture-heading>
    <section class="answer-container" id="answerContainer">
        <i class="material-icons md-24 cursor-pointer" id="iRemove" (click)="incrmentDecrementVal = incrmentDecrementVal - 1">remove</i>
        <h1 id="h1Header">{{incrmentDecrementVal}}</h1>
        <i class="material-icons md-24 cursor-pointer" id="iAdd" (click)="incrmentDecrementVal = incrmentDecrementVal + 1">add</i>
    </section>
    <div class="next-btn-container">
        <lib-primary-button id="btnNext" [text]="nextBtn" (clicked)="submitIncDecAnswer(qnData.answerSets[0])" autofocus></lib-primary-button>
    </div>
</ng-container>
