export class Tile {
    image: string;
    heading: string;
    subheading: string;
    buttonText: string;
    linkText: string;
    isImageLeft: boolean;
    tag: any;
    isButtonVisible: boolean;
    newOrEditQuoteAllowed: boolean;     
}
