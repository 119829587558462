import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Question } from '../../../models';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';
import { SessionStorageService } from 'angular-web-storage';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
@Component({
  selector: 'lib-wide-button-with-accordion',
  templateUrl: './wide-button-with-accordion.component.html',
  styleUrls: ['./wide-button-with-accordion.component.scss']
})
export class WideButtonWithAccordionComponent implements OnInit {
  @Input() label: string;
  @Input() details: string | string[];
  @Input() callToAction: string;
  @Input() tag: any;
  @Input() disabled = false;
  @Input() questions: string | string[];
  @Output() clicked = new EventEmitter<Question>();
  @Output() deleteClicked = new EventEmitter<Question>();
  @Input() answer;
  @Input() deleteCallToAction: string;
  @Input() isMaxRepeat: any;
  repeatingGroupCount: any;
  repeatingGroupIndex: any;
  emptyIterationExist: any;
  isDisabledButton: boolean;

  constructor(public questionSetService: QuestionSetService,
              public sessionStorage: SessionStorageService,
              public settingsService: SharedSettingsService
  ) { }

  ngOnInit() {
    // to trigger the widebutton when there is change in 'IsNewRepeatingGroup'
    this.questionSetService?.IsNewRepeatingGroup.subscribe(value => {
      this.isDisabledButton = this.settingsService.getPreviousAnswerEditButtonStatus();
    })

    this.isDisabledButton = this.settingsService.getPreviousAnswerEditButtonStatus();

    if (this.tag?.qIntentCode) {
      this.repeatingGroupIndex = this.questionSetService.getCurrentRepeatingGroupIndex(this.tag?.qIntentCode).currentIterationIndex;
      this.repeatingGroupCount = this.sessionStorage.get('AnsweredRepeatingIteration');
    }
  }

  onClick(event): void {
    this.clicked.emit(this.tag);
    event.stopPropagation()
  }

  onDeleteClick(event): void {
    this.deleteClicked.emit(this.tag);
    event.stopPropagation()
  }

}
