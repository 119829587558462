
import { ImageButtonComponent } from './image-button/image-button.component';
import { RadioDataCaptureComponent } from './radio-data-capture/radio-data-capture.component';
import { TextDataCaptureComponent } from './text-data-capture/text-data-capture.component';
import { OptionListDataCaptureComponent } from './option-list-data-capture/option-list-data-capture.component';
import { PlusMinusDataCaptureComponent } from './plus-minus-data-capture/plus-minus-data-capture.component';
import { CheckBoxDataCaptureComponent } from './check-box-data-capture/check-box-data-capture.component';
import { NumberDataCaptureComponent } from './number-data-capture/number-data-capture.component';
import { DropdownDataCaptureComponent } from './dropdown-data-capture/dropdown-data-capture.component';
import { GroupPanelDataCaptureComponent } from './group-panel-data-capture/group-panel-data-capture.component';
import { DatepickerDataCaptureComponent } from './datepicker-data-capture/datepicker-data-capture.component';
import { TextAreaDataCaptureComponent } from './textarea-data-capture/textarea-data-capture.component';
import { RecaptchaDataCaptureComponent } from './recaptcha-data-capture/recaptcha-data-capture.component';
import { RepeatingGroupDataCaptureComponent } from './repeating-group-data-capture/repeating-group-data-capture.component';
import { CurrencyDataCaptureComponent } from './currency-data-capture/currency-data-capture.component';
// import { DatePickerComponent } from '../../shared/components/inputs/date-picker/date-picker.component';
// import { DropDownComponent } from '../../shared/components/inputs/drop-down/drop-down.component';
export const components: any[] = [
  ImageButtonComponent,
  RadioDataCaptureComponent,
  TextDataCaptureComponent,
  OptionListDataCaptureComponent,
  PlusMinusDataCaptureComponent,
  CheckBoxDataCaptureComponent,
  NumberDataCaptureComponent,
  DropdownDataCaptureComponent,
  GroupPanelDataCaptureComponent,
  DatepickerDataCaptureComponent,
  TextAreaDataCaptureComponent,
  RecaptchaDataCaptureComponent,
  RepeatingGroupDataCaptureComponent,
  CurrencyDataCaptureComponent
];
export * from './image-button/image-button.component';
export * from './radio-data-capture/radio-data-capture.component';
export * from './text-data-capture/text-data-capture.component';
export * from './option-list-data-capture/option-list-data-capture.component';
export * from './plus-minus-data-capture/plus-minus-data-capture.component';
export * from './check-box-data-capture/check-box-data-capture.component';
export * from './number-data-capture/number-data-capture.component';
export * from './dropdown-data-capture/dropdown-data-capture.component';
export * from './datepicker-data-capture/datepicker-data-capture.component';
export * from './group-panel-data-capture/group-panel-data-capture.component';
export * from './textarea-data-capture/textarea-data-capture.component';
export * from './data-capture-heading/data-capture-heading.component';
export * from './recaptcha-data-capture/recaptcha-data-capture.component';
export * from './repeating-group-data-capture/repeating-group-data-capture.component';
export * from './currency-data-capture/currency-data-capture.component';
// export * from '../../shared/components/inputs/date-picker/date-picker.component';
// export * from '../../shared/components/inputs/drop-down/drop-down.component';
