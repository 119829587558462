import { Injectable } from '@angular/core';
import { endPoints } from '../../shared/core/app.config';
import { CommonHttpService } from '../common-http/common-http.service';
import { lastValueFrom } from 'rxjs';

const routes = {
  getB2CSalesBackOfficeUsersSession: endPoints.apiUrl + endPoints.endPointName.getB2CSalesBackOfficeUsersSession,
};

@Injectable({
  providedIn: 'root'
})

export class BackOfficeUsersService {


  constructor(protected commonHttpService: CommonHttpService) {
  }

  getB2CSalesBackOfficeUsersSession(sessionId: any): Promise<any> {
    const apiUrl = `${routes.getB2CSalesBackOfficeUsersSession}/${sessionId}`;
    return lastValueFrom<any>(this.commonHttpService.httpGetServiceWithBearer(apiUrl));
  }

}
