<ng-container>
  <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription"
    [QnSubDescription]="qnData.qSubDescription">
  </lib-data-capture-heading>
  <section class="answer-container" id="secContainer">
    <ng-container *ngFor="let optionSet of qnData.answerSets">
      <lib-secondary-button id="{{'btn'+ optionSet.name}}" class="option" [isBlock]="true" [text]="optionSet.name"
        *ngIf="optionSet.active" (clicked)="submitOptionListAnswer(optionSet)">
      </lib-secondary-button>
    </ng-container>
  </section>
</ng-container>