import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { QuestionSetService, PagingService, AccordionItem } from 'wtw-ui-components';
import moment from 'moment';
import { SharedSettingsService } from 'wtw-ui-components';
import { ConstantService } from '../../services/constant/constant.service';

@Injectable({
  providedIn: 'root'
})
export class SalesCommonService {


  constructor(public sessionStorageService: SessionStorageService, private questionSetService: QuestionSetService,
              private pagingService: PagingService,
              private sharedSettingsService: SharedSettingsService,
              private constantService: ConstantService) { }

  stashInceptionExpiryDates() {

    // These dates can be either top-level or child questions.
    let inceptionDate = this.questionSetService.findAnswerForIntentCode('PolicyInceptionDate');
    if (inceptionDate === undefined || inceptionDate === null) {
      inceptionDate = this.questionSetService.findAnswerForIntentCode('InceptionDate');
    }

    let expiryDate = this.questionSetService.findAnswerForIntentCode('PolicyExpiryDate');
    if (expiryDate === undefined || expiryDate === null) {
      expiryDate = this.questionSetService.findAnswerForIntentCode('ExpiryDate');
    }

    const isExpiryDateThrougRating = sessionStorage.getItem("isExpiryDateThrougRating");

    if (inceptionDate) {
      if (inceptionDate.length === 10 && moment(inceptionDate, 'DD/MM/YYYY', true).isValid()) {
        this.sessionStorageService.set('InceptionDate', moment(inceptionDate, 'DD/MM/YYYY').format('MM/DD/YYYY'));
      }
      else {
        this.sessionStorageService.set('InceptionDate', this.pagingService.convertDateIntoUTC(inceptionDate));

      }
    }

    // PBI : 199748 Added condition to check if Expiry date fetching from rating calculation else user defined
    if (isExpiryDateThrougRating === 'true') {
      const qSummary = this.sessionStorageService.get('quoteSummary');
      if (qSummary) {
        const quoteSummary = JSON.parse(qSummary);
        const calculatedExiryDate = quoteSummary.expirydateOutput;
        if (calculatedExiryDate) {
          this.sessionStorageService.set('ExpiryDate', this.pagingService.convertDateIntoUTC(calculatedExiryDate));
        }
      }
    }

    else if (expiryDate) {
      if (moment(expiryDate, 'DD/MM/YYYY', true).isValid()) {
        const mExpiryDate = moment(expiryDate, 'DD/MM/YYYY', true);
        this.sessionStorageService.set('ExpiryDate', this.pagingService.convertDateIntoUTC(mExpiryDate));
      }
      else {
        this.sessionStorageService.set('ExpiryDate', this.pagingService.convertDateIntoUTC(expiryDate));
      }
    }

  }

  getFormatedOriginalGrossRate() {
    let formatedOriginalGrossRate = '';
    const isB2cMultiCarrierQuotingEnabled = this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled;
    const qSummary = this.sessionStorageService.get('quoteSummary');

    if (qSummary) {
      const quoteSummary = JSON.parse(qSummary);

      if (quoteSummary.selectedPaymentFrequencyIntentCode === (this.constantService.PaymentFrequencyMonthlyPayment)
      ) {
        return this.getFirstPaymentAmount();
      }

      if (isB2cMultiCarrierQuotingEnabled === true) {

        quoteSummary.multiCarrierQuoteSummary?.carrierQuoteSummaryList?.forEach(x => {

          if (x.carrierId === quoteSummary.multiCarrierQuoteSummary.selectedCarrierId) {
            const selectedCoverageId = quoteSummary?.multiCarrierQuoteSummary?.selectedCoverageId;
            const coverage = x.coverageList.find(coverage => coverage.id === selectedCoverageId);
            const grossPremium = coverage && coverage.grossPremium ? coverage.grossPremium : x.coverageList[0].grossPremium;

            if (x.currencySymbolAfterMoney) {
              formatedOriginalGrossRate = grossPremium + ' ' + x.currencySymbol;
            } else {
              formatedOriginalGrossRate = x.currencySymbol + ' ' + grossPremium;
            }
          }
        });
      }
      else {
        formatedOriginalGrossRate = quoteSummary?.ratingBindingInformations?.formatedOriginalGrossRate;
      }
    }

    return formatedOriginalGrossRate;
  }

  isResumeQuote(): boolean {
    const isB2cMultiCarrierQuotingEnabled = this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled;
    const existingCustomerQuote = this.sharedSettingsService.existingCustomerQuote;
    const isQSetOverride = this.sessionStorageService.get('isQSetOverride');
    let isResumeQuote = false;
    if (isB2cMultiCarrierQuotingEnabled === true && existingCustomerQuote && isQSetOverride) {
      /* in the MCI flow, eventhough if it is resume quote flow...
      if the user  'Edit' the quote questions, we have to consider it as a normal flow to get MultiQuote..
      otherwise, it will bring the single quote (by assuming it as a resume quote flow) */
      isResumeQuote = false;
    }
    else {
      isResumeQuote = existingCustomerQuote ?? false;
    }
    return isResumeQuote;
  }

  getFirstPaymentAmount(): string {
    const quoteSummary = this.sessionStorageService.get('quoteSummary');
    const qSummary = JSON.parse(quoteSummary);
    if (qSummary.firstMonthPaymentAmount
      && !qSummary.firstMonthPaymentAmount?.toString().includes(qSummary.currencySymbol)) {
      qSummary.firstMonthPaymentAmount = qSummary.currencySymbolAfterMoney ?
        qSummary.firstMonthPaymentAmount + " " + qSummary.currencySymbol :
        qSummary.currencySymbol + " " + qSummary.firstMonthPaymentAmount
    }
    return qSummary.firstMonthPaymentAmount;
  }

  setCoverageTerm(accordionData: AccordionItem, isTermVisible) {
    let coverageTermAmount: string;
    let coverageTermValue: string;


    if (accordionData?.isOptionalCover && accordionData.hasDefaultCoverageTerms &&
      (accordionData?.coverageTermValue === null || accordionData?.coverageTermValue === "")) {
      coverageTermValue = accordionData?.defaultCoverageTerms
      accordionData?.coverageTermWithPricing?.map(term => {
        if (term.coverageTermValue === accordionData?.defaultCoverageTerms) {
          coverageTermAmount = term.expanderDesc;
        }
      });
    } else if (accordionData?.isOptionalCover && !accordionData.hasDefaultCoverageTerms) {
      accordionData?.coverageTermWithPricing?.map(term => {
        if (term.coverageTermValue === accordionData?.primaryCoverTerm) {
          coverageTermAmount = term.expanderDesc;
        }
        isTermVisible = false; // to hide coverage term drop down
      });
    } else {
      if (accordionData?.coverageTermValue !== null && accordionData?.coverageTermValue !== "") {
        coverageTermValue = accordionData?.coverageTermValue
        coverageTermAmount = accordionData?.expanderDesc;
      }
    }
    return { coverageTermAmount: coverageTermAmount, isTermVisible: isTermVisible, coverageTermValue: coverageTermValue }
  }
}
