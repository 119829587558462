import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteInactiveGuard } from './guards/site-inactive.guard';
import { LogoutComponent, LinkExpiredScreenComponent } from 'wtw-ui-components';
import { LoginComponent } from './authentication/components/login/login.component';
import { ErrorPageComponent, SessionTimeoutComponent, DocusignErrorPageComponent } from 'wtw-ui-components';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home/new-quote',
        pathMatch: 'full'
    },
    {
        path: 'home',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./home/home.module').then(m =>
            m.HomeModule)
    },
    {
        path: 'quote',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./quote/quote.module').then(m =>
            m.QuoteModule)
    },
    {
        path: 'user-details',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./user-details/user-details.module').then(m =>
            m.UserDetailsModule)
    },
    {
        path: 'customer',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./customer-search/customer-search.module').then(m =>
            m.CustomerSearchModule)
    },
    {
        path: 'policy',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./search/policy-search/policy-search.module').then(m =>
            m.PolicySearchModule)
    },
    {
        path: 'policy',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
    },
    {
        path: 'payment',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./payment/payment.module').then(m =>
            m.PaymentModule)
    },
    {
        path: 'directdebit',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./payment/payment.module').then(m =>
            m.PaymentModule)
    },
    {
        path: 'account-details',
        canActivate: [SiteInactiveGuard],
        loadChildren: () => import('./account-details/account-details.module').then(m => m.AccountDetailsModule)
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'logout',
        component: LogoutComponent
    },
    {
        path: 'sessiontimeout',
        component: SessionTimeoutComponent
    },
    {
        path: 'error-page',
        component: ErrorPageComponent
    },
    {
        path: 'docusign-error-page',
        component: DocusignErrorPageComponent
    },
    {
        path: 'expiredlink',
        component: LinkExpiredScreenComponent
    },
    { path: '**', redirectTo: 'home/new-quote' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'top',
    initialNavigation: 'disabled'
})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
