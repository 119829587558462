import { Component, OnInit, Input } from '@angular/core';
import { ButtonBaseDirective } from '../button-base';

@Component({
  selector: 'lib-navigation-link-button',
  templateUrl: './navigation-link-button.component.html',
  styleUrls: ['./navigation-link-button.component.scss']
})
export class NavigationLinkButtonComponent extends ButtonBaseDirective implements OnInit {
  @Input() showBookmark: boolean;
  ngOnInit() { }
}
