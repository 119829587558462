import { Injectable } from '@angular/core';

import { SessionStorageService } from 'angular-web-storage';
import { BaseCustomerService } from '../../../services/customer/base-customer.service';
import { ICustomerFieldValidations } from '../../models/customer-field-validations.model';
import { Observable, lastValueFrom } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ProfileEnabledDetailsResolver  {

  private sponsorId: number;

  constructor(private customerService: BaseCustomerService,
              private sessionStorage: SessionStorageService) {}

  async resolve() {
    this.sponsorId = this.sessionStorage.get('SponsorId');
    const customerFields = await lastValueFrom(this.customerService.getCustomerFieldValidations(this.sponsorId));
    return customerFields;
  }
}
