import { Injectable } from '@angular/core';
import * as Msal from 'msal';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { CookieBannerService } from '../cookie-banner/cookie-banner.service';
import { ConfigService } from '../config/config.service';
import { IConfig } from '../../shared/models/app-config.model';
import * as jwt_decode from 'jwt-decode';
import { CommonHttpService } from '../common-http/common-http.service';
import { endPoints } from '../../shared/core/app.config';
import { SharedSettingsService } from '../settings/settings-base.service';
import { CreatePasswordService } from '../CreatePassword.service';

export interface MSALTokenInfo {
  unique_name: string;
  iat: string; // issued at time
}

@Injectable({
  providedIn: 'root'
})
export class MsalAuthenticationService {

  private authority: string;
  private clientApplication: Msal.UserAgentApplication;
  private appConfig: IConfig;
  private msalTokenInfo: MSALTokenInfo;
  private baseAuthorityUrl: string;
  private baseUrl = endPoints.baseUrl;
  private apiUrl = endPoints.apiUrl;
  private b2cLoginAuditLogUrl: string = endPoints.endPointName.b2cLoginAuditLog;
  createpasswordid: any;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private cookieBannerService: CookieBannerService,
    private sessionStorage: SessionStorageService,
    private commonHttpService: CommonHttpService,
    private settingsService: SharedSettingsService,
    private createPasswordService: CreatePasswordService
  ) {

    this.setConfigServiceData(this.configService.getServiceConfigData());

  }

  private setConfigServiceData(configServiceData) {
    if (configServiceData) {
      this.appConfig = configServiceData;
    }
    if (this.appConfig) {
      this.baseAuthorityUrl = `${this.appConfig.azureAd.instance}/tfp/${this.appConfig.azureAd.tenantId}/`;
      this.authority = this.baseAuthorityUrl + (this.sessionStorage.get('EnableMFA') === true ?
        `${this.appConfig.azureAd.signUpSignInMFAPolicy}` : `${this.appConfig.azureAd.signUpSignInPolicy}`);
      this.buildUserAgentApplication();
    }
  }

  private authCallback(errorDesc: any, token: any, error: any, tokenType: any) {
    let msalKeysToRetain: string[];
    if (error) {
      msalKeysToRetain = ['msal.error', 'msal.login.error', 'msal.error.description'];
      this.removeMSALItems(msalKeysToRetain);
      console.error(`${error} ${errorDesc}`);
      if (errorDesc.indexOf('AADB2C90118') > -1) {
        sessionStorage.setItem('forgotPassword', 'true');
        this.forgotPassword();
      } else if (errorDesc.indexOf('AADB2C90091') > -1) {
        // Signup Cancel
        this.buildUserAgentApplication();
        this.login();
      }
    } else if (localStorage.getItem('msal.error') !== 'access_denied') {
      this.createpasswordid = sessionStorage.getItem('createpasswordid');
      if (this.createpasswordid !== null && this.createpasswordid !== '' &&
        this.createpasswordid !== undefined && this.createpasswordid !== 'null') {
        this.createPasswordService.updateCreateNewPasswordStatus(this.createpasswordid);
      }
      // Signin/Signup success
      this.b2cLoginAuditLog();
      msalKeysToRetain = ['msal.idtoken'];
      this.removeMSALItems(msalKeysToRetain);
      const currentTime = new Date();
      this.settingsService.ATPLoginExpiryTime = (currentTime.setMinutes(currentTime.getMinutes() + this.settingsService.TimeOutDuration));

    }
  }

  private forgotPassword() {
    this.authority = this.baseAuthorityUrl + `${this.appConfig.azureAd.resetPasswordPolicy}`;
    this.buildUserAgentApplication();
    this.login();
  }

  public createPassword(createpasswordid: any) {
    sessionStorage.setItem('createpasswordid', createpasswordid);
    this.authority = this.baseAuthorityUrl + `${this.appConfig.azureAd.resetPasswordPolicy}`;
    this.buildUserAgentApplication();
    this.login();
  }

  private buildUserAgentApplication(): void {
    this.clientApplication = new Msal.UserAgentApplication(
      this.appConfig.azureAd.clientId,
      this.authority,
      this.authCallback.bind(this),
      {
        cacheLocation: 'localStorage',
        navigateToLoginRequestUrl: false,
        postLogoutRedirectUri: window.location.origin + this.baseUrl + 'logout',
        redirectUri: window.location.origin + this.baseUrl,
        unprotectedResources: ['api/configuration/get'], // Bearer token is not required for the unprotected API resources
        validateAuthority: false,
        loadFrameTimeout: 30000
      });
  }

  b2cLoginAuditLog() {
    const apiURL = this.apiUrl + this.b2cLoginAuditLogUrl;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, null);
  }

  public login(): void {
    this.removeMSALItems();
    this.clientApplication.loginRedirect(this.appConfig.azureAd.b2cScopes, this.buildB2CLoginQueryString());
  }

  public logout(): void {
    this.cookieBannerService.clearLocalStorage();
    this.cookieBannerService.clearSessionStorage();
    this.cookieBannerService.clearExternalIntegrationSessionStorage();
    this.clientApplication.logout();
  }

  public getUserEmail(): string {
    this.msalTokenInfo = this.clientApplication.getUser().idToken as MSALTokenInfo;
    return this.msalTokenInfo.unique_name;
  }

  // This method provides the token issued time (user logged in time)
  // iat -> Issued at Time
  public getUserLoggedInDateTime(): string {
    this.msalTokenInfo = this.clientApplication.getUser().idToken as MSALTokenInfo;
    return this.msalTokenInfo.iat;
  }

  public changePassword() {
    this.authority = this.baseAuthorityUrl + `${this.appConfig.azureAd.changePasswordPolicyId}`;
    this.buildUserAgentApplication();
    this.login();
  }

  public getUser(): Msal.User {
    return this.clientApplication.getUser();
  }

  public authenticated(): Promise<boolean> {
    const promise: Promise<boolean> = new Promise(
      (resolve: (res: boolean) => void, reject: (res: boolean) => void) => {
        this.clientApplication.acquireTokenSilent(this.appConfig.azureAd.b2cScopes)
          .then(token => {
            resolve(true);
          }).catch((error) => {
            resolve(false);
          });
      });
    return promise;
  }

  public isSessionTimedOut(): boolean {
    const jwtToken = localStorage.getItem('msal.idtoken');

    if (jwtToken === null) {
      return false;
    }

    let tokenExpiryTime;
    if (this.settingsService.isATPLogin) {
      tokenExpiryTime = localStorage.getItem('ATPLoginExpiryTime');

      if (tokenExpiryTime === null || tokenExpiryTime === 'null' || tokenExpiryTime === undefined) {
        // *** To validate the 'msal.idtoken' we need ATPLoginExpirytime from SessonStorage.. *** //
        // *** If it is not available then we can get it by decoding the 'msal.idtoken' ***' //
        // const jwtObject = jwt_decode(jwtToken);
        // // this.settingsService.ATPLoginExpiryTime = jwtObject.exp * 1000;
        // // tokenExpiryTime = this.settingsService.ATPLoginExpiryTime;
        this.logout();
        return true;
      }

    } else {
      tokenExpiryTime = localStorage.getItem('idTokenExpiryTime');
    }

    // *** If we don't have Expiry Time in session then
    // *** we can cosnider that session a invalid session and can remove the msal token ***//
    if (tokenExpiryTime === null || tokenExpiryTime === 'null' || tokenExpiryTime === undefined) {
      localStorage.removeItem('ATPLoginExpiryTime');
      localStorage.removeItem('idTokenExpiryTime');

      this.cookieBannerService.clearLocalStorage();
      this.cookieBannerService.clearSessionStorage();
      this.cookieBannerService.clearExternalIntegrationSessionStorage();
      this.router.navigate(['**']);
    }

    const currentTime = new Date().getTime();
    if (jwtToken && tokenExpiryTime && tokenExpiryTime < currentTime) {
      localStorage.removeItem('ATPLoginExpiryTime');
      localStorage.removeItem('idTokenExpiryTime');
      localStorage.removeItem('msal.idtoken');
      return true;
    } else {
      return false;
    }

    return false;
  }

  public isAuthenticated(): boolean {
    const isSessionTimeout = this.isSessionTimedOut();
    const jwtToken = localStorage.getItem('msal.idtoken');
    if (jwtToken === null || isSessionTimeout) {
      return false;
    } else {
      return true;
    }
  }

  public removeMSALItems(msalKeysToRetain?: string[]) {
    const keys: string[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).startsWith('msal')) {
        if (msalKeysToRetain && msalKeysToRetain.indexOf(localStorage.key(i)) > -1) {
          continue;
        }
        keys.push(localStorage.key(i));
      }
    }

    for (const key of keys) {
      localStorage.removeItem(key);
    }
  }

  private buildB2CLoginQueryString(): string {
    const languageCode = this.sessionStorage.get('LanguageCode');
    const URL = 'ui_locales=' + (languageCode === 'zh-CHS' ? 'zh-Hans' : (languageCode === 'sr-RS' ? 'sr-latn-cs' : languageCode)) +
      '&campaignId=' + this.sessionStorage.get('SponsorName');
    return URL;
  }
}
