export interface ModalContent {
    headingText: string;
    additionalText: string;
    instructionText: string;
    iconName?: string;
    severityLevel?: SeverityLevel;
    buttons?: ButtonContent[];
    closeButton?: boolean;
    logo?: string;
    bodyText?: any;
    linkLabel?: any;
    sheetButtonText?: any;
    arrayOfInstructiontext?: any;
    subHeadingText?: string;
    subHeadingAdditionalText?: string,
    secondsCount?: number,
    secondsText?: string,
    showCheckbox?: boolean,
    checkboxLabel?: string,
    showTextbox?: boolean,
    textboxLabel?: string,
    isRenewal?: boolean
}

export interface ButtonContent {
    buttonText: string;
    tag: any;
    isSecondary?: boolean;
    hasFocus?: boolean;
}

export enum SeverityLevel {
    Information = 'Information',
    Warning = 'Warning',
    Error = 'Error',
    Update = 'Update'
}
