import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { LayoutService } from '../../../../services/layout/layout.service';


@Component({
  selector: 'lib-pdf-viewer',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {

  @Input() pdfSrc: Uint8Array;
  @Input() fileName: string;

  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;

  zoom = 0.93;
  fitToPage = true;
  autoresize = true;
  totalPages: number;
  page = 1;
  toggleMode = false;
  mimeType = 'application/pdf';


  constructor(private layoutService: LayoutService, @Inject(DOCUMENT) private document: Document, ) { }

  ngOnInit(): void {
    // ----- Checking screen resolution by angular layout/breakpoints
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  incrementZoom(amount: number) {
    this.zoom += amount;   }

  fit_to_screen(amount: number) {
    this.zoom = amount;
  }

  afterLoadComplete(pdf): void {
    this.totalPages = pdf.numPages;
  }
  pagechanging(e) {
    this.page = e.pageNumber;
  }

  onPrint() {
    const file = new Blob([this.pdfSrc], { type: this.mimeType });
    const url = URL.createObjectURL(file);
    window.open(url).print();
  }

  onPDFDownload() {
       const file = new Blob([this.pdfSrc], { type: this.mimeType });
       const nav = (window.navigator as any);  
       if (nav.msSaveOrOpenBlob) {
        // IE11 and Edge
        nav.msSaveOrOpenBlob(file, this.fileName);
      } else {
        // Chrome, Safari, Firefox, Opera
        const url = URL.createObjectURL(file);
        this.openLink(url);
        // Remove the link when done
        window.URL.revokeObjectURL(url);
    }
  }

  private openLink(url: string) {
    const a = document.createElement('a');
    // Firefox requires the link to be in the body
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = this.fileName;
    a.click();
    // Remove the link when done
    document.body.removeChild(a);
}

  mobilePdfOpen() {
  this.toggleMode = !this.toggleMode;
  this.document.body.classList.add('noScroll');
}

  mobilePdfClose() {
  this.toggleMode = !this.toggleMode;
  this.document.body.classList.remove('noScroll');
}

  @HostListener('window:resize', ['$event'])
onResize(event) {
   if (!this.isMobile) {
    this.document.body.classList.remove('noScroll');
   }
}


}
