import { Injectable, OnInit } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { endPoints } from '../../shared/core/app.config';
import { AnswerSets } from '../../shared/models';
import { OndemandDataRequest } from '../../shared/models/ondemanddatarequest.model';
import { Question } from '../../shared/models/question.model';
import { BehaviorSubject, Observable, Subject, lastValueFrom } from 'rxjs';
import { CommonHttpService } from '../common-http/common-http.service';
import { BaseConstantService } from '../../services/constant/base-constant.service';
import { PagingService } from '../app-insights/paging-service';
import { SharedSettingsService } from '../../services/settings/settings-base.service';
import moment from 'moment';
import { NotificationAlertService } from '../../services/notification-alert/notification-alert.service';
import { DropDownField } from '../../shared/form-field/dropdown-field';
import { CurrencyFormatPipe } from '../../shared/pipes/currency-format.pipe';

@Injectable({
  providedIn: 'root'
})

export class QuestionSetService {

  private internalIsInitialized = false;

  currentPosition = 0;
  private qIntentCode: string = '';
  private isFromYourPolicies: boolean;

  public internalQuestionSet: Question[];
  answerSet: AnswerSets;
  formFieldIteration: number = 1;
  initialRepeatingGroup: boolean = true;
  qSubDescriptionObservable = new Subject<string>();
  selectedParentDropDownValue = [];
  deletedRepeatingGroup = [];
  private _lastPolicyQuestion = '';
  private getOnDemandDataRequestUrl = `${endPoints.apiUrl}${endPoints.endPointName.getOnDemandDataRequest}`;
  private getPolicyResponseDataUrl = endPoints.apiUrl + endPoints.endPointName.getPolicyResponseData;

  private navigateToPreviousQues = new Subject<any>();

  public _navigateToPreviousQuesValue: Observable<any> = this.navigateToPreviousQues.asObservable();

  public previousAnswerLastQuestion = new BehaviorSubject('');
  public grossPremiumPreviousAnswer = new BehaviorSubject('');
  public getPrimaryCoveragTerm = new BehaviorSubject('');

  isSingleCarrierSingleQuoteResumeFlow = new BehaviorSubject<boolean>(null);

  // Holds the state of unsaved quote changes, initialized with a default value of false, indicating no unsaved changes.
  private readonly _unsavedQuoteChangesNotifier$ = new BehaviorSubject<boolean>(false);
  // Other components can subscribe to this to be notified of unsaved quote changes.
  readonly unsavedQuoteChanges$ = this._unsavedQuoteChangesNotifier$.asObservable();

  private _quoteAccepted = new BehaviorSubject<boolean>(false);

  private _profileTypeSetNotifier$ = new BehaviorSubject<boolean>(false);
  private _referralPermissionSetNotifier$ = new BehaviorSubject<boolean>(false);
  private _policyQuestions: Question[];
  private _policyQuestionResponses: any[] = [];
  private _policyQuestionHidden: boolean = false;
  private referralPermissions: any;
  private _enableSlowReveal: boolean = false;

  constructor(private sessionStorage: SessionStorageService,
              private commonHttpService: CommonHttpService,
              private pagingService: PagingService,
              private sharedSettingsService: SharedSettingsService,
              private alertService: NotificationAlertService,
              private currencyPipe: CurrencyFormatPipe
  ) {
  }

  initialize(questionSet: Question[], position: number = 0): void {
    this.internalQuestionSet = questionSet;
    this.sessionStorage.set(BaseConstantService.KEY_PrimaryQuestionData, JSON.stringify(this.internalQuestionSet));
    // this.sessionStorage.set(BaseConstantService.KEY_AnsweredQuestionSet, this.internalQuestionSet);
    this.currentPosition = position || 0;

    if (this.internalQuestionSet) {
      this.internalIsInitialized = true;
    }
  }

  get isInitialized(): boolean {
    const qset = JSON.parse(this.sessionStorage.get('AnsweredQuestionSet'));
    return this.internalIsInitialized || qset;
  }

  get currentQuestion(): Question {
    if (this.internalGetQuestionSet()) {
      return this.internalGetQuestionSet()[this.currentPosition];
    } else {
      return null;
    }
  }

  get currentQuestionType(): string {
    return this.currentQuestion && this.currentQuestion.qQuestionType;
  }

  get position(): number {
    return this.currentPosition;
  }

  get positionForType(): number {
    // We should only consider non-hidden questions.
    const intentCode = this.currentQuestion && this.currentQuestion.qIntentCode;
    const internalQuestionSet = this.internalQuestionSet?.filter(q => !q.isHidden && q.qQuestionFormat.toLowerCase() !== 'recaptcha');
    if (internalQuestionSet != null) {
      return internalQuestionSet.filter(q => q.qQuestionType === this.currentQuestionType)
        .findIndex(f => f.qIntentCode === intentCode);
    } else {
      return -1;
    }
  }

  getAlertMessage(questionSet) {
    if (questionSet && this.internalQuestionSet != null) {
      questionSet.forEach((qs, qsKey) => {
        this.internalQuestionSet.forEach((q, qKey) => {
          if (qs.qIntentCode === q.parentQIntentCode) {
            questionSet[qsKey]['alertMessage'] = q;
            qs.answerSets.forEach((ans) => {
              if (ans.name === q.parentOValue) {
                ans['alertMessage'] = q;
              }
              if (ans?.childQuestionSets?.length > 0) {
                this.getAlertMessage(ans.childQuestionSets);
              }
            });
          }
        });
      });
      return questionSet;
    }
  }

  // To Show notification alert messages
  showAlertMessages(field: DropDownField, value) {
    const alertMsg = field.options.filter((object, ind) => {
      object['index'] = ind;
      return object.value === value;
    })[0];
    if (alertMsg && alertMsg !== undefined && alertMsg.alertMessage?.cssClasses !== undefined) {
      const options = {
        autoClose: false,
        keepAfterRouteChange: false,
        alertType: field.key,
        parentOValue: alertMsg.alertMessage?.parentOValue,
        parentQIntentCode: alertMsg.alertMessage?.parentQIntentCode,
        CssClasses: alertMsg.alertMessage?.cssClasses
      };
      this.alertService.getNotification(alertMsg.alertMessage, options);
    }
  }

  get answeredQuestions(): Question[] {
    const questionSet = this.internalGetQuestionSet();
    return questionSet && questionSet.filter(qs => qs.answerSets?.some(as => as.isAnswered) ||
      qs.childQuestionSets?.some(cqs => cqs.answerSets?.some(as => as.isAnswered)));
  }


  hasVisibleQuestions(questionType: string = 'Quote'): boolean {
    const visibleQuestions = this.internalGetQuestionSet()?.filter(q =>
      q.qQuestionType.toLowerCase() === questionType.toLowerCase()
      && !q.isHidden);

    return (visibleQuestions?.length > 0);
  }

  getQuestionByIntentCode(qIntentCode: string): Question {
    return this.internalGetQuestionSet().filter(qnSet =>
      qnSet.qIntentCode.toLowerCase() === qIntentCode.toLowerCase())[0];
  }

  // moving this code in service as it is used in different scenarios
  onDeleteRepeatingGroup(questionSet, repeatingGroupToDelete, deleteFromPreviousAnswer) {
    this.setRepeatingGroupData(repeatingGroupToDelete);
    const result = repeatingGroupToDelete.qIntentCode.split("~");
    const repeatingGroupName = result[0];

    questionSet = this.removeEmptyIteration(questionSet, repeatingGroupName, deleteFromPreviousAnswer);

    let childLink = repeatingGroupToDelete?.childQIntentCode;
    let clonedArray = JSON.parse(JSON.stringify(questionSet));
    const index = clonedArray.findIndex(i => i.qIntentCode === repeatingGroupToDelete.qIntentCode);
    this.deletedRepeatingGroup = clonedArray.splice(index, 1);
    let removedIterationIndex = parseInt(result[1]);
    const questionToReorder = clonedArray.filter((question, queIndex) => queIndex >= index &&
      this.getRepeatingGroupName(question.qIntentCode) === repeatingGroupName);
    let backupChildQIntentCode;
    if (questionToReorder.length > 0) {
      for (let i = 0; i < questionToReorder.length; i++) {
        questionToReorder[i].qIntentCode = repeatingGroupName + '~' + removedIterationIndex;
        if (i < questionToReorder.length - 1) {
          backupChildQIntentCode = questionToReorder[i].childQIntentCode;
          questionToReorder[i].childQIntentCode = childLink;
          questionToReorder[i].answerSets[0].childQIntentCode = childLink;
          childLink = backupChildQIntentCode;
        }
        questionToReorder[i].qSubDescription = this.getRepeatingGroupqSubDescription
          (questionToReorder[i]?.qSubDescription) + ' (' + (removedIterationIndex + 1) + ')';
        questionToReorder[i]?.childQuestionSets.forEach((child) => {
          const childQIntentCode = this.getRepeatingGroupChildQintentCode(child.qIntentCode);
          child.qIntentCode = repeatingGroupName + '~' + removedIterationIndex + '~' + childQIntentCode;
        });

        removedIterationIndex++;
      }
    } else {
      // When deleting last iteration of repeating group then
      // shift childQintentcode to previous question.
      childLink = repeatingGroupToDelete.childQIntentCode;
      clonedArray[index - 1].childQIntentCode = childLink;
      clonedArray[index - 1].answerSets[0].childQIntentCode = childLink;
    }
    const currentlRepeatingGroupCount = this.sessionStorage.get('currentlRepeatingGroupCount') - 1;
    clonedArray = this.increaseRepeatingGroupCount(clonedArray, currentlRepeatingGroupCount, repeatingGroupName);
    clonedArray = this.savedRepeatingGroup(clonedArray, repeatingGroupName, 'decrease');
    this.deletedRepeatingGroup[0].childQIntentCode = clonedArray[index]?.qIntentCode;
    this.formFieldIteration = currentlRepeatingGroupCount - 1;
    questionSet = clonedArray;
    this.internalQuestionSet = questionSet;
    this.sessionStorage.set('AnsweredQuestionSet', JSON.stringify(questionSet));
    let currentQuesIndex = index - 1;
    if (questionSet[currentQuesIndex]?.qIntentCode.includes('.Length') &&
      questionSet[currentQuesIndex].qQuestionFormat === BaseConstantService.Key_RepeatingGroupLength) {
      currentQuesIndex = currentQuesIndex - 1;
    }
    this.initialize(this.internalQuestionSet, currentQuesIndex);
  }

  // To remove blank iteration from question set when we delete any
  // iteration from previous answer section
  removeEmptyIteration(clonedArray, repeatingGroupName, deleteFromPreviousAnswer) {
    const currentRepeatingGroupQues = clonedArray.filter(i =>
      this.getRepeatingGroupName(i.qIntentCode) === repeatingGroupName);
    const currentRepeatingGroupQuesCount = currentRepeatingGroupQues.length - 1;
    const currentQues = currentRepeatingGroupQues[currentRepeatingGroupQuesCount];
    const answered = currentQues?.childQuestionSets?.some(cqs => cqs.answerSets?.some(as => as.isAnswered));
    if (answered === false) {
      if (deleteFromPreviousAnswer === true) {
        const index = clonedArray.findIndex(i => i.qIntentCode === currentQues.qIntentCode);
        this.sessionStorage.set('currentlRepeatingGroupCount', this.sessionStorage.get('currentlRepeatingGroupCount') - 1);
        const backupChildQIntentCode = clonedArray[index].childQIntentCode;
        clonedArray.splice(index, 1);
        clonedArray[index - 1].childQIntentCode = backupChildQIntentCode;
        clonedArray[index - 1].answerSets[0].childQIntentCode = backupChildQIntentCode;
      } else {
        clonedArray = this.savedRepeatingGroup(clonedArray, repeatingGroupName, 'increase');
      }
    }
    return clonedArray;
  }



  clearUnSelectedOptionChildAnsweredQuestions(question: Question) {

    this.onDeleteRepeatingGroup(this.internalQuestionSet, question, false);
    if (this.deletedRepeatingGroup[0].childQIntentCode) {
      const grandChildQuestion = this.internalQuestionSet.filter(q => q.qIntentCode === this.deletedRepeatingGroup[0].childQIntentCode);
      // delete all the child repeating groups
      if (grandChildQuestion[0].qQuestionFormat === BaseConstantService.Key_RepeatingGroupQuestionFormat) {
        this.clearUnSelectedOptionChildAnsweredQuestions(grandChildQuestion[0]);
      }
    }

  }

  checkIfUnSelectedOptionHasAnsweredChild() {
    const UnSelectedOption = this.currentQuestion.qQuestionFormat !== "GroupPanel" ?
      this.currentQuestion?.answerSets.filter(as => !as.isAnswered) : [];
    this.internalQuestionSet?.filter(answeredRepeatingGroupChildQue => {
      UnSelectedOption.filter(child => {
        if (child?.childQIntentCode === answeredRepeatingGroupChildQue?.qIntentCode &&
          answeredRepeatingGroupChildQue?.qQuestionFormat === BaseConstantService.Key_RepeatingGroupQuestionFormat) {
          const currentRepeatingGroupName = answeredRepeatingGroupChildQue.qIntentCode.split("~");
          const CurrentRepeatingGroupCount = this.getCurrentRepeatingGroupCount(currentRepeatingGroupName[0])
          if (answeredRepeatingGroupChildQue?.childQuestionSets?.
            some(answerSet => answerSet?.answerSets?.some(as => as.isAnswered)) || CurrentRepeatingGroupCount === 1) {

            this.clearUnSelectedOptionChildAnsweredQuestions(answeredRepeatingGroupChildQue);
            // index of current ques
            const index = this.internalQuestionSet.findIndex(q => q.qIntentCode === this.currentQuestion.qIntentCode);
            // index of child answerset whose repeating group is deleted to update the name of repeating group
            const childAnswerSetIndex = this.internalQuestionSet[index].answerSets.findIndex(q => q.name === child.name);
            // updating with the original repeating group name as created one deleted
            if (childAnswerSetIndex > 0) {
              this.internalQuestionSet[index].answerSets[childAnswerSetIndex].childQIntentCode = currentRepeatingGroupName[0];
            }
            const originalQuestionSet = this.sessionStorage.get('originalQuestionSet');

            originalQuestionSet.filter((questionObject, questionIndex) => {
              if (questionObject.qIntentCode === currentRepeatingGroupName[0]) {
                this.internalQuestionSet.splice(questionIndex, 0, questionObject)
              }
            });
          }
        }

      })

    }

    )

  }

  checkIfDuplicateChildQuestions() {

    // find if childQIntentCode are same or duplicate
    const childQIntentCodeArray = this.currentQuestion.answerSets.map((item) => {
      return item.childQIntentCode;
    });
    const isChildQIntentCodeDuplicate = childQIntentCodeArray.some((item, index) => {
      return childQIntentCodeArray.indexOf(item) !== index;
    });
    return isChildQIntentCodeDuplicate;
  }

  navigateNext() {
    if (this.currentQuestion) {
      const answered = this.currentQuestion.answerSets.filter(as => as.isAnswered);
      const answers = answered.length > 0 ? answered : this.currentQuestion.answerSets;

      const isChildQIntentCodeDuplicate = this.checkIfDuplicateChildQuestions();
      // delete repeating  group question of unselected option if option has different child question
      if (this.currentQuestion.qQuestionFormat !== BaseConstantService.Key_RepeatingGroupQuestionFormat
        && !isChildQIntentCodeDuplicate) {
        this.checkIfUnSelectedOptionHasAnsweredChild();
      }


      // Are we on a previously answered question?
      const visibleAnsweredQuestions = this.answeredQuestions.filter(q => !q.isHidden);
      const answeredQuestionsOfType = visibleAnsweredQuestions.filter(q => q.qQuestionType === this.currentQuestion.qQuestionType);


      const answeredPosition =
        answeredQuestionsOfType.findIndex(qs => qs === this.currentQuestion);

      // Are we changing path from the previous answer?
      if (answeredPosition > -1) {
        // const nextAnsweredPosition = answeredPosition + 1;
        let nextAnsweredPosition = -1;
        if (this.currentQuestion.answerSets?.length > 1) {
          const nextQuestionLink = this.currentQuestion?.answerSets?.filter(x => x.isAnswered === true);

          if (nextQuestionLink?.length > 0) {
            nextAnsweredPosition = answeredQuestionsOfType.findIndex(x => x.qIntentCode === nextQuestionLink[0].childQIntentCode);

          }
          else {
            nextAnsweredPosition = answeredPosition + 1;
          }
        } else {
          nextAnsweredPosition = answeredPosition + 1;
        }

        const nextAnsweredQuestion = answeredQuestionsOfType[nextAnsweredPosition];
        const nextQuestion = this.answeredQuestions.find(x => x.qIntentCode === answers[0].childQIntentCode);

        // when clicking on add more all the question from current question getting dissappear because of below condition
        // hence adding this condition
        const isMaxRepeat = this.sessionStorage.get('isRepeat');
        const existingCustomerDraftQuote = this.sessionStorage.get('ExistingCustomerDraftQuote');
        const isCopyQuoteAtpClient = this.sharedSettingsService.isCopyQuoteAtpClient;

        // When integrating with external clients, Previous Answers may have missing questions from the question template.
        const childAnsweredQuestion = answeredQuestionsOfType.find(
          qs => qs.qIntentCode === answers[0].childQIntentCode
            || (qs.childQuestionSets && qs.childQuestionSets.find(cqs => cqs.qIntentCode === answers[0].childQIntentCode))
        );
        // If we have the next answered question in previous answers, and it doesn't match the one we're about to navigate to.
        if (nextAnsweredQuestion && nextQuestion?.isHidden !== true
          && ((answers[0].childQIntentCode !== nextAnsweredQuestion.qIntentCode) && childAnsweredQuestion) && isMaxRepeat !== 'true'
          && (!existingCustomerDraftQuote || !isCopyQuoteAtpClient)) {
          // Deliberately navigating backwards as list will reduce in size on each iteration.
          for (let i = answeredQuestionsOfType.length - 1; i >= nextAnsweredPosition; i--) {
            this.clearAnswers(answeredQuestionsOfType[i]);
          }
        }
      }

      const q =
        this.internalGetQuestionSet().find(
          qs => answers[0].childQIntentCode && qs.qIntentCode.toLowerCase() === answers[0].childQIntentCode.toLowerCase());

      if (q && q.offsetBasis && this.currentQuestion.qIntentCode === q.offsetBasis && answers[0].name) {
        if (moment(answers[0].name, 'DD/MM/YYYY').isValid()) {
          this.sessionStorage.set(`offsetControl-${this.currentQuestion.qIntentCode}`, moment(answers[0].name, 'DD/MM/YYYY'));
        } else if (moment(answers[0].name, 'YYYY-MM-DD').isValid()) {
          const dateToStore = moment(answers[0].name, 'YYYY-MM-DD').format('DD/MM/YYYY');
          this.sessionStorage.set(`offsetControl-${this.currentQuestion.qIntentCode}`, dateToStore);
        }
      }

      let childQIntentCode = answers[0].childQIntentCode;
      if (this.currentQuestion?.isRepeatingGroupToBeNext === true) {
        childQIntentCode = this.currentQuestion.childQIntentCode;
      }

      this.navigateToIntentCode(childQIntentCode);
    } else {
      this.currentPosition = -1;
    }
  }

  navigatePrevious(navigateFrom: string) {
    if (this.currentQuestion) {
      // We should not allow backwards navigation to a hidden question.
      const visibleAnsweredQuestions = this.answeredQuestions.filter(q => !q.isHidden);
      // Are we on a previously answered question?
      const answeredPosition =
        visibleAnsweredQuestions.findIndex(qs => qs === this.currentQuestion);

      if (this.sessionStorage.get('maxRepeat') > 1) {
        this.sessionStorage.set('PreviousAnsEditButton', false);
        this.IsNewRepeatingGroup = false;
        this.removeEmptyRepeatingGroupClone();
      }
      if (answeredPosition > -1) {
        let previousIntentCode;
        // on deleting second repeating queues it is navigating to two question back not last question hence adding below condition.
        const isDeletingQuesAns = this.sessionStorage.get('isDeletingQuesAns');
        if (this.currentQuestion?.qQuestionFormat === 'RepeatingGroup'
          && (parseInt(this.currentQuestion.qIntentCode.split("~")[1]) !== 0 || isDeletingQuesAns)
          && navigateFrom === "navigateAfterDelete") {

          previousIntentCode = visibleAnsweredQuestions[answeredPosition].qIntentCode;
        } else {
          if (answeredPosition > 0) {
            previousIntentCode = visibleAnsweredQuestions[answeredPosition - 1].qIntentCode;
          } else {
            // External integration scenarios may not load all questions into Previous Answers.
            // If index is -1 then load the first question from the question set
            const validationResult = this.sessionStorage.get('externalValidationResult');
            if (validationResult && validationResult.externalIntegration) {
              const firstQS = this.internalQuestionSet.filter(qs => qs?.isHidden !== true)[0];
              previousIntentCode = firstQS?.qIntentCode;
            } else {
              previousIntentCode = visibleAnsweredQuestions[0].qIntentCode;
            }
          }
        }

        this.navigateToIntentCode(previousIntentCode);
      } else {
        const lastAnsweredQuestionIntentCode = this.sessionStorage.get('lastAnsweredQuestionIntentCode');
        if (lastAnsweredQuestionIntentCode) {
          const index = visibleAnsweredQuestions.findIndex(q => q.qIntentCode === lastAnsweredQuestionIntentCode);
          this.navigateToIntentCode((visibleAnsweredQuestions[index]).qIntentCode);
        }
      }

    } else {
      this.currentPosition = -1;
    }
  }

  // To remove empty repeating group clone questions when navigating back.
  removeEmptyRepeatingGroupClone() {
    const answered = this.currentQuestion.childQuestionSets?.some(cqs => cqs.answerSets?.some(as => as.isAnswered));
    this.sessionStorage.set('isDeletingQuesAns', answered);
    if (answered === false && this.currentQuestion.qQuestionFormat !== BaseConstantService.Key_RepeatingGroupLength &&
      this.formFieldIteration > 1 && this.currentQuestion.qQuestionFormat === BaseConstantService.Key_RepeatingGroupQuestionFormat
      && this.currentQuestion?.childQuestionSets.length > 0) {
      const index = this.internalQuestionSet.findIndex(i => i.qIntentCode === this.currentQuestion.qIntentCode);
      this.formFieldIteration = this.sessionStorage.get('currentlRepeatingGroupCount') - 1;
      const repeatingGroupName = this.getRepeatingGroupName(this.currentQuestion.qIntentCode);
      this.updateRepeatingGroupCount(repeatingGroupName, this.formFieldIteration);
      this.internalQuestionSet = this.savedRepeatingGroup(this.internalQuestionSet, repeatingGroupName, 'decrease');
      this.sessionStorage.set('currentlRepeatingGroupCount', this.formFieldIteration);
      const backupChildQIntentCode = this.internalQuestionSet[index].childQIntentCode;
      this.internalQuestionSet.splice(index, 1);
      this.internalQuestionSet[index - 1].childQIntentCode = backupChildQIntentCode;
      this.internalQuestionSet[index - 1].answerSets[0].childQIntentCode = backupChildQIntentCode;

      // when navigating back from current cloned question without saving and again try to add more instead
      // of adding next instance jumping to next question
      this.sessionStorage.set(BaseConstantService.KEY_PrimaryQuestionData, JSON.stringify(this.internalQuestionSet));
    }
  }

  navigateToIntentCode(intentCode: string) {
    this.currentPosition =
      this.internalGetQuestionSet().findIndex(qs => intentCode && qs.qIntentCode.toLowerCase() === intentCode.toLowerCase());
  }

  navigateToQuestionType(questionType: string) {
    this.currentPosition =
      this.internalGetQuestionSet().findIndex(qs => qs.qQuestionType.toLowerCase() === questionType.toLowerCase());
  }

  navigateToLastQuestionOfType(questionType: string) {
    // We should not allow backwards navigation to a hidden question.
    const visibleQuestions = this.internalGetQuestionSet().filter(q => !q.isHidden);
    if (this.sessionStorage.get('cAttributeEntries')) {
      const question = this.internalGetQuestionSet()[this.sessionStorage.get('QuestionSetPosition')];
      this.navigateToIntentCode(question && question.qIntentCode);
    }
    else {
      const question = visibleQuestions.reverse().filter(qs => qs.qQuestionType.toLowerCase() === questionType.toLowerCase())[0];
      this.navigateToIntentCode(question && question.qIntentCode);
    }
  }

  navigateToLastQuestionOfTypeByIntentcode(questionType: string, intentCode: string) {
    // We should not allow backwards navigation to a hidden question.
    const visibleQuestions = this.internalGetQuestionSet().filter(q => !q.isHidden);
    const question = visibleQuestions.reverse().filter(
      qs => qs.qQuestionType.toLowerCase() === questionType.toLowerCase() && qs.qIntentCode.toLowerCase() === intentCode.toLowerCase())[0];

    this.navigateToIntentCode(question && question.qIntentCode);
  }

  clearAll() {
    this.sessionStorage.remove('AnsweredQuestionSet');
    this.sessionStorage.remove('QuestionSetPosition');
    if (this.internalIsInitialized && this.answeredQuestions) {
      for (let i = this.answeredQuestions.length - 1; i >= 0; i--) {
        this.clearAnswers(this.answeredQuestions[i]);
      }
    }
    this.currentPosition = 0;
    this.internalIsInitialized = false;
  }

  findAnswerForIntentCode(intentCode: string): string {
    let result: string;
    if (this.answeredQuestions) {
      for (const qs of this.answeredQuestions) {
        if (qs.qIntentCode.toLowerCase() === intentCode.toLowerCase()) {
          result = this.getQuestionAnswer(qs);
          break;
        } else {
          const childResult = qs.childQuestionSets.filter(cqs => cqs.qIntentCode.toLowerCase() === intentCode.toLowerCase())[0];
          if (childResult) {
            result = this.getQuestionAnswer(childResult);
            break;
          }
        }
      }
    }
    return result;
  }

  stashQuestionSet() {
    this.sessionStorage.set('AnsweredQuestionSet', JSON.stringify(this.internalGetQuestionSet()));
    this.sessionStorage.set('QuestionSetPosition', this.currentPosition);
  }

  prePopulateAnswer(question: Question, data: any) {
    this.prePopulateValue(question, data);
    this.qIntentCode = question.qIntentCode;
    // Iterate child question set.
    if (question.childQuestionSets && question.childQuestionSets?.length > 0) {
      for (const childQuestion of question.childQuestionSets) {
        if (question.qQuestionFormat === BaseConstantService.Key_RepeatingGroupQuestionFormat) {
          // if quote and policy question are duplicate then causing the issue of pre populate the policy question with the response of
          // quote question to avoid the issue below code is impleme.
          let dataObjectArray = [];
          dataObjectArray = Object.entries(data)
            .filter(([key, value]) => key.toLowerCase().replace('[', '~').replace('].', '~') === childQuestion.qIntentCode.toLowerCase());
          const isdataValueMatch = dataObjectArray?.length !== 0 ? dataObjectArray[0][0]?.toLowerCase().
            replace('[', '~').replace('].', '~').split('~')[0] === question.qIntentCode.toLowerCase().
              toLowerCase().split('~')[0] : false;
          if (isdataValueMatch) {
            this.prePopulateValue(childQuestion, data);
          }
        }
        else {
          this.prePopulateValue(childQuestion, data);
        }

        if (childQuestion?.childQIntentCode) {
          const isSelectedAnswer = childQuestion?.answerSets.filter(ans => ans?.isAnswered && ans?.default !== true);
          childQuestion?.answerSets.forEach(q => {
            if (childQuestion?.odefault === true && q.childQIntentCode === childQuestion?.childQIntentCode &&
              q?.default === true && isSelectedAnswer?.length === 0) {
              q.isAnswered = true;
            } else {
              if (childQuestion?.persistChildValue && q?.childQIntentCode && q.childQuestionSets?.length > 0) {
                q.childQuestionSets.forEach(childQue => {
                  this.prePopulateValueForDefaultQuestion(childQue, data);
                });
              }
            }
          });
        }
        // PrePopulate Answer for Child Question of AnsweredQuestion.
        this.prePopulateAnsweredQuestion(childQuestion, data);
      }
    }
  }

  prePopulateAnsweredQuestion(childQuestion, data) {
    // PrePopulate Answer for Child Question of AnsweredQuestion.
    const answeredQuestion = childQuestion?.answerSets.find(q => q.isAnswered);
    if (answeredQuestion && answeredQuestion.childQuestionSets?.length > 0) {
      answeredQuestion.childQuestionSets.forEach((childQuestion) =>
        this.prePopulateAnswer(childQuestion, data)
      )
    }
  }

  prePopulateValueForDefaultQuestion(question, data: any) {
    let suppliedValue = Object.entries(data)
      .filter(([key, value]) => key.toLowerCase().replace('[', '~').replace('].', '~') === question.qIntentCode.toLowerCase()
      )
      .map(([key, value]) => value as string)[0];

    // Excluded question type SUBTOTAL.
    if (suppliedValue && question.qQuestionType.toUpperCase() !== BaseConstantService.QuestionType.Subtotal) {
      if ((question.qQuestionFormat.toLowerCase() === "numeric" && question.localization)
        || question.qQuestionFormat.toLowerCase() === "money") {
        suppliedValue = this.currencyPipe.transform(suppliedValue);
      }
      question.answerSets[0].name = suppliedValue;
      question.answerSets[0].isAnswered = true;
    }
  }


  // Map Quote question and answer
  mapQuoteQuestionAnswer(questionSetResult, quoteResponses, isFromYourPolicies: boolean = false) {

    this.isFromYourPolicies = isFromYourPolicies;
    if (quoteResponses !== null && quoteResponses !== undefined) {
      questionSetResult?.forEach((question) => {
        Object.entries(quoteResponses)
          .forEach(([key, value]) => {
            if (key.toLowerCase() === question.qIntentCode.toLowerCase()) {
              if (question.qQuestionFormat.toLowerCase() === 'datepicker' && moment(value, "YYYY-MM-DD", true).isValid()) {

                quoteResponses[key] = this.pagingService.convertDateIntoUTC(value);
              }
              if ((question.qQuestionFormat.toLowerCase() === 'numeric' && question.localization)
                || question.qQuestionFormat.toLowerCase() === 'money') {
                quoteResponses[key] = this.currencyPipe.transform(value);
              }
            }
          });
        this.prePopulateAnswer(question, quoteResponses);
      });
    }
  }

  public internalGetQuestionSet(): Question[] {
    if (!this.internalQuestionSet) {
      const questionSet = JSON.parse(this.sessionStorage.get('AnsweredQuestionSet'));
      const position = this.sessionStorage.get('QuestionSetPosition');
      this.initialize(questionSet, position);
    }
    else if (this.internalQuestionSet) {
      for (const index in this.internalQuestionSet) {
        if (this.internalQuestionSet[index]?.qQuestionFormat.toLowerCase() === 'datepicker') {
          const dateValue = this.internalQuestionSet[index].answerSets[0].name;
          if (dateValue && dateValue !== undefined && dateValue !== '' && dateValue !== null && dateValue.toString().length > 10) {
            const objDate = this.pagingService.convertDateIntoUTC(dateValue);
            if (objDate && objDate !== undefined && objDate !== 'Invalid date') {
              this.internalQuestionSet[index].answerSets[0].name = objDate
            }
          }
        }
        else if (this.internalQuestionSet[index].childQuestionSets !== undefined &&
          this.internalQuestionSet[index].childQuestionSets.length > 0) {
          this.internalQuestionSet[index].childQuestionSets?.forEach((child) => {
            if (child.qQuestionFormat === "DatePicker") {
              const childDateValue = child.answerSets[0].name;
              if (childDateValue && childDateValue !== undefined && childDateValue !== '' && childDateValue !== null
                && childDateValue.toString().length > 10) {
                const objChildDate = this.pagingService.convertDateIntoUTC(childDateValue);
                if (objChildDate && objChildDate !== undefined && objChildDate !== 'Invalid date') {
                  child.answerSets[0].name = objChildDate
                }
              }
            }
          });
        }
      }
    }

    return this.internalQuestionSet;
  }

  // Recursively clear out answers.
  private clearAnswers(question: Question) {
    question.answerSets?.forEach(as => as.isAnswered = false);
    question.childQuestionSets?.forEach(cqs => this.clearAnswers(cqs));
  }

  private getQuestionAnswer(question: Question) {
    return question.answerSets.filter(ans => ans.isAnswered)[0].name;
  }

  MapAnswerSet(dataValue: any) {
    return this.answerSet =
    {
      name: dataValue?.id,
      active: dataValue?.active,
      textValue: dataValue?.text,
      disabled: !dataValue?.allowEdit,
      default: dataValue?.selected,
      childQuestionSets: dataValue?.questions
    };
  }

  MapAnswerSetMetadata(dataValue: any) {
    return this.answerSet =
    {
      name: dataValue?.text,
      active: dataValue?.active,
      textValue: dataValue?.id,
      disabled: !dataValue?.allowEdit,
      default: dataValue?.selected,
      childQuestionSets: dataValue?.questions
    };
  }

  MapLookupChildAnswerSet(childOptions: any) {
    return this.answerSet =
    {
      name: childOptions.name,
      textValue: childOptions?.name,
      id: childOptions?.id
    };
  }

  MapLookupChildAnswerSetMetadata(childOptions: any) {
    return this.answerSet =
    {
      name: childOptions?.name,
      textValue: childOptions?.value,
      id: childOptions?.id
    };
  }

  getOnDemandDataRequest(requestBody: OndemandDataRequest): Promise<any> {
    const apiURL = this.getOnDemandDataRequestUrl;
    return lastValueFrom<any>(this.commonHttpService.httpPostService(apiURL, requestBody));
  }

  private prePopulateValue(question: Question, data: any) {
    let suppliedValue = Object.entries(data)
      .filter(([key, value]) => key.toLowerCase().replace('[', '~').replace('].', '~') === question.qIntentCode.toLowerCase()
      )
      .map(([key, value]) => value as string)[0];

    // for policy repeating qs length will be empty hence checking and assigning empty literal to avoid null error
    if (suppliedValue === '' && question.qIntentCode.includes('.Length')) {
      question.answerSets[0].name = suppliedValue;
      question.answerSets[0].isAnswered = true;
    }

    if (this.isFromYourPolicies) {
      if ((suppliedValue === '' || suppliedValue === undefined) &&
        !question.qIntentCode.includes('.Length') &&
        question.qQuestionFormat.toLowerCase() !== 'grouppanel' &&
        question.qQuestionType.toLowerCase() === 'quote') {
        const unansweredIntentCode = this.sessionStorage.get('UnansweredIntentCode');
        if (unansweredIntentCode === null) {
          if (question.parentQIntentCode !== '' && question.parentQIntentCode !== undefined) {
            const answeredValue = Object.entries(data)
              .filter(([key, value]) => key.toLowerCase().replace('[', '~').replace('].', '~') === question.parentQIntentCode.toLowerCase())
              .map(([key, value]) => value as string)[0];
            if ((answeredValue !== '' || answeredValue !== undefined) &&
              (question.parentOValue !== '' || question.parentOValue !== undefined)) {
              if (answeredValue && (question.parentOValue.toLowerCase() === answeredValue.toLowerCase())) {
                this.sessionStorage.set('UnansweredIntentCode', this.qIntentCode);
              }
            }
          }
          else {
            if (question.qMandatory) {
              this.sessionStorage.set('UnansweredIntentCode', this.qIntentCode);
            }
          }
        }
      }
    }

    if (suppliedValue) {
      // Set the item value.
      if (question.answerSets.length > 1) {
        const targetAnswer = question.answerSets.find(ans => ans.name.toLowerCase() === suppliedValue.toLowerCase());
        if (targetAnswer) {
          targetAnswer.isAnswered = true;
        }
      } else {
        // Excluded question type SUBTOTAL.
        if (question.qQuestionType.toUpperCase() !== BaseConstantService.QuestionType.Subtotal) {
          if ((question.qQuestionFormat.toLowerCase() === "numeric" && question.localization)
            || question.qQuestionFormat.toLowerCase() === "money") {
            suppliedValue = this.currencyPipe.transform(suppliedValue);
          }
          question.answerSets[0].name = suppliedValue;
          question.answerSets[0].isAnswered = true;
        }
      }
    }
  }

  // ** Repeating Group functionality start ** //
  showHideIteration(formFieldIteration, qnData) {
    const qSubDescription = qnData.qSubDescription + ' (' + formFieldIteration + ')';
    this.qSubDescriptionObservable.next(qSubDescription);
  }

  // To Get repeating group name
  getRepeatingGroupName(repeatingGroupName) {
    const resultArray = repeatingGroupName.split("~");
    return resultArray[0];
  }

  // To Get child control name
  getRepeatingGroupChildQintentCode(childQIntentCode) {
    let resultArray = [];
    if (childQIntentCode?.includes("~")) {
      resultArray = childQIntentCode.split("~");
    } else if (childQIntentCode?.includes("[") && childQIntentCode?.includes(".")) {
      resultArray = childQIntentCode.split(".");
    }
    return resultArray[resultArray?.length - 1];
  }

  getRepeatingGroupqSubDescription(qSubDescription) {
    const resultArray = qSubDescription.split("(");
    return resultArray[0];
  }

  updateRepeatingGroupCount(repeatingGroupName: string, increaseDecreaseCount?: number, clonedGroupIntentCode?: string) {
    // Note: value can be either 1 or -1
    const result = this.internalQuestionSet.filter(que => que.qIntentCode === repeatingGroupName + '.Length' &&
      que.qQuestionFormat === BaseConstantService.Key_RepeatingGroupLength);
    if (result.length > 0) {
      result[0].answerSets[0].name = increaseDecreaseCount;
      result[0].answerSets[0].isAnswered = true;
      if (clonedGroupIntentCode) {
        result[0].answerSets[0].childQIntentCode = clonedGroupIntentCode;
      }

    }
  }

  getCurrentRepeatingGroupIndex(qIntentCode) {
    const currentlRepeatingGroupCount = this.sessionStorage.get('currentlRepeatingGroupCount');
    const result = qIntentCode.split("~");
    let iterationIndex = 0;
    if (result.length > 1) {
      iterationIndex = parseInt(result[1]);
    }
    return {
      repeatingGroupName: result[0], currentlRepeatingGroupCount: currentlRepeatingGroupCount,
      currentIterationIndex: iterationIndex
    }
  }

  increaseRepeatingGroupCount(clonedArray, currentlRepeatingGroupCount, repeatingGroupName) {
    this.sessionStorage.set('currentlRepeatingGroupCount', currentlRepeatingGroupCount);
    const filteredData = clonedArray.filter(que => que.qIntentCode === repeatingGroupName + '.Length' &&
      que.qQuestionFormat === BaseConstantService.Key_RepeatingGroupLength);
    currentlRepeatingGroupCount = this.sessionStorage.get('currentlRepeatingGroupCount');
    if (filteredData.length > 0) {
      filteredData[0].answerSets[0].name = currentlRepeatingGroupCount;
      filteredData[0].answerSets[0].value = currentlRepeatingGroupCount + 1;
      //  added this condition as 'currentlRepeatingGroupCount' is null when its value is 0
      if (currentlRepeatingGroupCount == null) {
        filteredData[0].answerSets[0].name = '';
      }
      return clonedArray;
    }
  }

  getCurrentRepeatingGroupCount(repeatingGroupName) {
    const result = this.internalQuestionSet?.filter(que => que.qIntentCode === repeatingGroupName + '.Length' &&
      que.qQuestionFormat === BaseConstantService.Key_RepeatingGroupLength);
    if (result.length > 0) {
      if (result[0].answerSets[0].name === "") {
        return 0;
      } else {
        return result[0].answerSets[0].name;
      }
    }
  }

  // From here Setting data in session
  setRepeatingGroupData(currentQuestion) {
    const currentRepeatingGroupName = this.getRepeatingGroupName(currentQuestion.qIntentCode);
    const currentlRepeatingGroupCount = parseInt(this.getCurrentRepeatingGroupCount(currentRepeatingGroupName), 10);
    this.sessionStorage.set('currentlRepeatingGroupCount', currentlRepeatingGroupCount);
    this.formFieldIteration = this.sessionStorage.get('currentlRepeatingGroupCount');
    this.sessionStorage.set('maxRepeat', currentQuestion?.maxRepeats);
  }

  savedRepeatingGroup(clonedArray, repeatingGroupName, incearseDecrease: string) {
    const filteredData = clonedArray.filter(que => que.qIntentCode === repeatingGroupName + '.Length' &&
      que.qQuestionFormat === BaseConstantService.Key_RepeatingGroupLength);
    if (filteredData.length > 0) {
      if (incearseDecrease === 'increase') {
        filteredData[0].answerSets[0].value = filteredData[0].answerSets[0].value + 1;
      } else {
        filteredData[0].answerSets[0].value = filteredData[0].answerSets[0].value - 1;
      }
      this.sessionStorage.set('AnsweredRepeatingIteration', filteredData[0].answerSets[0].value);
      return clonedArray;
    }
  }

  getSavedRepeatingGroup(repeatingGroupName) {
    const result = this.internalQuestionSet?.filter(que => que.qIntentCode === repeatingGroupName + '.Length' &&
      que.qQuestionFormat === BaseConstantService.Key_RepeatingGroupLength);
    if (result.length > 0) {
      return result[0].answerSets[0].value;
    }
  }

  hasRepeatingGroup(quoteResponseData: any): boolean {
    let hasRepeatingGroup: boolean = false;
    for (const res in quoteResponseData) {
      if (res.includes('.Length')) {
        hasRepeatingGroup = true;
        break;
      }
    }

    return hasRepeatingGroup;
  }

  cloneRepeatingGroupQuestions(questionSet, quoteResponse) {
    // *** Clone Repeating Group questions when resume from BackOffice flow ***//
    if (quoteResponse) {
      const repeatingGroupCount = [];
      for (const prop in quoteResponse) {
        if (quoteResponse.hasOwnProperty(prop)) {
          const innerObj = {};
          innerObj[prop] = quoteResponse[prop];
          if (prop.includes('.Length')) {
            repeatingGroupCount.push(innerObj);
          }
        }
      }

      repeatingGroupCount.forEach((element) => {
        const repeatingGroupLengthqIntentCode = Object.keys(element)[0];
        let cloneIterationCount: any = Object.values(element)[0];
        cloneIterationCount = parseInt(cloneIterationCount);

        const result = questionSet?.filter(que => que.qIntentCode === repeatingGroupLengthqIntentCode);
        if (result.length > 0) {
          result[0].answerSets[0].name = cloneIterationCount;
          result[0].answerSets[0].value = cloneIterationCount + 1;
        }
      });

      const clonedArray = this.createClone(repeatingGroupCount, questionSet);
      return clonedArray;
    }
  }

  createClone(repeatingGroupCount, questionSet) {
    const clonedArray = JSON.parse(JSON.stringify(questionSet));
    repeatingGroupCount.forEach((element) => {
      const qIntentCodeToClone = Object.keys(element)[0].split('.')[0];
      let cloneIterationCount: any = Object.values(element)[0];

      cloneIterationCount = parseInt(cloneIterationCount);
      const index = clonedArray.findIndex(i => i.qIntentCode === qIntentCodeToClone);

      // If the preceding question with multiple answers (e.g. yes/no) to a RepeatingGroup has a reference to the RepeatingGroup
      // then update the childQIntentCode to the first item in the RepeatingGroup. This is so navigation from the question
      // to the repeating group works.
      if (cloneIterationCount > 0) {
        const parentQuestionSets = clonedArray.filter(qs =>
          !qs?.isHidden && qs.answerSets && qs.answerSets.length > 1
          && qs.answerSets.filter(a => a.childQIntentCode === qIntentCodeToClone)?.length > 0);

        if (parentQuestionSets && parentQuestionSets.length > 0) {
          const answerSets = parentQuestionSets[0].answerSets.filter(a => a.childQIntentCode === qIntentCodeToClone);
          if (answerSets && answerSets.length > 0) {
            answerSets[0].childQIntentCode = `${qIntentCodeToClone}~0`;
          }
        }
      }

      if (index > 0) {
        const childQIntentCodeLink = clonedArray[index].childQIntentCode;
        const clonedGroup = JSON.parse(JSON.stringify(clonedArray[index]));

        for (let i = 0; i < cloneIterationCount; i++) {
          const parentToBeCloned = JSON.parse(JSON.stringify(clonedGroup));

          parentToBeCloned.qIntentCode = qIntentCodeToClone + '~' + i;

          // added this code as in b2b flow the intentcode of first repeating question needs to be map with the
          // previous question
          if (i === 0) {
            const lastNonRepeatingGroupQuesIndex = clonedArray.findIndex(q => q.childQIntentCode === qIntentCodeToClone);
            if (lastNonRepeatingGroupQuesIndex > -1) {
              clonedArray[lastNonRepeatingGroupQuesIndex].childQIntentCode = parentToBeCloned.qIntentCode;
              clonedArray[lastNonRepeatingGroupQuesIndex].answerSets.forEach(answer => {
                if (answer.childQIntentCode === qIntentCodeToClone) {
                  answer.childQIntentCode = parentToBeCloned.qIntentCode;
                }
              });
            }

          }
          parentToBeCloned.childQuestionSets.forEach((child) => {
            child.qIntentCode = qIntentCodeToClone + '~' + i + '~' + child.qIntentCode;
            child.answerSets.forEach(answer => {
              answer.isAnswered = false;
            });
          });

          if (parentToBeCloned?.qSubDescription) {
            parentToBeCloned.qSubDescription = parentToBeCloned?.qSubDescription + ' (' + (i + 1) + ')';
          }
          if (i === cloneIterationCount - 1) {
            if (childQIntentCodeLink !== "") {
              const nextQuestion = clonedArray?.filter(x => x.qIntentCode === childQIntentCodeLink);
              if (nextQuestion && nextQuestion.length > 0 && nextQuestion[0].qQuestionFormat.toLowerCase() === 'repeatinggroup') {
                parentToBeCloned.childQIntentCode = childQIntentCodeLink + '~' + 0;
                parentToBeCloned.answerSets[0].childQIntentCode = childQIntentCodeLink + '~' + 0;
              } else {
                parentToBeCloned.childQIntentCode = childQIntentCodeLink;
              }
            } else {
              parentToBeCloned.childQIntentCode = childQIntentCodeLink;
            }

          } else {
            parentToBeCloned.childQIntentCode = qIntentCodeToClone + '~' + (i + 1);
            parentToBeCloned.answerSets[0].childQIntentCode = qIntentCodeToClone + '~' + (i + 1)

          }

          clonedArray.splice(index + i, 0, parentToBeCloned);
        }
        if (!Number.isNaN(cloneIterationCount)) {
          clonedArray.splice(index + cloneIterationCount, 1);
        }
      }
    });
    return clonedArray;
  }

  formatIntentCode(quoteResponse) {
    /* Format intentcode for repeating group from array([]) structure to tild symbol(~)
    when resume from backoffice */
    const quoteResponseList: any = {};
    if (quoteResponse !== null && typeof quoteResponse === 'object') {
      Object.entries(quoteResponse).forEach(([key, value]) => {

        key = key.replace('[', '~').replace('].', '~');
        quoteResponseList[key] = value;

      })
    }
    return quoteResponseList;
  }
  // ** Repeating Group functionality End ** //


  set IsNewRepeatingGroup(value: any) {
    this.navigateToPreviousQues.next(value);
  }

  get IsNewRepeatingGroup() {
    return this._navigateToPreviousQuesValue;
  }

  setLastPolicyQuestion() {
    const questionSet = this.internalQuestionSet.filter(q =>
      q.qQuestionType === 'Policy');

    if (questionSet.length > 0) {
      this._lastPolicyQuestion = questionSet[questionSet.length - 1].qIntentCode;
    } else {
      this._lastPolicyQuestion = '';
    }
  }

  clearLastPolicyQuestion() {
    this._lastPolicyQuestion = '';
  }

  UpdatePreviousAnswerLastQuestion(value: any) {
    this.sessionStorage.set('PreviousAnswerLastQuestion', value);
    this.previousAnswerLastQuestion.next(value);
  }

  get GetPreviousAnswerLastQuestion(): Observable<any> {
    return this.previousAnswerLastQuestion.asObservable();
  }

  UpdateGrossPremiumPreviousAnswer(value: any) {
    this.sessionStorage.set('GrossPremiumPreviousAnswer', value);
    this.grossPremiumPreviousAnswer.next(value);
  }

  get LastPolicyQuestion(): string {
    return this._lastPolicyQuestion;
  }

  set LastPolicyQuestion(value: string) {
    this._lastPolicyQuestion = value;
  }

  get GetGrossPremiumPreviousAnswer(): Observable<any> {
    return this.grossPremiumPreviousAnswer.asObservable();
  }
  UpdatePrimaryCoverageTerm(value: any) {
    this.sessionStorage.set(BaseConstantService.KEY_PrimaryGrossPremium, value);
    this.getPrimaryCoveragTerm.next(value);
  }

  GetPrimaryCoverageTerm(): Observable<any> {
    return this.getPrimaryCoveragTerm.asObservable();
  }

  markQuoteChangesAsUnsaved(unsavedChangesExist: boolean): void {
    if (this.sharedSettingsService.isB2B()) {
      this._unsavedQuoteChangesNotifier$.next(unsavedChangesExist);
    }
  }

  getControlType(inputString: string): string {
    return BaseConstantService.mappedControlType[inputString] ?? "TEXT";
  }

  get quoteAccepted(): Observable<boolean> {
    return this._quoteAccepted.asObservable();
  }

  setQuoteAccepted(value: boolean) {
    this._quoteAccepted.next(value);
  }

  setSingleCarrierSingleQuoteResumeFlow() {
    const isB2b = this.sharedSettingsService.isB2B();
    const isExistingQuote = this.sharedSettingsService.existingCustomerQuote;
    const isB2cMultiCarrierQuotingEnabled = this.sessionStorage.get('isB2cMultiCarrierQuotingEnabled') === 'true';
    const isB2cSingleCarrierMultiQuoteEnabled = sessionStorage.getItem('isB2cSingleCarrierMultiQuoteEnabled') === 'true';

    this.isSingleCarrierSingleQuoteResumeFlow
      .next(isExistingQuote && isB2b && !isB2cSingleCarrierMultiQuoteEnabled && !isB2cMultiCarrierQuotingEnabled);
  }

  get profileTypeSet(): Observable<boolean> {
    return this._profileTypeSetNotifier$.asObservable()
  }

  get referralPermissionSet(): Observable<boolean> {
    return this._referralPermissionSetNotifier$.asObservable()
  }

  notifyProfileTypeIsSet(value: boolean) {
    this._profileTypeSetNotifier$.next(value);
  }

  notifyReferralPermissionIsSet(value: boolean) {
    this._referralPermissionSetNotifier$.next(value);
  }

  public getPolicyResponseData(policyId: number): Observable<any> {
    const apiUrl = `${this.getPolicyResponseDataUrl}/${policyId}`;
    return this.commonHttpService.httpGetServiceWithBearer(apiUrl);
  }

  setPolicyQuestion(policyQuestions: Question[]): void {
    this._policyQuestions = policyQuestions;
  }

  setPolicyQuestionResponse(policyId: number): void {
    this.getPolicyResponseData(policyId).subscribe(policyDetails => {
      this._policyQuestionResponses = policyDetails.responses;
    });
  }

  set policyQuestionHidden(value: boolean) {
    this._policyQuestionHidden = value;
  }

  get policyQuestionHidden(): boolean {
    return this._policyQuestionHidden;
  }

  includePolicyResponse(answeredResponseDictionary: any): any {
    const policyResponses = Object.entries(this._policyQuestionResponses || {}).map(([key, value]) => ({ key, value }));

    const isPolicyResponseCommon = policyResponses
      .some(policyResponse => answeredResponseDictionary
        .find(question => question.key === policyResponse.key));

    const answeredQuestionResponse = this.policyQuestionHidden && policyResponses.length > 0 && isPolicyResponseCommon
      ? policyResponses.map(policyResponse => {
        const matchingQuestion = answeredResponseDictionary.find(question => question.key === policyResponse.key);
        return matchingQuestion ? { ...matchingQuestion, ...policyResponse } : policyResponse;
      })
      : answeredResponseDictionary;

    return answeredQuestionResponse;
  }

  setReferralPermission(permissions: {
    isEditOnly: boolean,
    isApproveDeclineOnly: boolean,
    isEditWithoutPremiumOverride: boolean,
    isApproveDeclineWithPremiumOverride: boolean
  }): void {
    this.referralPermissions = permissions;
  }

  getReferralPermission(): any {
    return this.referralPermissions;
  }

  // To Set repeating group template just after the mastergrouppannel
  // where repeating group is configured at child level
  reArrangeRepeatingGroupPosition(masterGroupPanelIntentcode, repeatingGroupToReposition) {
    const targetRepeatingGroup = this.internalQuestionSet.find(i => i.qIntentCode === repeatingGroupToReposition);
    const newPosition = this.internalQuestionSet.findIndex(i => i.qIntentCode === masterGroupPanelIntentcode);
    if (this.internalQuestionSet[newPosition + 2].qIntentCode !== repeatingGroupToReposition &&
      !this.internalQuestionSet[newPosition + 2].qIntentCode.includes(repeatingGroupToReposition)) {
      const newArray = this.internalQuestionSet.filter(i => i.qIntentCode !== repeatingGroupToReposition &&
        i.qIntentCode !== repeatingGroupToReposition + '.Length');
      const targetRepeatingGroupLength = this.internalQuestionSet.find(i => i.qIntentCode === repeatingGroupToReposition + '.Length');

      newArray.splice(newPosition + 1, 0, targetRepeatingGroupLength);
      newArray.splice(newPosition + 2, 0, targetRepeatingGroup);
      this.internalQuestionSet = newArray;
    }
  }

  // To set childQuestionSet as actual
  clearChildQuestionSet(childQuestionSet) {
    childQuestionSet.forEach((child) => {
      const childQIntentCode = this.getRepeatingGroupChildQintentCode(child.qIntentCode);
      if (child?.qIntentCode) {
        child.qIntentCode = childQIntentCode;
        if (child?.answerSets?.length > 0) {
          child?.answerSets.forEach((ans)=>{
            ans.isAnswered = false;
          });
          
        }
        if (child?.childQuestionSets?.length > 0) {
          this.clearChildQuestionSet(child.childQuestionSets);
        }
      }
    });
  }


  // #region 'When the Group Panel's questions has RepeatingGroup as a child Question'

  // If repeating group is configured with a dropdown(dropdown can be at child or
  // grandchild or and so on) ex. If with 'yes' option repeationg group is configured then
  // this function is used to set repeating group(or any other group pannel ) as a next question.

  setResetChildQintentCode(data, event) {
    if (data.masterGroupPanelIntentCode !== undefined && data.masterGroupPanelIntentCode !== '') {
      const masterGroupPanelQuestion = this.internalQuestionSet
        .find(q => q.qIntentCode === data.masterGroupPanelIntentCode);
      const masterGroupPanelQuestionIndex = this.internalQuestionSet
        .findIndex(q => q.qIntentCode === data.masterGroupPanelIntentCode);
      const selectedOption = data?.options?.filter(x => x.name === event.value);
      if (masterGroupPanelQuestion && selectedOption && selectedOption.length > 0) {
        if (data?.isRepeatingGroupToBeNext === true && selectedOption[0]?.childQIntentCode === "") {
          this.resetSelectedRepeatingGroup(masterGroupPanelQuestion, masterGroupPanelQuestion, false, masterGroupPanelQuestionIndex);
          this.internalQuestionSet[masterGroupPanelQuestionIndex].childQIntentCode =
            masterGroupPanelQuestion?.masterGroupPanelChildQIntentCode;
        }
        const checkRepeatingGroup = this.internalQuestionSet.
          find(x => x.qIntentCode === selectedOption[0].childQIntentCode + '~' + '0')
        if (checkRepeatingGroup != null) {
          selectedOption[0].childQIntentCode = selectedOption[0].childQIntentCode + '~' + '0';
        }

        const filteredResult = this.internalQuestionSet
          .find(q => q.qIntentCode === selectedOption[0].childQIntentCode);
        if (filteredResult && filteredResult !== null) {
          if (selectedOption[0]?.childQIntentCode !== undefined && selectedOption[0]?.childQIntentCode !== "") {
            masterGroupPanelQuestion.childQIntentCode = selectedOption[0].childQIntentCode;
            this.resetSelectedRepeatingGroup(masterGroupPanelQuestion, selectedOption[0], true, masterGroupPanelQuestionIndex);
          }
        }
      }
    }
  }

  // Delete repeating group if repeating group is set as a next question
  resetSelectedRepeatingGroup(masterGroupPanelQuestion, selectedOption, isNextQueRepeatingGroup: boolean, masterGroupPanelQuestionIndex) {
    const nextQueRepeatingGroup = this.internalQuestionSet
      .find(q => q.qIntentCode === selectedOption.childQIntentCode);
    if (nextQueRepeatingGroup?.qQuestionFormat === BaseConstantService.Key_RepeatingGroupQuestionFormat ||
      nextQueRepeatingGroup?.qIntentCode?.includes('~')) {
      const repeatingGroupName = this.getRepeatingGroupName(nextQueRepeatingGroup.qIntentCode);
      // Actual repeating group question should not be deleted
      let repeatingGroupsListToDelete = this.internalQuestionSet.filter(que =>
        que.qIntentCode !== repeatingGroupName && que.qIntentCode?.includes(repeatingGroupName) &&
        !que.qIntentCode?.includes(repeatingGroupName + '~' + '0') &&
        !que.qIntentCode.includes('.Length'));

      if (repeatingGroupsListToDelete?.length > 0) {
        repeatingGroupsListToDelete = repeatingGroupsListToDelete.reverse();
        repeatingGroupsListToDelete.forEach((que) => {
          this.onDeleteRepeatingGroup(this.internalQuestionSet, que, false);
        });
      }

      this.internalQuestionSet.forEach(que => {
        if (que.qIntentCode?.includes(repeatingGroupName)) {
          if (que.qIntentCode !== repeatingGroupName) { // Actual repeating group question should not be deleted
            if (que.qIntentCode?.includes(repeatingGroupName + '~' + '0')) {
              que.qIntentCode = repeatingGroupName;
              que.childQIntentCode = que?.masterGroupPanelChildQIntentCode;
              if (que?.qSubDescription) {
                que.qSubDescription = this.getRepeatingGroupqSubDescription(que?.qSubDescription);
              }
              if (que?.childQuestionSets.length > 0) {
                this.clearChildQuestionSet(que.childQuestionSets);
              }
            } else if (que.qIntentCode.includes('.Length') && que?.answerSets.length > 0) {
              que.answerSets[0].name = 0;
              que.answerSets[0].value = 1;
              que.answerSets[0].isAnswered = false;
              this.sessionStorage.set('AnsweredRepeatingIteration', 0);
              this.initialize(this.internalQuestionSet, masterGroupPanelQuestionIndex);
            }
          }
        }
      });
      
      if (isNextQueRepeatingGroup === true) {
        masterGroupPanelQuestion['isRepeatingGroupToBeNext'] = true;
        masterGroupPanelQuestion.childQIntentCode = repeatingGroupName;
        this.reArrangeRepeatingGroupPosition(masterGroupPanelQuestion?.qIntentCode, repeatingGroupName);
      }
    }
  }

  // #endregion

  get EnableSlowReveal(): boolean {
    return this._enableSlowReveal;
  }

  set EnableSlowReveal(value: boolean) {
    this._enableSlowReveal = value;
  }
}



