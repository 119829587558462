import {Injectable} from '@angular/core';

export const CustomBreakpointNames = {
  mobile: 'mobile', /* Small -  */
  tablet: 'tablet', /* Medium -*/
  desktop: 'desktop' /* Large */
};

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {
  breakpoints: object = {
    '(max-width: 759px)': CustomBreakpointNames.mobile,
    '(min-width: 760px) and (max-width: 1280px)': CustomBreakpointNames.tablet,
    '(min-width: 1281px)': CustomBreakpointNames.desktop
  };

  getBreakpoints(): string[] {
    return Object.keys(this.breakpoints);
  }

  getBreakpointName(breakpointValue): string {
    return this.breakpoints[breakpointValue];
  }
}
