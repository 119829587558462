import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../services/layout/layout.service';
import { DropDownItem } from '../../../shared/models/drop-down-item.model';
import { ICustomerFieldValidations, YourProfileData } from '../../../shared/models';
import XRegExp from "XRegExp";
import {
  AddressDetails, ConsentPreferences, ContactPreferences,
  ContactType,
  LanguagePreference, ManageAddresses,
  PersonalDetails,
  PolicyConsent
} from '../../../shared/models/your-profile.model';
import { BaseYourProfileComponent } from '../base-your-profile/base-your-profile.component';
import { CountryCodeLookupData } from '../../../shared/models/user-profile.model';
import { BaseCustomerService } from '../../../services/customer/base-customer.service';
import { SessionStorageService } from 'angular-web-storage';

const pageLabels = {
  yourProfileTitleLabel: 'AccountDetails.lblYourProfileTitle',
  mandatoryErrorMessageLabel: 'Shared.lblErrorMandatory',
  personalDetailsLabel: 'AccountDetails.lblPersonalDetails',
  manageAddressesLabel: 'AccountDetails.lblManageAddresses',
  languagePreferenceLabel: 'AccountDetails.lblLanguagePreference',
  consentPreferencesLabel: 'AccountDetails.lblConsentPreferences',
  contactPreferencesLabel: 'AccountDetails.lblContactPreferences',
  tooltipText: 'AccountDetails.lblTooltipText',
  addNewAddressText: 'AccountDetails.lblAddNewAddress',
  editLabel: 'Shared.lblEdit',
  saveLabel: 'Shared.lblSave',
  cancelLabel: 'Shared.lblCancel',
  correctFormatErrorMessageLabel: 'Shared.lblCorrectFormatErrorMessage',
  seeLegalText: 'AccountDetails.lblSeeLegalText',
  hideLegalText: 'AccountDetails.lblHideLegalText',
  consentSubHeadingDescription: 'AccountDetails.lblConsentSubHeadingDescription',
  consentPolicyLabel: 'AccountDetails.lblConsentPolicyHeading',
  consentPolicyDescription: 'AccountDetails.lblConsentPolicyDescription',
  contactSubHeadingLabel: 'AccountDetails.lblContactSubHeadingDescription',
  contactNote: 'AccountDetails.lblContactNote',
  personalDetailsTitle: 'AccountDetails.lblPersonalDetailsTitle',
  personalDetailsSSN: 'AccountDetails.lblPersonalDetailsSSN',
  personalDetailsFirstName: 'AccountDetails.lblPersonalDetailsFirstName',
  personalDetailsLastName: 'AccountDetails.lblPersonalDetailsLastName',
  personalDetailsEmail: 'AccountDetails.lblPersonalDetailsEmail',
  personalDetailsDOB: 'AccountDetails.lblPersonalDetailsDOB',
  personalDetailsPlaceOfBirth: 'AccountDetails.lblPersonalDetailsPlaceOfBirth',
  personalDetailsPlaceOfBirthTown: 'AccountDetails.lblPersonalDetailsPlaceOfBirthTown',
  personalDetailsPhoneNumber: 'AccountDetails.lblPersonalDetailsPhoneNumber',
  countryCodeLabel: 'AccountDetails.lblCountryAreaCodeYourProfile',
  currentLanguage: 'AccountDetails.lblCurrentLanguage',
  contactPreferenceEmail: 'AccountDetails.lblContactPreferenceEmail',
  contactPreferenceAddress: 'AccountDetails.lblContactPreferenceAddress',
  contactPreferencePhone: 'AccountDetails.lblContactPreferencePhone',
  contactPreferenceYesOption: 'AccountDetails.lblContactPreferenceYesOption',
  contactPreferenceNoOption: 'AccountDetails.lblContactPreferenceNoOption',
  editHeaderLabel: 'AccountDetails.lblEditHeaderLabel',
  addHeaderLabel: 'AccountDetails.lblAddHeaderLabel',
  defaultAddressLabel: 'AccountDetails.lblDefaultAddressLabel',
  manageAddAddressLine: 'AccountDetails.lblManageAddAddressLine',
  manageAddTown: 'AccountDetails.lblManageAddTown',
  manageAddPostCode: 'AccountDetails.lblManageAddPostCode',
  manageAddCountry: 'AccountDetails.lblManageAddCountry',
  personalDetailsEmail2: 'AccountDetails.lblPersonalDetailsEmail2' 
};

@Component({
  selector: 'lib-your-profile',
  templateUrl: './your-profile.component.html',
  styleUrls: ['./your-profile.component.scss']
})

export class YourProfileComponent implements OnInit {

  isMobile = false;
  isTablet = false;
  isDesktop = false;
  public isLoading = false;
  public labels = {
    yourProfileTitleLabel: '',
    mandatoryErrorMessageLabel: '',
    correctFormatErrorMessageLabel: '',
    personalDetailsLabel: '',
    manageAddressesLabel: '',
    languagePreferenceLabel: '',
    consentPreferencesLabel: '',
    contactPreferencesLabel: '',
    addNewAddressText: '',
    tooltipText: '',
    editLabel: '',
    saveLabel: '',
    cancelLabel: '',
    seeLegalText: '',
    hideLegalText: '',
    consentSubHeadingDescription: '',
    consentPolicyLabel: '',
    consentPolicyDescription: '',
    contactSubHeadingLabel: '',
    contactNote: '',
    personalDetailsTitle: '',
    personalDetailsSSN: '',
    personalDetailsFirstName: '',
    personalDetailsLastName: '',
    personalDetailsEmail: '',
    personalDetailsDOB: '',
    personalDetailsPlaceOfBirth: '',
    personalDetailsPlaceOfBirthTown: '',
    personalDetailsPhoneNumber: '',
    CountryCode: '',
    manageAddAddressLine: '',
    manageAddTown: '',
    manageAddPostCode: '',
    manageAddCountry: '',
    currentLanguage: '',
    contactPreferenceEmail: '',
    contactPreferenceAddress: '',
    contactPreferencePhone: '',
    editHeaderLabel: '',
    addHeaderLabel: '',
    defaultAddressLabel: '',
    personalDetailsEmail2: ''
  };
  yourProfileData: YourProfileData = new YourProfileData();
  yourProfileResponse: any;
  profileEnabledDetailsResponse: any;
  consentAndContactEnabled = false;
  radioOption = [
    { id: 'true', value: '' },
    { id: 'false', value: '' }
  ];
  private countries: DropDownItem[];
  private countryCodeRequired: boolean;
  private countryCodeReadOnly: boolean;
  private selectedCountryCode: string;
  private countryCodeLookupData: CountryCodeLookupData[];
  public customerFields: ICustomerFieldValidations[];
  @Output() languageChangeWarningEvent = new EventEmitter<boolean>();
  @ViewChild(BaseYourProfileComponent) baseYourProfileComponent: BaseYourProfileComponent;

  constructor(
    private readonly translate: TranslateService,
    private readonly layoutService: LayoutService,
    private route: ActivatedRoute,
    private sessionStorageService: SessionStorageService,
    private baseCustomerService: BaseCustomerService,
  ) { }

  ngOnInit() {
    this.customerFields = this.route.snapshot.data.profileEnabledDetailsResponse;
    this.checkDeviceWidth();
    this.applyTranslation();
    this.yourProfileResponse = this.route.snapshot.data.yourProfileResponse;
    if (this.yourProfileResponse.yourProfileModel.consentPurposeList.length) {
      this.consentAndContactEnabled = true;
    }
    this.countries = this.route.snapshot.data.yourProfileResponse.customerDetailOptions.countryList;
    this.initializeYourProfileObject();
  }

  languageChangeWarning(event) {
    this.languageChangeWarningEvent.emit(event);
  }

  applyTranslation() {
    const { yourProfileTitleLabel, mandatoryErrorMessageLabel, correctFormatErrorMessageLabel, personalDetailsLabel,
            manageAddressesLabel, languagePreferenceLabel, consentPreferencesLabel,
            contactPreferencesLabel, tooltipText, addNewAddressText, editLabel,
            saveLabel, cancelLabel, seeLegalText, hideLegalText,
            consentSubHeadingDescription, consentPolicyLabel, consentPolicyDescription,
            contactSubHeadingLabel, contactNote, personalDetailsTitle, personalDetailsSSN,
            personalDetailsFirstName, personalDetailsLastName, personalDetailsEmail,
            personalDetailsDOB, personalDetailsPlaceOfBirth, personalDetailsPlaceOfBirthTown,
            personalDetailsPhoneNumber, countryCodeLabel, currentLanguage,
            contactPreferenceEmail, contactPreferenceAddress,
            contactPreferencePhone, contactPreferenceYesOption, contactPreferenceNoOption,
            editHeaderLabel, addHeaderLabel, defaultAddressLabel, manageAddAddressLine, manageAddTown,
            manageAddPostCode, manageAddCountry, personalDetailsEmail2
    } = pageLabels;

    this.translate.get([yourProfileTitleLabel, mandatoryErrorMessageLabel,
      correctFormatErrorMessageLabel, personalDetailsLabel, manageAddressesLabel, languagePreferenceLabel,
      consentPreferencesLabel, contactPreferencesLabel, tooltipText,
      addNewAddressText, editLabel, saveLabel, cancelLabel, seeLegalText,
      hideLegalText, consentSubHeadingDescription, consentPolicyLabel,
      consentPolicyDescription, contactSubHeadingLabel, contactNote,
      personalDetailsTitle, personalDetailsSSN, personalDetailsFirstName,
      personalDetailsLastName, personalDetailsEmail,
      personalDetailsDOB, personalDetailsPlaceOfBirth, personalDetailsPlaceOfBirthTown,
      personalDetailsPhoneNumber, countryCodeLabel, currentLanguage,
      contactPreferenceEmail, contactPreferenceAddress, contactPreferencePhone,
      contactPreferenceYesOption, contactPreferenceNoOption,
      editHeaderLabel, addHeaderLabel, defaultAddressLabel,
      manageAddAddressLine, manageAddTown,
      manageAddPostCode, manageAddCountry, personalDetailsEmail2])
      .subscribe((res) => {
        this.labels.yourProfileTitleLabel = res[yourProfileTitleLabel];
        this.labels.mandatoryErrorMessageLabel = res[mandatoryErrorMessageLabel];
        this.labels.correctFormatErrorMessageLabel = res[correctFormatErrorMessageLabel];
        this.labels.personalDetailsLabel = res[personalDetailsLabel];
        this.labels.manageAddressesLabel = res[manageAddressesLabel];
        this.labels.languagePreferenceLabel = res[languagePreferenceLabel];
        this.labels.consentPreferencesLabel = res[consentPreferencesLabel];
        this.labels.contactPreferencesLabel = res[contactPreferencesLabel];
        this.labels.editLabel = res[editLabel];
        this.labels.saveLabel = res[saveLabel];
        this.labels.cancelLabel = res[cancelLabel];
        this.labels.tooltipText = res[tooltipText];
        this.labels.addNewAddressText = res[addNewAddressText];
        this.labels.seeLegalText = res[seeLegalText];
        this.labels.hideLegalText = res[hideLegalText];
        this.labels.consentSubHeadingDescription = res[consentSubHeadingDescription];
        this.labels.consentPolicyLabel = res[consentPolicyLabel];
        this.labels.consentPolicyDescription = res[consentPolicyDescription];
        this.labels.contactSubHeadingLabel = res[contactSubHeadingLabel];
        this.labels.contactNote = res[contactNote];
        this.labels.personalDetailsTitle = res[personalDetailsTitle];
        this.labels.personalDetailsSSN = res[personalDetailsSSN];
        this.labels.personalDetailsFirstName = res[personalDetailsFirstName];
        this.labels.personalDetailsLastName = res[personalDetailsLastName];
        this.labels.personalDetailsEmail = res[personalDetailsEmail];
        this.labels.personalDetailsDOB = res[personalDetailsDOB];
        this.labels.personalDetailsPlaceOfBirth = res[personalDetailsPlaceOfBirth];
        this.labels.personalDetailsPlaceOfBirthTown = res[personalDetailsPlaceOfBirthTown];
        this.labels.personalDetailsEmail2 = res[personalDetailsEmail2];

        this.labels.personalDetailsPhoneNumber = res[personalDetailsPhoneNumber];
        this.labels.CountryCode = res[countryCodeLabel];
        this.labels.currentLanguage = res[currentLanguage];
        this.labels.contactPreferenceEmail = res[contactPreferenceEmail];
        this.labels.contactPreferenceAddress = res[contactPreferenceAddress];
        this.labels.contactPreferencePhone = res[contactPreferencePhone];
        this.radioOption[0].value = res[contactPreferenceYesOption];
        this.radioOption[1].value = res[contactPreferenceNoOption];
        this.labels.editHeaderLabel = res[editHeaderLabel];
        this.labels.addHeaderLabel = res[addHeaderLabel];
        this.labels.defaultAddressLabel = res[defaultAddressLabel];
        this.labels.manageAddAddressLine = res[manageAddAddressLine];
        this.labels.manageAddTown = res[manageAddTown];
        this.labels.manageAddPostCode = res[manageAddPostCode];
        this.labels.manageAddCountry = res[manageAddCountry];
      });
  }

  private checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  private initializeYourProfileObject() {
    this.yourProfileData.personalDetails = this.initPersonalDetails(this.yourProfileData.personalDetails);
    this.yourProfileData.manageAddresses = this.initManageAddresses(this.yourProfileData.manageAddresses);
    this.yourProfileData.languagePreference = this.initLangPreferences(this.yourProfileData.languagePreference);
    this.yourProfileData.consentPreferences = this.initConsentPreference(this.yourProfileData.consentPreferences);
    this.yourProfileData.contactPreferences = this.initContactPreference(this.yourProfileData.contactPreferences);
  }

  private initPersonalDetails(personalDetails: PersonalDetails): PersonalDetails {
    // Page Info Mapping
    personalDetails.pageInfo.headingText = this.labels.personalDetailsLabel;
    personalDetails.pageInfo.icon = 'person';
    personalDetails.pageInfo.btnEditText = this.labels.editLabel;
    personalDetails.pageInfo.btnSaveText = this.labels.saveLabel;
    personalDetails.pageInfo.btnCancelText = this.labels.cancelLabel;
    personalDetails.isEditMode = false;

    // Title Mapping
    if (this.isShowhideField('CM_Title')) {
      personalDetails.title.key = 'title',
        personalDetails.title.label = this.labels.personalDetailsTitle;
      personalDetails.title.required = this.isMandatory('CM_Title');
      personalDetails.title.readOnly = this.isReadOnly('CM_Title');
      personalDetails.title.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.title.options =
        this.getOptionList(this.yourProfileResponse.customerDetailOptions.titleList,
          'value', 'id');
      personalDetails.title.order = 1;
      personalDetails.title.value = this.yourProfileResponse.yourProfileModel.userProfile.title;
    } else { delete personalDetails.title; }

    // SSN Mapping
    if (this.isShowhideField('CM_CustomerIdentifier')) {
      personalDetails.ssn.key = 'ssn';
      personalDetails.ssn.label = this.labels.personalDetailsSSN;
      personalDetails.ssn.required = this.isMandatory('CM_CustomerIdentifier');
      personalDetails.ssn.readOnly = this.isReadOnly('CM_CustomerIdentifier');
      personalDetails.ssn.regexValidation = this.validateNumeric('CM_CustomerIdentifier');
      personalDetails.ssn.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.ssn.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;
      personalDetails.ssn.maxLength = this.getMaxLength('CM_CustomerIdentifier');
      personalDetails.ssn.order = 2;
      personalDetails.ssn.value = this.yourProfileResponse.yourProfileModel.userProfile.customerIdentifier;
    } else { delete personalDetails.ssn; }

    // Firstname Mapping
    if (this.isShowhideField('CM_FirstName')) {
      personalDetails.firstName.key = 'firstName';
      personalDetails.firstName.label = this.labels.personalDetailsFirstName;
      personalDetails.firstName.required = this.isMandatory('CM_FirstName');
      personalDetails.firstName.readOnly = this.isReadOnly('CM_FirstName');
      personalDetails.firstName.regexValidation = this.validateAlphaWithPunctuations('CM_FirstName');
      personalDetails.firstName.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;
      personalDetails.firstName.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.firstName.order = 3;
      personalDetails.firstName.value = this.yourProfileResponse.yourProfileModel.userProfile.firstName;
      personalDetails.firstName.maxLength = this.getMaxLength('CM_FirstName');
    } else { delete personalDetails.firstName; }

    // Lastname Mapping
    if (this.isShowhideField('CM_LastName')) {
      personalDetails.lastName.key = 'lastName';
      personalDetails.lastName.label = this.labels.personalDetailsLastName;
      personalDetails.lastName.required = this.isMandatory('CM_LastName');
      personalDetails.lastName.readOnly = this.isReadOnly('CM_LastName');
      personalDetails.lastName.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.lastName.order = 4;
      personalDetails.lastName.value = this.yourProfileResponse.yourProfileModel.userProfile.lastName;
      personalDetails.lastName.maxLength = this.getMaxLength('CM_LastName');
      personalDetails.lastName.regexValidation = this.validateAlphaWithPunctuations('CM_LastName');
      personalDetails.lastName.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;
    } else { delete personalDetails.lastName; }

    // DOB Mapping
    if (this.isShowhideField('CM_DateofBirth')) {
      const dob: ICustomerFieldValidations = this.customerFields?.find(fields =>
        fields.fieldName.toUpperCase() === 'CM_DateofBirth'.toUpperCase());
      const maxDateValue = dob.minAge > 0 ? '-' + dob.minAge + ',years' : '-1,days';

      personalDetails.dob.key = 'dateOfBirth';
      personalDetails.dob.label = this.labels.personalDetailsDOB;
      personalDetails.dob.required = this.isMandatory('CM_DateofBirth');
      personalDetails.dob.readOnly = this.isReadOnly('CM_DateofBirth');
      personalDetails.dob.maxDate = maxDateValue;
      personalDetails.dob.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.dob.order = 5;
      personalDetails.dob.value = this.yourProfileResponse.yourProfileModel.userProfile.dateOfBirth;
      // personalDetails.dob.callToActionLabel = this.labels.editLabel;
    } else { delete personalDetails.dob; }

    if (this.isShowhideField('CM_PlaceOfBirth')) {
      personalDetails.placeOfBirth.key = 'placeOfBirth';
      personalDetails.placeOfBirth.label = this.labels.personalDetailsPlaceOfBirth;
      personalDetails.placeOfBirth.value = this.yourProfileResponse.yourProfileModel.userProfile.placeOfBirth;
      personalDetails.placeOfBirth.required = this.isMandatory('CM_PlaceOfBirth');
      personalDetails.placeOfBirth.readOnly = this.isReadOnly('CM_PlaceOfBirth');
      personalDetails.placeOfBirth.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.placeOfBirth.maxLength = this.getMaxLength('CM_PlaceOfBirth');
      personalDetails.placeOfBirth.order = 6;
    } else { delete personalDetails.placeOfBirth; }

    if (this.isShowhideField('CM_PlaceOfBirthTown')) {
      personalDetails.placeOfBirthTown.key = 'placeOfBirthTown';
      personalDetails.placeOfBirthTown.label = this.labels.personalDetailsPlaceOfBirthTown;
      personalDetails.placeOfBirthTown.value = this.yourProfileResponse.yourProfileModel.userProfile.placeOfBirthTown;
      personalDetails.placeOfBirthTown.required = this.isMandatory('CM_PlaceOfBirthTown');
      personalDetails.placeOfBirthTown.readOnly = this.isReadOnly('CM_PlaceOfBirthTown');
      personalDetails.placeOfBirthTown.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.placeOfBirthTown.maxLength = this.getMaxLength('CM_PlaceOfBirthTown');
      personalDetails.placeOfBirthTown.order = 7;
    } else { delete personalDetails.placeOfBirthTown; }

    // Email Mapping
    if (this.isShowhideField('CM_Email1')) {
      personalDetails.email.key = 'email';
      personalDetails.email.label = this.labels.personalDetailsEmail;
      personalDetails.email.value = this.yourProfileResponse.yourProfileModel.userProfile.email;
      personalDetails.email.readOnly = true; // always in readoOnly
      personalDetails.email.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.email.iconName = 'email test';
      personalDetails.email.order = 8;
      personalDetails.tooltipText = this.labels.tooltipText;
    } else { delete personalDetails.email; }
     // Email2 Mapping
    if (this.isNewShowhideField('CM_Email2')) {
      personalDetails.email2.key = 'email2';
      personalDetails.email2.label = this.labels.personalDetailsEmail2;
      personalDetails.email2.value = this.yourProfileResponse.yourProfileModel.userProfile.email2;
      personalDetails.email2.readOnly =  this.isReadOnly('CM_Email2');
      personalDetails.lastName.required = this.isMandatory('CM_Email2');
      personalDetails.email2.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.email2.iconName = 'email test';
      personalDetails.email2.order = 9;
      personalDetails.email2.type = 'email';
    } else { delete personalDetails.email2; }

    // CM_CountryCode or Phone Number Mapping
    if (this.isShowhideField('CM_CountryCode')) {
      const phoneField = this.customerFields?.find(fields => fields.fieldName.toUpperCase() === 'CM_PhoneNumber1'.toUpperCase());
      personalDetails.countryCode.key = 'phoneNumber';
      personalDetails.countryCode.label = this.labels.personalDetailsPhoneNumber;
      personalDetails.countryCode.value = this.yourProfileResponse.yourProfileModel.userProfile.phoneNumber;
      personalDetails.countryCode.required = this.isMandatory('CM_PhoneNumber1');
      personalDetails.countryCode.readOnly = this.isReadOnly('CM_PhoneNumber1');
      personalDetails.countryCode.maxLength = phoneField?.maxLength;
      personalDetails.countryCode.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      personalDetails.countryCode.order = 10;
      personalDetails.countryCode.regexValidation = this.validateNumeric('CM_PhoneNumber1');
      personalDetails.countryCode.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;
      personalDetails.countryCodeLookupData = this.route.snapshot.data.countryLookupData;
      if (personalDetails.countryCodeLookupData.length > 0) {
        personalDetails.countryCodeLookupData[0].value = personalDetails.countryCodeLookupData[0].value.replace(/\s/g, '');
        personalDetails.countryCodeLookupData[0].code = personalDetails.countryCodeLookupData[0].code.replace(/\s/g, '');
      }
      personalDetails.countryCodeLabel = this.labels.CountryCode;
      personalDetails.showCountryCodeCtrl = this.isShowhideField('CM_CountryCode');
      personalDetails.countryCodeRequired = this.isMandatory('CM_CountryCode');
      personalDetails.countryCodeReadOnly = this.isReadOnly('CM_CountryCode');
      const strCountry = this.yourProfileResponse.yourProfileModel.userProfile;
      personalDetails.selectedCountryCode = strCountry && strCountry.countryCode ? strCountry.countryCode : '';
      delete personalDetails.phoneNumber;
    } else
      if (this.isShowhideField('CM_PhoneNumber1')) {
        const phoneField = this.customerFields?.find(fields => fields.fieldName.toUpperCase() === 'CM_PhoneNumber1'.toUpperCase());
        personalDetails.phoneNumber.key = 'phoneNumber';
        personalDetails.phoneNumber.label = this.labels.personalDetailsPhoneNumber;
        personalDetails.phoneNumber.value = this.yourProfileResponse.yourProfileModel.userProfile.phoneNumber;
        personalDetails.phoneNumber.required = this.isMandatory('CM_PhoneNumber1');
        personalDetails.phoneNumber.readOnly = this.isReadOnly('CM_PhoneNumber1');
        personalDetails.phoneNumber.maxLength = phoneField?.maxLength;
        personalDetails.phoneNumber.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
        personalDetails.phoneNumber.order = 10;
        personalDetails.phoneNumber.regexValidation = this.validateNumeric('CM_PhoneNumber1');
        personalDetails.phoneNumber.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;
        delete personalDetails.countryCode;
      } else { delete personalDetails.phoneNumber; }
    return personalDetails;
  }

  private initManageAddresses(manageAddresses: ManageAddresses) {
    // Page Info Mapping
    manageAddresses.pageInfo.headingText = this.labels.manageAddressesLabel;
    manageAddresses.pageInfo.editHeaderText = this.labels.editHeaderLabel;
    manageAddresses.pageInfo.addHeaderText = this.labels.addHeaderLabel;
    manageAddresses.pageInfo.defaultAddressText = this.labels.defaultAddressLabel;

    manageAddresses.pageInfo.icon = 'place';
    manageAddresses.pageInfo.btnEditText = this.labels.addNewAddressText;
    manageAddresses.pageInfo.btnSaveText = this.labels.saveLabel;
    manageAddresses.pageInfo.btnCancelText = this.labels.cancelLabel;

    // Address Line Mapping
    manageAddresses.addressLine1.key = 'addressLine1';
    manageAddresses.addressLine1.label = this.labels.manageAddAddressLine;
    manageAddresses.addressLine1.required = true;
    // personalDetails.firstName.readOnly = this.isReadOnly('CM_FirstName');
    manageAddresses.addressLine1.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
    manageAddresses.addressLine1.order = 1;
    manageAddresses.addressLine1.value = '';
    manageAddresses.addressLine1.regexValidation = this.validateAlphanumericWithSpecialCharacters('CM_AddressLine1');
    manageAddresses.addressLine1.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;

    // Town Mapping
    manageAddresses.town.key = 'town';
    manageAddresses.town.label = this.labels.manageAddTown;
    manageAddresses.town.required = true;
    // personalDetails.firstName.readOnly = this.isReadOnly('CM_FirstName');
    manageAddresses.town.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
    manageAddresses.town.order = 2;
    manageAddresses.town.value = '';

    // Post Code Mapping
    manageAddresses.postalCode.key = 'postalCode';
    manageAddresses.postalCode.label = this.labels.manageAddPostCode;
    manageAddresses.postalCode.required = true;
    manageAddresses.postalCode.regexValidation = this.validateNumeric('CM_ZipCode');
    manageAddresses.postalCode.correctFormatValidationMsg = this.labels.correctFormatErrorMessageLabel;
    // personalDetails.firstName.readOnly = this.isReadOnly('CM_FirstName');
    manageAddresses.postalCode.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
    manageAddresses.postalCode.order = 3;
    manageAddresses.postalCode.value = '';

    manageAddresses.addressId = { key: 'addressId', value: 0 },
      manageAddresses.defaultAddress = { key: 'defaultAddress', value: false },

      // Country Mapping
      manageAddresses.country.key = 'country',
      manageAddresses.country.label = this.labels.manageAddCountry;
    manageAddresses.country.required = true;
    // manageAddresses.country.readOnly = this.isReadOnly('CM_Title');
    manageAddresses.country.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
    manageAddresses.country.options =
      this.getOptionList(this.countries,
        'value', 'id');
    manageAddresses.country.order = 4;
    manageAddresses.country.value = '';

    this.yourProfileResponse.yourProfileModel.addresses.forEach((value) => {
      const addressObject = new AddressDetails();
      addressObject.addressId = value.addressId;
      addressObject.addressLine1 = value.addressLine1;
      addressObject.town = value.city;
      addressObject.postalCode = value.zipCode;
      addressObject.country = value.countryName;
      addressObject.countryId = value.countryId;
      addressObject.defaultAddress = value.isDefault;
      manageAddresses.addressList.push(addressObject);
    });
    return manageAddresses;
  }

  private initLangPreferences(languagePreference: LanguagePreference) {

    const isMultiLangEnabled =
      this.yourProfileResponse.yourProfileModel.isMultiLanguageEnabled;
    languagePreference.isMultiLanguageEnabled = isMultiLangEnabled;

    if (isMultiLangEnabled) {
      // Page Info Mapping
      languagePreference.pageInfo.headingText = this.labels.languagePreferenceLabel;
      languagePreference.pageInfo.icon = 'language';
      languagePreference.pageInfo.btnEditText = this.labels.editLabel;
      languagePreference.pageInfo.btnSaveText = this.labels.saveLabel;
      languagePreference.pageInfo.btnCancelText = this.labels.cancelLabel;
      languagePreference.isEditMode = false;

      languagePreference.currentLanguage.key = 'currentLanguage',
        languagePreference.currentLanguage.label = this.labels.currentLanguage;
      languagePreference.currentLanguage.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
      languagePreference.currentLanguage.value =
        this.yourProfileResponse.yourProfileModel.userProfile.languageId;
      languagePreference.currentLanguage.required = true;
      languagePreference.currentLanguage.readOnly = true;
      languagePreference.currentLanguage.order = 1;
      languagePreference.currentLanguage.options =
        this.getOptionList(this.yourProfileResponse.customerDetailOptions.sponsorLanguage.preferredLanguages,
          'languageName', 'languageID');
    }

    return languagePreference;
  }

  private initConsentPreference(consentPreferences: ConsentPreferences) {

    consentPreferences.isConsentPreferencesEnabled = this.consentAndContactEnabled;
    if (consentPreferences.isConsentPreferencesEnabled) {
      // Page Info Mapping
      consentPreferences.pageInfo.headingText = this.labels.consentPreferencesLabel;
      consentPreferences.pageInfo.btnEditText = this.labels.editLabel;
      consentPreferences.pageInfo.subheadingText = this.labels.consentSubHeadingDescription;
      consentPreferences.pageInfo.btnSaveText = this.labels.saveLabel;
      consentPreferences.pageInfo.btnCancelText = this.labels.cancelLabel;
      consentPreferences.isEditMode = false;

      consentPreferences.policyHeading = this.labels.consentPolicyLabel;
      consentPreferences.policyDescription = this.labels.consentPolicyDescription;

      this.yourProfileResponse.yourProfileModel.consentPurposeList.forEach((value, index) => {
        const consentObject = new PolicyConsent();
        consentObject.consent.key = value.title;
        consentObject.consentHeading = value.title;
        consentObject.consent.label = value.shortDescription;
        consentObject.consentLegalText = value.longDescription;
        consentObject.seeLegalText = this.labels.seeLegalText;
        consentObject.hideLegalText = this.labels.hideLegalText;
        consentObject.consent.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
        consentObject.consent.required = true;
        consentObject.consent.order = index + 1;
        consentObject.consent.options =
          this.getOptionList(this.radioOption,
            'value', 'id');
        consentObject.consent.value = value.consentValue.toString();
        consentObject.consentHeading = value.title;
        consentObject.consent.readOnly = false;
        consentPreferences.consentList.push(consentObject);
      });
    }
    return consentPreferences;
  }

  private initContactPreference(contactPreferences: ContactPreferences) {
    contactPreferences.isContactPreferencesEnabled = this.consentAndContactEnabled;
    if (contactPreferences.isContactPreferencesEnabled) {
      // Page Info Mapping
      contactPreferences.pageInfo.headingText = this.labels.contactPreferencesLabel;
      contactPreferences.pageInfo.btnEditText = this.labels.editLabel;
      contactPreferences.pageInfo.subheadingText = this.labels.contactSubHeadingLabel;
      contactPreferences.pageInfo.note = this.labels.contactNote;
      contactPreferences.pageInfo.btnSaveText = this.labels.saveLabel;
      contactPreferences.pageInfo.btnCancelText = this.labels.cancelLabel;
      contactPreferences.isEditMode = false;

      for (let i = 0; i < 3; i++) {
        const contactObject = new ContactType();
        contactObject.contact.mandatoryValidationMsg = this.labels.mandatoryErrorMessageLabel;
        contactObject.contact.required = true;
        contactObject.contact.order = i + 1;
        contactObject.contact.options =
          this.getOptionList(this.radioOption,
            'value', 'id');
        contactObject.contact.readOnly = false;

        if (i === 0) {
          contactObject.contact.key = 'email';
          contactObject.contact.label = this.labels.contactPreferenceEmail;
          contactObject.contact.value = this.yourProfileResponse.yourProfileModel.contactPreferences.agreeEmailTPCP.toString();
        } else if (i === 1) {
          contactObject.contact.key = 'address';
          contactObject.contact.label = this.labels.contactPreferenceAddress;
          contactObject.contact.value = this.yourProfileResponse.yourProfileModel.contactPreferences.agreeMailTPCP.toString();
        } else if (i === 2) {
          contactObject.contact.key = 'phone';
          contactObject.contact.label = this.labels.contactPreferencePhone;
          contactObject.contact.value = this.yourProfileResponse.yourProfileModel.contactPreferences.agreePhoneTPCP.toString();
        }
        contactPreferences.contactTypeList.push(contactObject);
      }
    }
    return contactPreferences;
  }

  private getOptionList(optionList: any[], name: string | number, value: string | number) {
    return optionList.map((obj) => {
      return { name: obj[name], value: obj[value]};
    });
  }

  private isShowhideField(fieldName: string): boolean {
    return this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase() && fields.isShowHide);
  }
  private isNewShowhideField(fieldName: string): boolean {
    return this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase() && fields.isNewSalesShowHide);
  }
  private isMandatory(fieldName: string): boolean {
    return this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase() && fields.isMandatry);
  }

  private isReadOnly(fieldName: string): boolean {
    return this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase() && fields.isReadOnly);
  }

  private getMaxLength(fieldName: string): number {
    const fieldConfig = this.customerFields?.find(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase());
    return fieldConfig?.maxLength > 0 ? fieldConfig?.maxLength : fieldConfig?.defaultMaxLength;
  }

  savePersonalDetailsForPartialClaim(saveType: string) {
    this.baseYourProfileComponent.savePersonalDeatils(saveType);
  }

  private validateNumeric(fieldName: string): string {
    const fieldConfig = this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase()
      && fields?.displayName?.includes('Numeric'));
    return fieldConfig === true ? '^[-]?[0-9., ]*$' : null;
  }

  private validateAlphaWithPunctuations(fieldName: string): any {
    const fieldConfig = this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase()
      && fields?.displayName === 'AlphaWithPunctuations');
    return fieldConfig === true ? XRegExp('^([\\pL+ A-z &\.,\'])*$') : null;
  }

  private validateAlphanumericWithSpecialCharacters(fieldName: string): any {
    const fieldConfig = this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase()
      && fields?.displayName === 'AlphanumericWithSpecialCharacters');
    return fieldConfig === true ? XRegExp('^((?:[\\pL+ A-Za-z0-9-\'.,@:?!()$#/\\]+|&[^#])*&?)*$') : null;
  }

}
