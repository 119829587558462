import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { AppConfig, endPoints } from '../../core/app.config';
import { MsalAuthenticationService } from '../../../services/common-auth/msalauthentication.service';
import { SponsorService } from '../../../services/sponsor/sponsor.service';
import { ITopbarLabellist } from '../../models/topbar-navlink';
import { BaseAccountService } from '../../../services/account/base-account.service';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';
import { Subscription } from 'rxjs';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { BaseConstantService } from '../../../services/constant/base-constant.service';


const newQuoteLabel = 'Home.lblNewQuote';
const newPolicySearchLabel = 'Shared.lblNewPolicySearchLabel';
const savedQuotesLabel = 'Home.lblSavedQuotes';
const renewalsLabel = 'Home.lblRenewals';
const loginRegisterLabel = 'Shared.lblLoginRegister';
const logoutLabel = 'Shared.lblLogout';
const newClaimLabel = 'Shared.lblNewClaim';
const yourClaimLabel = 'Shared.lblYourClaim';
const yourPoliciesLabel = 'Shared.lblYourPolicies';
const homeLabel = 'Home.lblHome';
@Component({
  selector: 'lib-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  topbarLabellist: ITopbarLabellist[] = [];
  profileMenuItems = [];
  newClaimText: string;
  selectLanguagesText: string;
  AppConfig: any = AppConfig;
  loginRegisterText: string;
  sponsorImage: string;
  currentPageLangSelection: boolean;
  loggedInUserName: string;
  displayMenus: boolean;
  displayRenewalsMenu: boolean;
  routerSubscription: Subscription;
  @Input() showLoginRegisterLogoutButton: boolean;
  @Input() hideAuthenticationForCustomerIdentification: boolean;
  isB2BFlow: boolean;


  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private accountService: BaseAccountService,
              private sessionStorage: SessionStorageService,
              private translate: TranslateService,
              private sponsorService: SponsorService,
              private msalAuthenticationService: MsalAuthenticationService,
              private sharedSettingsService: SharedSettingsService,
              private utilitiesService: UtilitiesService,
  ) {
    this.routerSubscription = router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.languageSelection();
      }
    });
  }

  ngOnInit(): void {

    this.getLogo();
    const baseUrl = endPoints.baseUrl.toLowerCase();
    this.isB2BFlow = this.sharedSettingsService.isB2B();
    if (baseUrl.indexOf('salesv2') === 1) {
      if (this.isB2BFlow) {
        this.bindTranslatedLabels(this.applyTranslations([newQuoteLabel, newPolicySearchLabel]));
      } else {
        this.bindTranslatedLabels(this.applyTranslations([newQuoteLabel, savedQuotesLabel, renewalsLabel, homeLabel]));
      }
    } else {
      this.bindTranslatedLabels(this.applyTranslations([newClaimLabel, yourClaimLabel, yourPoliciesLabel]));
    }
    this.setLoginLogoutText();
    this.languageSelection();
  }

  private setLoginLogoutText() {
    const baseUrl = endPoints.baseUrl.toLowerCase();
    if (baseUrl.indexOf('salesv2') === 1) {
      const jwtToken = localStorage.getItem('msal.idtoken');
      if ((this.displayMenus || this.displayRenewalsMenu) && (jwtToken === null || this.msalAuthenticationService.isSessionTimedOut())) {
        this.loginRegisterText = this.applyTranslations([loginRegisterLabel])[loginRegisterLabel];
      }
    } else {
      const jwtToken = localStorage.getItem('msal.idtoken');
      if (jwtToken === null || this.msalAuthenticationService.isSessionTimedOut()) {
        this.loginRegisterText = this.applyTranslations([loginRegisterLabel])[loginRegisterLabel];
      }
    }
  }

  private constructPathName(pathname: string): string {
    return pathname.split('/')[1];
  }
  languageSelection() {
    if (this.router.url.includes('/home/new-claim') || this.router.url.includes('/account-details/your-policies') ||
      this.router.url.includes('/account-details/your-profile') || this.router.url.includes('/home/new-quote') ||
      this.router.url.includes('/home/save-quote') || this.router.url.includes('/home/your-claim') ||
      this.router.url.includes('/quote/renewal-quotes')) {
      this.currentPageLangSelection = true;
    } else {
      this.currentPageLangSelection = false;
    }
  }

  private bindTranslatedLabels(translatedLabelList: string[]): void {
    const baseUrl = endPoints.baseUrl.toLowerCase();
    if (baseUrl.indexOf('claims') === 1) {
      this.topbarLabellist = [
        {
          label: translatedLabelList[newClaimLabel],
          navLink: '/home/new-claim'
        }
      ];
      this.profileMenuItems = [];
      if (this.hideAuthenticationForCustomerIdentification !== true && this.msalAuthenticationService.isAuthenticated() &&
         ((this.activatedRoute.snapshot.data.accountVerification || this.sharedSettingsService.enableAssently === true) ||
         this.isSkeletalPolicy())) {
        this.topbarLabellist.push({
          label: translatedLabelList[yourClaimLabel],
          navLink: '/home/your-claim'
        });
        this.getMenuByProfile();

      }
    } else {

      let headerText = "";
      let policySearchText ="";
      if (this.sessionStorage.get('EnableSalesV2Home') === true && (this.isB2BFlow === false)) {
        headerText = translatedLabelList[homeLabel];
      }
      else {
        headerText = translatedLabelList[newQuoteLabel];
      }
      this.topbarLabellist = [
        {
          label: headerText,
          navLink: '/home/new-quote'
        }

      ];
      if (this.isB2BFlow && this.sessionStorage.get('policySearchEnabled') === true)
      {
        policySearchText =translatedLabelList[newPolicySearchLabel];
        this.topbarLabellist.push( {
          label: policySearchText,
          navLink: '/policy/search'
        })
      }

      if (!this.isB2BFlow) {
        if (sessionStorage.getItem('saveQuoteEnabled') === 'true') {
          this.displayMenus = true;
        } else {
          this.displayMenus = false;
        }
        if (this.displayMenus) {
          this.topbarLabellist.push({
            label: translatedLabelList[savedQuotesLabel],
            navLink: '/quote/saved-quotes'
          });
        }
        this.displayRenewalsMenu = sessionStorage.getItem('renewals') === 'true'
        if (this.displayRenewalsMenu) {
          this.topbarLabellist.push({
            label: translatedLabelList[renewalsLabel],
            navLink: '/quote/renewal-quotes'
          });
        }
      }

      this.profileMenuItems = [];

      const isSalesV2 = window.location.href.toLowerCase().indexOf('salesv2') > 0 ? true : false;

      if (this.hideAuthenticationForCustomerIdentification !== true && isSalesV2
        && (this.displayMenus || this.displayRenewalsMenu) && this.msalAuthenticationService.isAuthenticated()) {
        this.getMenuByProfile();
      } else if (this.hideAuthenticationForCustomerIdentification !== true
        && (this.displayMenus || this.displayRenewalsMenu) && this.msalAuthenticationService.isAuthenticated()
        && (this.activatedRoute.snapshot.data.accountVerification || this.sharedSettingsService.enableAssently === true)) {
        this.getMenuByProfile();
      }
    }

  }
  getMenuByProfile() {
    this.accountService.getMenuByProfile(BaseAccountService.ProfileName).subscribe(menuItems => {
      this.profileMenuItems = [];
      const parentArray = [];
      menuItems.find((items) => {
        if (items.parentId === '00000000-0000-0000-0000-000000000000') {// To Find parent menu Items
          parentArray.push(items);
        }
      });
      parentArray.find((items) => {
        const subItems = [];
        menuItems.forEach((item) => {
          if (item.parentId === items.menuId) {
            subItems.push({ id: item.menuId, name: item.description, url: item.url, icon: item?.menuIcon });
          }
        });
        this.profileMenuItems.push({ items, subItems });
      });
    });
  }



  private getLogo(): void {
    const isWTWBrandingEnabled = this.sponsorService.applyWillisBranding;
    const sponsorLogo = this.sponsorService.sponsorLogo;

    if (isWTWBrandingEnabled === false && sponsorLogo !== '' &&
      sponsorLogo !== undefined && sponsorLogo !== 'data:image/;base64,') {
      // Show Sponsor Logo
      this.sponsorImage = sponsorLogo;
    } else {
      // Show WTW logo
      this.sponsorImage = window.location.origin + '/' + this.constructPathName(window.location.pathname)
        + `${this.AppConfig.assetsImagesPath}` + 'wtw-logo.jpg';
    }
  }

  private isSkeletalPolicy(): boolean {
    return  ((sessionStorage.getItem('EnableCustomerIdentityB2CClaims') !== null &&
              !JSON.parse(sessionStorage.getItem('EnableCustomerIdentityB2CClaims'))._value) &&
            (sessionStorage.getItem('ManageClaimsb2cSalesPortalEnabled') !== null &&
              JSON.parse(sessionStorage.getItem('ManageClaimsb2cSalesPortalEnabled'))._value))
  }

  private applyTranslations(labelList: string[]): string[] {
    let response: string[];

    const navigationEntries = performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload'
      && this.sharedSettingsService.getHomeTranslation(labelList)) {
      response = this.sharedSettingsService.getHomeTransalation();
      return response;
    }

    this.translate.get(labelList).subscribe((res) => {
      response = res;
      this.sharedSettingsService.setHomeTransalation(res);
    });
    return response;
  }

  navigateToNavLink(event: string) {
    if (event === 'savedquotes') {
      this.router.navigate(['/quote/saved-quotes']);
    } else if (event === 'renewals') {
      this.router.navigate(['/quote/renewal-quotes']);
    } else if (event === 'yourpolicies') {
      this.router.navigate(['/account-details/your-policies']);
    } else if (event === 'yourprofile') {
      this.router.navigate(['/account-details/your-profile']);
    } else if (event === 'yourclaims') {
      this.router.navigate(['home/your-claim']);
    } else if (event === 'LoginLogoutEvent') {
      if (this.msalAuthenticationService.isAuthenticated()) {
        this.msalAuthenticationService.logout();
        this.router.navigate(['/logout']);
      } else {
        this.loginRedirect();
      }
    } else {
      this.router.navigateByUrl(event);
    }
  }

  loginRedirect() {
    // Auth guard on this route will trigger login process.
    this.router.navigate(['/login']);
  }
  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }
}
