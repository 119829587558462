<div [formGroup]="autoSuggestListForm" class="autosuggest" [ngClass]="{'custom-width':field}">
  <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-readonly':field.readOnly}">
    <mat-label id="matLabel" for="typeahead-basic" [innerHTML]="field.label | safeHtml"></mat-label>
    <input id="typeahead-control" matInput [type]="field.type" class="form-control truncateBigWord"
      [ngbTypeahead]="search" [readonly]="field.readOnly" [pattern]="field.regexValidation"
      formControlName="{{field.key}}" (keyup)="getSuggestionList($event)"
      [attr.maxLength]="field.maxLength !== undefined && field.maxLength > 0 ? field.maxLength : null" 
      (blur)="onBlurEvent($event)"
      />

    <span matPrefix *ngIf="field.iconName !=''">
      <mat-icon id="iconName" matPrefix>{{field.iconName}}</mat-icon>
    </span>
    <button id="btnCallToAction" *ngIf="field.callToActionLabel !=''" mat-button matSuffix
      (click)="callToActionOnClick()">{{field.callToActionLabel}}</button>
  </mat-form-field>
</div>

