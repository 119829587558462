import { FormFieldBase } from './form-field-base';

export class FileUploadField extends FormFieldBase<string> {
  controlType = 'file';
  validatorMatchWith: string;
  questionFormat: string;
  allowedFileType: string;
  maxSize: number;
  maxRangeErrorMessage: string;
  inValidFileTypeErrorMessage: string;
  mandatoryDocErrorMessage: string;
  maxFileErrorMsg: string;

  constructor(options: {
    value?: string,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    type?: string,
    readOnly?: boolean,
    iconName?: string,
    mandatoryValidationMsg?: string | string[],
    validatorMatchWith?: string,
    allowedFileType?: string,
    maxSize?: number,
    maxRangeErrorMessage?: string,
    isVisible?: boolean,
    questionFormat?: string;
    inValidFileTypeErrorMessage?: string;
    mandatoryDocErrorMessage?: string;
    maxFileErrorMsg?: string;
  } = {}) {
    super(options);
    this.validatorMatchWith = options['validatorMatchWith'] || '';
    this.questionFormat = options.questionFormat;
    this.allowedFileType = options.allowedFileType;
    this.maxSize = options.maxSize;
    this.maxRangeErrorMessage = options.maxRangeErrorMessage;
    this.inValidFileTypeErrorMessage = options.inValidFileTypeErrorMessage;
    this.mandatoryDocErrorMessage = options.mandatoryDocErrorMessage;
    this.maxFileErrorMsg = options.maxFileErrorMsg;
  }
}
