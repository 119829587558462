<!-- <p>abc</p> -->

<ng-container class="container">
  <h2 mat-dialog-title>{{data.typeLabel}}</h2>
  <hr>
  <mat-mdc-dialog-content class="mat-typography">
    <form class="pageForm" [formGroup]="addressForm" id="addressForm">
      <ng-container *ngFor="let field of pageFormFields" class="form-row">
        <div class="row">
          <div class="col-md-3 d-flex align-items-center padding-bottom">
            <label class="description-label" *ngIf="field.label"><b>{{field.label}}</b></label>
          </div>
          <div class="col-md-9 w-100">
            <ng-container [ngSwitch]="field.controlType">
              <ng-container *ngSwitchCase="'datepicker'" class="dateAlign">
                <div class="row d-flex">
                  <div class="col-12"><lib-date-picker id="dtDatePicker" [field]="field" [form]="addressForm"></lib-date-picker></div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'timepicker'">
                <div class="row d-flex">
                  <div class="col-12"><lib-time-picker id="tmTimePicker" [field]="field" [form]="addressForm"></lib-time-picker></div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'textbox'">
                <div class="row d-flex">
                  <div class="col-12"><lib-text-box id="txtbox" [field]=field [form]="addressForm"></lib-text-box></div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'textarea'">
                <div class="row d-flex">
                  <div class="col-12"><lib-text-area id="textarea" [field]=field [form]="addressForm"></lib-text-area></div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'dropdown'">
                <div class="row d-flex">
                  <div class="col-12"><lib-drop-down id="ddl" [field]=field [form]="addressForm"></lib-drop-down></div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <mat-checkbox id="defaultAddress" formControlName="defaultAddress" [(ngModel)]="isDefaultAddress"
                      [disabled]="isDefaultAddressDisabled ">
          <p class="consentLabel" style="margin-left: 12px;">{{address.pageInfo.defaultAddressText}}</p>
        </mat-checkbox>
      </ng-container>
      <div class="row d-flex button-align mx-2 justify-content-center" id="saveCancel">
        <div class="col-6 my-2" id="divCancelButtonContainer">
          <lib-primary-button id="btnCancel" [text]="address.pageInfo.btnCancelText"
                              (clicked)="onCancel()">
          </lib-primary-button>
        </div>
        <div class="col-6 my-2" id="divSaveButtonContainer">
          <lib-primary-button id="btnSave" [text]="address.pageInfo.btnSaveText"
                              (clicked)="onClickEvent()" [disabled]="addressForm.invalid"></lib-primary-button>
        </div>
      </div>

    </form>
  </mat-mdc-dialog-content>
</ng-container>


