import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DropDownFieldMapper } from '../field-mapper-strategy/strategies/dropdown-field-mapper';
import { TranslateService } from '@ngx-translate/core';
import { AnswerSets, Question } from '../../../models';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';
import { DropDownField } from '../../../form-field/dropdown-field';

const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';

@Component({
  selector: 'lib-dropdown-data-capture',
  templateUrl: './dropdown-data-capture.component.html',
  styleUrls: ['./dropdown-data-capture.component.scss']
})
export class DropdownDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Input() nextBtn: string;
  @Output() updateEmitter = new EventEmitter();
  dropdownForm: FormGroup;
  field: DropDownField;
  mandatoryErrorMessageText: string;
  options: AnswerSets[];

  constructor(
    private formGroupService: FormGroupControlService,
    private fieldMapper: DropDownFieldMapper,
    private translate: TranslateService) { }

  ngOnInit() {
    this.initialise();
  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, this.mandatoryErrorMessageText);
    this.dropdownForm = this.formGroupService.toFormGroup([this.field]);
  }

  private getSelectedAnswerSet(): AnswerSets {
    return this.qnData.answerSets.filter(ans => ans.name === this.dropdownForm.get(this.field.key).value).pop();
  }

  submitDropdownAnswer() {
    // Clear any previous answers.
    this.qnData.answerSets.forEach(as => as.isAnswered = false);

    const answeredData = this.getSelectedAnswerSet();
    if (answeredData) {
      answeredData.isAnswered = true;
    }
    this.updateEmitter.emit();
  }

  private initialise() {
    this.translate.get([mandatoryErrorMessageLabel]).subscribe((res) => {
      this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
      this.createFormGroup();
    });
  }
}
