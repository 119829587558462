import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import moment from 'moment';
import { SessionStorageService } from 'angular-web-storage';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { LayoutService } from '../../../../services/layout/layout.service';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';
import { InfoCard } from '../../../../shared/models/info-card.model';

@Component({
  selector: 'lib-wide-button',
  templateUrl: './wide-button.component.html',
  styleUrls: ['./wide-button.component.scss']
})
export class WideButtonComponent implements OnInit {

  @Input() label: string;
  @Input() subheading: string;
  @Input() details: string | string[];
  @Input() panelState: string | string[];
  @Input() callToAction: string;
  @Input() tag: any;
  @Input() disabled = false;
  @Input() questions: string | string[];
  @Input() img: any[];
  @Input() infoItems: InfoCard[];
  @Input() totalGrossPremiumText: string = '';
  @Input() totalGrossPremiumValue: string = '';  
  @Input() indexVal: any;
  isMobile = false;
  isTablet = false;
  isDesktop = true;
  @Output() clicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter<any>();
  public showTooltip = false;
  isArray: boolean;
  isDate: boolean;
  isCompressedViewOfPreviousAnswers: boolean;
  @Input() deleteCallToAction: string;
  @Input() isMaxRepeat: any = 0;
  isDisabledButton: boolean;
  panelOpenState = true;
  isVisibleMatPanelHeader = false;
  isEmailValueBlank = false;
  @Input() productName: string;
  @Input() referredTooltipText: string;
  constructor(private sessionStorage: SessionStorageService,
              private settingsService: SharedSettingsService,
              private layoutService: LayoutService,
              public questionSetService: QuestionSetService,
  ) { }

  showUserDetails = false;

  ngOnInit() {
    // to trigger the widebutton when there is change in 'IsNewRepeatingGroup'
    this.questionSetService?.IsNewRepeatingGroup.subscribe(value => {
      this.isDisabledButton = this.settingsService.getPreviousAnswerEditButtonStatus();
    })
    if (this.isMaxRepeat > 0) {
      this.isVisibleMatPanelHeader = true;
    }
    this.isDisabledButton = this.settingsService.getPreviousAnswerEditButtonStatus();
    this.showUserDetails = this.indexVal !== undefined ? true : false;
    this.isEmailValueBlank = this.sessionStorage.get('isEmailValueBlank');
    this.checkDeviceWidth();
    this.isCompressedViewOfPreviousAnswers = this.settingsService.compressedViewOfPreviousAnswers;
    if (this.subheading !== undefined && this.subheading !== null
      && this.subheading.length >= 50 && this.isDesktop === true) {
      this.showTooltip = true;
    }
    moment.locale(this.sessionStorage.get('LanguageCode'));
    if (Array.isArray(this.details)) {
      this.isDate = false;
      this.isArray = true;
    } else {
      if (this.subheading !== undefined && this.subheading !== null
        && (this.subheading.toUpperCase() === "STARTDATE" || this.subheading.toUpperCase() === "ENDDATE")
        && this.details.length > 10) {
        const objDate = this.settingsService.setLocalisedDateFormat(this.details);
        if (objDate && objDate !== 'Invalid date' && objDate !== undefined) {
          this.details = objDate;
        }
      }

      this.isDate = this.isValidDate(this.details);
      this.isArray = false;
    }
  }

  togglePanel() {
    this.panelOpenState = !this.panelOpenState;
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  public onClick(event): void {
    event.stopPropagation();
    this.clicked.emit(this.tag);
  }

  isValidDate(date): boolean {
    let dateFormat = 'DD/MM/YYYY';
    if (this.sessionStorage.get('LocalisedDateFormat')) {
      dateFormat = this.sessionStorage.get('LocalisedDateFormat');
    }
    if (moment(date, "DD/MM/YYYY", true).isValid()) {
      date = moment.utc(date, "DD/MM/YYYY", true);
    }
    else if (moment(date, ["YYYY-MM-DD", dateFormat], true).isValid()) {
      date = moment.utc(date, ["YYYY-MM-DD", dateFormat], true);
    }
    return moment.isMoment(date)
      || moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()
      || moment(date, moment.defaultFormat, true).isValid()
      || moment(date, this.sessionStorage.get('LocalisedDateFormat'), true).isValid();
  }

  public onDeleteClick(): void {
    this.deleteClicked.emit(this.tag);
  }
}
