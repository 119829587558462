import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { ICustomerFieldValidations, Question, UserProfile, BaseConstantService } from 'wtw-ui-components';
import moment from 'moment';
import { Subscription, lastValueFrom } from 'rxjs';
import { QuestionSetService, LayoutService, BaseAccountService, BaseCustomerService, SharedSettingsService } from 'wtw-ui-components';
import { ConstantService } from '../../../services/constant/constant.service';
import { UploadDocuments } from '../../../shared/models/upload-documents.model';
import { SalesSettingsService } from '../../../services/settings/settings.service';
import { IProfileType, IQuoteSummary } from '../../models';
import { LocationStrategy } from '@angular/common';
import { AccountService } from '../../../services/account/account.service';
import { ProductTemplateService } from '../../../services/product-template/product-template.service';
import { DocumentService } from '../../../services/document/document.service';
import { CustomerService } from '../../../services/customer/customer.service';
import { QuoteEvidenceJourney } from '../../models/quote-evidence-journey';

const editLabel = 'Shared.lblEdit';
const communicationTypePostLabel = 'Shared.lblPost';
const communicationTypeEmailLabel = 'Shared.lblEmail';
const emailValueWarningLabel = 'Shared.lblEmailWarningMsg';
const salesUploadedDocumentsLabel = 'Shared.lblNewSalesUploadedDocuments';
const yourDetailsLabel = 'Shared.lblPrevAnsYourDetails';
const yourAddressLabel = 'Shared.lblPrevAnsYourAddress';
const yourConsentLabel = 'Shared.lblPrevAnsYourConsent';
const supportingDocumentsLabel = 'Shared.lblPrevAnsSupportingDocuments';
const mailLabel = 'Shared.lblPrevAnsAgreeMailTPCP';
const emailLabel = 'Shared.lblPrevAnsAgreeEmailTPCP';
const phoneLabel = 'Shared.lblPrevAnsAgreePhoneTPCP';
const emailPrimaryAndSecondaryLabel = 'Shared.lblEmailPrimaryAndSecondary';


@Component({
  selector: 'app-question-container',
  templateUrl: './question-container.component.html',
  styleUrls: ['./question-container.component.scss']
})
export class QuestionContainerComponent implements OnInit, OnDestroy {
  @Output() clicked = new EventEmitter<Question>();
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  panelState = false;
  registerPanelState = false;
  addressPanelState = false;
  consetPanelState = true;
  uploadDocumentPanelState = false;
  supportingDocuments: '';
  uploadedDocuments: '';
  referredTooltip: '';
  userData = {
    type: '',
    label: '',
    details: [],
    indexVal: null
  };
  detailsObject = [];
  userAddress = {
    type: '',
    label: '',
    details: []
  };
  userConsent = {
    type: '',
    label: '',
    details: [],
  };
  uploadDocuments = {
    label: '',
    labels: [],
    details: []
  }
  customerTypeList: any;
  customerIdentifierDecrypted;
  editText: string;
  emailText: string;
  postText: string;
  yourQuoteText: string;
  emailWarning: string;
  yourDetails: string;
  yourAddress: string;
  yourConsent: string;
  consentMailText: string;
  consentEmailText: string;
  consentPhoneText: string;
  private customerFields: ICustomerFieldValidations[];
  private sponsorId: number;
  customerTypeId: number;
  isCompressedViewOfPreviousAnswers: boolean;
  dateFormat = 'DD/MM/YYYY';
  private userProfile: UserProfile;
  lastAnswered: string;
  isQuoteEvidenceUploadEnabled: boolean;
  isQuoteEvidanceAtQuoteSave: boolean;
  isQuoteEvidanceAfterPolicy: boolean;
  quoteSummary: IQuoteSummary;
  panelOpenState: boolean;
  yourQuoteTextName: string;
  lblreferred: string;
  formatedOriginalGrossRateForDisplay: string;
  isQuoteReferredCheck: boolean;
  isB2cMultiCarrierQuotingEnabled: boolean;
  isB2cSingleCarrierMultiQuoteEnabled: boolean;
  quoteProductName: string;
  hideAddressInPreviousAnswer: boolean = true;
  hidePolicyQuestionFromB2B: boolean;
  shouldHideEditButton: boolean;
  validationList: ICustomerFieldValidations[];
  quoteReferenceID: any;
  profileType: IProfileType;
  atQuoteSave: QuoteEvidenceJourney = QuoteEvidenceJourney.Atquotesave;
  afterPolicyQuestions: QuoteEvidenceJourney = QuoteEvidenceJourney.Afterpolicyquestions;
  emailPrimaryAndSecondary: string;

  provinceList: any;
  private quoteStatusSubscription: Subscription = new Subscription();
  private profileTypeSetSubscription: Subscription = new Subscription();

  constructor(
    private questionSetService: QuestionSetService,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private baseAccountService: BaseAccountService,
    private accountService: AccountService,
    private sessionStorage: SessionStorageService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private baseCustomerService: BaseCustomerService,
    private customerService: CustomerService,
    private settingsService: SharedSettingsService,
    private route: ActivatedRoute,
    private constantService: ConstantService,
    private salessSettingsService: SalesSettingsService,
    private location: LocationStrategy,
    public productTemplateService: ProductTemplateService,
    private documentService: DocumentService
  ) { }
  async ngOnInit() {
 // preventing from back navigation through browser back arrow
    window.onpopstate = function () {
      window.history.go(1);
    };
    this.userProfile = this.activatedRoute.snapshot.data.userProfile;
    this.sponsorId = this.sessionStorage.get('SponsorId');
    const productId = this.sessionStorage.get('ProductId');
    this.quoteReferenceID = this.sessionStorage.get('QuoteReferenceID');
    this.quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    this.isQuoteReferredCheck = this.settingsService.isQuoteReferredCheck;
    this.isB2cMultiCarrierQuotingEnabled = this.sessionStorage.get('isB2cMultiCarrierQuotingEnabled') === 'true';
    this.isB2cSingleCarrierMultiQuoteEnabled = sessionStorage.getItem('isB2cSingleCarrierMultiQuoteEnabled') === 'true';
    this.formatedOriginalGrossRateForDisplay = this.sessionStorage.get('formatedOriginalGrossRateForDisplayValue');
    const replacementValue = this.quoteSummary?.paymentSplit?.length?.toString() || '0';
    const selectedPaymentFrequency = this.sessionStorage.get('SelectedPaymentFrequency') ?? '';
    const updatedPaymentFrequency = selectedPaymentFrequency.replace('<N>', replacementValue);
    if (updatedPaymentFrequency !== null && updatedPaymentFrequency !== undefined) {
      this.formatedOriginalGrossRateForDisplay = (this.formatedOriginalGrossRateForDisplay === 'referred') ? updatedPaymentFrequency :
        this.formatedOriginalGrossRateForDisplay + ' ' + updatedPaymentFrequency;
    }
    this.quoteProductName = this.sessionStorage.get('QuoteProductName');
    if (this.quoteSummary !== undefined && this.isQuoteReferredCheck) {
      this.lblreferred = this.sessionStorage.get('QuoteReferredStatusValue');
    }
    else {
      this.lblreferred = "";
    }
    const quotePageTranslations = this.sessionStorage.get('quotePageTranslations');
    if (quotePageTranslations !== null && quotePageTranslations !== undefined) {
      this.referredTooltip = quotePageTranslations[0].value; // ReferredTooltip
      this.yourQuoteTextName = quotePageTranslations[1].value; // YourQuote
    }

    this.customerTypeList = BaseConstantService.customerTypeList;
    this.isCompressedViewOfPreviousAnswers = this.settingsService.compressedViewOfPreviousAnswers;
    this.customerTypeId = this.sessionStorage.get('ProductCustomerType');
    this.questionSetService.previousAnswerLastQuestion.subscribe((previousAnswerLastQues: string) => {
      this.lastAnswered = previousAnswerLastQues;
      if (this.lastAnswered === "" || this.lastAnswered === undefined || this.lastAnswered === null) {
        const sessionObj = this.sessionStorage.get('PreviousAnswerLastQuestion');
        if (sessionObj) {
          this.lastAnswered = sessionObj;
        }
      }
      this.setPanelSate(this.lastAnswered);
    });
    if (this.sessionStorage.get('LocalisedDateFormat')) {
      this.dateFormat = this.sessionStorage.get('LocalisedDateFormat');
    }
    this.applyTranslations();
    this.checkDeviceWidth();

    await Promise.all([
      this.getCustomerFields(),
      this.getProductTemplate(productId),
      this.getCountryList(),
      this.GetProvinceList(),
      this.getConsentpurposeList(),
      this.getDocumentAttachment()
    ]);
    if (this.userProfile) {
      this.getUserProfileDetailRecords(this.userProfile);
    } else {
      this.fetchUserProfile(this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value);
    }

    if (this.isQuoteEvidenceUploadEnabled) {
      this.populateUploadDocuments();
    }

    this.quoteStatusSubscription = this.questionSetService.quoteAccepted.subscribe(this.handleQuoteAccepted.bind(this));
    this.profileTypeSetSubscription = this.questionSetService.profileTypeSet.subscribe(this.handleProfileTypeSet.bind(this));
  }

  setPanelSate(lastAnsweredObj) {
    if (lastAnsweredObj === this.constantService.youraddressPageName) {
      this.addressPanelState = true;
    }
    else {
      this.addressPanelState = false;
    }
    if (lastAnsweredObj === this.constantService.yourDetailsPageName) {
      this.registerPanelState = true;
    }
    else {
      this.registerPanelState = false;
    }
    if (lastAnsweredObj === this.constantService.yourconsentPageName) {
      this.consetPanelState = true;
    }
    else {
      this.consetPanelState = false;
    }
    if (lastAnsweredObj === this.constantService.policyquestionpageName) {
      this.uploadDocumentPanelState = true;
    }
    else {
      this.uploadDocumentPanelState = false;
    }
    if (lastAnsweredObj === this.constantService.yourQuotePageName) {
      this.panelOpenState = true;
    }
    else {
      this.panelOpenState = false;
    }

  }

  getUserProfileDetailRecords(objuserProfile: UserProfile) {
    if (objuserProfile?.customerIdentifierEncrypted) {
      this.baseAccountService.getDecryptedIdentifier(objuserProfile.customerIdentifierEncrypted, objuserProfile.sponsorId)
        .subscribe(result => {
          if (result !== null && result !== undefined) {
            this.customerIdentifierDecrypted = result.value;
          }
          this.getUserDetails(objuserProfile);
          this.getUserAddress(objuserProfile);
          this.getUserConsent(objuserProfile);
        })
    }
    else {
      this.getUserDetails(objuserProfile)
      this.getUserAddress(objuserProfile);
      this.getUserConsent(objuserProfile);
    }

    if (!(this.sessionStorage.get('userProfile'))) {
      this.sessionStorage.set('userProfile', objuserProfile);
    }
  }

  private applyTranslations() {
    this.translate.get([editLabel, communicationTypeEmailLabel, communicationTypePostLabel, emailValueWarningLabel,
      supportingDocumentsLabel, salesUploadedDocumentsLabel, yourDetailsLabel, yourAddressLabel, yourConsentLabel,
      mailLabel, emailLabel, phoneLabel, emailPrimaryAndSecondaryLabel]).subscribe((res) => {
        this.editText = res[editLabel];
        this.emailText = res[communicationTypeEmailLabel];
        this.postText = res[communicationTypePostLabel];
        this.emailWarning = res[emailValueWarningLabel];
        this.supportingDocuments = res[supportingDocumentsLabel];
        this.uploadedDocuments = res[salesUploadedDocumentsLabel];
        this.yourDetails = res[yourDetailsLabel];
        this.yourAddress = res[yourAddressLabel];
        this.yourConsent = res[yourConsentLabel];
        this.consentMailText = res[mailLabel];
        this.consentEmailText = res[emailLabel];
        this.consentPhoneText = res[phoneLabel];
        this.emailPrimaryAndSecondary = res[emailPrimaryAndSecondaryLabel];
      });
  }

  goToYourQuote() {
    const quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    if (quoteSummary.isB2cSingleCarrierMultiQuoteEnabled === false) {
      this.router.navigate(['/quote/choose-cover'], { relativeTo: this.route, state: { isBackwards: true } });
    } else {
      this.router.navigate(['/quote/primary-optional-cover']);
    }
  }
  getUserDetails(userProfileObject: any) {
    const lookupTitle = this.sessionStorage.get('TitleList');
    const lookupContactMethod = this.sessionStorage.get('ContactMethodList');
    const enableGuestPurchase = this.settingsService.enableGuestPurchase;
    const enableSSOBasedAccess = this.settingsService.enableSSOBasedAccess;
    const enableVoucherBasedAccess = this.settingsService.enableVoucherBasedAccess;
    // const useCached = !(enableGuestPurchase || enableSSOBasedAccess || enableVoucherBasedAccess);
    if (this.customerFields) {
      let title, contactMethod;
      if (lookupTitle) {
        title = lookupTitle.find(t => t.id === userProfileObject.title);
      }
      if (lookupContactMethod) {
        contactMethod = lookupContactMethod.find(t => t.id === userProfileObject.contactMethodId);
      }

      this.userData = {
        type: 'Details',
        label: this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value
          ? this.yourDetails
          : this.sessionStorage.get('UserDetailsPageTitle'),
        details: [],
        indexVal: null
      };

      this.getCustomerTypeForUserData(this.userData)
      if (title && title.value && this.isShowhideField('CM_Title')) {
        if (this.settingsService.compressedViewOfPreviousAnswers && this.isShowhideField('CM_FirstName')
          && this.isShowhideField('CM_LastName')) {
          const name = title.value + ' ' + userProfileObject.firstName + ' ' + userProfileObject.lastName;
          this.userData.details.push(name);
          this.detailsObject.push(this.addObjectValue('CM_FirstName', name));

        } else {
          this.userData.details.push(title.value);
          this.detailsObject.push(this.addObjectValue('CM_Title', title.value));
        }
      }
      if (this.isShowhideField('CM_CompanyName')) {
        this.userData.details.push(userProfileObject.companyName);
        this.detailsObject.push(this.addObjectValue('CM_CompanyName', userProfileObject.companyName))
      }

      if (this.isShowhideField('CM_CustomerIdentifier')) {
        this.userData.details.push(this.customerIdentifierDecrypted);
        this.detailsObject.push(this.addObjectValue('CM_CustomerIdentifier', this.customerIdentifierDecrypted));
      }
      if (this.isShowhideField('CM_CompanyID')) {
        this.userData.details.push(this.customerIdentifierDecrypted);
        this.detailsObject.push(this.addObjectValue('CM_CompanyID', this.customerIdentifierDecrypted));
      }
      if (this.isShowhideField('CM_PlaceOfRegistration')) {
        this.userData.details.push(userProfileObject.placeOfBirthTown);
        this.detailsObject.push(this.addObjectValue('CM_CompanyID', userProfileObject.placeOfBirthTown));
      }
      if ((this.isShowhideField('CM_FirstName'))
        && !this.settingsService.compressedViewOfPreviousAnswers) {
        this.userData.details.push(userProfileObject.firstName);
        this.detailsObject.push(this.addObjectValue('CM_FirstName', userProfileObject.firstName));
      }
      if (this.isShowhideField('CM_Company_Contact_First_Name')
        && !this.settingsService.compressedViewOfPreviousAnswers) {
        this.userData.details.push(userProfileObject.firstName);
        this.detailsObject.push(this.addObjectValue('CM_Company_Contact_First_Name', userProfileObject.firstName));

      }
      if ((this.isShowhideField('CM_LastName'))
        && !this.settingsService.compressedViewOfPreviousAnswers) {
        this.userData.details.push(userProfileObject.lastName);
        this.detailsObject.push(this.addObjectValue('CM_LastName', userProfileObject.lastName));
      }
      if (this.isShowhideField('CM_Company_Contact_Last_Name')
        && !this.settingsService.compressedViewOfPreviousAnswers) {
        this.userData.details.push(userProfileObject.lastName);
        this.detailsObject.push(this.addObjectValue('CM_Company_Contact_Last_Name', userProfileObject.lastName));
      }
      if (this.isShowhideField('CM_DateofBirth')) {
        if (userProfileObject.dateOfBirth) {
          const dobDate = new Date(userProfileObject.dateOfBirth)
          const momentDate = moment(dobDate, this.dateFormat, this.sessionStorage.get('LanguageCode'), false);
          this.userData.details.push(momentDate.format("YYYY-MM-DD"));
          this.detailsObject.push(this.addObjectValue('CM_DateofBirth', momentDate.format("YYYY-MM-DD")));
        } else {
          this.userData.details.push(userProfileObject.dateOfBirth);
          this.detailsObject.push(this.addObjectValue('CM_DateofBirth', userProfileObject.dateOfBirth));
        }
      }

      if (this.isShowhideField('CM_PlaceOfBirth')) {
        this.userData.details.push(userProfileObject.placeOfBirth);
        this.detailsObject.push(this.addObjectValue('CM_PlaceOfBirth', userProfileObject.placeOfBirth));
      }

      if (this.isShowhideField('CM_PlaceOfBirthTown')) {
        this.userData.details.push(userProfileObject.placeOfBirthTown);
        this.detailsObject.push(this.addObjectValue('CM_PlaceOfBirthTown', userProfileObject.placeOfBirthTown));
      }

      if (this.isShowhideField('CM_Email1')) {
        if (userProfileObject.email === "") {
          this.sessionStorage.set('isEmailValueBlank', true);
          this.userData.details.push(this.emailWarning);
          this.detailsObject.push(this.addObjectValue('CM_Email1', this.emailWarning));
        }
        else {
          this.sessionStorage.set('isEmailValueBlank', false);
          this.userData.details.push(userProfileObject.email);
          this.detailsObject.push(this.addObjectValue('CM_Email1', userProfileObject.email));
        }
      }
      if (this.isNewSalesShowhideField('CM_Email2')) {        
          this.userData.details.push(userProfileObject.email2);
          this.detailsObject.push(this.addObjectValue('CM_Email2', userProfileObject.email2));
      }

      if (this.isShowhideField('CM_TaxIdentificationNumber') || this.isShowhideField('CM_TaxIndentification')) {
        this.userData.details.push(userProfileObject.taxIdentificationNumber);
        this.detailsObject.push(this.addObjectValue('CM_TaxIdentificationNumber', userProfileObject.taxIdentificationNumber));
      }
      if (this.isShowhideField('CM_TaxIndentification')) {
        this.userData.details.push(this.addObjectValue('CM_TaxIndentification', userProfileObject.taxIdentificationNumber));

      }

      if (this.isShowhideField('CM_CountryCode')) {
        this.userData.details.push(userProfileObject.countryCode + ' ' + userProfileObject.phoneNumber);
        this.detailsObject.push(this.addObjectValue('CM_CountryCode', userProfileObject.countryCode + ' ' + userProfileObject.phoneNumber));
      } else if (this.isShowhideField('CM_PhoneNumber1')) {
        this.userData.details.push(userProfileObject.phoneNumber);
        this.detailsObject.push(this.addObjectValue('CM_PhoneNumber1', userProfileObject.phoneNumber));
      }
      if (contactMethod && contactMethod.value && this.isShowhideField('CM_ContactMethod')) {
        if (this.customerTypeId === 2) {
          if (contactMethod.id === 1) {
            this.userData.details.push(this.postText);
            this.detailsObject.push(this.addObjectValue('CM_ContactMethod', this.postText));
          }
          else if (contactMethod.id === 4) {
            this.userData.details.push(this.emailPrimaryAndSecondary);
            this.detailsObject.push(this.addObjectValue('CM_ContactMethod', this.emailPrimaryAndSecondary));
          }
          else {
            this.userData.details.push(this.emailText);
            this.detailsObject.push(this.addObjectValue('CM_ContactMethod', this.emailText));
          }
        }
        else {
          this.userData.details.push(contactMethod.value);
          this.detailsObject.push(this.addObjectValue('CM_ContactMethod', contactMethod.value));
        }
      }
      this.userData.indexVal = this.detailsObject.findIndex(x => x.fieldName === 'CM_Email1');
    }
  }

  addObjectValue(fieldName, fieldValue) {
    const fieldObj = {
      fieldName: '',
      value: ''
    }
    fieldObj['fieldName'] = fieldName,
      fieldObj['value'] = fieldValue

    return fieldObj;
  }

  getCustomerTypeForUserData(userData) {
    let customerType = this.sessionStorage.get(BaseConstantService.CattributeCustomerType);
    customerType = customerType === null || customerType === undefined ? 1 : customerType;
    if (this.sessionStorage.get('isCattributePresentForCustomerType') === 1 ||
      this.sessionStorage.get('ProductCustomerSelectedByDropDown') === 'true') {
      customerType = this.sessionStorage.get(BaseConstantService.CattributeCustomerType);
    }
    this.customerTypeList.map(x => {
      if (x.id === customerType) {
        this.userData.details.push(x.value);
        this.detailsObject.push(this.addObjectValue('CM_ContactMethod', x.value));
      }
    })
  }

  getUserAddress(userProfileObj: any) {
    const countries = JSON.parse(this.sessionStorage.get('CountryList'));
    const address = userProfileObj?.addresses?.[0];

    if (address) {
      let country;
      let province;
      if (countries) {
        country = countries.find(c => c.id === address.countryId);
      }
      if (this.provinceList) {
        province = this.provinceList.find(p => p.id === address.provinceId);
      }
      this.userAddress = {
        type: 'Address',
        label: this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value
          ? this.yourAddress
          : this.sessionStorage.get('AddressCapturePageTitle'),
        details: [
        ]
        // commenting below code for bug 176839
        // isHideEditButton: this.isShowAddressEditBtnOnPrevAns()
      };

      this.validationList = this.settingsService.customerFieldsValidationsList;

      let hideAddress = true;
      const addressFields = {
        'CM_AddressLine1': address?.addressLine1,
        'CM_City': address?.city,
        'CM_Province': province?.code,
        'CM_ZipCode': address?.zipCode,
        'CM_Country': country?.textValue
      };

      for (const field in addressFields) {
        if (this.isNewSalesShowhideField(field)) {
          this.userAddress.details.push(addressFields[field]);
          hideAddress = false;
        }
        else if (this.isShowhideField(field) && field.includes('CM_Province')) {
          this.userAddress.details.push(addressFields[field]);
          hideAddress = false;
        }
      }

      if (this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value) {
        this.hideAddressInPreviousAnswer = hideAddress;
      } else {
        this.hideAddressInPreviousAnswer = this.salessSettingsService.hideAddressInPreviousAnswer;
      }
    }
  }

  getUserConsent(userProfileObj: any) {
    const consentData = this.sessionStorage.get('ConsentList');
    if (consentData?.length > 0) {
      const customerConsent = userProfileObj.customerConsents;
      if (customerConsent) {
        this.userConsent = {
          type: 'Consent',
          label: this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value
            ? this.yourConsent
            : this.sessionStorage.get('ConsentCapturePageTitle'),
          details: []
        };
        customerConsent.forEach((c: any) => {
          const consentpurpose = consentData.find(cons => cons.consentPurposeId === c.consentPurposeId);
          this.userConsent.details.push(consentpurpose?.shortDescription, c.consentOptIn ? 'Yes' : 'No');
        });
        if (this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value) {
          this.setDataSharingConsent(userProfileObj.dataShareConsent);
        }
        if (this.sessionStorage.get('contactdetail') != null) {
          for (const value of this.sessionStorage.get('contactdetail')) {
            this.userConsent.details.push(value);
          }
        }
      }
    }
  }

  populateUploadDocuments() {
    if (this.salessSettingsService.uploadDocuments?.fileUpload?.length > 0) {
      const uploadDocuments: UploadDocuments = this.salessSettingsService.uploadDocuments;
      this.uploadDocuments = {
        label: this.uploadedDocuments,
        labels: [this.supportingDocuments],
        details: [
          uploadDocuments.uploadedFilesName
        ]
      };
    }
  }

  get doesPolicyQuestionExist() {
    if (this.questionSetService.answeredQuestions) {
      return this.questionSetService.answeredQuestions.some(item => item.qQuestionType === 'Policy');
    } else {
      return 0;
    }
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  editClicked(question: Question) {
    this.questionSetService.navigateToIntentCode(question.qIntentCode);
    if (question && question.qQuestionType === 'Quote') {
      this.goToQuoteQuestions(question);
    } else if (question && question.qQuestionType === 'Policy') {
      this.goToPolicyQuestions(question);
    }
  }

  goToQuoteQuestions(question: Question) {
    const currentUrl = window.location.pathname;
    if (!currentUrl?.includes('quote-questions')) {
      this.router.navigate(['quote/quote-questions']);
      return;
    }
    this.clicked.emit(question);
  }

  goToPolicyQuestions(question: Question) {
    // doing this code to fix bug 176882
    const currentUrl = window.location.pathname;
    if (!currentUrl?.includes('policy-questions')) {
      this.router.navigate(['policy/policy-questions']);
      return;
    }
    this.clicked.emit(question);
  }

  goToRegister() {
    this.setIsShowUserAndAddressDetailsScreenFlag();
    this.sessionStorage.set('isEditOptionClick', true)
    this.router.navigate(['user-details/register']);
  }

  goToUserAddress() {
    this.setIsShowUserAndAddressDetailsScreenFlag();
    this.sessionStorage.set('isEditOptionClick', true)
    this.router.navigate(['user-details/address']);
  }

  goToUserConsent() {
    this.sessionStorage.set('isEditOptionClick', true)
    this.router.navigate(['user-details/consent']);
  }

  goToUploadDocuments() {
    this.sessionStorage.set('isEditOptionClick', true)
    this.router.navigate(['policy/upload-documents']);
  }

  // #region Methods related to show/hide edit button in previous answers section.
  private checkIfEditButtonShouldBeHidden(): boolean {
    const isQuoteReferred = this.settingsService.isQuoteReferredCheck;
    if ((this.accountService.referralPermission.isApproveDeclineOnly && isQuoteReferred)
      || (this.accountService.referralPermission.isApproveDeclineWithPremiumOverride)) {
      return true;
    }

    if (isQuoteReferred && this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value) {
      switch (this.profileType?.profileTypeName?.toUpperCase()) {
        case this.constantService.ProfileType.B2BUSER:
        case this.constantService.ProfileType.B2CCUSTOMER:
          return isQuoteReferred;
        case this.constantService.ProfileType.BUSINESSUSER:
          return !this.accountService.referralPermission.isEditOnly && this.accountService.referralPermission.isApproveDeclineOnly;
        case this.constantService.ProfileType.AFFINITYOPSADMIN:
          return false;
        default:
          return true;
      }
    }
    return false;
  }

  private async getCountryList(): Promise<void> {
    if (!JSON.parse(this.sessionStorage.get('CountryList'))
      && this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value) {
      await lastValueFrom(this.accountService.getCountryList());
    }
  }

  private async GetProvinceList(): Promise<void> {
    this.provinceList = await lastValueFrom(this.customerService.GetProvinceList());
    return this.provinceList;
  }

  private async getConsentpurposeList(): Promise<void> {
    if (!this.sessionStorage.get('ConsentList')) {
      await lastValueFrom(this.customerService.getSponsorConsentpurposeList(this.sponsorId, this.sessionStorage.get('LanguageId')));
    }
  }

  private async getCustomerFields(): Promise<void> {
    if (!this.customerFields) {
      this.customerFields = await lastValueFrom(this.baseCustomerService.getCustomerFieldValidations(this.sponsorId));
      this.settingsService.customerFieldsValidationsList = this.customerFields;
    }
  }

  private async getProductTemplate(productId: string): Promise<void> {
    const productTemplate = await lastValueFrom(this.productTemplateService.getProductTemplate(Number(productId)));
    this.isQuoteEvidenceUploadEnabled = productTemplate?.isQuoteEvidenceUploadEnabled;
    this.isQuoteEvidanceAtQuoteSave = productTemplate?.quoteEvidenceJourney === this.atQuoteSave;
    this.isQuoteEvidanceAfterPolicy = productTemplate?.quoteEvidenceJourney === this.afterPolicyQuestions;
    this.hidePolicyQuestionFromB2B = productTemplate?.hidePolicyQuestionFromB2BProfileonB2C;
  }

  private async getDocumentAttachment(): Promise<void> {
    const quoteReferenceID = this.sessionStorage.get('QuoteReferenceID');
    if (this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value && quoteReferenceID) {
      const documentAttachment = await lastValueFrom(this.documentService.getTempAttachmentByQuoteId());
      if (documentAttachment) {
        this.documentService.resumeUploadDocuments(documentAttachment);
      }
    }
  }

  private handleResumedJourney(): void {
    if (this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value) {
      const resumeCustomerId = this.sessionStorage.get('ResumeCustomerId');
      if (resumeCustomerId) {
        this.sessionStorage.set('customerId', resumeCustomerId);
      }
    }
  }

  private handleQuoteAccepted(isQuoteAccepted: boolean) {
    // Determine if the edit button should be hidden based on the quoteStatus
    if (!isQuoteAccepted) {
      return;
    }

    this.shouldHideEditButton = this.checkIfEditButtonShouldBeHidden();
    this.isQuoteReferredCheck = this.shouldHideEditButton;
  }

  // Fetches the user profile based on whether the journey is resumed or not
  private fetchUserProfile(isResumedJourney: boolean) {
    const options = { usedCachedValue: false, ssoIdentifier: null, isEncryptionRequired: isResumedJourney };

    // If the journey is resumed, handle the resumed journey
    if (isResumedJourney) {
      this.handleResumedJourney();
    }

    this.baseAccountService.getUserProfile(options.usedCachedValue, options.ssoIdentifier, options.isEncryptionRequired).subscribe(data => {
      if (data) {
        this.getUserProfileDetailRecords(data);
      }
    });
  }

  // Setting Third Party Contact Preference.
  private setDataSharingConsent(dataSharingConsent: any) {
    if (dataSharingConsent) {
      const details = [];
      const consentSharingFields = {
        'CM_PostalContact': {
          label: this.consentMailText,
          value: dataSharingConsent?.agreeMailTPCP ? dataSharingConsent.agreeMailTPCP : false,
        },
        'CM_EmailContact': {
          label: this.consentEmailText,
          value: dataSharingConsent?.agreeEmailTPCP ? dataSharingConsent.agreeEmailTPCP : false,
        },
        'CM_PhoneContact': {
          label: this.consentPhoneText,
          value: dataSharingConsent?.agreePhoneTPCP ? dataSharingConsent.agreePhoneTPCP : false
        }
      };

      for (const field in consentSharingFields) {
        if (this.isShowhideField(field)) {
          details.push(consentSharingFields[field]?.label, consentSharingFields[field]?.value ? 'Yes' : 'No');
        }
      }

      this.sessionStorage.set('contactdetail', details);
    }
  }

  private handleProfileTypeSet(isProfileTypeSet: boolean) {
    if (isProfileTypeSet) {
      this.profileType = this.accountService.profileTypeDetails;
      this.shouldHideEditButton = this.checkIfEditButtonShouldBeHidden();
      this.settingsService.hidePolicyQuestionsEdit = this.shouldHideEditButton;
    }
  }
  // #endregion Methods related to show/hide edit button in previous answers section.

  private isShowhideField(fieldName: string): boolean {
    return this.customerFields?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase() && fields.isShowHide);
  }
  private isNewSalesShowhideField(fieldName: string): boolean {
    return this.customerFields ?.some(fields => fields.fieldName.toUpperCase() === fieldName.toUpperCase() && fields.isNewSalesShowHide);
  }
  private isShowAddressEditBtnOnPrevAns() {
    const currentUrl = window.location.pathname;
    const hasVisiblePolicyQuestions = this.questionSetService.hasVisibleQuestions('policy');
    // If product has any policy questions then check if it is answered or not
    // other wise check only current url.
    if (hasVisiblePolicyQuestions) {
      // If current url is register(user details) page and
      // one or more policy questions has been answered then hide edit button
      // on address  page.
      return this.doesPolicyQuestionExist && currentUrl.indexOf('register') >= 0;
    } else {
      // If current url is register(user details) page and
      // there is no policy question then hide edit button
      // on address  page.
      return currentUrl.indexOf('register') >= 0;
    }
  }

  private setIsShowUserAndAddressDetailsScreenFlag() {
    // Check if 'enableSSOBasedAccess' is set or not if yes, check for
    // 'externalValidationIterationData.index' if that is > 0 then
    // set 'IsShowUserAndAddressDetailsScreen' to true for displaying user details and address page.
    if (this.settingsService.enableSSOBasedAccess) {
      const externalValidationIterationData = this.settingsService.externalValidationIterationData;
      const isRepeatJourney = externalValidationIterationData
        && externalValidationIterationData.index
        && externalValidationIterationData.index > 0;
      if (isRepeatJourney) {
        this.sessionStorage.set('IsShowUserAndAddressDetailsScreen', true);
      }
    }
  }

  ngOnDestroy(): void {
    this.quoteStatusSubscription.unsubscribe();
    this.profileTypeSetSubscription.unsubscribe();
  }


}
