import { AfterViewInit, Directive, ElementRef, EventEmitter, Output } from '@angular/core';


@Directive({
    selector: '[libIsEllipsisActive]'
})
export class IsEllipsisActiveDirective implements AfterViewInit {
    constructor(private elementRef: ElementRef) { }
    ngAfterViewInit() {
        setTimeout(() => {
            const element = this.elementRef.nativeElement;
            element.title = element.innerHTML;
        });
    }
}
