import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, Inject } from '@angular/core';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';
import { LayoutService } from '../../../services/layout/layout.service';
import { ITopbarLabellist } from '../../models/topbar-navlink';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { BaseConstantService } from '../../../services/constant/base-constant.service';
import { TranslateService } from '@ngx-translate/core';
import { MsalAuthenticationService } from '../../../services/common-auth/msalauthentication.service';
import { SessionStorageService } from 'angular-web-storage';

const closeLabel = 'Shared.lblClose';

@Component({
  selector: 'lib-topbar-switcher',
  templateUrl: './topbar-switcher.component.html',
  styleUrls: ['./topbar-switcher.component.scss']
})

export class TopbarSwitcherComponent implements OnInit {

  @Input() sponsorImage: string;
  @Input() topbarLabellist: ITopbarLabellist[];
  @Input() loginRegisterText?: string;
  @Input() closeButtonText?: string;
  @Input() profileMenuItems = [];
  @Output() navigateToNavLink = new EventEmitter<string>();
  @Input() langSelection: boolean;
  showHomeIcon = true;
  showWTWLogo = false;
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  toggleMenuIcon = false;
  @Input() showLoginRegisterLogoutButton: boolean;
  @Input() hideAuthenticationForCustomerIdentification: boolean;
  public enableAssently = false;
  constructor(
    private layoutService: LayoutService,
    @Inject(DOCUMENT) private document: Document,
    private settingsService: SharedSettingsService,
    private utilitiesService: UtilitiesService,
    private translate: TranslateService,
    private msalAuthenticationService: MsalAuthenticationService,
    private sessionStorageService: SessionStorageService
  ) {

  }

  ngOnInit() {
    this.checkDeviceWidth();
    const isMultiProductLanding = sessionStorage.getItem('IsMultiProductLanding');
    if (isMultiProductLanding == null || isMultiProductLanding === undefined) {// To set the default value
      this.settingsService.isMultiProductLanding = false;
    }

    if (window.location.href.toLowerCase().indexOf('salesv2') > 0) {
      this.showHomeIcon = JSON.parse(sessionStorage.getItem('IsMultiProductLanding'))._value;
    } else {
      this.showHomeIcon = true;
    }

    // *** If 'isb2b' is true then Multi-Language & Login option should be hidden ***//

    const isB2BFlow = this.settingsService.isB2B();
    const b2bName = this.settingsService.b2bName();

    if (isB2BFlow) {
      this.applyTranslations();
      this.langSelection = false;
      if (this.msalAuthenticationService.isAuthenticated() === true) {
        this.showLoginRegisterLogoutButton = false;
        this.loginRegisterText = 'Hello ' + b2bName;
      } else {
        const queryParams = this.sessionStorageService.get('QueryParams');
        if (queryParams.hasOwnProperty('isb2b')) {
          delete queryParams.isb2b;
        }
        if (queryParams.hasOwnProperty('b2bname')) {
          delete queryParams.b2bname;
        }
        this.sessionStorageService.set('QueryParams', queryParams);
        this.showLoginRegisterLogoutButton = true;
      }
    }

    if (this.hideAuthenticationForCustomerIdentification === true) {
      this.loginRegisterText = null;
      this.showLoginRegisterLogoutButton = false;
    }     

  }

  private applyTranslations() {
    this.translate.get([closeLabel]).subscribe((res) => {
      this.closeButtonText = res[closeLabel];
    });
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  navigateToMultiProductLandingPage() {
    this.settingsService.isMultiProductLanding = true;
    this.settingsService.isMultiProductFindOutMoreLinkClicked = false;
    window.location.reload();
  }

  navigateNavLink(event: string) {
    this.navigateToNavLink.emit(event);
  }

  navigateToLogin() {
    this.navigateToNavLink.emit('LoginLogoutEvent');
  }

  isActive(activeLink: string): boolean {
    return window.location.href.includes(activeLink);
  }

  mobileMenuOpen() {
    this.toggleMenuIcon = true;
    this.document.body.classList.add('noScroll');
  }

  mobileMenuClose() {
    this.toggleMenuIcon = false;
    this.document.body.classList.remove('noScroll');
  }

  closeCurrentTab() {
    window.open('', '_self').close();
  }

}
