import { Injectable } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { IAppMonitor } from '../../models/app-monitor';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// API Documentation
// https://github.com/microsoft/applicationinsights-js
@Injectable({
    providedIn: 'root'
})

export class LoggingService {
    appMonitor: IAppMonitor;
    isAppInsightsInitialized = false;
    applicationSiteName = '';
    initializedByWithoutProductName = false;

    constructor() {
    }

    initializeAppInsights(instrumentationKey: string, siteName: string, sponsorName: string, productName: string): boolean {

        if (!sessionStorage.getItem('legalApprovedRegionAppInsights'))
        {
            // Have we previously initialized?
            if (this.appMonitor && this.initializedByWithoutProductName === false) {
                return true;
            }

            // Are performance cookies enabled?
            if (sessionStorage.getItem('AllowPerformanceCookies') !== 'True') {
                return false;
            }
        }

        // Can we retrieve the required instrumentation key?
        if (!instrumentationKey) {
            return false;
        }


        // Initialize ApplicationInsights.
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey
            }
        });

        appInsights.loadAppInsights();

        const telemetryInitializer = (envelope) => {
            envelope.data.SiteName = siteName;
            envelope.data.SponsorName = sponsorName ? sponsorName.toLowerCase() : '';
            envelope.data.ProductName = productName ?
                productName.toLowerCase() : '';
        };

        this.initializedByWithoutProductName = productName ? false : true;

        appInsights.addTelemetryInitializer(telemetryInitializer);

        this.appMonitor = appInsights;
        this.applicationSiteName = siteName;
        this.isAppInsightsInitialized = true;
        return true;

    }

    logPageView(name?: string, url?: string) {
        if (this.isAppInsightsInitialized) {
            this.appMonitor.trackPageView({
                name,
                uri: url
            });
        }
    }

    // Log non-exception type errors, e.g. invalid API request
    logError(error: any, severityLevel?: SeverityLevel) {
        this.sendToConsole(error, severityLevel);
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (this.isAppInsightsInitialized) {
            this.appMonitor.trackEvent({ name }, properties);
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (this.isAppInsightsInitialized) {
            this.appMonitor.trackMetric({ name, average }, properties);
        }
    }

    logException(exception: Error, severityLevel?: SeverityLevel) {
        this.sendToConsole(exception, severityLevel);
        if (this.isAppInsightsInitialized) {
            this.appMonitor.trackException({ exception, severityLevel });
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (this.isAppInsightsInitialized) {
            this.appMonitor.trackTrace({ message }, properties);
        }
    }

    private sendToConsole(error: any, severityLevel: SeverityLevel = SeverityLevel.Error) {

        switch (severityLevel) {
            case SeverityLevel.Critical:
            case SeverityLevel.Error:
                (console as any).group(`${this.applicationSiteName} Error:`);
                console.error(error);
                if (error.message) {
                    console.error(error.message);
                }
                if (error.stack) {
                    console.error(error.stack);
                }
                (console as any).groupEnd();
                break;
            case SeverityLevel.Warning:
                (console as any).group(`${this.applicationSiteName} Warning:`);
                console.warn(error);
                (console as any).groupEnd();
                break;
            case SeverityLevel.Information:
                (console as any).group(`${this.applicationSiteName} Information:`);
                console.log(error);
                (console as any).groupEnd();
                break;
        }
    }
}

