import { Injectable } from '@angular/core';
import { FormFieldBase } from '../../../form-field/form-field-base';
import { Question } from '../../../models';
import * as fromStrategies from './strategies';

@Injectable({
    providedIn: 'root'
})
export class FieldMapper {

    public map(question: Question,
               errorMessage?: string | string[],
               callToActionLabel?: string,
               order?: number,
               formFieldIteration?: number): FormFieldBase<string> {
        for (const mapper of fromStrategies.mappers.sort((a, b) => a.order - b.order)) {
            if (mapper.canMap(question)) {
                return mapper.map(question, errorMessage, callToActionLabel, order, formFieldIteration);
            }
        }
    }
}
