import { InjectionToken } from '@angular/core';
export const APP_CONFIG = new InjectionToken('app.config');

const baseURL = 'api/';
export const endPoints = {
  host: window.location.origin,
  baseUrl: '/salesv2/',
  apiUrl: baseURL,
  endPointName: {
    configurationEndPoint: 'Configuration',
    getTermsOfUsebySponsorName: 'Sponsor/GetTermOfUseBySponsorName',
    getSponsorPreferredLanguage: 'Sponsor/GetSponsorPreferredLanguage',
    getCookieNotice: 'Reference/GetCookieNoticeByLanguageCode/',
    getB2CHomeProductDetails: 'B2CProductTemplate/GetB2CHomeProductDetails/',
    getRatingQuestionSet: 'B2CProductTemplate/GetRatingQuestionSet',
    getQuoteQuestionSet: 'B2CProductTemplate/GetQuoteQuestionsSet',
    getQuotation: 'Quotation/GetQuoteRating',
    getSavedQuotes: 'Quotation/GetSavedQuotes',
    getRenewalQuotes: 'Quotation/GetRenewalQuotes',
    getQuotePricingData: 'Quotation/GetQuotePricingData',
    getQuoteDetails: 'Quotation/GetQuoteDetails',
    getPaymentConfiguration: 'Payments/GetPaymentPageContent', /* Payment page --> BEGIN */
    getPaymentReviewConfiguration: 'Payments/GetPaymentReviewPageContent',
    getPageContent: 'Payments/GetPageContent',
    initiatePayment: 'Payments/InitiatePayment',
    initiateDirectDebitPayment: 'Payments/InitiateDirectDebitPayment',
    directDebitPayment: 'Payments/DirectDebitPayment',
    activatePolicyPayment: 'Payments/ActivatePolicyPayment',
    threeDSecureValidationFailure: 'Payments/ThreeDSecureValidationFailure', /* Payment page --> END */
    getProductSubjectivityDocument: 'B2CProductTemplate/GetProductSubjectivityDocuments',
    getSelectedPaymentFrequenciesForProduct: 'B2CProductTemplate/GetSelectedPaymentFrequenciesForProduct',
    getPaymentTermDetailsForProduct: 'B2CProductTemplate/GetPaymentTermDetailsForProduct',
    getDocumentWithFileDetails: 'B2CProductTemplate/GetDocumentWithFileDetails',
    saveUserDetailsWithSupplementaryData: 'Account/SaveUserDetailsWithSupplementaryData',
    saveUserDetailsWithoutSupplementaryData: 'Account/SaveUserDetailsWithoutSupplementaryData',
    createQuoteReferralActionWithNotification: 'Account/CreateQuoteReferralActionWithNotification',
    b2cLoginAuditLog: 'Account/B2CLoginAuditLog',
    updatePolicyQuestionsResponse: 'Policy/UpdatePolicyQuestionsResponse',
    saveResponseDataWithAudit: 'Policy/SaveResponseDataWithAudit',
    financialTransactions: 'Policy/CreateFinancialTransactions',
    policyPurchase: 'Policy/PolicyPurchase',
    ProductCreation: 'Quotation/ProductCreation',
    assignOrUnassignUserToAction: 'Quotation/AssignOrUnassignUserToAction',
    declineReferralQuote: 'Quotation/DeclineReferralQuote',
    getPoliciesLimitAsync: 'Policy/GetPoliciesLimitAsync',
    getPolicySearchResult: 'Policy/SearchPolicy',
    saveB2CUserAddress: 'Account/SaveB2CUserAddress',
    getProductTemplate: 'B2CProductTemplate/getProductTemplate',
    getPaymentType: 'B2CProductTemplate/GetPaymentType',
    getDeclaration: 'B2CProductTemplate/getadditionaldeclarationforpolicy',
    saveDeclaration: 'Policy/SavePolicyDeclarations',
    getCoutryList: 'Account/GetCountryList',
    getProductTemplatePolicyConfirmationDisplayOptions: 'B2CProductTemplate/getProductTemplatePolicyConfirmationDisplayOptions',
    getLookupdatabycontext: 'Sponsor/GetLookupDataByContext',
    applyESignatureToPolicyDocument: 'Documents/ApplyESignatureToPolicyDocument',
    getPolicyDocumentForESignature: 'Documents/GetPolicyDocumentForESignature',
    getSponsorConsentpurposeList: 'Sponsor/GetSponsorConsentpurposeList',
    GetB2CUserProfileDetail: 'Sponsor/GetB2CUserProfileDetail',
    GetCustomerDataShareConsent: 'Sponsor/GetCustomerDataShareConsent',
    getMenuByProfile: 'Account/GetB2CMenuByProfile',
    getB2B2CMenu: 'Account/GetB2B2CMenu',
    docuSignEmbeddedESigning: 'Documents/DocuSignEmbeddedSigning',
    getPolicyDocumentsDocuSign: 'B2CProductTemplate/getPolicyDocumentsDocuSign',
    storeDocusignEnvelopeDetails: 'Policy/StoreDocuSignEnvelopeDetails',
    saveDocuSignESignedDocuments: 'Documents/SaveDocuSignESignedDocuments',
    getCustomerSearchResult: 'Customer/findCustomers',
    getCountryByCountryID: 'Customer/getCountryByCountryID',
    getCountryCodeLookupData: 'Customer/GetCountryCodeLookupData',
    sendQuoteEmailRequest: 'Quotation/SendQuoteEmailRequest',
    isQuoteEmailNotificationEnabled: 'Quotation/IsQuoteEmailNotificationEnabled',
    getCustomerType: 'Customer/GetCustomerTypes',
    getProductListByPolicyId: 'B2CProductTemplate/GetProductTemplateListByPolicyId',
    getProductListByPolicyAndUserId: 'B2CProductTemplate/GetProductTemplateListByPolicyAndUserId',
    getPolicyResponseData: 'Policy/GetPolicyResponseData',
    downloadExternalPolicyDocument: 'Documents/DownloadExternalPolicyDocument',
    getDocumentTemplateForPolicy: 'Documents/GetDocumentTemplateForPolicy',
    downloadMultiQuoteDocument: 'Documents/DownloadMultiQuoteDocument',
    submitQuoteURL: baseURL + 'Quotation/SubmitQuote',
    getChildProductByMasterIdURL: baseURL + 'Quotation/GetChildProductByMasterId',
    downloadATPMultiQuoteDocument: 'Documents/DownloadATPMultiQuoteDocument',
    downloadQuoteDocument: 'Documents/DownloadQuoteDocument',
    getQuoteEvidenceDocTypes: 'Quotation/GetQuoteEvidenceDocTypes',
    storeQuotesTemporaryAttachments: 'Documents/StoreQuotesTemporaryAttachments',
    attachTemporaryDocumentsToQuote: 'Documents/AttachTemporaryDocumentsToQuote',
    deleteTemporaryAttchmentNotLinkedToQuote: 'Documents/DeleteTemporaryAttchmentNotLinkedToQuote',
    getProductRatingAndPremium: 'B2CProductTemplate/GetProductRatingAndPremiumAsync',
    getCoverageTerms: 'B2CProductTemplate/GetCoverageTerms',
    getAllowableCoverageTerms: 'B2CProductTemplate/GetAllowableCoverageTerms',
    getAllMandatoryForCoverages: 'Reference/GetAllMandatoryForCoverages',
    getPspName: 'Quotation/GetPspName',
    worldLineHostedTokenSession: 'Payments/WorldlineCreateHostedTokenization',
    worldlineCreatePayment: 'Payments/CreateWorldlinePayment',
    worldlineGetPayment: 'Payments/GetWorldlinePayment',
    getTempAttachmentByQuoteReferenceId: 'Documents/GetTemporaryAttachmentByQuoteReferenceId',
    getPremiumCoverageDetails: 'Quotation/GetPremiumCoverageDetails',
    updateFinancialTransaction: 'Payments/UpdateFinancialTransaction',
    updateQuoteStatuswithAction: 'Quotation/UpdateQuoteStatuswithAction',
    AcceptQuoteReferralAction: 'Quotation/AcceptQuoteReferralAction',
    getDuplicateQuoteExtReference: 'Quotation/GetDuplicateQuoteExtReference',
    updateQuoteIdTempAttachmentsForSession: 'Documents/UpdateQuoteIdTempAttachmentsForSession',
    getProfileTypeByUserId: 'Reference/GetProfileTypeByUserId',
    shouldHidePolicyQuestions: 'Quotation/ShouldHidePolicyQuestions',
    getProductTemplateRatingData: 'B2CProductTemplate/IsRatingQuestionAndCalculationChanged',
    updateQuote: 'Quotation/UpdateQuote',
    getPolicyActivationLog: 'Policy/GetPolicyActivationLog',
    getProvinceList: 'Customer/GetProvinceList',
    getPaymentStatusCodes: 'Sponsor/GetPaymentStatusCodes',
    applyKycCheck: 'Quotation/ApplyKycCheck',
  }
};

export const AppConfig = {
  apiUrl: 'api/',
  configApiEndPoints: {
    GetConfigurationUrl: endPoints.apiUrl + endPoints.endPointName.configurationEndPoint + '/get'
  },
  assetsImagesPath: '/assets/images/'
};
