<section class="cover-wrapper" [ngClass]="{'cover-wrapper-medium':!isDesktop}">
    <section class="cover-section" id="coverSection">
        <div class="cover-container" [ngClass]="{'cover-container-medium':!isDesktop}" id="coverContainer">
            <section class="yourProfile-header text-center" id="yourProfileHeader">
                <lib-heading-sub-heading [heading]=labels.yourProfileTitleLabel class="titleDecoration"
                    [ngClass]="(isDesktop)?'heading-alingment-desktop':'heading-alingment'">
                </lib-heading-sub-heading>                    
            </section>
            <section class="profile-details" id="profile-details-section">
                <ng-container>
                <div class="profile-info">
                    <lib-base-your-profile [yourProfileData]="yourProfileData" [yourProfilePreviousData]="yourProfileResponse" (languageChangeWarningEvent)="languageChangeWarning($event)"></lib-base-your-profile>
                </div>
                </ng-container>
            </section>
        </div>
    </section>
</section>