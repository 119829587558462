<div class="desktopView" *ngIf="!isMobile">
  <div class="container">
    <div class="row">
      <div class="left">
        <mat-icon class="material-icons-outlined" class="iconAlign">insert_drive_file</mat-icon>
        <span><b>{{fileName}}</b></span>
      </div>
      <div class="middle">
        <p>{{page}}/{{totalPages}}</p>
      </div>
      <div class="right">
        <button mat-button>
          <mat-icon class="material-icons-outlined" (click)="onPDFDownload()">file_download</mat-icon>
        </button>
        <button mat-button>
          <mat-icon class="material-icons-outlined" (click)="onPrint()">print</mat-icon>
        </button>
      </div>
    </div>

  </div>
  <div class="pdfViewerDiv">
    <div class="buttonOverlayDiv">
      <div class="circle" style="margin-bottom: 16px;"><button mat-button>
          <mat-icon class="material-icons-outlined" (click)="fit_to_screen(0.93)">zoom_out_map</mat-icon>
        </button></div>
      <div class="circle" style="margin-bottom: 8px;"><button mat-button>
          <mat-icon class="material-icons-outlined" (click)="incrementZoom(0.17)">add</mat-icon>
        </button></div>
      <div class="circle"><button mat-button>
          <mat-icon class="material-icons-outlined" (click)="incrementZoom(-0.17)">remove</mat-icon>
        </button></div>
    </div>
    <div class="pdfViewsubDiv">
      <pdf-viewer [src]="pdfSrc" [render-text]="true" [fit-to-page]="fitToPage" [autoresize]="autoresize" [zoom]="zoom"
        (after-load-complete)="afterLoadComplete($event)" (pagechanging)="pagechanging($event)">
      </pdf-viewer>
    </div>
  </div>
</div>

<ng-container *ngIf="isMobile">
  <div class="mobileView" (click)="mobilePdfOpen()">
    <div class="thumbnail">
      <pdf-viewer [src]="pdfSrc" [page]="1" [original-size]="false" [show-all]="false" (after-load-complete)="afterLoadComplete($event)">
      </pdf-viewer>
    </div>

    <div class="fileNameContent">
      <p>{{fileName}}</p>
      <p *ngIf ="totalPages > 1">{{totalPages}} pages</p>
      <p *ngIf ="totalPages === 1">{{totalPages}} page</p>
    </div>

  </div>
  <div class="mobilePdfContainer" *ngIf="toggleMode">
    <div class="mobContainerHeader">
      <div class="pdfClose">
        <button mat-button>
        <mat-icon (click)="mobilePdfClose()">close</mat-icon>
      </button>
      </div>
      <div class="fileName ellipses">
        <span><b>{{fileName}}</b></span>
      </div>
      <div class="icons">
        <button mat-button>
          <mat-icon class="material-icons-outlined" (click)="onPDFDownload()">file_download</mat-icon>
        </button>
        <!-- <button mat-button>
          <mat-icon class="material-icons-outlined" (click)="onPrint()">print</mat-icon>
        </button> -->
      </div>
    </div>
    <div class="mobPdfView">
      <div class="pagenumber">
        <span>{{page}}/{{totalPages}}</span>
      </div>

      <pdf-viewer [src]="pdfSrc" [render-text]="true" [fit-to-page]="fitToPage" [autoresize]="autoresize" [zoom]="zoom"
        (after-load-complete)="afterLoadComplete($event)" (pagechanging)="pagechanging($event)">
      </pdf-viewer>
    </div>
  </div>
</ng-container>