<ng-container>
    <section class="cover-wrapper" [ngClass]="{'cover-wrapper-medium':!isDesktop}">
        <section class="cover-section" id="coverSection">
            <div class="cover-container" [ngClass]="{'cover-container-medium':!isDesktop}" id="coverContainer">
                <section class="yourPolicy-header text-center" id="yourPolicyHeader">
                    <lib-heading-sub-heading [heading]=labels.yourPoliciesTitleLabel
                        [ngClass]="(isDesktop)?'heading-alingment-desktop':'heading-alingment'">
                    </lib-heading-sub-heading>
                    <div class="d-flex justify-content-center pt-2 pb-3">
                        <ng-container *ngIf="!isPoliciesInfo">
                            <div class="dropdown">
                                <div id="toggleDropdown" (click)="toggleDropdown()" class="dropbtn d-flex">
                                    <mat-icon class="rotate">tune</mat-icon>
                                    <span class="ml-3 d-flex align-items-center">
                                        {{selectedStatusForSorting}}</span>
                                </div>
                                <div [hidden]="hideFilterSelection">
                                    <div id="overlay" class="overlay-shadow"></div>            
                                    <div id="dropdown-content" class="dropdown-content py-3">
                                        <ng-container *ngFor="let status of statusList">
                                            <a>
                                                <label class="container">{{status}}
                                                    <input id="status" name="status" type="radio"
                                                    [value]="status" [(ngModel)]="selectedStatusInput">
                                                    <span class="checkmark"></span>
                                                  </label>
                                            </a>
                                        </ng-container>
                                        <div class="d-flex mt-3 mx-3">
                                            <button class="button button-border px-4 py-2" (click)="clearFilter()">Clear</button>
                                            <button class="button button-block ml-md-auto ml-3 px-4 py-2" (click)="applyFilter()">Apply</button>
                                        </div>             
                                    </div>
                                </div>
                            </div>
                        </ng-container>                        
                    </div>
                </section>
                <section class="policy-details" id="policy-details-section">
                    <ng-container *ngIf="!isPoliciesInfo">
                        <div class="policy-info" *ngFor="let policy of filterPolicyList;">
                            <div class="card-top-container">
                                <div class="left-container row">
                                    <div class="icon-checkbox">
                                        <img class="img-fluid imgsize"
                                            [src]="'data:image/png;base64,'+policy?.productIcon" />
                                    </div>
                                    <div class="row heading-text">
                                        <div class="title-size">
                                            <div><b>{{policy.productCategory}}</b></div>
                                        </div>
                                        <div class="subtitle-size">
                                            <span><b>{{labels.policyNumberLabel}}:</b></span>
                                            <span><b>{{policy.policyNumber}}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="right-container row">
                                    <div id="link-layout-view" class="view-link" (click)="viewPolicyDetail(policy)">
                                        {{labels.viewTextLabel}}
                                    </div>
                                    <div *ngIf='checkForViewRenewal(policy.renewalPolicyID)' class="view-link"
                                        (click)="viewRenewalPolicyDetail(policy.renewalPolicyID)">{{labels.viewRenewalPolicy}}</div>
                                    <div *ngIf='checkForViewRenewalQuote(policy)' class="view-link"
                                        (click)="renewPolicy(policy, true)">{{labels.viewRenewalQuoteButtonLabel}}</div>
                                </div>
                            </div>
                            <div class="card-description" style="display: flex">
                              <div style="width: 9%;"></div>
                              <div class="col">
                                <div class="row info">
                                  <div class="col-6 col-md-6 col-sm-6"><b>{{labels.insurerNameLabel}}:</b></div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.insurerName}}</div>
                                </div>
                                <div class="row info">
                                  <div class="col-6 col-md-6 col-sm-6">
                                    <b>{{labels.policyInceptionDateLabel}}:</b>
                                  </div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.policyInceptionDate | localDateFormatPipe}}</div>
                                </div>
                                <div class="row info" *ngIf="!policy?.isContinuous">
                                  <div class="col-6 col-md-6 col-sm-6">
                                    <b>{{labels.policyExpiryDateLabel}}:</b>
                                  </div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.policyExpiryDate | localDateFormatPipe}}</div>
                                </div>
                                <div class="row info" *ngIf='!policy?.isContinuous'>
                                  <div class="col-6 col-md-6 col-sm-6"><b>{{labels.policyTermLabel}}:</b></div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.policyTerm}}</div>
                                </div>
                                <div class="row info" *ngIf='!policy?.isContinuous'>
                                  <div class="col-6 col-md-6 col-sm-6"><b>{{labels.policyPremiumLabel}}:</b></div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.formattedPremium}}</div>
                                </div>
                                <div class="row info">
                                  <div class="col-6 col-md-6 col-sm-6"><b>{{labels.paymentTermsLabel}}:</b></div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.paymentTerms}}</div>
                                </div>
                                <div class="row info">
                                  <div class="col-6 col-md-6 col-sm-6"><b>{{labels.policyStatusLabel}}:</b></div>
                                  <div class="col-6 col-md-6 col-sm-6">{{policy.policyStatus}}</div>
                                </div>
                                <div class="right-container row-center" *ngIf='policy.showRenewalPolicyButton'>
                                  <div id="link-layout-view" (click)="renewPolicy(policy)">
                                    <lib-primary-button id="btnNext" [text]="policy.renewalPolicyButtonLabel">
                                    </lib-primary-button>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isPoliciesInfo">
                        <div class="no-policies-info">
                            {{labels.noPolicyInfoLabel}}
                        </div>
                    </ng-container>
                </section>
            </div>
        </section>
    </section>
</ng-container>


