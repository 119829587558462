import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { BaseConstantService } from 'wtw-ui-components';
import {
  SharedSettingsService, UserProfile, Address,
  CustomerConsent, DataShareConsent
} from 'wtw-ui-components';
import { ProductTemplate } from '../../shared/models/product-template.model';
import { UploadDocuments } from '../../shared/models/upload-documents.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { IPreSelectMandatoryCover, ISecondaryCover } from '../../shared/models/quote-summary.model';
import { Guid } from 'guid-typescript';

const UserDetailModelKey = 'UserDetailModel';
const UserDetailsPageTitleKey = 'UserDetailsPageTitle';
const UserAddressModelKey = 'UserAddressModel';
const UserConsentModelKey = 'UserConsentModel';
const UserDataShareConsentModelKey = 'UserDataShareConsentModel';
const AddressCapturePageTitleKey = 'AddressCapturePageTitle';
const ConsentCapturePageTitleKey = 'ConsentCapturePageTitle';
const CustomerIdKey = 'customerId';
const UserPreferredLanguageID = 'UserPreferredLanguageID';
const CompressedViewOfPreviousAnswers = 'CompressedViewOfPreviousAnswers';
const DisplayGrossPremium = 'DisplayGrossPremium'
const EnableSalesV2Home = 'EnableSalesV2Home';
const IsESignatureEnabled = 'IsESignatureEnabled';
const PaymentsDueNthDayNextMonth = 'PaymentsDueNthDayNextMonth';
const IsDirectDebitPayment = 'IsDirectDebitPayment';
const DDOffSetPeriod = 'DDOffSetPeriod';
const AreaCode = 'AreaCode';
const ChildProductId = 'ChildProductId';
const UploadDocumentsKey = 'UploadDocuments';
const CurrentDocumentCountKey = 'CurrentDocumentCount';
const DisableMyAccountCreationForB2CKey = 'DisableMyAccountCreationForB2C';
const PolicyReference = 'policyReference';
const EnableProductRestrictionOnSubgroups = 'enableProductRestrictionOnSubgroups';
const HideAddressInPreviousAnswer = 'HideAddressInPreviousAnswer';
const CoverageNameAndCoverageTermSelection = 'CoverageNameAndCoverageTermSelection';
const PageId = 'PageId';
const TempQuoteId = 'TempQuoteId';
const ApplicationFormId = 'applicationFormId';

@Injectable({
  providedIn: 'root'
})

export class SalesSettingsService extends SharedSettingsService {
  public ProductDetails: ProductTemplate;
  public coverageTermChanged: boolean;
  public UniquesSessionId: string;
  public selectedOptionalCoversIntentCode: any = [];
  public primaryCoverSelected: boolean;
  public OptionalCoversIntentCode: string;
  public preSelectedAndMandatoryCoversList: any = [];
  public excludedCoverages = new Subject<any>();
  public ClearSelectionTriggerd: boolean;
  public launchUrl: string;
  constructor(sessionStorageService: SessionStorageService) {
    super(sessionStorageService);
  }

  /* Define Sales Application scoped Settings here, global settings on base. */

  // TempQuoteId
  public get tempQuoteId(): string {
    let quoteId = this.getValue<string>(TempQuoteId);

    if (quoteId === undefined || quoteId === null) {
      quoteId = Guid.create().toString();
      this.tempQuoteId = quoteId;
    }

    return quoteId;
  }

  public set tempQuoteId(value: string) {
    this.setValue(TempQuoteId, value);
  }

  // HideAddressInPreviousAnswer
  public get hideAddressInPreviousAnswer(): boolean {
    let hideAddress = this.getValue<boolean>(HideAddressInPreviousAnswer);
    if (hideAddress === null || hideAddress === undefined) {
      hideAddress = JSON.parse(sessionStorage.getItem('HideAddressInPreviousAnswer'))?._value
    }
    return hideAddress;
  }

  public set hideAddressInPreviousAnswer(value: boolean) {
    this.setValue(HideAddressInPreviousAnswer, value);
  }

  // CustomerId
  public get customerId(): number {
    return this.getValue<number>(CustomerIdKey);
  }

  public set customerId(value: number) {
    this.setValue(CustomerIdKey, value);
  }

  // Userpreferredlanguage Id
  public get userPreferredLanguageId(): number {
    return this.getValue<number>(UserPreferredLanguageID);
  }

  public set userPreferredLanguageId(value: number) {
    this.setValue(UserPreferredLanguageID, value);
  }

  // isDirectDebitPayment
  public get isDirectDebitPayment(): boolean {
    return this.getValue<boolean>(IsDirectDebitPayment);
  }

  public set isDirectDebitPayment(value: boolean) {
    this.setValue(IsDirectDebitPayment, value);
  }
  // paymentsDueNthDayNextMonth
  public get paymentsDueNthDayNextMonth(): string {
    return this.getValue<string>(PaymentsDueNthDayNextMonth);
  }

  public set paymentsDueNthDayNextMonth(value: string) {
    this.setValue(PaymentsDueNthDayNextMonth, value);
  }
  // DisableMyAccountCreationForB2CKey
  public get disableMyAccountCreationB2C(): boolean {
    return this.getValue<boolean>(DisableMyAccountCreationForB2CKey);
  }
  public set disableMyAccountCreationB2C(value: boolean) {
    this.setValue(DisableMyAccountCreationForB2CKey, value);
  }

  // DDOffSetPeriod
  public get ddOffSetPeriod(): number {
    return this.getValue<number>(DDOffSetPeriod);
  }

  public set ddOffSetPeriod(value: number) {
    this.setValue(DDOffSetPeriod, value);
  }
  // areaCode
  public get areaCode(): string {
    return this.getValue<string>(AreaCode);
  }

  public set areaCode(value: string) {
    this.setValue(AreaCode, value);
  }

  // CompressedViewOfPreviousAnswers
  public get compressedViewOfPreviousAnswers(): boolean {
    return this.getValue<boolean>(CompressedViewOfPreviousAnswers);
  }

  public set compressedViewOfPreviousAnswers(value: boolean) {
    this.setValue(CompressedViewOfPreviousAnswers, value);
  }

  public get displayGrossPremium(): boolean {
    return this.getValue<boolean>(DisplayGrossPremium);
  }

  public set displayGrossPremium(value: boolean) {
    this.setValue(DisplayGrossPremium, value);
  }

  // UserDetailModel
  public get userDetailModel(): UserProfile {
    return this.getValue<UserProfile>(UserDetailModelKey);
  }

  public set userDetailModel(value: UserProfile) {
    this.setValue(UserDetailModelKey, value);
  }


  // UserDetailsPageTitle
  public get userDetailsPageTitle(): string {
    return this.getValue<string>(UserDetailsPageTitleKey);
  }

  public set userDetailsPageTitle(value: string) {
    this.setValue(UserDetailsPageTitleKey, value);
  }

  // UserAddressModel
  public get userAddressModel(): Address[] {
    return this.getValue<Address[]>(UserAddressModelKey);
  }

  public set userAddressModel(value: Address[]) {
    this.setValue(UserAddressModelKey, value);
  }

  // AddressCapturePageTitle
  public get addressCapturePageTitle(): string {
    return this.getValue<string>(AddressCapturePageTitleKey);
  }

  public set addressCapturePageTitle(value: string) {
    this.setValue(AddressCapturePageTitleKey, value);
  }


  // UserConsentModel
  public get userConsentModel(): CustomerConsent[] {
    return this.getValue<CustomerConsent[]>(UserConsentModelKey);
  }

  public set userConsentModel(value: CustomerConsent[]) {
    this.setValue(UserConsentModelKey, value);
  }

  // ConsentCapturePageTitle
  public get consentCapturePageTitle(): string {
    return this.getValue<string>(ConsentCapturePageTitleKey);
  }

  public set consentCapturePageTitle(value: string) {
    this.setValue(ConsentCapturePageTitleKey, value);
  }

  public get userDataShareConsentModel(): DataShareConsent {
    return this.getValue<DataShareConsent>(UserDataShareConsentModelKey);
  }

  public set userDataShareConsentModel(value: DataShareConsent) {
    this.setValue(UserDataShareConsentModelKey, value);
  }

  // enable V2 Sales home

  public get enableSalesV2Home(): boolean {
    return this.getValue<boolean>(EnableSalesV2Home);
  }

  public set enableSalesV2Home(value: boolean) {
    this.setValue(EnableSalesV2Home, value);
  }


  public get isESignatureEnabled(): boolean {
    return this.getValue<boolean>(IsESignatureEnabled);
  }

  public set isESignatureEnabled(value: boolean) {
    this.setValue(IsESignatureEnabled, value);
  }

  public get PolicyIdToBeCloned(): number {
    return this.getValue<number>(BaseConstantService.KEY_PolicyIdToBeCloned);
  }

  public set PolicyIdToBeCloned(value: number) {
    this.setValue(BaseConstantService.KEY_PolicyIdToBeCloned, value);
  }

  public get PrimaryQuestionData(): any {
    const primaryQuestion = this.getValue<any>(BaseConstantService.KEY_PrimaryQuestionData);
    return JSON.parse(primaryQuestion);
  }

  public set PrimaryQuestionData(value: any) {
    const primaryQuestion = JSON.stringify(value);
    this.setValue(BaseConstantService.KEY_PrimaryQuestionData, primaryQuestion);
  }

  public set setProductTemplateDetails(value: ProductTemplate) {
    this.ProductDetails = value;
  }

  public get getProductTemplateDetails(): ProductTemplate {
    return this.ProductDetails;
  }
  public set setCoverageTermChangedValue(value: boolean) {
    this.coverageTermChanged = value;
  }

  public get getCoverageTermChangedValue(): boolean {
    return this.coverageTermChanged;
  }

  public set isPrimaryCoverSelected(value: boolean) {
    this.primaryCoverSelected = value;
  }

  public get isPrimaryCoverSelected(): boolean {
    return this.primaryCoverSelected;
  }

  public get childProductId(): number {
    return this.getValue<number>(ChildProductId);
  }

  public set childProductId(value: number) {
    this.setValue(ChildProductId, value);
  }

  public get uploadDocuments(): UploadDocuments {
    return this.getValue<UploadDocuments>(UploadDocumentsKey);
  }

  public set uploadDocuments(value: UploadDocuments) {
    this.setValue(UploadDocumentsKey, value);
  }

  public get currentDocumentCount(): number {
    return this.getValue<number>(CurrentDocumentCountKey);
  }

  public set currentDocumentCount(value: number) {
    this.setValue(CurrentDocumentCountKey, value);
  }

  public set setUniqueSessionId(value: string) {
    this.UniquesSessionId = value;
  }

  public get getUniqueSessionId(): string {
    return this.UniquesSessionId;
  }

  public get getPolicyReferenceId(): string {

    return this.getValue(PolicyReference)
  }
  public set setPolicyReferenceId(value: string) {

    this.setValue(PolicyReference, value);
  }

  public get getApplicationFormId(): string {
    return this.getValue<string>(ApplicationFormId);
  }

  public set setApplicationFormId(value: string) {
    this.setValue(ApplicationFormId, value);
  }

  public get enableProductRestrictionOnSubgroups(): boolean {
    return this.getValue<boolean>(EnableProductRestrictionOnSubgroups);
  }

  public set enableProductRestrictionOnSubgroups(value: boolean) {
    this.setValue(EnableProductRestrictionOnSubgroups, value);
  }

  public set setSelectedOptionalCoversIntentCode(value: any[]) {
    this.selectedOptionalCoversIntentCode = value;
  }

  public get getSelectedOptionalCoversIntentCode(): any[] {
    return this.selectedOptionalCoversIntentCode;
  }

  public set setOptionalViewCoversIntentCode(value: string) {
    this.OptionalCoversIntentCode = value;
  }
  public get getOptionalViewCoversIntentCode(): string {
    return this.OptionalCoversIntentCode;
  }

  public set setPreSelectedAndMandatoryCovers(value: any[]) {
    this.preSelectedAndMandatoryCoversList = value;
  }

  public get getPreSelectedAndMandatoryCovers(): any[] {
    return this.preSelectedAndMandatoryCoversList;
  }
  public set setClearSelection(value: boolean) {
    this.ClearSelectionTriggerd = value;
  }
  public get getClearSelection(): boolean {
    return this.ClearSelectionTriggerd;
  }

  public get coverageNameAndCoverageTermSelection(): string {
    return this.getValue<string>(CoverageNameAndCoverageTermSelection);
  }

  public set coverageNameAndCoverageTermSelection(value: string) {

    const preSelectionList: Array<IPreSelectMandatoryCover> = [];

    if (value !== null && value !== undefined && value !== '') {
      const coversList = JSON.parse(value);
      let i = 0;

      const secondaryCoverList: Array<ISecondaryCover> = [];

      const primaryCover: IPreSelectMandatoryCover = {
        coverageCode: '',
        coverageTerm: '',
        secondaryCover: []
      };

      coversList?.forEach(c => {
        const secondaryCover: ISecondaryCover = {
          coverageCode: '',
          coverageTerm: '',
        };

        if (i === 0) {// *** first coverage code will be always  the'Primary Cover' ***//
          primaryCover.coverageCode = c.CoverageCode;
          primaryCover.coverageTerm = c.CoverageTerm;
        } else {
          // *** rest of the coverages will be considered as optional cover of the above primary cover ***//
          secondaryCover.coverageCode = c.CoverageCode;
          secondaryCover.coverageTerm = c.CoverageTerm;
          secondaryCoverList.push(secondaryCover);
        }

        i = i + 1;
      });

      primaryCover.secondaryCover = secondaryCoverList;
      preSelectionList.push(primaryCover);
      value = JSON.stringify(preSelectionList);
    }

    this.setValue(CoverageNameAndCoverageTermSelection, value);
  }

  public get getPageId(): any {
    return this.getValue<any>(PageId);
  }

  public set setPageId(value: any) {
    this.setValue(PageId, value);
  }
}
