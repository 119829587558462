import { Component, OnInit } from '@angular/core';
import { ButtonBaseDirective } from '../button-base';

@Component({
  selector: 'lib-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss']
})
export class SecondaryButtonComponent extends ButtonBaseDirective implements OnInit {
  ngOnInit() {
  }
}
