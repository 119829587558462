<ng-container>
    <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription"
        [QnSubDescription]="qnData.qSubDescription"></lib-data-capture-heading>
    <section class="optionSetContainer" id="optionSetContainer">
        <ng-container *ngFor="let optionSet of qnData.answerSets; index as i">
            <div id="optionSet" class="optionSet" (click)="submitRadioDataCapture(optionSet);">
                <div class="imageCaptionSet" id="imageCaptionSet">
                    <div class="imgElement" id="imgElement">
                        <img id="imageControl" class="mx-auto mb-3" [src]="'data:image/png;base64,'+tempImageVal" />
                    </div>
                    <div>
                        <span id="spanName" [innerHtml]="optionSet.name | safeHtml"></span>
                    </div>
                </div>
            </div>
        </ng-container>
    </section>
</ng-container>