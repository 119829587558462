import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { endPoints } from '../../shared/core/app.config';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { SessionStorageService } from 'angular-web-storage';


const routes = {
  gettoken: endPoints.apiUrl + endPoints.endPointName.getToken,
  checktoken: endPoints.apiUrl + endPoints.endPointName.checkToken,
  getcustomeridentifier: endPoints.apiUrl + endPoints.endPointName.getcustomeridentifier,
  getassentlyurl: endPoints.apiUrl + endPoints.endPointName.getAssentlyUrl
};
@Injectable({
  providedIn: 'root'
})
export class AssentlyService {

  constructor(
    protected commonHttpService: CommonHttpService,
    protected sessionStorageSerice: SessionStorageService
  ) { }
  gettoken(): Promise<any> {
    try {
      const apiUrl = `${routes.gettoken}`;
      return lastValueFrom<any>(this.commonHttpService.httpGetServiceWithBearer(apiUrl));
    } catch (e) {
      console.error(e);
    }
  }
  DecryptResult(requestBody: any): Promise<any> {
    try {
      const apiUrl = `${routes.checktoken}`;
      return lastValueFrom<any>(this.commonHttpService.httpPostService(apiUrl, requestBody));
    } catch (e) {
      console.error(e);
    }
  }

  getCustomerIdentifier(cusotmerIdentifier: string): Promise<any> {
    try {
      const apiUrl = `${routes.getcustomeridentifier}`;
      const inputData = {
        ssn: cusotmerIdentifier,
        sponsorId: this.sessionStorageSerice.get('SponsorId')
      };
      return lastValueFrom(this.commonHttpService.httpPostService(apiUrl, inputData));
    } catch (e) {
      console.error(e);
    }
  }

  getassentlyurl(): Promise<any> {
    try {
      const apiUrl = `${routes.getassentlyurl}`;
      return lastValueFrom(this.commonHttpService.httpGetServiceWithBearer(apiUrl));
    } catch (e) {
      console.error(e);
    }
  }

}
