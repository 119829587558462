import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ChangeDetectorRef } from '@angular/core';
import { IQuoteSummary } from '../../shared/models';
import { TranslateService } from '@ngx-translate/core';
import { ConstantService } from '../../services/constant/constant.service';
import moment from 'moment';
import { QuotationService } from '../../services/quotation/quotation.service';
import { SessionStorageService } from 'angular-web-storage';

const showCostSummaryLabel = 'Shared.lblShowCostSummary';
const hideCostSummaryLabel = 'Shared.lblHideCostSummary';
const totalInstalmentsLabel = 'Shared.lblTotalInstalments';
const paymentScheduleLabel = 'Shared.lblPaymentSchedule';
const dueDateLabel = 'Shared.lblDueDate';
const monthlyAmountLabel = 'Shared.lblMonthlyAmount';
const firstPaymentRoundingLabel = 'Shared.lblFirstPaymentRounding';
const PaymentTermValue = 'PaymentTermSelectDuringQuote';

@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.scss']
})
export class PriceDetailsComponent implements OnInit {

  @Input() quoteSummary: IQuoteSummary;
  @Input() isApproveDeclineOnly: boolean = false;
  isTaxDataAvailable = false;
  isPolicyDataAvailable = false;
  showCostSummaryText: string;
  hideCostSummaryText: string;
  panelOpenState = false;
  isSpinner: boolean = false;
  currencySymbol: string;
  currencySymbolAfterMoney: boolean;
  isDisplayMonthlyPremium: boolean;
  totalTaxAndCharges: number;
  isB2B: boolean = false;

  constructor(
    private translate: TranslateService,
    private constantService: ConstantService,
    private quotationService: QuotationService,
    private cdr: ChangeDetectorRef,
    public sessionStorage: SessionStorageService,
  ) { 
    this.isB2B = this.sessionStorage.get('isb2b');
  }

  @Output() overridedNetPremium = new EventEmitter();
  totalInstalmentsText: string;
  totalInstalmentsTextClone: string;
  paymentScheduleText: string;
  dueDateText: string;
  monthlyAmountText: string;
  firstPaymentRoundingText: string;

  ngOnInit() {
    this.applyTranslations();

    if (this.quoteSummary && this.quoteSummary.ratingBindingInformations) {
      this.isTaxDataAvailable = this.quoteSummary.ratingBindingInformations.taxSectionDisplay &&
        this.quoteSummary.ratingBindingInformations.taxSectionDisplay.length > 0;

      this.isPolicyDataAvailable = this.quoteSummary.ratingBindingInformations.policySectionDisplay &&
        this.quoteSummary.ratingBindingInformations.policySectionDisplay.length > 0;
      this.totalTaxAndCharges = this.quoteSummary.ratingBindingInformations.taxesandCharges;

      if (this.quoteSummary?.paymentTerms === PaymentTermValue && this.quotationService.GetPaymentFrequencyIntentCode !== undefined) {
        this.quotationService.GetPaymentFrequencyIntentCode.subscribe((data: string) => {
          this.isDisplayMonthlyPremium = data === (this.constantService.PaymentFrequencyMonthlyPayment) 
          ? true : false || (data === this.constantService.PaymentFrequencyInstallmentPayment) ? true : false;
        });
      }
    }
    if (this.quoteSummary?.paymentResponse !== null &&
        this.quoteSummary?.paymentResponse !== undefined) {
        this.quoteSummary?.paymentResponse?.payments?.forEach(p => {
        if (moment(p.dueDate).isValid()) {
          p.dueDate = moment(p.dueDate).format('DD/MM/YYYY');
        }
      });
    }
    this.currencySymbol = this.quoteSummary?.currencySymbol;
    this.currencySymbolAfterMoney = this.quoteSummary?.currencySymbolAfterMoney;
    this.isDisplayMonthlyPremium = (this.quoteSummary?.selectedPaymentFrequencyIntentCode ===
                                   this.constantService.PaymentFrequencyMonthlyPayment) || 
                                   (this.quoteSummary?.selectedPaymentFrequencyIntentCode ===
                                   this.constantService.PaymentFrequencyInstallmentPayment) ? true : false;

    this.quotationService.GetRecalculateClickedSubject.subscribe((value) => {
      if (value) {
        this.isSpinner = true;
        this.cdr.detectChanges();
      }
    });
  }

  ngOnChanges() {
    this.isSpinner = false;
    if (this.isDisplayMonthlyPremium) {
      if (this.quoteSummary?.paymentResponse !== null &&
        this.quoteSummary?.paymentResponse !== undefined && this.quoteSummary?.paymentResponse?.payments !== undefined &&
        this.totalInstalmentsTextClone && this.totalInstalmentsTextClone.includes("{0}")) {
        this.totalInstalmentsText
          = this.totalInstalmentsTextClone.replace("{0}", this.quoteSummary?.paymentResponse?.payments?.length?.toString());
      }
    }
    this.cdr.detectChanges();
  }

  onPanelClick(val: string) {
    this.isDisplayMonthlyPremium = val === (this.constantService.PaymentFrequencyMonthlyPayment) ? true : false 
    || (val === this.constantService.PaymentFrequencyInstallmentPayment) ? true : false;
  }

  private applyTranslations() {
    this.translate.get([showCostSummaryLabel, hideCostSummaryLabel,
      totalInstalmentsLabel, paymentScheduleLabel, dueDateLabel, monthlyAmountLabel, firstPaymentRoundingLabel]).subscribe((res) => {
      this.showCostSummaryText = res[showCostSummaryLabel];
      this.hideCostSummaryText = res[hideCostSummaryLabel];
      this.totalInstalmentsText = res[totalInstalmentsLabel];
      this.totalInstalmentsTextClone  = res[totalInstalmentsLabel];
      if (this.quoteSummary?.paymentResponse !== null &&
          this.quoteSummary?.paymentResponse !== undefined && this.quoteSummary?.paymentResponse?.payments!==undefined &&
          this.totalInstalmentsText.includes("{0}")) {
        this.totalInstalmentsText 
        = this.totalInstalmentsText.replace("{0}", this.quoteSummary?.paymentResponse?.payments?.length?.toString());
       }
      this.paymentScheduleText = res[paymentScheduleLabel];
      this.dueDateText = res[dueDateLabel];
      this.monthlyAmountText = res[monthlyAmountLabel];
      this.firstPaymentRoundingText = res[firstPaymentRoundingLabel];
    });
  }
}
