import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from 'projects/wtw-ui-components/src/lib/services/layout/layout.service';
import { IContentSection } from '../../../models/content-section.model';

@Component({
  selector: 'lib-content-section',
  templateUrl: './content-section.component.html',
  styleUrls: ['./content-section.component.scss']
})
export class ContentSectionComponent implements OnInit {

  public isMobileView: boolean;
  public isTabletView: boolean;
  public isDesktopView: boolean;
  @Input() contentSectionObj: IContentSection;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
      // ----- Checking screen resolution by angular layout/breakpoints
      this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobileView = this.layoutService.isSmallView();
      this.isDesktopView = this.layoutService.isLargeView();
    });
  }

}
