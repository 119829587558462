import { Question } from '.';

export class QuestionAnsweredEventArgs {
  question: Question;
  finalQuestion: boolean;

  constructor(question: Question, finalQuestion: boolean = false) {
    this.question = question;
    this.finalQuestion = finalQuestion;
  }
}
