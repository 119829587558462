<section class="strapLineSection" [ngStyle]="{'background-image' : 'url('+strapLineBackgroundImage+')'}"
 *ngIf="productStraplineData">
    <div class="strapline-container" id="divStrapLine">
      <h1 id="straplineHeader" class="header-desktop screen-header strapline-header" [ngClass]="{'desktop-header-size': isDesktop,
                          'tablet-header-size': isTablet,
                          'mobile-header-size': isMobile}" *ngIf="productStraplineData.straplineHeaderText" [innerHTML]="productStraplineData.straplineHeaderText">
      </h1>
      <p id="straplineText" class="strapline-text"  *ngIf="productStraplineData.straplineDescription" [innerHTML]="productStraplineData.straplineDescription | safeHtml"></p>
    </div>
  
    <div [ngClass]="{'primaryInput' : isSticky}" id="startJourneyContainer" *ngIf="showQuote">
      <div class="btn-container">
        <lib-primary-button id="btnStartQuote" [text]="productStraplineData.straplineButton" (clicked)="startQuoteFromStrapline()"></lib-primary-button>
      </div>
    </div>
  </section>
  