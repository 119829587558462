import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { endPoints } from '../../shared/core/app.config';
import { ExternalValidationRequest, ExternalValidationResponse } from '../../shared/models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExternalValidationService {
    private apiUrl = endPoints.apiUrl;
    private getExternalValidationStatusUrl = this.apiUrl + endPoints.endPointName.getValidationStatus;

    constructor(private commonHttpService: CommonHttpService) {
    }

    getExternalValidationStatus(request: ExternalValidationRequest): Observable<ExternalValidationResponse> {
      return this.commonHttpService.httpPostService(this.getExternalValidationStatusUrl, request);
    }
  }
