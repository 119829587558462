import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ButtonToggle } from '../../../models/button-toggle.model';
import { LayoutService } from '../../../../services/layout/layout.service';

@Component({
  selector: 'lib-button-toggle',
  templateUrl: './button-toggle.component.html',
  styleUrls: ['./button-toggle.component.scss']
})
export class ButtonToggleComponent implements OnInit {
  @Input() buttonLabels: ButtonToggle[];
  @Input() isExcluded: boolean;
  selectedValue: string;
  @Output() CoverageNameClicked = new EventEmitter();
  isMobile: boolean;
  isCustomHeight: boolean;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.checkDeviceWidth();
    this.buttonLabels?.forEach((button) => {
      if (button.isSelected) {
        this.selectedValue = button.intentCode
      }
    })
    this.addCustomCss();
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
    });
  }

  onValueChange(event, selectedValue, previousValue) {
    if (selectedValue.intentCode === previousValue) {
      // To stop triggering of already selected option on each event
      return false;
    }
    this.selectedValue = selectedValue.intentCode;
    event['previousValue'] = previousValue;
    this.CoverageNameClicked.emit(event);
  }
  addCustomCss() {
    const isTextExceed = this.buttonLabels.find(tile => tile.label.length > 25);
    if (this.buttonLabels[0].groupId > 0 && isTextExceed) {
      this.isCustomHeight = true;
    } else {
      this.isCustomHeight = false;
    }
  }
}
