import { Injectable } from '@angular/core';
import { IAppConfig, IConfig } from '../../shared/models';
import { CommonHttpService } from '../common-http/common-http.service';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  private serviceConfig: IConfig = null;

  constructor(private http: CommonHttpService) { }

  // ---- returning the confirguarion data object which get from below function api
  getServiceConfigData() {
    return this.serviceConfig;
  }

  // ----- This is to load configuration data(insyance, tenantId, clientId etc) from config controlls
  loadServiceConfigData(config: IAppConfig): Promise<any> {
    const promise: Promise<any> = new Promise((resolve: any) => {
      this.http.httpGetService(config.configApiEndPoints.GetConfigurationUrl)
        .subscribe(srvConfig => {
          this.serviceConfig = srvConfig as IConfig;
          resolve(true);
        });
    });
    return promise;
  }
}
