<div class="mb-4 mx-3">    
    <form *ngIf="isReady" class="mt-3 px-md-4 pl-0 mx-md-4" [formGroup]="contactPreferencesForm" id="contactPreferencesForm">
        <div class="row mb-4">
            <div class="col-12">
                {{contactPreferences.pageInfo.subheadingText}}
            </div>            
        </div>
        <ng-container *ngFor="let contactObj of contactPreferences.contactTypeList">            
            <div class="row mt-2">
                <div class="col-12 d-flex align-items-center">
                    <lib-radio-group [field]="contactObj.contact" [form]="contactPreferencesForm" class="w-100">
                    </lib-radio-group>
                </div>
            </div>            
        </ng-container>
        <div class="row my-4">
            <div class="col-12">
                {{contactPreferences.pageInfo.note}}
            </div>            
        </div>
        <div class="row d-flex justify-content-center button-align-edit mt-2" id="edit" *ngIf="!contactPreferences.isEditMode">
            <div class="col-12 col-md-5 my-2" id="divEditButtonContainer">
                <lib-primary-button id="btnEdit" [text]="contactPreferences.pageInfo.btnEditText"
                (clicked)="onClickEvent('edit')">
                </lib-primary-button>
            </div>
        </div>
        <div class="row d-flex justify-content-center button-align mt-2" id="saveCancel" *ngIf="contactPreferences.isEditMode">
            <div class="col-6 col-md-5 my-2" id="divSaveButtonContainer">
                <lib-primary-button id="btnSave" [text]="contactPreferences.pageInfo.btnSaveText"
                (clicked)="onClickEvent('save')"></lib-primary-button>
            </div>
            <div class="col-6 col-md-5 my-2" id="divCancelButtonContainer">
                    <lib-primary-button id="btnCancel" [text]="contactPreferences.pageInfo.btnCancelText"
                    (clicked)="onClickEvent('cancel')">
                    </lib-primary-button>
            </div>
        </div>
    </form>
</div>