<header>
  <section class="header-bar-container" id="secHeaderBarContainer" [ngClass]="getClass(iconName)">
    <div class="icon-container" *ngIf="iconName" id="divIconContainer">
      <lib-icon-button id="btnIcon" [iconName]="iconName" (click)="clickIconEmit()"></lib-icon-button>
    </div>
    <div id="btnLinkLabel" class="linkLabel" *ngIf="linkLabel && !isB2BFlow && !allowCustomerIdentification" (click)="clickLabelEmit()">
      <mat-icon *ngIf="iconNearbyLink">{{iconNearbyLink}}</mat-icon>&nbsp; {{linkLabel}}
    </div>
    <div class="close-btn-wrappers" *ngIf="isB2BFlow">
      <lib-login-register-logout class="label-center" [loginRegisterText]="loginRegisterText">
      </lib-login-register-logout>
      <div id="btnClose">
        <button class="btn btn-md btn-block btn-top-bar" (click)="closeCurrentTab()">
          {{closeButtonText}}
        </button>
      </div>
    </div>
  </section>
</header>
