import { EventEmitter, Output } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormGroupControlService } from 'projects/wtw-ui-components/src/lib/services/form-group/form-group.service';
import { FormFieldBase } from '../../../../form-field/form-field-base';
import { LanguagePreference } from '../../../../models/your-profile.model';


@Component({
  selector: 'lib-lang-preference',
  templateUrl: './lang-preference.component.html',
  styleUrls: ['./lang-preference.component.scss']
})

export class LangPreferenceComponent implements OnInit {

  @Input() languagePreference: LanguagePreference;
  @Output() langPreferencesEvent = new EventEmitter();

  languagePreferenceForm: FormGroup;
  field: FormFieldBase<string>[] = [];
  isReady = false;

  constructor(private readonly formGroupControlService: FormGroupControlService
              ) { }

  ngOnInit(): void {
    this.buildFormFields();
  }

  async buildFormFields() {

    const formFields: FormFieldBase<string>[] = [];
    for (const [key, value] of Object.entries(this.languagePreference)) {
      if (key === 'currentLanguage') {
        formFields.push(value);
      }
    }

    this.formGroupControlService.formFieldswithOrder(formFields).subscribe(results => {
      this.field = results;
      this.languagePreferenceForm = new FormGroup(
        this.formGroupControlService.toFormControlArray(results)
      );

      this.isReady = true;
    });
  }

  onClickEvent(eventType: string) {
    this.langPreferencesEvent.emit(eventType);
  }

}

