import { Component, OnInit, Input } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from '../bottomsheet/bottomsheet.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { LayoutService } from '../../../services/layout/layout.service';
import { FooterDataItems } from '../../models';

@Component({
  selector: 'lib-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {
  public isSmallView: boolean;
  @Input() footerLinksAndContent: FooterDataItems;
  constructor(public bottomSheet: MatBottomSheet, public layoutService: LayoutService) { }

  ngOnInit() {
    this.checkDevicewidth();
  }

  checkDevicewidth() {
    // ----- Checking screen resolution by angular layout/breakpoints
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });

  }
  // ---- Open the bottom sheet to display privacy policy, terms and conditions links
  openBottomSheet(obj): void {
    const overlayPanleClass = obj.overlayPanelClass || 'fullWidthBottomSheet';
    if (obj.openTo === 'bottomsheet' && obj.key !== 'contactUs') {
      const bottomSheetRef = this.bottomSheet.open(BottomsheetComponent, {
        panelClass: overlayPanleClass,
        data: obj
      });
      bottomSheetRef.afterDismissed().subscribe(result => {
      });

    } else if (obj.openTo === 'bottomsheet' && obj.key === 'contactUs') {
      const contactUsBottomSheetRef = this.bottomSheet.open(ContactUsComponent, {
        panelClass: overlayPanleClass,
        data: obj
      });
      contactUsBottomSheetRef.afterDismissed().subscribe(result => {
      });
    }
  }


}
