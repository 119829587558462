import { Guid } from 'guid-typescript';
import { UserProfile, IterationData } from '../models';

export class ExternalValidationRequest {
  sponsorId: number;
  data: { [id: number]: string; };
  isGuestPurchase: boolean;
  isSSOSAML: boolean;
  isB2BFlow: boolean;
  appName?: string;
}

export class ExternalValidationResponse {
  success: boolean;
  message: string;
  retryRequest: boolean;
  iterationData: IterationData;
  tokenGenerationRedirectUrl: string;
  userProfileModel: UserProfile;
  extendedProperty: any;
  b2BData: B2BData;
}

export class B2BData {// *** To hold the Backoffice user details ***//
  b2BUserName: string;
  b2BEmail: string;
  orderId: string;
  policyId: number;
  customerId: number;// *** EndCustomer ID ***//
  customerName: string;
  customerEmail: string;
  quoteReferenceID: string;
  productTemplateID: number;
  isB2cSingleCarrierMultiQuoteEnabled: boolean
}
