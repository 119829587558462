import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, lastValueFrom } from 'rxjs';
import { share } from 'rxjs/operators';
import { endPoints } from '../../core/app.config';
import {
  CommonHttpService, UserProfile, QuestionSetService,
  BaseCustomerService, SharedSettingsService, SponsorService, BaseAccountService, BaseConstantService, MCIContext
} from 'wtw-ui-components';
import { SaveUserDetailsComposite, SaveUserDetailsCompositeResponse, CustomerSearch } from '../../shared/models';
import { LookupData } from '../../shared/models';
import { ConsentData } from '../../shared/models/consent-data.model';
import { IDataSharingConsent } from '../../shared/models/datasharing-consent.model';
import { SessionStorageService } from 'angular-web-storage';
import { QuotationService } from '../quotation/quotation.service';
import { AccountService } from '../account/account.service';
import moment from 'moment';
import { Country, CountryCodeLookupData, Province } from '../../shared/models/lookup-data.model';
import { DropdownItem } from 'projects/wtw-ui-components/src/public-api';
import { SalesCommonService } from '../common/sales-common.service';
import { SalesSettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends BaseCustomerService {

  private getCustomerTitleListUrl = endPoints.apiUrl + endPoints.endPointName.getLookupdatabycontext;
  lookupDataObservable: Observable<LookupData[]>;

  private getContactMethodListUrl = endPoints.apiUrl + endPoints.endPointName.getLookupdatabycontext;
  contactMethodObservable: Observable<LookupData[]>;

  private getSponsorConsentpurposeListUrl = endPoints.apiUrl + endPoints.endPointName.getSponsorConsentpurposeList;
  consentDataOberable: Observable<ConsentData[]>;

  private GetDataShareConsentUrl = endPoints.apiUrl + endPoints.endPointName.GetCustomerDataShareConsent;
  DataSharingConsentOberable: Observable<IDataSharingConsent>;

  private GetCustomerSearchResult = endPoints.apiUrl + endPoints.endPointName.getCustomerSearchResult;
  customerDataOberable: Observable<ConsentData[]>;

  private GetCountryByCountryIDUrl = endPoints.apiUrl + endPoints.endPointName.getCountryByCountryID;
  countryDataOberable: Observable<Country>;

  private GetCountryCodeLookupData = endPoints.apiUrl + endPoints.endPointName.getCountryCodeLookupData;
  countryCodeLookupDataObservable: Observable<CountryCodeLookupData[]>;
  private GetCustomerTypeList = endPoints.apiUrl + endPoints.endPointName.getCustomerType;

  private GetProvinceListUrl = endPoints.apiUrl + endPoints.endPointName.getProvinceList;
  getProvinceListObservable: Observable<Province>

  constructor(
    public commonHttpService: CommonHttpService,
    public sessionStorageService: SessionStorageService,
    private quotationService: QuotationService,
    private questionSetService: QuestionSetService,
    public sharedSettingsService: SharedSettingsService,
    public sponsorService: SponsorService,
    public accountService: AccountService,
    public baseAccountService: BaseAccountService,
    public constantService: BaseConstantService,
    public salesCommonService: SalesCommonService,
    public salesSettingService: SalesSettingsService
  ) {
    super(commonHttpService, sessionStorageService, sharedSettingsService, sponsorService, baseAccountService, constantService);
  }

  getCustomerTitleLookup(contextcode: string, sponsorid: number, languagecode: string) {
    const apiURL = this.getCustomerTitleListUrl;
    if (!this.lookupDataObservable) {
      this.lookupDataObservable = this.commonHttpService.httpGetService(`${apiURL}/${contextcode}/${sponsorid}/${languagecode}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        })) // and this tells Rx to keep the Observable alive as long as there are any Subscribers;
    }
    this.lookupDataObservable.subscribe(result => {
      this.sessionStorageService.set('TitleList', result);
    });
    return this.lookupDataObservable;
  }

  getContactMethodLookup(contextcode: string, sponsorid: number, languagecode: string) {
    const apiURL = this.getContactMethodListUrl;
    if (!this.contactMethodObservable) {
      this.contactMethodObservable = this.commonHttpService.httpGetService(`${apiURL}/${contextcode}/${sponsorid}/${languagecode}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }
    this.contactMethodObservable.subscribe(result => {
      this.sessionStorageService.set('ContactMethodList', result);
    });
    return this.contactMethodObservable;
  }

  getCountryCodeLookupData(contextcode: string, sponsorid: number, languagecode: string) {
    const apiURL = this.GetCountryCodeLookupData;
    if (!this.countryCodeLookupDataObservable) {
      this.countryCodeLookupDataObservable = this.commonHttpService.
        httpGetServiceWithBearer(`${apiURL}/${contextcode}/${sponsorid}/${languagecode}`).pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: true,
          }))
    }

    this.countryCodeLookupDataObservable.subscribe(result => {
      this.sessionStorageService.set('AreaCodeList', result);
    });
    return this.countryCodeLookupDataObservable;
  }

  getCountryByCountryID(countryId: number) {
    const apiURL = this.GetCountryByCountryIDUrl;
    if (!this.countryDataOberable) {
      this.countryDataOberable = this.commonHttpService.httpGetServiceWithBearer(`${apiURL}/${countryId}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }

    this.countryDataOberable.subscribe(result => {
      this.sessionStorageService.set('ProductCountry', result);
    });
    return this.countryDataOberable;
  }

  getSponsorConsentpurposeList(sponsorid: number, languageid: number) {
    const apiURL = this.getSponsorConsentpurposeListUrl;
    if (!this.consentDataOberable) {
      this.consentDataOberable = this.commonHttpService.httpGetService(`${apiURL}/${sponsorid}/${languageid}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }
    this.consentDataOberable.subscribe(result => {
      this.sessionStorageService.set('ConsentList', result);
    });
    return this.consentDataOberable;
  }

  getDataSharingConsent(customerId: number) {
    const apiURL = this.GetDataShareConsentUrl;
    if (!this.DataSharingConsentOberable) {
      this.DataSharingConsentOberable = this.commonHttpService.httpGetService(`${apiURL}/${customerId}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }
    this.DataSharingConsentOberable.subscribe(result => {
      this.sessionStorageService.set('DataSharingConsentList', result);
    });
    return this.DataSharingConsentOberable;
  }

  async saveUserDetailsComposite(userProfile: UserProfile, isSkipUserDetailSavePart: boolean = false, isRenewalQuoteSave: boolean = false,
                                 isQuoteEdited: boolean = false, isRenewalPolicySave: boolean = false):
    Promise<SaveUserDetailsCompositeResponse> {
    // Stash inception and expiry dates in session storage.
    if (!isRenewalQuoteSave) {
      this.salesCommonService.stashInceptionExpiryDates();
    }

    if ((userProfile === null || userProfile === undefined) && isRenewalQuoteSave) {
        const user = await lastValueFrom<any>(this.accountService.getUserProfile(true, null, true));
        userProfile = user;
    }

    // isTermsAndConditionsAcceptanceRequiredToQuote is stored using native sessionStorage.
    const termsAndCondFlag = Boolean(sessionStorage.getItem('isTermsAndConditionsAcceptanceRequiredToQuote'));
    let tsAndCsAcceptedDate = null;

    if (termsAndCondFlag && termsAndCondFlag === true) {
      const tsAndCsString = sessionStorage.getItem('termsAndConditionsAccepted');
      if (tsAndCsString && tsAndCsString.length > 0) {
        tsAndCsAcceptedDate = new Date(tsAndCsString);
      }
    }

    // build 'SaveUserDetailsComposite' object.
    const saveUserDetailsComposite = await this.buildUserDetailsCompositeObject(userProfile,
      isSkipUserDetailSavePart, isRenewalQuoteSave, isQuoteEdited, isRenewalPolicySave, tsAndCsAcceptedDate);
    // Make an API call to save user details,quote order and draft policy in system.
    const saveUserDetailsCompositeResponse = await lastValueFrom(this.accountService.saveUserDetailsWithSupplementaryData
      (saveUserDetailsComposite));

    // Update repeating group ID answers with the assigned IDs.
    if (saveUserDetailsCompositeResponse?.repeatingGroupIdQuestionResponses) {
      for (const repeatingGroupIdAnswer of saveUserDetailsCompositeResponse.repeatingGroupIdQuestionResponses) {
        this.questionSetService.answeredQuestions.forEach(question => {
          question.childQuestionSets.forEach(childQuestion => {
            if (childQuestion.qIntentCode === repeatingGroupIdAnswer.key) {
              childQuestion.answerSets[0].name = repeatingGroupIdAnswer.value;
            }
          });
        });
      }
    }

    // Store 'SaveUserDetailsCompositeResponse' object in session storage.
    this.storeUserDetailsResponseToSession(saveUserDetailsCompositeResponse);
    return saveUserDetailsCompositeResponse;
  }

  async saveUserDetailsOnly(userProfile: UserProfile) {
    const isB2BFlow = this.sharedSettingsService.isB2B();
    const b2bEmail = (isB2BFlow === true) ? this.sharedSettingsService.b2bEmail()
      : this.sessionStorageService.get('Email');

    await lastValueFrom(this.accountService.saveUserDetailsWithoutSupplementaryData(userProfile, b2bEmail));
  }

  async CreateQuoteReferralActionWithNotification(userProfile: UserProfile, isSkipUserDetailSavePart: boolean = false,
                                                  isRenewalQuoteSave: boolean = false): Promise<any> {
    // build 'SaveUserDetailsComposite' object.
    const saveUserDetailsComposite = await this.buildUserDetailsCompositeObject(userProfile,
      isSkipUserDetailSavePart, isRenewalQuoteSave);

    // Make an API call to create Referral Action with Notification.
    return this.accountService.createQuoteReferralActionWithNotification(saveUserDetailsComposite).subscribe(() => { });
  }


  private async buildUserDetailsCompositeObject(userProfile: UserProfile, isSkipUserDetailSavePart: boolean, isRenewalQuoteSave: boolean,
                                                isQuoteEdited: boolean = false, isRenewalPolicySave: boolean = false,
                                                termsAndConditionsAcceptedDate: Date = null):
    Promise<SaveUserDetailsComposite> {

    const isB2BFlow = this.sharedSettingsService.isB2B();
    const b2bEmail = (isB2BFlow === true) ? this.sharedSettingsService.b2bEmail()
      : this.sessionStorageService.get('Email');

    const quoteSummary = JSON.parse(this.sessionStorageService.get('quoteSummary'));

    const isEditedQuote = this.sessionStorageService.get('isEditedQuotequestions');
    const reRate = isEditedQuote && isEditedQuote === 'true' ? true: false;

    const useCacheValue = !reRate && !this.sharedSettingsService.isQuoteStatusChangedFromReferred;

    let quoteOrigin = this.sessionStorageService.get('IsRenewalQuote') === true
      ? BaseConstantService.Key_Renewals : BaseConstantService.Key_QuoteOrigin;
    let associatedPolicyId = this.sessionStorageService.get('AssociatedPolicyId')
      ? this.sessionStorageService.get('AssociatedPolicyId') : 0;
    let renewalQuoteOrderId = this.sessionStorageService.get('RenewalQuoteOrderID');

    let quoteExternalReference = this.salesSettingService.getPolicyReferenceId;
    const applicationFormId = this.salesSettingService.getApplicationFormId;
    if (applicationFormId) {
      quoteExternalReference = applicationFormId;
    }

    const renewalQuoteDetails = this.quotationService.renewalQuoteDetails;
    if (renewalQuoteDetails) {
      quoteOrigin = renewalQuoteDetails.quoteOrigin;
      associatedPolicyId = renewalQuoteDetails.associatedPolicyId;
      renewalQuoteOrderId = renewalQuoteDetails.orderId;
    }

    return {
      userProfile,
      sponsorId: this.sessionStorageService.get('SponsorId'),
      sponsorName: this.sessionStorageService.get('SponsorName'),
      languageId: this.sessionStorageService.get('LanguageId'),
      productId: this.sessionStorageService.get('ProductId'),
      inceptionDate: this.sessionStorageService.get('InceptionDate'),
      expiryDate: this.sessionStorageService.get('ExpiryDate'),
      customerId: this.sessionStorageService.get('customerId'),
      renewalQuoteOrderId: renewalQuoteOrderId,
      grossPremium: 0, // *** getting these value in the middleware ***//
      netPremium: 0,
      currencyCode: quoteSummary?.currencyIso4217,
      quoteReferenceId: this.sessionStorageService.get('QuoteReferenceID') ? this.sessionStorageService.get('QuoteReferenceID') : '',
      orderId: this.sessionStorageService.get('OrderId'),
      policyId: this.sessionStorageService.get('PolicyId') ? this.sessionStorageService.get('PolicyId') : 0,
      associatedPolicyId: associatedPolicyId,
      policyNumber: this.sessionStorageService.get('PolicyNumber') ? this.sessionStorageService.get('PolicyNumber') : '',
      createdProductId: this.sessionStorageService.get('CreatedProductId') ? this.sessionStorageService.get('CreatedProductId') : 0,
      answeredQuestionResponse: this.quotationService.getResponseDictionary(this.questionSetService.answeredQuestions),
      selectedCoverageList: this.quotationService.getSelectedCoverageList(),
      isB2cSingleCarrierMultiQuoteEnabled: this.quotationService.IsB2cSingleCarrierMultiQuoteEnabled(),
      isB2cMultiCarrierQuotingEnabled: this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled,
      multiCarrierQuoteSummary: quoteSummary?.multiCarrierQuoteSummary,
      MCIContext: MCIContext.ATPCARRINT,
      isSkipUserDetailSavePart,
      isRenewalQuoteSave,
      QuestionSetsToFilter: this.quotationService.getQuestionSetsToFilter(),
      isB2BFlow: isB2BFlow,
      b2bEmail: b2bEmail,
      quoteExternalReference: quoteExternalReference,
      disableMyAccountCreationB2C: this.salesSettingService.disableMyAccountCreationB2C,
      PaymentFrequencyDDItemID: this.sessionStorageService.get('selectedPaymentFreqValue'),
      policySection: this.quotationService.getPolicySection,
      isEditedQuote: isQuoteEdited,
      DiscountRate: this.salesSettingService.discountRate,
      CoverageNameAndCoverageTermSelectionJson: this.salesSettingService.coverageNameAndCoverageTermSelection ?? '',
      retainPolicyResponse: this.questionSetService.policyQuestionHidden,
      isRenewalPolicySave: isRenewalPolicySave, // || (this.sessionStorageService.get('RenewalPolicyID') > 0),
      renewalPolicyID: this.sessionStorageService.get('RenewalPolicyID') ? this.sessionStorageService.get('RenewalPolicyID') : 0,
      renewalPolicyNumber: this.sessionStorageService.get('RenewalPolicyNumber'),
      renewalProductId: this.sessionStorageService.get('RenewalProductId') ? this.sessionStorageService.get('RenewalProductId') : 0,
      termsAndConditionsAcceptedDate: termsAndConditionsAcceptedDate,
      sessionKey: this.sharedSettingsService.getUserSessionKey(),
      useCacheValue: useCacheValue,
      quoteOrigin: quoteOrigin
    };
  }

  private storeUserDetailsResponseToSession(saveUserDetailsCompositeResponse: SaveUserDetailsCompositeResponse) {

    this.sessionStorageService.set('OrderId', saveUserDetailsCompositeResponse.orderId);
    this.sessionStorageService.set('QuoteReferenceID', saveUserDetailsCompositeResponse.quoteReferenceId);
    if (Number(saveUserDetailsCompositeResponse.policyId) > 0) {
      this.sessionStorageService.set('PolicyId', saveUserDetailsCompositeResponse.policyId);
    }
    this.sessionStorageService.set('PolicyIdForATPDocumentDownload', saveUserDetailsCompositeResponse.policyId);

    if (Number(saveUserDetailsCompositeResponse.policyNumber) > 0) {
      this.sessionStorageService.set('PolicyNumber', saveUserDetailsCompositeResponse.policyNumber);
    }
    if (saveUserDetailsCompositeResponse) {
      if (saveUserDetailsCompositeResponse.userDataResponse?.objectIdentifier) {
        this.sessionStorageService.set('ObjectIdentifier', saveUserDetailsCompositeResponse.userDataResponse.objectIdentifier);
      }
      if (saveUserDetailsCompositeResponse.userDataResponse?.email) {
        this.sessionStorageService.set('Email', saveUserDetailsCompositeResponse.userDataResponse.email);
      }
      if (saveUserDetailsCompositeResponse.productId > 0) {
        this.sessionStorageService.set('CreatedProductId', saveUserDetailsCompositeResponse.productId);
      }
    }
  }

  SearchCustomerResult(customerSearch: CustomerSearch) {
    const apiURL = this.GetCustomerSearchResult;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, customerSearch);
  }
  GetCustomerType() {
    const languageId = this.sessionStorageService.get('LanguageId');
    const apiURL = this.GetCustomerTypeList + '/' + languageId;
    return this.commonHttpService.httpGetServiceWithBearer(apiURL);
  }

  GetProvinceList() {
    const languageId = this.sharedSettingsService.languageId
    const sponsorId = this.sharedSettingsService.sponsorId
    const apiURL = this.GetProvinceListUrl;
    if (!this.getProvinceListObservable) {
      this.getProvinceListObservable = this.commonHttpService.
        httpGetServiceWithBearer(`${apiURL}/${BaseConstantService.Key_Province}/${sponsorId}/${languageId}`).pipe(
          share({
            connector: () => new ReplaySubject(1),
            resetOnError: false,
            resetOnComplete: false,
            resetOnRefCountZero: true,
          })
        );
    }
    return this.getProvinceListObservable;
  }

}
