import { Pipe, PipeTransform } from '@angular/core';
import { SharedSettingsService } from '../../services/settings/settings-base.service'

@Pipe({
  name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
  constructor(private sharedSettingsService: SharedSettingsService) { }
  transform(value): any {

    if (value == null || isNaN(value)) {
      return value;
    }

    value = value.toString().replace(/,/g, '');

    value = '' + value;

    value = parseFloat(value);

    // Check if the parsed value is a valid number
    // if (isNaN(value)) {
    //   return value;
    // }

    value = value.toFixed(2);

   // In client we consider product currency code or culture name for localization so applying same here as well.
    const languageCode = this.sharedSettingsService.cultureName ;
    const currencyCode = this.sharedSettingsService.currencyIso4217;
    const formatedAmount = new Intl.NumberFormat(languageCode, { currency: currencyCode }).format(value);
    return formatedAmount ? formatedAmount : value
  }

}
