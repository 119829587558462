<mat-accordion class="example-headers-align">
    <mat-expansion-panel class="py-2" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="spacing">{{yourProfileData.personalDetails.pageInfo.icon}}</mat-icon><b class="d-flex align-items-center">{{yourProfileData.personalDetails.pageInfo.headingText}}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <lib-personal-details [personalDetails]="yourProfileData.personalDetails"
      (personalDetailsEvent)="personalDetailsEvent($event)"></lib-personal-details>
  
    </mat-expansion-panel>
  
    <mat-expansion-panel class="py-2">
      <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="spacing">{{yourProfileData.manageAddresses.pageInfo.icon}}</mat-icon> <b class="d-flex align-items-center">{{yourProfileData.manageAddresses.pageInfo.headingText}}</b>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <lib-manage-addresses [manageAddresses]="yourProfileData.manageAddresses" 
                            (addressUpdateEvent)="addressUpdateEvent($event)"></lib-manage-addresses>
   
    </mat-expansion-panel>

    <ng-container *ngIf="yourProfileData.languagePreference.isMultiLanguageEnabled">
      <mat-expansion-panel class="py-2" (afterCollapse)="resetLanguagePreferencesForm()">
        <mat-expansion-panel-header>
          <mat-panel-title>
              <mat-icon class="spacing">{{yourProfileData.languagePreference.pageInfo.icon}}</mat-icon><b class="d-flex align-items-center">{{yourProfileData.languagePreference.pageInfo.headingText}}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>        
          <lib-lang-preference [languagePreference]="yourProfileData.languagePreference"  (langPreferencesEvent)="langPreferencesEvent($event)"></lib-lang-preference>        
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="yourProfileData.consentPreferences.isConsentPreferencesEnabled">
      <mat-expansion-panel class="py-2" (afterCollapse)="resetConsentPreferencesForm()">
        <mat-expansion-panel-header>
          <mat-panel-title>  
            <mat-icon class="d-flex justify-content-center align-items-center">
              <img class="icon" [src]="'data:image/png;base64,'+consentLogo" />
            </mat-icon>  
            <b class="d-flex align-items-center">{{yourProfileData.consentPreferences.pageInfo.headingText}}</b>        
          </mat-panel-title>
        </mat-expansion-panel-header>        
          <lib-consent-preferences [consentPreferences]="yourProfileData.consentPreferences" (consentPreferencesEvent)="consentPreferencesEvent($event)"></lib-consent-preferences>        
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="yourProfileData.contactPreferences.isContactPreferencesEnabled">
      <mat-expansion-panel class="py-2" (afterCollapse)="resetContactPreferencesForm()">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-icon class="d-flex justify-content-center align-items-center">
              <img class="icon" [src]="'data:image/png;base64,'+contactLogo" />
            </mat-icon>
            <b class="d-flex align-items-center">{{yourProfileData.contactPreferences.pageInfo.headingText}}</b>
          </mat-panel-title>
        </mat-expansion-panel-header>        
          <lib-contact-preferences [contactPreferences]="yourProfileData.contactPreferences"
          (contactPreferencesEvent)="contactPreferencesEvent($event)"></lib-contact-preferences>        
      </mat-expansion-panel>
    </ng-container>
    </mat-accordion>
