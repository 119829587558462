<ng-container>
    <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription"
        [QnSubDescription]="qSubDescription">
    </lib-data-capture-heading>
    <lib-progress-spinner *ngIf="isLoading" [loadingMessage]="loaderMessage"></lib-progress-spinner>

    <form class="pageForm" id="groupPanelForm" [formGroup]="groupPanelForm" (ngSubmit)="submitGroupPanelAnswer()">
        <ng-container *ngFor="let field of pageFormFields" class="form-row">
            <div [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                [attr.childQIntentCode]="field.childQIntentCode" [attr.parentQIntentCode]="field.parentQIntentCode"
                [attr.aKey]="field.qIntentCode" [attr.iterationIndex]="field?.formFieldIterationIndex"
                [attr.isHidden]="field?.isHidden" class="description-label">
                <label *ngIf="field.descriptionLabel">{{field.descriptionLabel}}</label>
            </div>
            <ng-container [ngSwitch]="field.controlType">
                <ng-container *ngSwitchCase="'datepicker'">
                    <lib-date-picker id="dtDatePicker" [field]="field" [form]="groupPanelForm"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.iterationIndex]="field?.formFieldIterationIndex"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext">
                    </lib-date-picker>
                </ng-container>
                <ng-container *ngSwitchCase="'textbox'">
                    <lib-text-box id="txtbox" [field]=field [form]="groupPanelForm"
                        (valueChange)="onValueChange($event)"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.iterationIndex]="field?.formFieldIterationIndex"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext">
                    </lib-text-box>
                </ng-container>
                <ng-container *ngSwitchCase="'money'">
                    <lib-currency-box id="txtmoney" [field]=field [form]="groupPanelForm"
                        (valueChange)="onValueChange($event)"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.iterationIndex]="field?.formFieldIterationIndex"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext">
                    </lib-currency-box>
                </ng-container>
                <ng-container *ngSwitchCase="'textarea'">
                    <lib-text-area id="textarea" [field]=field [form]="groupPanelForm"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.iterationIndex]="field?.formFieldIterationIndex"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext">
                    </lib-text-area>
                </ng-container>
                <ng-container *ngSwitchCase="'dropdown'">
                    <lib-drop-down id="ddl" [field]="field" [form]="groupPanelForm"
                        (valueChange)="onValueChange($event)"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.iterationIndex]="field?.formFieldIterationIndex"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext">
                    </lib-drop-down>
                    <ng-container>
                        <lib-notification-alert [field]="field"
                            [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                            [attr.pValue]="field.parentOValue"
                            [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                            [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                            [attr.iterationIndex]="field?.formFieldIterationIndex"
                            [attr.qIntentCode]="field.qIntentCode" [attr.isHidden]="field?.isHidden"
                            class="notification-alert"></lib-notification-alert>
                    </ng-container>
                </ng-container>
                <ng-container *ngSwitchCase="'recaptcha'">
                    <lib-recaptcha-v2 id="ddl" [field]="field" [form]="groupPanelForm"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"                      
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext">
                    </lib-recaptcha-v2>
                </ng-container>
                <ng-container *ngSwitchCase="'checkbox'">
                    <lib-check-box id="checkbox" [field]="field" [form]="groupPanelForm"
                        (valueChange)="onValueChange($event)"
                        [ngStyle]="{'display':(field?.isHidden===true ||field?.visibility==='hide') ?'none':'block','margin-left':field?.margin+'px'}"
                        [attr.pValue]="field.parentOValue" [attr.rootParentQIntentCode]="field.rootParentQIntentCode"
                        [attr.childQIntentCode]="field.childQIntentCode"
                        [attr.parentQIntentCode]="field.parentQIntentCode" [attr.aKey]="field.qIntentCode"
                        [attr.iterationIndex]="field?.formFieldIterationIndex"
                        [attr.lookupPQIntentCode]="field.lookupPQIntentCode" [attr.qIntentCode]="field.qIntentCode"
                        [attr.isHidden]="field?.isHidden" [attr.isRepeatingGroupToBeNext]="field?.isRepeatingGroupToBeNext"></lib-check-box>
                </ng-container>
            </ng-container>
        </ng-container>
        <div class="next-btn-container">
            <lib-primary-button id="btnNext" [disabled]="groupPanelForm.invalid" [text]="nextBtn" autofocus>
            </lib-primary-button>
        </div>
    </form>
</ng-container>