

<div [formGroup]="optionListDetailsForm" class="autosearch"  [ngClass]="{'custom-width':field}">
    <mat-form-field id="matFormField" appearance="fill" [hideRequiredMarker]="true" [ngClass]="{'mat-form-field-readonly':field?.disableMatFormField}">
      <mat-select   placeholder={{placeHolderLabel}} #singleSelect 
      id="autosearch" [(ngModel)]="selectedValue"
      (selectionChange)="onSelectionChange($event)"
      formControlName={{controlName}} (click)="$event.stopPropagation()"
      panelClass="myPanelClass" [disabled]="isDisabled" [required]="field?.required" ngDefaultControl>
        <mat-option>
          <ngx-mat-select-search [formControl]="searchControl" ngDefaultControl
          placeholderLabel={{searchLabel}}
          noEntriesFoundLabel={{noRecordsFoundLabel}}></ngx-mat-select-search>
        </mat-option>
  
        <mat-option *ngFor="let option of  filteredOptions | async"  [value]="option.value">
            {{option.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
