export class NestedAccordion {
    productCategory: string;
    productWisePolicyList: ProductWisePolicy[];
}

export class ProductWisePolicy {
    productName: string;
    productIcon: string;
    isEligibleForClaims: boolean;
    policies: Policy[];
}
export class Policy {
    policyNumber: string;
    policyInceptionDate: string;
    policyExpiryDate: string;
    policyTerm: string;
    premium: string;
    policyID: number;
    productTemplateId: number;
    isContinuous: boolean;
}
