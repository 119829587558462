import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AnswerSets, Question } from '../../../models';

@Component({
  selector: 'lib-radio-data-capture',
  templateUrl: './radio-data-capture.component.html',
  styleUrls: ['./radio-data-capture.component.scss']
})
export class RadioDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Output() updateEmitter = new EventEmitter<AnswerSets>();
  tempImageVal = 'iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAAAn0lEQVR4Ae'
    + '3UIQjCQBiG4ZeBxSAmbatm+8J60maygx2MV8SOdrAHO9jrkmA12iwiDOSzDIOqtztBDn'
    + 'bP2/7wxZ//SZmQ8KM+K0rEnjHeuiy4okcFGc7azDmjl3YMqa3FjBP62JYBVglTjsjSjQ0'
    + 'pX4w4oJqVrOnxRkaBHLuwpMMTeWYCHGoaVRmnSxyKQ6EPmarc6RI441nejJ8dn7/FHf0vzMHHySV4AAAAAElFTkSuQmCC';
  constructor() { }

  ngOnInit() {
  }

  submitRadioDataCapture(answeredData) {
    answeredData.isAnswered = true;
    this.updateEmitter.emit();
  }
}
