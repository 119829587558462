import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent implements OnInit {
  @Input() iconName: string;
  @Output() clicked = new EventEmitter();

  ngOnInit() {
  }
  public onClick(): void {
    this.clicked.emit();
  }
}
