import { Injectable } from '@angular/core';

import { ReferenceService } from '../../../services/References/reference.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetLogoResolver  {
  constructor(private referenceService: ReferenceService) {}

  resolve(): Observable<any> {
    const consentLogo = this.referenceService.getLookupUIImagesAsync('Consent_Your_Profile');
    const contactLogo = this.referenceService.getLookupUIImagesAsync('Contact_Your_Profile');
    return forkJoin([consentLogo, contactLogo]).pipe(map((response) => {
      return response.map(value => value[0]);
    }));
  }
}
