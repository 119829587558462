import { Pipe, PipeTransform } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import moment from 'moment';

@Pipe({
  name: 'localDateFormatPipe'
})
export class LocalDateFormatPipe implements PipeTransform {
  constructor(private sessionStorage: SessionStorageService) { }

  transform(date: Date | string): string {
    if (date) {
      const stringToDate = new Date(date);
      let transformedDate;
      let dateFormat = 'DD/MM/YYYY';

      if (this.sessionStorage.get('LocalisedDateFormat')) {
        dateFormat = this.sessionStorage.get('LocalisedDateFormat');
      }
      if (moment(date, 'DD/MM/YYYY', true).isValid()) {
        const newDate = new Date(date);
        transformedDate = moment(newDate, dateFormat, this.sessionStorage.get('LanguageCode'), false);
        return transformedDate.format(dateFormat);
      }
      if (date.toString() !== (moment(date, dateFormat, true).format(dateFormat))) {
        if (moment(stringToDate, dateFormat).isValid()) {

          transformedDate = moment(stringToDate, dateFormat, this.sessionStorage.get('LanguageCode'), false);
        } else {
          transformedDate = moment(transformedDate, dateFormat, this.sessionStorage.get('LanguageCode'), false);
        }
        return transformedDate.format(dateFormat);
      } else {
        return date.toString();
      }

    }
  }
}
