import { Component, OnInit } from '@angular/core';
import { ButtonBaseDirective } from '../button-base';

@Component({
  selector: 'lib-simple-button',
  templateUrl: './simple-button.component.html',
  styleUrls: ['./simple-button.component.scss']
})
export class SimpleButtonComponent extends ButtonBaseDirective implements OnInit {

  ngOnInit() {
  }

}
