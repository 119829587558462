import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ManageAddresses } from '../../../../../shared/models';
import { MatDialog } from '@angular/material/dialog';
import { FormGroupControlService } from '../../../../../services/form-group/form-group.service';
import { FormFieldBase } from '../../../../form-field/form-field-base';
import { FormGroup } from '@angular/forms';
import { AddressModalComponent } from '../address-modal/address-modal.component';
import { TranslateService } from '@ngx-translate/core';

const pageLabels = {
  contactPreferenceAddress: 'AccountDetails.lblContactPreferenceAddress',
  editHeaderLabel: 'AccountDetails.lblEditHeaderLabel',
  editLabel: 'Shared.lblEdit',
  defaultLabel: 'Shared.lblDefault'
};

@Component({
  selector: 'lib-manage-addresses',
  templateUrl: './manage-addresses.component.html',
  styleUrls: ['./manage-addresses.component.scss']
})

export class ManageAddressesComponent implements OnInit {

  @Input() manageAddresses: ManageAddresses;

  pageFormFields: FormFieldBase<string>[] = [];
  addressForm: FormGroup;
  aKey: string;
  aValue: string;
  bKey: string;
  bValue: string;
  pageHeader: string;
  @Output() addressUpdateEvent = new EventEmitter();
  public labels = {
    contactPreferenceAddress: '',
    editHeaderLabel: '',
    editLabel: '',
    defaultLabel: ''
  };

  constructor(public dialog: MatDialog,
              private readonly formGroupControlService: FormGroupControlService,
              private readonly translate: TranslateService) { }


  ngOnInit(): void {
    this.applyTranslation();
    this.buildFormFields();
  }

  async buildFormFields() {

    const formFields: FormFieldBase<string>[] = [];
    for (const [key, value] of Object.entries(this.manageAddresses)) {
      if (key !== 'pageInfo' && key !== 'addressList') {
        formFields.push(value);
      }
    }

    this.formGroupControlService.formFieldswithOrder(formFields).subscribe(results => {
      this.pageFormFields = results;
      this.addressForm = new FormGroup(
        this.formGroupControlService.toFormControlArray(results)
      );
    });
  }

  onEditClick(add: ManageAddresses, type: string): void {

    Object.entries(this.addressForm.controls).forEach(
      ([oKey, oValue]) => {
        this.aKey = oKey;
        Object.entries(add).forEach(
          ([key, value]) => {
            this.bKey = key;
            this.bValue = value;
            if (this.aKey === this.bKey) {
              this.addressForm.controls[this.aKey].setValue(this.bValue);
              // this.manageAddresses[this.aKey].value = add[this.bKey];
            }
            if (this.bKey === 'country') {
              this.addressForm.controls[this.bKey].setValue(add['countryId']);
            }
          });
      }
    );

    this.pageHeader = this.setPageHeader(type);

    const dialogRef = this.dialog.open(AddressModalComponent, {
      width: '830px',
      height: 'auto',
      data: { addForm: this.addressForm, add: this.manageAddresses, typeLabel: this.pageHeader, eventType: type }
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        this.addressUpdateEvent.emit(result); // emit to parent
      });

  }

  onAddClick(type: string): void {
    this.buildFormFields();
    Object.entries(this.addressForm.controls).forEach(
      ([oKey, oValue]) => {
        this.aKey = oKey;
        this.manageAddresses[this.aKey].value = '';
        this.addressForm.controls[this.aKey].setValue('');
      }
    );

    this.pageHeader = this.setPageHeader(type);

    const dialogRef1 = this.dialog.open(AddressModalComponent, {
      width: '830px',
      height: 'auto',
      data: { addForm: this.addressForm, add: this.manageAddresses, typeLabel: this.pageHeader, eventType: type }
    });
    dialogRef1.afterClosed()
      .subscribe(result => {
        this.addressUpdateEvent.emit(result); // emit to parent
      });
  }

  setPageHeader(type: string): string {
    if (type === 'edit') {
      return this.manageAddresses.pageInfo.editHeaderText;
    } else if (type === 'new') {
      return this.manageAddresses.pageInfo.addHeaderText;
    }
  }

  applyTranslation() {
    const { contactPreferenceAddress,
            editHeaderLabel,
            editLabel,
            defaultLabel
    } = pageLabels;

    this.translate.get([contactPreferenceAddress,
      editHeaderLabel, editLabel, defaultLabel])
      .subscribe((res) => {
        this.labels.contactPreferenceAddress = res[contactPreferenceAddress];
        this.labels.editHeaderLabel = res[editHeaderLabel];
        this.labels.editLabel = res[editLabel];
        this.labels.defaultLabel = res[defaultLabel];
      });
  }

}
