import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ByteConversionService {

  constructor() { }

  base64DecodetoByteArray(base64EncodeData: string) {
    const byteArray = new Uint8Array(atob(base64EncodeData).split('').map(char => char.charCodeAt(0)));
    return byteArray;
  }

  base64DecodetoBlobFile(base64EncodeData: string, mimeType: string) {
    const byteArray = new Uint8Array(atob(base64EncodeData).split('').map(char => char.charCodeAt(0)));
    const file = new Blob([byteArray], { type: mimeType });
    return file;
  }
}
