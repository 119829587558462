import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { PolicyDocumentComponent } from './components/policy-document/policy-document.component';
import { WtwUiComponentsModule } from 'wtw-ui-components';
import { QuestionContainerComponent } from './question-set/question-container/question-container.component';
import { QuestionLogicComponent } from './question-set/question-logic/question-logic.component';
import { MciQuestionContainerComponent } from './question-set/mci-question-container/mci-question-container.component';
import { PriceDetailsComponent } from '../quote/price-details/price-details.component';
import { PolicySummaryComponent } from '../quote/price-details/policy-summary/policy-summary.component';
import { TaxSummaryComponent } from '../quote/price-details/tax-summary/tax-summary.component';
import { AutoSearchDropdownComponent } from
 '../../../../wtw-ui-components/src/lib/shared/components/inputs/auto-search-dropdown/auto-search-dropdown.component';
import { AutoSuggestInputComponent } from
 '../../../../wtw-ui-components/src/lib/shared/components/inputs/auto-suggest-input/auto-suggest-input.component';

@NgModule({
    declarations: [
        PolicyDocumentComponent,
        PriceDetailsComponent,
        PolicySummaryComponent,
        TaxSummaryComponent,
        QuestionContainerComponent,
        QuestionLogicComponent,
        MciQuestionContainerComponent,
        AutoSearchDropdownComponent,
        AutoSuggestInputComponent
    ],
    imports: [
        CommonModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
        TranslateModule,
        WtwUiComponentsModule
    ],
    exports: [
        LayoutModule,
        PolicyDocumentComponent,
        PriceDetailsComponent,
        PolicySummaryComponent,
        TaxSummaryComponent,
        WtwUiComponentsModule,
        TranslateModule,
        QuestionContainerComponent,
        QuestionLogicComponent,
        MciQuestionContainerComponent
    ]
})
export class SharedModule { }
