import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AnswerSets, Question } from '../../../models';
import { FormGroup } from '@angular/forms';
import { CheckboxField } from '../../../form-field/checkbox-field';
import { CheckboxFieldMapper } from '../field-mapper-strategy/strategies/checkbox-field-mapper';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';
import { TranslateService } from '@ngx-translate/core';
const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
@Component({
  selector: 'lib-check-box-data-capture',
  templateUrl: './check-box-data-capture.component.html',
  styleUrls: ['./check-box-data-capture.component.scss']
})
export class CheckBoxDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Input() nextBtn: string;
  @Output() updateEmitter = new EventEmitter();
  btnDisableStatus = true;
  checkboxForm: FormGroup;
  field: CheckboxField;
  mandatoryErrorMessageText: string;


  constructor(
    private formGroupService: FormGroupControlService,
    private fieldMapper: CheckboxFieldMapper,
    private translate: TranslateService) { }
 

  ngOnInit() {
    this.btnDisableStatus = this.qnData.qMandatory ? true : false;
    this.applyTranslations();
    this.createFormGroup();
  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, this.mandatoryErrorMessageText);
    this.checkboxForm = this.formGroupService.toFormGroup([this.field]);
  }
  onValueChange(event: MatCheckboxChange) {
    this.field.checked = event.source.checked;
    this.field.isActive = event.checked;
        
  }
 
  mapOptionsToAnswers() {
    // Clear any previous answers.
    this.qnData.answerSets.forEach(as => as.isAnswered = false);
    const answer = this.qnData.answerSets.filter(as => as.name.toLowerCase() ===
      this.checkboxForm.get(this.field.key).value.toString()).pop();  
    if (answer) {
      answer.isAnswered = true;
      }
    this.updateEmitter.emit();
  }

  private applyTranslations() {
    this.translate.get([
      mandatoryErrorMessageLabel]).subscribe((res) => {
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
        
      });
}
}
