export const endPoints = {
  host: window.location.origin,
  baseUrl: `/${window.location.pathname.split('/')[1]}/`,
  apiUrl: 'api/',
  endPointName: {
        configurationEndPoint: 'Configuration',
        b2cLoginAuditLog: 'Account/B2CLoginAuditLog',
        getTermsOfUsebySponsorName: 'Sponsor/GetTermOfUseBySponsorName',
        getSponsorLogoContent: 'Sponsor/GetSponsorLogoContent',
        getSponsorPreferredLanguage: 'Sponsor/GetSponsorPreferredLanguage',
        getSponsorUrl: 'Sponsor/GetSponsorDetails',
        getUIElements: 'Localization/getuielements/',
        getStrapLineUIElements: 'Localization/getStrapLineUIElements',
        getPageContentValues: 'Localization/GetPageContentValues',
        getErrorContent: 'Localization/GetErrorContent',
        getGlobalPrivacyPolicy: 'Reference/GetPrivacyNoticeByLanguageCode/',
        getCookieNotice: 'Reference/GetCookieNoticeByLanguageCode/',
        getUserProfile: 'Account/GetUserProfile',
        getDecryptedIdentifier: 'Customer/GetDecryptedIdentifier',
        getUserName: 'Account/GetUserName',
        yourProfile: 'Account/YourProfile',
        saveNewB2CUser: 'Account/SaveNewB2CUser',
        getTimeZoneInfo: 'Sponsor/GetTimeZoneInfoList',
        getCustomerFieldValidations: 'Sponsor/GetCustomerFieldValidations',
        storeClaimsTemporaryAttachmentsURL: 'Documents/StoreClaimsTemporaryAttachmentsAsync',
        getTempAttachmentDocuments: 'Documents/GetTempAttachmentDocumentsAsync',
        deleteTemporaryAttachments: 'Documents/DeleteTemporaryAttachmentAsync',
        getFile: 'Documents/GetFile',
        downloadDocument: 'Documents/DownloadDocument',
        isCustomerBlocked: 'Account/IsCustomerBlocked',
        isAccountVerified: 'Account/IsAccountVerified',
        saveB2CCustomerPersonalDetails: 'Customer/SaveB2CCustomer',
        saveCustomerAddress: 'Customer/SaveCustomerAddress',
        getCustomerPreferredLanguage: 'Customer/GetCustomerPreferredLanguage',
        getLookupUIImagesAsync: 'Reference/GetLookupUIImagesAsync',
        saveB2CUserConsent: 'Account/SaveB2CUserConsent',
        yourPolicy: 'Claim/YourPolicy',
        yourPolicies: 'Claim/YourPolicies',
        removeDraftClaims: 'Claim/RemoveDraftClaims',
        getMenuByProfile: 'Account/GetB2CMenuByProfile',
        getJavaScript: 'Sponsor/GetB2CJavaScriptAsync',
        getToken: 'Assently/GetInitToken',
        checkToken: 'Assently/ValidateToken',
        getcustomeridentifier: 'Assently/GetCustomerIdentifier',
        getValidationStatus: 'ExternalValidation/GetValidationStatus',
        getCustomer: 'Customer/GetCustomer',
        getCountryCodeLookupData: 'Customer/GetCountryCodeLookupData',
        createPassword: 'Customer/CreatePassword',
        updatecreatePasswordId: 'Customer/UpdateCreateNewPasswordStatus',
        getB2CSalesBackOfficeUsersSession: 'Customer/GetB2CSalesBackOfficeUsersSession',
        getLookUpValues: 'B2CProductTemplate/lookupvalues',
        getClaimLookUpValues: 'B2CProductTemplate/claimlookupvalues',
        getOnDemandDataRequest: 'B2CProductTemplate/ondemanddatarequest',
        getB2CWebsiteBrandingDetails: 'Sponsor/getB2CWebsiteBrandingDetails',
        getAssentlyUrl: 'Assently/GetAssentlyUrl',
        getMasterProduct: 'B2CProductTemplate/GetMasterProduct',
        getCustomerType: 'Customer/GetCustomerTypes',
        renewPolicy: 'Policy/RenewPolicy',
        resumeQuote: 'Policy/ResumeQuote',
        getDateFormatByIdUrl: 'Sponsor/GetDateFormatById',
        getSubGroupIdBySponsorIdUrl: 'Sponsor/GetSubGroupIdBySponsorId',
        getProductNameValidation: 'Sponsor/GetProductNameValidation',
        getCustIdentifier: 'Customer/GetCustomerIdentifier',
        getProductTemplate: 'B2CProductTemplate/getProductTemplate',
        isValidAuthCustomerIdentifier: 'Account/isValidCustomerIdentifier',
        isValidCustomerIdentifier: 'Customer/isValidCustomerIdentifier',
        getSponsorCustomerIdentification: 'Sponsor/GetSponsorCustomerIdentification',
        identifyCustomersPolicy: 'Customer/identifyCustomersPolicy',
        saveUserHistoryLog: 'Customer/SaveUserHistoryLog',
        getUserHistoryByIpAddress: 'Customer/GetUserHistoryByIpAddress',
        getSponsorBrandLogo: 'Reference/GetSponsorBrandLogo',
        getPolicyResponseData: 'Policy/GetPolicyResponseData'
  }
};
export const AppConfig = {
  apiUrl: 'api/',
  configApiEndPoints: {
    GetConfigurationUrl: endPoints.apiUrl + endPoints.endPointName.configurationEndPoint + '/get'
  },
  assetsImagesPath: '/assets/images/'
};

