import { Injectable } from '@angular/core';
import { endPoints } from '../../shared/core/app.config';
import { CommonHttpService } from '../common-http/common-http.service';
import { Observable, finalize, lastValueFrom, of, share, tap } from 'rxjs';
import { IPrivacyNoticeLanguage, ICookieNoticeLanguage, Question } from '../../shared/models';
import { LookUpValuePayload } from '../../shared/models/lookupvalue-payload.model';
import { promise } from 'protractor';


@Injectable({
  providedIn: 'root'
})
export class ReferenceService {

  constructor(private commonHttpService: CommonHttpService) { }

  private getGlobalPrivacyPolicyTextUrl = `${endPoints.apiUrl}${endPoints.endPointName.getGlobalPrivacyPolicy}`;
  private getCookieNoticeTextUrl = `${endPoints.apiUrl}${endPoints.endPointName.getCookieNotice}`;
  private getLookupUIImagesUrl = `${endPoints.apiUrl}${endPoints.endPointName.getLookupUIImagesAsync}`;
  private getLookUpValuesUrl = `${endPoints.apiUrl}${endPoints.endPointName.getLookUpValues}`;
  private getClaimLookUpValuesUrl = `${endPoints.apiUrl}${endPoints.endPointName.getClaimLookUpValues}`;
  private getProductNameValidationUrl = `${endPoints.apiUrl}${endPoints.endPointName.getProductNameValidation}`;
  private globalPrivacyPolicyCache: any;
  private globalPrivacyPolicyCachedObservable: Observable<any>;
  private globalPrivacyPolicyCachedKey: any;
  private cookieNoticeCache: any;
  private cookieNoticeCachedObservable: Observable<any>;
  private cookieNoticeCachedKey: any;
  private getSponsorBrandLogoUrl = `${endPoints.apiUrl}${endPoints.endPointName.getSponsorBrandLogo}`;
  // getGlobalPrivacyPolicyText(languageCode: string): Observable<IPrivacyNoticeLanguage> {
  //   const apiURL = this.getGlobalPrivacyPolicyTextUrl + languageCode;
  //   return this.commonHttpService.httpGetService(apiURL);
  // }

  getGlobalPrivacyPolicyText(languageCode: string): Observable<IPrivacyNoticeLanguage> {
    let observable: Observable<any>;
    const apiURL = this.getGlobalPrivacyPolicyTextUrl + languageCode;
    const requestPayload = languageCode;
    if (!this.globalPrivacyPolicyCachedKey?.includes(languageCode)) {
      this.globalPrivacyPolicyCachedKey = requestPayload;
    }

    if (this.globalPrivacyPolicyCache && this.globalPrivacyPolicyCachedKey?.includes(requestPayload)) {
      observable = of(this.globalPrivacyPolicyCache);
    } else if (this.globalPrivacyPolicyCachedObservable && this.globalPrivacyPolicyCachedKey?.includes(requestPayload)) {
      observable = this.globalPrivacyPolicyCachedObservable;
    } else {
      this.globalPrivacyPolicyCachedObservable = this.commonHttpService.httpGetService(apiURL)
        .pipe(
          tap(res => this.globalPrivacyPolicyCache = res),
          share(),
          finalize(() => this.globalPrivacyPolicyCachedObservable = null)
        );
      observable = this.globalPrivacyPolicyCachedObservable;
    }
    return observable;
  }

  // getCookieNoticeText(languageCode: string): Observable<ICookieNoticeLanguage> {
  //   const apiURL = this.getCookieNoticeTextUrl + languageCode;
  //   return this.commonHttpService.httpGetService(apiURL);
  // }

  getCookieNoticeText(languageCode: string): Observable<ICookieNoticeLanguage> {
    let observable: Observable<any>;
    const apiURL = this.getCookieNoticeTextUrl + languageCode;
    const requestPayload = languageCode;
    if (!this.cookieNoticeCachedKey?.includes(languageCode)) {
      this.cookieNoticeCachedKey = requestPayload;
    }

    if (this.cookieNoticeCache && this.cookieNoticeCachedKey?.includes(requestPayload)) {
      observable = of(this.cookieNoticeCache);
    } else if (this.cookieNoticeCachedObservable && this.cookieNoticeCachedKey?.includes(requestPayload)) {
      observable = this.cookieNoticeCachedObservable;
    } else {
      this.cookieNoticeCachedObservable = this.commonHttpService.httpGetService(apiURL)
        .pipe(
          tap(res => this.cookieNoticeCache = res),
          share(),
          finalize(() => this.cookieNoticeCachedObservable = null)
        );
      observable = this.cookieNoticeCachedObservable;
    }
    return observable;
  }

  getLookupUIImagesAsync(keyText: string): any {
    const apiURL = `${this.getLookupUIImagesUrl}/${keyText}`;
    return this.commonHttpService.httpGetService(apiURL);
  }

  getLookUpValues(requestBody: LookUpValuePayload): Promise<any[]> {
    const apiURL = this.getLookUpValuesUrl;
    return lastValueFrom(this.commonHttpService.httpPostService(apiURL, requestBody));
  }
  getClaimLookUpValues(requestBody: LookUpValuePayload): Promise<any[]> {
    const apiURL = this.getClaimLookUpValuesUrl;
    return lastValueFrom(this.commonHttpService.httpPostService(apiURL, requestBody));
  }

  async getProductNameValidation(sponsorEnglishName: string, productName: string): Promise<any> {
    const apiURL = `${this.getProductNameValidationUrl}/${sponsorEnglishName}/${productName}`;
    return await lastValueFrom(this.commonHttpService.httpGetService(apiURL));
  }

   /**
 * Fetches the sponsor's brand logo and sets it as the favicon of the webpage.
 * If the favicon link element already exists, it updates the href attribute with the logo URL.
 * If the favicon link element does not exist, it creates a new one and sets the appropriate attributes.
 */
  applySponsorLogoAsFavicon(sponsorId, applyWillisBranding: boolean) {
    if (applyWillisBranding) {
      this.setFavicon('favicon.ico');
    } else {
      this.getSponsorBrandLogo(sponsorId).subscribe({
        next: (blob: Blob) => {
          let url;
          if (blob && blob.size > 0) {
            url = window.URL.createObjectURL(blob);
          } else {
            url = 'favicon.ico';
          }
          this.setFavicon(url);
        },
        error: (error) => {
          console.error('Error fetching favicon:', error);
          this.setFavicon('favicon.ico'); // set default favicon in case of error
        }
      });
    }
  }

  setFavicon(url: string) {
    let favicon: HTMLLinkElement = document.querySelector('link[rel="icon"]');
    if (!favicon) {
      favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.type = 'image/x-icon';
      document.head.appendChild(favicon);
    }
    favicon.href = url;
  }

  getSponsorBrandLogo(sponsorId: number): Observable<any[] | Blob> {
    const apiURL =`${this.getSponsorBrandLogoUrl}/${sponsorId}`;
    return this.commonHttpService.httpGetFile(apiURL);
  }

}
