<div *ngIf="data.closeButton">
  <button mat-button class="close-icon" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div id='modalContainer' class="text-center">
  <div class="modelHeadingContainer" id="divHeadingContainer">
    <h1 id="mainHeading" [ngClass]="{'header-desktop': isDesktop}" [innerHtml]="data.headingText"></h1>
  </div>
  <mat-icon id="pageIcon" *ngIf="data.iconName" [ngClass]="{
          'severity-level-information': data.severityLevel === 'Information',
          'severity-level-warning': data.severityLevel === 'Warning',
          'severity-level-error': data.severityLevel === 'Error',
          'severity-level-warning': data.severityLevel === 'Update'}">{{data.iconName}}</mat-icon>
  <div id="divHeading" *ngIf="data.subHeadingText" [innerHtml]="data.subHeadingText" class="heading"></div>

  <div id="divSubHeadingAdditional" *ngIf="data.subHeadingAdditionalText" [innerHtml]="data.subHeadingAdditionalText"
    class="sub-headingAdditional"></div>

  <div *ngIf="data.secondsCount > 0" class="highlight-text">{{countDownValue}} {{data.secondsText}} </div>
  <div id="divSubHeading" *ngIf="data.additionalText" [innerHtml]="data.additionalText"> </div>
  <div id="divInstruction" *ngIf="data.instructionText" [innerHtml]="data.instructionText" class="instruction"></div>

  <div *ngIf="data.showCheckbox" class="text-left ml-1">
    <mat-checkbox [(ngModel)]="isCheckboxChecked">{{data.checkboxLabel}}</mat-checkbox>
  </div>

  <div *ngIf="data.showTextbox" class="text-left ml-4">
    <span>
      {{data.textboxLabel}}
      <input type="text" [(ngModel)]="textboxValue" [disabled]="!isCheckboxChecked">
    </span>
  </div>

  <div *ngIf="data?.arrayOfInstructiontext?.length > 0">
    <div [innerHtml]="data.arrayOfInstructiontext[0]"> </div>
    <a class="linkLabel" (click)="openBottomSheet()">{{data.linkLabel}}</a>
    <div [innerHtml]="data.arrayOfInstructiontext[1]"></div>
  </div>

  <div [ngClass]="{'mat-body-container12_list': data.bodyText.length > 4 ,
         'mat-body-container13_list' : data.bodyText.length < 4}" *ngIf="data.bodyText">
    <table mat-table [dataSource]="data.bodyText" class=" mat-table-container mat-elevation-z8">
      <ng-container matColumnDef="attributeName">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element"> {{element.attributeName}} </td>
      </ng-container>
      <ng-container matColumnDef="Quote">
        <th mat-header-cell *matHeaderCellDef> Quote</th>
        <td mat-cell *matCellDef="let element"> {{element.Quote}} </td>
      </ng-container>
      <ng-container matColumnDef="Profile">
        <th mat-header-cell *matHeaderCellDef> Profile </th>
        <td mat-cell *matCellDef="let element"> {{element.Profile}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div class='buttonContainer' *ngFor="let button of data.buttons">
    <lib-primary-button *ngIf="!button.isSecondary" [text]="button.buttonText" [tag]="button.tag"
      (clicked)="onClick($event)" [hasFocus]="button.hasFocus"></lib-primary-button>
    <lib-secondary-button *ngIf="button.isSecondary" [text]="button.buttonText" [tag]="button.tag" [disabled]="disableYesButton(button.tag)"
      (clicked)="onClick($event)" [hasFocus]="button.hasFocus"></lib-secondary-button>
  </div>

</div>