import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import {
  LayoutService, QuestionLogicResolverData, Question, BaseQuestionLogicComponent,
  QuestionSetService, BaseRepeatingGroupDataCaptureComponent, SharedSettingsService
} from 'wtw-ui-components';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SalesSettingsService } from '../../../services/settings/settings.service';
import { PolicyService } from '../../../services/Policy/policy.service'
import { lastValueFrom } from 'rxjs';
import { QuotationService } from '../../../services/quotation/quotation.service';

@Component({
  selector: 'app-question-logic',
  templateUrl: './question-logic.component.html',
  styleUrls: ['./question-logic.component.scss']
})
export class QuestionLogicComponent extends BaseRepeatingGroupDataCaptureComponent implements OnInit {

  currentUrl: string;
  isDesktopLayout: boolean;
  questionObject: QuestionLogicResolverData;
  policyDetails: any;
  policyIdToBeCloned: number;
  isSingleCarrierSingleQuoteResumeFlow: boolean;
  @ViewChild(BaseQuestionLogicComponent) baseQuestionLogicComponent: BaseQuestionLogicComponent;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutService,
    public questionSetService: QuestionSetService,
    public sessionStorageService: SessionStorageService,
    public translate: TranslateService,
    public matDialog: MatDialog,
    public salesSettingService: SalesSettingsService,
    public sharedSettingsService: SharedSettingsService,
    public policyService: PolicyService,
    public quotationService: QuotationService) {
    super(sessionStorageService, questionSetService, translate, matDialog);
  }

  async ngOnInit() {
    this.currentUrl = this.activatedRoute.snapshot.url[0].path;
    this.questionObject = this.activatedRoute.snapshot.data['questionLogicData'];
    
    if (this.sessionStorageService.get('EnableICECommonAuth'))
    {
      this.sessionStorageService.remove("previousUrl");
    }

    // when refreshing the page these needs to reset
    this.sessionStorageService.set('PreviousAnsEditButton', false);
    this.questionSetService.IsNewRepeatingGroup=false;

    // *** while isCopyToNewQuote is true then Bind the question answer  >> START ***//
    const isCopyToNewQuote = this.sessionStorageService.get('isCopyToNewQuote');
    const isCopyQuotePolicyQuestion=this.salesSettingService.isCopyQuotePolicyQuestion
    
    const isb2b = this.sessionStorageService.get('isb2b');
    const existingCustomerQuote = this.sharedSettingsService.existingCustomerQuote;
    const existingCustomerDraftQuote = this.sessionStorageService.get('ExistingCustomerDraftQuote');
    const isCopyQuoteAtpClient = this.sharedSettingsService.isCopyQuoteAtpClient;
    // when moving from policy question to quote question by edit its again overriding the
    // response with old data due to below code hence added this condition
    const isLinkToPolicyClicked = this.sharedSettingsService.isLinkToPolicyClicked;

    if (isb2b && (isCopyToNewQuote || existingCustomerQuote) && isLinkToPolicyClicked) {
      this.policyIdToBeCloned = this.salesSettingService.PolicyIdToBeCloned;

      if (this.policyIdToBeCloned) {
        await lastValueFrom<any>(this.policyService.getPolicyResponseData(this.policyIdToBeCloned)).then(value=>{

          this.policyDetails=value;

        });
        const policyResponses = this.policyDetails.responses;
        // const policyResponses = this.getFilteredPolicyReponse(policyDetails.responses);

        // *** Cloning Repeating Group Questions ***//
        if (policyResponses) {
          const hasRepeatingGroup = this.questionSetService.hasRepeatingGroup(policyResponses);
          const isRefreshRequired = this.sharedSettingsService.IsRefreshRequired;

          if (hasRepeatingGroup && isRefreshRequired) {
            this.questionObject.questionSet = this.questionSetService.cloneRepeatingGroupQuestions(
              this.questionObject.questionSet, policyResponses);
          }

          // *** Binding the Responses to questionSet ***//
          this.questionSetService.mapQuoteQuestionAnswer(this.questionObject.questionSet, policyResponses);

          // const answeredQuestionSet = this.questionObject.questionSet
          //   && this.questionObject.questionSet.filter(qs => qs.answerSets?.some(as => as.isAnswered) ||
          //     qs.childQuestionSets?.some(cqs => cqs.answerSets?.some(as => as.isAnswered)));

          // this.sessionStorageService.set('AnsweredQuestionSet', answeredQuestionSet);

          this.salesSettingService.PrimaryQuestionData = this.questionObject.questionSet;

          // *** Refresh the component ***//
          if (isRefreshRequired) {
            this.questionSetService.initialize(this.salesSettingService.PrimaryQuestionData, 0);
            this.sharedSettingsService.IsRefreshRequired = false;
            this.router.navigate(['quote/quote-questions']);
          }
          this.sharedSettingsService.isLinkToPolicyClicked= false;
        }
      }
    }
    else if (isb2b && isCopyQuotePolicyQuestion && isCopyQuoteAtpClient && !existingCustomerDraftQuote) {
      const quoteDetail = this.quotationService.getQuoteDetail();
      if (quoteDetail) {
        await lastValueFrom<any>(this.policyService.getPolicyResponseData(quoteDetail.policyId)).then(value => {
          this.policyDetails = value;
        });
        const policyResponses = this.policyDetails.responses;
        if (policyResponses.length > 1) {
          this.copyPolicyQuestion(policyResponses);
        }
        else {
          this.questionSetService.mapQuoteQuestionAnswer(this.questionObject.questionSet, quoteDetail?.quoteResponseData);
        }
    }
    } // *** while isCopyToNewQuote is true then Bind the question answer  >> END ***//
    else if ((isb2b && existingCustomerDraftQuote) || (isCopyQuoteAtpClient)) {
      // *** Binding the Responses to questionSet ***//
      const quoteDetail = this.quotationService.getQuoteDetail();
      this.questionSetService.mapQuoteQuestionAnswer(this.questionObject.questionSet, quoteDetail?.quoteResponseData);
    }
    this.checkDeviceWidthForDesktop();
    this.deleteRepeatingQues.subscribe((result) => {
      if (result) {
        this.questionSetService.onDeleteRepeatingGroup(result.questionSet, result.qnToDelete, result.deleteFrom);
        this.baseQuestionLogicComponent.onDeleteRepeatingGroup();
      }
    });
    this.isSingleCarrierSingleQuoteResumeFlow = this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value;
  }
  async copyPolicyQuestion(policyResponses: any) {
    if (policyResponses) {
      const hasRepeatingGroup = this.questionSetService.hasRepeatingGroup(policyResponses);
      const isRefreshRequired = this.sharedSettingsService.IsRefreshRequired;
      if (hasRepeatingGroup && isRefreshRequired) {
        this.questionObject.questionSet = this.questionSetService.cloneRepeatingGroupQuestions(
          this.questionObject.questionSet, policyResponses);
      }
      // *** Binding the Responses to questionSet ***//
      this.questionSetService.mapQuoteQuestionAnswer(this.questionObject.questionSet, policyResponses);

      this.salesSettingService.PrimaryQuestionData = this.questionObject.questionSet;    
      this.sharedSettingsService.isLinkToPolicyClicked= false;
    }
  
}
  // private getFilteredPolicyReponse(policyResponse: any): any {
  //   // *** We don't need to clone PolicyInceptionDate and PolicyExpiryDate.  Hence, we are removing it from policyResponse ***//
  //   var response = Object.keys(policyResponse)
  //     .filter((key) => !key.toLowerCase().includes("policyinceptiondate") && !key.toLowerCase().includes("policyexpirydate"))
  //     .reduce((obj, key) => {
  //       return Object.assign(obj, {
  //         [key]: policyResponse[key]
  //       });
  //     }, {});

  //   return response;
  // }

  private checkDeviceWidthForDesktop() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isDesktopLayout = this.layoutService.isLargeView();
    });
  }

  previousClicked(question: Question) {  
    this.sessionStorageService.set('enableIsBackWard', true)
    this.baseQuestionLogicComponent.previousClicked(question);
  }

  saveResponseDataWithAudit() {
    const answeredQuestions = this.questionSetService.answeredQuestions;
    if (answeredQuestions && answeredQuestions.length > 0) {
      this.policyService.saveResponseDataWithAudit().subscribe(data => {
        window.close();
      });
    }
  }

  updatePolicyResponseAndSendQuoteEmail() {
    const answeredQuestions = this.questionSetService.answeredQuestions;
    if (answeredQuestions && answeredQuestions.length > 0) {
      const isGlobalFunctionSendQuote = answeredQuestions[answeredQuestions.length - 1]
                                        .globalFunction?.toLowerCase().includes('sendquote');
      if (isGlobalFunctionSendQuote) {
        this.policyService.updatePolicyQuestionResponseDetails(false).subscribe(data => {
          lastValueFrom(this.quotationService.sendQuoteEmailRequest());
        });
      }
    }
  }

  onDeleteClick(question: Question) {
    this.deleteDialog(question, true, false);
  }

}
