import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalContent } from '../../../models';
import { LayoutService } from '../../../../services/layout/layout.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomsheetComponent } from '../../bottomsheet/bottomsheet.component';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { Observable, timer } from 'rxjs';
import { IdleUserService } from '../../../../services/idle-user/idle-user.service';

const counter = timer(0, 1000);

@Component({
  selector: 'lib-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit {
  public countDownValue: number;
  private popupTimerSubscription: any;
  isCheckboxChecked: boolean;
  textboxValue: string;
  constructor(
    public dialogRef: MatDialogRef<ModalTemplateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalContent,
    private layoutService: LayoutService,
    public bottomSheet: MatBottomSheet,
    private sharedSettingsService: SharedSettingsService,
    private idleUserService: IdleUserService
  ) { }
  displayedColumns: string[] = ['attributeName', 'Quote', 'Profile'];
  dataSource = this.data.bodyText;
  openBottomSheet() {
    const sheetObjectData = {
      key: 'privacyPolicy',
      label: this.data.linkLabel,
      openTo: 'bottomsheet',
      title: this.data.linkLabel,
      content: this.sharedSettingsService.privacyPolicyData,
      enableCloseBtn: true,
      overlayPanelClass: 'fullWidthBottomSheet',
      buttonText: this.data.sheetButtonText
    }
    const bottomSheetRef = this.bottomSheet.open(BottomsheetComponent, {
      panelClass: 'fullWidthBottomSheet',
      data: sheetObjectData
    });
    bottomSheetRef.afterDismissed().subscribe(result => {
    });

  }

  ngOnInit() {
    this.dialogRef.disableClose = true; // Only close via button click
    this.dialogRef.addPanelClass('full-width-dialog'); // Injects globally declared style.
    this.layoutService.subscribeToLayoutChanges();
    this.manageScreenSizing();

    this.countDownValue = this.data.secondsCount;

    this.popupTimerSubscription = counter.subscribe(() => {
      if (this.data.secondsCount >= 0) {
        this.countDownValue = this.countDownValue - 1;
        if (this.countDownValue <= 0) {
          this.popupTimerSubscription.unsubscribe();
          this.idleUserService.clearSession();
        }
      }
    });
  }

  ngOnDestroy() {
    this.popupTimerSubscription.unsubscribe();
  }

  onClick(tag: any): void {
    this.dialogRef.close(tag);
    //  For PBI 301590 - Passing comment for renewal decline only
    if (this.data.isRenewal && tag) {
      this.sharedSettingsService.UpdateQuoteDeclineRenewalSubject({isSelected: this.isCheckboxChecked, value: this.textboxValue});
    }
  }

  disableYesButton(tag: boolean): boolean {
    return tag && this.data.isRenewal && (!(this.textboxValue?.length > 0) || !this.isCheckboxChecked) ? true : false;
  }

  get isMobile(): boolean {
    return this.layoutService.isSmallView();
  }

  get isTablet(): boolean {
    return this.layoutService.isMediumView();
  }

  get isDesktop(): boolean {
    return this.layoutService.isLargeView();
  }

  private manageScreenSizing() {
    const width = this.isMobile ? '95%' : '470px';
    this.dialogRef.updateSize(width, 'auto');
  }
  closeDialog() {
    this.dialogRef.close('close');
  }
}
