import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { Observable, OperatorFunction } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-auto-suggest-input',
  templateUrl: './auto-suggest-input.component.html',
  styleUrls: ['./auto-suggest-input.component.scss']
})
export class AutoSuggestInputComponent {
  @Input() autoSuggestListForm: FormGroup;
  @Input() field: any;
  @Output() autoSuggestChange = new EventEmitter();
  @Output() onAutoSuggestBlur = new EventEmitter();

  constructor(
    private settingsService: SharedSettingsService
  ) { }


  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      map((term) =>
        term.length < this.settingsService.minimumCharForAutoSuggestion
          ? []
          : this.field.autoSuggestionList?.length > 0 &&
          this.field.autoSuggestionList.filter((v) => v?.toLowerCase().indexOf(term.toLowerCase()) > -1)
      )
    );

  // Responsible to call api once minimum character length reached
  getSuggestionList(event: any) {
    if (event.target.value?.length >= this.settingsService.minimumCharForAutoSuggestion) {
      this.autoSuggestChange.emit({ event: event, field: this.field });
    }
  }
  onBlurEvent(event: any) {
    this.onAutoSuggestBlur.emit({ event: event, field: this.field });
  }

}
