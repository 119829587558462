<div>
    <mat-card appearance="outlined" [ngClass]="{'last-card-container': isLast}">
        <mat-card-header (click)="emitHeaderClicked(cardInput)">
            <div mat-card-avatar>
                <mat-icon>{{cardInput.iconName}}</mat-icon>
            </div>
            <mat-card-title *ngIf="cardInput.titleLabel">{{cardInput.titleLabel}}</mat-card-title>
            <mat-card-subtitle *ngIf="cardInput.subTitleLabel">{{cardInput.subTitleLabel}}</mat-card-subtitle>
            <button type="button" *ngIf="cardInput.menuInfo.length" mat-icon-button [matMenuTriggerFor]="posXMenu" aria-label="Open x-positioned menu">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-card-header>
        <mat-menu xPosition="before" #posXMenu="matMenu">
            <button mat-menu-item *ngFor="let item of cardInput.menuInfo" (click)="menuClicked(item)">
                <mat-icon *ngIf="item.iconName">{{item.iconName}}</mat-icon>
                {{item.menuLabel}}
            </button>            
        </mat-menu>
    </mat-card>
</div>
