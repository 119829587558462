<div class="card text-left" (click)="onClick()">
    <div class="card-top-container">
        <div class="left-container">
            <ng-container *ngIf="infoData && infoData.image && infoData.image.length > 0">
                <!-- <img src={{infoData?.image}} alt="" class="icon-heading"> -->
                <img class="img-fluid imgsize" [src]="'data:image/png;base64,'+infoData?.image" />
            </ng-container>
            <span class="heading-text"> {{infoData?.name}}</span>
        </div>
        <div class="right-container">
            {{infoData?.sideText}}
        </div>
    </div>
    <div class="card-description">
        <div *ngFor="let item of infoData?.description">
            <strong>{{item.label}}</strong> {{item.sublabel}}
        </div>

        <div class="coverage-terms">
            <ng-container *ngIf="infoData?.editPrimaryCoveargeTermOnSecondaryCoverage">
                {{infoData?.coverageTermsLabel}}:<strong>{{infoData?.defaultCoverageTermText}} </strong>
            </ng-container>
        </div>

        <div class="coverage-terms"
            *ngIf="infoData?.coverageTermsList?.length > 0 && infoData.coverageTermsList[0] !== ''">

            <ng-container *ngIf="!infoData?.editPrimaryCoveargeTermOnSecondaryCoverage">
                <span id="PrimaryCoverTerm" class="mat-select-custom-style"
                    [formGroup]="coverageTermDetailsForm">{{infoData?.coverageTermsLabel}}:

                    <lib-auto-search-dropdown [optionListDetailsForm]="coverageTermDetailsForm"
                        [optionList]="optionList" (click)="$event.stopPropagation()"
                        (valueChange)="onSelectionChange($event,infoData)" [placeHolderLabel]="'CoverageTerms'"
                        [controlName]="'coverageTerm'"></lib-auto-search-dropdown>
                </span>
            </ng-container>
        </div>
    </div>
    <div class="selected-coverages" *ngIf="infoData?.mandatoryOrPreSelectedCoverages">
        <mat-chip-listbox aria-label="Fish selection">
            <mat-chip-option
                *ngFor="let coverName of infoData?.mandatoryOrPreSelectedCoverages">{{coverName}}</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
