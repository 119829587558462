import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { DropDownField } from '../../../form-field/dropdown-field';


const SearchLabel = 'Shared.lblSearchBtn';
const NoRecordsFoundLabel = 'Shared.lblNoRecordFound';


@Component({
  selector: 'lib-auto-search-dropdown',
  templateUrl: './auto-search-dropdown.component.html',
  styleUrls: ['./auto-search-dropdown.component.scss']
})
export class AutoSearchDropdownComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  @Input() optionList: any[] ;
  @Input() selectedValue: any;
  @Input() isDisabled: boolean;
  @Input() optionListDetailsForm: FormGroup;
  @Input() controlName: any;
  @Input() placeHolderLabel: string;
  @Input() field: DropDownField
  /** control for the MatSelect search */
  public searchControl: FormControl = new FormControl();
  @Output() valueChange = new EventEmitter();
  /** list of options filtered by search */
  public filteredOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  noRecordsFoundLabel: string;
  searchLabel: string;

  constructor(public translate: TranslateService) { }

  ngOnChanges() {
    this.filteredOptions.next(this.optionList);
  }

  
  ngOnInit() {

    this.applyTranslations();
    if (!this.selectedValue && this.optionListDetailsForm.get(this.controlName)?.value) {
      this.selectedValue = this.optionListDetailsForm.get(this.controlName).value
    }
    this.filteredOptions.next(this.optionList);
    // filter the options when user search the keyword
    this.searchControl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterOptions();
      });
  }

  private applyTranslations() {
    this.translate.get([NoRecordsFoundLabel, SearchLabel]).subscribe((res) => {
      this.noRecordsFoundLabel = res[NoRecordsFoundLabel];
      this.searchLabel = res[SearchLabel];

    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  onSelectionChange(event) {
    this.valueChange.emit(event);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  /**
   * Sets the initial value after the filteredOptions are loaded initially if any value is selected
   */
  protected setInitialValue() {
    this.filteredOptions
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.singleSelect.compareWith = (a: any, b: any) => a && b && a === b;
      });
  }

  protected filterOptions() {
    if (!this.optionList) {
      return;
    }
    // get the search keyword
    let search = this.searchControl.value;
    if (!search) {
      this.filteredOptions.next(this.optionList);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the options
    this.filteredOptions.next(
      this.optionList?.filter(options => options?.value?.toLowerCase().indexOf(search) > -1)
    );
  }
}
