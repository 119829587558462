<div class="footer-container mobile-view" id="divFooterContainer">
    <section id="secFooterLinks" [ngClass]="{'footer-section-small': isSmallView, 'footer-section-medium': !isSmallView}" class="footer-links">
        <ng-container *ngFor="let linkData of footerLinksAndContent.menuLinks">
            <div id="linkData.key" *ngIf="linkData.label" class="cursor-pointer" [class.pointer-events-none]="linkData.content == '' && !linkData.label.includes('<a href=')" [innerHtml]="linkData.label |safeHtml" (click)="openBottomSheet(linkData)"></div>
        </ng-container>
    </section>
    <hr class="separator">
    <section id="secCopyrightsText" class="footer-base" *ngIf="footerLinksAndContent.copyrightsText">
        <div id="divCopyrightsText" [innerHtml]="footerLinksAndContent.copyrightsText"></div>
    </section>
</div>