import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SessionStorageService } from 'angular-web-storage';
import { Location } from '@angular/common';
import {
  Router, RouterEvent, NavigationStart, NavigationEnd, NavigationError, NavigationCancel,
  ActivatedRoute, RoutesRecognized
} from '@angular/router';
import { ProductTemplateService } from './services/product-template/product-template.service';
import { IContactUs, ProductTemplate } from './shared/models';
import { Observable, forkJoin, lastValueFrom, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import cssVars from 'css-vars-ponyfill';
import {
  LoggingService, ConfigService, SponsorService, UtilitiesService, LocalizationService, ReferenceService,
  ISponsor, ITermsOfUse, IGetUIElement, IGetUIElementsPayload, IPrivacyNoticeLanguage, ICookieNoticeLanguage,
  MsalAuthenticationService, BaseCustomerService, CookieBannerService,
  ExternalValidationRequest, ExternalValidationResponse, ExternalValidationService, BaseAccountService,
  UserName, CreatePasswordService, BaseConstantService, SharedSettingsService, UserProfile, ErrorState, IdleUserService
} from 'wtw-ui-components';
import { SalesSettingsService } from './services/settings/settings.service';
import { ConstantService } from '../app/services/constant/constant.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { filter, pairwise } from 'rxjs/operators';
import { QuotationService } from './services/quotation/quotation.service';
import { IQuoteHistoryExtension } from './shared/models/quote-history.model';

declare let gtag;

const genericErrorPath = 'Generic';
const inactiveErrorPath = 'SiteInactive';
const okButtonLabel = 'Shared.lblOk';
const failureTitle = 'Shared.lblFailureTitle';
const failureText = 'Shared.lblFailureText';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  isLoading = true;
  hasSponsorErrors = false;
  externalValidationFailed = false;
  extValidationRetry = false;

  errorTitle: string;
  errorContent: string;
  privacyPolicyLink: string;
  privacyPolicyData: IPrivacyNoticeLanguage;
  cookieNoticeLink: string;
  cookieNoticeData: ICookieNoticeLanguage;
  termsAndConditionsLink: string;
  termsAndConditionsData: ITermsOfUse;
  copyrightsText: string;
  contactUsData: IContactUs;
  initialized: boolean;
  isErrorPage: boolean;
  errorPageMessage: string;
  okButtonText: string;
  sponsorSpecificPrivacyPolicyLink: string;
  privacyPolicyContent: string;
  lblFooterComplaintsContent: string;
  lblFooterComplaintsLabel: string;
  lblLegalNoticeContent: string;
  lblLegalNoticeLabel: string;
  url: string;
  isCopyQuoteAtpClient: boolean = false;
  isRatingDataUpdated = false;
  isCopyQuotePolicyQuestion: boolean = false

  private sponsor: ISponsor;
  private sponsorName: string;
  private productTemplate: ProductTemplate;
  private extauthUserProfile: UserProfile;
  private productId: number;
  private productTemplateUrlId: number;
  private pageNames: Array<string>;
  private getUiElementPayload: IGetUIElementsPayload;
  public footerLinksAndContent: object;
  public isB2BFlow = false;
  public isExpiredLink: false;
  private routePrefix = '';
  private isProductDetail: boolean = false;


  constructor(
    private sponsorService: SponsorService,
    private location: Location,
    private localizationService: LocalizationService,
    private referenceService: ReferenceService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private translate: TranslateService,
    private title: Title,
    private metaTag: Meta,
    private constantService: ConstantService,
    private loggingService: LoggingService,
    private productTemplateService: ProductTemplateService,
    private externalValidationService: ExternalValidationService,
    private configService: ConfigService,
    private settingsService: SalesSettingsService,
    private baseSettingsService: SharedSettingsService,
    private utilitiesService: UtilitiesService,
    private readonly baseCustomerService: BaseCustomerService,
    private baseAccountService: BaseAccountService,
    private gtmService: GoogleTagManagerService,
    // Do not remove the below unreferenced dependency as it is required to ensure that the MSAL library
    // is initialized after common auth redirection.
    private msalAuthenticationService: MsalAuthenticationService,
    private cookieBannerService: CookieBannerService,
    private createPasswordService: CreatePasswordService,
    private quotationService: QuotationService,
    private idleUserService: IdleUserService
  ) {

    router.events.subscribe((routerEvent) => {
      this.checkRouterEvent(routerEvent);
    });

    sponsorService.languageObservable.subscribe(languageId => {
      this.loadLanguageSpecificContent();
    });

    router.events
      .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
      .subscribe((events: RoutesRecognized[]) => {
        const MCIpreviousPageUrl = events[0].urlAfterRedirects;
        if (MCIpreviousPageUrl !== '/quote/choose-cover') {
          this.sessionStorageService.set('MCIpreviousPageUrl', MCIpreviousPageUrl);
        }
      });


  }

  checkRouterEvent(routerEvent): void {
    if (routerEvent instanceof NavigationStart) {

      if (this.settingsService.enableICECommonAuth && window.location.href.includes('sname')) {
        this.baseSettingsService.extValRetryUrl = window.location.href;
      }

      // Upon initiating navigation we will always re-attempt to initialize Application Insights.
      if (this.sponsorName) {
        const instrumentationKey: string = this.configService.getServiceConfigData().appInsights.instrumentationKey;
        let productName: string = this.productTemplate && this.productTemplate.productTemplateName;
        if (!productName) {
          productName = this.sessionStorageService.get('ProductName') ? this.sessionStorageService.get('ProductName').toLowerCase() : '';
        }
        this.loggingService.initializeAppInsights(instrumentationKey, 'B2CSalesV2', this.sponsorName, productName);
      }
      if (!this.sessionStorageService.get('isModelOpen')) {
        this.isLoading = true;
      }

    }

    // Google Tag Manager >> Start
    if (routerEvent instanceof NavigationEnd) {
      this.isLoading = false;
      if (sessionStorage.getItem('AllowPerformanceCookies') === 'True' && this.sessionStorageService.get('EnableSponsorSpecificTags')) {
        const gtmTag = {
          event: 'page',
          pageName: routerEvent.url
        };

        this.gtmService.pushTag(gtmTag);
      }
    }
    // Google Tag Manager >> End

    if (routerEvent instanceof NavigationEnd &&
      routerEvent.urlAfterRedirects !== '/error-page') {
      this.isLoading = false;
      this.sessionStorageService.set('ErrorPagePreviousUrl', routerEvent.urlAfterRedirects);
      let currentPageRoute = this.router.url.split('/').pop();
      if (currentPageRoute?.toLowerCase().indexOf('product') > -1) {
        currentPageRoute = this.sessionStorageService.get('ProductName') ?? '';
        this.isProductDetail = true;
      }
      this.routePrefix = currentPageRoute.replace(/[^\w+]/gi, '-');
      this.sessionStorageService.set('PageId', '');
    }

    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.isLoading = false;
      const url = routerEvent.url;
      const MCIpreviousPageUrl = this.sessionStorageService.get('MCIpreviousPageUrl');
      if (url.includes('new-quote') && !url.includes('pid') && sessionStorage.hasOwnProperty('ProductId')) {
        history.pushState(null, document.title, location.href);
      }
      if (MCIpreviousPageUrl === 'quote/multi-carrier-quote' && url.includes('choose-cover')) {
        window.history.back();
      }

    }
  }

  ngOnInit(): void {

    this.isB2BFlow = this.baseSettingsService.isB2B();
    const createpassword = this.utilitiesService.getUrlParameterByName('createpwd', false);

    // Falls back to cached value in case of browser refresh.
    this.sponsorName = this.sessionStorageService.get('SponsorName')
      || this.utilitiesService.getUrlParameterByName(ConstantService.SponsorNameQueryParamName);
    this.productId = this.sessionStorageService.get('ProductId')
      || this.utilitiesService.getUrlParameterByName(ConstantService.ProductIdQueryParamName);
    this.productTemplateUrlId = this.sessionStorageService.get('productTemplateUrlId')
      || this.utilitiesService.getUrlParameterByName(ConstantService.ProductTemplateIdQueryParamName);
    this.isCopyQuoteAtpClient = this.baseSettingsService.isCopyQuoteAtpClient
      || this.utilitiesService.getUrlParameterByName(ConstantService.IsCopyQuoteAtpClientQueryParamName) === 'true' ? true : false;
    const currentUrl = this.sessionStorageService.get('currentUrl');
    const previousUrl = this.sessionStorageService.get('previousUrl');
    if (createpassword) {
      this.ValidatePasswordLink(createpassword);
    }

    // Stash the SponsorName for later use. Skip this if we are re-routing after authentication.
    if (!currentUrl && !previousUrl) {
      this.title.setTitle(`Home - ${this.sponsorName}`);
      this.sessionStorageService.set('SponsorName', this.sponsorName);
      this.baseSettingsService.isCopyQuoteAtpClient = this.isCopyQuoteAtpClient;
      if (this.productId) {
        this.sessionStorageService.set('ProductId', this.productId);
      }
    }
    // Pull in shared translations.
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en-GB');

    forkJoin([this.sponsorService.getSponsor(), this.conditionallyGetProduct(),
    this.getUserProfileExtAuthLogin()]).pipe(mergeMap(result => {
      const [sponsor, productTemplate, extauthUserProfile] = result;
      this.sponsor = sponsor;
      this.sessionStorageService.set('enableMoodysPassfortKycScreening', this.sponsor?.enableMoodysPassfortKycScreening);
      if (!productTemplate?.isNavigateToPolicyQuestionCopyQuote && this.requiresProductValidation()) {
        this.productTemplate = productTemplate;
      }
      if (productTemplate && productTemplate != null && productTemplate?.isNavigateToPolicyQuestionCopyQuote) {
        this.isCopyQuotePolicyQuestion = productTemplate?.isNavigateToPolicyQuestionCopyQuote;
        sessionStorage.setItem('isB2cSingleCarrierMultiQuoteEnabled', String(productTemplate?.isB2cSingleCarrierMultiQuoteEnabled));
      }
      this.extauthUserProfile = extauthUserProfile;
      this.settingsService.disableMyAccountCreationB2C = this.sponsor.disableMyAccountCreationB2C;

      if (this.extauthUserProfile && this.extauthUserProfile?.customerId !== null
        && this.extauthUserProfile?.customerId !== undefined && this.extauthUserProfile.customerId >= 0
        && this.msalAuthenticationService.isAuthenticated() === false) {
        this.settingsService.extAuthNav = true;
        this.sponsorName = this.extauthUserProfile.sponsorName;
        this.sessionStorageService.set('customerId', this.extauthUserProfile.customerId);
        this.sessionStorageService.set('SponsorId', this.extauthUserProfile.sponsorId);
        sessionStorage.setItem('Email', this.extauthUserProfile.email);
        this.baseSettingsService.loggedInUser = this.extauthUserProfile.firstName;
      }

      if (this.productTemplate) {
        this.settingsService.isESignatureEnabled = this.productTemplate.isESignatureEnabled;
      }

      this.settingsService.displayGrossPremium = this.sponsor.displayGrossPremium;

      this.sponsorService.applyWillisBranding = this.sponsor.applyWillisBranding;
      this.sponsorService.sponsorLogo = this.sponsor.sponsorLogo;
      this.referenceService.applySponsorLogoAsFavicon(this.sponsor.sponsorId, this.sponsor.applyWillisBranding);
      this.settingsService.enableVoucherBasedAccess = this.sponsor.enableVoucherBasedAccess;
      this.settingsService.enableSSOBasedAccess = this.sponsor.enableSSOBasedAccess;
      this.settingsService.enableGuestPurchase = this.sponsor.enableGuestPurchase;
      this.settingsService.enableAssently = this.sponsor.enableAssently;
      this.settingsService.enableICECommonAuth = this.sponsor.enableICECommonAuth;
      this.settingsService.enableMFA = this.sponsor.enableMFA;
      this.settingsService.compressedViewOfPreviousAnswers = this.sponsor.compressedViewOfPreviousAnswers;
      this.settingsService.enableSalesV2Home = this.sponsor.enableSalesV2Home;
      this.sessionStorageService.set('legalApprovedRegionAppInsights', this.sponsor.legalApprovedAppInsightsEnabled);
      this.settingsService.enableProductRestrictionOnSubgroups = this.sponsor.enableProductRestrictionOnSubgroups;
      // PBI 277009 Session Timeout occurs if user is inactive for configured minutes
      this.baseSettingsService.inActivityMinutesForSessionTimeout = this.sponsor.inActivityMinutesForSessionTimeout;
      this.baseSettingsService.SessionTimeoutCountdown = this.sponsor.sessionTimeoutCountdown;
      // Get 'id_token' from query string and store it local storage.
      const idToken = this.utilitiesService.getUrlParameterByName('id_token', false);
      if (idToken) {
        localStorage.setItem(BaseConstantService.Session.msalIdToken, idToken);
        const currentTime = new Date();
        // The generated token can be used upto 8 hours, after it will be expired!
        localStorage.setItem(BaseConstantService.Session.idTokenExpiryTime,
          String(currentTime.setHours(currentTime.getHours() + 8)));
        // clearing token if user is inactive for configured minutes
        this.idleUserService.initIdleUserListener();

      }

      const isGuestFlow = (this.sponsor.enableGuestPurchase === true || this.isB2BFlow);
      this.settingsService.enableSponsorSpecificTags = this.sponsor.enableSponsorSpecificTags;

      sessionStorage.setItem('GTM', btoa(this.sponsor.gtmCode));
      // this.baseSettingsService.gtmCode = btoa(this.sponsor.gtmCode);

      if (sessionStorage.getItem('AllowPerformanceCookies') == null) {
        const cookieValue = this.cookieBannerService.getCookieValue('groups');
        if (cookieValue) {
          const allowPerformanceCookies = cookieValue.includes('C0002');
          sessionStorage.setItem('AllowPerformanceCookies', allowPerformanceCookies ? 'True' : 'False');
        }
      }
      if (sessionStorage.getItem('AllowPerformanceCookies') === 'True' && this.sponsor.enableSponsorSpecificTags) {
        this.setGTagManager(this.sponsor.gtmCode);
        this.setGoogleAnalytics(this.sponsor.gaCode);
      }

      return this.conditionallyGetExternalValidationStatus(isGuestFlow); // Todo: Handle errors on service call!
    })).subscribe(async (result) => {

      // PBI-187615
      this.setLocalDateFormat();

      this.sessionStorageService.set('isSearchMetaDataRequired', this.sponsor.isSearchMetaDataRequired);
      // Checks if its  enableICECommonAuth on and only validation for ExtCustomer e.g. yourPolicies/savedQuotes page
      const checkICEExtCustomerCheckOnly = this.checkExtCustomerICECommonAuthValidation();
      let searchMetaData = BaseConstantService.metaDataDenySearch;
      if (this.sponsor.isSearchMetaDataRequired) {
        searchMetaData = BaseConstantService.metaDataAllowSearch;
      }
      this.metaTag.addTags([
        { name: 'robots', content: searchMetaData }
      ]);

      let externalValidationResult = result;

      if (window.location.href?.includes('logout') === true) {
        externalValidationResult = null
      }
      else {
        if (externalValidationResult) {
          this.processExternalValidationResult(externalValidationResult);
        }
      }

      // adding this code for bug 178254
      const externalValidation = this.sessionStorageService.get('externalValidationResult');
      this.hasSponsorErrors = (Object.keys(this.sponsor).length === 0 && this.productTemplate === null) ? false : (!this.getSiteActive());
      if (!this.hasSponsorErrors) {

        this.setupOnetrustCookieBanner(this.sponsor.oneTrustCookieBannerScriptId);
        // Set site as active to circumvent routing guard.
        this.sessionStorageService.set('SiteActive', !this.hasSponsorErrors);
        this.sessionStorageService.set('SponsorId', this.sponsor.sponsorId);

        this.sessionStorageService.set('timeZone', this.sponsor.timeZone);
        this.sessionStorageService.set('timeZoneOffset', this.sponsor.timeZoneOffset);

        let customerId = this.sessionStorageService.get('customerId');

        if (customerId !== null && customerId !== undefined && customerId >= 0
          && this.msalAuthenticationService.isAuthenticated()) {
          this.setCustomerPreferredLanguage(customerId);
        } else if (!customerId && localStorage.hasOwnProperty('msal.idtoken') && this.msalAuthenticationService.isAuthenticated()) {
          /* After the login we couldn't see the CustomerId in Session.
              Hence, it couldn't set Customer's Preferred Language
              to handle that scenario we have added this else-if block */
          this.baseAccountService.getUserName().then((userDetail: UserName) => {

            if (this.sponsor.enableICECommonAuth && !checkICEExtCustomerCheckOnly && 
              (userDetail.customerID >= 0 || userDetail.customerID === 0) && !previousUrl) {
             this.sessionStorageService.set('previousUrl', '/quote/quote-questions');
           }

            customerId = userDetail.customerID;
            this.settingsService.customerId = userDetail.customerID;
            this.baseSettingsService.loggedInUser = userDetail.firstName;
            this.baseSettingsService.customerId = userDetail.customerID;
            this.setCustomerPreferredLanguage(customerId);
            this.sessionStorageService.set('LoggedInEmail', userDetail?.email);
          });
        } else {
          this.setSponsorLanguage();
          this.settingsService.isPreferredLanguageSet = true;
        }

        const ssoEnabledCheck = this.settingsService.enableVoucherBasedAccess
          || this.settingsService.enableSSOBasedAccess
          || this.settingsService.enableGuestPurchase
          || this.isB2BFlow
          || this.settingsService.extAuthNav
          || this.settingsService.enableICECommonAuth;
        if (ssoEnabledCheck === true) {
          this.sessionStorageService.set('LanguageCode', this.sponsor?.languageCode);
          this.sessionStorageService.set('LanguageId', this.sponsor?.languageId);
        }

        this.sessionStorageService.set('legalApprovedRegionAppInsights', this.sponsor.legalApprovedAppInsightsEnabled);

        this.translate.store.currentLang = undefined;
        // the lang to use, if the lang isn't available, it will use the current loader to get them
        await this.translate.use(this.sessionStorageService.get('LanguageCode')).subscribe();
        // Trigger asynchronously, fire and forget.
        // The below call is commented as it is already invoked by lanaguageObservable in the constructor.
        // this.loadLocalizationAndPageContent().subscribe();
        /* Reassigned the session storage value, As it was getting cleared after authentication*/
        if (externalValidation && this.isCopyQuoteAtpClient && externalValidation?.b2BData?.orderId > 0) {
          this.sessionStorageService.set(BaseConstantService.Key_OrderId, externalValidation?.b2BData?.orderId);
          this.sessionStorageService.set(BaseConstantService.Key_QuoteReferenceID, externalValidation?.b2BData?.quoteReferenceID);
        }
        const orderId = this.sessionStorageService.get(BaseConstantService.Key_OrderId);

        if (this.requiresExternalValidation() && externalValidationResult && !externalValidationResult?.success) {
          // On validation error.
          this.handleExternalValidationFailure(externalValidationResult);
        }
        else if (!this.msalAuthenticationService.isAuthenticated() && externalValidationResult
          && externalValidationResult?.success && (this.sponsor.enableICECommonAuth)
          && !this.isB2BFlow) {
          this.router.navigate(['/login']);
          return false;
        }
        else if (this.location.path().split('/').pop() === 'logout') {
          // Post logout.
          if (this.settingsService?.extAuthNav) {
            const queryParams = this.sessionStorageService.get('QueryParams');
            if (queryParams && queryParams !== undefined) {
              const url = `/salesv2?sname=${queryParams.sname}&extauth=${queryParams.extauth}&identifier=${queryParams.identifier}`;
              // You can use this URL for navigation or other purposes
              window.location.href = url;
            }
          }
          else {
            this.router.navigate(['logout']);
          }
        } else if ((localStorage.getItem('msal.error') === 'access_denied' || !previousUrl) && currentUrl) {
          // Post authentication - error.
          this.router.navigate([currentUrl]);
          this.clearPreviousRoutes();
        } else if (window.location.href.includes('docusigncallback')) {
          this.router.navigate(['/policy/docusigncallback'], { queryParams: { event: 'signing_complete' } });
        } else if (this.isB2BFlow && orderId && Number(orderId) > 0) {
          this.quotationService.retrieveQuoteDetails().subscribe(async (quoteDetails: IQuoteHistoryExtension) => {
            if ((quoteDetails !== null && quoteDetails !== undefined) &&
              (this.isCopyQuoteAtpClient && this.productTemplateUrlId &&
                Number(this.productTemplateUrlId) > 0)) {
              this.quotationService.setQuoteDetail(quoteDetails);
              const isB2cSingleCarrierMultiQuoteEnabled = sessionStorage.getItem('isB2cSingleCarrierMultiQuoteEnabled');
              this.baseSettingsService.existingCustomerQuote = true;
              this.sessionStorageService.set(BaseConstantService.Key_OrderId, null);
              this.sessionStorageService.set(BaseConstantService.Key_QuoteReferenceID, null);
              this.sessionStorageService.set(BaseConstantService.Key_ProductId, this.productTemplateUrlId);
              this.sessionStorageService.set('PolicyId', null);
              if (this.isCopyQuotePolicyQuestion && this.ValidateQuoteStatus(quoteDetails.statusText)
                && isB2cSingleCarrierMultiQuoteEnabled) {
                this.settingsService.isCopyQuotePolicyQuestion = this.isCopyQuotePolicyQuestion;
                this.settingsService.navigateToUserDetails = true;
                this.isRatingDataUpdated = await this.isRatingDataAndQuestionUpdated(quoteDetails.productTemplateId, quoteDetails.quoteId
                );
                this.baseSettingsService.existingCustomerQuote = true;
                if (!this.isRatingDataUpdated) {
                  this.router.navigateByUrl('/quote/choose-cover', { state: { b2bNavigation: 'true' } });
                }
                else {
                  this.settingsService.navigateToUserDetails = false;
                  this.router.navigate(['/quote/quote-questions']);
                }
              }
              else {
                this.router.navigate(['/quote/quote-questions']);
              }
            }
            else {
              // Redirect to first question when QuoteStatus is Draft
              if (quoteDetails !== null && quoteDetails !== undefined) {
                if (quoteDetails.statusText === this.constantService.QuoteStatus.DRAFT) {
                  this.quotationService.setQuoteDetail(quoteDetails);
                  this.sessionStorageService.set('ExistingCustomerDraftQuote', true);
                  this.router.navigate(['/quote/quote-questions']);
                }
                else {
                  this.quotationService.setQuoteDetail(quoteDetails);
                  // this.sessionStorageService.set('ExistingCustomerQuote', true);
                  this.baseSettingsService.existingCustomerQuote = true;
                  this.router.navigateByUrl('/quote/choose-cover', { state: { b2bNavigation: 'true' } });

                }
              }
            }
          });
        }
        // yourPolicies Screen will have check for extCustomerID,If enableICECommonAuth on
        else if (checkICEExtCustomerCheckOnly) {
          const extCustomerID = this.utilitiesService.getUrlParameterByName(this.constantService.extCustomerID, true);
          if (!extCustomerID) {
            this.clearSessionRoutesNavigateToError();
          }
          else if (this.msalAuthenticationService.isAuthenticated()) {
            // validate after login if queryParam  extCustomerID matches with Common Auth details
            this.baseAccountService.isValidCustomerIdentifier(extCustomerID).subscribe(async (authUser) => {
              if (authUser) {
                previousUrl ? this.router.navigate([previousUrl]) : this.router.initialNavigation();
              }
              else {
                this.clearSessionRoutesNavigateToError();
              }
            });
          }
          else {
            // validate before login if  extCustomerID exists in ATP if exists then navigate to Common Auth
            this.baseCustomerService.isValidCustomerIdentifier(extCustomerID).subscribe(async (authUser) => {
              if (authUser) {
                previousUrl ? this.router.navigate([previousUrl]) : this.router.initialNavigation();
              }
              else {
                this.clearSessionRoutesNavigateToError();
              }
            });
          }
        }
        else if (previousUrl) {
          // Post authentication - success.
          if (previousUrl.includes('ForcedLogin')) {
            this.router.navigate([previousUrl], { queryParams: { Login: 'ForcedLogin' } });
          } else {
            this.router.navigate([previousUrl]);
            //  this.clearPreviousRoutes();
          }
        }
        else {
          // Initial navigation.
          this.router.initialNavigation();
        }
      } else {
        this.loadErrorPageContent();
      }

      if (externalValidation) {
        this.processExternalValidationResult(externalValidation);
      }

      this.ApplyBrandingSettings();
      this.checkBrowserBackNavigation();
      if (this.sponsor.activateNewPublicWebsiteUrl) {
        this.createTermsOfUseScriptBox();
      }
      this.baseSettingsService.sponsorLanguageCode = this.sponsor.languageCode;
      this.baseSettingsService.minimumCharForAutoSuggestion = this.sponsor.minimumCharactersforWebServiceCall;

      // IE fallback measure.
      cssVars({
        watch: true
      });

      const validationResult = this.sessionStorageService.get('externalValidationResult');
      if (validationResult && validationResult?.success === true && validationResult.externalIntegration) {
        this.sessionStorageService.set('previousUrl', '/quote/quote-questions');
        this.router.navigate(['/quote/quote-questions']);
      }

    // #region  "Set UniqueUserSession Id"
      const userSessionKeyValue = this.baseSettingsService.getUserSessionKey();
      if (userSessionKeyValue === undefined || userSessionKeyValue === null) {
      this.baseSettingsService.setUserSessionKey();
    }
    // #endregion

    });


  }

  /** *Checks if rating calculation and question has been updated for a given product template.*/
  async isRatingDataAndQuestionUpdated(productTemplateId: any, quoteId: any): Promise<boolean> {
    const languageId = this.sessionStorageService.get('LanguageId');
    const ratingDataUpdateResult = await lastValueFrom<any>(this.productTemplateService
      .getProductTemplateRatingResult(quoteId, productTemplateId, languageId));
    return Boolean(ratingDataUpdateResult);
  }

  private setCustomerPreferredLanguage(customerId: number) {
    this.baseCustomerService.getCustomerPreferredLanguage(customerId).then((langObject) => {
      this.settingsService.userPreferredLanguageId = langObject?.languageID;
      if (langObject?.languageID && langObject?.languageID !== null) {
        this.baseCustomerService.setCustomerPreferredLanguage(langObject.languageID, langObject.languageCode);
        this.settingsService.isPreferredLanguageSet = true;
      }
    });
  }

  ValidateQuoteStatus(quoteStatus: any): boolean {
    const { SignaturePending, Expired, PendingConfirmation, Cancelled, TakenUp } = this.constantService.QuoteStatus;

    const allowedQuoteStatus = [
      SignaturePending,
      Expired,
      PendingConfirmation,
      Cancelled,
      TakenUp
    ];

    return allowedQuoteStatus.includes(quoteStatus.toUpperCase());
  }

  ValidatePasswordLink(createpassword: any) {
    this.createPasswordService.validateNewCreatePassword(createpassword).subscribe(result => {
      this.isExpiredLink = result;
      if (this.isExpiredLink) {
        this.sessionStorageService.set('linkexpiredurl', true);
      } else {
        this.msalAuthenticationService.createPassword(createpassword);
      }
    });
  }

  checkBrowserBackNavigation() {
    window.addEventListener('popstate', (event) => {
      if (window.location.href.includes('new-quote') && sessionStorage.hasOwnProperty('ProductId')) {
        history.pushState(null, document.title, location.href);
      }
    });
  }

  private processExternalValidationResult(externalValidationResult: ExternalValidationResponse) {
    this.sessionStorageService.set('tokenGenerationRedirectUrl', externalValidationResult.tokenGenerationRedirectUrl);
    this.sessionStorageService.set('externalValidationResult', externalValidationResult);

    // *** autoLogin for the B2BFlow ***//
    if (this.isB2BFlow && externalValidationResult.success
      && this.msalAuthenticationService.isAuthenticated() === false) {
      this.sessionStorageService.set(BaseConstantService.Key_IsB2B, true);
      this.sessionStorageService.set(BaseConstantService.Key_B2BEmail, externalValidationResult?.b2BData?.b2BEmail);
      this.sessionStorageService.set(BaseConstantService.Key_B2BUserName, externalValidationResult?.b2BData?.b2BUserName);
      this.sessionStorageService.set(BaseConstantService.Key_OrderId, externalValidationResult?.b2BData?.orderId);

      if (externalValidationResult?.b2BData?.quoteReferenceID) {
        this.sessionStorageService.set(BaseConstantService.Key_QuoteReferenceID, externalValidationResult?.b2BData?.quoteReferenceID);
        this.sessionStorageService.set('ResumeCustomerId', externalValidationResult?.b2BData?.customerId);
        // this.sessionStorageService.set('ExistingCustomerQuote', true);
        this.baseSettingsService.existingCustomerQuote = true;
      }
      else {
        // this.sessionStorageService.set('ExistingCustomerQuote', false);
        this.baseSettingsService.existingCustomerQuote = false;
        this.baseSettingsService.b2BCustomerId = externalValidationResult?.b2BData?.customerId;
      }

      if (externalValidationResult?.b2BData?.productTemplateID) {

        const productId = externalValidationResult?.b2BData?.productTemplateID;
        this.sessionStorageService.set(BaseConstantService.Key_ProductId, productId);
        lastValueFrom(this.productTemplateService.getProduct(this.sessionStorageService.get('SponsorId'), productId));
      }

      this.sessionStorageService.set(BaseConstantService.Key_IsB2cSingleCarrierMultiQuoteEnabled,
        externalValidationResult?.b2BData?.isB2cSingleCarrierMultiQuoteEnabled.toString());

      window.location.href = externalValidationResult.tokenGenerationRedirectUrl;
    }

    if (externalValidationResult && externalValidationResult?.success
      && this.settingsService.extAuthNav && !this.sessionStorageService.get('IsSAMLSSO')) {
      this.sessionStorageService.set('IsSAMLSSO', true);
      window.location.href = externalValidationResult.tokenGenerationRedirectUrl;
    }

    // Reassigned the productID in session storage, As it was getting cleared after authentication.
    if (this.isB2BFlow && externalValidationResult.success && externalValidationResult?.b2BData?.productTemplateID) {

      const productId = externalValidationResult?.b2BData?.productTemplateID;
      this.sessionStorageService.set(BaseConstantService.Key_ProductId, productId);
    }

    if (externalValidationResult.userProfileModel) {
      // Add user details and address in session storage.
      this.settingsService.userDetailModel = externalValidationResult.userProfileModel;
      this.settingsService.userAddressModel = externalValidationResult.userProfileModel.addresses;
      this.settingsService.userConsentModel = externalValidationResult.userProfileModel.customerConsents;
    }
    // Utilising new Settings Service.
    // Todo: Move all Sesssion Storage interaction to this service.
    this.settingsService.externalValidationIterationData = externalValidationResult.iterationData;
    const externalData = externalValidationResult.extendedProperty;
    if (externalData) {
      const targetProductId = externalData['ProductId'];
      if (targetProductId) {
        this.productId = targetProductId;
        this.sessionStorageService.set('ProductId', this.productId);
      }
      this.sessionStorageService.set('ExternalData', externalData);
      this.sessionStorageService.set('applicationFormId', externalData?.ApplicationFormId);
    }
  }

  private handleExternalValidationFailure(result: ExternalValidationResponse) {
    const languageId = this.sessionStorageService.get('LanguageId') || 0;
    this.localizationService.getErrorContent(result.message, languageId, this.sponsor?.sponsorId).subscribe((errorContent) => {
      this.errorPageMessage = errorContent.content;
      this.isErrorPage = true;
    });
    this.externalValidationFailed = true;
    result.retryRequest ? this.extValidationRetry = true : this.extValidationRetry = false;
  }

  private requiresExternalValidation(): boolean {
    // Check we're not redirected from Azure AD and that the relevant sponsor settings are applied.
    // If enableICECommonAuth on for  your Policies Screen no need to do extravalidation check
    return !this.utilitiesService.getUrlParameterByName('id_token', false)
      && this.sponsor
      && (this.sponsor.enableVoucherBasedAccess || this.sponsor.enableSSOBasedAccess || (this.sponsor.enableICECommonAuth &&
        !this.checkExtCustomerICECommonAuthValidation())
        || this.sponsor.enableGuestPurchase || this.sponsor.enableAssently
        || this.isB2BFlow || this.settingsService.extAuthNav);
  }

  private conditionallyGetExternalValidationStatus(isGuestPurchase: boolean): Observable<ExternalValidationResponse> {
    if ((this.requiresExternalValidation() && this.msalAuthenticationService.isAuthenticated() === false)) {
      const request = new ExternalValidationRequest();
      request.sponsorId = this.sponsor.sponsorId;
      request.data = this.utilitiesService.getAllParameters();
      request.isGuestPurchase = isGuestPurchase;
      request.appName = 'salesv2';
      request.isB2BFlow = this.isB2BFlow;
      request.isSSOSAML = this.settingsService.extAuthNav;

      return this.externalValidationService.getExternalValidationStatus(request);
    }
    return of(null);
  }

  private requiresProductValidation(): boolean {
    // Check we're not redirected from Azure AD and that we have retrieved a Product ID.
    return !(!this.utilitiesService.getUrlParameterByName(ConstantService.ProductIdQueryParamName));
  }

  private conditionallyGetProduct(): Observable<ProductTemplate> {

    const productTemplateUrlId = this.productTemplateUrlId;
    // Let's avoid making this call unless necessary.
    if (this.requiresProductValidation() || productTemplateUrlId) {
      if (productTemplateUrlId && productTemplateUrlId > 0) {
        this.productId = productTemplateUrlId;
      }
      // Don't worry, this is cached on the service for later
      return this.productTemplateService.getProductTemplate(this.productId);
    }
    return of(null);
  }

  private getUserProfileExtAuthLogin(): Observable<any> {
    if (this.utilitiesService.getUrlParameterByName('extauth')) {

      return this.baseAccountService.getDecryptedIdentifier(this.utilitiesService.getUrlParameterByName('identifier'), 0).pipe(
        mergeMap(res => {
          this.sessionStorageService.set('customerIdentifier', res.value);
          return this.baseCustomerService.getCustomerDetailsByIdentifier();
        })
      );

    }
    return of(null);
  }

  private getSponsorErrors(): string {
    if (!this.sponsor || !this.sponsor.b2CEnabled
      || (this.requiresProductValidation() && (!this.productTemplate || !this.productTemplate.activateNewSalesWebSite))) {
      return genericErrorPath;
    }

    if (!this.sponsor.activateNewPublicWebsiteUrl) {
      return inactiveErrorPath;
    }

    return null;
  }

  private setupOnetrustCookieBanner(cookieBannerScriptId: string) {
    const element = document.createElement('script');
    element.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
    element.setAttribute('type', 'text/javascript');
    element.setAttribute('charset', 'UTF-8');
    element.setAttribute('data-domain-script', cookieBannerScriptId);
    document.head.appendChild(element);
  }

  public getSiteActive(): boolean {
    return !this.getSponsorErrors();
  }

  loadLocalizationAndPageContent(): Observable<void> {
    this.applyTranslations();
    this.pageNames = ['Footer', 'ContactUs', 'Home', 'HowTo'];
    this.getUiElementPayload = {
      sponsorEnglishName: this.sponsorName,
      PageNames: this.pageNames,
      productId: this.productId,
      languageId: this.sessionStorageService.get('LanguageId'),
      applicationName: 'Sales'
    };
    const getUIElement: Observable<IGetUIElement[]> = this.localizationService.getUIElements(this.getUiElementPayload);
    const getTermsOfUseContent: Observable<ITermsOfUse> = this.sponsorService.getTermsOfUsebySponsorName(this.sponsorName,
      this.baseSettingsService.languageId.toString());
    const getGlobalPrivacyPolicy: Observable<IPrivacyNoticeLanguage>
      = this.referenceService.getGlobalPrivacyPolicyText(this.baseSettingsService.languageCode);
    const getCookieNoticeData: Observable<ICookieNoticeLanguage>
      = this.referenceService.getCookieNoticeText(this.baseSettingsService.languageCode);

    return forkJoin([getUIElement, getTermsOfUseContent, getGlobalPrivacyPolicy, getCookieNoticeData])
      .pipe(map(results => {
        const [resUiElement, resTermsOfUse, resGlobalPrivacyPolicyText, resCookieNoticeData] = results;
        // this.localizationService.setUIElementObj(resUiElement); // set result of UI element into shared service
        this.localizationService.sharedUIElement = resUiElement;

        this.privacyPolicyData = resGlobalPrivacyPolicyText;
        this.cookieNoticeData = resCookieNoticeData;
        this.termsAndConditionsData = resTermsOfUse;

        const copyrightsTextElement = resUiElement.find(item => item.uiElementCode === 'lblCopyRightsText');
        const privacyPolicyLinkElement = resUiElement.find(item => item.uiElementCode === 'lblPrivacyAndCookiesText');
        const cookieNoticeLinkElement = resUiElement.find(item => item.uiElementCode === 'lblCookiesText');
        const termsAndConditionsLinkElement = resUiElement.find(item => item.uiElementCode === 'lblTermsOfUseText');
        const contactUsTitleElement = resUiElement.find(item => item.uiElementCode === 'lblContactUs');
        const contactUsContentElement = resUiElement.find(item => item.uiElementCode === 'lblTextContactUsPage');
        const contactUsContactNumberElement = resUiElement.find(item => item.uiElementCode === 'lblContactUsContactNumber');
        const contactUsPageElement = resUiElement.find(item => item.uiElementCode === 'lblContactUsPage');
        const sponsorSpecificPrivacyPolicyElement = resUiElement.find(item => item.uiElementCode === 'lblSponsorSpecificPrivacyPolicyText');
        const lblFooterComplaintsLabel = resUiElement.find(item => item.uiElementCode === 'lblFooterComplaintsLabel');
        const lblFooterComplaintsText = resUiElement.find(item => item.uiElementCode === 'lblFooterComplaintsText');
        const lblLegalNoticeLabel = resUiElement.find(item => item.uiElementCode === 'lblLegalNoticeLabel');
        const lblLegalNoticeText = resUiElement.find(item => item.uiElementCode === 'lblLegalNoticeText');
        this.copyrightsText = copyrightsTextElement?.uiElementValue;
        this.privacyPolicyLink = privacyPolicyLinkElement?.uiElementValue;
        this.cookieNoticeLink = cookieNoticeLinkElement?.uiElementValue;
        this.termsAndConditionsLink = termsAndConditionsLinkElement?.uiElementValue;
        this.sponsorSpecificPrivacyPolicyLink = sponsorSpecificPrivacyPolicyElement?.uiElementValue;
        this.lblFooterComplaintsContent = lblFooterComplaintsText?.uiElementValue ? lblFooterComplaintsText?.uiElementValue : '';
        this.lblFooterComplaintsLabel = lblFooterComplaintsLabel?.uiElementValue;
        this.lblLegalNoticeContent = lblLegalNoticeText?.uiElementValue ? lblLegalNoticeText?.uiElementValue : '';
        this.lblLegalNoticeLabel = lblLegalNoticeLabel?.uiElementValue;

        if (this.sponsorSpecificPrivacyPolicyLink != null && this.sponsorSpecificPrivacyPolicyLink !== '') {
          this.privacyPolicyContent = this.sponsorSpecificPrivacyPolicyLink;
        } else {
          this.privacyPolicyContent = this.privacyPolicyData?.privacyNoticeDescription;
        }
        this.baseSettingsService.privacyPolicyData = this.privacyPolicyContent;
        const contactUsTitle = contactUsTitleElement?.uiElementValue;
        const contactUsContent = contactUsContentElement?.uiElementValue;
        const contactUsTelephoneNumber = contactUsContactNumberElement?.uiElementValue;
        const contactUsPage = contactUsPageElement?.uiElementValue;

        const menuLinks = [
          {
            key: 'contactUs',
            label: contactUsTitle ? contactUsTitle : '',
            openTo: 'bottomsheet',
            title: contactUsTitle,
            content: contactUsContent,
            telephoneNumber: contactUsTelephoneNumber,
            enableCloseBtn: false,
            overlayPanelClass: 'fullWidthBottomSheet',
            helpText: contactUsPage
          },
          {
            key: 'privacyPolicy',
            label: this.privacyPolicyLink,
            openTo: 'bottomsheet',
            title: this.privacyPolicyLink,
            content: this.privacyPolicyContent,
            enableCloseBtn: true,
            overlayPanelClass: 'fullWidthBottomSheet',
            buttonText: this.okButtonText
          },
          {
            key: 'termsOfUse',
            label: this.termsAndConditionsLink,
            openTo: 'bottomsheet',
            title: this.termsAndConditionsLink,
            content: this.termsAndConditionsData?.termsOfUseDescription,
            enableCloseBtn: true,
            overlayPanelClass: 'fullWidthBottomSheet',
            buttonText: this.okButtonText
          },
          {
            key: 'cookies',
            label: this.cookieNoticeLink,
            openTo: 'bottomsheet',
            title: this.cookieNoticeLink,
            content: this.cookieNoticeData?.description,
            enableCloseBtn: true,
            overlayPanelClass: 'fullWidthBottomSheet',
            buttonText: this.okButtonText
          },
          {
            key: 'lblFooterPlaceholderLabel',
            label: this.lblFooterComplaintsLabel,
            openTo: this.lblFooterComplaintsContent !== '' ? 'bottomsheet' : '',
            title: this.lblFooterComplaintsLabel,
            content: this.lblFooterComplaintsContent,
            enableCloseBtn: true,
            overlayPanelClass: 'fullWidthBottomSheet',
            buttonText: this.okButtonText
          },
          {
            key: 'lblLegalNoticeLabel',
            label: this.lblLegalNoticeLabel,
            openTo: this.lblLegalNoticeContent !== '' ? 'bottomsheet' : '',
            title: this.lblLegalNoticeLabel,
            content: this.lblLegalNoticeContent,
            enableCloseBtn: true,
            overlayPanelClass: 'fullWidthBottomSheet',
            buttonText: this.okButtonText
          }

        ];
        this.footerLinksAndContent = {
          key: 'footerlinks',
          copyrightsText: this.copyrightsText,
          menuLinks
        };

        // Show content.
        this.initialized = true;
      }));
  }

  private loadErrorPageContent(): void {
    const languageId = this.sessionStorageService.get('LanguageId') || 0;
    this.localizationService.getErrorContent(this.getSponsorErrors(), languageId, this.sponsor?.sponsorId).subscribe((errorContent) => {
      this.errorTitle = errorContent.title;
      this.errorContent = errorContent.content;
      this.initialized = true;
    });
  }

  private async loadLanguageSpecificContent() {
    this.isLoading = true;
    this.translate.store.currentLang = undefined;
    await lastValueFrom(this.translate.use(this.settingsService.languageCode));
    await lastValueFrom(this.loadLocalizationAndPageContent());

    this.url = this.sessionStorageService.get('previousUrl');
    // If external authentication navigation is enabled, set the URL to 'yourPolicies'
    const idToken = this.utilitiesService.getUrlParameterByName('identifier');
    if (this.settingsService?.extAuthNav && idToken) {
      this.url = 'yourPolicies';
    }

    // Each route object has a url, route, optional queryParams, and optional clearRoutes property
    // clearRoutes: whether to clear previous routes when navigating to this route
    // checkFunction: a function that returns a boolean to determine whether to navigate to the route
    const routes = [
      {
        url: 'user-details',
        route: ['/user-details/register'],
        queryParams: this.url?.includes('ForcedLogin') ? { Login: 'ForcedLogin' } : undefined,
        clearRoutes: true
      },
      { url: '/quote/quote-questions', route: ['/quote/quote-questions'] },
      { url: 'saved-quotes', route: ['/quote/saved-quotes'] },
      { url: 'renewal-quotes', route: ['/quote/renewal-quotes'] },
      { url: '/payment/confirm-success', route: ['/payment/confirm-success'] },
      { url: 'expiredlink', route: ['expiredlink'] },
      { url: '/logout', route: ['logout'] },
      { url: 'savedQuote', route: ['/quote/saved-quotes'] },
      { url: 'docusigncallback', route: ['/policy/docusigncallback'], queryParams: { event: 'signing_complete' } },
      { url: 'policy/review-quote', route: ['policy/review-quote'] },
      { url: 'account-details/your-policies', route: ['account-details/your-policies'] },
      { url: '/error-page', route: ['/error-page'], checkFunction: this.checkExtCustomerICECommonAuthValidation.bind(this) },
      { url: '/login', route: ['/login'] },
      { url: 'yourPolicies', route: ['account-details/your-policies'] }
    ];

    // Loop over each route
    for (const route of routes) {
      if ((this.url?.includes(route.url) || window.location.href?.includes(route.url))) {
        // If a checkFunction is defined for the route and it returns false, skip this route
        if (route.checkFunction && !route.checkFunction()) {
          continue;
        }
        // Navigate to the route, adding any query parameters if they exist
        this.router.navigate(route.route, { queryParams: route.queryParams });
        if (route.clearRoutes) {
          this.clearPreviousRoutes();
        }
        return;
      }
    }
    this.router.navigate(['./']);
  }

  private clearPreviousRoutes() {
    this.sessionStorageService.remove('currentUrl');
    this.sessionStorageService.remove('previousUrl');
  }

  private setSponsorLanguage() {
    this.sessionStorageService.set('LanguageCode', this.sponsor.languageCode);
    this.sessionStorageService.set('LanguageId', this.sponsor.languageId);
    // The below call is commented as it is already invoked by lanaguageObservable in the constructor.
    // this.sponsorService.languageObservable.next(this.sponsor.languageId);
  }

  private applyTranslations() {
    this.translate.get([okButtonLabel]).subscribe((res) => {
      this.okButtonText = res[okButtonLabel];
    });
  }

  private setLocalDateFormat() {
    if (this.sponsor?.preferredDateFormatId > 0) {
      this.sponsorService.getLocalisedDateFormatById(this.sponsor.preferredDateFormatId).subscribe(result => {
        if (result) {
          if (result.localDateFormat) {
            this.sessionStorageService.set('LocalisedDateFormat', result.localDateFormat);
          }
        }
      });
    } else {
      this.sessionStorageService.set('LocalisedDateFormat', 'DD/MM/YYYY');
    }
  }

  createTermsOfUseScriptBox() {
    this.sponsorService.getJavaScript(this.sessionStorageService.get('SponsorId')).subscribe(result => {
      if (result && result !== null) {
        const element = document.createElement('script');
        element.setAttribute('class', 'terms-of-use');
        element.setAttribute('type', 'text/javascript');
        element.setAttribute('id', result.javaScriptUploadID);
        const inlineCode = document.createTextNode(result?.fileContent);
        element.appendChild(inlineCode);
        document.body.appendChild(element);
      }
    });
  }

  ApplyBrandingSettings() {
    this.sponsorService.getB2CWebsiteBrandingDetails(this.baseSettingsService.sponsorId).subscribe(result => {
      if (result) {
        if (result.isNewUIUXBrandingEnabled) {
          if (Object.entries) {
            // eslint-disable-next-line no-irregular-whitespace
            // Iterate over branding variables, setting relevant properties.       
            if (this.sponsor && this.sponsor.brandingSettings) {
              Object.entries<string>(this.sponsor.brandingSettings).forEach(
                ([key, value]) => document.documentElement.style.setProperty(`--${key}`, value));
            }
          }
        }
        else {
          const element = document.createElement('style');
          const inlineCode = document.createTextNode(result?.content);
          element.appendChild(inlineCode);
          document.head.appendChild(element);
        }
      }
    });
  }

  private setGTagManager(gtmCode: string) {
    const s = document.createElement('script');

    s.type = 'text/javascript';
    s.id = 'GTMScript';
    s.innerHTML = 'window.dataLayer = window.dataLayer || [];'
      + '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push'
      + '({\'gtm.start\':new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)'
      + '[0],j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src='
      + '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);})'
      + '(window,document,\'script\',\'dataLayer\',\'' + gtmCode + '\');';

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(s);

    const ns = document.getElementsByTagName("noscript")[0];
    ns.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=' + gtmCode + '"'
      + ' height="0" width="0" style="display:none;visibility:hidden"></iframe>';
  }

  private setGoogleAnalytics(gaCode: string) {
    // const gACode = atob(sessionStorage.getItem('GA'));
    const scriptS = document.createElement('script');
    scriptS.type = 'text/javascript';
    scriptS.async;
    scriptS.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaCode;
    const scriptT = document.createElement('script');

    scriptT.type = 'text/javascript';
    scriptT.id = 'GAScript';
    scriptT.innerHTML = 'window.dataLayer = window.dataLayer || [];'
      + 'function gtag() { dataLayer.push(arguments); }'
      + 'gtag("js", new Date());'
      + 'gtag("config", ' + gaCode + ');'
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(scriptS);
    head.appendChild(scriptT);
  }

  private checkExtCustomerICECommonAuthValidation() {

    if (this.sessionStorageService.get('isExtCustomerICECommonAuthValidation')) {
      return this.sessionStorageService.get('isExtCustomerICECommonAuthValidation') === 'exists' ? true : false;
    }
    else {
      if ((this.sponsor.enableICECommonAuth || this.settingsService?.enableICECommonAuth) &&
        (this.utilitiesService.getUrlParameterByName('yourPolicies', true)
          || this.utilitiesService.getUrlParameterByName('savedQuote', true))) {
        this.sessionStorageService.set('isExtCustomerICECommonAuthValidation', 'exists');
        return true;
      }
    }
    return false;

  }
  // Stops use to navigate anywhere else on click of back button on Error Page
  private clearSessionRoutesNavigateToError() {
    this.clearPreviousRoutes();
    this.sessionStorageService.remove('ErrorPagePreviousUrl');
    this.router.navigate(['/error-page']);
  }

  public get getPageUniqueSelector(): string {
    if (!this.sponsorName) {
      return;
    }

    const pageId = this.sessionStorageService.get('PageId');
    if (!pageId) {
      return ((this.isProductDetail ? "product-detail" : this.routePrefix) + " " + this.sponsorName
        + "-" + this.routePrefix)?.toLowerCase();
    }
    return ((this.isProductDetail ? "product-detail" : this.routePrefix) + " " + this.sponsorName
      + "-" + this.routePrefix + (pageId ? "-" + pageId : ''))?.toLowerCase();
  }
}
