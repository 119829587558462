import { Injectable } from '@angular/core';
import { MsalAuthenticationService } from '../common-auth/msalauthentication.service';
import { SharedSettingsService } from '../settings/settings-base.service';

// added for question type mapping with control type
type controlMapping = { [key: string ]: string };

@Injectable({
  providedIn: 'root'
})
export class BaseConstantService {

  static readonly SponsorNameQueryParamName = 'sname';
  static readonly ExtAuthNavQueryParamName = 'extauth';
  static readonly ProductIdQueryParamName = 'pid';
  static readonly SkipLandingPage = 'skiplp';
  static readonly Key_IsB2B = 'isb2b';
  static readonly Key_B2BUserName = 'b2busername';
  static readonly Key_B2BEmail = 'b2bemail';
  static readonly Key_IsATPDocumentDownloadForMCI = 'IsATPDocumentDownloadForMCI ';
  static readonly Key_ExistingCustomerQuote = 'ExistingCustomerQuote';
  static readonly Key_IsNetPremiumOverridden = 'IsNetPremiumOverridden';
  static readonly Key_IsQuoteReferredCheck = "IsQuoteReferredCheck";
  static readonly Key_ResumeCustomerId = 'ResumeCustomerId';
  static readonly Key_IsDefaultListViewForMCI = 'IsDefaultListViewForMCI'
  static readonly DefaultDateFormat = 'DD/MM/YYYY';
  static readonly YearMonthDayDateFormat = 'YYYY-MM-DD';
  static readonly WizardSponsorSponsorEnglishName = 'WIZARDSPONSOR';

  static readonly metaDataAllowSearch = 'index, follow';
  static readonly metaDataDenySearch = 'noindex, nofollow';

  static readonly Key_OrderId = 'OrderId';
  static readonly Key_QuoteReferenceID = 'QuoteReferenceID';
  static readonly Key_ProductId = 'ProductId';
  static readonly Key_IsB2cSingleCarrierMultiQuoteEnabled = 'isB2cSingleCarrierMultiQuoteEnabled';
  static readonly Key_IsB2cMultiCarrierQuotingEnabled = 'isB2cMultiCarrierQuotingEnabled';

  static readonly defaultSponsorBrandNameClaims = 'B2C Claims';
  static readonly Key_QuoteOrigin = 'NewSales';
  static readonly Key_Renewals = 'Renewals';
  static readonly Key_RepeatingGroupLength = "RepeatingGroupLength";
  static readonly Key_RepeatingGroupQuestionFormat = "RepeatingGroup";
  static readonly CattributeCustomerType = 'CattributeCustomerType';
  static readonly Key_Province = "PROVINCE";
  static readonly Key_QuoteStatusChangedFromReferred = "QuoteStatusChangedFromReferred"

  static readonly customerTypeList = [
    { id: 0, value: 'Both' },
    { id: 2, value: 'Company' },
    { id: 1, value: 'Person' }
  ]

  static readonly QUOTE_ORIGIN = {
    RENEWALS: 'Renewals',
    NEW_BUSINESS: 'NewBusiness'
  };

  // *** it is for isB2cSingleCarrierMultiQuoteEnabled ***/
  static readonly CoverageTerm = 'CoverageTerm';
  // static readonly DiscountRate = 'DiscountRate';

  //* ** PolicySearch Clone >> START */
  static readonly KEY_PolicyIdToBeCloned = 'PolicyIdToBeCloned';
  //* ** PolicySearch Clone >> END */

  static readonly KEY_PrimaryQuestionData = 'primaryQuestionData';
  static readonly KEY_AnsweredQuestionSet = 'AnsweredQuestionSet'
  static readonly KEY_IsRefreshRequired = 'IsRefreshRequired'
  static readonly IsCopyQuoteAtpClientQueryParamName = 'isCopyQuoteAtpClient';
  static readonly ProductTemplateIdQueryParamName = 'prtmpid';
  static readonly PolicyExternalRef = "PolicyExternalRef";
  static readonly KEY_PrimaryGrossPremium="PrimaryGrossPremium";
  // static readonly KEY_DiscountRate="DiscountRate";

  static readonly KEY_hidePolicyQuestionsEdit = 'HidePolicyQuestionsEdit'

  static readonly QuestionType = {
    Subtotal: 'SUBTOTAL',
    TaxOnPremium: 'TAXONPREMIUM',
    TaxSubtotal: 'TAXSUBTOTAL',
    Charge: 'CHARGE',
    TaxOnCharge: 'TAXONCHARGE',
    Commission: 'TAXONCHARGE',
    Policy: 'POLICY'
  };

  static mappedControlType: controlMapping = {
    dropdown: "SELECT",
    text: "TEXT",
    checkbox: "CHECKBOX",
    datepicker: "DATEPICKER",
  }

  static readonly Session = {
    msalIdToken: 'msal.idtoken',
    idTokenExpiryTime: 'idTokenExpiryTime',
    atpLoginExpiryTime: 'ATPLoginExpiryTime'
  }

  constructor(
    private msalAuthenticationService: MsalAuthenticationService,
    private settingsService: SharedSettingsService) { }

  async getLogOutText(logoutText: string) {

    // *** If 'VoucherBasedAcces' is enable then 'LogOut' option shouldn't be shown to users *** //
    const enableVoucherBasedAccess = this.settingsService.enableVoucherBasedAccess;
    if (enableVoucherBasedAccess === true) {
      return '';
    }

    // *** Once the user is 'LoggedIn' then only 'LogOut' option should be shown to users *** //
    return await this.msalAuthenticationService.authenticated().then(result => {
      if (!result) {
        return '';
      }
      return logoutText;
    });
  }


}
