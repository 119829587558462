import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalAuthenticationService } from '../../../services/common-auth/msalauthentication.service';
import { PagingService } from '../../../services/app-insights/paging-service';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';

@Component({
  template: ''
})
export abstract class LoginBaseComponent implements OnInit {

  protected returnUrl: string;
  protected currentUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public msalService: MsalAuthenticationService,
    private metaTag: Meta,
    public sharedSettingsService: SharedSettingsService,
    private pagingService: PagingService) { }

  async ngOnInit() {
    this.metaTag.updateTag(
      { name: 'robots', content: 'noindex, nofollow' } // Todo: define as constant.
    );
    const enableGuestPurchase = this.sharedSettingsService.enableGuestPurchase;
    const pageName = enableGuestPurchase ? 'Guest Purchase' : 'Login';
    this.pagingService.registerPageDetails(pageName);

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.currentUrl = this.route.snapshot.queryParams['currentUrl'];

    if (this.returnUrl) {
      this.sharedSettingsService.previousUrl = this.returnUrl;
    }

    if (this.currentUrl) {
      this.sharedSettingsService.currentUrl = this.currentUrl;
    }

    await this.authenticate();
  }

  protected async authenticate() {
    this.msalService.authenticated()
      .then(result => {
        if (result) {
          this.router.navigate([this.returnUrl || '/']);
        } else {
          // If token is expired, redirect the user to login page.
          console.log('Token expired');
          if (sessionStorage.getItem('forgotPassword') == null) {
            this.msalService.login();
          } else {
            sessionStorage.removeItem('forgotPassword');
          }

        }
      }).catch(error => {
        console.log('login.component error ' + error);
        this.msalService.login();
      });
  }
}
