<ng-container>
    <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription"
        [QnSubDescription]="qnData.qSubDescription">
    </lib-data-capture-heading>
    <lib-currency-box [field]="field" [form]="currencyForm"></lib-currency-box>
    <div class="next-btn-container" [class.disable-container]="currencyForm?.invalid">
        <lib-primary-button id="btnNext" [text]="nextBtn" [disabled]="currencyForm?.invalid"
            (clicked)="submitAnswer(qnData.answerSets[0])" autofocus></lib-primary-button>
    </div>
</ng-container>
