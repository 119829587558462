import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormFieldBase } from '../../shared/form-field/form-field-base';
import { TextboxField } from '../../shared/form-field/textbox-field';
import { of } from 'rxjs';
import { TextAreaField } from '../../shared/form-field/textarea-field';

import { CurrencyBoxField } from '../../shared/form-field/currencybox-field';
import { SessionStorageService } from 'angular-web-storage';
import { SharedSettingsService } from '../../services/settings/settings-base.service'

export function customValidatorsToFieldForMaxRange(field: TextboxField) {
  return (control: AbstractControl) => {
    let maxErrorFlag = false;
    const deLocalizedValue = this.sharedSettingsService.reverseFormatNumber(control.value);
    if ((field.questionFormat.toLowerCase() === 'numeric') && field.maxRange !== undefined
      && (Number(deLocalizedValue) > field.maxRange)) {
      maxErrorFlag = true;
    } else {
      maxErrorFlag = false;
    }
    return maxErrorFlag ? { max: true } : null;
  }
}

export function customValidatorsToFieldForMinRange(field: TextboxField) {
  return (control: AbstractControl) => {
    let minxErrorFlag = false;
    const deLocalizedValue = this.sharedSettingsService.reverseFormatNumber(control.value);
    if ((field.questionFormat.toLowerCase() === 'numeric') && field.minRange !== undefined
      && (Number(deLocalizedValue) < field.minRange)) {
      minxErrorFlag = true;
    } else {
      minxErrorFlag = false;
    }
    return minxErrorFlag ? { min: true } : null;
  }
}
export function customValidationToFieldForPattern(languageCode) {
  return (control: AbstractControl) => {
    let pattern = false;
    const thousandSeparator = new Intl.NumberFormat(languageCode).format(1111).replace(/1/g, '');
    const decimalSeparator = new Intl.NumberFormat(languageCode).format(1.1).replace(/1/g, '');
    const findDecimal = control.value?.split(decimalSeparator)[1];
    if (findDecimal?.includes(thousandSeparator)) {
      pattern = true;
    } else if (findDecimal?.length > 2) {
      pattern = true;
    }

    return pattern ? { pattern: true } : null;
  }
}

export function applyMaxLengthValidation(field: CurrencyBoxField) {
  return (control: AbstractControl) => {
    let maxLengthErrorFlag = false;
    const deLocalizedValue = this.sharedSettingsService.reverseFormatNumber(control.value);
    if ((field.questionFormat.toLowerCase() === 'money') && field.maxLength !== undefined
      && (Number(deLocalizedValue) > 0) && (deLocalizedValue.length > field.maxLength)) {
      maxLengthErrorFlag = true;
    } else {
      maxLengthErrorFlag = false;
    }
    return maxLengthErrorFlag ? { maxLength: true } : null;
  }
}

export function applyMinLengthValidation(field: CurrencyBoxField) {
  return (control: AbstractControl) => {
    let minLengthErrorFlag = false;
    const deLocalizedValue = this.sharedSettingsService.reverseFormatNumber(control.value);
    if ((field.questionFormat.toLowerCase() === 'money') && field.minLength !== undefined
      && (Number(deLocalizedValue) > 0) && (deLocalizedValue.length < field.minLength)) {
      minLengthErrorFlag = true;
    } else {
      minLengthErrorFlag = false;
    }
    return minLengthErrorFlag ? { minLength: true } : null;
  }
}

@Injectable({
  providedIn: 'root'
})

export class FormGroupControlService {
  constructor(private sessionStorage: SessionStorageService,
              private sharedSettingsService: SharedSettingsService
  ) { }

  toFormGroup(fields: FormFieldBase<string>[], isGroupCheckbox?: boolean) {
    return new FormGroup(this.addValidators(fields, isGroupCheckbox));
  }

  toFormControlArray(fields: FormFieldBase<string>[]) {
    return this.addValidators(fields);
  }

  formFieldswithOrder(formFields: FormFieldBase<string>[]) {
    return of(formFields.sort((a, b) => a.order - b.order));
  }

  private addValidators(fields: FormFieldBase<string>[], isGroupCheckbox?: boolean): any {
    const group: any = {};
    let fieldValue;

    fields.forEach(field => {
      const validatorArray = this.addValidatorsToField(field);
      if (field.controlType === 'checkbox') {
        fieldValue = field.value ? Boolean(JSON.parse(field.value)) : false;
        if (isGroupCheckbox && isGroupCheckbox === true) {
          group[field.key] = new FormControl(fieldValue);
        }
        else {
          group[field.key] = new FormControl(fieldValue, field.required ? Validators.requiredTrue : null);
        }
      }
      else if (validatorArray.length === 0) {
        fieldValue = field.value === '0' ? '0' : field.value;
        group[field.key] = fieldValue === 0 ? new FormControl(0) : new FormControl(fieldValue || '');
      } else {
        group[field.key] = new FormControl(field.value || '', validatorArray);
      }
    });

    return group;
  }

  // move this validation array to a common place to reuse this in other places.
  public addValidatorsToField(field: FormFieldBase<string>): any[] {
    const validatorArray = [];
    if (field.required) {
      validatorArray.push(Validators.required);
    }
    if (field.minLength > 0) {
      validatorArray.push(Validators.minLength(field.minLength));
    }

    if (field.controlType === 'textbox') {
      const textBoxField = field as TextboxField;
      if (textBoxField.type === 'email') {
        validatorArray.push(Validators.email);
      }

      const quesetionformat = textBoxField && textBoxField.questionFormat ? textBoxField.questionFormat.toLowerCase() : '';
      if (quesetionformat === 'text' || quesetionformat === 'numeric' || quesetionformat === 'money') {
        const minLength = textBoxField.minLength;
        if (minLength && minLength > 0) {
          validatorArray.push(Validators.minLength(minLength));
        }
        const maxLength = textBoxField.maxLength;
        if (maxLength && maxLength > 0) {
          validatorArray.push(Validators.maxLength(maxLength));
        }
        const regexValidation = textBoxField.regexValidation;
        if (regexValidation) {
          validatorArray.push(Validators.pattern(regexValidation));
        }
      }

      if (quesetionformat === 'numeric') {
        if (textBoxField.localization) {
          const languageCode = this.sharedSettingsService.cultureName;
          validatorArray.push(Validators.pattern('[0-9., ]*$'))
          validatorArray.push(customValidatorsToFieldForMaxRange.bind(this)(field as TextboxField));
          validatorArray.push(customValidatorsToFieldForMinRange.bind(this)(field as TextboxField));
          validatorArray.push(customValidationToFieldForPattern(languageCode));
        } else {
          const minRange = textBoxField.minRange;
          if (minRange) {
            validatorArray.push(Validators.min(minRange));
          }
          const maxRange = textBoxField.maxRange;
          if (maxRange) {
            validatorArray.push(Validators.max(maxRange));
          }
          validatorArray.push(Validators.pattern('^[-]?[0-9., ]*$'));
        }
      }

    }
    if (field.controlType === 'textarea') {
      const textAreaField = field as TextAreaField;
      const quesetionformat = textAreaField && textAreaField.questionFormat ? textAreaField.questionFormat.toLowerCase() : '';
      if (quesetionformat === 'textarea') {
        const minLength = textAreaField.minLength;
        if (minLength && minLength > 0) {
          validatorArray.push(Validators.minLength(minLength));
        }
        const maxLength = textAreaField.maxLength;
        if (maxLength && maxLength > 0) {
          validatorArray.push(Validators.maxLength(maxLength));
        }
      }
    }

    if (field.controlType === 'money') {
      const currencyText = field as CurrencyBoxField;
      const quesetionformat = currencyText && currencyText.questionFormat ? currencyText.questionFormat.toLowerCase() : '';
      if (quesetionformat === 'money') {
        const languageCode = this.sharedSettingsService.cultureName;
        const minLength = currencyText.minLength;
        if (minLength && minLength > 0) {
          validatorArray.push(applyMinLengthValidation.bind(this)(field as CurrencyBoxField));
        }
        const maxLength = currencyText.maxLength;
        if (maxLength && maxLength > 0) {
          validatorArray.push(applyMaxLengthValidation.bind(this)(field as CurrencyBoxField));
        }
        validatorArray.push(Validators.pattern('[0-9., ]*$'))
        validatorArray.push(customValidationToFieldForPattern(languageCode));
      }
    }
    return validatorArray;
  }
}
