<ng-container>
    <lib-data-capture-heading id="dataCaptureHeading" [QnDescription]="qnData.qDescription"
        [QnSubDescription]="qnData.qSubDescription">
    </lib-data-capture-heading>
    <lib-text-box [field]="field" [form]="numericForm"></lib-text-box>
    <div class="next-btn-container" [class.disable-container]="numericForm?.invalid">
        <lib-primary-button id="btnNext" [text]="nextBtn" [disabled]="numericForm?.invalid"
            (clicked)="submitAnswer(qnData.answerSets[0])" autofocus></lib-primary-button>
    </div>
</ng-container>
