import { Component, EventEmitter, Input, Output, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { IQuoteSummary } from '../../../shared/models';
import { Subject, debounceTime } from 'rxjs';
import { QuotationService } from '../../../services/quotation/quotation.service';
import { SessionStorageService } from 'angular-web-storage';
import { QuestionSetService, SharedSettingsService } from 'wtw-ui-components';
import { PolicySection } from '../../../shared/models/policy-section.model';

@Component({
  selector: 'app-policy-summary',
  templateUrl: './policy-summary.component.html',
  styleUrls: ['./policy-summary.component.scss']
})
export class PolicySummaryComponent {

  @Input() quoteSummary: IQuoteSummary;
  @Input() isApproveDeclineOnly: boolean;
  netPremiumChangeSubject = new Subject<string>();
  rateForDisplay: string;
  isReady: boolean = false;
  isUndoDisabled: boolean = true;
  isSpinner: boolean = false;
  isB2B: boolean = false;
  isExistingQuote: boolean = false;
  policySection: PolicySection;
  isEditWithoutPremiumOverride: boolean = false;

  constructor(
    private quotationService: QuotationService,
    private cdr: ChangeDetectorRef,
    public sessionStorage: SessionStorageService,
    private settingsService: SharedSettingsService,
    private questionSetService: QuestionSetService
    ) {
    this.netPremiumChangeSubject.pipe(debounceTime(1000)).subscribe((value) => {
      this.policySection.isNetPremiumOverride = true;
      this.quotationService.updatePolicySection(this.policySection);
      this.isUndoDisabled = false;
      this.cdr.detectChanges();
    });

    this.quotationService.GetRecalculateClickedSubject.subscribe((value) => {
      if (value) {
        this.isSpinner = true;
      }
    });
  }

  ngOnInit() {
    this.isB2B = this.sessionStorage.get('isb2b');
    this.isExistingQuote = this.settingsService.existingCustomerQuote;
    this.setPolicySection();
    this.isReady = true;

    this.questionSetService.referralPermissionSet.subscribe((permissions) => {
      if (permissions) {
        const retrievedPermissions = this.questionSetService.getReferralPermission();
        this.isEditWithoutPremiumOverride = retrievedPermissions.isEditWithoutPremiumOverride
      }
    });
  }

  ngOnChanges() {
    this.setPolicySection();
    if (this.isReady) {
      this.isSpinner = false;
      this.cdr.detectChanges();
    }
  }

  setPolicySection() {
    this.policySection = {
      totalNetPremium: this.quoteSummary.ratingBindingInformations.originalNetRate,
      subtotalTaxModel: JSON.parse(JSON.stringify(this.quoteSummary.ratingBindingInformations.policySectionDisplay)),
      isNetPremiumOverride: false
    }
  }

  reCalculateTax(event: any) {

    // Allow only numbers and one dot, restrict to 2 decimal places
    const inputValue = event.target.value;
    const formattedValue = isNaN(inputValue) ? inputValue.slice(0, -1) : inputValue.replace(/[^0-9.]+/g, '').replace(/(\.\d{2})\d+/, '$1');
    event.target.value = formattedValue;

    this.netPremiumChangeSubject.next(event.target.value);
  }

  onUndo() {
    this.quotationService.UpdateUndoPremiumSubject(true);
  }
}
