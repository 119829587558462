import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { LayoutService } from '../../../../services/layout/layout.service';
declare global {
  interface Window { dataLayer: any[]; }
}
@Component({
  selector: 'lib-tile-with-image',
  templateUrl: './tile-with-image.component.html',
  styleUrls: ['./tile-with-image.component.scss']
})
export class TileWithImageComponent implements OnInit {
  public isMobileView: boolean;
  public isTabletView: boolean;
  public isDesktopView: boolean;
  public isB2BFlow: boolean = false;
  public hideEditButton: boolean = false;
  @Input() tag: any;
  @Input() image: any;
  @Input() heading: string;
  @Input() subheading: string;
  @Input() calltoAction: string;
  @Input() linkTotext: string;
  @Input() isImageLeft: any;
  @Input() isButtonVisible: boolean;
  @Input() newOrEditQuoteAllowed: boolean;
  @Output() buttonClicked = new EventEmitter<any>();
  @Output() linkClicked = new EventEmitter<any>();

  constructor(private layoutService: LayoutService,
              private sharedSettingService: SharedSettingsService) { }

  ngOnInit() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobileView = this.layoutService.isSmallView();
      this.isDesktopView = this.layoutService.isLargeView();
      this.isTabletView = this.layoutService.isMediumView();
    });
    this.subheading = this.subheading && this.subheading.replace('<br/>', '');
    this.subheading = this.subheading && this.subheading.replace('<br />', '');
    this.subheading = this.subheading && this.subheading.replace('<br>', '');

    this.isB2BFlow = this.sharedSettingService.isB2B();
    this.hideEditButton = (this.isB2BFlow === false && this.newOrEditQuoteAllowed === false) ? true : false;
  }

  public onLinkClicked(): void {
    this.linkClicked.emit(this.tag);
  }

  public onButtonClicked(): void {
    // To send product information in GTM and Google Analytics using custom event
    if (window.dataLayer !== undefined || window.dataLayer !== null) {
    window.dataLayer.push({
      event: 'trackEvent',
      eventCategory: 'SyA',
      eventAction: 'Click',
      eventLabel: this.tag.productname
    });
  }
    this.buttonClicked.emit(this.tag);
  }
}
