import { DatePickerField } from '../form-field/date-picker-field';
import { DropDownField } from '../form-field/dropdown-field';
import { RadioGroupField } from '../form-field/radio-group-field';
import { TextboxField } from '../form-field/textbox-field';
import { CountryCodePhoneNumberField } from '../form-field/CountryCodePhoneNumber-Field';
import { CountryCodeLookupData } from './user-profile.model';


export class YourProfileData {
  personalDetails: PersonalDetails = new PersonalDetails();
  manageAddresses: ManageAddresses = new ManageAddresses();
  languagePreference: LanguagePreference = new LanguagePreference();
  consentPreferences: ConsentPreferences = new ConsentPreferences();
  contactPreferences: ContactPreferences = new ContactPreferences();
}

export class PersonalDetails {
  pageInfo: PageInfo = new PageInfo();
  title?: DropDownField = new DropDownField();
  ssn?: TextboxField = new TextboxField();
  firstName?: TextboxField = new TextboxField();
  lastName?: TextboxField = new TextboxField();
  email?: TextboxField = new TextboxField();
  dob?: DatePickerField = new DatePickerField();
  placeOfBirth?: TextboxField = new TextboxField();
  placeOfBirthTown?: TextboxField = new TextboxField();
  phoneNumber?: TextboxField = new TextboxField();
  countryCode?: CountryCodePhoneNumberField = new CountryCodePhoneNumberField();
  countryCodeLookupData?: CountryCodeLookupData[];
  countryCodeRequired?: boolean;
  countryCodeReadOnly?: boolean;
  selectedCountryCode?: string;
  countryCodeLabel?: string;
  showCountryCodeCtrl?: boolean;
  tooltipText: string;
  isEditMode: boolean;
  email2?: TextboxField = new TextboxField();
}

export class ManageAddresses {
  pageInfo: PageInfo = new PageInfo();
  addressList: AddressDetails[] = [];
  addressLine1?: TextboxField = new TextboxField();
  town?: TextboxField = new TextboxField();
  postalCode?: TextboxField = new TextboxField();
  country?: DropDownField = new DropDownField();
  defaultAddress: { key: string, value: boolean };
  addressId: { key: string, value: number };
}

export class AddressDetails {
  addressId = 0;
  addressLine1 = '';
  town = '';
  postalCode = '';
  country = '';
  countryId = '';
  defaultAddress = false;
  disableMyAccountCreationB2C=false;
}

export class LanguagePreference {
  pageInfo: PageInfo = new PageInfo();
  isMultiLanguageEnabled = false;
  currentLanguage: DropDownField = new DropDownField();
  isEditMode: boolean;
}

export class ConsentPreferences {
  pageInfo: PageInfo = new PageInfo();
  isConsentPreferencesEnabled = false;
  policyHeading = '';
  policyDescription = '';
  consentList: PolicyConsent[] = [];
  isEditMode: boolean;
}

export class PolicyConsent {
  consentHeading = '';
  seeLegalText = '';
  hideLegalText = '';
  consentLegalText = '';
  consent: RadioGroupField = new RadioGroupField();
}

export class ContactPreferences {
  pageInfo: PageInfo = new PageInfo();
  isContactPreferencesEnabled = false;
  contactTypeList: ContactType[] = [];
  isEditMode: boolean;
}

export class ContactType {
  contact: RadioGroupField = new RadioGroupField();
}

export class PageInfo {
  headingText = '';
  subheadingText = '';
  editHeaderText = '';
  addHeaderText = '';
  note = '';
  icon = '';
  btnEditText = '';
  btnSaveText = '';
  btnCancelText = '';
  defaultAddressText = '';
}
