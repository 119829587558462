import { FormFieldBase } from './form-field-base';
import { AlertMessage } from '../models';

export class CountryCodePhoneNumberField extends FormFieldBase<string> {
  controlType = 'countrycodephonenumber';
  options: { name: string, value: string, alertMessage?: AlertMessage, index?: number}[] = [];
  minLength: number;
  maxLength: number;
  questionFormat: string;
  regexValidation: string;
  correctFormatValidationMsg: string;
  constructor(options: {
    value?: string,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    type?: string,
    readOnly?: boolean,
    iconName?: string,
    mandatoryValidationMsg?: string | string[],
    callToActionLabel?: string,
    minLength?: number,
    maxLength?: number,
    questionFormat?: string,
    regexValidation?: string,
    correctFormatValidationMsg?: string
    options?: { name: string, value: string }[]
  } = {}) {
    super(options);
    this.options = options['options'] || [];
    this.minLength = options.minLength;
    this.maxLength = options.maxLength;
    this.questionFormat = options.questionFormat;
    this.regexValidation = options.regexValidation;
    this.correctFormatValidationMsg = options.correctFormatValidationMsg;
  }
}
