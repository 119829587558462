import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Question } from '../../../models';

@Component({
  selector: 'lib-plus-minus-data-capture',
  templateUrl: './plus-minus-data-capture.component.html',
  styleUrls: ['./plus-minus-data-capture.component.scss']
})
export class PlusMinusDataCaptureComponent implements OnInit {
  @Input() qnData: Question;
  @Output() updateEmitter = new EventEmitter();
  @Input() nextBtn: string;
  incrmentDecrementVal = 1;
  constructor() { }

  ngOnInit() {

  }

  submitIncDecAnswer(answeredData): void {
    answeredData.name = answeredData.value = this.incrmentDecrementVal;
    answeredData.isAnswered = true;
    this.updateEmitter.emit();
  }
}
