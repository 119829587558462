import { Injectable } from '@angular/core';
import { FormFieldMapper } from './field-mapper-base';
import { IFieldMapperStrategy } from './field-mapper-strategy';
import { Question } from '../../../../models';
import { DropDownField } from '../../../../form-field/dropdown-field';
import { SessionStorage } from 'angular-web-storage';



@Injectable({
  providedIn: 'root'
})
export class DropDownFieldMapper extends FormFieldMapper<DropDownField> implements IFieldMapperStrategy {

  public order = 0;

  public canMap(question: Question): boolean {
    return question.qQuestionFormat.toLowerCase() === 'dropdown';
  }

  protected mapSpecificProperties(field: DropDownField, question: Question): void {
    // Check if this question has been previously answered.
    const isPreviouslyAnswered = question.answerSets.some(as => as.isAnswered);

    // Set value to default if the drowpdown options contains unique value
    // if (question.answerSets?.length === 1) {
    //   question.answerSets[0].default = true;
    // }

    // code to find whether intentcode is configure with metadata property
    let isMetadata;
    const parentQues = JSON.parse(JSON.parse(sessionStorage.getItem('primaryQuestionData'))._value)
    [JSON.parse(sessionStorage.getItem('QuestionSetPosition'))?._value]

    parentQues?.childQuestionSets?.filter(ques => {
      if (ques?.qIntentCode === question?.onDemandDataParameters?.split(',').map(s => s.trim())[0]
        && ques.globalFunction?.toLowerCase()?.includes('metadata')) {
        isMetadata = true;

      }
    })
    for (const answer of question.answerSets) {
      if (!answer.active) {
        continue;
      }
      if (question?.globalFunction?.toLowerCase()?.includes('metadata') && question?.globalFunction?.toLowerCase()?.includes('source')
        || isMetadata) {
        if (answer.alertMessage) {
          field.options.push({
            name: answer.name,
            value: answer.textValue,
            alertMessage: answer.alertMessage,
            childQIntentCode: answer.childQIntentCode
          });
        } else {
          field.options.push({
            name: answer.name,
            value: answer.name ? answer?.name : '',
            id: answer?.id ? answer?.id : answer.textValue,
            childQIntentCode: answer.childQIntentCode
          });
        }
      }
      else {
        if (answer.alertMessage) {
          field.options.push({
            name: answer.name,
            value: answer.name,
            alertMessage: answer.alertMessage,
            childQIntentCode: answer.childQIntentCode
          });
        } else {
          field.options.push({
            name: answer.name,
            value: answer.name,
            childQIntentCode: answer.childQIntentCode
          });
        }
      }


      // If previously answered, reinstate this value.
      if (answer.isAnswered) {
        field.value = answer.name;
      } else if (!isPreviouslyAnswered && answer.default) {
        field.value = answer.name;
      }
    }

    const newOrEditQuoteAllowed = JSON.parse(sessionStorage.getItem('newOrEditQuoteAllowed'))?._value;
    const isB2BFlow = JSON.parse(sessionStorage.getItem('isb2b'))?._value;

    if ((question.qQuestionType.toLowerCase() === 'quote') && (newOrEditQuoteAllowed === false) && isB2BFlow === false ||
      (question?.globalFunction?.toLowerCase()?.includes('dynamic') && !question?.globalFunction?.toLowerCase()?.includes('source')
        && (question?.answerSets?.length === 1))) {
      field.readOnly = true;
      if ((question?.globalFunction?.toLowerCase()?.includes('dynamic') && !question?.globalFunction?.toLowerCase()?.includes('source')
        && (question?.answerSets?.length === 1))) {
        field.disableMatFormField = true;
      }
    }
    else if (question?.lookupPQIntentCode && question?.lookupPQIntentCode !== '' && question?.lookupPQIntentCode !== null) {
      field.disableMatFormField = true;
      field.readOnly = true
    }
    field.globalFunction = question.globalFunction;
    field.onDemandDataParameters = question.onDemandDataParameters;
    field.onDemandDataSubscribers = question.onDemandDataSubscribers;
    field.odefault = question.odefault;
  }

  protected getInstance() {
    return new DropDownField();
  }
}
