import { animate, style, transition, trigger } from '@angular/animations';

export const slideInTrigger = trigger('slideInAnimation', [
  transition(':enter', [
    style({
      transform: 'translateY(-100vh)'
    }),
    animate('500ms ease-out')
  ]),
  transition(':leave',
    animate(
      '500ms',
      style({
        transform: 'translateY(-100vh)'
      })
    )
  )
]);
