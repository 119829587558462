import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MsalAuthenticationService } from '../../../../services/common-auth/msalauthentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../../services/layout/layout.service';
import { SessionStorageService } from 'angular-web-storage';
import { IErrorState } from '../../../../shared/models';
import { BaseConstantService } from '../../../../services/constant/base-constant.service';
import { Meta } from '@angular/platform-browser';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';

const errorHeaderLabel = 'Shared.lblServiceError';
const errorMessageLabel = 'Shared.lblDocusignServiceErrorMessage';
const backButtonLabel = 'Shared.lblDocusignPleaseTryAgain';
const logoutLabel = 'Shared.lblLogout';
const customerAccountLockedLabel = 'Shared.lblNewClaimCustomerAccountLocked';
const contactServicingTeamLabel = 'Shared.lblNewClaimCustomerAccountLockedContactServicingTeam';

@Component({
  selector: 'lib-docusign-error-page',
  templateUrl: './docusign-error-page.component.html',
  styleUrls: ['./docusign-error-page.component.scss']
})

export class DocusignErrorPageComponent implements OnInit {

  @Input() fromHomePage: boolean;
  @Input() errorMessageLabelText = 'Unable to estabilish connection to E-signature.';

  // If translate service is failed to retrieve the translated content,  hardcoded value will be used for the UI content.
  errorHeaderLabelText = 'Service Error';
  errorSeconadryMessageLabelText;
  backButtonText = 'Please Try Again';
  logoutText = 'Logout';
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  hideBackButton: boolean;
  state: IErrorState;
  isLoading: boolean;
  hasMultipleSecondaryMessages: boolean;
  isCustomerBlocked = false;

  hasMultipleMessages(): boolean {
    return Array.isArray(this.errorMessageLabelText);
  }

  constructor(
    private msalService: MsalAuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private sessionStorage: SessionStorageService,
    private baseConstantService: BaseConstantService,
    private questionSetService: QuestionSetService,
    private metaTag: Meta,
  ) { }

  async ngOnInit() {
    this.metaTag.updateTag(
      { name: 'robots', content: BaseConstantService.metaDataDenySearch }
    );

    this.getState();
    await this.showLogoutLink();
    this.applyTranslations();
    this.checkDeviceWidth();
  }

  private getState() {
    if (history && history.state) {
      this.state = history.state as IErrorState;
    }
  }

  private hasCustomMessage() {
    return this.state && (this.state.header || this.state.primaryMessage);
  }

  private applyTranslations() {
    this.translate.get([errorHeaderLabel, errorMessageLabel, backButtonLabel, logoutLabel,
      customerAccountLockedLabel, contactServicingTeamLabel]).subscribe((res) => {
        if (this.hasCustomMessage()) {
          this.setErrorHeaderAndMessage(this.state.header, this.state.primaryMessage, this.state.secondaryMessage, this.state.buttonLabel);
        } else if (this.state?.isCustomerBlocked) {
          this.isCustomerBlocked = this.state.isCustomerBlocked;
          this.setErrorHeaderAndMessage(res[customerAccountLockedLabel], null, res[contactServicingTeamLabel], null);
        } else {
          this.setErrorHeaderAndMessage(res[errorHeaderLabel], res[errorMessageLabel], null, res[backButtonLabel]);
        }
        if (this.logoutText && this.logoutText !== '' && res[logoutLabel] !== logoutLabel) {
          this.logoutText = res[logoutLabel];
        }
      });
  }

  private setErrorHeaderAndMessage(header, primaryMessage, secondaryMessage, buttonLabel) {
    this.errorHeaderLabelText = header && header !== errorHeaderLabel ? header : this.errorHeaderLabelText;
    this.errorMessageLabelText = (primaryMessage && primaryMessage !== errorMessageLabel && !this.fromHomePage)
      ? primaryMessage
      : this.errorMessageLabelText;
    this.errorSeconadryMessageLabelText = secondaryMessage;
    this.backButtonText = buttonLabel && buttonLabel !== backButtonLabel ? buttonLabel : this.backButtonText;
    this.hasMultipleSecondaryMessages = Array.isArray(secondaryMessage);
  }

  async showLogoutLink() {
    this.isLoading = true;
    this.logoutText = await this.baseConstantService.getLogOutText(this.logoutText);
    this.isLoading = false;
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  goBack() {
    this.router.navigate(['policy/docusignnav']);
  }

  logout() {
    this.msalService.logout();
  }
}
