import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormGroupControlService } from 'projects/wtw-ui-components/src/lib/services/form-group/form-group.service';
import { SharedSettingsService } from 'projects/wtw-ui-components/src/lib/services/settings/settings-base.service';
import { RecaptchaField } from '../../../form-field/re-captcha-field';
import { Question } from '../../../models';
import { RecaptchaFieldMapper } from '../field-mapper-strategy/strategies/recaptcha-field-mapper';

const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';

@Component({
  selector: 'lib-recaptcha-data-capture',
  templateUrl: './recaptcha-data-capture.component.html',
  styleUrls: ['./recaptcha-data-capture.component.scss']
})
export class RecaptchaDataCaptureComponent implements OnInit {

  @Input() qnData: Question;
  @Output() updateEmitter = new EventEmitter();
  @Input() nextBtn: string;
  recaptchaForm: FormGroup;
  field: RecaptchaField;
  mandatoryErrorMessageText: string;
  constructor(
    private formGroupService: FormGroupControlService,
    private fieldMapper: RecaptchaFieldMapper,
    private translate: TranslateService,
    private settingsService: SharedSettingsService) { }

  ngOnInit() {
    this.applyTranslations();
    this.createFormGroup();
  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, [
      this.mandatoryErrorMessageText]);
    this.recaptchaForm = this.formGroupService.toFormGroup([this.field]);
  }

  private applyTranslations() {
    this.translate.get([mandatoryErrorMessageLabel]).subscribe((res) => {
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
      });
  }

  submitTextAnswer(answeredData): void {
    this.updateEmitter.emit();
  }

}
