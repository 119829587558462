import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyboxFieldMapper } from '../field-mapper-strategy/strategies/currencybox-field-mapper';
import { Question } from '../../../models';
import { CurrencyBoxField } from '../../../form-field/currencybox-field';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';

const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
const minLengthErrorMessageLabel = 'Shared.lblMinLengthErrorMessage';
const maxLengthErrorMessageLabel = 'Shared.lblMaxLengthErrorMessage';
const minRangeErrorMessageLabel = 'Shared.lblMinRangeErrorMessage';
const maxRangeErrorMessageLabel = 'Shared.lblMaxRangeErrorMessage';
const numericErrorMessageLabel = 'Shared.lblNumericErrorMessage';
const invalidValueErrorMessageLabel = 'Shared.lblInvalidValue'

@Component({
  selector: 'lib-currency-data-capture',
  templateUrl: './currency-data-capture.component.html',
  styleUrls: ['./currency-data-capture.component.scss']
})
export class CurrencyDataCaptureComponent implements OnInit {
  field: CurrencyBoxField;
  currencyForm: FormGroup;
  mandatoryErrorMessageText: string;
  minRangeErrorMessage: string;
  maxRangeErrorMessage: string;
  numericErrorMessage: string;
  minLengthErrorMessageLabel: string;
  maxLengthErrorMessageLabel: string;
  invalidValueErrorMessage: string;

  @Input() qnData: Question;
  @Input() nextBtn: string;
  @Output() updateEmitter = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private formGroupService: FormGroupControlService,
              private fieldMapper: CurrencyboxFieldMapper,
              private translate: TranslateService) { }

  ngOnInit() {
                this.applyTranslations();
                this.createFormGroup();
              }
              
  createFormGroup() {
                this.field = this.fieldMapper.map(this.qnData, [
                  this.mandatoryErrorMessageText,
                  '',
                  '',
                  '',
                  this.minLengthErrorMessageLabel,
                  this.maxLengthErrorMessageLabel,
                  this.minRangeErrorMessage,
                  this.maxRangeErrorMessage,
                  this.numericErrorMessage,
                  this.invalidValueErrorMessage
                ]);
                this.currencyForm = this.formGroupService.toFormGroup( [this.field]);
            
              }

  private applyTranslations() {
                this.translate.get([
                  mandatoryErrorMessageLabel,
                  minLengthErrorMessageLabel,
                  maxLengthErrorMessageLabel,
                  minRangeErrorMessageLabel,
                  maxRangeErrorMessageLabel,
                  numericErrorMessageLabel,
                  invalidValueErrorMessageLabel
                ]).subscribe((res) => {
                    this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
                    this.minLengthErrorMessageLabel = res[minLengthErrorMessageLabel];
                    this.maxLengthErrorMessageLabel = res[maxLengthErrorMessageLabel];
                    this.minRangeErrorMessage = res[minRangeErrorMessageLabel];
                    this.maxRangeErrorMessage = res[maxRangeErrorMessageLabel];
                    this.numericErrorMessage = res[numericErrorMessageLabel];
                    this.invalidValueErrorMessage = res[invalidValueErrorMessageLabel];
                  });
              }
            
  submitAnswer(answeredData): void {
                const val = this.currencyForm.get(this.field.key).value;
                answeredData.name = val;
                answeredData.isAnswered = true;
                this.updateEmitter.emit();
              }
}
