import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, lastValueFrom, empty } from 'rxjs';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MsalHttpInterceptor implements HttpInterceptor {
  private token: string;
  public siteKey = '6LeELWgdAAAAADHHjwa8oio-sa-_3sZOF693kJeQ';
  public action = 'homepage';

  constructor(private settingsService: SharedSettingsService,
              private reCaptchaV3Service: ReCaptchaV3Service) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // To exclude the route for sending JWT token
    // make sure the excluderequests are in lowercase.
    const excludeRequests = ['api/configuration/get', '/manifest.json',
      'assets/i18n/en-gb.json', 'assets/i18n/en-us.json', 'assets/i18n/fr-fr.json', 'api/localization/gettranslations',
      'api/b2cproducttemplate/getb2chomeproductdetails', 'api/b2cproducttemplate/getproducttemplate', 'api/quotation/getquoterating'];

    const recaptchaRequests = ['api/quotation/getquoterating'];

    let addTokenHeader = true;

    let addRecaptcha = false;


    for (const excludeRequest of excludeRequests) {
      if (req.url.toLocaleLowerCase().search(excludeRequest) >= 0) {
        addTokenHeader = false;
      }
    }

    for (const recaptchaRequest of recaptchaRequests) {
      if (req.url.toLocaleLowerCase().includes(recaptchaRequest)) {
        addRecaptcha = true;
      }
    }

    if (addTokenHeader) {
      this.token = localStorage.getItem('msal.idtoken');
      req = req.clone({
        setHeaders: {
          'Access-Control-Allow-Origin': window.location.origin,
          Authorization: `Bearer ${this.token}`
        }
      });
    }

    if (this.settingsService.recaptchaVerified !== null &&
      this.settingsService.recaptchaVerified !== undefined &&
      this.settingsService.recaptchaVerified === true && addRecaptcha) {
      return from(this.reCaptchaV3Service.executeAsPromise(this.siteKey, this.action, { useGlobalDomain: true }))
        .pipe(
          switchMap((recaptchaToken: string) => {

            this.settingsService.recaptchaToken = recaptchaToken;

            if (this.settingsService.recaptchaToken) {
              if (this.settingsService.recaptchaCount === null
                || this.settingsService.recaptchaCount === undefined
                || this.settingsService.recaptchaCount <= 0) {
                this.settingsService.recaptchaCount = 1;
                this.settingsService.recaptchaVerified = false;
                req = req.clone({ headers: req.headers.set('Recaptcha', this.settingsService.recaptchaToken) });
              }
            }

            return next.handle(req);
          })
        );

    }

    return next.handle(req);
  }

}
