import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CheckboxField } from '../../../form-field/checkbox-field';
import { FormControl, FormGroup } from '@angular/forms';
import { LayoutService } from '../../../../services/layout/layout.service';
@Component({
  selector: 'lib-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent implements OnInit {
  public mandatoryErrorMsg;
  public isSmallView: boolean;
  public checkBoxFormControlName: string;
  @Input() field: CheckboxField;
  @Output() valueChange = new EventEmitter();
  @Input() form: FormGroup;
  constructor(
    private layoutService: LayoutService   
  ) { }

  get formControl(): any {
    return this.form.controls[this.checkBoxFormControlName];
  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

 
  onSelectionChange(event)  {    
    event['tagName']="checkbox";    
    this.valueChange.emit(event);
  }

  ngOnInit() {
    this.mandatoryErrorMsg = this.field?.mandatoryValidationMsg || 'This field is required';
    if (this.field.label === undefined || this.field.label === null) {
      this.field.label = '';
    }
    this.checkViewPortWidth(); 
    this.checkBoxFormControlName = this.field['key'] ? this.field['key'] : 'checkBox';  
  }  
}
