import { Directive, ViewContainerRef, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[libDataCapture]',
})
export class DataCaptureDirective implements OnInit {
  constructor(public viewContainerRef: ViewContainerRef,
              private el: ElementRef
    ) { }

  ngOnInit() {
    // this.el.nativeElement.textContent = ' test fff';
  }
}
