import { FormFieldBase } from '../../../../form-field/form-field-base';
import { Question } from '../../../../models';

export abstract class FormFieldMapper<T extends FormFieldBase<string>> {

    public map(question: Question, errorMessage?: string | string[], callToActionLabel?: string, order?: number,
               formFieldIterationIndex?: number): T {
        const result = this.getInstance();
        this.mapCommonProperties(result, question, errorMessage, callToActionLabel, order, formFieldIterationIndex);
        this.mapSpecificProperties(result, question);
        return result;
    }

    protected abstract getInstance(): T;

    protected abstract mapSpecificProperties(field: T, question: Question): void;

    private mapCommonProperties(formField: T,
                                question: Question, errorMessage?: string | string[],
                                callToActionLabel?: string, order?: number, formFieldIterationIndex?: number) {
        formField.key = question.qIntentCode;
        formField.label = question.placeholderText || '';
        formField.descriptionLabel = question.qSubDescription;
        formField.required = question.qMandatory || false;
        formField.mandatoryValidationMsg = errorMessage || '';
        formField.callToActionLabel = callToActionLabel || '';
        formField.order = order || 0;
        formField.visibility = question.visibility || '';
        formField.margin = question.margin;
        formField.index = question.index;
        formField.parentOValue = question.parentOValue || '';
        formField.qIntentCode = question.qIntentCode || '';
        formField.masterGroupPanelIntentCode = question.masterGroupPanelIntentCode || '';
        formField.rootParentQIntentCode = question.rootParentQIntentCode || '';
        formField.childQIntentCode = question.childQIntentCode || '';
        formField.parentQIntentCode = question.parentQIntentCode || '';
        formField.formFieldIterationIndex = formFieldIterationIndex;
        formField.lookupPQIntentCode = question.lookupPQIntentCode;
        formField.iterationIndex = question.iterationIndex;
        formField.persistChildValue = question.persistChildValue || false;
        formField.isHidden = question.isHidden || false;
        formField.isRepeatingGroupToBeNext = question.isRepeatingGroupToBeNext || false;
        formField.masterGroupPanelChildQIntentCode = question.masterGroupPanelChildQIntentCode || '';
    }
}
