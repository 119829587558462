<ng-container *ngIf="!isLoading">
  <div *ngIf="!hideLogOutButton">
    <header class="header-icon-container" id="headerIconContainer">
      <lib-top-bar-with-icon-and-link id="appTopBar" [linkLabel]="logoutText" (emitLabelAction)="logout();"
                                      [iconName]="'keyboard_backspace'" (emitIconAction)="goBack()">
      </lib-top-bar-with-icon-and-link>
    </header>
  </div>
  <div class="error-page" id="errorPage">
    <div class="error-page-container" id="errorPageContainer">

      <section class="page-title" [ngClass]="{'pad-top-100px' : isCustomerBlocked}" id="pageTitle">
        <h1 class="primary-header" id="h1PrimaryHeader" [class.desktop_title_size]="isDesktop">
          <mat-icon class="material-icons-outlined iconAlign" *ngIf="isCustomerBlocked">lock</mat-icon>
          {{errorHeaderLabelText}}
        </h1>
      </section>

      <section class="error-page-message tertiary-header" id="tertiaryHeader" *ngIf="!isCustomerBlocked">
        <mat-icon id="errorIcon" class="error-page-icon" tabindex="5">error</mat-icon>
        <p *ngIf="!hasMultipleMessages()" id="pErrorMessage">{{errorMessageLabelText}}</p>
        <div *ngIf="hasMultipleMessages()">
          <p *ngFor="let message of errorMessageLabelText">{{message}}</p>
        </div>
      </section>
      <section class="error-page-secondary-message" *ngIf="errorSeconadryMessageLabelText">
        <p *ngIf="!hasMultipleSecondaryMessages" id="pSeconadryErrorMessage">{{errorSeconadryMessageLabelText}}</p>
        <div *ngIf="hasMultipleSecondaryMessages">
          <p *ngFor="let message of errorSeconadryMessageLabelText">{{message}}</p>
        </div>
      </section>
      <section *ngIf="!hideBackButton" class="flex-container">
        <div class="flex-item">
          <lib-primary-button id="btnBack" [text]="backButtonText" (clicked)="goBack()"></lib-primary-button>
        </div>
      </section>
      <section *ngIf="retryNavigation" class="flex-container">
        <div class="flex-item">
          <lib-primary-button id="btnRetry" [text]="retryButtonText" (clicked)="navigationRetry()"></lib-primary-button>
        </div>
      </section>
    </div>
  </div>
</ng-container>
<lib-progress-spinner *ngIf="isLoading"></lib-progress-spinner>
