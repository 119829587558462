import { Injectable } from '@angular/core';
import { endPoints } from '../../core/app.config';
import { CommonHttpService, BaseAccountService, SharedSettingsService, UserProfile, Address } from 'wtw-ui-components';
import {
  SaveUserDetailsComposite, SaveUserDetailsCompositeResponse, DropDownItem, IProfileType
} from '../../shared/models';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SessionStorageService } from 'angular-web-storage';
import { ConstantService } from '../constant/constant.service';
import { UtilitiesService } from 'wtw-ui-components';
@Injectable({
  providedIn: 'root'
})
export class AccountService extends BaseAccountService {

  constructor(
    commonHttpService: CommonHttpService,
    sessionStorageService: SessionStorageService,
    sharedSettingsService: SharedSettingsService,
    utilitiesService: UtilitiesService) {
    super(commonHttpService, sessionStorageService, sharedSettingsService, utilitiesService);
  }
  private apiUrl = endPoints.apiUrl;
  private saveUserDetailsWithSupplementaryDataUrl = this.apiUrl + endPoints.endPointName.saveUserDetailsWithSupplementaryData;
  private saveUserDetailsWithoutSupplementaryDataUrl = this.apiUrl + endPoints.endPointName.saveUserDetailsWithoutSupplementaryData;
  private saveB2CUserAddressUrl = this.apiUrl + endPoints.endPointName.saveB2CUserAddress;
  private createQuoteReferralActionWithNotificationUrl = this.apiUrl + endPoints.endPointName.createQuoteReferralActionWithNotification;
  private getCountryListUrl = this.apiUrl + endPoints.endPointName.getCoutryList;
  private _profileTypeDetails;
  private _referralPremission = {
    isEditOnly: false,
    isApproveDeclineOnly: false,
    isEditWithoutPremiumOverride: false,
    isApproveDeclineWithPremiumOverride: false

  }
  getMenuByProfileUrl = this.apiUrl + endPoints.endPointName.getMenuByProfile;
  getB2B2CMenuUrl = this.apiUrl + endPoints.endPointName.getB2B2CMenu;
  getProfileTypeByUserIdUrl = this.apiUrl + endPoints.endPointName.getProfileTypeByUserId;

  saveUserDetailsWithSupplementaryData(requestBody: SaveUserDetailsComposite): Observable<SaveUserDetailsCompositeResponse> {
    const apiURL = this.saveUserDetailsWithSupplementaryDataUrl;

    requestBody.sessionKey = this.sharedSettingsService.getUserSessionKey();

    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((result: SaveUserDetailsCompositeResponse) => {
        // adding this code for bug 179642
        if (result?.userDataResponse?.customerId) {
          this.sessionStorageService.set('customerId', result?.userDataResponse?.customerId);
        }
        this.patchUserProfile(result.userDataResponse);
        // Remove from session once user details saved.
        this.sessionStorageService.remove('UserDetailModel');
        return result; // Bubble result.
      })
    );
  }

  saveUserDetailsWithoutSupplementaryData(userProfile: UserProfile, b2bEmail: string) {
    const apiURL = this.saveUserDetailsWithoutSupplementaryDataUrl;

    const requestBody = {
      userProfile: userProfile,
      B2BEmail: b2bEmail
    }

    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((userDataResponse: UserProfile) => {
        if (userDataResponse?.customerId) {
          this.sessionStorageService.set('customerId', userDataResponse?.customerId);
        }
        this.patchUserProfile(userDataResponse);
        // Remove from session once user details saved.
        this.sessionStorageService.remove('UserDetailModel');
        return userDataResponse;
      })
    );
  }
  createQuoteReferralActionWithNotification(requestBody: SaveUserDetailsComposite): Observable<SaveUserDetailsCompositeResponse> {
    const apiURL = this.createQuoteReferralActionWithNotificationUrl;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((result: any) => {
        return result;
      })
    );
  }
  saveB2CUserAddress(requestBody: Address): Observable<Address> {
    return this.commonHttpService.httpPostServiceWithBearer(this.saveB2CUserAddressUrl, requestBody).pipe(
      map((resAddress: Address) => {
        if (this.userProfileObservable) {
          // Update the cached value.
          this.userProfileObservable.subscribe(userData => {
            userData.addresses[0] = resAddress;
            this.userProfileObservable = of(userData);
            // Remove from session once address details saved.
            this.sessionStorageService.remove('UserAddressModel');
          });
        }
        return resAddress;
      })
    );
  }

  getCountryList() {
    const stashedCountryList = JSON.parse(this.sessionStorageService.get('CountryList'));

    if (stashedCountryList) {
      return of(stashedCountryList);
    }

    return this.commonHttpService.httpGetService(this.getCountryListUrl).pipe(
      map((countries: DropDownItem[]) => {
        this.sessionStorageService.set('CountryList', JSON.stringify(countries));
        return countries;
      }));
  }

  // getMenuByProfile(profileType) {
  //   const sponsorName = this.sessionStorageService.get('SponsorName');
  //   const profileName = profileType;
  //   const menuType = ConstantService.MenuTypeID;
  //   const languageId = this.sessionStorageService.get('LanguageId');
  //   const menuByProfile = `${this.getMenuByProfileUrl}/${sponsorName}/${profileName}/${menuType}/${languageId}`;
  //   return this.commonHttpService.httpGetService(menuByProfile).pipe(shareReplay());
  // }

  getB2B2CMenu(profileType) {
    const requestBody = {
      sponsorName: this.sessionStorageService.get('SponsorName'),
      email: profileType,
      languageId: this.sessionStorageService.get('LanguageId')
    }
    const apiURL = this.getB2B2CMenuUrl;
    return this.commonHttpService.httpPostServiceWithBearer(apiURL, requestBody).pipe(
      map((result: any) => {
        return result;
      })
    );
  }

  public getProfileTypeByUserID(): Observable<IProfileType> {
    const userid = this.sessionStorageService.get('b2bemail')
    const profileTypeByUserIdUrl = `${this.getProfileTypeByUserIdUrl}/${userid}`;
    return this.commonHttpService.httpGetService(profileTypeByUserIdUrl).pipe(
      map((result: any) => {
        this._profileTypeDetails = result;
        return result;
      }));
  }

  public get profileTypeDetails() {
    return this._profileTypeDetails;
  }

  public get referralPermission() {
    return this._referralPremission;
  }

  public setReferralPermission(isEditOnly, isApproveDeclineOnly, isEditWithoutPremiumOverride, isApproveDeclineWithPremiumOverride) {
    this._referralPremission.isEditOnly = isEditOnly;
    this._referralPremission.isApproveDeclineOnly = isApproveDeclineOnly;
    this._referralPremission.isEditWithoutPremiumOverride = isEditWithoutPremiumOverride;
    this._referralPremission.isApproveDeclineWithPremiumOverride = isApproveDeclineWithPremiumOverride;
  }
}

