import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { DatePickerField } from '../../../form-field/date-picker-field';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LayoutService } from '../../../../services/layout/layout.service';
import { SessionStorageService } from 'angular-web-storage';
import { DateManipulationService } from '../../../../services/date-manipulation/date-manipulation.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { BaseConstantService } from '../../../../services/constant/base-constant.service';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';

const dateFormatFactory = (session: SessionStorageService) => {
  let setDateForamat = 'DD/MM/YYYY';
  const localisedDateFormatObj = sessionStorage.getItem('LocalisedDateFormat');
  if (localisedDateFormatObj) {
    setDateForamat = JSON.parse(localisedDateFormatObj)._value;
  }
  const dateformat = {
    parse: {
      dateInput: setDateForamat,
    },
    display: {
      dateInput: setDateForamat,
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  };
  return dateformat;
}
@Component({
  selector: 'lib-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    }
    , { provide: MAT_DATE_FORMATS, useFactory: dateFormatFactory, deps: [SessionStorageService] }

  ]
})

export class DatePickerComponent implements OnInit {
  @Input() field: DatePickerField;
  @Input() form: FormGroup;
  @Input() offsetValue = '';

  isSmallView: boolean;
  minDateOffset: string[];
  maxDateOffset: string[];
  stashedDate: Date | moment.Moment;
  isValid = false;
  // disableElement = false;
  sponsorTimeZoneOffset = '';
  datePickerFormControlName: string;
  dateFormat: string = 'DD/MM/YYYY';
  offsetControlDate: Moment;
  offsetControlStartValue: Moment;

  constructor(
    private layoutService: LayoutService,
    private sessionStorage: SessionStorageService,
    private dateAdapter: DateAdapter<any>,
    private dateManipulationService: DateManipulationService,
    private sharedSettingsService: SharedSettingsService
    ) { }

  ngOnInit() {
    if (this.sessionStorage.get('LocalisedDateFormat')) {
      this.dateFormat = this.sessionStorage.get('LocalisedDateFormat');
    }
    this.stashedDate = this.retrieveAnchorFromSessionStorage();
    this.minDateOffset = this.field.minDate ? this.field.minDate.split(',') : null;
    this.maxDateOffset = this.field.maxDate ? this.field.maxDate.split(',') : null;
    const timeZoneOffset = this.sessionStorage.get('timeZoneOffset');
    this.sponsorTimeZoneOffset = timeZoneOffset && timeZoneOffset.toLowerCase().replace('utc', '');
    this.createForm();

    const offsetControl = this.form.controls[this.field.offsetBasis];
    if (offsetControl) {
      if (offsetControl.value) {
        this.offsetControlStartValue = moment(offsetControl.value);
      }
      offsetControl.valueChanges
        .pipe(debounceTime(800),
          distinctUntilChanged()
        )
        .subscribe(result => {
          this.offsetControlDate = result; 
          this.field.value = '';
          this.setDefaultDate();
        });
    }

    this.setDefaultDate();
    this.checkViewPortWidth();
    this.dateAdapter.setLocale(this.sessionStorage.get('LanguageCode'));
    // this.disableElement = this.field.readOnly;
  }

  createForm() {
    if (this.form) {
      this.datePickerFormControlName = this.field.key || 'datePickerField';
    } else {
      this.datePickerFormControlName = 'datePickerField';
      this.form = new FormGroup({ datePickerField: new FormControl('') });
    }
  }

  setDefaultDate() {
    let calculatedDate: Date | moment.Moment;
    if (this.field.value 
        && (moment(this.field.value).isValid() 
          || moment(this.field.value, BaseConstantService.DefaultDateFormat, true).isValid()
          || moment(this.field.value, BaseConstantService.YearMonthDayDateFormat, true).isValid()
          )) {
      let dateValue: any = this.field.value;
      let date: Date;
      if (moment(dateValue, BaseConstantService.DefaultDateFormat, true).isValid()) {
        date = moment(dateValue, BaseConstantService.DefaultDateFormat, true).toDate();
      } else if (moment(dateValue, BaseConstantService.YearMonthDayDateFormat, true).isValid()) {
        date = moment(dateValue, BaseConstantService.YearMonthDayDateFormat, true).toDate();
      }
      if (date && date.toString() !== 'Invalid Date') {
        dateValue = date;
      }

      const newFieldValue = moment(dateValue, BaseConstantService.DefaultDateFormat, true).toDate();
      const transformedDate = moment(newFieldValue, this.dateFormat, this.sessionStorage.get('LanguageCode'), false);
      this.field.value = transformedDate.format(this.dateFormat);
    }

    if (this.field.isUnderMasterPolicy && this.field.isUnderMasterPolicy === true && this.sharedSettingsService.productValidityEndDate) {
      const month = moment(this.sharedSettingsService.productValidityEndDate).month() + 1;
      const day = moment(this.sharedSettingsService.productValidityEndDate).date();

      if (this.offsetControlDate) {
        calculatedDate = this.getCalculatedDateUnderMasterPolicy(this.offsetControlDate, day, month);
      } else if (this.offsetControlStartValue) {
        calculatedDate = this.getCalculatedDateUnderMasterPolicy(this.offsetControlStartValue, day, month);
      } else {
        const offsetControlDateFromSession = this.sessionStorage.get(`offsetControl-${this.field.offsetBasis}`);
        if (offsetControlDateFromSession && moment(offsetControlDateFromSession, 'DD/MM/YYYY').isValid()) {
          calculatedDate = this.getCalculatedDateUnderMasterPolicy(moment(offsetControlDateFromSession, 'DD/MM/YYYY'), day, month);
        } else {
          calculatedDate = null;
        }
      }

      if (calculatedDate) {
        this.form.patchValue({ [this.datePickerFormControlName]: calculatedDate });
        this.field.value = this.getThisformValuewithFormat();
      }
    } else {
      if (this.field.value && moment(this.field.value) && moment(this.field.value, [this.dateFormat]).isValid()) {
        const datevalue = moment(this.field.value, [this.dateFormat]);
        this.form.patchValue({ [this.datePickerFormControlName]: datevalue });
        this.field.value = this.formControl.value;
      } else {
        if (this.field.default && this.field.default.toLowerCase() === 'mindate') {
          calculatedDate = this.minDate;
        } else if (this.field.default && this.field.default.toLowerCase() === 'maxdate') {
          calculatedDate = this.maxDate;
        } else if (this.field.default) {
          calculatedDate = this.dateManipulationService.applyDateOffset(
            this.field.default, this.getAnchorDate(), this.sponsorTimeZoneOffset);
        }
  
        if (this.field.isExpiryDateAsInceptionDate === false) {
          calculatedDate = this.dateManipulationService.applyDateOffset('-1,days', calculatedDate, this.sponsorTimeZoneOffset);
        }
  
        if (calculatedDate) {
          this.form.patchValue({ [this.datePickerFormControlName]: calculatedDate });
          this.field.value = this.getThisformValuewithFormat();
        }
      }
    }

    this.formControl.updateValueAndValidity();
  }

  getCalculatedDateUnderMasterPolicy(offsetDate: Moment, endDateDay: number, endDateMonth: number) {

    const comparisonMonth = offsetDate.month() + 1;
    const comparisonDay = offsetDate.date();
    const comparisonYear = offsetDate.year();

    const defaultDateFormat = 'DD/MM/YYYY';
    const currentYear = moment().year();
    if (comparisonMonth > endDateMonth || (comparisonMonth === endDateMonth && comparisonDay >= endDateDay)) {
      return moment(
        `${endDateDay}/${endDateMonth}/${comparisonYear > currentYear ? comparisonYear + 1 : currentYear + 1}`, defaultDateFormat);
    } else if (comparisonMonth < endDateMonth || (comparisonMonth === endDateMonth && comparisonDay < endDateDay)) {
      return moment(
        `${endDateDay}/${endDateMonth}/${comparisonYear > currentYear ? comparisonYear : currentYear}`, defaultDateFormat);
    } else {
      return null;
    }
  }

  get formControl(): AbstractControl {
    return this.form.controls[this.datePickerFormControlName];
  }

  get minDate(): Date | moment.Moment {
    return this.dateManipulationService.applyDateOffset(this.field.minDate, this.getAnchorDate(), this.sponsorTimeZoneOffset);
  }

  get maxDate(): Date | moment.Moment {
    return this.dateManipulationService.applyDateOffset(this.field.maxDate, this.getAnchorDate(), this.sponsorTimeZoneOffset);
  }

  getAnchorDate(): Date | moment.Moment {
    const offsetFieldControl = this.form.get(this.field.offsetBasis);
    const dynamicDate = offsetFieldControl ? offsetFieldControl.value : null;
    return this.stashedDate ? this.stashedDate : dynamicDate
      ? dynamicDate : this.dateManipulationService.getMomentDatewithUtcOffset(this.sponsorTimeZoneOffset);
  }

  getThisformValuewithFormat() {
    if (this.form.get(this.datePickerFormControlName).value) {
      const date = new Date(this.form.get(this.datePickerFormControlName).value);
      const transformedDate = moment(date, this.dateFormat, this.sessionStorage.get('LanguageCode'), false);
      return transformedDate.format(this.dateFormat);
    } else {
      return this.form.get(this.datePickerFormControlName).value;
    }
  }

  retrieveAnchorFromSessionStorage(): Date | moment.Moment {
    if (this.offsetValue && moment(this.offsetValue) && moment(this.offsetValue).isValid()) {
      return moment.parseZone(this.offsetValue);
    }
    return null;
  }

  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  showErrorMessage(): boolean {
    return this.formControl.invalid
      && ((this.formControl.hasError('required') && this.formControl.touched) || !this.formControl.hasError('required'));
  }

  formateDate(event) {
    const dateValue = event.target.value;
    const transformedDate = moment(dateValue.format(this.dateFormat), this.dateFormat, this.sessionStorage.get('LanguageCode'), false);
    this.form.patchValue({ [this.datePickerFormControlName]: transformedDate });
  }
}
