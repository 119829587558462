import { Component, OnInit, Input, ViewChild, Renderer2, ElementRef  } from '@angular/core';
import { SideBarService } from '../../../services/side-bar/side-bar.service';
import { LayoutService } from '../../../services/layout/layout.service';
import { Observable } from 'rxjs';



export enum SideNavDirection {
    Left = 'left',
    Right = 'right'
}

@Component({
    selector: 'lib-side-bar',
    templateUrl: './side-bar.component.html',
    styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
    isMobile = false;
    isTablet = false;
    isDesktop = false;
    showSideNav: Observable<boolean>;

    @Input() sidenavTemplateRef: any;
    @Input() duration = 0.25;
    @Input() navWidth: number = window.innerWidth;
    @Input() direction: SideNavDirection = SideNavDirection.Right;
   // @ViewChild('closeIcon') closeIcon: ElementRef;
    constructor(
        private sideBarService: SideBarService,
        private layoutService: LayoutService,
        private renderer: Renderer2
    ) {
        // this.renderer.listen('window', 'click', (e: Event) => {
        //     if (this.closeIcon.nativeElement.contains(e.target)) {
        //         document.body.style.overflow = 'hidden';
        //         this.sideBarService.setShowNav(true);
        //     }
        // });
    }

    ngOnInit(): void {
        this.checkDeviceWidth();
        this.showSideNav = this.sideBarService.getShowNav();
    }

    onSidebarClose() {
        this.sideBarService.setShowNav(false);
        document.body.style.overflow = 'auto';
    }

    getSideNavBarStyle(showNav: boolean) {
        const navBarStyle: any = {};
        navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
        navBarStyle.width = this.isMobile ? 100 + '%' : this.navWidth + 'px';
        navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';

        return navBarStyle;
    }
    checkDeviceWidth() {
        this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
            this.isMobile = this.layoutService.isSmallView();
            this.isTablet = this.layoutService.isMediumView();
            this.isDesktop = this.layoutService.isLargeView();

        });
    }
}
