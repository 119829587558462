import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { TextboxFieldMapper } from '../field-mapper-strategy/strategies/textbox-field-mapper';
import { Question } from '../../../models';
import { TextboxField } from '../../../form-field/textbox-field';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';

const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
const minRangeErrorMessageLabel = 'Shared.lblMinRangeErrorMessage';
const maxRangeErrorMessageLabel = 'Shared.lblMaxRangeErrorMessage';
const numericErrorMessageLabel = 'Shared.lblNumericErrorMessage';


@Component({
  selector: 'lib-number-data-capture',
  templateUrl: './number-data-capture.component.html',
  styleUrls: ['./number-data-capture.component.scss']
})
export class NumberDataCaptureComponent implements OnInit {

  field: TextboxField;
  numericForm: FormGroup;
  mandatoryErrorMessageText: string;
  minRangeErrorMessage: string;
  maxRangeErrorMessage: string;
  numericErrorMessage: string;

  @Input() qnData: Question;
  @Input() nextBtn: string;
  @Output() updateEmitter = new EventEmitter();

  constructor(private formBuilder: FormBuilder,
              private formGroupService: FormGroupControlService,
              private fieldMapper: TextboxFieldMapper,
              private translate: TranslateService) { }

  ngOnInit() {

    this.applyTranslations();
    this.createFormGroup();

    // // If previously answered, reinstate this value.
    // if (this.qnData.answerSets[0].isAnswered) {
    //   this.numberField.setValue(this.qnData.answerSets[0].name);
    //   this.numberField.updateValueAndValidity();
    // }
  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, [
      this.mandatoryErrorMessageText,
      '',
      '',
      '',
      '',
      '',
      this.minRangeErrorMessage,
      this.maxRangeErrorMessage,
      this.numericErrorMessage
    ]);
    this.numericForm = this.formGroupService.toFormGroup( [this.field]);

  }

  private applyTranslations() {
    this.translate.get([
      mandatoryErrorMessageLabel,
      minRangeErrorMessageLabel,
      maxRangeErrorMessageLabel,
      numericErrorMessageLabel
    ]).subscribe((res) => {
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
        this.minRangeErrorMessage = res[minRangeErrorMessageLabel];
        this.maxRangeErrorMessage = res[maxRangeErrorMessageLabel];
        this.numericErrorMessage = res[numericErrorMessageLabel];
      });
  }

  // get numberField() {
  //   return this.numericForm.get('number');
  // }

  submitAnswer(answeredData): void {
    const val = this.numericForm.get(this.field.key).value;
    answeredData.name = val;
    answeredData.isAnswered = true;
    this.updateEmitter.emit();
  }
}
