import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { QuotationService } from '../../../services/quotation/quotation.service';
import { LayoutService, QuestionSetService, BaseRepeatingGroupDataCaptureComponent } from 'wtw-ui-components';
import { Question, BaseConstantService } from 'wtw-ui-components';
import {
  CarrierQuoteSummary, IPrimaryCoverage, MultiCarrierQuoteSummary, MultiQuoteCoverage
} from '../../models/quote-summary.model';
import { MatDialog } from '@angular/material/dialog';
import { InfoCard } from '../../../../../../wtw-ui-components/src/public-api'
import { ConstantService } from '../../../services/constant/constant.service';
import { SharedSettingsService } from 'wtw-ui-components';
import { AccountService } from '../../../services/account/account.service';
import { CurrencyFormatPipe } from '../../../../../../wtw-ui-components/src/lib/shared/pipes/currency-format.pipe';

const editLabel = 'Shared.lblEdit';
const lblInsuredValue = 'Shared.lblMCIInsuredValue';
const lblCoverage = 'Shared.lblCoverage';
const lblTotalGrossPremium = 'Shared.lblTotalGrossPremium';

@Component({
  selector: 'app-mci-question-container',
  templateUrl: './mci-question-container.component.html',
  styleUrls: ['./mci-question-container.component.scss']
})
export class MciQuestionContainerComponent extends BaseRepeatingGroupDataCaptureComponent implements OnInit {
  isMobile = false;
  isPrimaryCover: any;
  isTablet = false;
  isDesktop = false;
  editText: string;
  insuredText: string;
  subHeading: string;
  img: any[];
  @Output() clicked = new EventEmitter<Question>();
  multiCarrierQuoteSummary: MultiCarrierQuoteSummary;
  selectedCarrierQuoteSummary: CarrierQuoteSummary;
  grossPremium: string;
  carrierName: string;
  coverageName: string;
  carrierImage: any[];
  currencySymbol: string;
  currencySymbolAfterMoney: boolean;
  insuredAmount: string;
  isB2cMultiCarrierQuotingEnabled: boolean;
  isB2cSingleCarrierMultiQuoteEnabled: boolean;
  selectedCoverageList: InfoCard[] = [];
  totalGrossPremiumText: string = '';
  totalGrossPremiumValue: string = '0.0';
  coverageText: string;
  lastAnswered: string;
  panelOpenState: boolean;
  coveragePanelOpenState: boolean;
  isApproveDeclineOnly: boolean;

  constructor(private quotationService: QuotationService,
              private layoutService: LayoutService,
              public translate: TranslateService,
              public sessionStorage: SessionStorageService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              public questionSetService: QuestionSetService,
              public matDialog: MatDialog,
              private constantService: ConstantService,
              private sharedSettingsService: SharedSettingsService,
              private accountService: AccountService,
              private currencyPipe: CurrencyFormatPipe
  ) {
    super(sessionStorage, questionSetService, translate, matDialog);
  }

  ngOnInit(): void {
    this.checkDeviceWidth();
    this.applyTranslations();
    const isb2b = this.sessionStorage.get('isb2b');
    const isExistingQuote = this.sharedSettingsService.existingCustomerQuote;
    const previousAnswerLastSessionObj = this.sessionStorage.get('PreviousAnswerLastQuestion');
    this.questionSetService?.GetPreviousAnswerLastQuestion.subscribe((previousAnswerLastQues: string) => {
      if (previousAnswerLastQues && previousAnswerLastQues !== '') {
        this.lastAnswered = previousAnswerLastQues;
      }
    });
    if ((this.lastAnswered === undefined || this.lastAnswered === '' || this.lastAnswered === null) && previousAnswerLastSessionObj) {
      this.lastAnswered = previousAnswerLastSessionObj
    }
    // to hide the coverage details if on primary cover page in previous answer
    if (window.location.pathname.includes('primary-cover')) {
      this.isPrimaryCover = true;
    }
    this.isB2cMultiCarrierQuotingEnabled = this.sharedSettingsService.isB2cMultiCarrierQuotingEnabled;
    this.isB2cSingleCarrierMultiQuoteEnabled =
      sessionStorage.getItem('isB2cSingleCarrierMultiQuoteEnabled').toLowerCase() === 'true' ? true : false;
    if (this.isB2cMultiCarrierQuotingEnabled === true) {
      this.selectedCarrierQuoteSummary = this.quotationService.getSelectedMCICarrierQuoteSummary();
      this.setSelectedCarrierAndCoverageValues();
      this.subHeading = this.insuredText + " " + this.insuredAmount;
    } else if (this.isB2cSingleCarrierMultiQuoteEnabled) {
      this.setSelectedCoverageList();
    }
    this.questionSetService?.GetPrimaryCoverageTerm().subscribe((result: any) => {
      if (result && result !== '' && this.isB2cSingleCarrierMultiQuoteEnabled) {
        this.setSelectedCoverageList();
      }
    });

    this.questionSetService.referralPermissionSet.subscribe(isApproveDeclineOnlySet => {
      if (isApproveDeclineOnlySet) {
        this.isApproveDeclineOnly = this.accountService.referralPermission.isApproveDeclineOnly
          && isb2b && isExistingQuote && this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value
          && this.sharedSettingsService.isQuoteReferredCheck;
      }
    });
  }

  applyTranslations() {
    this.translate.get([editLabel, lblInsuredValue, lblCoverage, lblTotalGrossPremium]).subscribe((res) => {
      this.editText = res[editLabel];
      this.insuredText = res[lblInsuredValue];
      this.coverageText = res[lblCoverage];
      this.totalGrossPremiumText = res[lblTotalGrossPremium];
    });
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }
  editClicked(question: Question) {
    this.sessionStorage.set('isQSetOverride', true);

    this.questionSetService.navigateToIntentCode(question.qIntentCode);
    if (question && question.qQuestionType === 'Quote') {
      if (question?.qQuestionFormat === BaseConstantService.Key_RepeatingGroupQuestionFormat) {
        this.questionSetService.initialRepeatingGroup = false;
      }
      this.sessionStorage.set('maxRepeat', question?.maxRepeats);
      this.goToQuoteQuestions(question);
    } else if (question && question.qQuestionType === 'Policy') {
      this.goToPolicyQuestions(question);
    }
  }

  goToQuoteQuestions(question: Question) {
    const currentUrl = window.location.pathname;
    if (!currentUrl?.includes('quote-questions')) {
      this.router.navigate(['quote/quote-questions']);
      return;
    }
    this.clicked.emit(question);
  }

  goToPolicyQuestions(question: Question) {
    const currentUrl = window.location.pathname;
    if (!currentUrl?.includes('policy-questions')) {
      this.router.navigate(['policy/policy-questions']);
      return;
    }
    this.clicked.emit(question);
  }

  goToYourQuote() {
    this.sessionStorage.set('isQSetOverride', true);
    if (this.isB2cMultiCarrierQuotingEnabled) {
      this.router.navigate(['quote/multi-carrier-quote']);
    } else if (this.isB2cSingleCarrierMultiQuoteEnabled) {
      this.router.navigate(['/quote/primary-optional-cover']);
    }
  }

  setSelectedCarrierAndCoverageValues() {
    this.carrierName = this.selectedCarrierQuoteSummary?.carrierName;
    this.carrierImage = this.selectedCarrierQuoteSummary?.carrierImageData;
    this.currencySymbol = this.selectedCarrierQuoteSummary?.currencySymbol;
    this.currencySymbolAfterMoney = this.selectedCarrierQuoteSummary?.currencySymbolAfterMoney;

    this.insuredAmount = this.currencySymbolAfterMoney ? this.selectedCarrierQuoteSummary?.insuredAmount + this.currencySymbol
      : this.currencySymbol + this.selectedCarrierQuoteSummary?.insuredAmount;
    this.selectedCarrierQuoteSummary?.coverageList?.filter(coverage => {
      this.grossPremium = this.currencySymbolAfterMoney ? coverage.grossPremium + this.currencySymbol
        : this.currencySymbol + coverage.grossPremium;
      this.coverageName = coverage.languageWiseCoverageName;
      if (this.lastAnswered === this.constantService.multicarrierpageName) {
        this.panelOpenState = true;
      }
      else {
        this.panelOpenState = false;
      }
    })
  }

  setSelectedCoverageList() {
    let totalGrossPremium = 0;

    const coverageList = this.quotationService.getSelectedCoverageList();
    if (coverageList.length > 0) {

      this.selectedCoverageList = [];
      const currencySymbol = this.quotationService.currencySymbol();
      const currencySymbolAfterMoney = this.quotationService.currencySymbolAfterMoney();

      coverageList?.forEach(coverage => {
        if (coverage.isSelected) {
          const grossPremiumValue = coverage?.grossPremium != null && coverage?.grossPremium !== undefined ?
            this.currencyPipe.transform(coverage.grossPremium) : '';

          const grossPremium = coverage?.grossPremium != null && coverage?.grossPremium !== undefined
            ? (currencySymbolAfterMoney ? `${grossPremiumValue}${currencySymbol}`
              : `${currencySymbol}${grossPremiumValue}`) : '';

          const cover: InfoCard = {
            intentCode: coverage.intentCode,
            image: coverage.base64ImageData,
            name: coverage.coverageName,
            sideText: grossPremium,
            description: [],
            coverageTermsLabel: '',
            coverageTerms: coverage.coverageTerms,
            defaultCoverageTermText: coverage.defaultCoverageTermText,
            coverageTermsList: coverage.coverageTermsList,
            currencySymbol: '',
            currencySymbolAfterMoney: false,
            monthText: '',
            editPrimaryCoveargeTermOnSecondaryCoverage: false,
            hideCoverage: coverage?.optionalCoverageBenefits?.hideCoverage
          }

          this.selectedCoverageList.push(cover);
          totalGrossPremium = totalGrossPremium + coverage.grossPremium;

          if (this.lastAnswered === this.constantService.primarycoverage
            || this.lastAnswered === this.constantService.primaryOptionalCoverage) {
            this.coveragePanelOpenState = true;
          }
          else {
            this.coveragePanelOpenState = false;
          }
        }
      })


      this.totalGrossPremiumValue = currencySymbolAfterMoney ? `${this.currencyPipe.transform(totalGrossPremium)}${currencySymbol}`
        : `${currencySymbol}${this.currencyPipe.transform(totalGrossPremium)}`;

    }
  }

  // To Delete repeating group from previous answer section
  onDeleteClick(question: Question) {
    this.deleteDialog(question, true, true);
  }
}
