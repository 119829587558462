import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalHttpInterceptor } from 'wtw-ui-components';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    exports: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalHttpInterceptor,
            multi: true
        }
    ]
})
export class CoreModule { }
