import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InfoCard } from '../../../models/info-card.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { SessionStorageService } from 'angular-web-storage';

@Component({
  selector: 'lib-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {

  @Input() infoData: InfoCard;
  @Input() tag: any;
  @Output() clicked = new EventEmitter();
  @Output() coverageTermChanged = new EventEmitter();
  optionList: any[] = [];

  coverageTermDetailsForm: FormGroup;

  constructor(private formBuilder: FormBuilder, private sessionStorage: SessionStorageService) {

  }

  ngOnInit() {

    this.coverageTermDetailsForm = this.formBuilder.group({
      coverageTerm: ['']
    });
    this.optionList = this.infoData?.coverageTermsList?.map(term => { return {name: term, value: term} })

    // to retain selection on primary-coverage coverage change.
    if (this.infoData?.coverageTerms) {
      this.coverageTermDetailsForm.get('coverageTerm').setValue(this.infoData?.coverageTerms);
    } else {
      this.coverageTermDetailsForm.get('coverageTerm').setValue(this.infoData?.defaultCoverageTermText);

    }
  }

  public onClick(): void {
    this.clicked.emit(this.tag);
  }

  public onSelectionChange(event: any): void {
    this.coverageTermChanged.emit(event);
  }

}
