import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from '../../../services/layout/layout.service';

@Component({
  selector: 'lib-heading-sub-heading',
  templateUrl: './heading-sub-heading.component.html',
  styleUrls: ['./heading-sub-heading.component.scss']
})
export class HeadingSubHeadingComponent implements OnInit {
  @Input() heading: string;
  @Input() subHeading: string;
  public isDesktopView: boolean;
  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.checkLayoutViewPort();
  }

  // ----- Checking screen resolution by angular layout/breakpoints
  checkLayoutViewPort() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isDesktopView = this.layoutService.isLargeView();
    });
  }

}
