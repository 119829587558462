<div id='modalContainer' class="text-center">
    <div class="modelHeadingContainer" id="divHeadingContainer">
      <h3 id="mainHeading" [ngClass]="{'header-desktop': isDesktop}" [innerHtml]="data.headingText"></h3>
    </div>
   
    <div>
        <h5 id="divSubHeading" *ngIf="data.additionalText" [innerHtml]="data.additionalText" class="instruction"></h5>
    </div>
    <div>
      <mat-icon>
        <img class="icon" [src]="'data:image/png;base64,'+spinnerLogo" />
      </mat-icon> 
        <span id="divInstruction" *ngIf="data.instructionText" [innerHtml]="data.instructionText" class="sub-heading"></span>
   
    </div>
    
   
  </div>
