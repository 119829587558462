<section [ngClass]="{'compressed-section':isCompressedViewOfPreviousAnswers}" class="d-flex justify-content-between">
  <main>
    <mat-expansion-panel [(expanded)]="isMaxRepeat>0?isVisibleMatPanelHeader:panelState">
      <mat-expansion-panel-header [ngClass]="{'matheaderdisplay':isVisibleMatPanelHeader}">
        <p id="label" class="label" *ngIf="label">
          <img *ngIf="tag==='image'" [ngClass]="{'img-size-desktop':isDesktop,
           'img-size-tablet':isTablet,
           'img-size-mobile':isMobile}" [src]="'data:image/png;base64,'+img" />
          {{label}}
        </p>
        <!-- <mat-icon id="errorIcon" class="error-icon">error</mat-icon> -->
        <div *ngIf="!disabled && isMaxRepeat===0" [class.disabledButton]="isDisabledButton" id="divCallToAction"
          class="call-to-action cursor-pointer" (click)="onClick($event)">
          {{callToAction}}
        </div>
      </mat-expansion-panel-header>
      <div *ngIf="productName">
        <img *ngIf="tag==='image'" [ngClass]="{'img-size-desktop':isDesktop,
           'img-size-tablet':isTablet,
           'img-size-mobile':isMobile}" [src]="'data:image/png;base64,'+img" />
        <p id="label" class="label">{{productName}}</p>
      </div>
      <div *ngIf="subheading && showTooltip && (isDesktop || isMobile) else templateForToolTip">
        <p id="label" class="label-subheading" libIsEllipsisActive><span
            *ngIf="subheading!=='referred'">{{subheading}}</span></p>
      </div>
      <ng-template #templateForToolTip>
        <p *ngIf="subheading" id="label"><span *ngIf="subheading!=='referred'">{{subheading}}</span>
          <mat-icon *ngIf="details && productName" id="helpinfo" class="infoicon" [matTooltip]="referredTooltipText"
            matTooltipPosition="above">error</mat-icon>
        </p>
      </ng-template>
      <hr [class.style-hr]="isCompressedViewOfPreviousAnswers && !disabled"
        *ngIf="isCompressedViewOfPreviousAnswers && !disabled">
      <ng-container *ngIf="isArray">
        <ng-container *ngFor="let detail of details;let i=index">
          <div class="question-container" *ngIf="detail">
            <hr *ngIf="!isCompressedViewOfPreviousAnswers">
            <p class="detail"
              [ngClass]="{'detail':!isCompressedViewOfPreviousAnswers, 'label':isCompressedViewOfPreviousAnswers}"
              *ngIf="questions?.length>0">{{questions[i]}}
            <div class="warnin-div">
              <!--In New Claims for upload document we are sending html tag with data. So added this condition.-->
              <ng-container *ngIf="tag==='doc'; else else_content">
                <mat-icon class="warning"
                  *ngIf="(indexVal != undefined && i == indexVal) && isEmailValueBlank == true">warning</mat-icon>
                <p id="detail1" [ngClass]="(indexVal != undefined && i == indexVal) ? 'warning' : 'detail'"
                  *ngIf="!isValidDate(detail)" [innerHTML]="detail | safeHtml"></p>
              </ng-container>
              <ng-template #else_content>
                <mat-icon class="warning"
                  *ngIf="(indexVal != undefined && i == indexVal) && isEmailValueBlank == true">warning</mat-icon>
                <p id="detail1" [ngClass]="(indexVal != undefined && i == indexVal) ? 'warning' : 'detail'"
                  *ngIf="!isValidDate(detail)" [innerText]="detail"></p>
              </ng-template>

              <p id="detail2" class="detail" *ngIf="isValidDate(detail)">{{detail| momentOffsetDatePipe}}</p>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <p id="detail3" class="detail" *ngIf="details && !isValidDate(details) && !isArray  && !isDate && !infoItems"
        [innerHTML]="details | safeHtml"></p>
      <p id="detail4" class="detail" *ngIf="isValidDate(details)  && isDate">{{details | momentOffsetDatePipe}}</p>
      <ng-cotainer *ngIf="infoItems && infoItems.length>0">
        <hr *ngIf="!isCompressedViewOfPreviousAnswers">
        <div class="infoDiv">
          <table>
            <tr>
              <td></td>
              <td class="name-col highlight">{{totalGrossPremiumText}}</td>
              <td class="text-col highlight">{{totalGrossPremiumValue}}</td>
            </tr>
            <ng-container *ngFor="let item of infoItems">
              <tr [hidden]="item?.hideCoverage">
                <td class="image-col">
                  <img *ngIf="item?.image" [ngClass]="{'table-img-size-desktop':isDesktop,
                     'table-img-size-tablet':isTablet,
                     'table-img-size-mobile':isMobile}" [src]="'data:image/png;base64,'+item.image" />
                </td>
                <td class="name-col">{{item?.name}}</td>
                <td class="text-col">{{item?.sideText}}</td>
              </tr>
            </ng-container>
          </table>
        </div>
      </ng-cotainer>
    </mat-expansion-panel>
  </main>
</section>