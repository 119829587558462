import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { DropdownItem } from '../../../models/dropdown-item.model';
import { DOCUMENT } from '@angular/common';
import { LayoutService } from '../../../../services/layout/layout.service';

@Component({
  selector: 'lib-icon-drop-down',
  templateUrl: './icon-drop-down.component.html',
  styleUrls: ['./icon-drop-down.component.scss']
})
export class IconDropDownComponent implements OnInit {
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  @Input() Categories: DropdownItem[];
  @Input() selectedOption: any;
  @Input() iconName: any;
  @Output() selectChange = new EventEmitter();
  @Output() selectChange1 = new EventEmitter();
  @Input() selectLanguagesText: string;
  @Input()selectedOption1: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.checkDeviceWidth();
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  onSelectionChange(event) {
    this.selectChange.emit(event.value);
  }

  mobileMenuClose() {
    this.document.body.classList.remove('noScroll');
  }
}
