<div [formGroup]="form" [ngClass]="{'textbox-container-medium': !isSmallView}" class="textbox-container"
  matTooltip={{field.value}} [matTooltipDisabled]="!showTooltip" matTooltipPosition="right"
  matTooltipClass="tooltipClass">
  <mat-form-field appearance="fill" [ngClass]="{'mat-form-field-readonly':field.readOnly}">
    <mat-label id="matLabel" [innerHTML]="field.label | safeHtml"></mat-label>
    <input class="truncateBigWord" [type]="field.type" matInput formControlName="{{field.key}}" [id]="field.key"
      [readonly]="field.readOnly" [pattern]="field.regexValidation" (keydown.enter)="valueChangeOnKeyPressed($event,field)" 
      (blur)="onBlurEvent($event)" (focusin)="onFocusInEvent()" 
       />

    <span matSuffix>{{currencyText}} </span>

    <span matPrefix *ngIf="field.iconName !=''">
      <mat-icon id="iconName" matPrefix>{{field.iconName}}</mat-icon>
    </span>
    
    <button id="btnCallToAction" *ngIf="field.callToActionLabel !=''" mat-button matSuffix
      (click)="callToActionOnClick()">{{field.callToActionLabel}}</button>
    <mat-error id="matError">{{getSpecificErrorMessage()}}</mat-error>
    

  </mat-form-field>
  
</div>
