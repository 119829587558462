import {
  Component, OnInit, AfterViewInit, Input, EventEmitter, Output,
  ChangeDetectorRef, ViewChild, AfterViewChecked, AfterContentChecked, Renderer2
} from '@angular/core';
import { MCIContext } from '../../../models/mci-context.model';
import { TranslateService } from '@ngx-translate/core';
import { FieldMapper } from '../..../../field-mapper-strategy/field-mapper';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Question } from '../../../models';
import { FormFieldBase } from '../../../form-field/form-field-base';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';
import { OndemandDataRequest } from '../../../models/ondemanddatarequest.model';
import { SessionStorageService } from 'angular-web-storage';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';
import { Element, Parent } from '../../../models/Carrier-Integration/utility.model';
import { DropDownComponent } from '../../../components/inputs/drop-down/drop-down.component';
import { AnswerSets } from '../../../../shared/models/answer-sets.model';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { BaseConstantService } from '../../../../services/constant/base-constant.service';
import { isNullOrUndefined } from 'util';

const datePickerErrorMessage = 'Shared.lblDatePickerErrorMessage';
const editLabel = 'Shared.lblEdit';
const mandatoryErrorMessageLabel = 'Shared.lblErrorMandatory';
const emailErrorMessageLabel = 'Shared.lblInvalidEmail';
const minLengthErrorMessageLabel = 'Shared.lblMinLengthErrorMessage';
const maxLengthErrorMessageLabel = 'Shared.lblMaxLengthErrorMessage';
const minRangeErrorMessageLabel = 'Shared.lblMinRangeErrorMessage';
const maxRangeErrorMessageLabel = 'Shared.lblMaxRangeErrorMessage';
const numericErrorMessageLabel = 'Shared.lblNumericErrorMessage';
const invalidValueErrorMessageLabel = 'Shared.lblInvalidValue';
const loaderMessageLabel = 'Shared.lblLoaderMessage';
const ERROR_NOT_FOUND = 'NotFound';

@Component({
  selector: 'lib-group-panel-data-capture',
  templateUrl: './group-panel-data-capture.component.html',
  styleUrls: ['./group-panel-data-capture.component.scss']
})
export class GroupPanelDataCaptureComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked {
  @Input() qnData: Question;
  @Input() groupPanelQnData: Question[];
  @Output() updateEmitter = new EventEmitter();
  groupPanelForm: FormGroup;
  pageFormFields: FormFieldBase<string>[];
  subFormFields: FormFieldBase<string>[];
  groupQnAnswerObj: Question[];
  datePickerErrorMessageText: string;
  mandatoryErrorMessageText: string;
  emailErrorMessageText: string;
  minLengthErrorMessage: string;
  maxLengthErrorMessage: string;
  minRangeErrorMessage: string;
  maxRangeErrorMessage: string;
  numericErrorMessage: string;
  invalidValueErrorMessage: string;
  editLabelText: string;
  nextBtn: string;
  currentSelection: string;
  previousSelection: string;
  @Input() maxRepeats: number = 1;
  formFieldIteration: number = 1;
  parentIntentCode: string = '';
  childSpace: number = 15;
  qSubDescription: string;
  onDemandDataRequestValues: OndemandDataRequest;
  onDemandDataPayLoad: OndemandDataRequest;
  childElements: Element[] = [];
  parent: Parent;
  inputParameters: Element[] = []
  inputParamValues: Element[] = []
  parentChildArray: Element[] = [];
  controlType: any;
  options: any[] = [];
  showChildOfSelectedParentFlag = false;
  @ViewChild(DropDownComponent) dropDownComponent: DropDownComponent;
  checkboxGroup: any;
  isLoading: boolean = false;
  loaderMessage: string

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private formGroupControlService: FormGroupControlService,
    private fieldMapper: FieldMapper,
    public questionSetService: QuestionSetService,
    private sessionStorageService: SessionStorageService,
    private sharedSettingsService: SharedSettingsService,
    private renderer: Renderer2
  ) {
    this.checkboxGroup = new Map()
    this.questionSetService.qSubDescriptionObservable.subscribe(qSubDescription => {
      // this.showChildOfSelectedParent(this.groupPanelQnData);
      this.repeatingElementHeading();
    });
  }

  ngOnInit() {
    this.applyTranslations();
    this.createFormGroup();
    // this.questionSetService.formFieldIteration = 1;
  }


  submitGroupPanelAnswer(): void {
    // ---- set ansewer in below function
    this.groupQnAnswerObj = this.setValueToAnswerSetsGroupQn(this.groupPanelQnData);
    this.updateEmitter.emit();

  }

  setValueToAnswerSetsGroupQn(groupPanelQnData) {
    groupPanelQnData.forEach((qn) => {
      const qnIntentCode = qn.qIntentCode;
      if (qn?.globalFunction?.toLowerCase()?.includes('dynamic') && !qn?.globalFunction?.toLowerCase()?.includes('source')) {
        this.pageFormFields.filter(fieldsVal => {
          if (fieldsVal.key === qn.qIntentCode) {
            qn.answerSets.length = 0;
            const fieldsOptionsVal = [];
            if (!fieldsVal?.options && fieldsVal?.value) {
              fieldsOptionsVal?.push({ name: fieldsVal?.value, value: fieldsVal?.value });
              fieldsVal.options = fieldsOptionsVal;
            }
            fieldsVal.options?.forEach((optionsVal, index) => {
              const answerSet = this.questionSetService.MapLookupChildAnswerSet(optionsVal);
              answerSet['active'] = true;
              qn.answerSets.push(answerSet);
            });
          }
        })
      }
      else if (qn?.globalFunction?.toLowerCase()?.includes('metadata') && qn?.globalFunction?.toLowerCase()?.includes('source')) {
        this.pageFormFields.filter(fieldsVal => {
          if (fieldsVal.key === qn.qIntentCode) {
            qn.answerSets.length = 0;
            if (fieldsVal?.options) {
              fieldsVal.options?.forEach((optionsVal, index) => {
                let answerSet = this.questionSetService.MapLookupChildAnswerSet(optionsVal);
                if (optionsVal.name !== '' && optionsVal.name !== undefined && optionsVal.value !== '' && optionsVal.value !== undefined) {
                  if (optionsVal.name !== optionsVal.value) {
                    answerSet = this.questionSetService.MapLookupChildAnswerSetMetadata(optionsVal);
                  }
                }
                answerSet['active'] = true;
                qn.answerSets.push(answerSet);
              });
            }
            else {
              const optionsVal = [fieldsVal.value, fieldsVal.value];
              const answerSet = this.questionSetService.MapLookupChildAnswerSet(optionsVal);
              answerSet['active'] = true;
              qn.answerSets.push(answerSet);
            }
          }

        })

      }
      // Single input.
      if (qn?.answerSets?.length === 1) {
        const currentValue = this.groupPanelForm.get(qnIntentCode)?.value;
        const currentAnswerSet = qn.answerSets[0];
        if (qn.qQuestionFormat.toLowerCase() !== 'recaptcha') {
          currentAnswerSet.name = currentValue;
          if (currentValue) {
            currentAnswerSet.isAnswered = true;
          }
        }
      } else {
        if (qn?.answerSets) {
          qn?.answerSets.forEach(as => as.isAnswered = false);
          const formElement = this.groupPanelForm.get(qnIntentCode);
          if (formElement) {
            if (qn?.globalFunction?.toLowerCase()?.includes('metadata') && qn?.globalFunction?.toLowerCase()?.includes('source')) {
              const formData = this.groupPanelForm.get(qnIntentCode);
              const answeredData = qn.answerSets.filter(ans => ans.name === formData.value).pop();

              if (answeredData) {
                answeredData.isAnswered = true;
                if (answeredData.childQuestionSets?.length > 0) {
                  this.setValueToAnswerSetsGroupQn(answeredData.childQuestionSets)
                }

              }
            }
            else {
              if (qn?.qQuestionFormat.toLowerCase() === 'checkbox') {
                const answeredData = qn.answerSets.filter(ans => ans.name?.toLowerCase() === formElement?.value?.toString()).pop();
                if (answeredData) {
                  answeredData.isAnswered = true;
                  if (answeredData.childQuestionSets?.length > 0) {
                    this.setValueToAnswerSetsGroupQn(answeredData.childQuestionSets)
                  }
                }
              }
              else {
                const answeredData = qn.answerSets.filter(ans => ans.name === formElement.value).pop();
                if (answeredData) {
                  answeredData.isAnswered = true;
                  if (answeredData.childQuestionSets?.length > 0) {
                    this.setValueToAnswerSetsGroupQn(answeredData.childQuestionSets)
                  }
                } else if (!answeredData && qn?.globalFunction?.toLowerCase()?.includes('autosuggestion')) {
                  // To save entered value in auto suggested field
                  // if value is not available in suggestion list
                  const currentValue = this.groupPanelForm.get(qnIntentCode)?.value;
                  const currentAnswerSet = qn.answerSets[0];
                  currentAnswerSet.name = currentValue;
                  if (currentValue) {
                    currentAnswerSet.isAnswered = true;
                  }
                }
              }
            }
          }
        }
      }
    });
    return this.groupPanelQnData;
  }


  ngAfterViewChecked() {
    if (this.sessionStorageService.get('isRepeat') === 'true') {
      this.createRepeatingGroup();
      this.sessionStorageService.set('isRepeat', 'false');
    }
    // this.showChildOfSelectedParent(this.groupPanelQnData);
  }
  ngAfterContentChecked() {
    if (this.showChildOfSelectedParentFlag === true) {
      this.showChildOfSelectedParent(this.groupPanelQnData);
      const state = window.history.state;
      if (state && state.isBackwards === true || this.sharedSettingsService.isB2B()) {
        this.emitSelectionChange();
      }
    }
  }


  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.showChildOfSelectedParentFlag = true;
    this.getModelForSelectedMake();
    // this.showChildOfSelectedParent(this.groupPanelQnData);
  }

  private createFormGroup() {
    this.qSubDescription = this.qnData.qSubDescription
    this.pageFormFields = [];
    const questions = this.groupPanelQnData;
    if (questions) {
      this.createChildGroup(questions);
    }
  }

  createChildGroup(questions, isRepeat?: string) {
    questions.forEach((question, index) => {
      question['masterGroupPanelChildQIntentCode'] = this.qnData?.childQIntentCode;
      question['isRepeatingGroupToBeNext'] = question?.isRepeatingGroupToBeNext;
      this.createLookUpParentChildHierarchy(question);
      this.mapFormData(question, index);
      this.createGrandChilds(question, 'outerCall', 0)
    });
    if (this.checkValidationRequired()) {
      this.groupPanelForm = this.formGroupControlService.toFormGroup(this.pageFormFields, true);
      this.groupPanelForm.setValidators(requireCheckboxesToBeCheckedValidator(this.checkboxGroup));
    }
    else {
      this.groupPanelForm = this.formGroupControlService.toFormGroup(this.pageFormFields);
    }
  }

  createGrandChilds(question, callType, maxRepeatIndex?: number) {
    // if (question.childQIntentCode) {
    question?.answerSets?.forEach((subQuestion) => {
      if (subQuestion?.childQIntentCode && subQuestion?.childQuestionSets?.length > 0) {
        if (callType === 'outerCall') {
          this.childSpace = 15;
        }
        subQuestion.childQuestionSets.forEach((que, ind) => {
          que['visibility'] = 'hide';
          que['margin'] = this.childSpace;
          que['index'] = maxRepeatIndex;
          que['masterGroupPanelIntentCode'] = question.masterGroupPanelIntentCode;
          que['masterGroupPanelChildQIntentCode'] = this.qnData?.childQIntentCode;
          que['isRepeatingGroupToBeNext'] = que?.isRepeatingGroupToBeNext;
          if (callType === 'outerCall') {
            que['rootParentQIntentCode'] = que.parentQIntentCode;
            this.parentIntentCode = que.parentQIntentCode;
            // que['masterGroupPanelIntentCode'] = question.masterGroupPanelIntentCode;
          } else if (callType === 'innerCall' && this.parentIntentCode) {
            que['rootParentQIntentCode'] = this.parentIntentCode;
          }

          this.mapFormData(que, ind, question.answerSets);
          if (que?.childQIntentCode) {
            this.childSpace = this.childSpace + 15;
            this.createGrandChilds(que, 'innerCall');
          }
        });
      }
    });
    // }
  }

  mapFormData(question, index, parentQuestionAnswerset?: AnswerSets[]) {
    const fieldData = this.fieldMapper.map(
      question,
      this.getErrorMessageText(question.qQuestionFormat),
      this.getEditLabelText(question.qQuestionFormat),
      index,
      this.questionSetService.formFieldIteration);

    // const existingCustomerDraftQuote = this.sessionStorageService.get('ExistingCustomerDraftQuote');
    // const isCopyQuoteAtpClient = this.sharedSettingsService.isCopyQuoteAtpClient;

    if (fieldData) {
      fieldData[question.parentQIntentCode];
      this.pageFormFields.push(fieldData);

      // if (parentQuestionAnswerset?.some(as => as.isAnswered) && fieldData?.value === undefined && fieldData?.required !== undefined
      //   && (!existingCustomerDraftQuote || !isCopyQuoteAtpClient) && question?.qQuestionFormat?.toLowerCase() !== 'hidden') {
      //   fieldData.required = false;
      // }
      // Added to check if  Group checkbox validation required or not
      if ((fieldData.controlType === 'checkbox' && fieldData.required === true)
        || (parentQuestionAnswerset && this.checkboxGroup && this.checkboxGroup.size > 0
          && this.checkboxGroup.has(fieldData.rootParentQIntentCode))) {
        this.checkboxGroup.set(fieldData.key, null);
        // if it has child and its mandatory
        if (parentQuestionAnswerset && this.checkboxGroup?.has(fieldData.rootParentQIntentCode)
          && this.checkboxGroup.get(fieldData.rootParentQIntentCode) === null && fieldData.required === true) {
          this.checkboxGroup.set(fieldData.rootParentQIntentCode, fieldData.key);
        }


      }
    }
  }

  onValueChange(event) {
    if (event.value && event.value !== '-1' && (event?.data?.onDemandDataSubscribers !== '' && event?.data?.onDemandDataSubscribers)
      || event?.data?.globalFunction === 'Disable') {
      this.getOndemandDataValues(event);
    }
    else if (event?.target?.value) {
      if (event instanceof PointerEvent) {
        this.isLoading = true;
      }
      event['value'] = event.target.value
      this.getOndemandDataValues(event);
    }
    // to clear the child values if user has chosen clear option
    else if ((event.value === '' || event.value === '-1') &&
      (event?.data?.onDemandDataSubscribers !== '' && event?.data?.onDemandDataSubscribers)) {
      this.clearChildControls(event.data.onDemandDataSubscribers);
    }
    else if (event.value || event.source) {
      this.previousSelection = this.currentSelection;
      let qIntentCode;
      if (event.value) {
        this.currentSelection = event.value;
        qIntentCode = event?.qIntentCode;
      }
      else if (event.source && event.checked !== undefined) {
        this.currentSelection = event.checked;
        qIntentCode = event?.source.id;
      }

      const element = (<HTMLElement>document.getElementById('groupPanelForm'));
      const children = element?.children;
      for (let i = 0; i < children?.length; i++) {
        const child = children[i];
        if ((child.getAttribute('parentQIntentCode') === qIntentCode) ||
          (child.getAttribute('rootParentQIntentCode') === qIntentCode)) {
          if (child.getAttribute('pvalue')?.toLowerCase() === this.currentSelection?.toString().toLowerCase()
            && qIntentCode === child.getAttribute('parentQIntentCode') && child.getAttribute('isHidden') !== 'true') {
            const field = this.pageFormFields?.filter(form => form.qIntentCode === (child.getAttribute('aKey')))[0];
            const validatorArray = this.formGroupControlService.addValidatorsToField(field);
            const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
            child.setAttribute("style", styles);

            if (!event?.persistChildValue) {
              this.getFormField(child.getAttribute('aKey')).reset();
            }

            // this.getFormField(child.getAttribute('aKey')).setValidators(Validators.required);
            this.getFormField(child.getAttribute('aKey')).addValidators(validatorArray);
            this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
          } else if ((qIntentCode !== child.getAttribute('parentQIntentCode')) ||
            (child.getAttribute('pvalue')?.toLowerCase() !== this.currentSelection?.toString().toLowerCase())) {
            const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
            child.setAttribute("style", styles);
            // Replacing master group pannel childQintentcode with actual one
            if (child.getAttribute('isrepeatinggrouptobenext')?.toLowerCase() === 'true') {
              const masterGroupPanelQuestion = this.questionSetService.internalQuestionSet
                .find(q => q.qIntentCode === this.qnData?.masterGroupPanelIntentCode);
              const masterGroupPanelQuestionIndex = this.questionSetService.internalQuestionSet
                .findIndex(q => q.qIntentCode === this.qnData?.masterGroupPanelIntentCode);
              this.questionSetService.resetSelectedRepeatingGroup(masterGroupPanelQuestion, masterGroupPanelQuestion,
                false, masterGroupPanelQuestionIndex);
              this.questionSetService.internalQuestionSet[masterGroupPanelQuestionIndex].childQIntentCode =
                masterGroupPanelQuestion?.masterGroupPanelChildQIntentCode;
            }
            if (!event?.persistChildValue) {
              this.getFormField(child.getAttribute('aKey')).reset();
            }
            this.getFormField(child.getAttribute('aKey')).clearValidators();
            this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
          }
        }
      }
    }
    if (event instanceof Event || event instanceof KeyboardEvent) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private getErrorMessageText(questionType: string): string | string[] {
    return questionType.toLowerCase() === 'datepicker'
      ? this.datePickerErrorMessageText
      : (questionType.toLowerCase() === 'text' || questionType.toLowerCase() === 'textarea'
        || questionType.toLowerCase() === 'money')
        ? [
          this.mandatoryErrorMessageText,
          this.emailErrorMessageText,
          '',
          '',
          this.minLengthErrorMessage,
          this.maxLengthErrorMessage,
          '',
          '',
          '',
          this.invalidValueErrorMessage
        ] // Todo: change from positional to KVP.
        : questionType.toLowerCase() === 'numeric'
          ? [
            this.mandatoryErrorMessageText,
            '',
            '',
            '',
            this.minLengthErrorMessage,
            this.maxLengthErrorMessage,
            this.minRangeErrorMessage,
            this.maxRangeErrorMessage,
            this.numericErrorMessage,
            '',] // Todo: change from positional to KVP.
          : this.mandatoryErrorMessageText;
  }

  private getEditLabelText(questionType: string): string {
    return questionType.toLowerCase() === 'datepicker' ? this.editLabelText : '';
  }

  private applyTranslations() {
    this.translate.get([
      datePickerErrorMessage,
      mandatoryErrorMessageLabel,
      emailErrorMessageLabel,
      minLengthErrorMessageLabel,
      maxLengthErrorMessageLabel,
      minRangeErrorMessageLabel,
      maxRangeErrorMessageLabel,
      numericErrorMessageLabel,
      invalidValueErrorMessageLabel,
      editLabel,
      loaderMessageLabel]).subscribe((res) => {
        this.datePickerErrorMessageText = res[datePickerErrorMessage];
        this.mandatoryErrorMessageText = res[mandatoryErrorMessageLabel];
        this.emailErrorMessageText = res[emailErrorMessageLabel];
        this.minLengthErrorMessage = res[minLengthErrorMessageLabel];
        this.maxLengthErrorMessage = res[maxLengthErrorMessageLabel];
        this.minRangeErrorMessage = res[minRangeErrorMessageLabel];
        this.maxRangeErrorMessage = res[maxRangeErrorMessageLabel];
        this.numericErrorMessage = res[numericErrorMessageLabel];
        this.invalidValueErrorMessage = res[invalidValueErrorMessageLabel];
        this.editLabelText = res[editLabel];
        this.loaderMessage = res[loaderMessageLabel];
      });
  }

  getFormField(key: string) {
    return this.groupPanelForm?.get(key);
  }
  onAddAnotherClick() {
    if (this.questionSetService.formFieldIteration < this.maxRepeats) {
      this.setValueToAnswerSetsGroupQn(this.groupPanelQnData);
      this.questionSetService.formFieldIteration = this.questionSetService.formFieldIteration + 1;
      this.sessionStorageService.set('isRepeat', 'true')
      this.createChildGroup(this.groupPanelQnData, 'true');
    }
  }

  createRepeatingGroup() {
    // this.showHideIteration(this.questionSetService.formFieldIteration, 'reset');
    this.repeatingElementHeading();
  }

  showHideIteration(formFieldIteration, type) {
    this.groupPanelQnData.forEach((question) => {
      const element = (<HTMLElement>document.getElementById('groupPanelForm'));
      const children = element?.children;
      for (let i = 0; i < children?.length; i++) {
        const child = children[i];
        if (parseInt(child.getAttribute('iterationIndex')) === formFieldIteration && child.getAttribute('aKey') === question.qIntentCode) {
          const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
          child.setAttribute("style", styles);
          if (type === 'reset') {
            this.getFormField(child.getAttribute('aKey')).reset();
            this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
          }
        } else if (parseInt(child.getAttribute('iterationIndex')) !== formFieldIteration) {
          const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
          child.setAttribute("style", styles);
        }
      }
    });
  }

  repeatingElementHeading() {
    if (this.qnData && this.qnData?.qSubDescription) {
      this.qSubDescription = this.qnData.qSubDescription + ' (' + this.questionSetService.formFieldIteration + ')';
    }
  }


  showChildOfSelectedParent(questions) {
    this.showChildOfSelectedParentFlag = false;
    questions.forEach((question) => {
      this.showParent(question);
      this.showGrandChild(question);
    });
  }

  showGrandChild(question) {
    // if (question?.childQIntentCode) {
    question?.answerSets?.forEach((ques) => {
      if (ques?.childQIntentCode && ques?.childQuestionSets?.length > 0) {
        ques.childQuestionSets.forEach((que) => {
          const parentQIntentCode = que?.parentQIntentCode;
          const parentQIntentCodeValue = this.getFormField(parentQIntentCode)?.value;
          if (parentQIntentCodeValue) {
            const element = (<HTMLElement>document.getElementById('groupPanelForm'));
            const children = element?.children;
            for (let i = 0; i < children?.length; i++) {
              const child = children[i];
              if (que.parentQIntentCode === child.getAttribute('rootParentQIntentCode') ||
                que.parentQIntentCode === child.getAttribute('parentqintentcode')) {
                if (((child.getAttribute('pvalue')?.toLowerCase() ===
                  this.getFormField(child.getAttribute('parentQIntentCode'))?.value?.toString().toLowerCase()) ||
                  (!child.getAttribute('parentQIntentCode')
                    && child.getAttribute('pvalue')?.toLowerCase() === parentQIntentCodeValue.toString().toLowerCase()))
                  && child.getAttribute('isHidden') !== 'true') {
                  const field = this.pageFormFields?.filter(form => form.qIntentCode === (child.getAttribute('aKey')))[0];
                  const validatorArray = this.formGroupControlService.addValidatorsToField(field);
                  const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
                  child.setAttribute("style", styles);
                  this.getFormField(child.getAttribute('aKey')).addValidators(validatorArray);
                  this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
                } else if ((child.getAttribute('pvalue')?.toLowerCase() !==
                  this.getFormField(child.getAttribute('parentQIntentCode'))?.value?.toString().toLowerCase())
                  || (!child.getAttribute('parentQIntentCode')
                    && child.getAttribute('pvalue')?.toLowerCase() !== parentQIntentCodeValue.toString().toLowerCase()
                  )) {
                  const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
                  child.setAttribute("style", styles);
                  if (child.getAttribute('aKey')) {
                    if (!question?.persistChildValue) {
                      this.getFormField(child.getAttribute('aKey')).reset();
                    }
                    this.getFormField(child.getAttribute('aKey')).clearValidators();
                    this.getFormField(child.getAttribute('aKey')).updateValueAndValidity();
                  }
                }
              }
            }
          }
          if (que?.childQIntentCode) {
            this.showGrandChild(que);
          }
        });
      }
    });
    // }
  }

  showParent(question) {
    const element = (<HTMLElement>document.getElementById('groupPanelForm'));
    const children = element?.children;
    for (let i = 0; i < children?.length; i++) {
      const child = children[i];
      if (child.getAttribute('aKey') === question.qIntentCode && !question.isHidden) {
        const styles = "display:block; " + "margin-left:" + getComputedStyle(child).marginLeft;
        child.setAttribute("style", styles);
      } else if (child.getAttribute('aKey') === question.qIntentCode) {
        // else if(parseInt(child.getAttribute('iterationIndex')) !== this.questionSetService.formFieldIteration){
        const styles = "display:none; " + "margin-left:" + getComputedStyle(child).marginLeft;
        child.setAttribute("style", styles);
      }
    }
  }

  // look up functions start
  // get child options in response
  getOndemandDataValues(event) {
    this.childElements = [];
    this.parentChildArray = [];
    this.controlType = [];
    if (event.tagName === 'SELECT') {
      this.controlType = "SELECT";
    }
    else if (event.tagName === 'text') {
      this.controlType = "text";
    }

    let selectedValue;
    const element = (<HTMLElement>document.getElementById('groupPanelForm'));
    const children = element?.children;
    if (event?.data) {

      let hasMetadaInGlobalFunction: boolean = false;
      const rootLevelParentName: string = event.data?.onDemandDataParameters?.split(',').map(s => s.trim())[0];
      if (rootLevelParentName && rootLevelParentName.trim().length !== 0
        && rootLevelParentName.toLowerCase() !== event?.id?.toLowerCase()) {
        const rootLevelParent = this.pageFormFields.find(field => field.key.toLowerCase() === rootLevelParentName.toLowerCase());
        hasMetadaInGlobalFunction = rootLevelParent ? rootLevelParent?.globalFunction?.toLowerCase().includes('metadata') : false;
      }

      // if dropdown is child dropdown
      if (event?.data?.lookupPQIntentCode !== '' && event?.data?.lookupPQIntentCode) {
        // get the intend code and options of parents and its subchilds
        this.getPIntendCodeValue(children, '', event);
      } else {
        // if lookupPQIntentCode is not set and is Child Dropdown.(When answerset of childQuestionset contains questions.)
        // if dropdown is parent dropdown
        const onDemandDataParameters = event.data?.onDemandDataParameters
          && event.data.onDemandDataParameters !== '' ? event.data.onDemandDataParameters.split(',').map(s => s.trim())
          : [];

        onDemandDataParameters.forEach(parent => {
          const controlValue = this.pageFormFields.find(field => field.key.toLowerCase() === parent.toLowerCase());
          const selectedText = this.getFormField(parent).value;
          const selectedValue =
            (controlValue?.options === null || controlValue?.options === undefined)
              ? controlValue?.selectedId
              : controlValue?.options.find(x => x.name === selectedText)?.id ?? controlValue?.selectedId;

          const type = this.controlType?.toLowerCase() === "text" ? this.questionSetService.getControlType(controlValue.controlType)
            : this.controlType;
          this.parentChildArray.push(
            {
              name: parent,
              selectedText: selectedText,
              type: type,
              selectedValue: hasMetadaInGlobalFunction ? selectedValue : selectedText,
              value: null
            });
        });

        if (this.parentChildArray?.length === 0 && !hasMetadaInGlobalFunction) {
          const controlValue = this.getFormField(event?.data?.qIntentCode).value;
          this.parentChildArray.push(
            {
              name: event?.data?.qIntentCode,
              selectedText: controlValue,
              type: this.controlType,
              selectedValue: controlValue,
              value: null
            });
        }
      }

      // added this code to pass the id of selected option if intentcode is configured with metadata property
      selectedValue = event.value;
      const grantParent = event?.data?.onDemandDataParameters?.split(',').map(s => s.trim())[0];

      this.groupPanelQnData?.filter(ques => {

        // checking if either parent or child intentcode configure with metadata property
        if ((grantParent && ques?.qIntentCode === grantParent && ques.globalFunction !== null
          && ques?.globalFunction !== "" && ques?.globalFunction?.toLowerCase()?.includes('metadata') ||
          (event?.data?.globalFunction !== null && event.data.globalFunction !== ""
            && event?.data?.globalFunction?.toLowerCase()?.includes('metadata')))) {

          selectedValue = event.data?.selectedId;

        }

      })


      // get childelements
      event.data.onDemandDataSubscribers.split(',').forEach((value, index, array) => {
        const control = this.pageFormFields.find(field => field.key.toLowerCase() === value.toLowerCase().trim());
        if (control) {
          this.childElements.push(
            {
              name: value?.trim(),
              selectedText: null,
              selectedValue: null,
              type: this.questionSetService.getControlType(control.controlType),
              value: null,
            });
        }
      });
      // get parent
      const parentField = this.pageFormFields.find(p => p.key.toLowerCase() === event.id?.trim()?.toLowerCase());
      if (hasMetadaInGlobalFunction && parentField.selectedId) {
        selectedValue = parentField.selectedId;
      }

      this.parent = {
        name: event.id?.trim(),
        selectedText: event.value,
        selectedValue: selectedValue,
        type: this.controlType,
        value: selectedValue,
        endPointUrl: null
      }

      let backendsystem = "";
      if (event?.data?.globalFunction?.toLowerCase()?.includes('metadata')) {
        backendsystem = MCIContext[3];
      }
      else { backendsystem = "None" }

      // payload value
      this.onDemandDataPayLoad = {
        backEndSystem: backendsystem,
        childElements: this.childElements,
        inputParameters: this.parentChildArray,
        parent: this.parent,
        productName: this.sessionStorageService.get('ProductName'),
        globalFunction: event.data.globalFunction,
        quoteId: this.sessionStorageService.get('QuoteReferenceID'),
        sponsorId: this.sessionStorageService.get('SponsorId'),
        sponsorName: this.sessionStorageService.get('SponsorName'),
        userId: ''
      };

      // api call
      this.questionSetService.getOnDemandDataRequest(this.onDemandDataPayLoad).then((observerResponse: OndemandDataRequest) => {
        if (observerResponse?.globalFunction?.toLowerCase()?.includes('autosuggestion') &&
          observerResponse?.optionsList.length > 0) {
          // To map result in auto suggestion list
          const optionsList = [];
          this.groupPanelQnData?.forEach(qnData => {
            if (qnData.qIntentCode === observerResponse.inputParameters[0].name) {
              const result = this.pageFormFields?.filter(formFieldVal => formFieldVal.key === qnData.qIntentCode)[0];
              observerResponse?.optionsList.forEach(opt => {
                const answerSet = this.questionSetService.MapAnswerSetMetadata({ text: opt, id: opt, value: opt });
                qnData.answerSets.push(answerSet);
                optionsList.push(answerSet.name);
                result.autoSuggestionList = optionsList;
              });
            }
          });
        } else {
          observerResponse?.childElements?.forEach((value, index, array) => {
            if ((this.groupPanelForm.controls[value.name]?.touched !== false &&
              this.groupPanelForm.controls[value.name]?.status !== "INVALID")
              || this.groupPanelForm.controls[value.name]?.value !== '') {
              if (!event?.isEmitted) {
                this.clearChildControls(value.name, value.type);
              }
            }
            this.options = [];
            if (value?.lookupData?.length > 0) {
              // to add clear option if response option is only one
              // if (value?.lookupData?.length < 2) {
              //   this.options?.push({ name: '', value: '' });
              // }
              // bind the value for dropdown options
              value?.lookupData?.forEach(lookupDataValue => {
                this.options?.push({ name: lookupDataValue?.text, value: lookupDataValue?.value })
              })
              // binding the options in dropdown
              if (value.type === 'SELECT') {
                let toDisableControl = false;
                this.pageFormFields?.filter(fieldsVal => {

                  // pushing the options along with id as id needs to pass in input parameter
                  if (fieldsVal?.onDemandDataParameters !== '' && fieldsVal?.onDemandDataParameters !== null) {
                    const isMetadata = this.pageFormFields?.
                      find(field => field.qIntentCode === fieldsVal?.onDemandDataParameters?.split(',').
                        map(s => s.trim())[0])?.globalFunction.toLowerCase()?.includes('metadata');

                    if (isMetadata) {
                      this.options = [];
                      value?.lookupData?.forEach(lookupDataValue => {
                        this.options?.push({ name: lookupDataValue?.text, value: lookupDataValue?.text, id: lookupDataValue?.value })
                      })
                    }

                  }

                  if (fieldsVal.key === value.name) {
                    fieldsVal.options = this.options;

                    // *** if the lookup has single option then we have to disable and autoselect that value ***//
                    if (value?.lookupData?.length === 1) {
                      const matSelect = this.groupPanelForm.get(value.name);
                      matSelect.setValue(this.options[0].value, { emitEvent: true });
                      toDisableControl = true;
                      this.cdr.detectChanges();
                      //* ** trigger SelectionChange() event ***/
                      if (fieldsVal.controlType === "dropdown") {
                        const data = this.pageFormFields.filter(field => field.key === value.name)[0];
                        const selected = { value: this.options[0].value };
                        this.dropDownComponent.onSelectionChange(selected, data);
                      }
                    }
                    fieldsVal.readOnly = toDisableControl;
                    fieldsVal.disableMatFormField = toDisableControl;
                  }
                });
              }
              else {
                this.groupPanelForm.get(value.name).setValue(this.options);
              }
            }
            else if (!value?.lookupData && value?.value !== '' && value?.value !== null && value?.value !== undefined) {
              this.groupPanelForm.get(value.name).setValue(value.value);
              this.groupPanelForm.get(value.name).disabled;

              if (observerResponse?.error === ERROR_NOT_FOUND) {
                this.highlightElementAsNotFound(value.name);
              } else {
                this.removeHighlight(value.name);
              }
              this.pageFormFields?.filter(fieldsVal => {
                if (fieldsVal.key === value.name) {
                  fieldsVal.value = value.value;
                }
              });
            }
          })
        }
        this.isLoading = false;
      });
    }
  }

  // method to clear child options when user reselect or clear the options
  clearChildControls(childQIntentCode, type?) {
    this.options = []; // to clear the previous value that user selected in array
    this.options?.push({ name: '', value: '' }) // to display empty value on UI

    // if 'onDemandDataSubscribers' has more that one child to iterate the childs
    if (childQIntentCode?.includes(',')) {
      const childQIntentCodes = childQIntentCode?.split(',').map(s => s.trim());
      if (childQIntentCodes?.length > 0) {
        childQIntentCodes.filter((child) => {
          this.clearChildControls(child); // passing the next child
        })
      }

    }
    this.pageFormFields.filter(fieldsVal => {
      if (fieldsVal.key === childQIntentCode) {
        this.getFormField(childQIntentCode).reset();
        this.getFormField(childQIntentCode).updateValueAndValidity();
        fieldsVal.options = this.options;
        fieldsVal.readOnly = true;
        fieldsVal.value = '';
        if (fieldsVal?.onDemandDataSubscribers !== '' && fieldsVal?.onDemandDataSubscribers !== null
          && fieldsVal.onDemandDataSubscribers !== undefined) {

          this.clearChildControls(fieldsVal.onDemandDataSubscribers);

        }
      }
    });
  }

  getPIntendCodeValue(children, parentValue?, event?) {
    for (let i = 0; i < children?.length; i++) {
      if (!children[i]?.className?.includes('description-label') && !children[i]?.className?.includes('notification-alert')) {
        const parent = children[i];
        if (parentValue && parentValue?.getAttribute('lookupPQIntentCode') !== '') {
          if (parentValue.getAttribute('lookupPQIntentCode') !== null && parent.getAttribute('qIntentCode') !== null
            && parentValue.getAttribute('lookupPQIntentCode') === parent.getAttribute('qIntentCode')) {

            const parentControlValue = this.getFormField(parent.getAttribute('qIntentCode')).value;
            let parentControlId = parentControlValue;

            if (this.pageFormFields.filter(x => x.qIntentCode === parentValue?.getAttribute('lookupPQIntentCode'))[0]
              .globalFunction?.toLowerCase()?.includes('metadata')) {
              parentControlId = this.pageFormFields.filter(x => x.qIntentCode === parentValue?.
                getAttribute('lookupPQIntentCode'))[0].options.find(x => x.name === parentControlValue).id
            }

            this.parentChildArray.push(
              {
                lookupData: null,
                name: parentValue.getAttribute('lookupPQIntentCode'),
                selectedText: parentControlValue,
                type: this.controlType,
                selectedValue: parentControlId,
                value: null
              });
            this.getPIntendCodeValue(children, parent, '');
          }
        }
        else if (event !== null && children !== null && event?.data?.lookupPQIntentCode !== '' && event?.data?.lookupPQIntentCode) {
          if ((event?.data?.lookupPQIntentCode === parent?.getAttribute('qIntentCode'))) {
            //    let parent=child.getAttribute('lookupPQIntentCode')
            let selectedValue;
            let parentSelectedValue;
            const controlValue = this.getFormField(event?.data?.qIntentCode).value;
            const parentControlValue = this.getFormField(event?.data?.lookupPQIntentCode).value;
            const grantParent = event?.data?.onDemandDataParameters?.split(',').map(s => s.trim())[0];
            selectedValue = controlValue;
            parentSelectedValue = parentControlValue;

            const isMetaData = this.groupPanelQnData?.find((intentCode) => intentCode.qIntentCode === (grantParent)).
              globalFunction?.toLowerCase()?.includes('metadata');

            // as child intent code doesnt have keyword configured in globalfunction checking whether parent has configured with metadata
            if (isMetaData) {
              this.pageFormFields?.filter(parentques => {

                if (parentques.qIntentCode === event?.data?.lookupPQIntentCode) {
                  parentSelectedValue = parentques?.options.find((answer) => answer?.name === (parentControlValue)).id
                  selectedValue = event.data.selectedId;
                }

              })
            }

            if (parent.getAttribute('lookupPQIntentCode') !== '') {
              this.getPIntendCodeValue(children, parent, '');
            }
            this.parentChildArray.push(
              {
                name: event?.data?.lookupPQIntentCode,
                selectedText: parentControlValue,
                type: this.controlType,
                selectedValue: parentSelectedValue,
                value: null
              },
              {
                name: event?.data?.qIntentCode,
                selectedText: controlValue,
                type: this.controlType,
                selectedValue: selectedValue,
                value: null
              });
            //  this.parentChildArray.push({name:event?.data?.lookupPQIntentCode});
            // event.data.lookupPQIntentCode='';

          }
        }

      }
    }
  }

  createLookUpParentChildHierarchy(question: Question) {
    const grandParent = this.qnData.childQuestionSets.
      filter(x => x.qIntentCode === question.onDemandDataParameters?.split(',').map(s => s.trim())[0])

    if (question?.globalFunction?.toLowerCase()?.includes('source')) {
      question['lookupPQIntentCode'] = ''
      if (question.globalFunction?.toLowerCase()?.includes('metadata')) {
        const ParentArray = question.onDemandDataParameters?.split(',').map(s => s.trim());
        if (ParentArray?.length > 1) {
          question['lookupPQIntentCode'] = ParentArray[ParentArray.length - 2];
        }
        else {
          question['lookupPQIntentCode'] = ''
        }

      }

    }
    else if (question.globalFunction?.toLowerCase()?.includes('dynamic')) {
      const ParentArray = question.onDemandDataParameters?.split(',').map(s => s.trim());
      question['lookupPQIntentCode'] = ParentArray[ParentArray.length - 2];
    }
    // added this code to get the child options for metadata configuration
    else if (grandParent?.length > 0 && grandParent[0].globalFunction?.toLowerCase()?.includes('metadata')) {
      const ParentArray = question.onDemandDataParameters?.split(',').map(s => s.trim());
      question['lookupPQIntentCode'] = ParentArray[ParentArray.length - 1];
    }
  }

  emitSelectionChange() {
    this.groupPanelQnData.forEach((qn) => {
      if (qn?.globalFunction?.toLowerCase()?.includes('dynamic')) {
        //* ** trigger SelectionChange() event ***/
        if (qn.answerSets?.length > 0) {
          qn.answerSets.forEach(q => {
            if (q.isAnswered === true && q.name !== "") {
              const data = this.pageFormFields.filter(field => field.key === qn.qIntentCode)[0];
              const selected = { value: q.name, isEmitted: true };
              if (data?.controlType === "dropdown") {
                this.dropDownComponent.onSelectionChange(selected, data);
              }
            }
          });
        }
      }
    });
  }
  // look up functions end

  // Check if  its a Checkbox group and one selection reqired
  checkValidationRequired() {
    if (this.checkboxGroup && this.pageFormFields && this.checkboxGroup.size > 1 &&
      this.checkboxGroup.size === this.pageFormFields.length) {
      return true;
    }
    return false;
  }

  private highlightElementAsNotFound(elementId: string): void {
    const element = this.renderer.selectRootElement(`#${elementId}`, true);
    this.renderer.setStyle(element, 'color', 'red');
  }

  private removeHighlight(elementId: string): void {
    const element = this.renderer.selectRootElement(`#${elementId}`, true);
    this.renderer.removeStyle(element, 'color');
  }

  getModelForSelectedMake() {
    const parentField = this.pageFormFields.find(p => p.key.toLowerCase() === "make");
    if (!isNullOrUndefined(parentField)) {
      if (!isNullOrUndefined(parentField.value)) {
        this.childElements = [];
        this.parentChildArray = [];

        this.childElements.push(
          {
            name: 'Model',
            selectedText: null,
            selectedValue: null,
            type: 'SELECT',
            value: null,
          });

        const selectedId = parentField.options.find(x => x.value === parentField.value).id;
        this.parent = {
          name: 'Make',
          selectedText: parentField.value,
          selectedValue: selectedId,
          type: 'SELECT',
          value: selectedId,
          endPointUrl: null
        }

        this.parentChildArray.push(
          {
            name: 'Make',
            selectedText: parentField.value,
            type: 'SELECT',
            selectedValue: parentField.value,
            value: null
          });

        let backendsystem = "";
        if (parentField.globalFunction?.toLowerCase()?.includes('metadata')) {
          backendsystem = MCIContext[3];
        }
        else { backendsystem = "None" }

        this.onDemandDataPayLoad = {
          backEndSystem: backendsystem,
          childElements: this.childElements,
          inputParameters: this.parentChildArray,
          parent: this.parent,
          productName: this.sessionStorageService.get('ProductName'),
          globalFunction: parentField.globalFunction,
          quoteId: this.sessionStorageService.get('QuoteReferenceID'),
          sponsorId: this.sessionStorageService.get('SponsorId'),
          sponsorName: this.sessionStorageService.get('SponsorName'),
          userId: ''
        };

        this.questionSetService.getOnDemandDataRequest(this.onDemandDataPayLoad).then((observerResponse: OndemandDataRequest) => {
          if (!isNullOrUndefined(observerResponse)) {
            this.options = [];
            if (observerResponse?.childElements?.length > 0) {
              const modelDetail = observerResponse?.childElements.find(x => x.name === "Model")
              if (modelDetail?.lookupData.length > 0) {
                modelDetail?.lookupData.forEach(lookupDataValue => {
                  this.options?.push({ name: lookupDataValue?.text, value: lookupDataValue?.text, id: lookupDataValue?.value })
                })

                this.options = this.options.filter(item => item.id !== '-1');
                this.pageFormFields?.filter(fieldsVal => {
                  if (fieldsVal.key === modelDetail.name) {
                    this.options.forEach(option => {
                      const existingField = fieldsVal.options.find(field => field.name === option.name);

                      if (existingField) {
                        existingField.id = option.id;
                      } else {
                        fieldsVal.options.push(option);
                      }
                    });
                  }
                })
              }
            }
          }
        });
      }
    }
  }
}
export function requireCheckboxesToBeCheckedValidator(checkGroup: any, minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    let checked = 0;

    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key];
      if (control.value === true && checkGroup.has(key)) {
        // Check if it has any child
        const chilControl = formGroup.controls[checkGroup.get(key)];
        if (checkGroup.get(key) === null ||
          (chilControl && chilControl.value && chilControl.value !== undefined)) {
          checked++;
        }
      }
      if (checked >= minRequired && control.invalid) {
        control.clearValidators();
        control.updateValueAndValidity();
      }
    });

    if (checked < minRequired) {
      return {
        requireOneCheckboxToBeChecked: true,
      };
    }
    return null;
  };
}

