import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TextAreaField } from '../../../form-field/textarea-field';
import { LayoutService } from '../../../../services/layout/layout.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @Input() field: TextAreaField;
  @Input() form: FormGroup;
  @Output() cToAction = new EventEmitter();
  public isSmallView: boolean;
  public mandatoryErrorMessage: string;
  public minLengthErrorMessage: string;
  public maxLengthErrorMessage: string;
  // public requiredFieldMsg: string | string[];
  constructor(private layoutService: LayoutService) { }

  get formControl(): any {
    return this.form.controls[this.field.key];
  }

    // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
      this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
        this.isSmallView = this.layoutService.isSmallView();
      });
    }

  ngOnInit() {
    if (this.field.label === undefined || this.field.label === null) {
      this.field.label = '';
    }
    if (Array.isArray(this.field.mandatoryValidationMsg)) {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg[0];
      this.minLengthErrorMessage = this.field.mandatoryValidationMsg[4];
      this.maxLengthErrorMessage = this.field.mandatoryValidationMsg[5];
    } else {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg;
    }
  }

  callToActionOnClick() {
    this.cToAction.emit();
  }

  getSpecificErrorMessage() {
    if (this.formControl.hasError('required')) {
      return this.mandatoryErrorMessage;
    }
    if (this.formControl.hasError('minlength')) {
      return this.minLengthErrorMessage;
    }
    if (this.formControl.hasError('maxlength')) {
      return this.maxLengthErrorMessage;
    }
  }
}
