<mat-accordion id="divAccordion" class="accordion-item" multi='true'>
    <mat-expansion-panel tabindex="0" hideToggle="true" #RepeatedGroupAccordion [expanded]="false">
        <mat-expansion-panel-header id="expansionPanelHeader" [collapsedHeight]="'auto'" [expandedHeight]="'auto'"
            class="expansion-header-item">
            <div class="flex-container flex-grow-8">
                <mat-panel-title class="accordion-header">
                    {{label}}
                </mat-panel-title>
            </div>
            <div class="flex-grow-1">
                <mat-panel-description id="pnlDescription">
                    <div *ngIf="questionSetService.getCurrentRepeatingGroupIndex(tag?.qIntentCode).currentIterationIndex === 0 && questionSetService.getSavedRepeatingGroup(this.questionSetService.getRepeatingGroupName(this.tag?.qIntentCode))>=3 " id="deleteCallToAction"
                        class="call-to-action cursor-pointer" (click)="onDeleteClick($event)">
                        {{deleteCallToAction}}
                    </div>
                    <div *ngIf="questionSetService.getCurrentRepeatingGroupIndex(tag?.qIntentCode).currentIterationIndex!==0 && questionSetService.getSavedRepeatingGroup(this.questionSetService.getRepeatingGroupName(this.tag?.qIntentCode))>=2" id="deleteCallToAction"
                        class="call-to-action cursor-pointer" (click)="onDeleteClick($event)">
                        {{deleteCallToAction}}
                    </div>
                    <div id="divCallToAction" *ngIf="!disabled" [class.disabledButton]="isDisabledButton" class="call-to-action cursor-pointer" (click)="onClick($event)">
                        {{callToAction}}
                    </div>
                    <mat-icon id="downArrow" *ngIf="!RepeatedGroupAccordion.expanded">arrow_drop_down
                    </mat-icon>
                    <mat-icon id="upArrow" *ngIf="RepeatedGroupAccordion.expanded">arrow_drop_up
                    </mat-icon>
                </mat-panel-description>
            </div>
        </mat-expansion-panel-header>
        <!-- <br /> -->
        <!-- Repeating Group questions -->
        <ng-container>
            <lib-wide-button id="btnFlaggedAnswers" [questions]="questions" [details]="details"
                [callToAction]="callToAction" [deleteCallToAction]="deleteCallToAction" [disabled]="disabled"
                [tag]="tag" [isMaxRepeat]="isMaxRepeat">
            </lib-wide-button>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>
