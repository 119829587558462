import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LayoutService } from '../../../../services/layout/layout.service';
import { CurrencyBoxField } from '../../../form-field/currencybox-field';
import { SharedSettingsService } from '../../../../services/settings/settings-base.service';
import { CurrencyFormatPipe } from '../../../../shared/pipes/currency-format.pipe';

@Component({
  selector: 'lib-currency-box',
  templateUrl: './currency-box.component.html',
  styleUrls: ['./currency-box.component.scss']
})
export class CurrencyBoxComponent implements OnInit {
  @Input() field: CurrencyBoxField;
  @Input() form: FormGroup;
  @Output() cToAction = new EventEmitter(); 
  @Output() valueChange = new EventEmitter();
  public isSmallView: boolean;
  public mandatoryErrorMessage: string;
  public emailErrorMessage: string;
  public validEmailErrorMessage: string;
  public confirmEmailErrorMessage: string;
  public minLengthErrorMessage: string;
  public maxLengthErrorMessage: string;
  public minRangeErrorMessage: string;
  public maxRangeErrorMessage: string;
  public numericErrorMessage: string;
  public invalidValueErrorMessage: string;
  public showTooltip =  false;
  public maxLength: number;
  public correctFormatValidationMsg: string;
  public currencyText: string;
  public validationMessage: string;

  constructor(private layoutService: LayoutService, private sharedSettingsService: SharedSettingsService,
              private currencyPipe: CurrencyFormatPipe) { }

  get formControl(): any {
    return this.form.controls[this.field.key];
  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  ngOnInit() {
    if (Array.isArray(this.field.mandatoryValidationMsg)) {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg[0];
      this.emailErrorMessage = this.field.mandatoryValidationMsg[1];
      this.validEmailErrorMessage = this.field.mandatoryValidationMsg[2];
      this.confirmEmailErrorMessage = this.field.mandatoryValidationMsg[3];
      this.minLengthErrorMessage = this.field.mandatoryValidationMsg[4];
      this.maxLengthErrorMessage = this.field.mandatoryValidationMsg[5];
      this.minRangeErrorMessage = this.field.mandatoryValidationMsg[6];
      this.maxRangeErrorMessage = this.field.mandatoryValidationMsg[7];
      this.numericErrorMessage = this.field.mandatoryValidationMsg[8];
      this.invalidValueErrorMessage = this.field.mandatoryValidationMsg[9];
    } else {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg;
      this.correctFormatValidationMsg = this.field.correctFormatValidationMsg!=null && 
      this.field.correctFormatValidationMsg!=='' ? this.field.correctFormatValidationMsg: 'Must be money value' ;
    }

    if (this.field.key === 'email') {
      this.showTooltip = true;
    }
    if (this.field.label === undefined || this.field.label === null)
    {
      this.field.label='';
    }

    this.currencyText=this.sharedSettingsService.currencyIso4217;
  }

  callToActionOnClick() {
    this.cToAction.emit();
  }

  valueChangeOnKeyPressed(event, data) {
    if (event.target.value?.length > 0) {
      this.convertToLocaliseCurrency(event);
    }
    event['tagName']="money";
    event['id'] = data.key;
    event['qIntentCode'] = data?.qIntentCode;
    if (data.globalFunction !== null && data.globalFunction !== "") {
      event['data'] = data;
    }
    this.valueChange.emit(event);

  }

  onFocusInEvent()
  {
    this.validationMessage= '';
  }

  onBlurEvent(event)
  {
    if (event.target.value?.length > 0) {
      this.convertToLocaliseCurrency(event);
    }
    
  }

  getSpecificErrorMessage() {
    if (this.formControl.hasError('required')) {
      return this.mandatoryErrorMessage;
    }
    
    if (this.formControl.hasError('notMatch')) {
      return this.confirmEmailErrorMessage;
    }
    if ((this.formControl.hasError('maxLength')) && (this.field.questionFormat?.toLowerCase() === 'money')) {
      return this.maxLengthErrorMessage;
    }

    if ((this.formControl.hasError('minLength')) && (this.field.questionFormat?.toLowerCase() === 'money')) {
      return this.minLengthErrorMessage;
    }

    if ((this.formControl.hasError('pattern')) && ((typeof this.field.regexValidation !== 'undefined' && this.field.regexValidation))) {
      return this.correctFormatValidationMsg;
    }

    if ((this.formControl.hasError('pattern')) && (this.field.questionFormat?.toLowerCase() === 'money')) {
      return this.invalidValueErrorMessage;
    }

  }
  convertToLocaliseCurrency(event) {
    const formattedValue = this.sharedSettingsService.reverseFormatNumber(event.target.value);
    if (this.formControl.valid) {
      const localizedFormat = this.currencyPipe.transform(formattedValue)
      event.target.value = localizedFormat;
      this.form.get(this.field.key).setValue(localizedFormat);
    }
  }
}

