import { Component, OnInit, Input } from '@angular/core';
import { LayoutService } from '../../../../services/layout/layout.service';

@Component({
  selector: 'lib-data-capture-heading',
  templateUrl: './data-capture-heading.component.html',
  styleUrls: ['./data-capture-heading.component.scss']
})
export class DataCaptureHeadingComponent implements OnInit {
  @Input() QnDescription: string;
  @Input() QnSubDescription: string;

  isDesktop = false;
  isTablet = false;
  isMobile = false;

  constructor(private layoutService: LayoutService) { }

  ngOnInit() {
    this.checkLayoutViewPort();
  }

  checkLayoutViewPort() {
    // ----- Checking screen resolution by angular layout/breakpoints
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isDesktop = this.layoutService.isLargeView();
      this.isTablet = this.layoutService.isMediumView();
      this.isMobile = this.layoutService.isSmallView();
    });
  }

}
