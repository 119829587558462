import { Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

    @Input() title: string;
    @Input() content: string;

    constructor() { }

    ngOnInit() {
  }
}
