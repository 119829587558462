<div class="logged-out">
    <div class="logout-container" id="logoutContainers">
      <section class="page-title">
        <h1 id="h1PrimaryHeader" class="screen-header primary-header" [ngClass]="{'desktop-header-size': isDesktop,
              'tablet-header-size': isTablet,
              'mobile-header-size': isMobile}">
          {{loggedOutHeaderText}}
        </h1>
      </section>
  
      <section class="logout-message tertiary-header">
        <mat-icon id="logoutIcon" class="logout-icon" tabindex="5">check_circle</mat-icon>
        <p id="logoutText">{{loggedOutMessageText}}</p>
      </section>
      <section class="flex-container">
        <div class="flex-item" *ngIf="showReturnToHomePage">
          <lib-primary-button id="btnReturnToHomePage" [text]="returnToHomePageText" (clicked)="returnToHomePage()"></lib-primary-button>
        </div>
      </section>
    </div>
  </div>
  