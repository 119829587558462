import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { LayoutService } from '../../../services/layout/layout.service';
import { IStraplineContent } from '../../../shared/models/strapline-content.model';
declare global {
  interface Window { dataLayer: any[]; }
}
@Component({
  selector: 'lib-strapline-content',
  templateUrl: './strapline-content.component.html',
  styleUrls: ['./strapline-content.component.scss']
})
export class StraplineContentComponent implements OnInit {

  @Input() productStraplineData: IStraplineContent;
  @Input() showQuote: boolean;
  @Output() startQuote = new EventEmitter();
  public isSticky = false;
  public isDesktop = false;
  public isTablet = false;
  public isMobile = false;
  public strapLineBackgroundImage: string;

  constructor(
    private layoutService: LayoutService
  ) { }

  ngOnInit() {
    this.checkLayoutViewPort();
    if (this.productStraplineData.straplineImage) {
      this.strapLineBackgroundImage = 'data:image/png;base64,' + this.productStraplineData.straplineImage;
    } else {
      this.strapLineBackgroundImage = 'assets/banner-images/banner-image.png';
    }
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const startJourneyElement = document.querySelector('#startJourneyContainer') as HTMLElement;
    if (startJourneyElement && !startJourneyElement.classList.contains('noStickyStyle')) {
      this.isSticky = window.pageYOffset >= 250;
    }
  }

  startQuoteFromStrapline() {
    // To send product information in GTM and Google Analytics using custom event
    if (window.dataLayer !== undefined || window.dataLayer !== null) {
      window.dataLayer.push({
        event: 'trackEvent',
        eventCategory: 'SyA',
        eventAction: 'Click',
        eventLabel: this.productStraplineData?.straplineHeaderText
      });
    }
    this.startQuote.emit();
  }

  // -- check layout and add css based on that
  checkLayoutViewPort() {
    // ----- Checking screen resolution by angular layout/breakpoints
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isDesktop = this.layoutService.isLargeView();
      this.isTablet = this.layoutService.isMediumView();
      this.isMobile = this.layoutService.isSmallView();
    });
  }

}

