import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { BaseConstantService } from '../../../services/constant/base-constant.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';

const closeLabel = 'Shared.lblClose';
@Component({
  selector: 'lib-top-bar-with-icon-and-link',
  templateUrl: './top-bar-with-icon-and-link.component.html',
  styleUrls: ['./top-bar-with-icon-and-link.component.scss']
})
export class TopBarWithIconAndLinkComponent implements OnInit, OnChanges {
  @Input() iconName: string;
  @Input() iconNearbyLink: string;
  @Input() linkLabel: string;
  @Output() emitIconAction = new EventEmitter();
  @Output() emitLabelAction = new EventEmitter();
  public isB2BFlow: boolean;
  @Input() closeButtonText = 'Close';
  public loginRegisterText;
  allowCustomerIdentification: any;

  constructor(private utilitiesService: UtilitiesService,
              private router: Router,
              private translate: TranslateService,
              private baseSettingsService: SharedSettingsService) {
  }

  ngOnInit() {
    this.isB2BFlow = this.baseSettingsService.isB2B();
    this.loginRegisterText = 'Hello ' + this.baseSettingsService.b2bName();
    this.allowCustomerIdentification = this.baseSettingsService.allowCustomerIdentification
    this.applyTranslations();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.iconName?.currentValue?.toString() !== "close") {
      window.history.pushState(null, null, null);
      window.onpopstate = function () {
      window.history.go(1);
      };
    }
  }

  public clickIconEmit(): void {
    this.emitIconAction.emit();
  }

  public clickLabelEmit(): void {
    this.emitLabelAction.emit();
  }

  private applyTranslations() {
    this.translate.get([closeLabel]).subscribe((res) => {
      this.closeButtonText = res[closeLabel];
    });
  }
  public closeCurrentTab() {
    window.open('', '_self').close();
  }
  public getClass(iconName: string) {
    if (iconName === undefined) {
      return 'pull-right';
    }
  }
}
