import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { SessionStorageService } from 'angular-web-storage';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { ManageAddressesComponent } from './components/manage-addresses/manage-addresses.component';
import { LangPreferenceComponent } from './components/lang-preference/lang-preference.component';
import { ConsentPreferencesComponent } from './components/consent-preferences/consent-preferences.component';
import { ContactPreferencesComponent } from './components/contact-preferences/contact-preferences.component';
import { AddressDetails, YourProfileData } from '../../models/your-profile.model';
import { BaseCustomerService } from '../../../services/customer/base-customer.service';
import { BaseAccountService } from '../../../services/account/base-account.service';
import { CustomerConsent } from '../../models/customerconsent.model';
import { DataShareConsent } from '../../models/datashareconsent.model';
import { SaveUserConsents } from '../../models/save-user-consents.model';
import { endPoints } from '../../core/app.config';
import { AddressModalComponent } from './components/address-modal/address-modal.component';
import { PagingService } from '../../../services/app-insights/paging-service';

@Component({
  selector: 'lib-base-your-profile',
  templateUrl: './base-your-profile.component.html',
  styleUrls: ['./base-your-profile.component.scss']
})

export class BaseYourProfileComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild(PersonalDetailsComponent) personalDetailsComponent: PersonalDetailsComponent;
  @ViewChild(ManageAddressesComponent) manageAddressComponent: ManageAddressesComponent;
  @ViewChild(AddressModalComponent) addressModalComponent: AddressModalComponent;
  @ViewChild(LangPreferenceComponent) langPreferenceComponent: LangPreferenceComponent;
  @ViewChild(ConsentPreferencesComponent) consentPreferencesComponent: ConsentPreferencesComponent;
  @ViewChild(ContactPreferencesComponent) contactPreferencesComponent: ContactPreferencesComponent;
  @Input() yourProfileData: YourProfileData;
  @Input() yourProfilePreviousData: any;
  @Output() languageChangeWarningEvent = new EventEmitter<boolean>();
  currentLanguageObject = {
    languageCode: '',
    languageId: null
  };
  readOnlyControls: string[] = [];
  private customLogos: any[] = [];
  public consentLogo: string;
  public contactLogo: string;
  private customerId: number;
  private isAnySectionEdited: boolean; // To load the latest data on click of cancel after edit

  constructor(
    private readonly sessionStorage: SessionStorageService,
    private readonly baseCustomerService: BaseCustomerService,
    private accountService: BaseAccountService,
    private route: ActivatedRoute,
    private pagingService: PagingService) { }


  ngOnInit(): void {
    this.isAnySectionEdited = false;
    this.customerId = this.yourProfilePreviousData.yourProfileModel.userProfile.customerId;
    this.checkForReadOnlyControls();
    this.setReadOnlyPersonalDetails(true);
    this.setReadOnlyConsentPreferences(true);
    this.setReadOnlyContactPreferences(true);
    this.getLogos();
  }

  getLogos() {
    this.customLogos = this.route.snapshot.data.getLogoResolver;
    this.consentLogo = this.customLogos.find(value => value.title === 'Consent_Your_Profile').imageData;
    this.contactLogo = this.customLogos.find(value => value.title === 'Contact_Your_Profile').imageData;
  }

  checkForReadOnlyControls() {
    const personalDetails = this.yourProfileData.personalDetails;
    for (const [key, value] of Object.entries(personalDetails)) {
      if (value?.readOnly) {
        this.readOnlyControls.push(value?.key);
      }
    }
  }

  // Consent Preference Save
  consentPreferencesEvent(eventType: string) {
    if (eventType === 'save') {
      this.saveConsentPreferences('consent');
    } else if (eventType === 'edit') {
      this.setReadOnlyConsentPreferences(false);
    } else if (eventType === 'cancel') {
      this.resetConsentPreferencesForm();
    }
  }

  setReadOnlyConsentPreferences(readOnlyValue: boolean) {
    this.yourProfileData.consentPreferences.isEditMode = !readOnlyValue;

    this.yourProfileData.consentPreferences.consentList.forEach((value, i) => {
      if (value.consent.hasOwnProperty('readOnly')) {
        this.yourProfileData.consentPreferences.consentList[i].consent.readOnly = readOnlyValue;
      }
    });
  }

  saveConsentPreferences(saveType: string) {
    const saveUserConsnetObj = this.buildUserConsent(
      this.yourProfilePreviousData.yourProfileModel.consentPurposeList,
      this.yourProfilePreviousData.yourProfileModel.contactPreferences,
      this.consentPreferencesComponent.consentPreferencesForm.value,
      this.contactPreferencesComponent.contactPreferencesForm.value,
      saveType);
    this.baseCustomerService.saveUserConsent(saveUserConsnetObj).then((value) => {
      this.isAnySectionEdited = true;
      if (saveType === 'consent') {
        this.setReadOnlyConsentPreferences(true);
      } else if (saveType === 'contact') {
        this.setReadOnlyContactPreferences(true);
      }
    });
  }

  private buildUserConsent(consentModel, contactModel, consentFormAnswers,
                           contactFormAnswers, preferenceSaveType: string = 'both'): SaveUserConsents {
    const customerConsents: CustomerConsent[] = consentModel.map((consentObj) => {
      const obj: CustomerConsent = {
        consentOptIn: JSON.parse(consentFormAnswers[consentObj.title]),
        consentPurposeId: consentObj.consentPurposeId,
        customerConsentId: consentObj.customerConsentId,
        customerId: this.customerId,
        id: consentObj.consentPurposeId,
        languageId: consentObj.languageId,
      };
      return obj;
    });

    const dataShareConsent: DataShareConsent = {
      agreeDataSharingConsent: contactModel.agreeDataSharingConsent,
      agreeEmailTPCP: JSON.parse(contactFormAnswers.email),
      agreeMailTPCP: JSON.parse(contactFormAnswers.address),
      agreePhoneTPCP: JSON.parse(contactFormAnswers.phone),
      customerId: this.customerId,
      dataConsentId: contactModel.dataConsentID
    };

    const saveUserConsents: SaveUserConsents = {
      customerConsents,
      dataShareConsent,
      customerId: this.customerId,
      languageId: this.sessionStorage.get('LanguageId'),
      userName: this.yourProfilePreviousData.yourProfileModel.userProfile.email,
      saveType: preferenceSaveType
    };
    return saveUserConsents;
  }

  resetConsentPreferencesForm() {
    if (this.isAnySectionEdited) {
      this.updateLatestDetails('consent');
    } else {
      this.setReadOnlyConsentPreferences(true);
      this.consentPreferencesComponent.buildFormFields();
    }
  }

  // Contact Preference Save
  contactPreferencesEvent(eventType: string) {
    if (eventType === 'save') {
      this.saveConsentPreferences('contact');
    } else if (eventType === 'edit') {
      this.setReadOnlyContactPreferences(false);
    } else if (eventType === 'cancel') {
      this.resetContactPreferencesForm();
    }
  }

  resetContactPreferencesForm() {
    if (this.isAnySectionEdited) {
      this.updateLatestDetails('contact');
    }
    this.setReadOnlyContactPreferences(true);
    this.contactPreferencesComponent.buildFormFields();
  }

  setReadOnlyContactPreferences(readOnlyValue: boolean) {
    this.yourProfileData.contactPreferences.isEditMode = !readOnlyValue;

    this.yourProfileData.contactPreferences.contactTypeList.forEach((value, i) => {
      if (value.contact.hasOwnProperty('readOnly')) {
        this.yourProfileData.contactPreferences.contactTypeList[i].contact.readOnly = readOnlyValue;
      }
    });
  }


  // Personal Details Functions
  personalDetailsEvent(eventType: string) {
    if (eventType === 'save') {
      this.savePersonalDeatils('personalDetails');
    } else if (eventType === 'edit') {
      this.setReadOnlyPersonalDetails(false);
    } else if (eventType === 'cancel') {
      this.resetPersonalDetailsForm();
    }
  }

  async savePersonalDeatils(saveType: string) {
    const personalDetailsForm = this.personalDetailsComponent.personalDetailsForm;
    const languagePreferencesForm = this.langPreferenceComponent?.languagePreferenceForm;
    const objDate = this.pagingService.convertDateIntoUTC(personalDetailsForm?.controls?.dateOfBirth?.value);
    const yourProfileModel = {
      userProfile: {
        customerId: this.customerId,
        title: personalDetailsForm?.controls?.title?.value,
        customerIdentifier: personalDetailsForm?.controls?.ssn?.value,
        firstName: personalDetailsForm?.controls?.firstName?.value,
        lastName: personalDetailsForm?.controls?.lastName?.value,
        phoneNumber: personalDetailsForm?.controls?.phoneNumber?.value,
        countryCode: personalDetailsForm?.controls?.countryCode?.value,
        dateOfBirth: objDate,
        placeOfBirth: personalDetailsForm?.controls?.placeOfBirth?.value,
        placeOfBirthTown: personalDetailsForm?.controls?.placeOfBirthTown?.value,
        customerPreferredLanguageID: languagePreferencesForm?.controls?.currentLanguage?.value,
        email2: personalDetailsForm?.controls?.email2?.value,
        IsB2bUser: false
      },

      currentUser: {
        sponsorId: this.sessionStorage.get('SponsorId'),
        currentUserName: this.sessionStorage.get('LoggedInUser'),
        languageCode: this.sessionStorage.get('LanguageCode'),
        subGroupID: '',
      }
    };

    await this.baseCustomerService.saveB2CCutomerPersonalDetails(yourProfileModel).subscribe(res => {
      if (res && res.isSsuccess === true) {
        this.isAnySectionEdited = true;
        if (saveType === 'personalDetails') {
          this.setReadOnlyPersonalDetails(true);
        } else if (saveType === 'languagePreferences') {
          this.setReadOnlyLanguagePreferences(true);

          const currentLanguageId = this.langPreferenceComponent?.languagePreferenceForm.controls.currentLanguage.value;

          this.currentLanguageObject.languageId = currentLanguageId;

          this.currentLanguageObject.languageCode =
            this.yourProfilePreviousData.customerDetailOptions.sponsorLanguage.preferredLanguages.find((obj) => {
              return obj.languageID === currentLanguageId;
            }).languageCode;

          this.sessionStorage.set('LanguageCode', this.currentLanguageObject.languageCode);
          this.sessionStorage.set('LanguageId', this.currentLanguageObject.languageId);

          this.baseCustomerService.setCustomerPreferredLanguage(
            this.currentLanguageObject.languageId, this.currentLanguageObject.languageCode);
          window.location.reload();

        }
      }
    });
  }

  addressUpdateEvent(address: any) {
    if (address) {
      address.customerId = this.sessionStorage.get('customerId');
      address.SponsorId = this.sessionStorage.get('SponsorId');
      address.UserLanguageCode = this.sessionStorage.get('LanguageCode');

      this.baseCustomerService.saveCustomerAddress(address).subscribe(res => {

        if (res && res === true) {
          console.log('Address saved successcfully..!');

          const addressList: AddressDetails[] = [];
          this.accountService.yourProfile().subscribe(result => {
            if (result) {
              this.yourProfileData.manageAddresses.addressList =
                this.baseCustomerService.buildCustomerAddressList(result.yourProfileModel?.addresses);
            }
          });
        }
      });
    }
  }

  setReadOnlyPersonalDetails(readOnlyValue: boolean) {
    this.yourProfileData.personalDetails.isEditMode = !readOnlyValue;

    for (const [key, value] of Object.entries(this.yourProfileData.personalDetails)) {
      if (!this.readOnlyControls.includes(value?.key) && value.hasOwnProperty('readOnly')) {
        this.yourProfileData.personalDetails[key].readOnly = readOnlyValue;
      }
    }
  }

  resetPersonalDetailsForm() {
    if (this.isAnySectionEdited) {
      this.updateLatestDetails('personalDetails');
    } else {
      this.setReadOnlyPersonalDetails(true);
      this.personalDetailsComponent.buildFormFields();
    }
  }

  // Language Preferences Functions
  langPreferencesEvent(eventType: string) {
    if (eventType === 'save') {
      this.saveLanguagePreferences();
    } else if (eventType === 'edit') {
      this.setReadOnlyLanguagePreferences(false);
    } else if (eventType === 'cancel') {
      this.resetLanguagePreferencesForm();
    }
  }

  saveLanguagePreferences() {
    const baseUrl = endPoints.baseUrl.toLowerCase();
    if (baseUrl.indexOf('claims') === 1) {
      this.languageChangeWarningEvent.emit(true);
    } else {
      this.savePersonalDeatils('languagePreferences');
    }
  }

  setReadOnlyLanguagePreferences(readOnlyValue: boolean) {
    this.yourProfileData.languagePreference.isEditMode = !readOnlyValue;

    this.yourProfileData.languagePreference.currentLanguage.readOnly = readOnlyValue;
  }

  resetLanguagePreferencesForm() {
    if (this.isAnySectionEdited) {
      this.updateLatestDetails('languagePreferences');
    }
    this.setReadOnlyLanguagePreferences(true);
    this.langPreferenceComponent.buildFormFields();
  }

  async updateLatestDetails(updateType: string) {
    this.accountService.yourProfile().subscribe((yourProfileDetails) => {
      if (updateType === 'personalDetails') {
        this.yourProfileData.personalDetails.title.value = yourProfileDetails.yourProfileModel.userProfile.title;
        this.yourProfileData.personalDetails.ssn.value = yourProfileDetails.yourProfileModel.userProfile.customerIdentifier;
        this.yourProfileData.personalDetails.firstName.value = yourProfileDetails.yourProfileModel.userProfile.firstName;
        this.yourProfileData.personalDetails.lastName.value = yourProfileDetails.yourProfileModel.userProfile.lastName;
        this.yourProfileData.personalDetails.email.value = yourProfileDetails.yourProfileModel.userProfile.email;
        this.yourProfileData.personalDetails.dob.value = yourProfileDetails.yourProfileModel.userProfile.dateOfBirth;
        // in one scenario 'personalDetails' doesn't have 'phonenumber' property
        if (this.yourProfileData?.personalDetails?.phoneNumber?.value) { 
          this.yourProfileData.personalDetails.phoneNumber.value = yourProfileDetails.yourProfileModel.userProfile.phoneNumber;
        }
        this.yourProfileData.personalDetails.countryCode.value = yourProfileDetails.yourProfileModel.userProfile.countryCode;
        this.isAnySectionEdited = false;
        this.resetPersonalDetailsForm();
      }

      if (updateType === 'languagePreferences') {
        this.yourProfileData.languagePreference.currentLanguage.value =
          yourProfileDetails.yourProfileModel.userProfile.languageId;
        this.isAnySectionEdited = false;
        this.resetLanguagePreferencesForm();
      }

      if (updateType === 'consent') {
        yourProfileDetails.yourProfileModel.consentPurposeList.forEach((value, index) => {
          this.yourProfileData.consentPreferences.consentList[index].consent.value = value.consentValue.toString();
        });
        this.isAnySectionEdited = false;
        this.resetConsentPreferencesForm();
      }

      if (updateType === 'contact') {
        this.yourProfileData.contactPreferences.contactTypeList[0].contact.value =
          yourProfileDetails.yourProfileModel.contactPreferences.agreeEmailTPCP.toString();

        this.yourProfileData.contactPreferences.contactTypeList[1].contact.value =
          yourProfileDetails.yourProfileModel.contactPreferences.agreeMailTPCP.toString();

        this.yourProfileData.contactPreferences.contactTypeList[2].contact.value =
          yourProfileDetails.yourProfileModel.contactPreferences.agreePhoneTPCP.toString();
        this.isAnySectionEdited = false;
        this.resetContactPreferencesForm();
      }

    });

  }

}

