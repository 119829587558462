// This class must be descended and then provided in each target module to load specific translations for that module's TranslationsLoader.

import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { lastValueFrom } from 'rxjs';

@Injectable()
export abstract class BaseTranslationsResolver  {
  constructor(
    private translate: TranslateService,
    private sessionStorage: SessionStorageService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Set up Translations.
    // this language will be used as a fallback when a translation isn't found in the current language
    await this.translate.setDefaultLang('en-GB');

    const currentLang = this.sessionStorage.get('LanguageCode');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    await lastValueFrom(this.translate.use(currentLang));

    const translations = await lastValueFrom(this.translate.getTranslation(currentLang));
  }
}
