import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse, HttpBackend } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { HttpOptions } from '../../shared/models/http-options.model';
import { LoggingService } from '../../shared/core/app-insights/logging.service';


@Injectable({
  providedIn: 'root'
})

export class CommonHttpService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private httpClient: HttpClient;

  constructor(
    private http: HttpClient,
    private router: Router,
    private httpBackend: HttpBackend,
    private loggingService: LoggingService
  ) {
    // To Bypass the MSAL interceptor
    this.httpClient = new HttpClient(httpBackend);
  }

  // HTTP COMMON GET METHOD TO RETRIEVE FILE CONTENT
  httpGetFileService(url, httpOptions) {
    const req = new HttpRequest('GET', url, httpOptions);
    return this.http.request(req);
  }

  // HTTP COMMON GET METHOD - Bearer token is not added in the header.
  httpGetService(url) {
    return this.httpClient.get<any>(url, this.httpOptions)
      .pipe(
        catchError(this.handleError('get', []))
      );
  }


  // HTTP COMMON POST METHOD - Bearer token is not added in the header.
  httpPostService(url, data) {
    return this.httpClient.post<any>(url, data, this.httpOptions)
      .pipe(
        catchError(this.handleError('post', []))
      );
  }

  // HTTP COMMON GET METHOD - Bearer token is added in the header.
  httpGetServiceWithBearer(url) {
    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        catchError(this.handleError('get', []))
      );
  }

  // HTTP COMMON POST METHOD - Bearer token is added in the header.
  httpPostServiceWithBearer(url, data) {
    return this.http.post<any>(url, data, this.httpOptions)
      .pipe(
        catchError(this.handleError('post', []))
      );
  }

  // Http Get To Retrieve File content
  httpGetFile(url) {
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get(url, { headers, responseType: 'blob' })
      .pipe(
        catchError(this.handleError('get', []))
      );
  }
  // Http Post for file content
  httpPostFile(url, data): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      Accept: 'application/json',
    });

    const options: HttpOptions = {
      headers,
      params: { data },
      observe: 'response' as 'response',
      responseType: 'blob' as 'blob'
    };
    return this.http.post<any>(url, data, options);
  }

  httpPutService(url, data) {
    return this.http.put<any>(url, data, this.httpOptions)
      .pipe(
        catchError(this.handleError('put', []))
      );
  }

  // HTTP COMMON ERROR HANDLER
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // Send the error details to AppInsights
      this.loggingService.logError(error);

      switch (error.status) {
        case 500: {
          console.log('Internal server error');
          this.navigateErrorPage();
          /* this._notificationService.showMessage(
              this._translationService.getTranslatedValue('ADMIN.SAVEFAIL'),
              this._translationService.getTranslatedValue('ADMIN.DISMISS'), 4000); */
          break;
        }
        case 401: {
          console.log('unauthorized');
          this.router.navigate(['/login']);
          break;
        }
        case 403: {
          this.router.navigate(['/error-page']);
          console.log('Forbidden/unauthorized');
          break;
        }
        default: {
          console.log('Invalid error');
          this.navigateErrorPage();
          break;
        }
      }
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private navigateErrorPage() {
    this.router.navigate(['/error-page']);
  }

  private log(message: string) {
    console.log('API service issue: ' + message);
  }
}

