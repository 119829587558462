export class YourPolicy {
    productCategory: string;
    productName: string;
    productIcon: string;
    insurerName: string;
    policyNumber: string;
    policyInceptionDate: string;
    policyExpiryDate: string;
    policyTerm: string;
    premium: string;
    formattedPremium: string;
    paymentTerms: string;
    policyID: number;
    productTemplateId: number;
    renewalQuoteStatus: string;
    isRenewPolicyButton: boolean;
    renewalPolicyButtonLabel: string;
    renewalQuoteOrderID: number;
    renewalQuoteReferenceID: string;
    isB2cSingleCarrierMultiQuoteEnabled: boolean;
    quoteOrigin: string;
    showRenewalPolicyButton: boolean;
    quoteReferenceID: string;
    orderId: number;
    policyStatusInEnglish: string;
    policyStatus: string;
    renewalPolicyID: number;
    renewalPolicyNumber: string;
    renewalProductId: number;
    policyQuestionResponses: PolicyResponse[]
}

export class YourPolicies {
    PoliciesList: YourPolicy[];
}

export interface PolicyDocument {
    fileExtension: string;
    documentTypeName: string;
    createdDate: string;
}

export interface PolicyUpload {
    fileIcon: string;
    fileName: string;
    createdDate: string;
}

export class PolicyResponse {
    question: string;
    questionResponse: string;
}
