import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-simple-wide-card',
  templateUrl: './simple-wide-card.component.html',
  styleUrls: ['./simple-wide-card.component.scss']
})
export class SimpleWideCardComponent implements OnInit {
  @Input() labelName: string;
  @Input() isLast: boolean;
  @Input() iconName: string;
  constructor() { }

  ngOnInit() {
  }

}
