import { Injectable } from '@angular/core';
import {
  MsalAuthenticationService, BaseConstantService,
  SharedSettingsService
} from 'wtw-ui-components';
import { SalesSettingsService } from '../settings/settings.service';

@Injectable({
  providedIn: 'root'
})
export class ConstantService extends BaseConstantService {

  static readonly MenuTypeID = '7'; // Static menu type id will be different for each portal
  static readonly CustomerProfileName = 'Customer';
  static readonly PublicProfileName = 'Public';

  // *** Static SkipLandingPageValue in order skip landing page and redirect to quote-question page *** //
  static readonly SkipLandingPageValue = '1';

  static readonly PageName = {
    AccountVerification: 'Account Verification',
    YourProfile: 'Your Profile',
    YourPolicies: 'Your Policies'
  };

  readonly voucherHardErrorPath = 'VoucherCodeError';
  readonly voucherInvalidPath = 'VoucherCodeInvalid';
  readonly PaymentTermMonthly = 'PaymentTermMonthly';
  readonly PaymentTermSinglePayment = 'PaymentTermSinglePayment';
  readonly PaymentFrequencyMonthlyPayment = 'SelectableFrequencyMonthlyPayment';
  readonly PaymentFrequencyInstallmentPayment = 'SelectableFrequency<N>Installment';

  readonly yourDetailsPageName = 'your-details';
  readonly yourconsentPageName = 'your-consent';
  readonly youraddressPageName = 'your-address';
  readonly primarycoverage = "primary-coverage";
  readonly primaryOptionalCoverage = "primary-optional-coverage";
  readonly multicarrierpageName = "multi-carrier";
  readonly policyquestionpageName ="policy-question";
  readonly yourQuotePageName = 'your-Quote';
  readonly PrimaryGrossPremium="PrimaryGrossPremium";
  readonly extCustomerID = 'extCustomerID'
  readonly ProfileType = {
    B2BUSER: 'B2B USER',
    B2CCUSTOMER: 'B2C CUSTOMER',
    BUSINESSUSER: 'BUSINESS USER',
    AFFINITYOPSADMIN: 'AFFINITY OPS ADMINISTRATOR'
  }

  readonly QuestionType = {
    Subtotal: 'SUBTOTAL',
    TaxOnPremium: 'TAXONPREMIUM',
    TaxSubtotal: 'TAXSUBTOTAL',
    Charge: 'CHARGE',
    TaxOnCharge: 'TAXONCHARGE',
    Commission: 'TAXONCHARGE',
    Policy: 'POLICY',
    Quote: 'QUOTE',
  };

  readonly QuoteStatus = {
    DRAFT: "DRAFT",
    SignaturePending: "SIGNATUREPENDING",
    Expired: "QUOTEEXPIRED",
    PendingConfirmation: "QUOTEPENDINGCONFIRMATION",
    TakenUp: "QUOTETAKENUP",
    Cancelled: "QUOTECANCELLED",
    Quoted: "QUOTED"
  }

  readonly ReferralPermission = {
    APPROVEDECLINEONLY: "approvedeclineonly",
    EDITONLY: "editonly",
    EDITWITHOUTPREMIUMOVERRIDE: "editwithoutpremiumoverride",
    APPROVEDECLINEWITHPREMIUMOVERRIDE: "approvedeclinewithpremiumoverride"
  }

  constructor(msalAuthenticationService: MsalAuthenticationService, settingService: SharedSettingsService) {
    super(msalAuthenticationService, settingService);
  }
}
