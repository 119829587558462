<div class="field-wrapper" id="fieldWrapper">
<div id="divQuestionContainer" class="question-container d-flex justify-content-between align-items-center cursor-pointer" 
    [ngClass]="{'answer-container-error': this.formControl.value && !this.formControl.valid, 
    'date-container-disabled':disableElement, 'datePicker-container-medium': !isSmallView}" (click)="timepicker.open()">
        <div class="d-flex align-timepicker">
            <div id="divIcon" class="icon-container d-flex justify-content-center align-items-center mx-3">
                <mat-icon id="iconEvent">schedule</mat-icon>
            </div>
            
            <div class="align-timepicker">
            <form [formGroup]="form">
            <mat-form-field id="matFormField" class="d-flex align-items-center disable-container">
                <input class="align-self-center" matInput [ngxMatTimepicker]="timepicker"
                formControlName="{{field.key}}" [id]="field.key" [readonly]="field.readOnly"
                [disabled]="disableElement" format="24" placeholder="{{field.label}}">
                <ngx-mat-timepicker [appendToInput]="true" #timepicker (closed)="closeEvent()"></ngx-mat-timepicker>
            </mat-form-field>                                   
            </form>  
        </div>       
        </div>
        <div class="btn-container" id="divBtnContainer">
            <button id="btnEdit1" type="button" class="btn btn-sm" [disabled]="disableElement">{{field.callToActionLabel}}</button>
        </div>
    </div>
    <mat-error id="matError" class="date-mat-error" *ngIf="inputClicked">{{field.mandatoryValidationMsg}}</mat-error>
</div>



