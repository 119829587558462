import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerFieldMapper } from '../field-mapper-strategy/strategies/date-picker-field-mapper';
import { Question } from '../../../models';
import { FormGroupControlService } from '../../../../services/form-group/form-group.service';
import { DatePickerField } from '../../../form-field/date-picker-field';

const datePickerErrorMessage = 'Shared.lblDatePickerErrorMessage';
const editLabel = 'Shared.lblEdit';

@Component({
  selector: 'lib-datepicker-data-capture',
  templateUrl: './datepicker-data-capture.component.html',
  styleUrls: ['./datepicker-data-capture.component.scss']
})
export class DatepickerDataCaptureComponent implements OnInit, AfterViewInit {
  @Input() qnData: Question;
  @Input() nextBtn: string;
  @Output() updateEmitter = new EventEmitter();
  @Input() offsetBasisQnData: Question;
  dateForm: FormGroup;
  field: DatePickerField;
  datePickerErrorMessageText: string;
  editLabelText: string;
  offsetValue: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private formGroupService: FormGroupControlService,
    private fieldMapper: DatePickerFieldMapper) { }

  ngOnInit(): void {
    this.applyTranslations();
    this.createFormGroup();
    this.provideOffsetValue();
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  createFormGroup() {
    this.field = this.fieldMapper.map(this.qnData, this.datePickerErrorMessageText, this.editLabelText, undefined);
    this.dateForm = this.formGroupService.toFormGroup([this.field]);
  }

  submitDate() {
    const momentObj = this.dateForm.get(this.field.key).value;
    const val = momentObj.isMoment ? momentObj.format() : momentObj;
    const answeredData = this.qnData.answerSets[0];
    answeredData.name = val;
    answeredData.isAnswered = true;
    this.updateEmitter.emit();
  }

  private applyTranslations() {
    this.translate.get([datePickerErrorMessage, editLabel]).subscribe((res) => {
      this.datePickerErrorMessageText = res[datePickerErrorMessage];
      this.editLabelText = res[editLabel];
    });
  }

  private provideOffsetValue() {
    this.offsetValue = this.offsetBasisQnData && this.offsetBasisQnData.answerSets[0].name;
  }
}
