import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { DropdownItem } from '../../../models/dropdown-item.model';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../../services/layout/layout.service';

const hiUserText = 'Shared.lblHiUserText';
@Component({
  selector: 'lib-icon-menu',
  templateUrl: './icon-menu.component.html',
  styleUrls: ['./icon-menu.component.scss']
})
export class IconMenuComponent implements OnInit {
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  @Input() Categories: DropdownItem[];
  @Input() selectedOption: any;
  @Input() iconName: any;
  @Output() selectChange = new EventEmitter();
  selectLanguagesText: string;
  @Output() selectedItem = new EventEmitter();
  @Input() loggedInUserName: string;
  @Input() isLanguage: boolean;
  hiUserTextLabel: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private layoutService: LayoutService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.checkDeviceWidth();
    this.applyTranslations();
    this.selectLanguagesText = this.selectedOption?.name;
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }
  private applyTranslations() {
    this.translate.get([hiUserText]).subscribe((res) => {
      this.hiUserTextLabel = res[hiUserText];
    });
  }

  onSelectionChange(item) {
    this.selectedOption = item;
    this.selectChange.emit(item);
  }
  mobileMenuClose() {
    this.document.body.classList.remove('noScroll');
  }

  onItemSelected(item) {
    this.selectedItem.emit(item);
  }
}
