import { FormFieldBase } from './form-field-base';
import { AlertMessage } from '../models';


export class DropDownField extends FormFieldBase<string> {
  controlType = 'dropdown';

  options: { name: string, value: string, alertMessage?: AlertMessage, index?: number, id?: any, childQIntentCode?: string }[] = [];
  groupOptions: { label: string, options: { name: string, value: string } }[] = [];

  constructor(options: {} = {}) {
    super(options);
    this.options = options['options'] || [];
    this.groupOptions = options['groupOptions'] || [];
  }
}
