<header id="header" *ngIf="data !== undefined">
    <lib-top-bar-with-icon-and-link id="appTopBar" [iconName]="iconName" (emitIconAction)="closeAndGoBack();"
        (emitLabelAction)="logout();" [linkLabel]="logoutText"></lib-top-bar-with-icon-and-link>
</header>
<ng-container *ngIf="progressBar">
    <lib-progress-bar-steps [progress]=progressBar.progress [total]=progressBar.totalSteps
        [stepName]=progressBar.stepName></lib-progress-bar-steps>
</ng-container>
<section class="qnContainer" id="qnContainer">
    <form class="qnForm" id="qnForm">
        <div [ngClass]="{'qnContainer-small':isMobile,'qnContainer-medium-large':!isMobile}">
            <ng-template libDataCapture></ng-template>
        </div>
    </form>
</section>
