import { Input } from '@angular/core';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupControlService } from '../../../../../services/form-group/form-group.service';
import { FormFieldBase } from '../../../../form-field/form-field-base';
import { Address, ManageAddresses } from '../../../../models';
// import { FormGroup } from '@angular/forms';
// import { FormFieldBase, FormGroupControlService } from 'wtw-ui-components';

@Component({
  selector: 'lib-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {

  pageFormFields: FormFieldBase<string>[] = [];
  addressForm: FormGroup;
  address: ManageAddresses;
  isDefaultAddress = false;
  isDefaultAddressDisabled = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: { addForm: FormGroup, add: ManageAddresses, typeLabel: string, eventType: string },
              private readonly formGroupControlService: FormGroupControlService,
              public dialogRef: MatDialogRef<AddressModalComponent>) { }

  ngOnInit(): void {
    this.isDefaultAddress = this.data.addForm.controls.defaultAddress.value;
    this.isDefaultAddressDisabled = this.isDefaultAddress;
    if (this.data.eventType === 'new') {this.isDefaultAddressDisabled = false; }
    this.address = this.data.add;
    this.buildFormFields();
  }

  async buildFormFields() {

    const formFields: FormFieldBase<string>[] = [];
    for (const [key, value] of Object.entries(this.data.add)) {
      if (key !== 'pageInfo' && key !== 'addressList') {
        formFields.push(value);
      }
    }

    this.formGroupControlService.formFieldswithOrder(formFields).subscribe(results => {
      this.pageFormFields = results;
      this.addressForm = this.data.addForm;
    });
  }

  onClickEvent() {
    if (this.addressForm.valid) {
      const addressResult = {
        firstLine: this.data.addForm.controls.addressLine1.value,
        city: this.data.addForm.controls.town.value,
        countryId: this.data.addForm.controls.country.value,
        postCode: this.data.addForm.controls.postalCode.value,
        isNew: this.data.eventType === 'new' ? true : false,
        isDefault: this.isDefaultAddress,
        isDeleted: false,
        customerAddressID: this.data.addForm.value.addressId,
        customerAddressTypeID: 1,
      };

      this.dialogRef.close(addressResult);
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
