export class Alert {
    id: string;
    message: AlertMessage;
    autoClose: boolean;
    keepAfterRouteChange: boolean;
    fade: boolean;
    alertType: string;

    constructor(init?: Partial<Alert>) {
        Object.assign(this, init);
    }
}

export class AlertMessage {
    qIntentCode?: string;
    qDescription?: string;
    qSubDescription?: string;
    parentQIntentCode?: string;
    parentOValue?: string;
    cssClasses?: string;

}
