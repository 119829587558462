import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ButtonBaseDirective } from '../button-base';

@Component({
  selector: 'lib-link-button',
  templateUrl: './link-button.component.html',
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent extends ButtonBaseDirective implements OnInit {
  @Input() showBookmark: boolean;
  @Input() linkAction: string;
  @Input() linkTotext: string;

  ngOnInit() { }
}
