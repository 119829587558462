import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../../../services/layout/layout.service';
import { DropDownField } from '../../../form-field/dropdown-field';
import { FormGroup, FormControl } from '@angular/forms';
import { NotificationAlertService } from '../../../../services/notification-alert/notification-alert.service';
import { MatSelectChange } from '@angular/material/select';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';
import { BaseConstantService } from '../../../../services/constant/base-constant.service';

@Component({
  selector: 'lib-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit, AfterViewInit {
  public mandatoryErrorMsg;
  public isSmallView: boolean;
  public dropDownFormControlName: string;
  @Input() field: DropDownField;
  @Output() valueChange = new EventEmitter();
  @Input() form: FormGroup;
  constructor(
    private layoutService: LayoutService,
    private questionSetService: QuestionSetService
  ) { }

  get formControl(): any {
    return this.form.controls[this.dropDownFormControlName];
  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  onSelectionChange(event, data: DropDownField) {

    this.questionSetService.showAlertMessages(data, event?.value);

    event['tagName'] = 'SELECT';
    event['id'] = data.key;
    event['qIntentCode'] = data?.qIntentCode;

    this.questionSetService.setResetChildQintentCode(data, event);

    // to find whether parent intentcode configured with global function
    const grantParent = this.questionSetService?.internalQuestionSet?.length > 0 ?
      this.questionSetService.internalQuestionSet[this.questionSetService?.currentPosition]?.
        childQuestionSets?.filter(x => x.qIntentCode === data.onDemandDataParameters?.split(',').map(s => s.trim())[0])
      : undefined;

    // to find rootLevelParent (When answerset of childQuestionset contains questions.)
    const rootLevelParentName: string = data?.onDemandDataParameters?.split(',').map(s => s.trim())[0];
    let parent = undefined;
    if (rootLevelParentName && rootLevelParentName.trim().length !== 0 && this.questionSetService?.internalQuestionSet?.length > 0) {

      this.questionSetService.internalQuestionSet[this.questionSetService?.currentPosition]?.
        childQuestionSets[0]?.answerSets?.forEach(answerSet => {
          if (parent === undefined || parent?.length === 0) {
            parent = answerSet?.childQuestionSets?.filter(cq => cq.qIntentCode.toLowerCase() === rootLevelParentName.toLowerCase());
          }
        })
    }

    if ((data.globalFunction !== null && data.globalFunction !== "") ||
      (grantParent?.length > 0 && grantParent[0]?.globalFunction !== null && grantParent[0].globalFunction !== "") ||
      (parent?.length > 0 && parent[0]?.globalFunction !== null && parent[0].globalFunction !== "")) {
      event['data'] = data;

      // adding this code to get the id of selected option
      data?.options.filter(option => {
        if (option.name === event.value) {

          event['data']['selectedId'] = option?.id
        }
      });
    }

    if (data?.persistChildValue === true) {
      event['persistChildValue'] = true;
    }
    this.valueChange.emit(event);
  }

  onAutoSearchSelectionChange(event) {
    this.onSelectionChange(event, this.field);
  }

  ngOnInit() {
    this.mandatoryErrorMsg = this.field.mandatoryValidationMsg || 'This field is required';
    if (this.field.label === undefined || this.field.label === null) {
      this.field.label = '';
    }
    this.checkViewPortWidth();
    this.createForm();

    // Check default value for dropdown
    const defaultValue = this.field.options.filter((object, ind) => {
      object['index'] = ind; return object.value === this.field.value;
    })[0];
    // OnDemand data for default value dropdown
    if (defaultValue) {
      if (this.field.odefault && this.field.onDemandDataSubscribers !== undefined && this.field.onDemandDataSubscribers !== "") {
        const $event = new MatSelectChange(null, this.field.value);
        $event['tagName'] = 'SELECT';
        $event['id'] = this.field.key;
        $event['qIntentCode'] = this.field?.qIntentCode;
        $event.value = this.field.value;
        if (this.field.globalFunction !== null && this.field.globalFunction !== "") {
          $event['data'] = this.field;
        }
        this.valueChange.emit($event);
      }
    }
    if (this.field?.odefault === true) {
      const $event = new MatSelectChange(null, this.field.value);
      this.questionSetService.setResetChildQintentCode(this.field, $event);
    }
  }

  ngAfterViewInit() {
    if (this.field?.options?.length > 0) {
      this.questionSetService.showAlertMessages(this.field, this.field.value);
    }
  }

  /* Generic functions that relate to datepicker */
  createForm() {
    if (this.form) {
      this.dropDownFormControlName = this.field['key'] ? this.field['key'] : 'dropDownField';
    } else {
      this.dropDownFormControlName = 'dropDownField';
      this.form = new FormGroup({ dropDownField: new FormControl('') });
    }
  }

}
