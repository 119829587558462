import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { Observable } from 'rxjs';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalizationService } from '../../../services/localization/localization.service';
import { PolicyService } from '../../../services/policy/policy.service';
import { IGetUIElement } from '../../models/get-ui-element.model';
import { IGetUIElementsPayload } from '../../models/get-ui-elements-payload.model';



@Injectable({
  providedIn: 'root'
})

export class MyTransactionsLabelResolver implements Resolve<any> {

  private getUiElementForMyTransactions: IGetUIElementsPayload;

  constructor(
    private localizationService: LocalizationService,
    private sessionStorageService: SessionStorageService) { }

  async resolve(route: ActivatedRouteSnapshot) {

    this.getUiElementForMyTransactions = {
      sponsorEnglishName: this.sessionStorageService.get('SponsorName'),
      PageNames: ['MyTransactions'],
      productId: this.sessionStorageService.get('ProductId'),
      applicationName: '',
      languageId: this.sessionStorageService.get('LanguageId')
    };
    
    const result = await this.localizationService.getUIElements(this.getUiElementForMyTransactions).toPromise();
    const renewPolicyButtonLabel = result.find(item => item.uiElementCode === 'RenewPolicyButton')?.uiElementValue;
    return renewPolicyButtonLabel;
  }
}
