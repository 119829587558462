<ng-container>
  <div class="linkexpired-page" id="linkexpiredPage">
    <div class="linkexpired-page-container" id="linkexpiredPageContainer">
      <section class="page-title" id="pageTitle">
        <h1 class="primary-header" id="h1PrimaryHeader" [class.desktop_title_size]="isDesktop">
          <mat-icon class="material-icons-outlined iconAlign"></mat-icon>
          {{errorHeaderLabelText}}
        </h1>
      </section>
      <section class="linkexpired-page-message tertiary-header" id="tertiaryHeader">
        <mat-icon id="errorIcon" class="linkexpired-page-icon" tabindex="5">error</mat-icon>
        <p *ngIf="!hasMultipleMessages()" id="pErrorMessage">{{errorMessageLabelText}}</p>
        <div *ngIf="hasMultipleMessages()">
          <p *ngFor="let message of errorMessageLabelText">{{message}}</p>
        </div>
      </section>
      <section class="linkexpired-page-secondary-message" *ngIf="errorSeconadryMessageLabelText">
        <p *ngIf="!hasMultipleSecondaryMessages" id="pSeconadryErrorMessage">{{errorSeconadryMessageLabelText}}</p>
        <div *ngIf="hasMultipleSecondaryMessages">
          <p *ngFor="let message of errorSeconadryMessageLabelText">{{message}}</p>
        </div>
      </section>
      <section class="flex-container">
        <div class="flex-item">
          <lib-primary-button id="btnBack" [text]="backButtonText" (clicked)="goBack()"></lib-primary-button>
        </div>
      </section>
    </div>
  </div>
</ng-container>
<lib-progress-spinner *ngIf="isLoading"></lib-progress-spinner>