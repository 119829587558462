<script [src]="scriptUrl"></script>

<div [formGroup]="form" class="captcha-padding-bottom mt-0 input-answer-container no-gutters">
    <div class="col-12 d-flex checkbox-input-style">
        <span class="p-4">
            <mat-checkbox formControlName="{{field.key}}" (change)="recaptchaVerified()" id="recaptchaCheckboxId" class="mat-checkbox-input-style" [checked]="captchaVerified" [disabled]="captchaVerified" [class.muted-input]="captchaVerified">
                <span class="d-flex align-items-center">{{field.label}}</span></mat-checkbox>
        </span>
        <span class="ml-auto d-flex align-items-center p-2">
            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/RecaptchaLogo.svg/1200px-RecaptchaLogo.svg.png" alt="Recaptcha" height="65">
        </span>                    
    </div>    
</div>

