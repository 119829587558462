import { Injectable } from "@angular/core";
import { CheckboxField } from "../../../../form-field/checkbox-field";
import { IFieldMapperStrategy } from "./field-mapper-strategy";
import { FormFieldMapper } from "./field-mapper-base";
import { Question } from "../../../../models";

@Injectable({
    providedIn: 'root'
})
export class CheckboxFieldMapper extends FormFieldMapper<CheckboxField> implements IFieldMapperStrategy {

    public order = 0;

    public canMap(question: Question): boolean {
        return question.qQuestionFormat.toLowerCase() === 'checkbox';
    }

    protected mapSpecificProperties(field: CheckboxField, question: Question): void {
        field.type = question.subType && question.subType.toLowerCase();       
        const previousAnswer = question.answerSets.filter(ans => ans.isAnswered).pop();
        field.checked = previousAnswer ? previousAnswer.isAnswered ? (previousAnswer.name.toLowerCase() === 'true' ? true: false) 
        : question.odefault? question.odefault :false :false ;
        field.isActive = field.checked
        field.value = field.checked.toString();
        field.descriptionLabel ="";
        field.name = question.qDescription;
    }

    protected getInstance() {
        return new CheckboxField();
    }
}
