<div class="mb-4 mx-3">    
    <form *ngIf="isReady" class="mt-3 px-md-4 pl-0 mx-md-4" [formGroup]="consentPreferencesForm" id="consentPreferencesForm">
        <div class="row mb-5">
            <div class="col-12">
                {{consentPreferences.pageInfo.subheadingText}}
            </div>
            <div class="col-12 mt-4">
                <h3>
                    <U>{{consentPreferences.policyHeading}}</U>
                </h3>
            </div>
            <div class="col-12 mt-2">
                {{consentPreferences.policyDescription}}
            </div>
        </div>
        <ng-container *ngFor="let consentObj of consentPreferences.consentList">
            <div class="row mt-2">
                <div class="col-12 text-break">
                    <b>{{consentObj.consentHeading}}</b>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 d-flex align-items-center">
                    <lib-radio-group [field]="consentObj.consent" [form]="consentPreferencesForm" class="w-100">
                    </lib-radio-group>
                </div>
            </div>
            <ng-container *ngIf="consentObj.consentLegalText">
                <div class="row mb-4">
                    <div class="col-12 font-weight-bold">
                        <a href="javascript:void(0)"
                            (click)="consentObj.isContentCollapsed = !consentObj.isContentCollapsed">{{consentObj.isContentCollapsed
                            ? consentObj.seeLegalText : consentObj.hideLegalText}}</a>
                    </div>
                    <div class="col-12 mt-2 text-break" [hidden]="consentObj.isContentCollapsed">
                        {{consentObj.consentLegalText}}
                    </div>
                </div>
            </ng-container>
        </ng-container>
        <div class="row d-flex justify-content-center button-align-edit mt-2" id="edit" *ngIf="!consentPreferences.isEditMode">
            <div class="col-12 col-md-5 my-2" id="divEditButtonContainer">
                <lib-primary-button id="btnEdit" [text]="consentPreferences.pageInfo.btnEditText"
                (clicked)="onClickEvent('edit')">
                </lib-primary-button>
            </div>
        </div>
        <div class="row d-flex justify-content-center button-align mt-2" id="saveCancel" *ngIf="consentPreferences.isEditMode">
            <div class="col-6 col-md-5 my-2" id="divSaveButtonContainer">
                <lib-primary-button id="btnSave" [text]="consentPreferences.pageInfo.btnSaveText"
                (clicked)="onClickEvent('save')"></lib-primary-button>
            </div>
            <div class="col-6 col-md-5 my-2" id="divCancelButtonContainer">
                    <lib-primary-button id="btnCancel" [text]="consentPreferences.pageInfo.btnCancelText"
                    (clicked)="onClickEvent('cancel')">
                    </lib-primary-button>
            </div>
        </div>
    </form>
</div>