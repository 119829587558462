import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import { LayoutService } from '../../../services/layout/layout.service';
import { IBottomsheetData } from '../../models';

@Component({
  selector: 'lib-bottomsheet',
  templateUrl: './bottomsheet.component.html',
  styleUrls: ['./bottomsheet.component.scss']
})
export class BottomsheetComponent implements OnInit {
  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;
  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomsheetComponent>,
    private layoutService: LayoutService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: IBottomsheetData) {}

  closeBottomsheet(): void {
      this.bottomSheetRef.dismiss();
    }

  ngOnInit() {
      // ----- Checking screen resolution by angular layout/breakpoints
      this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
        this.isMobile = this.layoutService.isSmallView();
        this.isTablet = this.layoutService.isMediumView();
        this.isDesktop = this.layoutService.isLargeView();
      });
    }

}
