<div *ngIf="isDesktop">
  <button *ngIf="Categories && Categories.length > 0" mat-menu-item class="active menu-top" (click)="menuTrigger.openMenu()"
    #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="clickmenu">
    <div *ngIf="!isLanguage">
    <mat-icon>account_circle</mat-icon>
    <span>{{hiUserTextLabel}},&nbsp;{{loggedInUserName}}
      <mat-icon>keyboard_arrow_down</mat-icon></span>
    </div>
    <div *ngIf="isLanguage"><mat-icon>language</mat-icon>{{selectedOption?.name}}
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </button>
  <mat-menu #clickmenu="matMenu" class="menu-lOne-level" xPosition="before">
    <ng-container *ngIf="!isLanguage">
    <button mat-menu-item class="submenu-item" *ngFor="let category of Categories"  (click)="onItemSelected(category.items)" url="category.items.url">
      <mat-icon class="material-icons">{{category.items?.menuIcon}}</mat-icon> <span>{{category.items.description}}</span>
      <button mat-menu-item class="submenu-item sub-items" *ngFor="let subCategory of category.subItems"
        (click)="onItemSelected(subCategory)" [value]="subCategory.id" url="subCategory.url">
        <mat-icon class="material-icons">{{subCategory.icon}}</mat-icon>
        <span>{{subCategory.name}}</span>
      </button>
    </button>
    </ng-container>
    <ng-container *ngIf="isLanguage">
    <button mat-menu-item class="submenu-item" *ngFor="let category of Categories" [class.active]="category.id == selectedOption?.id"
      (click)="onSelectionChange(category)" [value]="category.id">{{category.name}}</button>
    </ng-container>
  </mat-menu>
</div>

<!--For Mobile view-->
<div *ngIf="isMobile || isTablet">
  <mat-accordion class="example-headers-align">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title class="d-flex align-items-center">
          <mat-icon>account_circle</mat-icon>
          {{hiUserTextLabel}},&nbsp;{{loggedInUserName}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <button mat-menu-item class="submenu-item" *ngFor="let category of Categories" (click)="onItemSelected(category.items)" url="category.items.url">
          <mat-icon class="material-icons">{{category.items?.menuIcon}}</mat-icon><span>{{category.items.description}}</span>
        <button mat-menu-item class="submenu-item sub-items" *ngFor="let subCategory of category.subItems"
          (click)="onItemSelected(subCategory)" [value]="subCategory.id" url="subCategory.url">
          <mat-icon>{{subCategory.icon}}</mat-icon><span>{{subCategory.name}}</span>
        </button>
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</div>