<div class="session-expiry" id="divSessionExpiry">
  <div class="session-expiry-container" id="divSessionExpiryContainer">

    <section class="page-title">
      <h1 id="h1PrimaryHeader" [ngClass]="{'desktop-header-size': isDesktop,
            'tablet-header-size': isTablet,
            'mobile-header-size': isMobile}">
        {{sessionTimeoutHeaderText}}
      </h1>
    </section>

    <section class="session-expiry-message" id="expiryMessageSection">
      <mat-icon id="expiryIcon" class="session-expiry-icon" tabindex="5">check_circle</mat-icon>
      <p id="sessionTimeoutMessageText">{{sessionTimeoutMessageText}}</p>
    </section>
    <section *ngIf="!isB2BFlow" class="flex-container">
      <div class="flex-item">
        <lib-primary-button id="btnReturnToHomePage" [text]="returnToHomePageText" (clicked)="navigateToHomePage()"></lib-primary-button>
      </div>
    </section>   
  </div>
</div>
