import { Injectable } from '@angular/core';
import { FormFieldMapper } from './field-mapper-base';
import { IFieldMapperStrategy } from './field-mapper-strategy';
import { Question } from '../../../../models';
import { DatePickerField } from '../../../../form-field/date-picker-field';

const policyInceptionDateIntentCode = 'PolicyInceptionDate';
const policyExpiryDateIntentCode = 'PolicyExpiryDate';

@Injectable({
  providedIn: 'root'
})
export class DatePickerFieldMapper extends FormFieldMapper<DatePickerField> implements IFieldMapperStrategy {

  public order = 0;

  public canMap(question: Question): boolean {
    return question.qQuestionFormat.toLowerCase() === 'datepicker';
  }

  protected mapSpecificProperties(field: DatePickerField, question: Question): void {
    let policyTerm = 0;

    if (question.qIntentCode.toLowerCase() === policyExpiryDateIntentCode.toLowerCase()) {
      policyTerm = Number(sessionStorage.getItem('policyTerm'));
      if (policyTerm && Number(policyTerm) > 0) {
        field.isFixedTerms = true;
      }
      const isUnderMasterPolicy = sessionStorage.getItem('isUnderMasterPolicy');
      if (isUnderMasterPolicy && isUnderMasterPolicy === 'true') {
        field.readOnly = true;
        field.isUnderMasterPolicy = true;
      }
    }
    field.minDate = question.minDate;
    field.maxDate = question.maxDate;

    if (field.isFixedTerms) {
      field.default = `${policyTerm},months`;
      field.offsetBasis = policyInceptionDateIntentCode;
      const isExpiryDateAsInceptionDate = sessionStorage.getItem('isExpiryDateasInceptionDate');
      field.isExpiryDateAsInceptionDate = isExpiryDateAsInceptionDate === 'true'
        ? true : isExpiryDateAsInceptionDate === 'false'
          ? false : null;
      field.readOnly = true;
    } else {
      field.default = question.default;
      field.offsetBasis = question.offsetBasis;
    }

    const previousAnswer = question.answerSets.filter(ans => ans.isAnswered).pop();
    field.value = previousAnswer && previousAnswer.name;
    const newOrEditQuoteAllowed = JSON.parse(sessionStorage.getItem('newOrEditQuoteAllowed'))?._value;
    const isB2BFlow = JSON.parse(sessionStorage.getItem('isb2b'))?._value;

    if ((question.qQuestionType.toLowerCase() === 'quote') && (newOrEditQuoteAllowed === false) && isB2BFlow === false) {
      field.readOnly = true;
    }

    const isRenewalQuote = JSON.parse(sessionStorage.getItem('IsRenewalQuote')) ?
      JSON.parse(sessionStorage.getItem('IsRenewalQuote'))._value : false;
    if (isRenewalQuote && question.qIntentCode.toLowerCase() === policyInceptionDateIntentCode.toLowerCase()) {
      field.readOnly = true;
    }
  }

  protected getInstance() {
    return new DatePickerField();
  }
}
