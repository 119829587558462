<div class="mb-5 mt-4 pb-2"
  [ngClass]="{'faq-container-mobile': isMobileView, 'faq-container-non-mobile': !isMobileView}"
  *ngIf="faqData?.length > 0">
    <div id="divFaqWrapper" class="faq-wrapper" *ngIf="faqSectionHeading">
        <div id="divFaqSectionHeading" [ngClass]="{'large-title': isDesktopView, 'medium-title': !isDesktopView}" class="brand-h2 text-center primary-header"
        [innerHtml]="faqSectionHeading | safeHtml"></div>
        <div id="divFaqSectionSubHeading" *ngIf="faqSectionSubHeading" class="text-center pb-2 secondary-header"
        [innerHtml]="faqSectionSubHeading | safeHtml"></div>
    </div>
    <div>
        <mat-accordion id="divFAQ" class="faq-item" multi='true'>
            <mat-expansion-panel *ngFor="let faq of faqData" tabindex="0" hideToggle="true" #ItemAccordion>
                <mat-expansion-panel-header id="expansionPanelHeader" [collapsedHeight]="'auto'" [expandedHeight]="'auto'"
                    class="expansion-header-item">
                    <div class="flex-grow-1">
                        <mat-panel-title class="faq-header tertiary-header" [innerHtml]="faq?.question.uiElementValue | safeHtml">
                        </mat-panel-title>
                    </div>
                    <div>
                        <mat-panel-description id="pnlDescription">
                            <mat-icon id="downArrow" *ngIf="!ItemAccordion.expanded">arrow_drop_down</mat-icon>
                            <mat-icon id="upArrow" *ngIf="ItemAccordion.expanded">arrow_drop_up</mat-icon>
                        </mat-panel-description>
                    </div>
                </mat-expansion-panel-header>
              <p id="description" class="tertiary-header" [innerHtml]="faq?.answer.uiElementValue | safeHtml"></p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>


