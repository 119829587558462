import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalAuthenticationService } from '../../services/common-auth/msalauthentication.service';
import { SessionStorageService } from 'angular-web-storage';
import { SharedSettingsService } from '../../services/settings/settings-base.service';
import { BaseAccountService } from './../../services/account/base-account.service';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { BaseConstantService } from '../../services/constant/base-constant.service';
import { endPoints } from '../../shared/core/app.config';
import { ErrorState } from '../models/error-state.model';
import { ICheckCustomerBlockResponse } from '../models/check-customer-block-response.model';
import { lastValueFrom } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard  {
  constructor(
    private router: Router,
    private msalService: MsalAuthenticationService,
    private sessionStorage: SessionStorageService,
    private settingsService: SharedSettingsService,
    private accountService: BaseAccountService,
    private sharedSettingsService: SharedSettingsService,
    private utilitiesService: UtilitiesService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const promise: Promise<boolean> = new Promise(
      (resolve: (res: boolean) => void, reject: (res: boolean) => void) => {
        this.msalService.authenticated()
          .then(async result => {
            if (result) {
              this.setATPLoginExpiryTime();

              if (this.msalService.isSessionTimedOut()) {
                this.router.navigate(['/sessiontimeout']);
              } else {
                // Check if customer already blocked from using claim portal.
                const checkCustomerBlockResponse = await this.isBlockedCustomer();
                if (checkCustomerBlockResponse && checkCustomerBlockResponse.isSuccess && checkCustomerBlockResponse.isBlocked) {
                  this.errorRedirect(checkCustomerBlockResponse.isBlocked);
                } else if (!checkCustomerBlockResponse || !checkCustomerBlockResponse.isSuccess) {
                  this.errorRedirect(false);
                } else {
                  resolve(true);
                }
              }
            } else {
              const enableVoucherBasedAccess = this.settingsService.enableVoucherBasedAccess;
              const enableSSOBasedAccess = this.settingsService.enableSSOBasedAccess;
              const enableGuestPurchase = this.settingsService.enableGuestPurchase;
              const enableAssently = this.settingsService.enableAssently;
              const extAuthNav = this.settingsService.extAuthNav;
              const enableICECommonAuth = this.settingsService.enableICECommonAuth;
              const allowCustomerIdentification = this.settingsService.allowCustomerIdentification;
             
              const isB2BFlow = this.settingsService.isB2B();

              if (enableVoucherBasedAccess || enableSSOBasedAccess
                || enableGuestPurchase || enableAssently || isB2BFlow || extAuthNav || allowCustomerIdentification) {
                const idTokenExpiryTime = localStorage.getItem('idTokenExpiryTime');

                const currentTime = new Date().getTime();
                const idToken = localStorage.getItem('msal.idtoken');
                // If 'idTokenExpiryTime' is greater then 'currentTime' and 'id_token' present in local storage then
                // return true otherwise redirect to login component.
                if (idTokenExpiryTime && Number(idTokenExpiryTime) > currentTime && idToken) {
                  resolve(true);
                } else {
                  this.loginRedirect(route, state);
                }
              } else {
                this.loginRedirect(route, state);
              }
              resolve(false);
            }
          }).catch(() => {
            this.loginRedirect(route, state);
            resolve(false);
          });          
        }
        );
        
    return promise;
  }

  setATPLoginExpiryTime() {
    if (this.settingsService.isATPLogin) {
      const ATPLoginExpiryTime = this.sharedSettingsService.ATPLoginExpiryTime;
      if (!ATPLoginExpiryTime) {
        const currentTime = new Date();
        this.sharedSettingsService.ATPLoginExpiryTime = (currentTime.setMinutes(currentTime.getMinutes()
          + this.settingsService.TimeOutDuration));
      }
    }
  }

  loginRedirect(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Set the Url if there are any redirect url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url, currentUrl: this.router.url } });
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(childRoute, state);
  }

  private async isBlockedCustomer(): Promise<ICheckCustomerBlockResponse> {
    const baseUrl = endPoints.baseUrl.toLowerCase();
    if (baseUrl.indexOf('claims') === -1) {
      return {
        isSuccess: true,
        isBlocked: false
      };
    } else {
      return await lastValueFrom(this.accountService.isCustomerBlocked());
    }
  }

  private errorRedirect(isBlockedCustomer: boolean) {
    localStorage.clear();
    this.router.navigate(['error-page'], { state: new ErrorState(null, null, null, null, isBlockedCustomer) });
  }
}
