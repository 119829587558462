import {Injectable} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {BreakpointsService, CustomBreakpointNames} from '../breakpoints/breakpoints.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  activeBreakpoints: string[] = [];

  constructor(private breakpointObserver: BreakpointObserver,
              private breakpointService: BreakpointsService) {
  }

  // --- check screen breakpoint when layout changes and form actibe break points string array
  subscribeToLayoutChanges(): Observable<string[]> {
    return this.breakpointObserver
      .observe(this.breakpointService.getBreakpoints())
      .pipe(map((observeResponse) => this.parseBreakpointsResponse(observeResponse.breakpoints)));
  }

  parseBreakpointsResponse(breakpoints): string[] {
    this.activeBreakpoints = [];

    Object.keys(breakpoints).map((key) => {
      if (breakpoints[key]) {
        this.activeBreakpoints.push(this.breakpointService.getBreakpointName(key));
      }
    });

    return this.activeBreakpoints;
  }

  // --- will return which breapoints is active or not
  isBreakpointActive(breakpointName) {
    return this.activeBreakpoints.find(breakpoint => breakpoint === breakpointName);
  }

  // --- will return all active break points
  getAllBreakpoints() {
    return this.activeBreakpoints;
  }

  // -- 760 Px Wide - MOBILE - Applicable to all mobile views for both portrtait, landscape(Except like iphone x, pixel 2 xl landscape)
  isSmallView() {
    return this.activeBreakpoints.every(breakpoint => breakpoint === CustomBreakpointNames.mobile);
  }

  // -- 760px - 1280px wide - TABLET - Applicable to all tablet/ipad views
  isMediumView() {
    return this.activeBreakpoints.every(breakpoint => breakpoint === CustomBreakpointNames.tablet);
  }

  // -- Over 1280Px wide - DESKTOP - Applicable to all desktip views
  isLargeView() {
    return this.activeBreakpoints.every(breakpoint => breakpoint === CustomBreakpointNames.desktop);
  }
}
