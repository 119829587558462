<div class="field-wrapper" id="fieldWrapper">
    <div id="divQuestionContainer"
        class="question-container d-flex justify-content-between align-items-center cursor-pointer" [ngClass]="{'answer-container-error': this.formControl.value && !this.formControl.valid, 
    'date-container-disabled':field.readOnly, 'datePicker-container-medium': !isSmallView}" (click)="picker.open()">
        <div class="d-flex">
            <div id="divIcon" class="icon-container d-flex justify-content-center align-items-center mx-3">
                <mat-icon id="iconEvent">event</mat-icon>
            </div>
            <mat-form-field id="matFormField" class="d-flex justify-content-center align-items-center disable-container"
                [formGroup]="form">
                <mat-label id="matLablel" [innerHTML]="field.label | safeHtml"></mat-label>
                <input id="dtInput" [min]="minDate" [max]="maxDate" class="align-self-center" matInput
                    [matDatepicker]="picker" formControlName="{{field.key}}" [id]="field.key"
                    (dateChange)="formateDate($event)" placeholder="{{field.label}}" [disabled]="field.readOnly">
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="btn-container" id="divBtnContainer">
            <button id="btnEdit" type="button" class="btn btn-sm"
                [disabled]="field.readOnly">{{field.callToActionLabel}}</button>
        </div>
    </div>
    <mat-error id="matError" class="date-mat-error"
        *ngIf="showErrorMessage()">{{field.mandatoryValidationMsg}}</mat-error>
</div>
