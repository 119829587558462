<div class="bottomsheet-container">
  <header class="header-icon-container">
    <lib-top-bar-with-icon-and-link [iconName]="'close'" (emitIconAction)="closeBottomsheet()"></lib-top-bar-with-icon-and-link>
  </header>
  <div class="main-section">
    <h1 id="h1PrimaryHeader" class="title screen-header primary-header" [innerHTML]="data.title | safeHtml"
        [ngClass]="{'desktop-header-size': isDesktop,
                      'tablet-header-size': isTablet,
                      'mobile-header-size': isMobile}"></h1>
  </div>
  <div class="body-section">
    <ng-container *ngIf="data.content">
    <p id="tertiaryHeader" class="text tertiary-header" [innerHTML]="data.content | safeHtml"></p>
    </ng-container>
  </div>

  <section class="sub-section">
    <div class="details">
      <lib-primary-button id="btnOK" [text]="data.buttonText" (clicked)="closeBottomsheet()" autofocus></lib-primary-button>
    </div>
  </section>
</div>
