import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { endPoints } from '../../shared/core/app.config';
import { Observable, ReplaySubject } from 'rxjs';
import { BaseProductTemplate } from '../../shared/models/product-template.model';
import { share } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class BaseProductTemplateService {
  private apiUrl = endPoints.apiUrl;

  private getMasterProductUrl = this.apiUrl + endPoints.endPointName.getMasterProduct;
  private getProductTemplateUrl = this.apiUrl + endPoints.endPointName.getProductTemplate;

  constructor(private commonHttpService: CommonHttpService) { }

  private productTemplateObservables: { [id: number]: Observable<BaseProductTemplate>; } = {};

  getMasterProduct(templateId: number) {
    return this.commonHttpService.httpGetServiceWithBearer(this.getMasterProductUrl + '/' + templateId);
  }

  getProductTemplate(templateId: number): Observable<BaseProductTemplate> {
    // Caching as queried in multiple places.
    if (!this.productTemplateObservables[templateId]) {
      const apiURL = this.getProductTemplateUrl;
      this.productTemplateObservables[templateId] = this.commonHttpService.httpGetServiceWithBearer(apiURL + '/' + templateId).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    }
    return this.productTemplateObservables[templateId];
  }

}
