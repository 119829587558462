import { Input, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
export class ButtonBaseDirective {
  @Input() text: string;
  @Input() detail: string = null;
  @Input() disabled = false;
  @Input() enableBorderRadius: boolean;
  @Input() isBlock = true;
  @Output() clicked = new EventEmitter<any>();
  @Input() isButtonVisible = true;
  @Input() tag: any = null;
  @Input() hasFocus = false;
  @Input() emitRawEvent = false;

  public onClick(event?: Event): void {
    this.clicked.emit(this.emitRawEvent ? event : this.tag);
  }

  public textOnly(): boolean {
    return this.text && !this.detail;
  }
}
