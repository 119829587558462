import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MsalAuthenticationService } from '../../../services/common-auth/msalauthentication.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../services/layout/layout.service';
import { SessionStorageService } from 'angular-web-storage';
import { IErrorState } from '../../../shared/models';
import { BaseConstantService } from '../../../services/constant/base-constant.service';
import { Meta } from '@angular/platform-browser';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';

const errorHeaderLabel = 'Shared.lblServiceError';
const errorMessageLabel = 'Shared.lblServiceErrorMessage';
const backButtonLabel = 'Shared.lblBack';
const logoutLabel = 'Shared.lblLogout';
const customerAccountLockedLabel = 'Shared.lblNewClaimCustomerAccountLocked';
const contactServicingTeamLabel = 'Shared.lblNewClaimCustomerAccountLockedContactServicingTeam';
const tryagainButtonLabel = 'Shared.lblTryAgain';

@Component({
  selector: 'lib-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})

export class ErrorPageComponent implements OnInit {

  @Input() fromHomePage: boolean;
  @Input() extValidationFailed: boolean;
  @Input() extValidationRetry: boolean;
  @Input() errorMessageLabelText = 'I\'m sorry, there has been a technical error';

  // If translate service is failed to retrieve the translated content,  hardcoded value will be used for the UI content.
  errorHeaderLabelText = 'Service Error';
  errorSeconadryMessageLabelText;
  backButtonText = 'Back';
  logoutText = 'Logout';
  isMobile: boolean;
  isDesktop: boolean;
  hideBackButton: boolean;
  state: IErrorState;
  isLoading: boolean;
  hasMultipleSecondaryMessages: boolean;
  isCustomerBlocked = false;
  hideLogOutButton: boolean = false;
  retryNavigation: boolean = false;
  retryButtonText = 'Try Again';

  hasMultipleMessages(): boolean {
    return Array.isArray(this.errorMessageLabelText);
  }

  constructor(
    private msalService: MsalAuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private sessionStorage: SessionStorageService,
    private baseConstantService: BaseConstantService,
    private metaTag: Meta,
    private baseSettingsService: SharedSettingsService
  ) { }

  async ngOnInit() {
    this.metaTag.updateTag(
      { name: 'robots', content: BaseConstantService.metaDataDenySearch }
    );

    this.getState();
    this.hideLogOutButton = this.state?.signInFailed || this.extValidationFailed || (this.fromHomePage && this.state?.isCustomerBlocked);
    this.hideBackButton = this.state?.signInFailed || this.extValidationFailed  || (this.fromHomePage && this.state?.isCustomerBlocked);
    this.retryNavigation = this.state?.signInFailed || (this.extValidationFailed && this.extValidationRetry && !this.state?.signInFailed);
   
    await this.showLogoutLink();
    this.applyTranslations();
    this.checkDeviceWidth();
  }

  private getState() {
    if (history && history.state) {
      this.state = history.state as IErrorState;
    }
  }

  private hasCustomMessage() {
    return this.state && (this.state.header || this.state.primaryMessage);
  }

  private applyTranslations() {
    this.translate.get([errorHeaderLabel, errorMessageLabel, backButtonLabel, logoutLabel,
      customerAccountLockedLabel, contactServicingTeamLabel, tryagainButtonLabel]).subscribe((res) => {
        if (this.hasCustomMessage()) {
          if (this.state.buttonLabel === null || !this.state.buttonLabel) {
            this.state.buttonLabel = res[backButtonLabel];
          }
          this.setErrorHeaderAndMessage(this.state.header, this.state.primaryMessage, this.state.secondaryMessage, this.state.buttonLabel);
        } else if (this.state?.isCustomerBlocked) {
          this.isCustomerBlocked = this.state.isCustomerBlocked;
          this.setErrorHeaderAndMessage(res[customerAccountLockedLabel], null, res[contactServicingTeamLabel], null);
        } else {
          this.setErrorHeaderAndMessage(res[errorHeaderLabel], res[errorMessageLabel], null, res[backButtonLabel]);
        }
        if (this.logoutText && this.logoutText !== '' && res[logoutLabel] !== logoutLabel) {
          this.logoutText = res[logoutLabel];
        }
        this.retryButtonText = this.state?.signInFailed ?  res[tryagainButtonLabel] : this.retryButtonText;
      });
  }

  private setErrorHeaderAndMessage(header, primaryMessage, secondaryMessage, buttonLabel) {
    this.errorHeaderLabelText = header && header !== errorHeaderLabel ? header : this.errorHeaderLabelText;
    this.errorMessageLabelText = (primaryMessage && primaryMessage !== errorMessageLabel && !this.fromHomePage)
      ? primaryMessage
      : this.errorMessageLabelText;
    this.errorSeconadryMessageLabelText = secondaryMessage;
    this.backButtonText = buttonLabel && buttonLabel !== backButtonLabel ? buttonLabel : this.backButtonText;
    this.hasMultipleSecondaryMessages = Array.isArray(secondaryMessage);
    if (secondaryMessage === 'customer') {
      this.hideLogOutButton = true;
      this.hideBackButton = true;
      this.errorSeconadryMessageLabelText = '';
    }
  }

  async showLogoutLink() {
    this.isLoading = true;
    this.logoutText = await this.baseConstantService.getLogOutText(this.logoutText);
    this.isLoading = false;
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  goBack() {
    this.router.navigate([this.sessionStorage.get('ErrorPagePreviousUrl')]);
  }

  logout() {
    this.msalService.logout();
  }

  navigationRetry() {
    if (this.state?.signInFailed && !this.extValidationFailed) {
      this.router.navigate(['/login']);
    } else if (this.extValidationFailed && !this.state?.signInFailed) {
      window.location.href = this.baseSettingsService.extValRetryUrl;
    }
  }
}
