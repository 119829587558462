
 <div [formGroup]="form" class="row checkbox-container">
    <div class="col-md-2 col-12 my-2">
        <mat-checkbox  [ngClass]="{'mat-form-field-readonly':field.readOnly}"
        id="{{field.key}}"              
        class="cursor-pointer"       
        [class.apply-border-item]="field.checked"
        formControlName="{{checkBoxFormControlName}}"
        (change)="onSelectionChange($event)"       
         ></mat-checkbox>       
    </div>
    <div class="col-md-9 col-12 my-2 description-label">
        <mat-label id="matLabel" [innerHTML]="field.name | safeHtml"></mat-label>        
    </div>     
    <mat-error *ngIf="!this.formControl.valid">{{mandatoryErrorMsg}}</mat-error>
</div>
