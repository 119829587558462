import { ModalContent, SeverityLevel } from '../../../../shared/models/modal-content';
import { ModalTemplateComponent } from '../../../../shared/components/modal/modal-template/modal-template.component';
import { Component, OnInit } from '@angular/core';
import { QuestionSetService } from '../../../../services/question-set/question-set.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from 'angular-web-storage';
import { BaseConstantService } from '../../../../services/constant/base-constant.service';
import { Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

const headingInstructionLabel = 'Shared.lblDeletePopupHeading';
const subHeadingInstructionLabel = 'Shared.lblDeletePopupSubHeading';
const deletePopupOkBtnLabel = 'Shared.lblDeletePopupOkBtn';
const deletePopupCancelBtnLabel = 'Shared.lblDeletePopupCancelBtn';
@Component({
    template: ''
})
export abstract class BaseRepeatingGroupDataCaptureComponent implements OnInit {
    headingInstructionText: string;
    subHeadingInstructionText: string;
    deletePopupOkBtnText: string;
    deletePopupCancelBtnText: string;
    public deleteRepeatingQues = new Subject<any>();
    // Observable string streams
    deleteRepeatingQuesObservable = this.deleteRepeatingQues.asObservable();
    constructor(public sessionStorage: SessionStorageService,
                public questionSetService: QuestionSetService,
                public translate: TranslateService,
                public dialog: MatDialog
    ) { }

    ngOnInit() { }
    public async applyLangTranslations(): Promise<boolean> {
        await this.translate.get([
            headingInstructionLabel, subHeadingInstructionLabel, deletePopupOkBtnLabel, deletePopupCancelBtnLabel
        ]).subscribe((res) => {
            this.headingInstructionText = res[headingInstructionLabel];
            this.subHeadingInstructionText = res[subHeadingInstructionLabel];
            this.deletePopupOkBtnText = res[deletePopupOkBtnLabel];
            this.deletePopupCancelBtnText = res[deletePopupCancelBtnLabel];
        });
        return true;
    }
    // To Delete repeating group Iteration
    public deleteDialog(qnToDelete, deleteFrom, deleteFromPreviousAnswer) {
        if (this.applyLangTranslations()) {
            const modalData: ModalContent = {
                headingText: '',
                additionalText: this.headingInstructionText,
                instructionText: this.subHeadingInstructionText,
                iconName: 'warning',
                severityLevel: SeverityLevel.Warning,
                buttons: [
                    {
                        buttonText: this.deletePopupOkBtnText,
                        tag: true
                    },
                    {
                        buttonText: this.deletePopupCancelBtnText,
                        tag: false,
                        isSecondary: true,
                        hasFocus: true
                    }
                ]
            };

            const dialogRef = this.dialog.open(ModalTemplateComponent, {
                autoFocus: false, // Required to allow selective focus of button applied above.
                data: modalData
            });

            dialogRef.afterClosed().subscribe((result: boolean) => {
                if (result) {
                    // Observable string streams
                    if (deleteFromPreviousAnswer === true) {
                        this.questionSetService.onDeleteRepeatingGroup(this.questionSetService.internalQuestionSet, qnToDelete, deleteFrom);
                    } else {
                        this.deleteRepeatingQues.next(
                            {
                                questionSet: this.questionSetService.internalQuestionSet, qnToDelete: qnToDelete,
                                deleteFrom: deleteFrom
                            }
                        );
                    }
                } else {
                    // Revert control value.
                }
            });
        }
    }

   }
