
export class InfoCard {
  intentCode: string;
  name: string;
  image: string;
  sideText: string;
  description: Array<InfoCardDescription>;
  coverageTermsLabel: string;
  coverageTerms: string;
  defaultCoverageTermText: string;
  coverageTermsList: string[];
  currencySymbol: string = '';
  currencySymbolAfterMoney: boolean = false;
  monthText: string = '';
  mandatoryOrPreSelectedCoverages?: string[];
  editPrimaryCoveargeTermOnSecondaryCoverage: boolean = false;
  hideCoverage?: boolean = false
}

export class InfoCardDescription {
  label: string;
  sublabel: string;
}

export class AccordionItem extends InfoCardDescription {
  expanderDesc: string;
  content: string;
  isSelected: boolean;
  grossPremium: number;
  netPremium: number;
  tax: number;
  coverageId?: number;
  isOptionalCover?: boolean;
  allowableCoverageTerms?: any[];
  defaultCoverageTerms?: string;
  isPrimary?: boolean;
  readOnly?: boolean;
  coverageTermValue?: string;
  intentCode?: string;
  primaryCoverTerm?: string;
  coverageTermWithPricing?: CoverageTermsWithPricing[];
  isCoveragePreselected?: boolean;
  isCoverageTermGreater?: boolean;
  groupId?: number;
  sequence?: number;
  isMandatory?: boolean;
  hasDefaultCoverageTerms?: boolean;
  excludedSecondaryCoverages?: any[];
  excludedFor?: any[];
  isExcluded?: boolean;
  groupHeading?: string;
  displaySequenceForSecondaryCoverage?: number;
  hideCoverage?: boolean;
}

export class CoverageTermsWithPricing {
  coverageTermValue: string;
  expanderDesc: string;
  isCoverageTermSelected: boolean;
  grossPremium: number;
  netPremium: number;
  tax: number;

}

export class CoverageGrouping {
  groupId: number;
  isMandatory?: boolean;
  hasPreSelect?: boolean;
  groupHeading: string
  accordianItems: AccordionItem[];

}

