<div class="field-wrapper" id="fieldWrapper" [formGroup]="form">
    <div (click)="fileInput.click()"
        class="upload-container d-flex justify-content-between align-items-center cursor-pointer"
        [ngClass]="{'fileupload-container-medium': !isSmallView}">
        <div id="divIcon" class="icon-container d-flex justify-content-center align-items-center mx-3">
            <mat-icon id="iconEvent">{{field.iconName}}</mat-icon>
            <div class="header-title">{{field.label}}</div>
            <input type="file" #fileInput id="uploadFile" formControlName="{{field.key}}"
                (change)="uploadFileEvt($event)" (click)="onFileUpload($event)" [required]="field.key" multiple="multiple" />
        </div>
    </div>
    <mat-error id="matError" class="file-mat-error">{{getSpecificErrorMessage()}}</mat-error>
    <mat-error class="file-mat-error" *ngIf="fileCount > this.maxFileCount">{{maxFileErrorMsg}}</mat-error>
</div>
