import { Injectable } from '@angular/core';
import { IQuoteHistory, QuotePricingData } from '../../shared/models';
import { CommonHttpService, UserProfile, QuestionSetService, SharedSettingsService, BaseConstantService } from 'wtw-ui-components';
import { endPoints } from '../../core/app.config';
import { SessionStorageService } from 'angular-web-storage';
import { AccountService } from '../account/account.service';
import { QuotationService } from '../quotation/quotation.service';
import { SalesSettingsService } from '../settings/settings.service';
import { mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { DocuSignEnvelopeDetails } from '../../shared/models/docusign.model';
import { PolicySearch } from '../../shared/models/policy-search.model';
import { Observable } from 'rxjs';

const urlUpdatePolicyQuestionsResponse = endPoints.apiUrl + endPoints.endPointName.updatePolicyQuestionsResponse;
const urlSaveResponseDataWithAudit = endPoints.apiUrl + endPoints.endPointName.saveResponseDataWithAudit;
const urlPolicyPurchase = endPoints.apiUrl + endPoints.endPointName.policyPurchase;
const urlGetPoliciesLimit = endPoints.apiUrl + endPoints.endPointName.getPoliciesLimitAsync;
const GetCustomerSearchResult = endPoints.apiUrl + endPoints.endPointName.getPolicySearchResult;
const urlStoreDocusignEnvelopeDetails = endPoints.apiUrl + endPoints.endPointName.storeDocusignEnvelopeDetails;
const getProductListByPolicyIdUrl = endPoints.apiUrl + endPoints.endPointName.getProductListByPolicyId;
const getProductListByPolicyAndUserIdUrl = endPoints.apiUrl + endPoints.endPointName.getProductListByPolicyAndUserId;
const getPolicyResponseDataUrl = endPoints.apiUrl + endPoints.endPointName.getPolicyResponseData;
const getPolicyActivationLogByPolicyIdUrl = endPoints.apiUrl + endPoints.endPointName.getPolicyActivationLog;

const renewalKeys: string[] = [
  "renewals",
  "IsRenewalQuote",
  "IsRenewalPolicy",
  "RenewalQuoteReferenceID",
  "RenewalQuoteOrderID",
  "RenewalPolicyID",
  "RenewalPolicyNumber",
  "RenewalProductId",
];

const keysToRemove: string[] = ['CreatedProductId', 'PolicyId', 'OrderId', 'PolicyNumber', 'productId', 'ExpiryDate',
  'quoteSummary', 'AnsweredQuestionSet', 'QuestionSetPosition', 'QuoteReferenceID', 'primaryQuestionData', 'InceptionDate',
  'IsAuthorizationFailure', 'IsPolicyActivationFailure', 'Is3D2SecureValidationFailed', 'IsSettlementFailure', 'ThreeD2Error',
  'primaryCover', 'optionalCover', 'ExistingCustomerDraftQuote', ...renewalKeys];

@Injectable({
  providedIn: 'root'
})
export class PolicyService {

  constructor(
    private commonHttpService: CommonHttpService,
    private sessionStorage: SessionStorageService,
    private questionSetService: QuestionSetService,
    private accountService: AccountService,
    private quotationService: QuotationService,
    private settingsService: SalesSettingsService,
    private baseSettingsService: SharedSettingsService) { }

  public updatePolicyQuestionResponseDetails(declarationAccepted: boolean) {
    const quoteReferenceID = this.sessionStorage.get('QuoteReferenceID') ? this.sessionStorage.get('QuoteReferenceID') : '';
    const isB2BFlow = this.baseSettingsService.isB2B();

    return forkJoin([this.accountService.getUserProfile(), this.quotationService.getQuotePricingData()]).pipe(
      mergeMap((results: [UserProfile, QuotePricingData]) => {
        const userDetails = results[0];
        const premium = results[1];
        const creationUser = isB2BFlow ? this.baseSettingsService.b2bEmail() : userDetails.email;

        const quoteHistory: IQuoteHistory = {
          quoteId: quoteReferenceID,
          inceptionDate: this.sessionStorage.get('InceptionDate'),
          expiryDate: this.sessionStorage.get('ExpiryDate'),
          policyExpiryDate: new Date().toDateString(),
          // answeredQuestionSets: this.questionSetService.answeredQuestions,
          answeredQuestionResponse: this.quotationService.getResponseDictionary(this.questionSetService.answeredQuestions),
          grossPremium: premium.grossPremium,
          quotedDate: new Date(),
          quoteAccepted: true,
          productTemplateId: this.sessionStorage.get('ProductId'),
          customerId: userDetails.customerId,
          createdBy: creationUser,
          sponsorId: this.sessionStorage.get('SponsorId'),
          policyId: this.sessionStorage.get('PolicyId'),
          policyNumber: this.sessionStorage.get('PolicyNumber'),
          declarationAccepted,
          sponsorEnglishName: this.sessionStorage.get('SponsorName'),
          timeZone: this.sessionStorage.get('timeZone')
        };

        return this.commonHttpService.httpPostServiceWithBearer(urlUpdatePolicyQuestionsResponse, quoteHistory);
      })
    );
  }

  public saveResponseDataWithAudit() {
    const quoteReferenceID = this.sessionStorage.get('QuoteReferenceID') ? this.sessionStorage.get('QuoteReferenceID') : '';
    const isB2BFlow = this.baseSettingsService.isB2B();

    return this.accountService.getUserProfile().pipe(
      mergeMap((userDetails: UserProfile) => {
        const creationUser = isB2BFlow ? this.baseSettingsService.b2bEmail() : userDetails.email;

        const responseDataRequestObj = {
          quoteId: quoteReferenceID,
          inceptionDate: this.sessionStorage.get('InceptionDate'),
          expiryDate: this.sessionStorage.get('ExpiryDate'),
          productTemplateId: this.sessionStorage.get('ProductId'),
          createdBy: creationUser,
          policyId: this.sessionStorage.get('PolicyId'),
          timeZone: this.sessionStorage.get('timeZone'),
          sponsorEnglishName: this.sessionStorage.get('SponsorName'),
          answeredQuestionResponse: this.quotationService.getResponseDictionary(this.questionSetService.answeredQuestions),
        };

        return this.commonHttpService.httpPostServiceWithBearer(urlSaveResponseDataWithAudit, responseDataRequestObj);
      })
    );
  }

  SearchPolicyResult(policySearch: PolicySearch) {
    return this.commonHttpService.httpPostServiceWithBearer(GetCustomerSearchResult, policySearch);
  }
  public policyPurchase(): any {
    const params = this.settingsService.queryParams;
    let voucherCode = '';
    if (params) {
      voucherCode = params['vcode'];
    }
    const quoteSummary = JSON.parse(this.sessionStorage.get('quoteSummary'));
    return this.accountService.getUserProfile().pipe(

      mergeMap((userProfile: UserProfile) => {
        const policyPurchaseRequest = {
          sponsorId: this.sessionStorage.get('SponsorId'),
          sponsorEnglishName: this.sessionStorage.get('SponsorName'),
          timeZone: this.sessionStorage.get('timeZone'),
          languageId: this.sessionStorage.get('LanguageId'),
          languageCode: this.sessionStorage.get('LanguageCode'),

          orderId: this.sessionStorage.get('OrderId'),
          quoteReferenceId: this.sessionStorage.get('QuoteReferenceID'),
          quoteOrigin: this.sessionStorage.get('IsRenewalQuote') ? BaseConstantService.QUOTE_ORIGIN.RENEWALS :
            BaseConstantService.QUOTE_ORIGIN.NEW_BUSINESS,
          productId: this.sessionStorage.get('CreatedProductId'),
          ProductTemplateId: this.sessionStorage.get('ProductId'),

          policyId: this.sessionStorage.get('PolicyId'),
          inceptionDate: this.sessionStorage.get('InceptionDate'),
          expiryDate: this.sessionStorage.get('ExpiryDate'),

          customerId: userProfile.customerId,
          emailID: userProfile.email,
          answeredQuestionResponse: this.quotationService.getResponseDictionary(this.questionSetService.answeredQuestions),
          questionSetsToFilter: this.quotationService.getQuestionSetsToFilter(),
          enableVoucherBasedAccess: this.settingsService.enableVoucherBasedAccess,
          voucherCode,
          iterationData: this.settingsService.externalValidationIterationData,
          selectedCoverageList: this.quotationService.getSelectedCoverageList(),
          isB2BFlow: this.baseSettingsService.isB2B(),
          b2bEmail: this.baseSettingsService.b2bEmail(),
          MultiCarrierQuoteSummary: quoteSummary?.multiCarrierQuoteSummary,
          isResumeQuote: this.sessionStorage.get('ExistingCustomerQuote'),
          policySection: this.quotationService.getPolicySection,
          CoverageNameAndCoverageTermSelectionJson: this.settingsService.coverageNameAndCoverageTermSelection ?? '',
          discountRate: this.settingsService.discountRate ? this.settingsService.discountRate : 0,
          sessionKey: this.settingsService.getUserSessionKey()
        };

        return this.commonHttpService.httpPostServiceWithBearer(urlPolicyPurchase, policyPurchaseRequest);
      })
    );
  }

  public getPoliciesLimit(customerid: number, productId: number) {
    const policyLimitRequest = {
      CustomerId: customerid,
      ProductTemplateId: productId
    };
    return this.commonHttpService.httpPostServiceWithBearer(urlGetPoliciesLimit, policyLimitRequest);
  }

  getProductListByPolicyId(sponsorID: number, policyId: number): Observable<any> {
    const apiUrl = `${getProductListByPolicyIdUrl}/${sponsorID}/${policyId}`;
    return this.commonHttpService.httpGetService(apiUrl);
  }

  getProductListByPolicyAndUserId(sponsorID: number, policyId: number, userId: string): Observable<any> {
    const apiUrl = `${getProductListByPolicyAndUserIdUrl}/${sponsorID}/${policyId}/${userId}`;
    return this.commonHttpService.httpGetService(apiUrl);
  }

  public storeDocuSignEnvelopeDetails(docuSignEnvelopeDetails: DocuSignEnvelopeDetails) {
    return this.commonHttpService.httpPostServiceWithBearer(urlStoreDocusignEnvelopeDetails, docuSignEnvelopeDetails);
  }

  public getPolicyResponseData(policyId: number): Observable<any> {
    const apiUrl = `${getPolicyResponseDataUrl}/${policyId}`;
    return this.commonHttpService.httpGetServiceWithBearer(apiUrl);
  }

  get KeysToRemove(): string[] {
    return keysToRemove;
  }

  public getPolicyActivationLog(policyId: number): Observable<any> {
    const apiUrl = `${getPolicyActivationLogByPolicyIdUrl}/${policyId}`;
    return this.commonHttpService.httpGetServiceWithBearer(apiUrl);
  }
}
