import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LayoutService } from 'projects/wtw-ui-components/src/lib/services/layout/layout.service';


@Component({
  selector: 'lib-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss']
})
export class RadioGroupComponent implements OnInit {

  @Input() field: any;
  @Input() form: FormGroup;
  @Output() cToAction = new EventEmitter();
  public isSmallView: boolean;
  public mandatoryErrorMessage: string;

  constructor(private layoutService: LayoutService) { }

  get formControl(): any {
    return this.form.controls[this.field.key];
  }

  // --- checking width of viewport and set the view flags on
  checkViewPortWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isSmallView = this.layoutService.isSmallView();
    });
  }

  ngOnInit() {
    if (this.field.label === undefined || this.field.label === null) {
      this.field.label = '';
    }
    if (Array.isArray(this.field.mandatoryValidationMsg)) {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg[0];
    } else {
      this.mandatoryErrorMessage = this.field.mandatoryValidationMsg;
    }
  }

  callToActionOnClick() {
    this.cToAction.emit();
  }

  getSpecificErrorMessage() {
    if (this.formControl.hasError('required')) {
      return this.mandatoryErrorMessage;
    }
  }

}
