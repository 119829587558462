import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import { AppMaterialModule } from './shared/app-material/app-material.module';
import { WtwUiComponentsComponent } from './wtw-ui-components.component';
import { PrimaryButtonComponent } from './shared/components/buttons/primary-button/primary-button.component';
import { NavigationLinkButtonComponent } from './shared/components/buttons/navigation-link-button/navigation-link-button.component';
import { SecondaryButtonComponent } from './shared/components/buttons/secondary-button/secondary-button.component';
import { SimpleButtonComponent } from './shared/components/buttons/simple-button/simple-button.component';
import { SimpleWideCardComponent } from './shared/components/buttons/simple-wide-card/simple-wide-card.component';
import { WideButtonComponent } from './shared/components/buttons/wide-button/wide-button.component';
import { CloseTopComponent } from './shared/components/controls/close-top/close-top.component';
import { IconButtonComponent } from './shared/components/controls/icon-button/icon-button.component';
import { DatePickerComponent } from './shared/components/inputs/date-picker/date-picker.component';
import { DropDownComponent } from './shared/components/inputs/drop-down/drop-down.component';
import { TextBoxComponent } from './shared/components/inputs/text-box/text-box.component';
import { TextAreaComponent } from './shared/components/inputs/text-area/text-area.component';
import { TopBarWithIconAndLinkComponent } from './shared/components/top-bar-with-icon-and-link/top-bar-with-icon-and-link.component';
import { BottomsheetComponent } from './shared/components/bottomsheet/bottomsheet.component';
import { NumbersOnlyDirective } from './shared/directives/numbers-only.directive';
import { AutofocusDirective } from './shared/directives/auto-focus.directive';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { StripHtmlTagsPipe } from './shared/pipes/strip-html-tags.pipe';
import { MomentOffsetDatePipe } from './shared/pipes/moment-offset-date-pipe';
import { HeadingSubHeadingComponent } from './shared/components/heading-sub-heading/heading-sub-heading.component';
import { CardButtonComponent } from './shared/components/buttons/card-button/card-button.component';
import { ProgressBarComponent } from './shared/components/progress/progress-bar/progress-bar.component';
import { CookieBannerComponent } from './shared/components/cookie-banner/cookie-banner.component';
import { TileWithImageComponent } from './shared/components/tile/tile-with-image/tile-with-image.component';
import { LinkButtonComponent } from './shared/components/buttons/link-button/link-button.component';
import { TileListComponent } from './shared/components/tile/tile-list/tile-list.component';
import { IconDropDownComponent } from './shared/components/controls/icon-drop-down/icon-drop-down.component';
import { InfoCardComponent } from './shared/components/controls/info-card/info-card.component';
import { ViewPdfComponent } from './shared/components/controls/view-pdf/view-pdf.component';
import { PdfViewerModule, PdfViewerComponent } from 'ng2-pdf-viewer';
import { ContentListComponent } from './shared/components/content-section/content-list/content-list.component';
import { ContentSectionComponent } from './shared/components/content-section/content-section/content-section.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { FaqComponent } from './shared/components/controls/faq/faq.component';
import { StraplineContentComponent } from './shared/components/strapline-content/strapline-content.component';
import { TopbarSwitcherComponent } from './shared/components/topbar-switcher/topbar-switcher.component';
import { TopbarDropdownComponent } from './shared/components/topbar-switcher/topbar-dropdown/topbar-dropdown.component';
import { LogoutComponent } from './shared/components/authentication/logout/logout.component';
import { LoginRegisterLogoutComponent } from './shared/components/login-register-logout/login-register-logout.component';
import { BaseConstantService } from '../lib/services/constant/base-constant.service';
import { DataCaptureDirective } from './shared/question-set/data-capture.directive';
import { PreviousComponent } from './shared/question-set/previous/previous.component';
import * as dataCaptureComponents from './shared/question-set/data-capture-components';
import { TranslateModule } from '@ngx-translate/core';
import { DataCaptureHeadingComponent } from './shared/question-set/data-capture-components';
import { ProgressBarStepsComponent } from './shared/components/progress/progress-bar-steps/progress-bar-steps.component';
import { ErrorPageComponent } from './shared/components/error-page/error-page.component';
import { BaseQuestionLogicComponent } from './shared/question-set/base-question-logic/base-question-logic.component';
import { TimePickerComponent } from './shared/components/inputs/time-picker/time-picker.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { FileUploadComponent } from './shared/components/inputs/file-upload/file-upload.component';
import { SessionTimeoutComponent } from './shared/components/authentication/session-expiry/session-timeout.component';
import { ModalTemplateComponent } from './shared/components/modal/modal-template/modal-template.component';
import { SideBarComponent } from './shared/components/side-bar/side-bar.component';
import { RecaptchaV2Component } from './shared/components/inputs/recaptcha-v2/recaptcha-v2.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { IsEllipsisActiveDirective } from './shared/directives/is-ellipsis-active.directive';
import { IconMenuComponent } from './shared/components/controls/icon-menu/icon-menu.component';
import { RadioGroupComponent } from './shared/components/inputs/radio-group/radio-group.component';
import { YourProfileComponent } from './shared/profile-details/your-profile/your-profile.component';
import * as profileComponents from './shared/profile-details/index';
import { YourPoliciesComponent } from './shared/your-policies/your-policies.component';
import { NotificationAlertComponent } from './shared/components/notification-alert/notification-alert.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AssentlyLoginComponent } from './shared/components/assently-login/assently-login/assently-login.component';
import { DocusignErrorPageComponent } from './shared/components/error-page/docusign-error-page/docusign-error-page.component';
import { LinkExpiredScreenComponent } from './shared/components/link-expired-screen/link-expired-screen.component';
import { SpinnerModalComponent } from './shared/components/spinner-modal/spinner-modal.component';
import { WideButtonWithAccordionComponent }
  from './shared/components/buttons/wide-button-with-accordion/wide-button-with-accordion.component';
import { LocalDateFormatPipe } from './shared/pipes/local-date-format-pipe';
import { CurrencyBoxComponent } from './shared/components/inputs/currency-box/currency-box.component';
import { CurrencyDataCaptureComponent } 
from './shared/question-set/data-capture-components/currency-data-capture/currency-data-capture.component';
import { ButtonToggleComponent } from './shared/components/buttons/button-toggle/button-toggle.component';
import { AutoSearchDropdownComponent } from 
'./shared/components/inputs/auto-search-dropdown/auto-search-dropdown.component';
import { AutoSuggestInputComponent } from
 './shared/components/inputs/auto-suggest-input/auto-suggest-input.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CheckBoxComponent } from './shared/components/inputs/check-box/check-box.component';
import { CurrencyFormatPipe } from './shared/pipes/currency-format.pipe';
import { ProgressSpinnerComponent } from './shared/components/progress-spinner/progress-spinner.component';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    WtwUiComponentsComponent,
    PrimaryButtonComponent,
    NavigationLinkButtonComponent,
    SecondaryButtonComponent,
    SimpleButtonComponent,
    SimpleWideCardComponent,
    WideButtonComponent,
    CloseTopComponent,
    IconButtonComponent,
    DatePickerComponent,
    DropDownComponent,
    RadioGroupComponent,
    TextBoxComponent,
    TextAreaComponent,
    TopBarWithIconAndLinkComponent,
    HeadingSubHeadingComponent,
    BottomsheetComponent,
    NumbersOnlyDirective,
    AutofocusDirective,
    SafeHtmlPipe,
    StripHtmlTagsPipe,
    MomentOffsetDatePipe,
    LocalDateFormatPipe,
    CardButtonComponent,
    ProgressBarComponent,
    CookieBannerComponent,
    TileWithImageComponent,
    LinkButtonComponent,
    TileListComponent,
    IconDropDownComponent,
    InfoCardComponent,
    ViewPdfComponent,
    ContentListComponent,
    ContentSectionComponent,
    ContactUsComponent,
    FooterComponent,
    FaqComponent,
    StraplineContentComponent,
    TopbarSwitcherComponent,
    TopbarDropdownComponent,
    LoginRegisterLogoutComponent,
    LogoutComponent,
    DataCaptureDirective,
    ...dataCaptureComponents.components,
    PreviousComponent,
    DataCaptureHeadingComponent,
    ProgressBarStepsComponent,
    TimePickerComponent,
    BaseQuestionLogicComponent,
    FileUploadComponent,
    ErrorPageComponent,
    SessionTimeoutComponent,
    ModalTemplateComponent,
    SpinnerModalComponent,
    SpinnerModalComponent,
    SideBarComponent,
    RecaptchaV2Component,
    IsEllipsisActiveDirective,
    IconMenuComponent,
    YourProfileComponent,
    ...profileComponents.components,
    YourPoliciesComponent,
    NotificationAlertComponent,
    HeaderComponent,
    AssentlyLoginComponent,
    DocusignErrorPageComponent,
    LinkExpiredScreenComponent,
    WideButtonWithAccordionComponent,
    CurrencyBoxComponent,
    CurrencyDataCaptureComponent,
    AutoSearchDropdownComponent,
    ButtonToggleComponent,
    CheckBoxComponent,
    CurrencyFormatPipe,
    ProgressSpinnerComponent,  
    AutoSuggestInputComponent 
  ],
  imports: [
    CommonModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule.withConfig({callSetDisabledState: 'whenDisabledForLegacyCode'}),
    AppMaterialModule,
    PdfViewerModule,
    TranslateModule,
    NgxMatTimepickerModule,
    NgxCaptchaModule,
    MatTooltipModule,
    NgbTypeaheadModule
  ],
  exports: [
    AppMaterialModule,
    WtwUiComponentsComponent,
    PrimaryButtonComponent,
    NavigationLinkButtonComponent,
    SecondaryButtonComponent,
    SimpleButtonComponent,
    SimpleWideCardComponent,
    WideButtonComponent,
    CloseTopComponent,
    IconButtonComponent,
    DatePickerComponent,
    DropDownComponent,
    RadioGroupComponent,
    TextBoxComponent,
    TextAreaComponent,
    TopBarWithIconAndLinkComponent,
    HeadingSubHeadingComponent,
    BottomsheetComponent,
    HeaderComponent,
    NumbersOnlyDirective,
    AutofocusDirective,
    SafeHtmlPipe,
    StripHtmlTagsPipe,
    MomentOffsetDatePipe,
    LocalDateFormatPipe,
    LayoutModule,
    CardButtonComponent,
    ProgressBarComponent,
    CookieBannerComponent,
    TileWithImageComponent,
    LinkButtonComponent,
    TileListComponent,
    IconDropDownComponent,
    InfoCardComponent,
    ViewPdfComponent,
    PdfViewerComponent,
    PdfViewerModule,
    ContentListComponent,
    ContentSectionComponent,
    ContactUsComponent,
    FooterComponent,
    FaqComponent,
    StraplineContentComponent,
    TopbarSwitcherComponent,
    TopbarDropdownComponent,
    LogoutComponent,
    DataCaptureDirective,
    ...dataCaptureComponents.components,
    PreviousComponent,
    DataCaptureHeadingComponent,
    ProgressBarStepsComponent,
    LoginRegisterLogoutComponent,
    TimePickerComponent,
    NgxMatTimepickerModule,
    BaseQuestionLogicComponent,
    FileUploadComponent,
    ErrorPageComponent,
    SessionTimeoutComponent,
    ModalTemplateComponent,
    SideBarComponent,
    RecaptchaV2Component,
    NgxCaptchaModule,
    IsEllipsisActiveDirective,
    IconMenuComponent,
    YourProfileComponent,
    ...profileComponents.components,
    YourPoliciesComponent,
    NotificationAlertComponent,
    AssentlyLoginComponent,
    DocusignErrorPageComponent,
    LinkExpiredScreenComponent,
    WideButtonWithAccordionComponent,
    CurrencyBoxComponent,
    AutoSearchDropdownComponent,
    ButtonToggleComponent,  
    MatTooltipModule,
    CheckBoxComponent,
    CurrencyFormatPipe,
    ProgressSpinnerComponent,
    AutoSuggestInputComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    BaseConstantService,
    PdfViewerComponent,
    NgxMatTimepickerModule,
    NgxCaptchaModule,
    CurrencyFormatPipe,
  ]
})
export class WtwUiComponentsModule { }
