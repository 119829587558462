import { FormFieldBase } from './form-field-base';

export class DatePickerField extends FormFieldBase<string> {
    controlType = 'datepicker';
    minDate: string;
    maxDate: string;
    default: string;
    offsetBasis: string;
    isExpiryDateAsInceptionDate: boolean;
    isFixedTerms: boolean;
    isUnderMasterPolicy: boolean;
    constructor(options: {
        value?: string,
        key?: string,
        label?: string,
        required?: boolean,
        order?: number,
        controlType?: string,
        type?: string,
        readOnly?: boolean,
        iconName?: string,
        mandatoryValidationMsg?: string | string[],
        callToActionLabel?: string,
        minDate?: string,
        maxDate?: string,
        default?: string,
        offsetBasis?: string,
        isExpiryDateAsInceptionDate?: boolean,
        isFixedTerms?: boolean;
        isUnderMasterPolicy?: boolean;
    } = {}) {
        super(options);
        this.minDate = options.minDate || '';
        this.maxDate = options.maxDate || '';
        this.default = options.default || '';
        this.offsetBasis = options.offsetBasis || '';
        this.isExpiryDateAsInceptionDate = options.isExpiryDateAsInceptionDate || true;
        this.isFixedTerms = options.isFixedTerms || false;
        this.isUnderMasterPolicy = options.isUnderMasterPolicy || false;
    }
}
