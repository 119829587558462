<div *ngIf="isDesktop" class="mr-2">
  <!-- <ng-container *ngIf="langCategories && langCategories.length > 1">
    <lib-icon-drop-down [Categories]="langCategories" (selectChange)="langChange($event)" [iconName]="globeIcon" [selectedOption]="defaultLang?.id"></lib-icon-drop-down> 
  </ng-container>  -->
  <ng-container *ngIf="langCategories && langCategories.length > 1  && (!url.includes('your-profile')&&!url.includes('your-policies')&&!url.includes('your-claim'))" >
    <lib-icon-menu [Categories]="langCategories" (selectChange)="onSelectionChange($event)" [isLanguage]="true" [iconName]="globeIcon" [selectedOption]="defaultLang"></lib-icon-menu> 
  </ng-container> 
</div>
 <div *ngIf="isMobile || isTablet">
  <button *ngIf="langCategories && langCategories.length > 1 && (!url.includes('your-profile')&&!url.includes('your-policies')&&!url.includes('your-claim'))" mat-menu-item class="active" (click)="menuTrigger.openMenu()" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="language">
    <mat-icon>language</mat-icon>{{selectedLanguage?.name}}
  </button>    
  <button mat-menu-item *ngIf="isUserLoggedIn" class="active"><mat-icon>lock_outline</mat-icon>{{logoutText}}</button>
</div>

<mat-menu #language="matMenu" class="menu-lOne-level">
  <div class="container-fluid">
  <div class="row submenu-heading">
    <div class="col-10">
      <span class="SubmenuLabel">{{selectedLanguage?.name}}</span>
    </div>
    <div class="d-flex flex-row-reverse h-100 col-2" *ngIf="isMobile || isTablet">
      <div class="align-self-center">
        <mat-icon (menuClosed)="mobileMenuClose()">close</mat-icon>
      </div>
    </div>
  </div>  
</div>    
    <button mat-menu-item class="submenu-item" *ngFor="let category of langCategories" [class.active]="category.id == defaultLang?.id"
      (click)="onSelectionChange(category)" [value]="category.id">{{category.name}}</button>
</mat-menu>

  <!-- User Profile menu -->  
<ng-container *ngIf="profileMenuItemsData && profileMenuItemsData.length > 0">
  <div [ngClass]="{'scroll-bar-menu':isMobile}" >
    <lib-icon-menu (click)="$event.stopPropagation();" [Categories]="profileMenuItemsData" (selectedItem)="changeItems($event)" [loggedInUserName]="loggedInUserName" [iconName]="globeIcon"
    [selectedOption]="profileMenuItemsData"></lib-icon-menu>
  </div>
</ng-container>





