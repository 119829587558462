import { Injectable } from '@angular/core';
import { CommonHttpService, UserProfile, IterationData, BaseDocumentService, BaseConstantService  } from 'wtw-ui-components';
import { endPoints } from '../../core/app.config';
import { AccountService } from '../account/account.service';
import { SessionStorageService } from 'angular-web-storage';
import { IESignatureRequest, ISubjectInfos } from '../../shared/models/e-signature-request.model';
import { IESignatureResponse } from '../../shared/models/e-signature-response.model';
import { IGetDeclarationResponse } from '../../shared/models';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, map, tap, share, isEmpty, switchMap } from 'rxjs/operators';
import { SalesSettingsService } from '../../services/settings/settings.service';
import { UploadDocuments } from '../../shared/models/upload-documents.model';
import { Guid } from 'guid-typescript';
import { ISaveQuoteTranslations } from '../../shared/models/ISaveQuoteTranslations';


const urlApplyESignatureToPolicyDocument = endPoints.apiUrl + endPoints.endPointName.applyESignatureToPolicyDocument;
const urlGetPolicyDocumentForESignature = endPoints.apiUrl + endPoints.endPointName.getPolicyDocumentForESignature;
const urlstoreQuotesTemporaryAttachments = endPoints.apiUrl + endPoints.endPointName.storeQuotesTemporaryAttachments;
const urlattachTemporaryDocumentsToQuote = endPoints.apiUrl + endPoints.endPointName.attachTemporaryDocumentsToQuote;
const mandateSearchString = 'MANDAT DE PRELEVEMENT SEPA';
const mandateDocName = 'MANDAT_QBP';
const subscriptionFormSearchString = 'QBP BULLETIN ADHESION';
const subscriptionFormDocName = 'BULLETIN_QBP';
const gdprSearchString = 'LA PROTECTION DES DONNÉES PERSONNELLES';
const gdprDocName = 'RGPD_QBP';
const termsOfServicePasdebolSearchString1 = 'CONDITION GÉNÉRALES';
const termsOfServicePasdebolSearchString2 = 'PAS DE BOL';
const termsOfServiceTranquillouSearchString1 = 'CONDITION GÉNÉRALES';
const termsOfServiceTranquillouSearchString2 = 'TRANQUILLOU';
const termsOfServiceDocName = 'CGU_QBP';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  uploadedFiles: any = [];
  iconName = {
    removeFile: 'delete',
    viewFile: 'visibility'
  };

  private isDocUploadPriorToSaveQuote: boolean = false;
  private SaveQuoteTranslationObj: ISaveQuoteTranslations = {
    savedQuoteText: '',
    saveQuoteButtonText: '',
    nextButtonText: '',
    okButtonText: ''
  };
  private cachedAttachments: Observable<any> = EMPTY;
  private newAttachmentAdded: boolean = false;

  constructor(
    private commonHttpService: CommonHttpService,
    private accountService: AccountService,
    private sessionStorageService: SessionStorageService,
    private salesSettingsService: SalesSettingsService,
    private documentService: BaseDocumentService
  ) { }

  applyESignatureToPolicyDocument(currentDocumentTemplateId: number, currentDocumentName: string): Observable<IESignatureResponse> {
    return this.accountService.getUserProfile().pipe(mergeMap((userProfileDetails: UserProfile) => {
      const subjectInfoObj: ISubjectInfos = {
        lastName: userProfileDetails.lastName,
        firstName: userProfileDetails.firstName,
        email: userProfileDetails.email,
        address: userProfileDetails.addresses[0].addressLine1,
        phone: userProfileDetails.phoneNumber,
        customerId: userProfileDetails.customerId,
        gender: null
      };
      let numContractForESign = '';
      let documentNameForESign = '';
      const externalData = this.sessionStorageService.get('ExternalData');
      if (externalData) {
        if (externalData['ExSubId'] && externalData['FormulaCode']) {
          numContractForESign = externalData['ExSubId'] + externalData['FormulaCode'];
          documentNameForESign = this.getDocumentNameForESign(currentDocumentName.toUpperCase());

        }
      }
      const eSignatureRequest: IESignatureRequest = {
        locale: this.sessionStorageService.get('LanguageCode'),
        subjectInfo: subjectInfoObj,
        productID: this.sessionStorageService.get('ProductId'),
        policyId: this.sessionStorageService.get('PolicyId'),
        sponsorId: this.sessionStorageService.get('SponsorId'),
        languageId: this.sessionStorageService.get('LanguageId'),
        documentTemplateId: currentDocumentTemplateId,
        numContract: numContractForESign,
        documentName: documentNameForESign,
        signaturePosition: null,
        consentGroup: null
      };
      return this.commonHttpService.httpPostServiceWithBearer(urlApplyESignatureToPolicyDocument, eSignatureRequest);
    }));

  }

  getPolicyDocumentForESignature(policyDeclaration: IGetDeclarationResponse): Observable<IGetDeclarationResponse> {
    return this.commonHttpService.httpPostServiceWithBearer(urlGetPolicyDocumentForESignature, policyDeclaration);
  }

  private getDocumentNameForESign(documentName: string) {
    if (documentName.indexOf(mandateSearchString) !== -1) {
      return mandateDocName;
    } else if (documentName.indexOf(subscriptionFormSearchString) !== -1) {
      return subscriptionFormDocName;
    } else if (documentName.indexOf(gdprSearchString) !== -1) {
      return gdprDocName;
    } else if (((documentName).indexOf(termsOfServicePasdebolSearchString1) !== -1)
                  && ((documentName).indexOf(termsOfServicePasdebolSearchString2) !== -1)) {
                    return termsOfServiceDocName;
    } else if (((documentName).indexOf(termsOfServiceTranquillouSearchString1) !== -1)
                  && ((documentName).indexOf(termsOfServiceTranquillouSearchString2) !== -1)) {
                    return termsOfServiceDocName;
    } else {
      return '';
    }
  }

  downloadExternalPolicyDocument(documentRequest: any)
  {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.downloadExternalPolicyDocument}`;
    return this.commonHttpService.httpPostServiceWithBearer(url, documentRequest);
  }
  getDocumentTemplateForPolicy(documentRequest: any)
  {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.getDocumentTemplateForPolicy}`;
    return this.commonHttpService.httpPostServiceWithBearer(url, documentRequest);
  }

  getChildProductByMasterId(quoteSummary)
  {
    const getChildProductByMasterIdURL = endPoints.endPointName.getChildProductByMasterIdURL;
    return this.commonHttpService.httpPostServiceWithBearer(getChildProductByMasterIdURL, quoteSummary);
  }

  downloadQuoteDocument(documentRequest: any) {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.downloadQuoteDocument}`;
    return this.commonHttpService.httpPostServiceWithBearer(url, documentRequest);
  }

  downloadMultiQuoteDocument(documentRequest: any) {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.downloadMultiQuoteDocument}`;
    return this.commonHttpService.httpPostServiceWithBearer(url, documentRequest);
  }
  downloadATPMultiQuoteDocument() {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.downloadATPMultiQuoteDocument}`;
    const email = this.sessionStorageService.get('Email') ? this.sessionStorageService.get('Email') :
      this.salesSettingsService.userDetailModel.email;

    const documentRequest = {
      quoteReferenceId: this.sessionStorageService.get('QuoteReferenceID'),
      userEmail: email,
      languageId: this.sessionStorageService.get('LanguageId'),
      sponsorId: this.sessionStorageService.get('SponsorId'),
    };
    return this.commonHttpService.httpPostServiceWithBearer(url, documentRequest).pipe(
      map((result: any) => {
        return result;
      }));
  }

  displayUploadDocumentList(fileAttachmentInfo: any) {
    this.uploadedFiles.push({
      attachmentTypeId: fileAttachmentInfo.attachmentTypeId,
      fileName: `${fileAttachmentInfo.attachDescription}.
            ${this.documentService.getFileExtension(fileAttachmentInfo.fileName).toLowerCase()}`,
      iconName: this.documentService.getUploadedDocIcon(fileAttachmentInfo.fileName).icon,
      titleLabel: `${fileAttachmentInfo.attachDescription} ${this.getOccurrence(fileAttachmentInfo.attachmentTypeId) + 1}`,
      subTitleLabel: `${'uploaded'} - ${this.documentService.getFileExtension(fileAttachmentInfo.fileName)}`,
      menuInfo: [{
        iconName: this.iconName.removeFile, menuLabel: 'remove', data: fileAttachmentInfo.attachmentReferenceId ?
          fileAttachmentInfo.attachmentReferenceId.value : fileAttachmentInfo.tempAttachId
      },
      {
        iconName: this.iconName.viewFile, menuLabel: 'view', data: fileAttachmentInfo.attachmentReferenceId ?
          fileAttachmentInfo.attachmentReferenceId.value : fileAttachmentInfo.tempAttachId
      }],
      attachmentId: fileAttachmentInfo.attachmentReferenceId ?
        fileAttachmentInfo.attachmentReferenceId.value : fileAttachmentInfo.tempAttachId
    });
    this.setData(this.uploadedFiles);

  }

  setData(uploadedFiles) {
    const uploadedFileList = uploadedFiles.map(uf => uf.fileName);
    const uploadDocuments: UploadDocuments = {
      attachmentTypeId: 1,
      fileUpload: uploadedFiles,
      uploadedFilesName: uploadedFileList.toString().split(',').join('<br />')
    };
    this.salesSettingsService.uploadDocuments = uploadDocuments;
    this.uploadedFiles = this.salesSettingsService.uploadDocuments.fileUpload;
  }

  getOccurrence(value) {
    let count = 0;
    this.uploadedFiles.forEach((v) => (v.attachmentTypeId === value && count++));
    return count;
  }

  storeQuotesTemporaryAttachments(attachmentInfo: any) {
    const formData: FormData = new FormData();
    formData.append('fileKey', attachmentInfo.file, attachmentInfo.file.name);
    formData.append('quoteReferenceId', attachmentInfo.quoteReferenceId ? attachmentInfo.quoteReferenceId : '')
    formData.append('AttachmentTypeId', attachmentInfo.attachmentTypeId);
    formData.append('AttachDescription', attachmentInfo.attachDescription);
    formData.append('attachmentReferenceId', attachmentInfo.attachmentReferenceId);
    formData.append('uniqueSessionId', attachmentInfo.uniqueSessionId);

    return this.commonHttpService.httpPostFile(urlstoreQuotesTemporaryAttachments, formData);
  }

  attachTemporaryDocumentsToQuote(uniqueSessionId: Guid) {
    const request = {
      sessionId: uniqueSessionId.toString(),
      quoteReferenceId: this.sessionStorageService.get('QuoteReferenceID'),
      createdBy: this.sessionStorageService.get('Email'),
      customerId: this.sessionStorageService.get('customerId'),
      sponsorId: this.sessionStorageService.get('SponsorId'),
      quoteOrigin: BaseConstantService.Key_QuoteOrigin

    };

    return this.commonHttpService.httpPostServiceWithBearer(urlattachTemporaryDocumentsToQuote, request);

  }

  deleteTempAttachNotLinkedToQuote() {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.deleteTemporaryAttchmentNotLinkedToQuote}`;
    const request = {
      quoteId: this.sessionStorageService.get('QuoteReferenceID'),
    }

    return this.commonHttpService.httpPostServiceWithBearer(url, request);

  }

  getTempAttachmentByQuoteId(): Observable<any> {
    const quoteId = this.sessionStorageService.get('QuoteReferenceID');
    const url = `${endPoints.apiUrl}${endPoints.endPointName.getTempAttachmentByQuoteReferenceId}/${quoteId}`;
 
    return this.cachedAttachments.pipe(
      isEmpty(),
      switchMap(isEmpty => {
        if (this.newAttachmentAdded || isEmpty) {
          return this.fetchAttachmentsFromApi(url);
        } else {
          return this.cachedAttachments;
        }
      })
    );
  }
 
  fetchAttachmentsFromApi(url: string): Observable<any> {
    return this.commonHttpService.httpGetServiceWithBearer(url)
      .pipe(
        tap(response => {
          this.cachedAttachments = of(response);
          this.updateAttachmentStatus(false);
        }),
        share()
      );
  }
 
  updateAttachmentStatus(flag: boolean): void {
    this.newAttachmentAdded = flag;
  }

 // adding this because we are not allowing 'tiff' format for now. Will be handled letter.
  getFileExtension(filename) {
    return filename.split('.').pop().toUpperCase();
  }

  getUploadedDocIcon(uploadedDocType) {
    const supportedFormats = [
      { icon: 'image', name: 'image', type: ['jpg', 'png', 'jpeg', 'bmp', 'tif'] },
      { icon: 'insert_drive_file', name: 'documents', type: ['doc', 'docx', 'xls', 'xlsx'] },
      { icon: 'picture_as_pdf', name: 'pdf', type: ['pdf'] },
      { icon: 'description', name: 'text-document', type: ['ppt', 'pptx', 'csv', 'txt'] },
      { icon: 'library_music', name: 'music', type: ['mp3'] }
    ];
    return supportedFormats.filter(sf => sf.type.includes(this.getFileExtension(uploadedDocType).toLowerCase()))[0];

  }

  resumeUploadDocuments(documentsInfo) {
    let itemsProcessed = 0;
    this.uploadedFiles = [];
    if (documentsInfo !== undefined && documentsInfo.length > 0) {
      this.salesSettingsService.setUniqueSessionId = documentsInfo[0]?.uniqueSessionID;
      documentsInfo.forEach((documentInfo: UploadDocuments) => {
        itemsProcessed++;
        this.displayUploadDocumentList(documentInfo);
        if (itemsProcessed === documentsInfo.length) {
          this.setData(this.uploadedFiles);
        }

      });
    } else {
      this.setData(this.uploadedFiles);
    }
  }

  setIsDocUploadPriorToSaveQuote(value: boolean): void {
    this.isDocUploadPriorToSaveQuote = value;
  }

  getIsDocUploadPriorToSaveQuote(): boolean {
    return this.isDocUploadPriorToSaveQuote;
  }
  setSaveQuoteTranslation(value: ISaveQuoteTranslations): void {
    this.SaveQuoteTranslationObj = value;
  }

  getSaveQuoteTranslation(): ISaveQuoteTranslations {
    return this.SaveQuoteTranslationObj;
  }

  updateQuoteIdTemporaryAttachments(uniqueSessionId: string, quoteReferenceID: string) {
    const url = `${endPoints.apiUrl}${endPoints.endPointName.updateQuoteIdTempAttachmentsForSession}`;
    const request = {
      quoteId: quoteReferenceID,
      uniqueSessionID: uniqueSessionId
    }

    return this.commonHttpService.httpPostServiceWithBearer(url, request);
  }

}
