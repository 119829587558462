import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HttpParams } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorModule } from './errors/error.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { endPoints } from './core/app.config';
import { APP_CONFIG, AppConfig } from './core/app.config';
import { ConfigService } from 'wtw-ui-components';
import { AppComponent } from './app.component';
import { Router } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LoginComponent } from './authentication/components/login/login.component';
import { SessionStorageService } from 'angular-web-storage';
import { Location } from '@angular/common';
import { ErrorHandlerService } from './errors/error-handler';
import { ConstantService } from '../app/services/constant/constant.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxPaginationModule } from 'ngx-pagination';
import { CurrencyFormatPipe } from '../../../wtw-ui-components/src/lib/shared/pipes/currency-format.pipe';
export function initializeApp(configService: ConfigService, config) {
  const promise = configService.loadServiceConfigData(config);
  return () => promise;
}

export function httpLoaderFactory(
  http: HttpClient,
  location: Location,
  sessionStorage: SessionStorageService,
  constantService: ConstantService) {
  const sponsorId = sessionStorage.get('SponsorId');
  if (sponsorId) {
    return new TranslateHttpLoader(http, `${endPoints.apiUrl}Localization/GetTranslations/${sponsorId}/Shared/`);
  }
  const sponsorName = sessionStorage.get('SponsorName') || getUrlParameterByName(ConstantService.SponsorNameQueryParamName) || 'unknown';
  return new TranslateHttpLoader(http,
    `${endPoints.apiUrl}Localization/GetTranslationsForNamedSponsor/${sponsorName}/Shared/`);
}

function getUrlParameterByName(paramName: string) {
  const stashedResult = JSON.parse(sessionStorage.getItem('QueryParams'));
  if (stashedResult) {
    const queryParams = JSON.parse(stashedResult._value);
    return queryParams[paramName];
  }
  const url = window.location.href;
  let paramValue;
  if (url.includes('?')) {
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });
    paramValue = httpParams.get(paramName);
  }
  return paramValue;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CurrencyFormatPipe
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    FontAwesomeModule,
    ErrorModule,
    AppRoutingModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient, Location, SessionStorageService, ConstantService]
      }
    })
  ],
  providers: [
    ConstantService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    { provide: APP_CONFIG, useValue: AppConfig },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp, deps: [ConfigService, APP_CONFIG], multi: true
    },
    { provide: 'googleTagManagerId', useValue: 'GTM-N2KZ5PP' },
    GoogleTagManagerService,
    CurrencyFormatPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(router: Router) {

  }
}
