import { Injectable } from '@angular/core';

import { BaseAccountService } from '../../../services/account/base-account.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class YourProfileResolver  {

  constructor(private accountService: BaseAccountService
    ) {}

  async resolve(route: ActivatedRouteSnapshot) {
    let isEncryptionRequired = route.data["encryptedValue"];

    // Validate and convert to boolean
    if (typeof isEncryptionRequired === 'string') {
      isEncryptionRequired = isEncryptionRequired.toLowerCase() === 'true';
    } else if (typeof isEncryptionRequired !== 'boolean') {
      isEncryptionRequired = false; // Default value if not a boolean or string
    }

    const yourProfileDetails = await lastValueFrom<any>(this.accountService.yourProfile(isEncryptionRequired));
    return yourProfileDetails;
  }
}
