import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { MsalAuthenticationService } from '../../../services/common-auth/msalauthentication.service';
import { BaseAccountService } from '../../../services/account/base-account.service';

@Injectable({
  providedIn: 'root'
})
export class AccountVerificationResolver  {
  constructor(
    private accountService: BaseAccountService,
    private msalAuthenticationService: MsalAuthenticationService,
  ) { }

  async resolve(route: ActivatedRouteSnapshot) {
    if (this.msalAuthenticationService.isAuthenticated()) {
      const isAccountVerified =  await lastValueFrom(this.accountService.isAccountVerified());
      return isAccountVerified;
    } else {
      return false;
    }
  }
}
