import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { MsalAuthenticationService } from 'projects/wtw-ui-components/src/lib/services/common-auth/msalauthentication.service';
import { CookieBannerService } from 'projects/wtw-ui-components/src/lib/services/cookie-banner/cookie-banner.service';
import { LayoutService } from 'projects/wtw-ui-components/src/lib/services/layout/layout.service';
import { BaseConstantService } from 'projects/wtw-ui-components/src/lib/services/constant/base-constant.service';
import { SharedSettingsService } from 'projects/wtw-ui-components/src/lib/services/settings/settings-base.service';
import { SessionStorageService } from 'angular-web-storage';



const sessionTimeoutHeaderLabel = 'Shared.lblSessionTimeout';
const sessionTimeoutMessageLabel = 'Shared.lblSessionTimeoutMessage';
const returnToHomePageLabel = 'Shared.lblReturnToHomepage';

@Component({
  selector: 'lib-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  sessionTimeoutHeaderText: string;
  sessionTimeoutMessageText: string;
  returnToHomePageText: string;
  isDesktop = false;
  isTablet = false;
  isMobile = false;
  isB2BFlow = false;
  private sponsorName: string;

  constructor(
    private msalService: MsalAuthenticationService,
    private router: Router,
    private translate: TranslateService,
    private cookieBannerService: CookieBannerService,
    private layoutService: LayoutService,
    private metaTag: Meta,
    private settingsService: SharedSettingsService,
    private sessionStorage: SessionStorageService
  ) { }

  ngOnInit() {
    this.metaTag.updateTag(
      { name: 'robots', content: BaseConstantService.metaDataDenySearch }
    );
    this.isB2BFlow = this.settingsService.isB2B();  
    if (this.isB2BFlow ) {
      // to disable browser back navigation
      window.history.pushState(null, null, null);
      window.onpopstate = function() {
        window.history.go(1);
      }
    } 
    this.applyTranslations();
    this.checkDeviceWidth();
    this.msalService.removeMSALItems();
    this.cookieBannerService.clearLocalStorage();
    this.cookieBannerService.clearSessionStorage();
    this.cookieBannerService.clearExternalIntegrationSessionStorage();
    this.sponsorName = this.settingsService.sponsorName;
    
  }

  private applyTranslations() {
    this.translate.get([sessionTimeoutHeaderLabel, sessionTimeoutMessageLabel, returnToHomePageLabel]).subscribe((res) => {
      this.sessionTimeoutHeaderText = res[sessionTimeoutHeaderLabel];
      this.sessionTimeoutMessageText = res[sessionTimeoutMessageLabel];
      this.returnToHomePageText = res[returnToHomePageLabel];
    });
  }

  navigateToHomePage() {
    if (this.sponsorName) {
      this.settingsService.sponsorName = this.sponsorName;
    }
    this.router.navigate(['**']);
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }
}


