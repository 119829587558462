import { CustomerConsent, DataShareConsent } from '../models';

export interface SaveUserConsents {
  customerConsents: CustomerConsent[];
  dataShareConsent: DataShareConsent;
  customerId: number;
  languageId: number;
  userName: string;
  saveType: string;
  disableMyAccountCreationB2C?: boolean;
}

export class SaveUserConsentsResponse {
  customerConsents: CustomerConsent[];
  dataShareConsent: DataShareConsent;
}
