import { Injectable } from '@angular/core';
import { CommonHttpService } from '../common-http/common-http.service';
import { endPoints } from '../../shared/core/app.config';
import { SessionStorageService } from 'angular-web-storage';
import { Observable, Subject } from 'rxjs';
import { SharedSettingsService } from '../../services/settings/settings-base.service';
import { BaseDocumentService } from '../../services/document/base-document.service';
import { PolicyRenewalDetail } from '../../shared/models/policyRenewalDetail.model';


const routes = {
  yourPoliciesURL: endPoints.apiUrl + endPoints.endPointName.yourPolicies,
  yourPolicyURL: endPoints.apiUrl + endPoints.endPointName.yourPolicy,
  renewPolicyURL: endPoints.apiUrl + endPoints.endPointName.renewPolicy,
  resumeQuoteURL: endPoints.apiUrl + endPoints.endPointName.resumeQuote,
  removeDraftClaimURL: endPoints.apiUrl + endPoints.endPointName.removeDraftClaims
};

@Injectable({
  providedIn: 'root'
})

export class PolicyService {
  viewPlociyDetailsObservable = new Subject<boolean>();
  constructor(protected commonHttpService: CommonHttpService,
              protected sessionStorageService: SessionStorageService,
              private settingsService: SharedSettingsService,
              private documentService: BaseDocumentService
  ) { }


  yourPolicies(customerId: string): any {
    const languageId = this.sessionStorageService.get('LanguageId');
    const policyUrl = `${routes.yourPoliciesURL}/${customerId}/${languageId}`;
    return this.commonHttpService.httpGetServiceWithBearer(policyUrl);
  }

  yourPolicy(policyId: string, productTemplateId: number): any {
    const inputData = {
      customerId: this.sessionStorageService.get('customerId'),
      productId: productTemplateId,
      policyId,
      sponsorId: this.sessionStorageService.get('SponsorId'),
      sponsorEnglishName: this.sessionStorageService.get('SponsorName'),
      languageId: this.sessionStorageService.get('LanguageId'),
      timeZone: this.sessionStorageService.get('timeZone')
    };
    const policyUrl = `${routes.yourPolicyURL}`;
    return this.commonHttpService.httpPostServiceWithBearer(policyUrl, inputData);
  }

  renewPolicy(policy: any): Promise<any> {
    const request = {
      customerId: this.sessionStorageService.get('customerId'),
      productTemplateId: policy.productTemplateId,
      policyId: policy.policyID,
      sponsorId: this.sessionStorageService.get('SponsorId'),
      sponsorEnglishName: this.sessionStorageService.get('SponsorName'),
      orderId: policy.renewalQuoteOrderID
    };

    let requestUrl = `${routes.renewPolicyURL}`;
    if (policy.quoteOrigin !== null && policy.quoteOrigin !== undefined && policy.quoteOrigin.toUpperCase() === 'RENEWALS') {
      requestUrl = `${routes.resumeQuoteURL}`;
    }
    return this.commonHttpService.httpPostServiceWithBearer(requestUrl, request).toPromise();
  }


  removeDraftClaimURL(): Observable<boolean> {
    const inputData = {
      customerId: this.sessionStorageService.get('customerId'),
      sponsorId: this.sessionStorageService.get('SponsorId')
    };
    const policyUrl = `${routes.removeDraftClaimURL}`;
    return this.commonHttpService.httpPostServiceWithBearer(policyUrl, inputData);
  }

  viewPolicyDetail(policy) {
    this.viewPlociyDetailsObservable.next(policy);
  }

  downloadPolicyDocument(documentInfo) {
    const fileName = (documentInfo.documentTypeName + '.' + documentInfo.fileExtension).trim();
    const docRequest = {
      documentId: documentInfo.documentID,
      policyId: documentInfo.policyID,
      customerId: this.sessionStorageService.get('customerId'),
      sponsorID: this.settingsService.sponsorId,
      languageID: this.settingsService.languageId,
      context: 'Policy'
    };
    this.documentService.downloadDocument(docRequest).subscribe(
      res => {
        this.documentService.downloadFile(res, fileName);
      });
  }

  downloadPolicyAttachment(documentInfo) {
    const fileName = documentInfo.fileName.split(".");
    const fileNameWithExtension = (fileName[0] + '.' + documentInfo.fileExtension).trim();
    const docRequest = {
      attachmentId: documentInfo.documentID,
      policyId: documentInfo.policyID,
      customerId: this.sessionStorageService.get('customerId'),
      sponsorID: this.settingsService.sponsorId,
      languageID: this.settingsService.languageId,
      context: 'Policy'
    };
    this.documentService.getFile(docRequest).subscribe(
      res => {
        this.documentService.downloadFile(res, fileNameWithExtension);
      });
  }

  findFileTypeIcon(policyUploads) {
        policyUploads.find(p => {
            const supportedIcon = this.documentService.getUploadedDocIcon(p.fileExtension);
            p['fileIcon'] = supportedIcon.icon;
        });
        return policyUploads;
    }

  savePolicyRenewalDetailsToSession(detail: PolicyRenewalDetail) {
    detail.isb2b = false;
    detail.IsRenewalQuote = true;
    detail.IsRenewalPolicy = true;
    Object.keys(detail).forEach(key => {
      this.sessionStorageService.set(key, detail[key]);
    });
    this.getProductTemplate(detail.ProductId).subscribe(data => {
      detail.cultureName = data.cultureName;
      detail.currencyIso4217 = data.currencyIso4217;
      this.sessionStorageService.set('cultureName', data.cultureName);
      this.sessionStorageService.set('currencyIso4217', data.currencyIso4217);
    })
  }

  getProductTemplate(productTemplateId: any): Observable<any> {
    const ProductTemplateUrl = endPoints.apiUrl + endPoints.endPointName.getProductTemplate
    return this.commonHttpService.httpGetServiceWithBearer(ProductTemplateUrl + '/' + productTemplateId)
  }

}
