import { Injectable } from '@angular/core';
import { SessionStorageService } from 'angular-web-storage';
import { Observable, ReplaySubject, lastValueFrom, of } from 'rxjs';
import { finalize, publishReplay, refCount, share, shareReplay, tap } from 'rxjs/operators';
import { endPoints } from '../../shared/core/app.config';
import {
  AddressDetails, ICustomerFieldValidations, SaveUserConsents,
  SaveUserConsentsResponse, UserProfile, ICustomerPolicySearchResult, IUserHistoryLog
} from '../../shared/models';
import { BaseAccountService } from '../account/base-account.service';
import { CommonHttpService } from '../common-http/common-http.service';
import { SharedSettingsService } from '../settings/settings-base.service';
import { SponsorService } from '../sponsor/sponsor.service';
import { CountryCodeLookupData, LookupData } from '../../shared/models/user-profile.model';
import { BaseConstantService } from '../../services/constant/base-constant.service';


@Injectable({
  providedIn: 'root'
})
export class BaseCustomerService {

  private getCustomerFieldValidationsUrl = endPoints.apiUrl + endPoints.endPointName.getCustomerFieldValidations;
  private saveB2CCustomerPersonalDetailsUrl = endPoints.apiUrl + endPoints.endPointName.saveB2CCustomerPersonalDetails;
  private saveCustomerAddressUrl = endPoints.apiUrl + endPoints.endPointName.saveCustomerAddress;
  private getCustomerPreferredLanguageUrl = endPoints.apiUrl + endPoints.endPointName.getCustomerPreferredLanguage;
  private getCustomerUrl = endPoints.apiUrl + endPoints.endPointName.getCustomer;
  private getCustomerIdentifierUrl = endPoints.apiUrl + endPoints.endPointName.getCustIdentifier;
  private getCountryCodeLookupDataUrl = endPoints.apiUrl + endPoints.endPointName.getCountryCodeLookupData;
  private isValidCustomerIdentifierURL = endPoints.apiUrl + endPoints.endPointName.isValidCustomerIdentifier;
  private identifyCustomersPolicyUrl = endPoints.apiUrl + endPoints.endPointName.identifyCustomersPolicy;
  private saveUserHistoryLogUrl = endPoints.apiUrl + endPoints.endPointName.saveUserHistoryLog;
  private getUserHistoryByIpAddressUrl = endPoints.apiUrl + endPoints.endPointName.getUserHistoryByIpAddress;
  lookupDataObservable: Observable<LookupData[]>;
  private GetCustomerTypes = endPoints.apiUrl + endPoints.endPointName.getCustomerType;
  countryCodeLookupDataObservable: Observable<CountryCodeLookupData[]>;
  private customerFieldValidationCache: any;
  customerTypeCacheKey: any;

  constructor(
    protected commonHttpService: CommonHttpService,
    protected sessionStorageService: SessionStorageService,
    protected sharedSettingsService: SharedSettingsService,
    protected sponsorService: SponsorService,
    protected accountService: BaseAccountService,
    protected constantService: BaseConstantService
  ) { }

  setIsBackwardsFlaginSessionStorage() {
    // Check if 'enableSSOBasedAccess' is set or not if yes, check for
    // 'externalValidationIterationData.index' if that is > 0 then
    // set 'isBackwards' to true for skipping user details and address page.
    if (this.sharedSettingsService.enableSSOBasedAccess) {
      const externalValidationIterationData = this.sharedSettingsService.externalValidationIterationData;
      const isRepeatJourney = externalValidationIterationData
        && externalValidationIterationData.index
        && externalValidationIterationData.index > 0;
      if (isRepeatJourney) {
        this.sessionStorageService.set('isBackwards', true);
      }
    }
  }

  getCustomerFieldValidations(sponsorid: number): Observable<ICustomerFieldValidations[]> {
    const productCustomerType = this.getProductCustomerType();

    if (this.customerFieldValidationCache && this.customerTypeCacheKey === productCustomerType) {
      return this.customerFieldValidationCache;
    }

    const apiURL = this.getCustomerFieldValidationsUrl;

    this.customerFieldValidationCache = this.commonHttpService.httpGetService(`${apiURL}/${sponsorid}/${productCustomerType}`).pipe(
      tap(res => this.customerTypeCacheKey = productCustomerType),
      shareReplay(1)
    );

    return this.customerFieldValidationCache;
  }

  saveB2CCutomerPersonalDetails(yourProfileModel: any): any {
    return this.commonHttpService.httpPostServiceWithBearer(this.saveB2CCustomerPersonalDetailsUrl, yourProfileModel);
  }

  saveCustomerAddress(customerAddress: any): any {
    return this.commonHttpService.httpPostServiceWithBearer(this.saveCustomerAddressUrl, customerAddress);
  }

  buildCustomerAddressList(addressList: any): AddressDetails[] {

    const addressListResult: AddressDetails[] = [];

    addressList.forEach((value) => {

      const address = new AddressDetails();

      address.addressId = value.addressId;
      address.addressLine1 = value.addressLine1;
      address.town = value.city;
      address.postalCode = value.zipCode;
      address.country = value.countryName;
      address.countryId = value.countryId;
      address.defaultAddress = value.isDefault;

      addressListResult.push(address);
    });

    return addressListResult;
  }

  getCustomerPreferredLanguage(customerId: number): Promise<any> {
    const getCustomerPreferredLanguageUrl = `${this.getCustomerPreferredLanguageUrl}/${customerId}`;
    return lastValueFrom<any>(this.commonHttpService.httpGetServiceWithBearer(getCustomerPreferredLanguageUrl));
  }

  setCustomerPreferredLanguage(languageId: number, languageCode: string) {
    this.sharedSettingsService.languageId = languageId;
    this.sharedSettingsService.languageCode = languageCode;
    this.sponsorService.browserLangFlag = false;
    // Publish the language change to any subscribers.
    this.sponsorService.languageObservable.next(languageId);
  }

  async saveUserConsent(saveUserConsent: SaveUserConsents): Promise<SaveUserConsentsResponse> {
    const saveUserConsentResponse = await lastValueFrom(this.accountService.saveB2CUserConsent(saveUserConsent));
    return saveUserConsentResponse;
  }

  getCustomer(): Observable<UserProfile> {
    const data = {
      sponsorId: this.sessionStorageService.get('SponsorId'),
      customerId: this.sessionStorageService.get('customerId')
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.getCustomerUrl, data);
  }

  getCustomerDetailsByIdentifier(): Observable<UserProfile> {
    const data = {
      sponsorId: this.sessionStorageService.get('SponsorId'),
      customerIdentifier: this.sessionStorageService.get('customerIdentifier')
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.getCustomerIdentifierUrl, data);
  }

  GetCustomerType() {
    const languageId = this.sessionStorageService.get('LanguageId');
    const apiURL = this.GetCustomerTypes + '/' + languageId;
    return this.commonHttpService.httpGetServiceWithBearer(apiURL);
  }

  getCountryCodeLookupData(contextcode: string, sponsorid: number, languagecode: string) {
    const apiURL = this.getCountryCodeLookupDataUrl;
    this.countryCodeLookupDataObservable = this.commonHttpService.
      httpGetServiceWithBearer(`${apiURL}/${contextcode}/${sponsorid}/${languagecode}`).pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: true,
        }))
    this.countryCodeLookupDataObservable.subscribe(result => {
      this.sessionStorageService.set('AreaCodeList', result);
    });
    return this.countryCodeLookupDataObservable;
  }

  isValidCustomerIdentifier(customerIdentifier: string): Observable<boolean> {
    const data = {
      sponsorId: this.sessionStorageService.get('SponsorId'),
      customerIdentifier: customerIdentifier
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.isValidCustomerIdentifierURL, data);
  }

  identifyCustomersPolicy(mobilePhoneNumber: string, externalRefNumber: string,
                          sponsorId: number): Observable<ICustomerPolicySearchResult[]> {

    const customerIdentificationCriteria = {
      SponsorId: sponsorId,
      MobilePhoneNumber: mobilePhoneNumber,
      ExternalRefNumber: externalRefNumber
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.identifyCustomersPolicyUrl, customerIdentificationCriteria);
   
  }

  getUserHistoryByIpAddress(ipAddress: string, sponsorId: number): Observable<IUserHistoryLog[]> {
    const getUserHistoryUrl = this.getUserHistoryByIpAddressUrl + '/' + ipAddress + '/' + sponsorId;
    return this.commonHttpService.httpGetServiceWithBearer(getUserHistoryUrl);
  } 

  private getProductCustomerType(): string {
    let productCustomerType = this.sessionStorageService.get('ProductCustomerType');

    if (this.sessionStorageService.get('isCattributePresentForCustomerType') === true) {
      productCustomerType = this.sessionStorageService.get(BaseConstantService.CattributeCustomerType);
    } else if (this.sessionStorageService.get(BaseConstantService.CattributeCustomerType)) {
      productCustomerType = this.sessionStorageService.get(BaseConstantService.CattributeCustomerType);
    }

    return productCustomerType || this.sharedSettingsService.customerTypePerson;
  }


  saveUserHistoryLog(UserHistoryLog: IUserHistoryLog): Observable<any> {
    const sponsorId: number = this.sessionStorageService.get('SponsorId');
    const requestBody: IUserHistoryLog = {
      logID: 0,
      userID: UserHistoryLog.userID,
      logDate: UserHistoryLog.logDate,
      sponsorID: sponsorId,
      userHostIP: UserHistoryLog.userHostIP,
      applicationName: UserHistoryLog.applicationName,
      description: UserHistoryLog.description,
      unsuccessfulAccessAttempt: UserHistoryLog.unsuccessfulAccessAttempt
    };
    return this.commonHttpService.httpPostServiceWithBearer(this.saveUserHistoryLogUrl, requestBody);
  }
}
