import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionSetService } from '../../../services/question-set/question-set.service';
import { AnswerSets, Question } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../services/layout/layout.service';
import { SharedSettingsService } from '../../../services/settings/settings-base.service';
import { DateManipulationService } from '../../../services/date-manipulation/date-manipulation.service';
import { SessionStorageService } from 'angular-web-storage';
import moment from 'moment'
import { BaseConstantService } from '../../../services/constant/base-constant.service';


const editLabel = 'Shared.lblEdit';
const policyExpiryDateIntentCode = 'policyexpirydate';
const deleteLabel = 'Shared.lblDeleteRepeatingGroup';
const grossPremiumLabel = 'Shared.lblGrossPremiumText';
@Component({
  selector: 'lib-previous',
  templateUrl: './previous.component.html',
  styleUrls: ['./previous.component.scss']
})
export class PreviousComponent implements OnInit {

  @Output() clicked = new EventEmitter<Question>();
  @Output() deleteClicked = new EventEmitter<Question>();
  @Input() additionalLabel: string;
  @Input() additionalLabelText: string;
  @Input() questionType: string;
  @Input() showHeader = true;
  @Input() isContainer = true;
  @Input() isApproveDeclineOnly = false;
  isNotGrouped = true;
  answers: Question[];
  arrayLength: any;
  transformedPreviousAns: Question[];
  editText: string;
  deleteText: string;
  grossPremiumText: string;
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  label = '';
  isCompressedViewOfPreviousAnswers: boolean;
  displayGrossPremium: boolean;
  childQuestions = [];
  childAnswers = [];
  sponsorTimeZoneOffset = '';
  repeatingGroupLengthKey: string;
  panelState = 'panelState';
  previousAnswerLastQues: string;
  grossPremiumValue: string;
  isApproveDeclineWithPremiumOverride: boolean = false;

  constructor(
    private questionSetService: QuestionSetService,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private settingsService: SharedSettingsService,
    private dateManipulationService: DateManipulationService,
    private sessionStorage: SessionStorageService,
    public baseConstantService: BaseConstantService
  ) { }

  ngOnInit() {
    this.isCompressedViewOfPreviousAnswers = this.settingsService.compressedViewOfPreviousAnswers;
    this.displayGrossPremium = this.settingsService.displayGrossPremium;
    this.repeatingGroupLengthKey = BaseConstantService.Key_RepeatingGroupLength;
    this.applyTranslations();
    this.checkDeviceWidth();
    const previousAnswerLastSessionObj = this.sessionStorage.get('PreviousAnswerLastQuestion');
    const grossPremiumLastSessionObj = this.sessionStorage.get('GrossPremiumPreviousAnswer');
    this.questionSetService?.GetPreviousAnswerLastQuestion.subscribe((result: string) => {
      if (result && result !== '') {
        this.previousAnswerLastQues = result;
      }
    });
    this.questionSetService.GetGrossPremiumPreviousAnswer.subscribe((result: any) => {
      if (result && result !== '') {
        this.grossPremiumValue = result;
      }
    });
    if ((this.previousAnswerLastQues === undefined || this.previousAnswerLastQues === '' || this.previousAnswerLastQues === null)
      && previousAnswerLastSessionObj) {
      this.previousAnswerLastQues = previousAnswerLastSessionObj
    }
    if ((this.grossPremiumValue === undefined || this.grossPremiumValue === '' || this.grossPremiumValue === null)
      && grossPremiumLastSessionObj) {
      this.grossPremiumValue = grossPremiumLastSessionObj
    }

    this.questionSetService.referralPermissionSet.subscribe((referralPermission) => {
      if (referralPermission) {
        const retrievedPermissions = this.questionSetService.getReferralPermission();
        this.isApproveDeclineWithPremiumOverride = retrievedPermissions.isApproveDeclineWithPremiumOverride;
      }
    });
  }

  onClick(question: Question): void {
    this.clicked.emit(question);
  }

  onDeleteClick(question: Question): void {
    this.deleteClicked.emit(question);
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  get previousAnswers() {
    let isFixedTerm = false;
    let policyTerm = 0;
    policyTerm = Number(sessionStorage.getItem('policyTerm'));
    if (policyTerm && Number(policyTerm) > 0) {
      isFixedTerm = true;
    }
    const timeZoneOffset = this.sessionStorage.get('timeZoneOffset');
    this.sponsorTimeZoneOffset = timeZoneOffset && timeZoneOffset.toLowerCase().replace('utc', '');
    const currentDate = new Date();
    if (this.questionSetService.answeredQuestions != null) {
      const currentUrl = window.location.pathname;
      const isRenewal = this.sessionStorage.get('IsRenewalQuote') || false;
      const isSlowRevealEnabled = this.questionSetService.EnableSlowReveal;
      const filteredAnsweredQuestions = this.questionSetService.answeredQuestions
        .filter(q => (!this.questionType || q.qQuestionType.toLowerCase() === this.questionType.toLowerCase()) && !q.hideHistory)
        .filter(q => !isSlowRevealEnabled || (isSlowRevealEnabled && q.visited)); // Further filter for renewal condition

      filteredAnsweredQuestions.map(value => {
        if (this.previousAnswerLastQues) {
          if (value?.qIntentCode === this.previousAnswerLastQues) {
            value[this.panelState] = true;
          }
          else {
            value[this.panelState] = false;
          }
        }
      });
      // If current url is register(user details) page and
      // one or more policy questions has been answered then hide edit button
      // on policy questions page.
      // commenting below code for bug 176839
      const productNewOreditQuoteAllowed = JSON.parse(sessionStorage.getItem('newOrEditQuoteAllowed'))?._value;
      const isB2BFlow = this.settingsService.isB2B();
      // neworEditQuoteAllowed is default to true,If it is false edit button should not be visible in only B2C flow
      if (this.questionType.toLowerCase() === 'quote') {
        for (const index in filteredAnsweredQuestions) {
          let maxdate;
          let policyInceptionDate
          if (filteredAnsweredQuestions[index].qIntentCode.toLowerCase() === 'policyinceptiondate' &&
            (filteredAnsweredQuestions[index].isHidden || productNewOreditQuoteAllowed === false) &&
            filteredAnsweredQuestions[index].qQuestionFormat.toLowerCase() === 'datepicker') {
            policyInceptionDate = filteredAnsweredQuestions[index].answerSets[0].name;
            if (filteredAnsweredQuestions[index].maxDate !== '' && filteredAnsweredQuestions[index].maxDate !== undefined &&
              filteredAnsweredQuestions[index].maxDate !== null) {
              maxdate = this.dateManipulationService.applyDateOffset(filteredAnsweredQuestions[index].maxDate, this.getAnchorDate(),
                this.sponsorTimeZoneOffset);
            }
            if (filteredAnsweredQuestions[index].default !== '' && filteredAnsweredQuestions[index].default !== undefined &&
              filteredAnsweredQuestions[index].default !== null) {
              const defaultDate =
                filteredAnsweredQuestions[index].default?.toLowerCase() === 'mindate' ? filteredAnsweredQuestions[index].minDate
                  : filteredAnsweredQuestions[index].default?.toLowerCase() === 'maxdate' ? filteredAnsweredQuestions[index].maxDate
                    : filteredAnsweredQuestions[index].default;
              const defaultInceptionDate = this.dateManipulationService.applyDateOffset(defaultDate, this.getAnchorDate(),
                this.sponsorTimeZoneOffset);

              if (policyInceptionDate === undefined || policyInceptionDate < defaultInceptionDate.format('YYYY-MM-DD')
                || policyInceptionDate < maxdate?.format('YYYY-MM-DD')) {
                filteredAnsweredQuestions[index].answerSets[0].name = defaultInceptionDate.format('YYYY-MM-DD');
              }
            }
            else if (filteredAnsweredQuestions[index].minDate !== '' && filteredAnsweredQuestions[index].minDate !== undefined &&
              (filteredAnsweredQuestions[index].default === '' ||
                filteredAnsweredQuestions[index].default === null || filteredAnsweredQuestions[index].default === undefined)) {
              const inceptionDate = this.dateManipulationService.applyDateOffset(filteredAnsweredQuestions[index].minDate,
                this.getAnchorDate(), this.sponsorTimeZoneOffset)
              if (policyInceptionDate === undefined || policyInceptionDate < inceptionDate.format('YYYY-MM-DD')
                || policyInceptionDate < maxdate?.format('YYYY-MM-DD')) {
                filteredAnsweredQuestions[index].answerSets[0].name = inceptionDate.format('YYYY-MM-DD');
              }
            }
            else if (filteredAnsweredQuestions[index].childQuestionSets.length > 0) {
              filteredAnsweredQuestions[index].childQuestionSets?.forEach((child) => {
                let childInceptionDate;
                if (child.qIntentCode.toLowerCase() === "policyinceptiondate" && child.qQuestionFormat.toLowerCase() === 'datepicker'
                  && child.isHidden) {
                  if (child.maxDate !== '' && child.maxDate !== undefined && child.maxDate !== null) {
                    maxdate = this.dateManipulationService.applyDateOffset(child.maxDate, this.getAnchorDate(),
                      this.sponsorTimeZoneOffset);
                  }
                  policyInceptionDate = child.answerSets[0].name;
                  if (child.default !== '' && child.default !== undefined && child.default !== null) {
                    const childDefaultDate =
                      child.default?.toLowerCase() === 'mindate' ? child.minDate
                        : child.default?.toLowerCase() === 'maxdate' ? child.maxDate
                          : child.default;
                    childInceptionDate = this.dateManipulationService.applyDateOffset(childDefaultDate, this.getAnchorDate(),
                      this.sponsorTimeZoneOffset)
                    if (policyInceptionDate === undefined || policyInceptionDate < childInceptionDate.format('YYYY-MM-DD')
                      || policyInceptionDate < maxdate?.format('YYYY-MM-DD')) {
                      child.answerSets[0].name = childInceptionDate.format('YYYY-MM-DD');
                    }
                  }
                  else if (child.minDate !== '' && child.minDate !== undefined && child.minDate != null && child.default === '' &&
                    (child.default === null || child.default === undefined || child.default === '')) {
                    childInceptionDate = this.dateManipulationService.applyDateOffset(child.minDate, this.getAnchorDate(),
                      this.sponsorTimeZoneOffset)
                    if (policyInceptionDate === undefined || policyInceptionDate < childInceptionDate.format('YYYY-MM-DD')
                      || policyInceptionDate < maxdate?.format('YYYY-MM-DD')) {
                      child.answerSets[0].name = childInceptionDate.format('YYYY-MM-DD');
                    }
                  }
                }
              })
            }
            else {
              filteredAnsweredQuestions[index].answerSets[0].name = moment(currentDate, "DD/MM/YYYY").format('YYYY-MM-DD');
            }
          }
          if (filteredAnsweredQuestions[index].qIntentCode.toLowerCase() === policyExpiryDateIntentCode
            && (isFixedTerm || filteredAnsweredQuestions[index].isHidden) &&
            filteredAnsweredQuestions[index].qQuestionFormat.toLowerCase() === 'datepicker') {
            this.refressPolicyExpiryDate(filteredAnsweredQuestions, index);
          }
          else if (filteredAnsweredQuestions[index].childQuestionSets.length > 0) {
            filteredAnsweredQuestions[index].childQuestionSets?.forEach((qChild, qChildIndex) => {
              if ((qChild.qIntentCode.toLowerCase() === policyExpiryDateIntentCode && qChild.qQuestionFormat.toLowerCase() === 'datepicker')
                && qChild.isHidden) {
                this.refressPolicyExpiryDate(filteredAnsweredQuestions[index].childQuestionSets, qChildIndex);
              }
            });
          }
          if (productNewOreditQuoteAllowed && !isB2BFlow === true) {
            filteredAnsweredQuestions[index].isHideEditButton = false;
          } else if (isB2BFlow === true) {
            filteredAnsweredQuestions[index].isHideEditButton = false;
          } else if (productNewOreditQuoteAllowed === false && isB2BFlow === false) {
            filteredAnsweredQuestions[index].isHideEditButton = true;
          }
        }
      }

      // Check if the current flow is Single Carrier Single Quote Resume Flow
      const isSingleCarrierSingleQuoteResumeFlow = this.questionSetService.isSingleCarrierSingleQuoteResumeFlow.value;
      if (isSingleCarrierSingleQuoteResumeFlow) {
        const policyQuestions = filteredAnsweredQuestions.filter(question => question.qQuestionType.toLowerCase() === 'policy');

        // For each policy-related question
        for (const question of policyQuestions) {
          // Set the visibility of the edit button based on whether the quote is referred or not
          question.isHideEditButton = this.settingsService.hidePolicyQuestionsEdit;
        }
      }

      if (this.settingsService.compressedViewOfPreviousAnswers && filteredAnsweredQuestions
        && filteredAnsweredQuestions?.length > 0 && filteredAnsweredQuestions !== null) {
        filteredAnsweredQuestions?.filter(value => {
          if (value.hasOwnProperty('groupKey')) {
            this.isNotGrouped = false;
          }
        });
        // call this method to compress the previous answer
        return this.getCompressedAnswer(filteredAnsweredQuestions);

      } else {
        return filteredAnsweredQuestions;
      }
    } else {
      const emptyQuoteQuestion: Question[] = [];
      return emptyQuoteQuestion;
    }
  }

  getChildAnswers(parentQuestion: Question) {
    this.childQuestions = [];
    this.childAnswers = [];
    for (const childQuestionSet of parentQuestion.childQuestionSets) {
      const childAnswer = childQuestionSet.answerSets.filter(as => as.isAnswered).pop();
      if (childAnswer) {
        if ((childQuestionSet.qDescription.toLowerCase() === 'startdate' || childQuestionSet.qDescription.toLowerCase() === 'enddate')
          && (childAnswer.name.length > 10)) {
          const objDate = this.settingsService.setLocalisedDateFormat(childAnswer.name);
          if (objDate && objDate !== 'Invalid date' && objDate !== undefined) {
            childAnswer.name = objDate;
          }
        }
        if (childQuestionSet?.hideHistory !== true) {
            if (childQuestionSet.qQuestionFormat?.toLowerCase() === 'checkbox' && childAnswer.placeholderText) {
                this.childAnswers.push(childAnswer.placeholderText);
            }
            else {
                this.childAnswers.push(childAnswer.name);
            }
            this.childQuestions.push(childQuestionSet.qDescription);
        }
        if (childAnswer.isAnswered === true && childQuestionSet?.answerSets?.length > 0) {
          this.getGrandChildAnswers(childQuestionSet)
        }
      }


    }
    return [this.childQuestions, this.childAnswers];
  }

  refressPolicyExpiryDate(filteredAnsweredQuestions, index) {
    let endDate;
    const startDateIndex = filteredAnsweredQuestions.findIndex(item => item.qIntentCode.toLowerCase()
      === filteredAnsweredQuestions[index].offsetBasis.toLowerCase());
    if (startDateIndex >= 0) {
      let StartDate = filteredAnsweredQuestions[startDateIndex].answerSets[0].name;
      // added this condition for MCI expiry date issue.
      if (moment(StartDate, "DD/MM/YYYY", true).isValid()) {
        StartDate = moment(StartDate, "DD/MM/YYYY");
      }
      if (filteredAnsweredQuestions[index].default && filteredAnsweredQuestions[index].default !== '') {
        endDate =
          filteredAnsweredQuestions[index].default?.toLowerCase() === 'mindate' ? filteredAnsweredQuestions[index].minDate
            : filteredAnsweredQuestions[index].default?.toLowerCase() === 'maxdate' ? filteredAnsweredQuestions[index].maxDate
              : filteredAnsweredQuestions[index].default;
      }

      else if (filteredAnsweredQuestions[index].minDate !== '' && filteredAnsweredQuestions[index].minDate !== undefined &&
        (filteredAnsweredQuestions[index].default === '' || filteredAnsweredQuestions[index].default === undefined)) {
        endDate = filteredAnsweredQuestions[index].minDate;

      }
      const calculateEndDate = this.dateManipulationService.applyDateOffset(endDate, moment(StartDate), this.sponsorTimeZoneOffset);
      filteredAnsweredQuestions[index].answerSets[0].name = calculateEndDate.format('YYYY-MM-DD');
      if (sessionStorage.getItem('isExpiryDateasInceptionDate') === 'false') {
        filteredAnsweredQuestions[index].answerSets[0].name =
          this.dateManipulationService.applyDateOffset('-1,days', calculateEndDate, this.sponsorTimeZoneOffset);
      }
    }
  }



  getGrandChildAnswers(childQuestionSet) {
    for (const grandChildQuestionSet of childQuestionSet?.answerSets) {
      if (grandChildQuestionSet?.childQIntentCode && grandChildQuestionSet?.childQuestionSets
        && grandChildQuestionSet?.childQuestionSets.length > 0 && grandChildQuestionSet?.isAnswered === true) {
        for (const grandChildSet of grandChildQuestionSet?.childQuestionSets) {
          const childAnswer = grandChildSet.answerSets.filter(as => as.isAnswered).pop();
          if (childAnswer) {
            if ((grandChildSet.qQuestionFormat?.toLowerCase() === "datepicker")
              && (childAnswer.name.length > 10)) {
              const objectDate = moment(childAnswer?.name).valueOf();
              const objectNewDate = moment(objectDate).format('YYYY-MM-DD');
              if (objectNewDate && objectNewDate !== 'Invalid date' && objectNewDate !== undefined) {
                childAnswer.name = objectNewDate;
              }
            }
            if (grandChildQuestionSet.qQuestionFormat?.toLowerCase() === 'checkbox' && childAnswer.placeholderText) {
              this.childAnswers.push(childAnswer.placeholderText);
            }
            if (grandChildSet?.hideHistory !== true) {
                if (grandChildQuestionSet.qQuestionFormat?.toLowerCase() === 'checkbox' && childAnswer.placeholderText) {
                    this.childAnswers.push(childAnswer.placeholderText);
                }
                else {
                    this.childAnswers.push(childAnswer.name);
                }
                this.childQuestions.push(grandChildSet.qDescription);
            }

            if (grandChildSet.childQIntentCode) {
              this.getGrandChildAnswers(grandChildSet);
            }
          }
        }
      }
    }
  }
  getFlaggedAnswers(question: Question): AnswerSets[] {
    return question.answerSets.filter(as => as.isAnswered);
  }
  getAnchorDate(): Date | moment.Moment {
    const timeZoneOffset = this.sessionStorage.get('timeZoneOffset');
    this.sponsorTimeZoneOffset = timeZoneOffset && timeZoneOffset.toLowerCase().replace('utc', '');
    return this.dateManipulationService.getMomentDatewithUtcOffset(this.sponsorTimeZoneOffset);
  }
  private applyTranslations() {
    this.translate.get([editLabel, deleteLabel, grossPremiumLabel]).subscribe((res) => {
      this.editText = res[editLabel];
      this.deleteText = res[deleteLabel];
      this.grossPremiumText = res[grossPremiumLabel];
    });
  }

  getCompressedAnswer(filteredAnsweredQuestions) {
    // grouping of array on the basis of groupkey
    const groups = filteredAnsweredQuestions.reduce((r, a) => {
      r[a.groupKey] = [...r[a.groupKey] || [], a];
      return r;
    }, {});

    for (const key of Object.keys(groups)) {
      if (key.toLowerCase().includes('group')) {
        // sort the array on basis of orderno.
        const sortedArray = this.sortArray(groups[key]);
        // copy first element of array
        const clonedProductDetailArray = groups[key][0];
        // concat the product name on basis of sorted array (ex : Apple Iphone XMax Red 128gb)
        this.label = '';
        sortedArray?.filter(value => {
          this.label = this.label?.concat(value.answerSets[0].name, ' ');
          return this.label;
        });
        // remove the array element which are concat above from array
        sortedArray?.filter(value => {
          filteredAnsweredQuestions.findIndex((product, index) => {
            if (product?.qSubDescription === value?.qSubDescription) {
              filteredAnsweredQuestions.splice(index, 1);
            }

          });

        });

        if (sortedArray?.length > 0 && sortedArray && sortedArray !== undefined) {
          // assign the concatinated product name values
          clonedProductDetailArray.qSubDescription = this.label;
          // push the copiedarrayelement at first index in below array
          filteredAnsweredQuestions?.splice(0, 0, clonedProductDetailArray);
        }
      }
    }

    // below code to not to display the confirmation detail in previous answer
    // in case if intial confirmation page is hide

    if (!filteredAnsweredQuestions.some(item => item.childQIntentCode === '')) {
      filteredAnsweredQuestions.findIndex((product, index) => {
        if (product.qSubDescription === 'Confirmation') {
          filteredAnsweredQuestions.splice(index, 1);
        }
      });
    }
    this.arrayLength = (filteredAnsweredQuestions.length) - 1;
    return filteredAnsweredQuestions;

  }

  sortArray(groups) {
    const sortedArray = groups.sort((x, y) => {
      if (x?.orderNo > y?.orderNo) {
        return 1;
      }
      if (x?.orderNo < y?.orderNo) {
        return -1;
      }
      return 0;
    });
    return sortedArray;

  }
  getQuestionsAnswer(question: Question) {

    if (question.qIntentCode ==='Brand' && this.isCompressedViewOfPreviousAnswers) {
      return '' ;
    }
    else {
     const answer = question.answerSets.filter(as => as.isAnswered)
     if (answer) {
       if (question.qQuestionFormat?.toLowerCase() === 'checkbox' && answer[0].placeholderText) {
         return answer[0].placeholderText;
       }
       else {
        return answer[0].name;
       }
    }
  }
   }

  isDisabled(answer: any) {
    return (answer.isHidden || answer.isHideEditButton) || this.isApproveDeclineOnly || this.isApproveDeclineWithPremiumOverride;
  }

}
