export class MandatoryForCoverageResponse {
    optionalCoverId: number;
    sponsorId: number
    productTemplateId: number
    coverageId: number
    isDeleted: boolean
    optionalCoverItems: MultiQuoteOptionalCoverageItems[]
}

export class MultiQuoteOptionalCoverageItems {
    optionalCoverItemId: number
    optionalCoverId: number
    languageId: number
    limit: string
    additionalText: string
    sortOrder: number
    isDeleted: boolean
    isMandatory: boolean
    mandatoryForCoverageIds: string
    optionalCover: MandatoryForCoverageResponse
    preSelectForNewSales: any;
    displaySequenceForSecondaryCoverage?: number;
    hideCoverage: false
}
