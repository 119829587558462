<div id="divFormGroup" [formGroup]="form" [ngClass]="{'dropDown-container-medium': !isSmallView}"
  class="dropDown-container" *ngIf="!field?.qIntentCode">
  <mat-form-field id="matFormField" appearance="fill" [hideRequiredMarker]="true"  [ngClass]="{'mat-form-field-readonly':field.disableMatFormField}">
    <mat-label id="matLablel" [innerHTML]="field.label | safeHtml"></mat-label>
    <mat-select id="{{dropDownFormControlName}}" *ngIf="field.options.length>0" (selectionChange)="onSelectionChange($event,field)"
      formControlName="{{dropDownFormControlName}}" [required]="field.required" [disabled]="field.readOnly"
      disableOptionCentering disableRipple panelClass="myPanelClass">
      <mat-option *ngFor="let option of field.options" [value]="option.value">
        {{option.name}}
      </mat-option>
    </mat-select>

    <mat-select id="{{dropDownFormControlName}}" *ngIf="field.groupOptions.length>0"
      (selectionChange)="onSelectionChange($event,field)" formControlName="{{dropDownFormControlName}}"
      [required]="field.required" [disabled]="field.readOnly" disableOptionCentering disableRipple
      panelClass="myPanelClass">
        <mat-optgroup *ngFor="let groupOption of field.groupOptions" [label]="groupOption.label">
          <mat-option *ngFor="let option of groupOption.options" [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="!this.formControl.valid">{{mandatoryErrorMsg}}</mat-error>
  </mat-form-field>
</div>
<div [ngClass]="{'dropDown-container-medium': !isSmallView}"
  class="dropDown-container" *ngIf="field?.qIntentCode">
  <lib-auto-search-dropdown [optionListDetailsForm]="form" [optionList]="field.options"
                            (valueChange)="onAutoSearchSelectionChange($event)" [placeHolderLabel]="field.label"
                            [controlName]="dropDownFormControlName" [field]="field"></lib-auto-search-dropdown>
  <mat-error *ngIf="!this.formControl.valid">{{mandatoryErrorMsg}}</mat-error>
</div>
