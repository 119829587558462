import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormGroupControlService } from 'projects/wtw-ui-components/src/lib/services/form-group/form-group.service';
import { FormFieldBase } from '../../../../form-field/form-field-base';
import { ContactPreferences } from '../../../../models/your-profile.model';


@Component({
  selector: 'lib-contact-preferences',
  templateUrl: './contact-preferences.component.html',
  styleUrls: ['./contact-preferences.component.scss']
})

export class ContactPreferencesComponent implements OnInit {

  @Input() contactPreferences: ContactPreferences;
  @Output() contactPreferencesEvent = new EventEmitter();
  contactPreferencesForm: FormGroup;
  field: FormFieldBase<string>[] = [];
  isReady = false;

  constructor(private readonly formGroupControlService: FormGroupControlService) { }

  ngOnInit(): void {
    this.buildFormFields();

    this.contactPreferences.contactTypeList.forEach((value) => {
      value['isContentCollapsed'] = true;
    });
  }

  async buildFormFields() {

    const formFields: FormFieldBase<string>[] = [];
    this.contactPreferences.contactTypeList.forEach((value) => {
      formFields.push(value.contact);
    });

    this.formGroupControlService.formFieldswithOrder(formFields).subscribe(results => {
      this.field = results;
      this.contactPreferencesForm = new FormGroup(
        this.formGroupControlService.toFormControlArray(results)
      );

      this.isReady = true;
    });
  }

  onClickEvent(eventType: string) {
    this.contactPreferencesEvent.emit(eventType);
  }

}

