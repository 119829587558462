import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout/layout.service';
import { SideBarService } from '../../services/side-bar/side-bar.service';
import { PolicyService } from '../../services/policy/policy.service';
import { YourPolicy } from '../models/index';
import { SessionStorageService } from 'angular-web-storage';
import { BaseConstantService } from '../../services/constant/base-constant.service';
import { PolicyRenewalDetail } from '../models/policyRenewalDetail.model';

const yourPoliciesTitleLabel = 'AccountDetails.lblYourPoliciesTitle';
const policyNumberLabel = 'AccountDetails.lblPolicyNumber';
const insurerNameLabel = 'AccountDetails.lblInsurerName';
const policyInceptionDateLabel = 'AccountDetails.lblPolicyInceptionDate';
const policyExpiryDateLabel = 'AccountDetails.lblPolicyExpiryDate';
const policyTermLabel = 'AccountDetails.lblPolicyTerm';
const policyPremiumLabel = 'AccountDetails.lblPolicyPremium';
const policyStatusLabel = 'AccountDetails.lblPolicyStatus';
const paymentTermsLabel = 'AccountDetails.lblPaymentTerms';
const viewTextLabel = 'AccountDetails.lblViewText';
const viewRenewalQuoteButtonLabel = 'AccountDetails.lblViewRenewalQuote';
const noPolicyInfoLabel = 'AccountDetails.lblNoPolicyInfoText';
const viewRenewalPolicy =  'AccountDetails.lblViewRenewalPolicy';


@Component({
  selector: 'lib-your-policies',
  templateUrl: './your-policies.component.html',
  styleUrls: ['./your-policies.component.scss']
})
export class YourPoliciesComponent implements OnInit, AfterViewInit {
  isMobile = false;
  isTablet = false;
  isDesktop = false;
  public isLoading = false;
  public policiesInfo: YourPolicy[];
  isPoliciesInfo = false;
  filterIcon: any;
  selectedStatusForSorting = 'All';
  selectedStatusInput = 'All';
  statusList: string[] = ['All'];
  renewPolicyButtonLabel: string;
  renewPolicyButtonAdminLabel: string;
  public filterPolicyList: YourPolicy[];

  public hideFilterSelection = true;


  public labels = {
    yourPoliciesTitleLabel: '',
    productCategoryLabel: '',
    policyNumberLabel: '',
    insurerNameLabel: '',
    policyInceptionDateLabel: '',
    policyExpiryDateLabel: '',
    policyTermLabel: '',
    policyPremiumLabel: '',
    policyStatusLabel: '',
    paymentTermsLabel: '',
    viewTextLabel: '',
    viewRenewalQuoteButtonLabel: '',
    noPolicyInfoLabel: '',
    viewRenewalPolicy: ''
  };

  constructor(
    private translate: TranslateService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private router: Router,
    private policyService: PolicyService
  ) { }

  ngOnInit(): void {
    this.policiesInfo = this.route.snapshot.data.yourpolicyList;
    this.renewPolicyButtonAdminLabel = this.route.snapshot.data.myTransactionsLabel;
    this.filterPolicyList = Object.assign([], this.policiesInfo);
    this.createStatusArray();
    this.sortObjectBasedOnExpiryDate();

    if (this.filterPolicyList.length === 0) { this.isPoliciesInfo = true; }

    const labelList: string[] = [yourPoliciesTitleLabel, policyNumberLabel, insurerNameLabel,
      policyInceptionDateLabel, policyExpiryDateLabel, policyTermLabel, policyPremiumLabel, policyStatusLabel, paymentTermsLabel,
      viewTextLabel, viewRenewalQuoteButtonLabel, noPolicyInfoLabel, viewRenewalPolicy
    ];
    this.bindTranslatedLabels(this.applyTranslations([...labelList]));
    if (this.filterPolicyList.length > 0) {
      this.checkDeviceWidth();
    }


    this.policiesInfo.forEach((policy) => {
      policy.renewalPolicyButtonLabel = this.renewPolicyButtonAdminLabel;
      if (policy.isRenewPolicyButton &&
        (policy.policyStatusInEnglish.toUpperCase() === 'ACTIVE' || policy.policyStatusInEnglish.toUpperCase() === 'EXPIRED')
        && !policy.isB2cSingleCarrierMultiQuoteEnabled) {
        policy.showRenewalPolicyButton = true;
        if ((policy.renewalQuoteStatus === null || policy.renewalQuoteStatus === undefined) ||
          policy.renewalQuoteStatus.toUpperCase() === "QUOTED") {
            policy.renewalPolicyButtonLabel = this.renewPolicyButtonAdminLabel;
        } 
        else {
          policy.showRenewalPolicyButton = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    if (this.filterPolicyList.length > 0) {
      this.displayWindowSize();
    }
  }

  displayWindowSize() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const windowWidth = document.body.scrollWidth;
    const windowHeight = document.body.scrollHeight;
    const overlayElement = document.getElementById('overlay');
    const topHeight = document.getElementById('toggleDropdown').getBoundingClientRect().top;
    const elementHeight = windowHeight - topHeight - 56;
    const overlayLeft = ((windowWidth - 200) / 2) - 30;
    overlayElement.style.left = `-${overlayLeft}px`;
    overlayElement.style.width = windowWidth + 'px';
    overlayElement.style.height = elementHeight + 'px';

    if (this.isMobile) {
      const dropdownContent = document.getElementById('dropdown-content');
      dropdownContent.style.minWidth = windowWidth + 'px';
      dropdownContent.style.left = `-${overlayLeft}px`;
      dropdownContent.style.paddingLeft = `${overlayLeft + 40}px`;
    }
  }

  private bindTranslatedLabels(translatedLabelList: string[]): void {
    this.labels.yourPoliciesTitleLabel = translatedLabelList[yourPoliciesTitleLabel];
    this.labels.policyNumberLabel = translatedLabelList[policyNumberLabel];
    this.labels.insurerNameLabel = translatedLabelList[insurerNameLabel];
    this.labels.policyInceptionDateLabel = translatedLabelList[policyInceptionDateLabel];
    this.labels.policyExpiryDateLabel = translatedLabelList[policyExpiryDateLabel];
    this.labels.policyTermLabel = translatedLabelList[policyTermLabel];
    this.labels.policyPremiumLabel = translatedLabelList[policyPremiumLabel];
    this.labels.policyStatusLabel = translatedLabelList[policyStatusLabel];
    this.labels.paymentTermsLabel = translatedLabelList[paymentTermsLabel];
    this.labels.viewTextLabel = translatedLabelList[viewTextLabel];
    this.labels.viewRenewalQuoteButtonLabel = translatedLabelList[viewRenewalQuoteButtonLabel];
    this.labels.noPolicyInfoLabel = translatedLabelList[noPolicyInfoLabel];
    this.labels.viewRenewalPolicy = translatedLabelList[viewRenewalPolicy];
  }

  private applyTranslations(labelList: string[]): string[] {
    let response: string[];
    this.translate.get(labelList).subscribe((res) => {
      response = res;
    });
    return response;
  }

  checkDeviceWidth() {
    this.layoutService.subscribeToLayoutChanges().subscribe(observerResponse => {
      this.isMobile = this.layoutService.isSmallView();
      this.isTablet = this.layoutService.isMediumView();
      this.isDesktop = this.layoutService.isLargeView();
    });
  }

  async renewPolicy(policy, isViewRenewalQuote: boolean = false) {

      const { quoteReferenceID, policyID, productTemplateId, renewalQuoteOrderID, renewalQuoteReferenceID, renewalPolicyID,
              policyNumber, renewalProductId } = policy;

      const detail: PolicyRenewalDetail = {
        QuoteReferenceID: renewalQuoteOrderID > 0 ? renewalQuoteReferenceID : quoteReferenceID,
        AssociatedPolicyId: policyID,
        ProductId: productTemplateId,
        RenewalQuoteOrderID: renewalQuoteOrderID,
        RenewalQuoteReferenceID: renewalQuoteReferenceID,
        RenewalPolicyID: renewalPolicyID,
        RenewalPolicyNumber: policyNumber,
        RenewalProductId: renewalProductId
      };

      this.policyService.savePolicyRenewalDetailsToSession(detail);
      this.router.navigate(['quote/choose-cover'], { queryParams: { PreviousPage: 'YourPolicies', ViewRenewalQuote: isViewRenewalQuote } });
  }

  viewPolicyDetail(policy) {
    this.policyService.viewPolicyDetail(policy);
  }

  viewRenewalPolicyDetail(policyId) {
    if (!policyId) {
      return;
    }
    const renewalPolicy = this.filterPolicyList.find(policy => policy.policyID === policyId.toString());
    this.viewPolicyDetail(renewalPolicy);
  }

  toggleDropdown() {
    this.hideFilterSelection = !this.hideFilterSelection;
    if (!this.hideFilterSelection) {
      this.displayWindowSize();
    }
  }

  clearFilter() {
    this.selectedStatusInput = 'All';
    this.selectedStatusForSorting = 'All';
    this.filterPolicyList = Object.assign([], this.policiesInfo);
    this.sortObjectBasedOnExpiryDate();
    this.toggleDropdown();
  }

  applyFilter() {
    if (this.selectedStatusInput === 'All') {
      this.clearFilter();
    } else {
      this.selectedStatusForSorting = this.selectedStatusInput;
      this.filterPolicyList = this.policiesInfo.filter((value) => {
        if (value.policyStatus === this.selectedStatusForSorting) {
          return value;
        }
      });
      this.sortObjectBasedOnExpiryDate();
      this.toggleDropdown();
    }

  }

  createStatusArray() {
    this.policiesInfo.forEach((value) => {
      if (!this.statusList.includes(value.policyStatus)) {
        this.statusList.push(value.policyStatus);
      }
    });
  }

  sortObjectBasedOnExpiryDate() {
    this.filterPolicyList.sort((a, b) => {
      const firstValue = new Date(a.policyExpiryDate).getTime();
      const secondValue = new Date(b.policyExpiryDate).getTime();
      return secondValue - firstValue;
    });
  }

  checkForViewRenewal(renewalPolicyID): boolean {
    return renewalPolicyID > 0 &&
      this.filterPolicyList.some(x => x.policyID === renewalPolicyID.toString() 
      && x.policyStatusInEnglish.toLowerCase() === 'soon to be active');
  }

  checkForViewRenewalQuote(policy: YourPolicy): boolean {
    return this.checkForViewRenewal(policy.renewalPolicyID) === false && policy.renewalQuoteStatus === 'QUOTEREFERRED';
  }
}
