import { Injectable } from '@angular/core';
import { FileRequest } from '../../shared/models/filerequest.model';
import { endPoints } from '../../shared/core/app.config';
import { CommonHttpService } from '../common-http/common-http.service';

@Injectable({ providedIn: 'root' })

export class BaseDocumentService {

  private storeClaimsTemporaryAttachmentsURL = endPoints.apiUrl + endPoints.endPointName.storeClaimsTemporaryAttachmentsURL;
  private getTempAttachmentDocumentsURL = endPoints.apiUrl + endPoints.endPointName.getTempAttachmentDocuments;
  private deleteTemporaryAttachmentsURL = endPoints.apiUrl + endPoints.endPointName.deleteTemporaryAttachments;
  private getFileURL = endPoints.apiUrl + endPoints.endPointName.getFile;
  private downloadDocumentURL = endPoints.apiUrl + endPoints.endPointName.downloadDocument;

  constructor(protected commonHttpService: CommonHttpService) {
  }

  storeClaimsTemporaryAttachments(attachmentInfo: any) {
    const formData: FormData = new FormData();
    formData.append('fileKey', attachmentInfo.file, attachmentInfo.file.name);
    formData.append('claimId', attachmentInfo.claimId);
    formData.append('AttachmentTypeId', attachmentInfo.attachmentTypeId);
    formData.append('AttachDescription', attachmentInfo.attachDescription);
    formData.append('attachmentReferenceId', attachmentInfo.attachmentReferenceId);

    return this.commonHttpService.httpPostFile(this.storeClaimsTemporaryAttachmentsURL, formData);
  }

  getTempAttachmentDocuments(documentRequest: any) {
    return this.commonHttpService.httpPostServiceWithBearer(this.getTempAttachmentDocumentsURL, documentRequest);
  }

  deleteTemporaryAttachments(documentRequest: any) {
    return this.commonHttpService.httpPostServiceWithBearer(this.deleteTemporaryAttachmentsURL, documentRequest);
  }

  getFile(documentRequest: FileRequest) {
    return this.commonHttpService.httpPostServiceWithBearer(this.getFileURL, documentRequest);
  }

  downloadDocument(documentRequest: any): any {
    return this.commonHttpService.httpPostServiceWithBearer(this.downloadDocumentURL, documentRequest);
  }

  downloadFile(fileContent: any, fileName: string) {
    const mimeType = 'application/pdf';
    const pdfSrc: Uint8Array = this.base64DecodetoByteArray(fileContent);
    const file = new Blob([pdfSrc], { type: mimeType });
    const nav = (window.navigator as any);
    if (nav.msSaveOrOpenBlob) {
      // *** IE11 and Edge *** //
      nav.msSaveOrOpenBlob(file, );
    } else {
      // *** Chrome, Safari, Firefox, Opera *** //
      const url = URL.createObjectURL(file);
      this.openLink(url, fileName);
      // *** Remove the link when done ***//
      window.URL.revokeObjectURL(url);
    }
  }

  base64DecodetoByteArray(base64EncodeData: string) {
    const byteArray = new Uint8Array(atob(base64EncodeData).split('').map(char => char.charCodeAt(0)));
    return byteArray;
  }

  base64DecodetoBlobFile(base64EncodeData: string, mimeType: string) {
    const byteArray = new Uint8Array(atob(base64EncodeData).split('').map(char => char.charCodeAt(0)));
    const file = new Blob([byteArray], { type: mimeType });
    return file;
  }

  private openLink(url: string, fileName: string) {
    const a = document.createElement('a');
    // *** Firefox requires the link to be in the body *** //
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    a.click();
    // *** Remove the link when done *** //
    document.body.removeChild(a);
  }

  getFileExtension(filename) {
    return filename.split('.').pop().toUpperCase();
  }

  getUploadedDocIcon(uploadedDocType) {
    const supportedFormats = [
      { icon: 'image', name: 'image', type: ['jpg', 'png', 'jpeg', 'bmp', 'tif', 'tiff'] },
      { icon: 'insert_drive_file', name: 'documents', type: ['doc', 'docx', 'xls', 'xlsx'] },
      { icon: 'picture_as_pdf', name: 'pdf', type: ['pdf'] },
      { icon: 'description', name: 'text-document', type: ['ppt', 'pptx', 'csv', 'txt'] },
      { icon: 'library_music', name: 'music', type: ['mp3'] }
    ];
    return supportedFormats.filter(sf => sf.type.includes(this.getFileExtension(uploadedDocType).toLowerCase()))[0];

  }
}
