import { LoggingService } from 'wtw-ui-components';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    // Helper property to resolve the service dependency.
    private get _router() { return this.injector.get(Router); }

    constructor(private loggingService: LoggingService, private injector: Injector) {
        super();
    }

    handleError(error: Error) {
        if (error.toString().indexOf('ExpressionChangedAfterItHasBeenCheckedError') > 0) {
            return;
        }
        this.loggingService.logException(error); // Manually log exception
        this.navigateErrorPage();
    }

    private navigateErrorPage() {
        this._router.navigate(['/error-page']);
    }
}
